import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import GlobalProvider from "./context";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import store from "./store";

const app = (
    <GlobalProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </GlobalProvider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
