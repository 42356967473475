import React from "react";
import { Redirect } from "react-router";
import { PageContainer } from "../../../components/common/PageContainer";
import { getParams, getUrlPath } from "../../../helpers";
import Detail from "./detail";

const Dashboard = () => {
    const { arg_1: entity } = getParams();
    if (!entity) return <Redirect to={getUrlPath(1, "dashboard")} />;
    else return <PageContainer breadcrumbTitle="Market Intelligence Dashboard" component={Detail} />;
};

export default Dashboard;
