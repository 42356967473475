import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { PageContainer } from "../../../components/common/PageContainer";
// import { GeneralContext } from "../../../context";
import { getLabel, getParams, useEntityFields } from "../../../helpers";

const List = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const { arg_0: solution, arg_1: entity } = getParams();
    // const { enum_commodity_type, enum_catalogue_type } = useContext(GeneralContext);
    const entity_fields = useEntityFields({ entity, solution });

    // console.log("entity fields are", entity, solution, entity_fields);
    const override_fields = {};
    // if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    // console.log("----------------");

    return (
        <PageContainer
            breadcrumbTitle={getLabel(entity, true)}
            breadcrumbOptions={["type", "active"]}
            breadcrumbArg={3}
            tab_arg={4}
            tab_options={[
                {
                    label: "ALL",
                    value: "all",
                    ...entity_fields,
                    ...override_fields,
                    overrideValues: {
                        ...(entity_fields?.overrideValues || {}),
                        ...(override_fields?.overrideValues || {}),
                    },
                    component: DataTable,
                    filters: {
                        ...(entity_fields?.filters || {}),
                        ...(override_fields?.filters || {}),
                    },
                },
            ]}
        />
    );
};

export default List;
