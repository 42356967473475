export const APP_PATHS = {
    login: "/",
    logout: "/logout",
    authRedirect: "/auth/redirect",
    // dashboard:  "/auth/dashboard",
    dashboard: "/adminDashboard",
   
    forgotPassword: "/auth/pwd/reset",
    changePassowrd: "/auth/pwd/change",
};

export const APP_CONFIG = {
    NAME: "Origin Konnect",
    PROTOCOL: "https",
    HOST: "127.0.0.1",
    PORT: "3000",
    ...process.env,
};
