import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../components/common/DataTable";
import { PageContainer } from "../../../components/common/PageContainer";
import { GeneralContext } from "../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields } from "../../../helpers";

const List = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
    const { enum_order_status } = useContext(GeneralContext);
    const entity_fields = useEntityFields({ entity, solution });
    // const override_fields = { disableAction: true, filters:{}};
    const override_fields = { }


    if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    return (
        <PageContainer
            breadcrumbTitle={getLabel(entity, true)}
            breadcrumbOptions={["status", "active"]}
            breadcrumbArg={3}
            tab_arg={4}
            disableAdd={true}
            tab_options={(() => {
                let tab_options = [];
                switch (tab) {
                    case "status":
                        tab_options = !_.isArray(enum_order_status)
                            ? []
                            : enum_order_status?.map((e) => ({
                                  ...entity_fields,
                                  ...override_fields,
                                  overrideValues: {
                                      ...(entity_fields?.overrideValues || {}),
                                      ...(override_fields?.overrideValues || {}),
                                  },
                                  label: e?.comment,
                                  value: e?.value,
                                  component: DataTable,
                                  filters: {
                                      ...(entity_fields?.filters || {}),
                                      ...(override_fields?.filters || {}),
                                      status: { _eq: e.value },
                                  },
                              }));
                        break;
                    default:
                        tab_options = ENUM_ACTIVE.map((e) => ({
                            ...entity_fields,
                            ...override_fields,
                            overrideValues: {
                                ...(entity_fields?.overrideValues || {}),
                                ...(override_fields?.overrideValues || {}),
                            },
                            label: e?.comment,
                            value: e?.value,
                            component: DataTable,
                            filters: {
                                ...(entity_fields?.filters || {}),
                                ...(override_fields?.filters || {}),
                                active: { _eq: e.value === "active" ? true : false },
                            },
                        }));
                        break;
                }
                tab_options = [
                    {
                        label: "ALL",
                        value: "all",
                        ...entity_fields,
                        ...override_fields,
                        overrideValues: {
                            ...(entity_fields?.overrideValues || {}),
                            ...(override_fields?.overrideValues || {}),
                        },
                        component: DataTable,
                        filters: {
                            ...(entity_fields?.filters || {}),
                            ...(override_fields?.filters || {}),
                        },
                    },
                    ...tab_options,
                ];
                return tab_options;
            }).call(this)}
        />
    );
};

export default List;
