import React from 'react';
import { useForm } from 'react-hook-form';
import OkImage from '../../../assets/images/oclogo/cecs.png';
import ShipSvg from '../../../assets/images/brands/container.svg';
import { useHistory } from 'react-router-dom';
import OtpVerification from './OtpVerifiy';
import { SendWebRequest } from '../common/sendRequest';
import { AuthContext } from "../../../context";


const OtpScreen = () => {

  const history = useHistory();
  
  const {setAuthContext} = React.useContext(AuthContext);

  const [verificationPage, setVerificationPage] = React.useState(true);

  const [phoneNo, setPhoneNo] = React.useState(true);

  const { register, handleSubmit,  formState } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' });

  const { isDirty } = formState;
  
  const onSubmit = async (e) => {

    const phoneNumber = {"phone": `+91${e["phone"]}`};

    // console.log("Phone number",phoneNumber);

  
    setPhoneNo(e["phone"]);

    const response = await SendWebRequest({ method: 'POST', subUrl: '/user/loginOtp', body: { role: 'exporter', phone:phoneNumber["phone"] }, Smes: 'Otp Sent to you Mobile number', Emes: (err) => `${err?.response?.data?.error?.message}` });

    // console.log("response is",response, response?.data?.data?.user_info?.id,  response?.data?.data?.token, response?.data?.data?.user_info?.role);

    setAuthContext({
      customer_id: response?.data?.data?.user_info?.customer_id,
      user_id: response?.data?.data?.user_info?.id,
      id_token: response?.data?.data?.token,
      role: response?.data?.data?.user_info?.role,
    });

    setVerificationPage(false);
 
  };

const OtpScreenPage =  <div className="   vh-100  vw-100   row align-items-center justify-content-center bg-white">
      <div className="row m-2 justify-content-around">
        <div className=" col-12 col-sm-6  col-md-6 col-lg-6">
          <img src={ShipSvg} className="img-fluid" alt="Responsive" />
        </div>
        <div className=" col-12 col-sm-6  col-md-6 col-lg-6  ">
          <div class="container mt-2" style={{ margin: '0 auto' }}>
            <div className="float-right col-11 text-center m-2">
              <img src={OkImage} alt="Origin Konnect Logo" height="110px"></img>
            </div>
            
            <form class="needs-validation" onSubmit={handleSubmit(onSubmit)} novalidate >
              <div class="row   ">
                <div className="col-12 col-sm-6 col-sm-11 mb-3 ">
                  <h6 className="h6 small text-uppercase form-label text-success"> Enter Mobile Number <span className="text-danger ml-2"> *</span> </h6>
                  <input type="text" className={`col-12 p-1  border-1  p-2 form-control`} name="phone"   placeholder="97065116516" ref={register({ required: 'Email is required', minLength: 2, })} />
                  <div className="text-danger"> {Error} </div>
                </div>
              </div>
              <div className="text-center col-sm-11">
                <button className={` cursor-pointer btn btn-sm btn-success text-justify m-4 ps-4 pe-4 pt-2 pb-2 m-2 ${ !isDirty ? 'disabled' : '' }`} type="submit" > Send Otp </button>
              </div>
            </form>
          </div>
          <h6 className="text-center   text-success"> Don't have an account ?  <span className="text-danger cursor" role="button" onClick={() => history.push('/user/auth/signup')} > Signup </span> </h6>
        </div>
      </div>
    </div>
  

  return verificationPage ? OtpScreenPage : <OtpVerification phoneNumber={phoneNo} onsubmit={onSubmit}/>;
};

export default OtpScreen;
