import axios from "axios";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { APP_CONFIG } from "../../config";
import { AuthContext } from "../../context";
import Camera from "./camera";
import { ImageModal } from "./ImageModal";
import { Loader } from "./Loader";

export const UploadImage = React.forwardRef((props, ref) => {
    const {
        onChange = () => {},
        value = [],
        multiple = "false",
        type = "",
        accept = "",
        cameraField = "false",
        label = "Choose file",
        privateImage = false,
        key=null
        
    } = props;
     const { id_token } = useContext(AuthContext);
    const form_data = new FormData();
    const [loader, setLoader] = useState(false);
    const [error, showError] = useState(false);

    console.log("type ", value);

    return (
        <div ref={ref}>
            <div className="input-group d-flex justify-content-sm-start  align-items-start">
                {!loader && (
                    <label for={props.id || type} style={{ padding: "6.5px 10px", cursor: "pointer", zIndex: 0 }} className="btn btn-outline-success m-1">
                        {label}
                    </label>
                )}
                <div className="custom-file m-1">
                    {cameraField === true && <Camera id={key || type} onChange={onChange} token={id_token} />}
                    <input
                        width="75%"
                        type="file"
                        multiple={multiple}
                        id={ props.id || type}
                        style={{ display: "none" }}
                        className="btn btn-sm btn-pill m-1 btn-outline-primary"
                        accept={accept}
                        onChange={(e) => {
                            showError(false);
                            // console.log(
                            //     "chekcing accept is",e?.target?.files?.[0]?.name ,  accept, accept.test(e?.target?.files?.[0]?.name)
                            // )
                            if (!accept.test(e?.target?.files?.[0]?.name)) {
                                showError(true);
                                return;
                            }
                            Object.entries(e?.target?.files).map(([, value]) => form_data.append("uploads", value));
                            setLoader(true);

                            // gif|jpeg|tiff|png|webp|bmp
                            axios
                                .post(
                                    `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/userimage/${props?.privateImage ? "private" : "public"}`,
                                    form_data,
                                    {
                                        headers: { Authorization: `Bearer ${id_token}`, "x-client-app": "web-admin" },
                                    }
                                )
                                .then((result) => {
                                    // console.log("checking result is", result, result?.config,"VALUE ",value,type,result?.data?.data?.file_url,_.isArray(value) && type !== "photo" && type !== "document")
                                    if (result && result?.data?.status === 200 && result?.config && result?.config.url) {
                                        if (_.isArray(value) && type !== "photo" && type !== "document") {
                                            onChange([...value, ...result?.data?.data?.file_url]);
                                            return;
                                        } else {
                                            onChange(result?.data?.data?.file_url[0]);                                                                                                                      
                                        }
                                    } else toast.error("Upload Failed.");
                                })
                                .catch((error) => toast.error(error))
                                .finally(() => setLoader(false));
                        }}
                    />
                </div>
            </div>
            <div className="input-group d-flex justify-content-sm-start  align-items-start">
                {value ? (
                    _.isArray(value) ? (
                        value?.map((v, i) => (
                            <div key={i} className="input-group-append m-1">
                                {v?.slice(-4) !== ".pdf" ? (
                                    <div className="border pl-2">
                                        <ImageModal type={type} key={v} url={v} imageHeight={80} accept={accept} privateImage={privateImage} />
                                        <span className="btn btn-sm">
                                            <i
                                                className="fa fa-lg fa-times text-danger"
                                                aria-hidden="true"
                                                onClick={() => onChange(value.filter((e) => e !== v))}
                                            ></i>
                                        </span>
                                    </div>
                                ) : (
                                    <div className="border pl-2">
                                        <ImageModal type={type} key={v} url={v} imageHeight={80} accept={accept} privateImage={privateImage} />
                                        <span className="btn btn-sm">
                                            <i
                                                className="fa fa-lg fa-times text-danger"
                                                aria-hidden="true"
                                                onClick={() => onChange(value.filter((e) => e !== v))}
                                            ></i>
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="input-group-append m-1">
                            {value?.slice(-4) !== ".pdf" ? (
                                <>
                                    <ImageModal type={type} key={value} url={value} imageHeight={80} accept={accept} privateImage={privateImage} />
                                    <span className="btn btn-sm">
                                        <i className="fa fa-lg fa-times text-danger" aria-hidden="true" onClick={() => onChange(null)}></i>
                                    </span>
                                </>
                            ) 
                            : (

                                <></>
                                // <a key={value} href={value} className="h5">
                                //     {/* <i className="fa fa-file-download fa-lg" aria-hidden="true"></i> */}
                                // </a>
                            )}

                              {value?.slice(-4) === ".pdf" && (
                                <>
                                    <ImageModal type={type} key={value} url={value} imageHeight={80} accept={accept} privateImage={privateImage} />
                                    <span className="btn btn-sm">
                                        <i className="fa fa-lg fa-times text-danger" aria-hidden="true" onClick={() => onChange(null)}></i>
                                    </span>
                                </>
                            ) }
                              {/* {value?.slice(-4) === ".pdf" &&(
                                <a key={value} href={value} className="h5">
                                     <i className="fa fa-file-download fa-lg" aria-hidden="true"></i>  
                                </a>
                            )} */}

                        </div>
                    )
                ) : (
                    <span key={Math.random()} />
                )}
                {loader && <Loader />}
                {error && <p style={{ marginLeft: ".5rem" }}>{`Accept only ${type}`}</p>}
            </div>
        </div>
    );
});
