import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { GeneralContext , AuthContext } from "../../context";
import { useEntityFields, useObjectsQuery } from "../../helpers";
import { DataForm } from "./DataForm";

const TypeAhead = React.forwardRef((props, ref) => {
    const {
        entity = "user",
        value,
        placeholder = "Search...",
        width,
        multiple,
        onChange = () => {},
        align = "justify",
        labelKey = "name",
        valueKey = "id",
        selfId,
        skip_ids = [],
        minLength,
        clearButton = true,
        filters = {},
        increment = 25,
        typeahead_fields = ` id name `,
        filter_fields = [],
        onReset = () => {},
        object = {},
        independent = false,
        hideAdd = false,
        solution,
        activeFilter = true,
        overrideValue="",
        distinctOn = null,
        ...rest
    } = props;

    console.log("checking the value",labelKey,object, filters,filter_fields)
    const { enum_vehicle_weight_map } = useContext(GeneralContext);
    const { role } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    let copyValue = value;
    if (_.isArray(value) && value?.length > 0 && _.isObject(value[0])) {
        copyValue = [...value];
    }

    if (entity === "enum_vehicle_weight" && _.isArray(copyValue) && copyValue?.length > 0 && !_.isObject(copyValue[0])) {
        copyValue = value.map((e, i) => {
            const key = `${"_" + enum_vehicle_weight_map[e]?.replace("kg", "").trim()}`;
            return { value: [key] + "", comment: enum_vehicle_weight_map[e] };
        });
    }

    //  console.log("enums are",filter_fields object);

    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState(increment * 2);
    const [selected, setSelected] = useState(
        // null
        multiple ? (copyValue ? (copyValue[0] === null ? copyValue?.filter((e) => e != null) : copyValue) : []) : copyValue ? [copyValue] : []
        //multiple ? (value ? (value[0] === null ? value?.filter((e) => e != null) : value) : []) : value ? [value]?.map((e) => setLabel(e)) : []
    );
    let entity_fields = useEntityFields({ entity, solution });

    //   console.log("entity fields are", entity_fields);

    const setLabel = (o) => {



        // console.log("Checkasd  asd typasdf",o);
        return entity === "catalogue_commission"
            ? `${o.days} Days (${o.commission}) %`
            : // : entity === "enum_vehicle_weight"
            // ? o?.value?.replace("_", "")
            entity === "contact"
            ? `${o?.name ?? ""}${o?.phone ? " , " + o?.phone : ""}${o?.whatsapp ? " , " + o?.whatsapp : ""}${o?.email ? " , " + o?.email : ""}`
            : o?.type
            ? `${o?.name ?? ""} ${
                  o?.type?.name ?? o?.type?.comment ?? o?.type ? " (" + (o?.type?.name ?? o?.type?.comment ?? o?.type) + ")" : ""
              }${o?.extra?.name ?? o?.extra?.comment ?? o?.extra ? "  - " + (o?.extra?.name ?? o?.extra?.comment ?? o?.extra) : ""}${
                  o?.shape?.name ? " , " + o?.shape?.name : ""
              }${o?.size?.name ? " , " + o?.size?.name : ""}${o?.color?.name ? " , " + o?.color?.name : ""}`
            : o?.name ?? o?.comment ?? o?.address ??   o?.customer?.name ?? "";
    };

    let ids;
    let skipIds = multiple && _.isArray(selected) ? selected?.map((e) => e?.[valueKey] || "_" + e) : [];
    if (props.skipIds) skipIds.push(...skipIds);
    if (selfId) skipIds.push(selfId);
    if (skip_ids.length > 0) skipIds.push(...skip_ids);

    let disabled = false;
    let where = activeFilter ? { active: { _eq: true }, ...filters } : { ...filters };

    if (ids) where[valueKey] = { _in: ids };
    // if (skipIds.length > 0 && !entity.includes("enum")) where[valueKey] = { _nin: skipIds };
    if (skipIds.length > 0) where[valueKey] = { _nin: skipIds };
    // useEffect(() => {
    //     console.log("selected is", selected);
    // });

    let field_filter = {};
    if (filter_fields.length > 0) {
        filter_fields?.map(({ accessor, dbname, nestedAccessor= null }) => {
            const field = _.get(object, accessor);
            // console.log("checking typehead data", field?.id, accessor, dbname,nestedAccessor,object)

            //  if (nestedAccessor&&field?.id)
            // _.set(field_filter, nestedAccessor, { _eq: field?.id  });  
              if (field?.id) _.set(field_filter, dbname ?? `${accessor}_id`, { _eq: field.id });
            else if (field?.value) _.set(field_filter, dbname ?? accessor, { _eq: field.value });
            else if (field) _.set(field_filter, dbname ?? accessor, { _eq: field });
            else if (_.get(object, accessor + "_id"))
                _.set(field_filter, `${dbname ?? accessor}_id`, {
                    _eq: _.get(object, accessor + "_id"),
                });
  
            return null;
        });
        if (!independent && _.isEmpty(field_filter)) disabled = true;
    }

    let search_on = { [labelKey]: { _ilike: `${search}%` } };
    if (entity === "contact")
        search_on = {
            _or: [
                { name: { _ilike: `${search}%` } },
                { phone: { _ilike: `${search}%` } },
                { whatsapp: { _ilike: `${search}%` } },
                { email: { _ilike: `${search}%` } },
            ],
        };
    else if (entity === "commodity_variety")
        search_on = {
            _or: [{ [labelKey]: { _ilike: `${search}%` } }, { commodity: { [labelKey]: { _ilike: `${search}%` } } }],
        };
    else if (entity === "commodity")
        search_on = {
            _or: [{ [labelKey]: { _ilike: `${search}%` } }, { enum_commodity_type: { comment: { _ilike: `${search}%` } } }],
            
        };
 
          

          console.log("Field filter is",where,field_filter,typeahead_fields,labelKey)
    const { objects, refetch, loading } = useObjectsQuery({
        entity: entity_fields.entity ?? entity,
        fields: typeahead_fields,
        where: { ...where, ...field_filter },
        search_on: search_on,
        order_by: { [labelKey]: "asc" },
        // distinct_on:distinctOn&&labelKey!="name" ?  [labelKey]:false ,
        distinct_on: distinctOn ? [labelKey]:[],
        skipCache: true,
        limit,
    });


     
  

       console.log("entity_fieldss", objects,selected,search, search_on,objects?.map((o) => ({ ...o, [labelKey]: setLabel(o)?.trim() })));
   
    useEffect(() => {
        if (
            !_.isEqual(
                selected,
                multiple
                    ? copyValue
                        ? copyValue[0] === null
                            ? copyValue?.filter((e) => e != null)
                            : copyValue
                        : []
                    : copyValue
                    ? [copyValue]?.map((e) => setLabel(e))
                    : []
            )
        )
            setSelected(
                multiple
                    ? copyValue
                        ? copyValue[0] === null
                            ? copyValue?.filter((e) => e != null)
                            : copyValue
                        : []
                    : copyValue
                    ? [copyValue]?.map((e) => setLabel(e))
                    : []
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple, refetch, selected, copyValue]);
    //  console.log("selected",copyValue, selected);


    let modified_columns =[];

    // console.log("typehead ",entity_fields)

    if(role !== "moderator")  {
         modified_columns = entity_fields?.columns?.filter((e,i) =>    !entity_fields?.userColumns?.skipColumn[e?.accessor]);

        modified_columns?.forEach((e,i) =>   {
 
         if(entity_fields?.userColumns?.skipEnum?.[e?.accessor]){
  
                e?.options?.forEach((o,i)=> entity_fields?.userColumns?.skipEnum?.[e?.accessor]?.[o?.value]&&e?.options?.splice(i,1))
         }
      }
       )
     } else{

        if(entity_fields?.columns)modified_columns=[...entity_fields?.columns]
        
     }
     //if it is not itterable
    modified_columns = modified_columns?.length ? [...modified_columns] : []
   
  
    return (
        <div className="row">
            <div className="col" style={{ paddingRight: 0 }}>
                <AsyncTypeahead
                    {...props}
                    ref={ref}
                    open={open}
                    onFocus={() => {
                        refetch();
                        setOpen(true);
                        setSearch("");
                    }}
                    align={align}
                    clearButton={clearButton}
                    id={entity}
                    labelKey={labelKey}
                    isLoading={loading}
                    minLength={minLength ?? 0}
                    multiple={multiple}
                    maxResults={limit - 1}
                    onBlur={() => setOpen(false)}
                    disabled={props.disabled || disabled}
                    placeholder={placeholder}
                    onChange={(e) => {
                        if (!_.isEqual(selected, e)) {
                            console.log("e value ids", e);
                            setSelected(e);
                            setSearch("");
                            onChange(multiple ? e : e[0]);
                            setOpen(multiple ? true : false);
                            if (!independent) onReset();
                        }
                    }}
                    options={objects?.map((o) => ({ ...o, [labelKey]: setLabel(o)?.trim() }))}
                    onSearch={(e) => setSearch(e)}
                    selected={selected}
                    width={width}
                    useCache={false}
                    onPaginate={() => setLimit(limit * 2)}
                    filter_by={(option, props) => option.name.toLowerCase().indexOf(props.text.toLowerCase()) !== -1}
                />
            </div>
            {!props.disabled && !disabled && !hideAdd && (
                <div className="col-auto p-1">
                    <DataForm
                        {...props}
                        key={entity + "add"}
                        {...entity_fields }
                        columns={[...modified_columns]}
                        refetch={refetch}
                        buttonClassName=" btn-outline-light "
                        icon={true}
                        overrideValues={overrideValue}
                        showTitle={rest.Header}
                        onComplete={(o) => {
                            let newSelection = {
                                [valueKey]: o?.id,
                                [labelKey]: setLabel(o),
                            };
                            setSelected([newSelection]);
                            onChange(newSelection);
                            setSearch("");
                        }}
                    />
                </div>
            )}
        </div>
    );
});
export default TypeAhead;
