import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();

	let entity ="customer";
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity, parent_id, solution }),
		// fields: `
        //     id uid active created_at updated_at  name email phone photo_url is_phone_verified is_email_verified role
        //     active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
        //     active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
        //     total_offers: offers_aggregate{aggregate{count}}
        //     total_orders: orders_aggregate{aggregate{count}}
        // `,

		fields: `
		id active
		active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
		active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
		total_offers: offers_aggregate{aggregate{count}}
		total_orders: orders_aggregate{aggregate{count}}
	`,
	});

	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			entity={"customer"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				{
					label: getLabel("order", true),
					value: object?.active_orders?.aggregate?.count || 0,
					total: object?.total_orders?.aggregate?.count || 0,
				},
				{
					label: getLabel("offer", true),
					value: object?.active_offers?.aggregate?.count || 0,
					total: object?.total_offers?.aggregate?.count || 0,
				},
				{
					label: getLabel("bank", true),
					value: object?.active_banks?.aggregate?.count || 0,
					total: object?.total_banks?.aggregate?.count || 0,
				},
				{
					label: getLabel("company", true),
					value: object?.active_companys?.aggregate?.count || 0,
					total: object?.total_companys?.aggregate?.count || 0,
				},
				{
					label: getLabel("document", true),
					value: object?.active_documents?.aggregate?.count || 0,
					total: object?.total_documents?.aggregate?.count || 0,
				},
			]}
			tab_options={[
				{
					...useEntityFields({ entity: "order", parent_id, solution }),
					label: getLabel("order", true),
					value: "order",

					component: DataTable,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["user"],
				},
				{
					...useEntityFields({ entity: "offer", parent_id, solution }),
					label: getLabel("offer", true),
					value: "offer",

					component: DataTable,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["user"],
				},
				{
					...useEntityFields({ entity: "location", parent_id, solution }),
					label: getLabel("location", true),
					value: "location",
					component: DataTable,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["user"],
				},

				// {
				// 	...useEntityFields({ entity: "exporter_data ", parent_id, solution }),
				// 	label: getLabel("exporter", true),
				// 	value: "exporter_data",
				// 	component: DataTable,
				// 	filters: { user_id: { _eq: parent_id } },
				// 	overrideValues: { user_id: parent_id },
				// 	skipColumns: ["customer"],
				// },

			]}
		/>
	);
};

export default Detail;
