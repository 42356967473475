import _ from "lodash";
import React, { useContext } from "react";
import { Button } from "reactstrap";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { GeneralContext,AuthContext } from "../../../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import { Modals } from "./modal";
import RowChild from './rowChild';

const List = () => {

	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	const { enum_po_status } = useContext(GeneralContext);
    const {  user_id , id_token} = useContext(AuthContext);
	let entity_fields = useWebEntityFields("other_freight_forwarder");
    //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
  
	const { object, error } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
	
     const override_fields = { filters:{eta:{_gt:new Date()}} }
 
    const changeFields = {"location":{ filters: { customer_id: { _in: [ object?.customer_id] } }}}

	let neColumns = [];
 
	entity_fields?.columns?.forEach(e => {

		if(changeFields[e?.acessor])  return	neColumns.push({...e,...changeFields[e?.entity]})
	    return	neColumns.push(e)
	}
	)

    entity_fields = { ...entity_fields, "columns":neColumns, }
     
   console.log("object overas",object, object?.customer_id, object?.customer?.locations?.[0]?.id)

 
	return (
		<PageContainer
			// breadcrumbTitle={getLabel("Freight Options", true)}
			// breadcrumbOptions={["type", "active"]}
			// breadcrumbArg={3}
			tab_arg={4}
			key={Math.random()}
			showBreadCrumb={false}
			options={{ entity_fields}}
			disableAction={true}
			disableEdit={true}
			disableAdd={true}
			// disableAddChild={false}
			// disableAddChildName={{entity:"inquiries",overrideValues:{"customer_id":object?.customer_id, "created_by_id":object?.customer_id}}}
			searchableColumns={false}
			hideFilter={true}
			showFilterForm={true}
			// extraColumnData={extraColumnData}
			detail={page === "detail"}
			rowChild={RowChild}
			expanded={true}
			Model={Modals}
			// miniColumnWidth={100}
			enabledispatchColumn={
				[
				
				   {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				// {Header:"Order",entity:"exporter_offer_button",showTitle:true,minWidth:150,  DataForm:true, overrideValues:{customer_id:object?.customer_id }},				// {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				// {Header:"Order",entity:"exporter_offer_button",showTitle:true,minWidth:150,  DataForm:true, overrideValues:{customer_id:object?.customer_id }},				// {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				{Header:"",DataForm:true, entity:"inquiries", showtitle:true, minWidth:150,  
				   overrideValues:{"customer_id":object?.customer_id, "created_by_id":object?.customer_id, },
				   skipdata:[
								"bill_of_lading_charges","toll_charges","temp_variation_charges","cmc_charges","seal_charges","etd",
								"etd","id","created_at","updated_at","export_shipping_line_id","do","bl","transportation_slip","cut_off","transit_period",
								"vessel","dollar_price","active","uid","vessel_risk","muf","validity","total_cost"
					     	],
				},
					 

				]}	
			tab_options={(() => {
				let tab_options = [];
				// switch (tab) {
				// 	case "type":
				// 		tab_options = !_.isArray(enum_po_status)
				// 			? []
				// 			: enum_po_status?.map((e) => ({
				// 					...entity_fields,
				// 					...override_fields,
				// 					overrideValues: {
				// 						...(entity_fields?.overrideValues || {}),
				// 						...(override_fields?.overrideValues || {}),
				// 					},
				// 					label: e?.comment,
				// 					value: e?.value,
				// 					component: DataTable,
				// 					filters: {
				// 						...(entity_fields?.filters || {}),
				// 						...(override_fields?.filters || {}),
				// 						status: { _eq: e.value },
				// 					},
				// 			  }));
				// 		break;
				// 	default:
				// 		tab_options = ENUM_ACTIVE.map((e) => ({
				// 			...entity_fields,
				// 			...override_fields,
				// 			overrideValues: {
				// 				...(entity_fields?.overrideValues || {}),
				// 				...(override_fields?.overrideValues || {}),
				// 			},
				// 			label: e?.comment,
				// 			value: e?.value,
				// 			component: DataTable,
				// 			filters: {
				// 				...(entity_fields?.filters || {}),
				// 				...(override_fields?.filters || {}),
				// 				active: { _eq: e.value === "active" ? true : false },
				// 			},
				// 		}));
				// 		break;
				// }
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						// TableName: Freight,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
						skipColumns: ["rank"] ,
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
 
	);
};

export default List;
