import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({ id: parent_id, ...useEntityFields({ entity, parent_id, solution }) });

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            component={DetailInfo}
            entity={entity}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            metric_cards={[
                {
                    label: getLabel("order", true),
                    value: object?.active_orders?.aggregate?.count || 0,
                    total: object?.total_orders?.aggregate?.count || 0,
                },
                {
                    label: getLabel("offer", true),
                    value: object?.active_offers?.aggregate?.count || 0,
                    total: object?.total_offers?.aggregate?.count || 0,
                },
                {
                    label: getLabel("jobcard", true),
                    value: object?.active_jobcards?.aggregate?.count || 0,
                    total: object?.total_jobcards?.aggregate?.count || 0,
                },
                {
                    label: getLabel("stock", true),
                    value: object?.active_stocks?.aggregate?.count || 0,
                    total: object?.total_stocks?.aggregate?.count || 0,
                },
                {
                    label: getLabel("rate", true),
                    value: object?.active_rates?.aggregate?.count || 0,
                    total: object?.total_rates?.aggregate?.count || 0,
                },
            ]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "order", parent_id, solution }),
                    label: getLabel("order", true),
                    value: "order",
                    component: DataTable,
                    filters: { location_id: { _eq: parent_id } },
                    overrideValues: { location_id: parent_id },
                    skipColumns: ["location"],
                },
                {
                    ...useEntityFields({ entity: "offer", parent_id, solution }),
                    label: getLabel("offer", true),
                    value: "offer",
                    component: DataTable,
                    filters: { location_id: { _eq: parent_id } },
                    overrideValues: { location_id: parent_id },
                    skipColumns: ["location"],
                },
                {
                    ...useEntityFields({ entity: "location_contact", parent_id, solution }),
                    label: getLabel("Contacts", true),
                    value: "contact",
                    component: DataTable,
                    filters: { location_id: { _eq: parent_id } },
                    overrideValues: { location_id: parent_id },
                    skipColumns: ["location"],
                },
                {
                    ...useEntityFields({ entity: "jobcard" }),
                    label: getLabel("jobcard", true),
                    value: "jobcard",
                    component: DataTable,
                    filters: { _or: [{ origin_id: { _eq: parent_id } }, { destination_id: { _eq: parent_id } }] },
                    overrideValues: { location_id: parent_id },
                    skipColumns: ["location"],

                },
                {
                    ...useEntityFields({ entity: "location_stock", parent_id, solution }),
                    label: getLabel("stock", true),
                    value: "stock",

                    component: DataTable,
                    filters: { location_id: { _eq: parent_id } },
                    overrideValues: { location_id: parent_id },
                    skipColumns: ["location"],

                },
                {
                    ...useEntityFields({ entity: "location_rate", parent_id, solution }),
                    label: getLabel("rate", true),
                    value: "rate",

                    component: DataTable,
                    filters: { location_id: { _eq: parent_id } },
                    overrideValues: { location_id: parent_id },
                    skipColumns: ["location"],

                },
            ]}
        />
    );
};

export default Detail;
