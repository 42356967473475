import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { GeneralContext,AuthContext } from "../../../../../context";
import {  getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
 
const List = () => {

	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	const { enum_freight_order_status  } = useContext(GeneralContext);
    const {   user_id } = useContext(AuthContext);
	let entity_fields = useWebEntityFields("user_manage_order");
    //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    // console.log("updated_enum_freight_statsus",enum_freight_order_status)
	const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
	
    const override_fields = { overrideValues:{ exporter_id  : object?.customer_id} ,filters:{exporter_id  :{_eq:object?.customer_id}}}
 
	const updated_enum_freight_statsus = enum_freight_order_status?.filter(e =>  !["awaiting_confirmation"]?.includes(e.value))

	return (
		<PageContainer
			breadcrumbTitle={getLabel("manage order", true)}
			// breadcrumbOptions={["type", "active"]}
			breadcrumbArg={3}
			tab_arg={4}
			key={Math.random()}
			options={{ entity_fields}}
			// disableEdit={true}
			disableAction={true}
			disableAdd={true}
			// enabledispatchColumn={
            //     [
            //        {Header:"Add Instructions",entity:"shipping_instructions",showTitle:true,minWidth:150,  link:true,linkS:"/web/exp_blr/detail/#id", overrideValues:{ }},
			//        {Header:"Notes",entity:"order_note",showTitle:true,minWidth:150,  DataForm:true, overrideValues:{ customer_id: object?.customer_id}},
            //     ]}
			// detail={page === "detail"}
			tab_options={(() => {
				let tab_options = [];
				// switch (tab) {
				// 	case "type":
						tab_options = !_.isArray(updated_enum_freight_statsus)
							? []
							: updated_enum_freight_statsus?.map((e) => ({
									...entity_fields,
									...override_fields,
									overrideValues: {
										...(entity_fields?.overrideValues || {}),
										...(override_fields?.overrideValues || {}),
									},
									label: e?.comment,
									value: e?.value,
									component: DataTable,
									filters: {
										...(entity_fields?.filters || {}),
										...(override_fields?.filters || {}),
										status: { _eq: e.value },
									},
							  }));
					// 	break;
					// default:
						// tab_options = ENUM_ACTIVE.map((e) => ({
						// 	...entity_fields,
						// 	...override_fields,
						// 	overrideValues: {
						// 		...(entity_fields?.overrideValues || {}),
						// 		...(override_fields?.overrideValues || {}),
						// 	},
						// 	label: e?.comment,
						// 	value: e?.value,
						// 	component: DataTable,
						// 	filters: {
						// 		...(entity_fields?.filters || {}),
						// 		...(override_fields?.filters || {}),
						// 		active: { _eq: e.value === "active" ? true : false },
						// 	},
						// }));
						// break;
				// }
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields, 
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						// TableName: Freight,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
 
	);
};

export default List;
