import { gql } from "@apollo/client";

export const UPSERT_ONE = (entity, fields = " id ") =>
    gql`mutation insert_${entity}_one(   $object:${entity}_insert_input! ){

        object: insert_${entity}_one(object:$object,   on_conflict:{
                                                            constraint:${entity}_pkey
                                                            update_columns:[${fields}]
                                                        }
                                    ){${fields}}}`;


export const INSERT_ONE = (entity, fields = " id ") =>
    gql`mutation insert_${entity}_one($object:${entity}_insert_input!){object: insert_${entity}_one(object:$object){${fields}}}`;
export const UPDATE_ONE = (entity, fields = " id ") =>
    gql`mutation update_${entity}_by_pk($set:${entity}_set_input! $id:uuid!){object: update_${entity}_by_pk(pk_columns:{id:$id},_set:$set){${fields}}}`;
export const DELETE_ONE = (entity, fields = " id ") =>
    gql`mutation delete_${entity}_by_pk($id:uuid!){object: delete_${entity}_by_pk(id:$id){${fields}}}`;

export const INSERT_ENUM = (entity) =>
    gql`mutation insert_${entity}_one($object:${entity}_insert_input!){object: insert_${entity}_one(object:$object){value comment}}`;
export const UPDATE_ENUM = (entity) =>
    gql`mutation update_${entity}_by_pk($set:${entity}_set_input! $value:String!){object: update_${entity}_by_pk(pk_columns:{value:$value},_set:$set){value comment}}`;
export const DELETE_ENUM = (entity) =>
    gql`mutation delete_${entity}_by_pk($value:String!){object: delete_${entity}_by_pk(value:$value){value comment}}`;

export const INSERT_MANY = (entity, fields = " id ") =>
    gql`mutation insert_${entity}($objects:[${entity}_insert_input!]!){objects: insert_${entity}(objects:$objects){affected_rows returning{${fields}}}}`;
export const UPDATE_MANY = (entity, fields = " id ") =>
    gql`mutation update_${entity}($where:${entity}_bool_exp!,$set:${entity}_set_input!){objects: update_${entity}(where:$where,_set:$set){affected_rows returning{${fields}}}}`;
export const DELETE_MANY = (entity, fields = " id ") =>
    gql`mutation delete_${entity}($where:${entity}_bool_exp!){objects: delete_${entity}(where:$where){affected_rows returning{${fields}}}}`;
