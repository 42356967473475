import React from "react";
import { useForm } from "react-hook-form";
import OkImage from  '../../../assets/images/oclogo/cecs.png';
import VerificationSvg from  '../../../assets/images/brands/verification.svg';
import { useHistory } from "react-router-dom";
import { SendWebRequest } from "../common/sendRequest";
import { AuthContext } from "../../../context";



const OtpVerify = ({phoneNumber=null, onsubmit=null  }) =>  {

    const { register, handleSubmit,  formState } = useForm({mode: 'onSubmit', reValidateMode: 'onChange',});

    const {isDirty} = formState;

    const history = useHistory();

    const [timePeriod, setTimePeriod] = React.useState(20);

    const [buttonDisable, setButtonDisable] = React.useState(true);

    const [phone, ] = React.useState(phoneNumber);

    const { setAuthContext } = React.useContext(AuthContext);
 
    React.useEffect(() => {

        const timer = setTimeout(() => {

            if(timePeriod>0  ) {
                setTimePeriod(oldValue => oldValue - 1); 
            } 
            if(timePeriod===0 ){
                clearInterval(timer);
                setButtonDisable(false)
            }
              }, 1000);

          return () => {
            clearInterval(timer);
          };

    },[timePeriod]);

   const onSubmit = async (e) =>{

      // console.log("checking details ", e, phone);

      const phoneArrayNo = phone?.split("/");
      // console.log("phone array no", phoneArrayNo);

      let phoneNumber = {"phone": `+91${phone}`};


      if (phoneArrayNo?.length === 2) {
       
        phoneNumber = {"phone":`+91${phoneArrayNo[1]}`}
      }
     
      

       const response = await SendWebRequest({ method: 'POST', subUrl: '/user/verifyotp', body:  {...e, ...phoneNumber }, Smes: 'Successfully Verified!', Emes: (err) => err?.response?.data?.error?.message , });
       
        // console.log("response is---------",response, response?.data?.data?.user_info?.id,  response?.data?.data?.token, response?.data?.data?.user_info?.role);

      setAuthContext({
       customer_id: response?.data?.data?.user_info?.customer_id,
       user_id: response?.data?.data?.user_info?.id,
       id_token: response?.data?.data?.token,
       role: response?.data?.data?.user_info?.role,
      });
    
      // console.log("role is",`/web/${response?.data?.data?.user_info?.role}/list`)

      if (response) {
        history.replace(`/web/${response?.data?.data?.user_info?.role}/list`);
      }

      // history.replace(`/web/${response?.data?.data?.user_info?.role}/list`);


};


return  <><div className="vh-100  vw-100   row align-items-center justify-content-center bg-white">

<div className="row m-2 justify-content-around">
        <div className="col-12 col-sm-6  col-md-6 col-lg-6">
          <img src={VerificationSvg} className="img-fluid"  alt="Responsive" />   
        </div>

 <div className="  col-12 col-sm-6   col-md-6 col-lg-6 ">
      <div class="container mt-2" style={{margin:"0 auto"}} >
          <div className="float-right col-11 m-3  text-center">
              <img  src={OkImage} alt="Origin Konnect Logo" height="100px"></img>   
          </div>
                  
        <form class="needs-validation" onSubmit={handleSubmit(onSubmit)}  novalidate>
            <div class="row  justify-content-center align-items-center ">
                    <div className="col-6   col-sm-8 mb-3 ">
                    <h6    className="h6 small text-uppercase form-label text-success" >Enter Verification Code<span className="text-danger ml-2"> *</span></h6>
                    <input type="number" className={`col-12 col-sm-6  col-md-6 col-lg-6  p-1  border-1  p-2 form-control   `} name="otp"     ref={register({required:"Email is required", minLength: 2})} />
                    <div  className="text-danger">  { Error?.otp?.message} </div>
                    </div>
            </div>

            <div className="text-center col-sm-11 ">
                    <button type="submit"   className={` cursor-pointer btn btn-sm btn-success text-justify m-4 ps-4 pe-4 pt-2 pb-2 m-2 ${!isDirty ? "disabled" : ""}`}  >
                                Verify 
                    </button>
            </div>
        </form>
    </div>

      <h6 className="    text-center   text-success ">Not Received Code yet ? {
      !buttonDisable ? <span className="text-danger cursor" role="button" onClick={()=>{setTimePeriod(20);  setButtonDisable(true); onsubmit({"phone":phone})
      }}>Try Again</span> 
        :
        <span className="text-danger cursor ">{timePeriod}</span>
      }
      </h6>

</div>
</div>
</div>
</>  


};

export default OtpVerify;