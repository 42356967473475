import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { GeneralContext,AuthContext } from "../../../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
 
const List = () => {
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	const { enum_po_status } = useContext(GeneralContext);
    const { id_token, user_id } = useContext(AuthContext);
	let entity_fields = useWebEntityFields("po");
	console.error(solution)
	const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
    const override_fields = { overrideValues:{customer_id : object?.customer_id} ,filters:{customer_id :{_eq:object?.customer_id}}}
    const changeFields = {"location":{ filters: { customer_id: { _in: [ object?.customer_id] } }}}
	let neColumns = [];
	entity_fields?.columns?.forEach(e => {

		if(changeFields[e?.acessor])  return	neColumns.push({...e,...changeFields[e?.entity]})
	    return	neColumns.push(e)
	}
	)
    entity_fields = { ...entity_fields, "columns":neColumns, }
     
    //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
                                                                                                      
	return (
		<PageContainer
			breadcrumbTitle={getLabel(entity, true)}
			// breadcrumbOptions={["type", "active"]}
			breadcrumbArg={3}
			tab_arg={4}
			enabledispatchColumn={
				[
			    	{Header:"EXP PO",minWidth:100, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
					{Header:"OK-Invoice",minWidth:50, body:{col:"id",type:"OK Invoice", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",message:"Sucessfully Created!"}},            
					
					// {Header:"EXP-GRN",minWidth:100, body:{col:"id",type:"Exporter GRN",  sendEmail:"true" , },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}}  ,          
					// {Header:"OK PO",minWidth:100, body:{col:"id",type:"OK PO", sendEmail:"true"  },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob" ,message:"Sucessfully Created!"}}            
				]}	
			key={Math.random()}
			options={{ entity_fields}}
			// disableAction={false} njj
			disableEdit={false}
 			// disableExcel= {false}
			detail={page === "detail"}
			tab_options={(() => {
				let tab_options = [];
				switch (tab) {
					case "type":
						tab_options = !_.isArray(enum_po_status)
							? []
							: enum_po_status?.map((e) => ({
									...entity_fields,
									...override_fields,
									overrideValues: {
										...(entity_fields?.overrideValues || {}),
										...(override_fields?.overrideValues || {}),
									},
									label: e?.comment,
									value: e?.value,
									component: DataTable,
									filters: {
										...(entity_fields?.filters || {}),
										...(override_fields?.filters || {}),
										status: { _eq: e.value },
									},
							  }));
						break;
					default:
						tab_options = ENUM_ACTIVE.map((e) => ({
							...entity_fields,
							...override_fields,
							overrideValues: {
								...(entity_fields?.overrideValues || {}),
								...(override_fields?.overrideValues || {}),
							},
							label: e?.comment,
							value: e?.value,
							component: DataTable,
							filters: {
								...(entity_fields?.filters || {}),
								...(override_fields?.filters || {}),
								active: { _eq: e.value === "active" ? true : false },
							},
						}));
						break;
				}
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
 
	);
};

export default List;
