import React, { useState } from "react";
import { ChartCards } from "../../components/common/ChartsandGraphs";
import { FilterForm } from "../../components/common/FilterForm";
import { MetricCards } from "../../components/common/MetricCards";
import {
    getDateWhere,
    getLabel,
    useAggQuery,
    useAggQuerytEST,
    useAggQuerytESTdistinct,
    useCommonSubscription,
    // useObjectSubscription,
    useTotalSubscription,
 
} from "../../helpers";

const Detail = () => {
    const [days_to_show] = useState(30);
    const [inner_filters, set_inner_filters] = useState({});

    // const metric_offer_status = useCommonSubscription({ entity: "metric_offer_status" });
    const metric_active_status = useCommonSubscription({ entity: "metric_active_status" });
    const metric_order_status = useCommonSubscription({ entity: "metric_order_status" });
    const metric_active_commodities = useCommonSubscription({ entity: "metric_active_commodities" });
    // const metric_user_revenue_commodities = useCommonSubscription({ entity: "metric_new_user_revenue_created" });
    // const metric_new_user_quantity_created = useCommonSubscription({ entity: "metric_new_user_quantity_created" });
    const metric_repeated_user_revenue_created = useCommonSubscription({ entity: "metric_repeated_user_revenue_created" });

    const hels = useCommonSubscription({ entity: "metric_user_created" });


    const metric_offer_created = useCommonSubscription({
        entity: "metric_offer_created",
    });

    // console.log("offer created", metric_offer_created);
    const metric_order_created = useCommonSubscription({
        entity: "metric_order_created",
    });
    const metric_payment_created = useCommonSubscription({
        entity: "metric_payment_created",
        where: { ...getDateWhere(days_to_show), ...inner_filters },
    });

    // const metric_payment_status = useCommonSubscription({
    //     entity: "metric_payment_status",
    // });

    const metric_order_sep_status = useCommonSubscription({
        entity: "metric_order_sep_status",
    });

    // console.log("metric_order_sep_status", metric_order_sep_status);
 
 

    let checks = useCommonSubscription({
        entity: "metric_order_quanitity_created",
        fields: "id date count amount type",
        
    });

    
    return (
        <>
            <FilterForm fields={[]} onFilter={set_inner_filters} />
            <MetricCards
                options={[
                    {
                        // icon: <FcPackage />,
                        label: getLabel("offer", true),
                        link: "/marketplace/offer/list/active/all",
                        value: useTotalSubscription({
                            entity: "offer",
                            where: { active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "offer", where: {} }),
                    },
                    {
                        // icon: <FcBusiness />,
                        label: getLabel("order", true),
                        link: "/marketplace/order/list/active/all",
                        value: useTotalSubscription({
                            entity: "order",
                            where: { active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "order", where: {} }),
                    },
                    {
                        // icon: <FcBusinessContact />,
                        label: getLabel("user", true),
                        link: "/management/user/list/active/all",
                        value: useTotalSubscription({
                            entity: "user",
                            where: { active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "user", where: {} }),
                    },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Order", true),
                        type: "currency",
                        link: "/marketplace/order/list/active/all",
                        value: useAggQuery({ entity: "order",field:"buyer_commodity_charges", where: { active: { _eq: true } } }),
                        total: useAggQuery({ entity: "order",field:"buyer_commodity_charges", where: {} }),
                    },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Commission", true),
                        type: "currency",
                        link: "/marketplace/order/list/active/all",
                        value: useAggQuery({ entity: "order",field:"buyer_selling_price", where: { active: { _eq: true } } }),
                        total: useAggQuery({ entity: "order",field:"buyer_selling_price", where: {} }),
                    },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("payment_amount", true),
                        type: "currency",
                        link: "/marketplace/payment/list/active/all",
                        value: useAggQuery({ entity: "order_payment", where: { active: { _eq: true } } }),
                        total: useAggQuery({ entity: "order_payment", where: {} }),
                    },

                      {
                            // icon: <FcSalesPerformance />,
                          label: getLabel("On Commission Avg Amount of Repeated custom"),
                          type: "currency",
                        //   link: "/marketplace/payment/list/active/all",
                          value: useAggQuerytEST({entity: "metric_repeated_user_revenue_created",aggregate:"avg", where:{type:{_eq:"on_commission"}}}),
                        //   total: useAggregateSubscription({ entity: "metric_repeated_user_revenue_created", where: {} }),
                      },

                      {
                        // icon: <FcSalesPerformance />,
                      label: getLabel("On Order Avg Amount of Repeated custom"),
                      type: "currency",
                    //   link: "/marketplace/payment/list/active/all",
                      value: useAggQuerytEST({entity: "metric_repeated_user_revenue_created",aggregate:"avg", where:{type:{_eq:"on_order"}}}),
                    //   total: useAggregateSubscription({ entity: "metric_repeated_user_revenue_created", where: {} }),
                  },

                      
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Commission  Avg quantity Of new customers", true),
                        type:"weight",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_new_user_quantity_created",aggregate:"avg", where:{type:{_eq:"on_commission"}}}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Order Avg quantity Of new customers", true),
                        type:"weight",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_new_user_quantity_created",aggregate:"avg", where:{type:{_eq:"on_order"}}}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },

                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Commission Avg Revenue Of new customers ", true),
                        type:"currency",
                        // link: "/marketplace/payment/list/active/all",
                        // value:useAggQuery({ entity: "metric_new_user_revenue_created",aggregate:"avg", where:{type:{_eq:"on_commission"}}}),
                        value:useAggQuerytEST({ entity: "metric_new_user_revenue_created",aggregate:"avg",where:{
                            "type":{"_eq":"on_commission"}
                          }}),

                    },
       
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Order Avg Revenue Of new customers", true),
                        type:"currency",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_new_user_revenue_created",aggregate:"avg", where:{type:{_eq:"on_order"}}}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },

                
                     {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Commission no of cust who ord once in a month", true),
                        // type: "currency",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_cust_order_once_in_month",field:"count",aggregate:"sum", where:{type:{_eq:"on_commission"}}}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    }, 
                    
                    
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("On Order no of cust who ord once in a month", true),
                        // type: "currency",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_cust_order_once_in_month",field:"count",aggregate:"sum", where:{type:{_eq:"on_order"}}}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },
                    
                    
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("no of cust who haven't Order in a last 2 month", true),
                        // type: "currency",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_cust_havent_order_in_last_2months",field:"count",aggregate:"sum"}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },
                    // {
                    //     // icon: <FcSalesPerformance />,
                    //     label: getLabel("Amount haven't paid to oc(>15 days)", true),
                    //    type: "currency",
                    //     link: "/marketplace/payment/list/active/all",
                    //     value: useAggQuery({ entity: "view_order_payment_g15days_test",field:"orders",aggregate:"sum"}),
                    //     // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    // },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("Amount haven't paid to  oc(>15 days)", true),
                       type: "currency",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytEST({ entity: "metric_amount_havent_paid_gt15days", aggregate:"sum"}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("no of customers haven't paid to oc (>15 days)", true),
                        // type: "currency",
                        // link: "/marketplace/payment/list/active/all",
                        value: useAggQuerytESTdistinct({ entity: "metric_amount_havent_paid_gt15days",field:"count",aggregate:"count", }),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },
                    {
                        // icon: <FcSalesPerformance />,
                        label: getLabel("no of customers will pay in next 3 days", true),
                        type: "currency",
                        link: "/marketplace/orderpayment/list/status/all",
                        value: useAggQuerytEST({ entity: "metric_payments_in_three_days",field:"count",aggregate:"sum"}),
                        // total: useAggQuery({ entity: "metric_new_user_quantity_created", where: {} }),

                    },
                    
                ]}
            />
            <ChartCards
                options={[
                    {
                        header: getLabel("Amount by status", true),
                        header_link: "/marketplace/offer/list/active/all",
                        type: "donut",
                        series:React.useMemo(()=> metric_order_sep_status?.map((e) => e?.amount ?? 0) ?? []    ,[metric_order_sep_status]) ,
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: React.useMemo(()=>metric_order_sep_status?.map((e) => e?.type.toUpperCase()?.replace("_"," ")) ?? []    ,[metric_order_sep_status]) ,
                        },

                        colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
                    },
                    
                    {
                        header: getLabel("offer", true),
                        header_link: "/marketplace/offer/list/active/all",
                        type: "donut",
                        series:React.useMemo(()=> metric_active_status?.map((e) => e?.count ?? 0) ?? []     ,[metric_active_status]),
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: React.useMemo(()=> metric_active_status?.map((e) => e?.type?.toUpperCase() ==="TRUE" ? "ACTIVE" : "NON - ACTIVE") ?? []   ,[metric_active_status]) ,
                        },

                        colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
                    },
                    {
                        header: getLabel("order", true),
                        header_link: "/marketplace/order/list/active/all",
                        type: "donut",
                        series:React.useMemo(()=>metric_order_status?.map((e) => e?.count ?? 0) ?? []     ,[metric_order_status]) ,
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: React.useMemo(()=> metric_order_status?.map((e) => e?.type?.toUpperCase()) ?? []    ,[metric_order_status]),
                        },

                        colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
                    },
                    {
                        header: getLabel("Active Commodities", true),
                        header_link: "/marketplace/offer/list/active/all",
                        type: "donut",
                        series:React.useMemo(()=> metric_active_commodities?.map((e) => e?.count ?? 0) ?? []    ,[metric_active_commodities]),
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: React.useMemo(()=> metric_active_commodities?.map((e) => e?.type?.toUpperCase()) ?? []   ,[metric_active_commodities]) ,
                        },

                        colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
                    },
                  
             
                    {
                        header: "Source volume",               
                        type: "area",
                        stroke: {
                            curve: "smooth",
                        },
                        options: {
                            stroke: {
                                curve: "smooth",
                            },
                            dataLabels: {
                                enabled:false
                            },
                            chart: {
                                height: 350,
                                type: 'area',
                                zoom: {
                                    autoScaleYaxis: true,
                                },
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories:React.useMemo(()=>[...new Set(checks?.map((e) => e?.date))] ?? [],[checks]) ,
                                type: 'datetime'

                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                            {
                                name: getLabel("Onion", true),
                                // data: React.useMemo(()=>firstTest?.map((e) => e?.amount ?? 0) ?? [firstTest])  ,
                                data: React.useMemo(()=>checks.filter(e=>e?.type !== "Garlic" && e?.type !== "Potato")?.map((e) => e?.amount ?? 0)  , [checks])  ,
                                // data: test?.map((e) => e?.amount ?? 0) ?? []
                            },
                            {
                                name: getLabel("Garlic", true),
                                // data: React.useMemo(()=>secondTest?.map((e) => e?.amount ?? 0) ?? [secondTest]),
                                
                                data: React.useMemo(()=>checks.filter(e=>e?.type !== "Onion" && e?.type !== "Potato")?.map((e) => e?.amount ?? 0)  , [checks])  ,
                                // data: test?.map((e) => e?.amount ?? 0) ?? [],
                            },
                            {
                                name: getLabel("Potato", true),
                                data: React.useMemo(()=>checks.filter(e=>e?.type !== "Onion" && e?.type !== "Garlic")?.map((e) => e?.amount ?? 0)  , [checks])  ,
                               
                             
                            },
                        ],

                         colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                       // colSpan: `col-xl-6 col-lg-6 col-md-6 col-sm-12`
                    },{
                        header: "Orders & Offers By  Date",
                        type: "area",
                        options: {
                            stroke: {
                                curve: "smooth",
                            },
                            dataLabels: {
                                enabled:false
                            },
                            chart: {
                                height: 350,
                                type: 'area',
                                zoom: {
                                    autoScaleYaxis: true,
                                },
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories: metric_order_created?.map((e) => e?.date) ?? [],
                                type: 'datetime'

                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                            {
                                name: getLabel("order", true),
                                data: React.useMemo(()=>metric_order_created?.map((e) => e?.count ?? 0) , [metric_order_created]),
                            },
                            {
                                name: getLabel("offer", true),
                                data: React.useMemo(()=>metric_offer_created?.map((e) => e?.count ?? 0) , [metric_offer_created]),
                            },
                             
                           
                        ],

                          colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                       // colSpan: `col-xl-6 col-lg-6 col-md-6 col-sm-12`
                    },
                    
                    // {
                    //     header: "New users Revenue Month",
                    //     type: "area",
                    //     stroke: {
                    //         curve: "smooth",
                    //     },
                    //     options: {
                    //         stroke: {
                    //             curve: "smooth",
                    //         },
                    //         dataLabels: {
                    //             enabled:false
                    //         },
                    //         chart: {
                    //             height: 350,
                    //             type: 'area',
                    //             zoom: {
                    //                 autoScaleYaxis: true,
                    //             },
                    //         },
                    //         responsive: [
                    //             {
                    //                 breakpoint: 480,
                    //                 options: {
                    //                     legend: {
                    //                         position: "bottom",
                    //                         offsetX: -10,
                    //                         offsetY: 0,
                    //                     },
                    //                 },
                    //             },
                    //         ],
                    //         xaxis: {
                    //             categories:React.useMemo(()=>[...new Set(metric_user_revenue_commodities?.map((e) => e?.date))] ?? [],[metric_user_revenue_commodities]) ,
                    //             // type: 'date'
                           
                    //         },
                    //         fill: {
                    //             opacity: 1,
                    //         },
                    //     },
                    //     series: [
                    //         {
                    //             name: getLabel("On Order", true),
                    //             data:React.useMemo(()=> metric_user_revenue_commodities?.filter((e) => e?.type !== "on_commission" ).map(e=>e?.amount ?? 0) ?? [],[metric_user_revenue_commodities]),
                    //         },
                    //         {
                    //             name: getLabel("On Commission", true),
                    //             data:React.useMemo(()=>  metric_user_revenue_commodities?.filter((e) => e?.type !== "on_order" ).map(e=>e?.amount ?? 0) ?? [],[metric_user_revenue_commodities]),
                    //         },
                    //     ],

                    //     // colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                    //     colSpan: `col-xl-6 col-lg-6 col-md-6 col-sm-12`
                    // },

                    // {
                    //     header: "New users Quantity per Month",
                    //     type: "area",
                    //     stroke: {
                    //         curve: "smooth",
                    //     },
                    //     options: {
                    //         stroke: {
                    //             curve: "smooth",
                    //         },
                    //         dataLabels: {
                    //             enabled:false
                    //         },
                    //         chart: {
                    //             height: 350,
                    //             type: 'area',
                    //             zoom: {
                    //                 autoScaleYaxis: true,
                    //             },
                    //         },
                    //         responsive: [
                    //             {
                    //                 breakpoint: 480,
                    //                 options: {
                    //                     legend: {
                    //                         position: "bottom",
                    //                         offsetX: -10,
                    //                         offsetY: 0,
                    //                     },
                    //                 },
                    //             },
                    //         ],
                    //         xaxis: {
                    //             categories: [...new Set(metric_new_user_quantity_created?.map((e) => e?.date))] ?? [],
                    //             type: 'date'

                    //         },
                    //         fill: {
                    //             opacity: 1,
                    //         },
                    //     },
                    //     series: [
                    //         {
                    //             name: getLabel("On Order", true),
                    //             data: React.useMemo(()=>metric_new_user_quantity_created?.filter((e) => e?.type !== "on_commission" ).map(e=>e?.amount ?? 0) ?? [],[metric_new_user_quantity_created]),
                    //         },
                    //         {
                    //             name: getLabel("On Commission", true),
                    //             data: React.useMemo(()=>metric_new_user_quantity_created?.filter((e) => e?.type !== "on_order" ).map(e=>e?.amount ?? 0)?? [],[metric_new_user_quantity_created]),
                    //         },
                    //     ],

                    //     // colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                    //     colSpan: `col-xl-6 col-lg-6 col-md-6 col-sm-12`
                    // },
                    {
                        header: "Users Signups",
                        type: "line",
                        stroke: {
                            curve: "smooth",
                        },
                        options: {
                            stroke: {
                                curve: "smooth",
                            },
                            dataLabels: {
                                enabled:false
                            },
                            chart: {
                                height: 350,
                                zoom: {
                                    autoScaleYaxis: true,
                                },
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories: hels?.map((e) => e?.date) ?? [],
                                type: 'datetime'

                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                            {
                                name: getLabel("order", true),
                                data: hels?.map((e) => e?.count ?? 0) ?? [],
                            },
                        ],

                        // colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                        colSpan: `col-xl-8 col-lg-6 col-md-6 col-sm-12`
                    },


                    {
                        header: "Repeated users Revenue per Month",
                        type: "area",
                        stroke: {
                            curve: "smooth",
                        },
                        options: {
                            stroke: {
                                curve: "smooth",
                            },
                            chart: {
                                height: 350,
                                type: 'area',
                                zoom: {
                                    autoScaleYaxis: true,
                                },
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories: [...new Set(metric_repeated_user_revenue_created?.map((e) => e?.date))] ?? [],
                                type: 'datetime'

                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                            {
                                name: getLabel("On Order", true),
                                data: React.useMemo(()=>metric_repeated_user_revenue_created?.filter((e) => e?.type !== "on_commission" ).map(e=>e?.amount ?? 0)  ?? [],[metric_repeated_user_revenue_created]) 
                            },
                            {
                                name: getLabel("On Commission", true),
                                data: React.useMemo(()=>metric_repeated_user_revenue_created?.filter((e) => e?.type !== "on_order" ).map(e=>e?.amount ?? 0)  ?? [],[metric_repeated_user_revenue_created])
                            },
                        ],

                        // colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                        colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`
                    },
                    {
                        header: "Payments By Created Date",
                        type: "area",
                        options: {
                            stroke: {
                                curve: "smooth",
                            },
                            dataLabels: {
                                enabled:false
                            },
                            chart: {
                                height: 350,
                                type: 'area',
                                zoom: {
                                    autoScaleYaxis: true,
                                },
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories: metric_payment_created?.map((e) => e?.date) ?? [],
                                type: 'datetime'

                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                        
                            {
                                name: getLabel("payment", true),
                                data: metric_payment_created?.map((e) => e?.count ?? 0) ?? [],
                            },
                        ],

                          colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                       // colSpan: `col-xl-6 col-lg-6 col-md-6 col-sm-12`
                    },

                    

                   
                    
                   
                ]}
            />
        </>
    );
};

export default Detail;
