import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { DataForm } from "../../../../../components/common/DataForm";
import { ImageModal } from "../../../../../components/common/ImageModal";
import {
    BOOLEAN_LIST,
    ENUM_LIST,
    ENUM_STATUS,
    getCalculatedRows,
    getJobCardFields,
    getLabel,
    getLoadingFields,
    getOfferFields,
    getOrderFields,
    getOrderPriceFields,
    getPaymentFields,
    getUnLoadingFields,
    getWeighingFields,
    InvoiceButton,
    UI_DATETIME_FORMAT,
    UI_DATE_FORMAT,
} from "../../../../../helpers";
import { SendWebRequest } from "../../../common/sendRequest";
  
  const CardInfo = ({
        entity = "user",
        object = {},
        columns = [],
        title_field = "name",
        disableEdit = false,
        refetch = () => {},
        solution,
        queryTotal,
        label,
        modalValue=null,
        disableAdd,
        overrideValues={},

    ...rest

}) => {
    const new_value = object?.new_value ?? {};

      console.log("new values", object, "->",new_value,rest);


      const update_bl_status = async ()=>{
         
        await  SendWebRequest({method:"POST",subUrl:"/user/bl_status",body:{id:object?.id},Emes: (err) => `${err?.response?.data?.error?.message}`,Smes: 'Sucessfully updated'})


      }

      const shipping_con_body = {obj:"shipping_containers", arr: [{key:"uid",value:"uid"},{key:"seal_no",value:"seal_no"},{key:"container_no",value:"container_no"}]};

      const shipping_ins_body = { obj:"shipping_instructions", arr: [{key:"uid",value:"uid"}, {key:"bookingno",value:"bookingno"}, {key:"container_no",value:"container_no"}, {key:"consigneenameandaddress",value:"consigneenameandaddress"}, {key:"emailaddress",value:"emailaddress"}, {key:"freightcharges",value:"freightcharges"}, {key:"marksandnumbers",value:"marksandnumbers"}, {key:"measurement",value:"measurement"}, {key:"notifypartynameandaddress",value:"notifypartynameandaddress"}, {key:"numberandkindofpackagesdescriptionofgoods",value:"numberandkindofpackagesdescriptionofgoods"}, {key:"numberoforiginals",value:"numberoforiginals"}, {key:"placeofdelivery",value:"placeofdelivery"}, {key:"placeofreceipt",value:"placeofreceipt"}, {key:"portofdischarge",value:"portofdischarge"}, {key:"portofloading",value:"portofloading"}, {key:"shippernameandaddress",value:"shippernameandaddress"}, {key:"shippingline",value:"shippingline"}, {key:"telno",value:"telno"}, {key:"totalnumberofcontainer",value:"totalnumberofcontainer"}, {key:"vessel",value:"vessel"}, {key:"weightinkgormt",value:"weightinkgormt"}, ]};
     
      const header = ({arr,disableAdd=false,entityName,label,showTitle=false,defaultValues={}, overrideValuess={}}) => {
       return   arr?.length>0&&[...arr?.map((e,i)=>{
                return    <div className="col">
                            <div className="p-1">
                                <span className="h5">{e?.["key"]} </span>
                                <span className="text-muted h5">   {e?.["value"] ? e?.["value"] : ""}</span>
                                </div>
                            </div>
                     }),
    
                <div className="col">
                    <div className="row justify-content-end">
                    <div className="col-auto">
                        {!disableAdd  && (  
                                <DataForm
                                    {...rest}
                                    key={ (label ||  entityName) + 'add'}
                                    entity={entityName}
                                    modalValue={modalValue}
                                    showTitle={label}
                                    // columns={columns}
                                    defaultValues={defaultValues}
                                    overrideValues={{...overrideValuess}}
                                    refetch={refetch}
                                />
                        )}
                    
                    </div>
                    </div>
               </div>
              ]

    
      }


      const body = ({obj, arr}) =>{

        return   object?.[obj]?.map((e,i)=>{
                                return <div className="row border-bottom"> 
                                     {[...arr?.map((ee,ii)=>{
                                            return  <div className="col-md-4 col-sm-12 ">
                                                        <div className=" p-2">
                                                            <span className="text-muted">{getLabel(ee?.key)}</span>
                                                            <span className="float-end h6">{ e[ee?.value]} </span>
                                                        </div>   
                                                      
                                            </div>
                                       })
                                      ,
                                      <div className="card-footer p-3">
                                      <div className="row">
                                          {e?.created_at && (
                                              <div className="col-md-4 col-sm-12">
                                                  <div className="small text-muted text-uppercase">{getLabel("created_at")}</div>
                                                  <div className="float-left h6">{moment(e?.created_at).format(UI_DATETIME_FORMAT)}</div>
                                              </div>
                                          )}
                                          {e?.updated_at && (
                                              <div className="col-md-4 col-sm-12">
                                                  <div className="small text-muted text-uppercase">{getLabel("updated_at")}</div>
                                                  <div className="float-left h6">{moment(e?.updated_at).format(UI_DATETIME_FORMAT)}</div>
                                              </div>
                                          )}
                                  
                                      </div>
                                  </div>
                                      
                                      ]}
                                  
                               </div>
                               
                    
                    })  
 
      }
 
      const body_with_img = (item) =>{
      
      return   [item].map((e, i) => !_.isEmpty(object?.[e])  ? (
               <div key={i} className="p-2">
                    <div className="h6">
                        <ImageModal
                            key={object?.id}
                            imageHeight={80}
                            url={object?.[e]}
                            privateImage={true}
                            type="document"
                            title={_.get(object, title_field)}
                        />
                    </div>
            </div>
                ) : (
                    <span key={Math.random()} />
                )
           )
      }

 
        return (<div>
                    <div key={Math.random()} className="card">
                        <div key={entity + 'header'}> 
                            <div className="row card-header">
                                {header({arr: [ {key:"Booking Validity", value:object?.["shipping_containers"]?.length}, {key:"Date", value:object?.booking_validity}, {key:"status", value:object?.["booking_validity_status"] } ], entityName:"shipping_containers", overrideValuess:{...overrideValues,} }) }
                            </div>
                        </div>
                        <div className="card-body p-2 row">
                               {body(shipping_con_body)}
                        </div>
                    </div>
                  
                    <div key={Math.random()} className="card">
                        <div key={entity + 'header'}> 
                            <div className="row card-header">
                               {header({ arr:[ {key:"ShippingInstructions", value:object?.["shipping_instructions"]?.length}, {key:"Date", value:object?.shipping_instructions_cut_off}, {key:"status", value: object?.["shipping_ins_status"] } ], entityName:"shipping_instructions",overrideValuess:{...overrideValues} }) }
                            </div>
                           <div className="card-body p-2">
                              {body(shipping_ins_body)}
                           <div key={Math.random()} className="card">
                           
                           {object?.draft_bl_slip?.length>0 ? <div key={entity + 'header'}> 
                                <div className="row card-header"> {header({arr:[{key:"DraftBL"  }], disableAdd:true})} </div>
                                <div className="row d-flex card-body">
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>Download/preview</div>   
                                           {body_with_img("draft_bl_slip")}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-evenly align-items-center">
                                        <button type="button" class="btn btn-primary"  disabled={object?.bl_approved} onClick={update_bl_status}>Approve BL</button>
                                        {!object?.bl_approved ? header({arr: [ {key:"", value:""},], entityName:"order_note",label:"Reject BL  Notes", overrideValuess:{order_id:object?.id,customer_id:object?.export_freight.customer?.id} }) :"" } 
                                        </div>
                                    </div>
                                </div>
                                
                               </div> : ""}
                              </div>
                            </div>
                        </div>
                    </div>

                    <div key={Math.random()} className="card">
                        <div key={entity + 'header'}> 
                            <div className="row card-header">
                                {header({ arr:[{key:"VGMCuttOff(PDF)"}, {key:"Date", value:object?.vgm_cut_off_date}, {key:"status", value:object?.["vgm_status"] } ] , entityName:"export_freight_order", label:"VGMCuttOff(Pdf)", defaultValues:object }, )}
                            </div>
                        </div>
                        <div className="card-body p-2 row">
                        {object?.vgm_cut_off?.length>0  ? <div className="row d-flex card-body">
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-between align-items-center"> <div>Download/preview</div> 
                                         {body_with_img("vgm_cut_off")} 
                                       </div> 
                                   </div>
                                       <div className="col-md-4"> </div>
                                </div> : ""}
                        </div>
                    </div>
                    
 
                    <div key={Math.random()} className="card">
                        <div key={entity + 'header'}> 
                            <div className="row card-header">
                                {header({ arr:[{key:"GateCutOff(PDF)"}, {key:"GateOpens", value:object?.gate_opens}, {key:"Gate in CutOf", value:object?.gate_in_cut_off_date} ] , entityName:"export_freight_order", label:"Gate CutOff(Pdf)", defaultValues:object }, )}
                             </div>
                        </div>
                        <div className="card-body p-2 row">
                        {object?.gate_cut_off?.length>0 ? <div className="row d-flex card-body">
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>Download/preview</div> { body_with_img("gate_cut_off")} </div>
                                    </div>
                                    <div className="col-md-4"> </div>
                                </div> : ""}
                        </div>
                    </div>
 
                    <div key={Math.random()} className="card">
                            <div key={entity + 'header'}> 
                                <div className="row card-header"> {header({arr:[{key:"BL"  }], disableAdd:true})} </div>
                                {object?.bl_slip ? <div className="row d-flex card-body">
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-between align-items-center"> <div>Download/preview</div> 
                                        {body_with_img("bl_slip")} 
                                        </div> </div>
                                   </div> : ""}
                                
                            </div>
                            </div>



                            <div key={Math.random()} className="card">
                            <div key={entity + 'header'}> 
                                <div className="row card-header">  
                                {header({arr:[{key:"Invoice"},{key:"Payment status", value:object?.invoice_no ? "completed" : "pending"},],disableAdd:true})}
                                
                                  </div>
                                {object?.invoice_no?.length>0  ? <div className="row d-flex card-body">
                                    <div className="col-md-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                        <div>Download/preview</div>   
                                           {body_with_img("invoice")}
                                        </div>
                                  
                                    </div>

                                    <div className="col-md-4">
                                        <div className=" ">
                                        <div><span>Amount </span>&nbsp;<strong>{object?.invoice_amount}</strong></div>   
                                        <div><span>UTR </span>&nbsp;<strong>{object?.invoice_no}</strong></div>   
                                        {/* <div><span>Date </span><strong>{object?.invoice_no}</strong></div>    */}
                                        </div>
                                  
                                    </div>
                                    
                                </div> : ""}
                                
                            </div>
                            </div>
                    
                </div>

        );
};


export default CardInfo;