import React from "react";
import { DataForm } from "../../../../../components/common/DataForm";

export const ListForm = ({...rest}) => {

    return <>
     <DataForm {...rest} saveLabel="SUBMIT" classes="align-center" showClose={false} showClear={false} showForm={true}>
        
     </DataForm>
    </>
}