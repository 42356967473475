import _ from "lodash";
import React, { useContext } from "react";
import { Button } from "reactstrap";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { GeneralContext,AuthContext } from "../../../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import { ListForm } from "./listForm";
import RowChild from './rowChild';

const List = () => {

	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	// const { enum_po_status } = useContext(GeneralContext);
    const {  user_id , id_token} = useContext(AuthContext);
	let entity_fields = useWebEntityFields("bidding_importer");
    console.log("checking entity ===2", entity_fields)
    //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;

	const {fields} = useEntityFields({ entity:"customer" });

	// let fieldsa =  fields+ `customer{id name}`
     
 	const { object, error } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }),});
 	console.log("ASDASDkjbs",object,  )
    // const override_fields = { overrideValues:{importer_id : object?.customer_id} ,filters:{importer_id :{_eq:object?.customer_id}} }
    const override_fields = { overrideValues:{importer_id : object?.customer_id} ,filters:{importer_id :{_eq:object?.customer_id}} }
 
 

 
	return (
		<PageContainer
			// breadcrumbTitle={getLabel("Freight Options", true)}
			// breadcrumbOptions={["type", "active"]}	
			// breadcrumbArg={3}
			tab_arg={4}
			key={Math.random()}
			showBreadCrumb={false}
			options={{ entity_fields}}
            disableAdd={true}
			disableAction={true}
			disableEdit={false}
 			searchableColumns={false}
 			// showFilterForm={true}
			detail={page === "detail"}
			tab_options={(() => {
				let tab_options = [];
				// switch (tab) {
				// 	case "type":
				// 		tab_options = !_.isArray(enum_po_status)
				// 			? []
				// 			: enum_po_status?.map((e) => ({
				// 					...entity_fields,
				// 					...override_fields,
				// 					overrideValues: {
				// 						...(entity_fields?.overrideValues || {}),
				// 						...(override_fields?.overrideValues || {}),
				// 					},
				// 					label: e?.comment,
				// 					value: e?.value,
				// 					component: DataTable,
				// 					filters: {
				// 						...(entity_fields?.filters || {}),
				// 						...(override_fields?.filters || {}),
				// 						status: { _eq: e.value },
				// 					},
				// 			  }));
				// 		break;
				// 	default:
				// 		tab_options = ENUM_ACTIVE.map((e) => ({
				// 			...entity_fields,
				// 			...override_fields,
				// 			overrideValues: {
				// 				...(entity_fields?.overrideValues || {}),
				// 				...(override_fields?.overrideValues || {}),
				// 			},
				// 			label: e?.comment,
				// 			value: e?.value,
				// 			component: DataTable,
				// 			filters: {
				// 				...(entity_fields?.filters || {}),
				// 				...(override_fields?.filters || {}),
				// 				active: { _eq: e.value === "active" ? true : false },
				// 			},
				// 		}));
				// 		break;
				// }
				tab_options = [
					{
						label: "",
						value: "all",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
 
	);
};

export default List;
