import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { DetailInfo } from "../../../../../components/common/DetailInfo";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { AuthContext } from "../../../../../context";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();

 
	let entity = "user_manage_order";
	const {   user_id } = useContext(AuthContext);
     //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    // console.log("updated_enum_freight_statsus",enum_freight_order_status)
	const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });

	const { object:ObjectQ } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity}) });



	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={ObjectQ?.name}
			component={DetailInfo}
			entity={"customer"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				{
                    label: getLabel("shipping_instructions", true),
                    value: ObjectQ?.active_contacts?.aggregate?.count || 0,
                    total: ObjectQ?.total_contacts?.aggregate?.count || 0,
                },
                {
                    label: getLabel("order_note", true),
                    value: ObjectQ?.active_locations?.aggregate?.count || 0,
                    total: ObjectQ?.total_locations?.aggregate?.count || 0,
                },
			]}
			tab_options={[
				{
					...useWebEntityFields( "shipping_instructions"),
					label: getLabel("shipping_instructions", true),
					value: "shipping_instructions",
                    disableAdd:true,
					disableEdit:true,
					disableAction:true,
					component: DataTable,
					component: DataTable,
					filters: { "order_id":{_eq:parent_id} },
					overrideValues: { customer_id: object?.customer_id , "order_id":parent_id},
					skipColumns: ["user"],
				},	{
					...useEntityFields({ entity: "order_note"}),
					label: getLabel("order_note", true),
					value: "order_note",
                    disableAdd:true,
					disableEdit:true,
					disableAction:true,
					component: DataTable,
					filters: {"order_id":{_eq:parent_id}},
					overrideValues: { customer_id: object?.customer_id , "order_id":parent_id},
					skipColumns: ["user"],
				},	
				{
				...useWebEntityFields("shipping_containers"),
				label: getLabel("shipping_containers", true),
				value: "shipping_containers",
				disableAdd:true,
				disableEdit:true,
				disableAction:true,
				component: DataTable,
				filters: {"order_id":{_eq:parent_id}},
				overrideValues: { customer_id: object?.customer_id , "order_id":parent_id},
				skipColumns: ["user"],
			},	
			 
				 
			]}
		/>
	);
};

export default Detail;
