import React, { useState }  from "react"; 
import NumberFormat from "react-number-format";
 
import { SimpleTable } from "../../../../../components/common/SimpleTable";
import { ENUM_ACTIVE } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import moment from "moment";


const ListRowChild =   ({obj,props}) => {
 

    const [counter, setCounter] = useState(1);

     const object = obj?.object?.quotations?.[0]

     if(!obj?.object?.quotations?.[0]) return <div className="p-2 text-danger "  style={{ background: "whitesmoke" }}>
     <p className="text-center m-auto">Quotation not available</p>
  </div>

    console.log("props ",obj, object);
	 
     const total_amount =  
      
       (0.18*object?.basic_freight_in_usd)
     + ((0.18*object?.temp_variation_charges) || 0)
     + ((0.18*object?.muf) || 0)
    //  + (0.18*object?.rate)
     + ((0.18*object?.terminal_handling_charges) || 0)
    //  + (0.18*object?.bl_fee)
     + (0.18*object?.seal_charges)
     + (0.18*object?.toll_charges);

     console.log("Total amount", total_amount,object?.ocean_freight,object?.temp_variation_charges,object?.muf,object?.rate
     ,object?.terminal_handling_charges,object?.bl_fee,  object?.seal_charges,object?.toll_charges );

	return (

<div class="container-fluid shadow  vw-80 py-2">
    
      <div class=" mb-1    " style={{color:"#34C38F"}}>Breakup (FCL Freight Local Charges)</div>
      
      <div className="row">
            
             <div className="col">

                  <div class="row">
                    <div class="col text-start font-weight-bold"><b>Ocean freight(USD)</b>:</div>
                    <div class="col text-start">
                                            
                    <NumberFormat
                                    value={ object?.basic_freight_in_usd?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                   </div>
                  </div>
                  <div class="row">
                   <div class="col  text-start font-weight-bold"><b>Ex rate(INR)</b>:</div>
                   <div class="col  text-start">
                   <NumberFormat
                                    value={ object?.exchange_rate_in_inr?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                   </div>
               </div>
                  

             </div>
             <div className="col">
             <div class="row">
                    <div class="col text-start font-weight-bold"><b>Temp variation Charges(INR)</b>:</div>
                    <div class="col text-start">
                      
                    <NumberFormat
                                    value={ object?.temp_variation_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    </div>


                   </div>

                   <div class="row">
                   <div class="col  text-start font-weight-bold"><b>THC (INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.terminal_handling_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                    
                                    </div>
               </div>  
               <div class="row">
                   <div class="col  text-start font-weight-bold"><b>ETA</b>:</div>
                   <div class="col text-start">{moment(object?.eta)?.format("MMM Do YY")}
                   {/* <NumberFormat
                                    value={ object?.terminal_handling_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> */}
                                    
                                    
                                    </div>
               </div>  
               <div class="row">
                   <div class="col  text-start font-weight-bold"><b>ETD</b>:</div>
                   <div class="col text-start">{moment(object?.etd)?.format("MMM Do YY")}
                   {/* <NumberFormat
                                    value={ object?.terminal_handling_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />*/}
                                    
                                    
                                    </div> 
               </div>  
               <div class="row">
                   <div class="col  text-start font-weight-bold"><b>BL Fee(INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.b_l_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                   </div>
               </div>

             </div>
             <div className="col">

             <div class="row">
                    <div class="col  text-start font-weight-bold"><b>MUC(INR)</b>:</div>
                    <div class="col text-start">
                    <NumberFormat
                                    value={ object?.muf?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                     </div>
                    
                   </div>

                   <div class="row">
                   <div class="col  text-start font-weight-bold"><b>Toll Charges(INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.toll_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                    </div>
               </div>

               <div class="row">
                   <div class="col  text-start font-weight-bold"><b>Seal Charges(INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.seal_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                
                                 </div>
               </div>



               <div class="row">
                   <div class="col text-start"><b>Total Amount(INR)</b> :</div>
                   <div class="col text-start"><strong>
                   <NumberFormat
                                    value={((object?.basic_freight_in_usd*object?.basic_freight_in_usd) + 
                                     ( object?.temp_variation_charges || 0) + 
                                      (object?.terminal_handling_charges || 0) + 
                                      (object?.bill_of_lading_charges || 0)+
                                      (object?.muf || 0) + 
                                      (object?.toll_charges || 0) + 
                                      (object?.seal_charges || 0))?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />

</strong>
                        
                        </div>
               </div>
               

             </div>

      </div>
      
  
 
</div>
	);
};
export default ListRowChild;
