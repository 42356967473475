import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({
        id: parent_id,
        ...useEntityFields({ entity, parent_id, solution }),
    });

    // console.log("Detail of export", object);
    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            component={DetailInfo}
            entity={entity}
            list_options={[]}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            // excelSubUrl = "user/UploadExcelData" 
			// excelResponseType = "blob" 
			// excelDocType = "pdf" 
            metric_cards={[]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "location", parent_id, solution }),
                    label: getLabel("Location", true),
                    value: "Location",
                    disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { id: { _eq: object?.location_id } },
                    overrideValues: { id: object?.location_id },
                    // showList: true,
                },

                {
                    ...useEntityFields({ entity: "location", parent_id, solution }),
                    label: getLabel("Origin Port", true),
                    value: "Origin Port",
                    disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { id: { _eq: object?.origin_port_id } },
                    overrideValues: { id: object?.origin_port_id },
                    // showList: true,
                },

                {
                    ...useEntityFields({ entity: "location", parent_id, solution }),
                    label: getLabel("Destination Port", true),
                    value: "Destination Port",
                    disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { id: { _eq: object?.destination_port_id } },
                    overrideValues: { id: object?.destination_port_id },
                    // showList: true,
                },
                {
                    ...useEntityFields({ entity: "export_order_payment", parent_id, solution }),
                    label: getLabel("export_order_payments", true),
                    value: "export_order_payments",
                    hideFilter: true,
                    component: DataTable,
                    filters: { export_order_id: { _eq: object?.id } },
                    overrideValues: { export_order_id: object?.id },
                    skipColumns: ["offer"],
                    // showList: true,
                },
                // {
                //     ...useEntityFields({ entity: "order_payment", parent_id, solution }),
                //     label: getLabel("order_payment", true),
                //     value: "order_payment",
                //     // // disableAdd: true,
                //     hideFilter: true,
                //     component: DataTable,
                //     filters: { order_id: { _eq: parent_id } },
                //     overrideValues: { order_id: parent_id },
                //     skipColumns: ["order"],
                //     showList: true,
                // },
                // {
                //     ...useEntityFields({ entity: "jobcard", parent_id, solution: "trade" }),
                //     label: getLabel("jobcard", true),
                //     value: "jobcard",
                //     hideFilter: true,
                //     component: DataTable,
                //     filters: { order_id: { _eq: parent_id } },
                //     overrideValues: { order_id: parent_id },
                //     skipColumns: ["order"],
                //     showList: true,
                // },
            ]}
        />
    );
};

export default Detail;
