import React, { useEffect } from "react";
import {useHistory } from "react-router-dom";
import { AuthContext } from "../../../../context";

const  HomePage = () =>{

    let history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const { user_id, is_authenticated, setAuthContext, id_token, user, logoutUser ,role} = React.useContext(AuthContext);

    useEffect(()=>
    {
        logoutUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


return <div>
    <div onClick={() => history.push('/auth/login')}>Web Admin</div>
    <div onClick={() => history.push('/user/auth/login')}>Web App</div>
</div>

};

export default HomePage;