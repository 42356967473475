import { ApolloClient, ApolloProvider, gql, HttpLink, InMemoryCache, split, useQuery } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import storage from "store2";
import { usePosition } from "use-position";
import validate from "uuid-validate";
import "./assets/scss/theme.scss";
import HorizontalLayout from "./components/horizontalLayout";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/verticalLayout";
import { APP_CONFIG } from "./config";
import { AuthContext, GeneralContext } from "./context";
import { ENUM_LIST, getLabel, getParams, useObjectByPkSubscription } from "./helpers";
import "./i18n";
import { adminAuthRoutes, adminNonAuthRoutes,  userNonAuthRoutes } from "./routes";
import AuthMiddleware, { NonAuthmiddleware } from "./routes/middleware";

export const useApolloClient = (id_token) => {
    let headers = { "content-type": "application/json" };
    // "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWRtaW4iLCJtb2JpbGUiOiIrOTE5MDAwMDAwMDE4IiwiZW1haWwiOiJhZG1pbkBvcmlnaW5rb25uZWN0LmluIiwicm9sZSI6Im1vZGVyYXRvciIsInBob3RvX3VybCI6bnVsbCwiY3JlYXRlZF9hdCI6IjIwMjEtMDgtMDRUMDk6NTU6MDYuMzMwWiIsInVwZGF0ZWRfYXQiOiIyMDIxLTA4LTA0VDA5OjU1OjA2LjMzMFoiLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsibW9kZXJhdG9yIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Im1vZGVyYXRvciIsIngtaGFzdXJhLXVzZXItaWQiOiI3MDBiYzlhMS03NGI1LTQ1NjMtYjQ5Mi1lMDA5NjE4M2FmZGYiLCJ4LWhhc3VyYS1yb2xlIjoibW9kZXJhdG9yIn0sImlhdCI6MTY0MzAxMzY0NywiZXhwIjoxNjQzNjE4NDQ3LCJzdWIiOiI3MDBiYzlhMS03NGI1LTQ1NjMtYjQ5Mi1lMDA5NjE4M2FmZGYifQ.mbi1QYNlIuC6cX8YArEqp7Uy8rcrJs1jtA_qs6UA2c2DfI5eLhkW0A_079kUcopEQbl7W5EMqTvA-AhmndANglNqwfF43zG0KQHv8EzZ8_j-mGSbAK97-DJlVPFW-r4UPpE234Htn6NCADPN-iXUkOZex_mHdmyJT3olq-KbQkrfQv--0dNV7cRKHr5j7doeJmmUfkzOPPatTf5XJJeiWhzUm4HR2fEByo2WrWKX0wqAc4evKWx9V8SSup-L7mmJtLUSE35gQxZubhwHqbTbO1qLOfgq4VtvjNSa14ppHxDrfxhT0lMKqbC9_CvY58AwkOMKeJTjbYkv1FeEhLTz3w"
    if (id_token) headers.Authorization = `Bearer ${id_token}`;
    // if (id_token)
    //  headers.Authorization = `Bearer eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQWRtaW4iLCJtb2JpbGUiOiIrOTE5MDAwMDAwMDE4IiwiZW1haWwiOiJhZG1pbkBvcmlnaW5rb25uZWN0LmluIiwicm9sZSI6Im1vZGVyYXRvciIsInBob3RvX3VybCI6bnVsbCwiY3JlYXRlZF9hdCI6IjIwMjEtMDgtMDRUMDk6NTU6MDYuMzMwWiIsInVwZGF0ZWRfYXQiOiIyMDIxLTA4LTA0VDA5OjU1OjA2LjMzMFoiLCJodHRwczovL2hhc3VyYS5pby9qd3QvY2xhaW1zIjp7IngtaGFzdXJhLWFsbG93ZWQtcm9sZXMiOlsibW9kZXJhdG9yIl0sIngtaGFzdXJhLWRlZmF1bHQtcm9sZSI6Im1vZGVyYXRvciIsIngtaGFzdXJhLXVzZXItaWQiOiI3MDBiYzlhMS03NGI1LTQ1NjMtYjQ5Mi1lMDA5NjE4M2FmZGYiLCJ4LWhhc3VyYS1yb2xlIjoibW9kZXJhdG9yIn0sImlhdCI6MTY0MzAxMzY0NywiZXhwIjoxNjQzNjE4NDQ3LCJzdWIiOiI3MDBiYzlhMS03NGI1LTQ1NjMtYjQ5Mi1lMDA5NjE4M2FmZGYifQ.mbi1QYNlIuC6cX8YArEqp7Uy8rcrJs1jtA_qs6UA2c2DfI5eLhkW0A_079kUcopEQbl7W5EMqTvA-AhmndANglNqwfF43zG0KQHv8EzZ8_j-mGSbAK97-DJlVPFW-r4UPpE234Htn6NCADPN-iXUkOZex_mHdmyJT3olq-KbQkrfQv--0dNV7cRKHr5j7doeJmmUfkzOPPatTf5XJJeiWhzUm4HR2fEByo2WrWKX0wqAc4evKWx9V8SSup-L7mmJtLUSE35gQxZubhwHqbTbO1qLOfgq4VtvjNSa14ppHxDrfxhT0lMKqbC9_CvY58AwkOMKeJTjbYkv1FeEhLTz3w`;
    return new ApolloClient({
        cache: new InMemoryCache({ addTypename: false }),
        link: split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === "OperationDefinition" && definition.operation === "subscription";
            },
            new WebSocketLink({
                uri: APP_CONFIG.REACT_APP_HASURA_URL_WS,
                options: {
                    lazy: true,
                    reconnect: true,
                    connectionParams: async () => {
                        return { headers };
                    },
                },
            }),
            new HttpLink({
                uri: APP_CONFIG.REACT_APP_HASURA_URL,
                headers: headers,
            })
        ),
    });
};

const App = (props) => {
    const current_position = usePosition();
    const [collapsed, setCollapsed] = useState(false);
    const [enumState, setEnumState] = useState();
    const { args, pathname, search } = getParams();
    storage.set("redirect_path", pathname + search);
    document.title = args
        ? args.reduce((text, arg) => (validate(arg) ? "" : `${getLabel(arg, true)} | `) + text, APP_CONFIG.REACT_APP_NAME || "OriginKonnect")
        : APP_CONFIG.REACT_APP_NAME || "OriginKonnect";
    const { setEnums, setData, current_location } = useContext(GeneralContext);
    const { user_id, is_authenticated, setAuthContext, id_token, user, logoutUser ,role} = useContext(AuthContext);


    const { object: updated_user, error: updated_user_error } = useObjectByPkSubscription({
        id: user_id,
        entity: "user",
        fields: ` id active name email phone photo_url created_at updated_at role `,
    });

    useEffect(() => {
        if (is_authenticated && id_token && user_id && !updated_user_error && updated_user && !_.isEqual(user, updated_user))
            setAuthContext({ user: updated_user });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id_token, is_authenticated, updated_user, user, user_id, updated_user_error]);

    // SET ENUM DATA
    useEffect(() => {
        if (
            (is_authenticated || id_token || user_id) &&
            updated_user_error &&
            JSON.stringify(updated_user_error).includes("Could not verify JWT")
        )
            logoutUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updated_user_error, id_token, is_authenticated, user_id]);

    useEffect(() => {
        if (
            current_position &&
            !current_position?.error &&
            current_position?.latitude &&
            current_position?.longitude &&
            !current_location?.lat &&
            !current_location?.lng
        )
            setData(
                {
                    ...current_location,
                    lat: current_position?.latitude,
                    lng: current_position?.longitude,
                },
                "current_location"
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_position, current_location]);

    const { data, loading, error } = useQuery(
        gql(`query getEnumValues { ${ENUM_LIST.map((e) => ` ${e}{id:value name:comment value comment} `).join()} }`)
    );

    // console.log("checking values",data)
    
    useEffect(() => {
        if (!loading && data && !error
             && !_.isEqual(enumState, data))
              {
        
            setEnumState(data);
            // console.log("setting data", data)

            ENUM_LIST.map((e) => data?.[e]?.length > 0 && setEnums(data?.[e], e))
        
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data]);

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {adminNonAuthRoutes?.map((route, idx) => (
                        <NonAuthmiddleware
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                            exact={route.exact}
                        />
                    ))}

                    {role === "moderator"  &&  adminAuthRoutes?.map((route, idx) => (
                        <AuthMiddleware
                            exact={route.exact}
                            key={idx}
                            path={route.path}
                            layout={props.layout.layoutType === "horizontal" ? HorizontalLayout : VerticalLayout}
                            component={route.component}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    )   ) }
                    
                   {   role === "exporter" && userNonAuthRoutes?.map((route, idx) => (
                        <AuthMiddleware
                            exact={route.exact}
                            key={idx}
                            path={route.path}
                            layout={props.layout.layoutType === "horizontal" ? HorizontalLayout : VerticalLayout}
                            component={route.component}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    ))}

                   {   role === "supplier" && userNonAuthRoutes?.map((route, idx) => (
                        <AuthMiddleware
                            exact={route.exact}
                            key={idx}
                            path={route.path}
                            layout={props.layout.layoutType === "horizontal" ? HorizontalLayout : VerticalLayout}
                            component={route.component}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    ))}

                   {   role === "freight_forwarder" && userNonAuthRoutes?.map((route, idx) => (
                        <AuthMiddleware
                            exact={route.exact}
                            key={idx}
                            path={route.path}
                            layout={props.layout.layoutType === "horizontal" ? HorizontalLayout : VerticalLayout}
                            component={route.component}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    ))}

                    {   role === "importer" && userNonAuthRoutes?.map((route, idx) => (
                        <AuthMiddleware
                            exact={route.exact}
                            key={idx}
                            path={route.path}
                            layout={props.layout.layoutType === "horizontal" ? HorizontalLayout : VerticalLayout}
                            component={route.component}
                            collapsed={collapsed}
                            setCollapsed={setCollapsed}
                        />
                    ))}
                    

                </Switch>
            </Router>
        </React.Fragment>
    );
};

const Client = (props) => {
    const { id_token } = useContext(AuthContext);
    return (
        <ApolloProvider client={useApolloClient(id_token)}>
            <App {...props} />
            <ToastContainer />
        </ApolloProvider>
    );
};

const mapStateToProps = (state) => ({ layout: state.Layout });
export default connect(mapStateToProps, null)(Client);
