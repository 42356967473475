import moment from "moment";
import { useContext } from "react";
import { GeneralContext } from "../context";
import {
    activeColumn,
    booleanColumn,
    dateColumn,
    datetimeColumn,
    defaultColumn,
    enumColumn,
    fillColumn,
    getLabel,
    imageColumn,
    linkColumn,
    mapColumn,
    numberColumn,
    timeColumn,
    uuidColumn,
} from "./utils";
import { AuthContext } from "../context";

export const useEntityFields = ({ entity = "", solution = "", ...rest }) => {
    const {
        enum_po_status,
        enum_price_type,
        enum_jobcard_purpose,
        enum_jobcard_type,
        enum_jobcard_status,
        enum_contact_type,
        enum_document_type,
        enum_catalogue_type,
        enum_customer_type,
        enum_commodity_type,
        enum_location_type,
        enum_offer_status,
        enum_order_status,
        enum_payment_status,
        enum_loading_status,
        enum_user_role,
        enum_grading_type,
        enum_credit_days_type,
        enum_order_type,
        enum_bag_type,
        enum_commodity_category,
        enum_vehicle_type,
        enum_transportation_paid_by,
        // enum_vehicle_weight,
        enum_nbfc_credit_status,
        
        enum_vehicle_weight_map,
        // enum_order_type,
        
        enum_commodity_origin_type,
        enum_payment_gateway,
        enum_business_type,
        enum_annual_sales,
        enum_firm_type,
        enum_vendor_payment_terms,
        enum_nbfc_status,
        enum_container_type,
        enum_cargo_category,
        enum_freight_status,
        enum_int_packaging_type,
        enum_export_payment_status,
        enum_user_org_business_type,
        enum_incoterms_type,
        enum_inquery_type,
        enum_freight_transit_status,
        enum_stuffing_location_type,
        enum_free_days_des_type,
        enum_export_freight_order_payment_status,
        enum_exp_fri_ord_booking_val_status,
        enum_exp_fri_ord_shipping_ins_status,
        enum_exp_fri_ord_draft_bl_status,
        enum_exp_fri_ord_vgm_status,
        enum_freight_order_status,
        enum_bidding_imp_status,
         enum_bid_order_action_admin_status ,
         enum_bid_order_action_exp_status ,
         enum_bid_order_action_imp_status ,
         enum_bidding_admin_status ,
         enum_bidding_exp_status 
           
       

    } = useContext(GeneralContext);

    // console.log("entity isasdfafasdf ",entity,solution)

    const { id_token, role } = useContext(AuthContext);

    const default_options = {
        entity,
        fields: `id  active created_at updated_at `,
        columns: [
            activeColumn(),
            datetimeColumn({
                accessor: "created_at",
                rules: null,
                displayOnly: true,
            }),
            datetimeColumn({
                Header: "Updated",
                accessor: "updated_at",
                rules: null,
                column: true,
                isSorted: true,
                isSortedDesc: true,
            }),
        ],
        sortBy: [{ id: "updated_at", desc: true }],
        activateStatus: true,
        showTitle: entity,
    };
    let options = {};
    //   console.log("entity is",entity)

    entity = entity?.toLowerCase();
    switch (true) {
        case entity === "vehicle":
            options = {
                entity: "vehicle",
                fields: `id created_at updated_at uid active name capacity vehicle_type enum_vehicle_type {comment  value }   notes owner_id owner{id name phone whatsapp}
                
                
                `,
                columns: [
                    linkColumn({
                        Header: "Vehicle Number",
                        accessor: "name",
                        link: `/${solution}/vehicle/detail/#id`,
                    }),
                    enumColumn({
                        accessor: "vehicle_type",
                        type: "select",
                        options: enum_vehicle_type,
                    }),
                    numberColumn({
                        Header: "Capacity (T)",
                        accessor: "capacity",
                        type: "weight",
                    }),
                    uuidColumn({
                        Header: getLabel("owner"),
                        accessor: "owner",
                        dbname: "owner_id",
                        entity: "customer",
                        solution: "trade",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        filters: { customer_type: { _in: ["transporter"] } },
                    }),
                    defaultColumn({
                        Header: "Phone",
                        accessor: "owner.phone",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    defaultColumn({
                        Header: "Whatsapp",
                        accessor: "owner.whatsapp",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    defaultColumn({
                        Header: "Email",
                        accessor: "owner.email",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    defaultColumn({
                        accessor: "notes",
                        rules: null,
                        type: "textarea",
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
            
        case entity === "orderpayment":
            options={
                entity : "view_metric_details_payments_in_three_days",
                fields: `amount comm_amount delivery_date name ord_amount order_id order_type payment_gateway  payed_amount`,
                columns: [
                    linkColumn({
                        Header: "name",
                        accessor: "name",
                        link: `/${solution}/vehicle/detail/#id`,
                    }),
                   
                    defaultColumn({
                        Header: "order type",
                        accessor: "order_type",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    enumColumn({
                        accessor: "payment_gateway ",
                        type: "select",
                        options: enum_payment_gateway,
                    }),
                    defaultColumn({
                        Header: "Amount",
                        accessor: "amount",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
               
                    datetimeColumn({
                        accessor: "delivery_date",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                        column:true
                    }),
                 
                    defaultColumn({
                        Header: "ord_amount",
                        accessor: "ord_amount",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    defaultColumn({
                        Header: "comm_amount",
                        accessor: "comm_amount",
                        rules: null,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    defaultColumn({
                        accessor: "payed_amount",
                        rules: null,
                        type: "textarea",
                        column: true,
                    }),
                    
                ],
            }   
            break; 

        case entity === "export_order_payment":
            options={
                entity : "export_order_payments",
                fields: `   
                id
                active
                uid
                payments
                amount 
                total_payed_amount
                status enum_export_payment_status {comment}
                export_order_id
                export_order {
                    id
                    uid
                    name
                    total_amount

                    customer {
                        id
                        name
                    }

                    supplier{
                        id
                        name
                    }

                }
              
                created_at
                updated_at
                
               
                `,
                sortBy: [{ id: "status", desc: true }],
                columns: [
                        linkColumn({
                            Header: "Id",
                            displayOnly: true,
                            accessor: "uid",
                            link_id:"id",
                            field:"uid",
                            link: `/management/exporter_payment/detail/#id`,
                            add: false,
                            edit: false,
                            disabled:true
                        }),

                    uuidColumn({
                        Header:"Order ID",
                        entity: "export_order",
                        accessor:"export_order",
                        dbname:"export_order_id",
                        typeahead_fields: `id name type: customer{name} `,
                        filters:{status:{_eq:"completed"}},
                        add:false,
                        edit:false

                    }),
                    uuidColumn({
                        Header: "Customer",
                        accessor: "export_order.customer.name",
                        edit:false,
                        add:false
                    }), 

                    uuidColumn({
                        Header: "Supplier",
                        accessor: "export_order.supplier.name",
                        edit:false,
                        add:false
                    }), 
                    
            
                    defaultColumn({
                        Header: "Payments",
                        accessor: "payments",
                        type:"multiInput",
                        multiInputFields : ["utrno","payed","Date"],
                        column:false,
                    }), 

                


                    defaultColumn({
                        Header: "Total Payment",
                        accessor: "total_payed_amount",
                        displayOnly:true,
                        add:false,
                        edit:false
                    }), 
                    
                    // defaultColumn({
                    //     Header: "UTR No",
                    //     accessor: "utr_no",
                    // }), 
                    enumColumn({
                        Header: "Status",
                        accessor: "status",
                        type: "select",
                        options: enum_export_payment_status,
                        add: true,
                        edit: true,
                        column: true,
                    }),

                    numberColumn({
                    Header:"Order Total Amount",
                    accessor: "export_order.total_amount",
                    add:false,
                    edit:false }),

                   
                    // dateColumn({ accessor: "payment_date" }),
                    
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                 

                ],
            }   
            break; 

        case entity === "exporter_data":
                options={
                    entity : "exporter_data",
                    fields: `id  anuual_sales_revenue active  type  remark  
                             country has_experience name photo_url  no_of_employees   
                             has_warehouse year_of_establishment `,
                    userColumns:{

                    skipColumn:{}    , 
                    skipEnum  : {"type": role === "exporter" ?  {farmer:true, supplier:true} :  {exporter:true }}
                } ,
                    columns: [
                        linkColumn({
                            Header: "Business Name",
                            accessor: "name",
                            // link: `/${solution}/vehicle/detail/#id`,
                        }),
                        
                        enumColumn({
                            Header: "Annual Sales revenue",
                            accessor: "anuual_sales_revenue",
                            type: "select",
                            options: enum_annual_sales,
                            add: true,
                            edit: true,
                            column: true,
                        }),

                        enumColumn({
                            Header: "Business",
                            accessor: "type",
                            type: "select",
                            options: enum_business_type,
                            add: true,
                            edit: true,
                            column: true,
                        }),
                     
                        imageColumn({
                            Header:"Image(Logo / Business Card)",
                            accessor: "photo_url",
                            type: "photo",
                            accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                            rules: null,
                            column: true,
                            single:true,
                            width: 250,
                            minWidth: 200,
                        }),

                        

                        numberColumn({
                            Header: "No Of Employees",
                            accessor: "no_of_employees",
                        }), 
 

                        numberColumn({
                            Header: "Year Of Establishment",
                            accessor: "year_of_establishment",
                        }),

                      booleanColumn({Header:"WareHouse", accessor: "has_warehouse" }),
                      booleanColumn({Heaer:"Experience", accessor: "has_experience" }),
 

                        // numberColumn({
                        //     Header: "commodity HSN ",
                        //     accessor: "commodity_hsn",
                        //     type: "weight",
                        // }), 

                        defaultColumn({
                            Header:"Business Summary",
                            accessor: "remark",
                            rules: null,
                            type: "textarea",
                            // column: false,
                        }),
 
                    ],
                }   
                break; 


                case entity === "exporter_commodity":
                    options={
                        entity : "exporter_commodity",
                        fields: ` 
                        id
                        active
                        commodity_hsn
                        commodity_id 
                        commodity{
                            name
                        }
                        customer_id
                        created_at
                        updated_at`,
                        columns: [
                         
                            uuidColumn({
                                entity: "commodity",
                                typeahead_fields: `id name`,
                                // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
                                hideAdd:true,
                            }),

                                numberColumn({
                                Header: "HS Code ",
                                accessor: "commodity_hsn",
                                // type: "weight",
                            }), 
 
                          
                       
                        ],
                    }   
                    break; 
    

                case entity ==="nbfc_partners":
                    options = {
                        entity: "nbfc_partners",
                        fields:" id active address_proof  aadhaar  pan name ",
                        columns:[
                            activeColumn(),

                            defaultColumn({
                                accessor: "name",
                                rules: null,
                                type: "text",
                                column: true,
                            }),
                            imageColumn({
                                header:"Address proof",
                                accessor: "address_proof",
                                rules: null,
                                id:"address_proof",
                                type: "photo",
                                privateImage:true,
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                column: true,
                                width:400,
                                minWidth:300,
                                maxWidth:650,
                            }),


                            imageColumn({
                                Header:"Pan",
                                accessor: "pan",
                                rules: null,
                                id:"pan",
                                privateImage:true,
                                type: "photo",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                column: true,
                                width:400,
                                minWidth:300,
                                maxWidth:650,
                            }),

                            imageColumn({
                                Header:"Aadhaar",
                                accessor: "aadhaar",
                                rules: null,
                                id:"aadhaar",
                                privateImage:true,
                                type: "image",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                column: true,
                                width:400,
                                minWidth:300,
                                maxWidth:650,
                            }),

 

                             
                        ]
                    }
   break;






         case entity ==="nbfc_credit":

                    options = {
                        entity: "nbfc_credit",
                        fields:" id  status active order_id order{id name} nbfc_id   invoice_img  amount ",
                        columns:[
                            activeColumn(),

                            numberColumn({
                                Header: "Amount",
                                accessor: "amount",
    
                            }), 


                            enumColumn({
                                accessor: "status",
                                options: enum_nbfc_credit_status,
                                type: "select",
                                add: true,
                                edit: true,
                                column: true,
                                // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

                            }),
                            uuidColumn({
                                entity: "order",
                                typeahead_fields: `id name`,
                                rules:null,
                                // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
    
                            }),


                            uuidColumn({
                                entity: "nbfc",
                                typeahead_fields: `id name`,
                                rules:null,
                                // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
    
                            }),


                            imageColumn({
                                header:"Invoices",
                                accessor: "invoice_img",
                                rules: null,
                                id:"address_proof",
                                type: "photo",
                                privateImage:true,
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                column: true,
                                width:400,
                                minWidth:300,
                                maxWidth:650,
                            }),
 
                        ]
                    }

                    break;

                case  entity === "nbfc":
                    options={
                        entity: "nbfc",
                        fields:  `id  status company_name   
                        nbfc_valid_docs{
                             id
                             adhaar_card_img_valid gst_certificate_img_valid _12_months_gst_returns_valid office_and_res_latest_ele_bill_gas_bill_valid rent_aggrement_if_rented_valid front_profile_photo_of_properieter_valid _2_years_business_banking_current_or_cc__od_valid   coi_valid _2_years_bs_and_pl_valid _2_years_tax_audit_report_valid company_pan_valid moa_and_aoa_valid certificate_of_incorporation_valid all_partners_kyc_pan_adhaar_address_proof_valid address_proof_valid gst_3_b_jan_2021_to_jun3_2021_valid gst_2a_june_2020_to_june_2021_valid gst_certificate_valid partnership_deed_valid office_and_residence_proor_partners_and_firm_valid rent_aggrement_if_premise_are_rented_valid front_profile_photograph_of_partners_valid _2_years_itr_valid _2_years_bs_and_pnl_valid pan_card_of_director_valid office_residence_proof_valid director_residence_rpoor_valid _12_month_business_banking_currrent_or_cc_or_od_valid _2_month_gst_return_valid front_profile_photograph_of_director_valid aadhaar_card_of_director_valid pan_card_of_company_valid adhaar_no_valid pan_no_valid gst_no_valid company_name_valid customer_id_valid pan_card_photo_valid company_pan_no_valid  
                          }
                          
                          aadhaar_card_of_director  company_pan_no customer_id customer {id name} active front_profile_photograph_of_director  adhaar_no gst_no pan_no firm_type _12_month_business_banking_currrent_or_cc_or_od _12_months_gst_returns _2_month_gst_return _2_years_bs_and_pl _2_years_bs_and_pnl _2_years_business_banking_current_or_cc__od _2_years_itr  _2_years_tax_audit_report address_proof adhaar_card_img all_partners_kyc_pan_adhaar_address_proof certificate_of_incorporation company_pan coi front_profile_photo_of_properieter director_residence_rpoor front_profile_photograph_of_partners gst_2a_june_2020_to_june_2021 gst_3_b_jan_2021_to_jun3_2021 gst_certificate_img gst_certificate id moa_and_aoa office_and_res_latest_ele_bill_gas_bill office_and_residence_proor_partners_and_firm office_residence_proof pan_card_of_director pan_card_photo partnership_deed rent_aggrement_if_rented rent_aggrement_if_premise_are_rented`,
                        columns:[

                            // adhaar_card_img_valid gst_certificate_img_valid _12_months_gst_returns_valid office_and_res_latest_ele_bill_gas_bill_valid rent_aggrement_if_rented_valid front_profile_photo_of_properieter_valid _2_years_business_banking_current_or_cc__od_valid _2_years_itr_pdf_valid coi_valid _2_years_bs_and_pl_valid _2_years_tax_audit_report_valid company_pan_valid moa_and_aoa_valid certificate_of_incorporation_valid all_partners_kyc_pan_adhaar_address_proof_valid address_proof_valid gst_3_b_jan_2021_to_jun3_2021_valid gst_2a_june_2020_to_june_2021_valid gst_certificate_valid partnership_deed_valid office_and_residence_proor_partners_and_firm_valid rent_aggrement_if_premise_are_rented_valid front_profile_photograph_of_partners_valid _2_years_itr_valid _2_years_bs_and_pnl_valid pan_card_of_director_valid office_residence_proof_valid director_residence_rpoor_valid _12_month_business_banking_currrent_or_cc_or_od_valid _2_month_gst_return_valid front_profile_photograph_of_director_valid aadhaar_card_of_director_valid pan_card_of_company_valid adhaar_no_valid pan_no_valid gst_no_valid company_name_valid customer_id_valid pan_card_photo_valid company_pan_no_valid  


                            // booleanColumn({Header:"TEST 12 Business", accessor:"tests[0]._12_month_business_banking_currrent_or_cc_or_od_valid" }) ,
                            // booleanColumn({ Header:"TEST 12 Returns ",accessor:"tests[0]._12_months_gst_returns_valid"   }) ,
                            // booleanColumn({ Header:"TEST 2 Business", accessor:"tests[0]._2_month_gst_return_valid" }) ,
                            linkColumn({
                                accessor: "company_name",
                                link: `/management/nbfc_partners/detail/#id`,
                            }),

                            
                            activeColumn(),
                            enumColumn({
                                accessor: "firm_type",
                                options: enum_firm_type,
                                type: "select",
                                add: true,
                                edit: true,
                                column: true,
                                // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

                            }),

                            enumColumn({
                                accessor: "status",
                                options: enum_nbfc_status,
                                type: "select",
                                add: true,
                                edit: true,
                                column: true,
                                // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

                            }),

                        uuidColumn({
                            entity: "customer",
                            typeahead_fields: `id name type:customer_type`,
                            rules:null,
                            filters: { customer_type: { _in: ["trader","wholesaler","exporter"] } },
                            // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

                        }),
                            defaultColumn({
                                simpleColumnDepends:{"proprietorship":true,"partnership":true,"pvt_limited_company":true},
                                accessor: "gst_no",
                                add: true,
                                edit:true,
                                column:true,
                                dependsValue: ["firm_type:partnership","firm_type:proprietorship","firm_type:pvt_limited_company"],                                rules:null,
                                // simpleColumnDepends:{"partnership":true}


                            }),

                            booleanColumn({ 
                                simpleColumnDepends:{"proprietorship":true,"partnership":true,"pvt_limited_company":true},                            Header:"GST No Valid",
                            accessor: "nbfc_valid_docs[0].gst_no_valid", add: true,
                            edit:true,
                            column:true,
                            dependsValue: ["firm_type:partnership","firm_type:proprietorship","firm_type:pvt_limited_company"],                                rules:null,
                              }),

                            defaultColumn({
                                accessor: "pan_no",
                                add: true,
                                edit:true,
                                column:true,
                                dependsValue: ["firm_type:partnership","firm_type:proprietorship","firm_type:pvt_limited_company"],
                                rules:null,

                            }),

                            booleanColumn({
                                Header:"Pan No Valid", 
                                accessor: "nbfc_valid_docs[0].pan_no_valid", 
                                add: true,
                                edit:true,
                                column:true,
                                dependsValue: ["firm_type:partnership","firm_type:proprietorship","firm_type:pvt_limited_company"],
                                rules:null, }),


                            defaultColumn({
                                accessor: "company_pan_no",
                                add: true,
                                edit:true,
                                column:true,
                                dependsValue: ["firm_type:pvt_limited_company"],
                                rules:null,

                            }),

                            booleanColumn({ 
                                Header:"Company Pan No Valid",
                                accessor: "nbfc_valid_docs[0].company_pan_no_valid", 
                                add: true,
                                edit:true,
                                column:true,
                                dependsValue: ["firm_type:pvt_limited_company"],
                                rules:null, }),




                            defaultColumn({
                                accessor: "adhaar_no",
                                add: true,
                                edit:true,
                                column:true,
                                 dependsValue: ["firm_type:partnership","firm_type:proprietorship",],
                                rules:null,

                            }),
                            booleanColumn({ 
                                Header:"AAdhaar Valid",
                                accessor: "nbfc_valid_docs[0].adhaar_no_valid", 
                                add: true,
                                edit:true,
                                column:true,
                                dependsValue: ["firm_type:partnership","firm_type:proprietorship",],
                                rules:null, }),
 
                            
                      ...[
                       
                        //For propertierShip and partnerShip 
                        {
                          simpleColumnDepends:{"proprietorship":true,"partnership":true},
                          depends: ["proprietorship","partnership"], 
                          type:"image", 
                          label: getLabel("pan_card_photo"), 
                                                    acc:'pan_card_photo',
                                                    valid_acc:'pan_card_photo_valid'
                        },

                       //For propertierShip and partnerShip and pvt_limited
                       {
                        simpleColumnDepends:{"partnership":true,"pvt_limited_company":true},
                        depends: ["pvt_limited_company" ,"proprietorship", "partnership"], 
                        type:"photo", 
                        label: getLabel("gst_certificate_img"), 
                                                acc:'gst_certificate_img',
                                                valid_acc:'gst_certificate_img_valid',
                      },
 
                        
                          //For propertierShip and partnerShip 
                       {
                           simpleColumnDepends:{"partnership":true,"proprietorship":true},

                           depends: ["partnership", "proprietorship"], 
                           
                           // eslint-disable-next-line no-useless-escape
                           accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                           type:"document", 
                           label: getLabel("_12_months_gst_returns"), 
                                                      acc:"_12_months_gst_returns",
                                                      valid_acc:"_12_months_gst_returns_valid",
                        },
 

                        //For Partnership Firm	
                        {simpleColumnDepends:{"partnership":true,"pvt_limited_company":true},
                        depends: ["partnership","pvt_limited_company"], 
                        type:"photo", 
                        label: getLabel("company_pan"), 
                        acc:'company_pan',
                        valid_acc:'company_pan_valid'
                       },

                      {
                        simpleColumnDepends:{"partnership":true},
                        depends: ["partnership"], 
                        type:"photo", 
                        label: getLabel(" all_partners_kyc_pan_adhaar_address_proof"), 
                                                acc:'all_partners_kyc_pan_adhaar_address_proof',
                                                valid_acc:'all_partners_kyc_pan_adhaar_address_proof_valid'
                      },
                      
                        {
                        simpleColumnDepends:{"partnership":true},
                        depends: ["partnership"], 
                        type:"photo", 
                        label: getLabel(" address_proof"), 
                                                acc:'address_proof',
                                                valid_acc:'address_proof_valid'
                      },

                      
                        {
                        simpleColumnDepends:{"proprietorship":true,},
                        depends: ["proprietorship"], 
                        type:"photo", 
                        label: getLabel("front_profile_photo_of_PROPERIETOR"), 
                                                acc:'front_profile_photo_of_properieter',
                                                valid_acc:'front_profile_photo_of_properieter_valid'
                       },

                        {
                            simpleColumnDepends:{"proprietorship":true,},
                            depends: ["proprietorship"], 
                            type:"photo", 
                            label: getLabel("Office and Residence Proof"), 
                                                        acc:'office_and_res_latest_ele_bill_gas_bill',
                                                        valid_acc:'office_and_res_latest_ele_bill_gas_bill_valid'
                        },


                        {simpleColumnDepends:{"pvt_limited_company":true},
                        depends: ["pvt_limited_company"], 
                        type:"document", 
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i, 
                        label: getLabel("12 Mon business bankcurrent/cc/od"),
                                                acc:'_12_month_business_banking_currrent_or_cc_or_od',
                                                valid_acc:'_12_month_business_banking_currrent_or_cc_or_od_valid'
                    },
                        

                        {simpleColumnDepends:{"pvt_limited_company":true},
                        depends: ["pvt_limited_company"], 
                        type:"photo", 
                        label: getLabel("director_residence_proof"), 
                                                acc:'director_residence_rpoor',
                                                valid_acc:'director_residence_rpoor_valid'
                    },

                        {
                            simpleColumnDepends:{"pvt_limited_company":true, },
                        depends: ["pvt_limited_company"], 
                        type:"photo", 
                        label: getLabel("office_residence_proof"), 
                                                acc:'office_residence_proof',
                                                valid_acc:'office_residence_proof_valid'
                    },
                      
                        {
                        simpleColumnDepends:{"partnership":true},
                        depends: ["partnership"], 
                        type:"photo", 
                        label: getLabel("front_profile_photograph_of_partners"), 
                                                acc:'front_profile_photograph_of_partners',
                                                valid_acc:'front_profile_photograph_of_partners_valid'
                    },
                        {
                        simpleColumnDepends:{"pvt_limited_company":true},
                        depends: ["pvt_limited_company"], 
                        type:"photo", 
                        label: getLabel("front_profile_photograph_of_director"), 
                                                acc:'front_profile_photograph_of_director',
                                                valid_acc:'front_profile_photograph_of_director_valid'
                    },
                      
                    //     {
                    //     simpleColumnDepends:{"partnership":true,"proprietorship":true},
                    //     depends: ["proprietorship", "pvt_limited_company"], 
                    //     type:"photo", 
                    //     label: getLabel("gst_certificate"), 
                    //                         //     acc:'gst_certificate'
                    // valid_acc:'gst_certificate'
                    // },
                        

                        {
                        simpleColumnDepends:{"partnership":true},
                        depends: ["partnership"], 
                        type:"photo", 
                        label: getLabel("office_and_residence_proof_partners_and_firm"), 
                                                acc:'office_and_residence_proor_partners_and_firm',
                                                valid_acc:'office_and_residence_proor_partners_and_firm_valid'
                    },

                        {
                        simpleColumnDepends:{"pvt_limited_company":true},
                        depends: ["pvt_limited_company"], 
                        type:"photo", 
                        label: getLabel("pan_card_of_director"), 
                                                acc:'pan_card_of_director',
                                                valid_acc:'pan_card_of_director_valid'
                    },
                    //     {
                    //     simpleColumnDepends:{"pvt_limited_company":true},
                    //     depends: ["pvt_limited_company"], 
                    //     type:"photo", 
                    //     label: getLabel("pan_card_of_company"), 
                    //       acc:'pan_card_of_company',
                    //       valid_acc:'pan_card_of_company_valid'
                    // },
                        {
                        simpleColumnDepends:{"pvt_limited_company":true},
                        depends: ["pvt_limited_company"], 
                        type:"image", 
                        label: getLabel("aadhaar_card_of_director"), 
                                                acc:'aadhaar_card_of_director',
                                                valid_acc:'aadhaar_card_of_director_valid'
                    },


                         //For propertierShip and partnerShip 
                        {
                        simpleColumnDepends:{"partnership":true,"proprietorship":true},
                        depends: ["partnership","proprietorship"], 
                        type:"image", 
                        label: getLabel("adhaar_card_img "), 
                                                acc:'adhaar_card_img',
                                                valid_acc:'adhaar_card_img_valid'
                    },
                        

                          //For  partnership and Pvt Ltd Company
                          {
                          simpleColumnDepends:{"partnership":true,"pvt_limited_company":true}, 
                          depends: ["partnership", "pvt_limited_company"],
                          // eslint-disable-next-line no-useless-escape
                          accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                          type:"document", 
                          label: getLabel("gst_3_b_jan_2021_to_jun_2021"), 
                                                    acc:'gst_3_b_jan_2021_to_jun3_2021',
                                                    valid_acc:'gst_3_b_jan_2021_to_jun3_2021_valid'
                        },

                          { 
                          simpleColumnDepends:{"partnership":true, "pvt_limited_company":true}, 
                          depends: ["partnership", "pvt_limited_company"],
                          // eslint-disable-next-line no-useless-escape
                          accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                          type:"document", 
                          label: getLabel("gst_2a_june_2020_to_june_2021"), 
                                                    acc:'gst_2a_june_2020_to_june_2021',
                                                    valid_acc:'gst_2a_june_2020_to_june_2021_valid'
                         },
                          
                        {
                        simpleColumnDepends:{"partnership":true},
                        depends: ["partnership"], 
                        type:"document", 
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        label: getLabel("certificate_of_incorporation "), 
                                                acc:'certificate_of_incorporation',
                                                valid_acc:'certificate_of_incorporation_valid'
                        },
                        {
                        simpleColumnDepends:{"partnership":true, "pvt_limited_company":true},
                        depends: ["partnership", "pvt_limited_company"],
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        type:"document", 
                        label: getLabel('_2_month_gst_return'), 
                                                acc:"_2_month_gst_return",
                                                valid_acc:"_2_month_gst_return_valid"
                       },

                          
                          {simpleColumnDepends:{"partnership":true},
                          depends: ["partnership"],
                          // eslint-disable-next-line no-useless-escape
                          accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                          type:"document", 
                          label: getLabel("partnership_deed"), 
                                                    acc:'partnership_deed',
                                                    valid_acc:'partnership_deed_valid'
                                                },

                         //For propertierShip and partnerShip and Pvt Ltd Company
                       {simpleColumnDepends:{"partnership":true,"proprietorship":true,     "pvt_limited_company":true},
                       depends: ["partnership", "proprietorship", "pvt_limited_company"], 
                       // eslint-disable-next-line no-useless-escape
                       accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i, 
                       type:"document", 
                       label :getLabel("rent_aggrement_if_premise_are_rented"),
                                              acc:'rent_aggrement_if_premise_are_rented',
                                              valid_acc:'rent_aggrement_if_premise_are_rented_valid'}

                       ,              //For propertierShip and partnerShip 
                       {simpleColumnDepends:{"partnership":true, "proprietorship":true},
                       depends: ["partnership", "proprietorship" ],  
                       // eslint-disable-next-line no-useless-escape
                       accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i, 
                       type:"document", 
                       label: getLabel(" _2_yeas_rbusiness_banking_current_or_cc__od"), 
                                              acc:'_2_years_business_banking_current_or_cc__od',
                                              valid_acc:'_2_years_business_banking_current_or_cc__od_valid'},


   //For propertierShip and partnerShip and Pvt Ltd Company
                        {simpleColumnDepends:{"partnership":true, "pvt_limited_company":true},
                        depends: ["partnership" , "proprietorship", "pvt_limited_company"],
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        type:"document", 
                        label: getLabel(" _2_years_itr"), 
                                                acc:'_2_years_itr',
                                                valid_acc:'_2_years_itr_valid'},
   //For propertierShip and partnerShip and Pvt Ltd Company
                        {simpleColumnDepends:{"partnership":true,"proprietorship":true,"pvt_limited_company":true },
                        depends: ["partnership", "proprietorship", "pvt_limited_company"],
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,   
                        type:"document", 
                        label: getLabel(" coi"), 
                                                acc:'coi',
                                                valid_acc:'coi_valid'},
   //For propertierShip and partnerShip and Pvt Ltd Company

                        {simpleColumnDepends:{"partnership":true,"proprietorship":true,"pvt_limited_company":true},
                        depends: ["partnership", "proprietorship", "pvt_limited_company"], 
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        type:"documents", 
                        label: getLabel("_2_years_bs_and_pl"), 
                                                acc:'_2_years_bs_and_pl',
                                                valid_acc:'_2_years_bs_and_pl_valid'},
   //For propertierShip and partnerShip and Pvt Ltd Company

                        {simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true},
                        depends: ["partnership", "proprietorship", "pvt_limited_company"], 
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        type:"documents", 
                        label: getLabel("_2_years_tax_audit_report "), 
                                                acc:'_2_years_tax_audit_report',
                                                valid_acc:'_2_years_tax_audit_report_valid'},

                        //For propertierShip and partnerShip and Pvt Ltd Company

                        {
                        simpleColumnDepends:{"pvt_limited_company":true,"partnership":true } , 
                        depends: ["pvt_limited_company","partnership"], 
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        type:"document", 
                        label: getLabel("moa_and_aoa"), 
                                                acc:'moa_and_aoa',
                                                valid_acc:'moa_and_aoa_valid'},


                        ]?.flatMap((e,i)=>{
 

                                return [
                                    booleanColumn({  accessor:`nbfc_valid_docs[0].${e?.valid_acc}`,
                                    Header:getLabel(`nbfc_valid_docs[0].${e?.valid_acc}`?.replace("nbfc_valid_docs[0]."," ")),
                                    id:`nbfc_valid_docs[0].${e?.valid_acc}`,
                                    key:`nbfc_valid_docs[0].${e?.valid_acc}`,
                                    dependsValue:e?.depends?.map((e,i)=>`firm_type:${e}`),
                                    rules: null,
                                    span: 3,
                                    nullSpan:true,
                                    column:true,
                                    width:150,
                                    minWidth:100,
                                    maxWidth:280,
                                    simpleColumnDepends: e?.simpleColumnDepends
                                 }) , 
                                 imageColumn({
                                    accessor: e?.acc,
                                    Header:e?.label,
                                    id:e?.acc,
                                    key:e?.acc,
                                    dependsValue:e?.depends?.map((e,i)=>`firm_type:${e}`),
                                    rules: null,
                                    type: e?.type,
                                    span: 3,
                                    accept: e?.accept || /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                    column:true,
                                    privateImage: true,
                                    width:150,
                                    minWidth:100,
                                    maxWidth:280,
                                    simpleColumnDepends: e?.simpleColumnDepends 
                                })
                             ]

                                
                            }
                            ) , 

 
                            ]
 
                            // uuidColumn({
                            //     entity: "customer",
                            //     solution: "marketplace",
                            //     filters: { customer_type: { _in: ["exporter"] } },
                            //     typeahead_fields: `id name type: enum_customer_type{value comment}`,
                              
                            // }),
      
                    }   
                    break; 


                case entity === "export_order":
                    options={
                        entity : "export_order",
                        fields: `id     active    
                                 commodity_id commodity    {id name}   
                                 customer_id  customer{id name}`,
                     
                        columns: [
 
                            uuidColumn({
                                entity: "commodity",
                                typeahead_fields: `id name type: enum_commodity_type{value comment}`,
                            }),

                            // uuidColumn({
                            //     Header:"Exporter Name",
                            //     entity: "exporter_data",
                            //     typeahead_fields: `id name  `,
                            //     accessor:"exporter_datum"
                            // }),
    
                            // uuidColumn({
                            //     entity: "customer",
                            //     solution: "marketplace",
                            //     filters: { customer_type: { _in: ["exporter"] } },
                            //     typeahead_fields: `id name type: enum_customer_type{value comment}`,
                              
                            // }),
 
    
                            // numberColumn({
                            //     Header: "commodity HSN ",
                            //     accessor: "commodity_hsn",
                            //     type: "weight",
                            // }), 

                            
     
                        ],
                    }   
                    break; 

        


        case entity === "address":
        case entity === "location":
        case entity === "customer_location":
        case entity === "pickup_location":
        case entity === "delivery_location":
        case entity === "farm":
        case entity === "coldstorage":
        case entity === "godown":
        case entity === "mandi":
        case entity === "store":
        case entity === "origin":
        case entity === "destination":
        case entity === "weighbridge":
        case enum_location_type?.includes(entity):
            options = {
                entity: "location",
                fields: `id uid active created_at updated_at name location_type enum_location_type {comment} notes year_of_establishment
                user_id user {id name} customer_id customer {id name customer_type commission_percent payment_days  enum_customer_type{comment}} phone email whatsapp
                address postcode lat lng state district area city country vehicle_type`,
               
                userColumns:{

                    skipColumn:{"payment_days":true,year_of_establishment:true, email:true, phone:true}    , 
                    skipEnum  :{"location_type":{weighbridge:true,farm:true,source:true}}
                    // skipEnum  :{"location_type":{weighbridge:true,destination_port:true,origin_port:true,farm:true,source:true}}
                } ,
                
                 columns: [
                    linkColumn({
                        accessor: "name",
                        link: `/trade/location/detail/#id`,
                    }),
                    enumColumn({
                        accessor: "location_type",
                        type: "select",
                        options: enum_location_type,
                        add: true,
                        edit: true,
                        column: true,
                    }),

                    uuidColumn({
                        Header: "Vehicle Capacity (KG)",
                        entity: "enum_vehicle_weight",
                        dbname: "vehicle_type",
                        accessor: "vehicle_type",
                        labelKey: "comment",
                        valueKey: "value",
                        multiple: true,
                        hideAdd: true,
                        activeFilter: false,
                        searchable:false,
                        dependsValue: ["location_type:delivery"],
                        typeahead_fields: `value comment `,
                        enum_vehicle_weight_map: enum_vehicle_weight_map,
                    }),

                    defaultColumn({ accessor: "email", column: false, rules: null }),
                    defaultColumn({
                        accessor: "phone",
                        column: false,
                        rules: { pattern: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/ },
                    }),
                    defaultColumn({
                        accessor: "whatsapp",
                        column: false,
                        rules: { pattern: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/ },
                    }),
                    numberColumn({
                        accessor: "year_of_establishment",
                        column: false,
                        rules: null,
                        min: 1600,
                    }),
                    mapColumn({rules:null}),
                    defaultColumn({
                        accessor: "area",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        accessor: "city",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        accessor: "district",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        accessor: "state",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        accessor: "country",
                        rules: null,
                        add: false,
                        edit: false,
                        column: false,
                    }),
                    defaultColumn({
                        accessor: "postcode",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        accessor: "notes",
                        rules: null,
                        type: "textarea",
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "location_rate":
        case entity === "marketprice":
        case entity === "rate":
            options = {
                entity: "location_rate",
                fields: `id uid active created_at updated_at price_type rate min_rate max_rate date no_of_vehicles bag_size enum_bag_type {comment value}
				location_id location{id name address location_type enum_location_type {comment} }
                source_location_id  source_location {id name address location_type enum_location_type {comment}}
                commodity_id commodity{ id name }
				commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
			  `,
                columns: [
                    dateColumn({ accessor: "date" }),
                    enumColumn({
                        accessor: "price_type",
                        options: enum_price_type,
                        type: "select",
                    }),
                    uuidColumn({
                        entity: "commodity_variety",
                        typeahead_fields: `id name type: commodity{id name}`,
                        rules:null
                    }),

                    uuidColumn({
                        entity: "commodity",
                        typeahead_fields: `id name `,
                    }),

                    uuidColumn({
                        Header: "Location",
                        entity: "location",
                        link: `/trade/location/detail/#id`,
                        link_id: "location_id",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    enumColumn({
                        accessor: "bag_size",
                        options: enum_bag_type,
                        type: "select",
                        // column: false,
                        span: 4,
                    }),

                    uuidColumn({
                        Header: "Source Location",
                        entity: "location",
                        accessor: "source_location",
                        // link: `/trade/location/detail/#id`,
                        link_id: "location_id",
                        modifyDbName: "source_location_id",
                        // filter_fields: [{ accessor: "commodity" }],
                        filters: { location_type: { _in: ["source"] } },
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                        // column: false,
                    }),
                    numberColumn({ accessor: "no_of_vehicles" }),

                    numberColumn({ accessor: "min_rate", type: "currency" }),
                    // numberColumn({ accessor: "rate", type: "currency" }),
                    numberColumn({ accessor: "max_rate", type: "currency" }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "location_stock":
        case entity === "stock":
            options = {
                entity: "location_stock",
                fields: `id created_at updated_at uid active location_id location{id name address location_type enum_location_type {comment} }
                commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
                filling_opening_date filling_closing_date filling_units trade_opening_date trade_closing_date opening_time closing_time
                number_of_chambers chamber_capacity current_year_filling last_year_filling
                seed_quantity_available processing_quantity consumable_quantity last_year_closing_date`,
                columns: [
                    uuidColumn({
                        entity: "commodity_variety",
                        typeahead_fields: `id name type: commodity{id name}`,
                    }),
                    uuidColumn({
                        entity: "location",
                        link: `/trade/location/detail/#id`,
                        link_id: "location_id",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    dateColumn({ accessor: "filling_opening_date", rules: null }),
                    dateColumn({ accessor: "filling_closing_date", rules: null }),
                    numberColumn({ accessor: "filling_units", rules: null }),
                    dateColumn({
                        accessor: "trade_opening_date",
                        rules: null,
                        column: false,
                    }),
                    dateColumn({
                        accessor: "trade_closing_date",
                        rules: null,
                        column: false,
                    }),
                    timeColumn({ accessor: "opening_time", rules: null, column: false }),
                    timeColumn({ accessor: "closing_time", rules: null, column: false }),
                    dateColumn({
                        accessor: "last_year_closing_date",
                        rules: null,
                        column: false,
                        edit: false,
                        add: false,
                    }),
                    numberColumn({ accessor: "number_of_chambers", rules: null }),
                    numberColumn({ accessor: "chamber_capacity", rules: null }),
                    numberColumn({
                        accessor: "current_year_filling",
                        rules: null,
                        column: false,
                        add: false,
                        edit: false,
                    }),
                    numberColumn({
                        accessor: "last_year_filling",
                        rules: null,
                        column: false,
                        edit: false,
                        add: false,
                    }),
                    numberColumn({ accessor: "seed_quantity_available", rules: null }),
                    numberColumn({
                        accessor: "processing_quantity",
                        rules: null,
                        column: false,
                        edit: false,
                    }),
                    numberColumn({
                        accessor: "consumable_quantity",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "commodity":
            options = {
                entity: "commodity",
                fields: `id uid active commodity_type  category_type  name origin_type  desc created_at updated_at photo_url vehicle_type`,
                userColumns:{
                    skipColumn:{"vehicle_weight":true,vehicle_type:true, photo_url :true}    , 
                    skipEnum  :{"origin_type":{domestic:true, }}
                } ,
                columns: [
                    linkColumn({
                        Header:"Commodity name",
                        accessor: "name",
                        link: `/masterdata/commodity/detail/#id`,
                    }),
                    enumColumn({
                        accessor: "commodity_type",
                        options: enum_commodity_type,
                        type: "select",
                        column: false,
                        searchable:false

                    }),
                    enumColumn({
                        accessor: "category_type",
                        options: enum_commodity_category,
                        type: "select",
                        column: false,
                        searchable:false

                    }),
                    enumColumn({
                        accessor: "origin_type",
                        options: enum_commodity_origin_type,
                        type: "select",
                        add:true,
                        searchable:false
                        // column: false,
                    }),
               
                    imageColumn({
                        accessor: "photo_url",
                        rules: null,
                        type: "photo",
                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                        searchable:false

                    }),
                    defaultColumn({ accessor: "desc", type: "textarea", rules: null }),
                    uuidColumn({
                        Header: "Vehicle Capacity (KG)",
                        entity: "enum_vehicle_weight",
                        dbname: "vehicle_type",
                        accessor: "vehicle_type",
                        labelKey: "comment",
                        valueKey: "value",
                        multiple: true,
                        hideAdd: true,
                        activeFilter: false,
                        dependsValue: ["origin_type:domestic"],
                        typeahead_fields: `value comment `,
                        enum_vehicle_weight_map: enum_vehicle_weight_map,
                        searchable:false

                    }),
                    enumColumn({
                        accessor: "package_type",
                        options: enum_int_packaging_type,
                        type: "select",
                        searchable:false

                    }),

                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "commodity_variety":
            options = {
                entity: "commodity_variety",
                fields: `id uid active commodity_id commodity {id name commodity_type} name desc created_at updated_at photo_url`,
                columns: [
                    linkColumn({
                        accessor: "name",
                        link: `/masterdata/commodity_variety/detail/#id`,
                    }),
                    uuidColumn({
                        entity: "commodity",
                        typeahead_fields: `id name type: enum_commodity_type{value comment}`,
                    }),
                    enumColumn({
                        Header: "Commodity Type",
                        accessor: "commodity.commodity_type",
                        options: enum_commodity_type,
                        type: "select",
                        add: false,
                        edit: false,
                    }),
                    imageColumn({
                        accessor: "photo_url",
                        rules: null,
                        type: "photo",
                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                    }),
                    defaultColumn({ accessor: "desc", type: "textarea", rules: null }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "commission":
            options = {
                entity: "catalogue_commission",
                fields: `active commission created_at days id uid updated_at grv_commission`,
                columns: [
                    defaultColumn({ accessor: "days", valueAsNumber: true }),
                    defaultColumn({ accessor: "commission", valueAsNumber: true }),
                    defaultColumn({ accessor: "grv_commission", valueAsNumber: true }),
                    // numberColumn({ accessor: "days",width:200 }),
                    // numberColumn({ accessor: "commission" ,width:200}),
                    activeColumn(),

                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                        span: 6,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                        span: 6,
                    }),
                ],
            };
            break;
        case entity === "catalogue":
            options = {
                entity: "catalogue",
                fields: `id uid active created_at updated_at
				commodity{id name commodity_type}
				commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
				catalogue_type name is_graded`,
                columns: [
                    linkColumn({ accessor: "name" }),
                    enumColumn({
                        accessor: "catalogue_type",
                        options: enum_catalogue_type,
                        type: "select",
                    }),
                    enumColumn({
                        Header: "Commodity Type",
                        accessor: "commodity.commodity_type",
                        options: enum_commodity_type,
                        type: "select",
                    }),
                    uuidColumn({
                        entity: "commodity",
                        reset_fields: ["commodity_variety"],
                        typeahead_fields: `id name type: enum_commodity_type{value comment}`,
                    }),
                    uuidColumn({
                        entity: "commodity_variety",
                        rules: null,
                        filter_fields: [{ accessor: "commodity" }],
                        typeahead_fields: `id name type: commodity{id name}`,
                    }),
                    booleanColumn({ accessor: "is_graded" }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        // case entity === "product":
        // case entity === "customer_product":
        // 	options = {
        // 		entity: "customer_product",
        // 		fields: `id uid name active created_at updated_at customer_id customer{id name}
        // 		commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
        // 		images videos notes`,
        // 		columns: [
        // 			uuidColumn({
        // 				entity: "customer",
        // 				reset_fields: ["location"],
        // 				filters: { customer_type: { _in: ["farmer", "trader"] } },
        // 				typeahead_fields: `id name type: enum_customer_type{value comment}`,
        // 			}),
        // 			uuidColumn({
        // 				entity: "commodity_variety",
        // 				typeahead_fields: `id name type: commodity{id name}`,
        // 			}),
        // 			defaultColumn({
        // 				accessor: "images",
        // 				type: "image",
        // 				accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
        // 				rules: null,
        // 				column: false,
        // 			}),
        // 			defaultColumn({
        // 				accessor: "videos",
        // 				type: "video",
        // 				accept: /\.(mp4)$/i,
        // 				rules: null,
        // 				column: false,
        // 			}),
        // 			defaultColumn({
        // 				accessor: "notes",
        // 				rules: null,
        // 				type: "textarea",
        // 				column: false,
        // 			}),
        // 			activeColumn(),
        // 			datetimeColumn({
        // 				accessor: "created_at",
        // 				rules: null,
        // 				displayOnly: true,
        // 			}),
        // 			datetimeColumn({
        // 				accessor: "updated_at",
        // 				rules: null,
        // 				isSorted: true,
        // 				isSortedDesc: true,
        // 			}),
        // 		],
        // 	};
        // 	break;
        case entity === "import":
            options = {
                entity: "order",
                fields: `
                id uid name active  items_draft created_at updated_at status  enum_order_status{comment}  purchase_amount 
				grading_type enum_grading_type{comment value}  enum_credit_days_type {comment value}
                credit_days shape_id shape{id name} size_id size{id name} color_id color{id name}
                offer_id offer{id name uid}  images videos documents order_type enum_order_type{comment value}
                grading grading_charges transportation transportation_charges
                packaging packaging_type enum_packaging_type{comment}
                packaging_charges gst_charges requested_price negotiated_price offered_price delivery_date total_amount
				supplier_id supplier{id name} pickup_location_id pickup_location{id name address location_type enum_location_type {comment} }
				commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
				customer_id customer{id name} delivery_location_id delivery_location{id name address location_type enum_location_type {comment} }
				number_of_units price_per_unit available_date amount requested_date delivery_vehicle_weight
            	change_history(order_by:{created_at: desc_nulls_last}){id new_value old_value status created_at enum:enum_order_status{comment}}
				commission_amount is_tag_to_offer
               
				catalogue_commission {
					active
					commission
					created_at
					days
					uid
					updated_at
					id
				  }	
				  loading_charges
				  unloading_charges
				  buyer_commission
				  supplier_commission

				  buyer_amount
				  supplier_amount

                  supplier_price
                  buyer_price

				  grv_commission
				  grv_profit

                  buyer_selling_rate_per_kg
                  buyer_selling_grn_weight
                  buyer_selling_price

                  jobcards{
                    transportation_cost
                  }
				 
                `,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        link: `/marketplace/order/detail/#id`,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_order_status,
                        type: "select",
                        add: false,
                    }),
                    booleanColumn({ accessor: "is_tag_to_offer" }),

                    fillColumn(),
                    uuidColumn({
                        entity: "commodity_variety",
                        reset_fields: ["offer"],
                        typeahead_fields: `id name type: commodity{id name}`,
                        // edit: false,
                        // add: false,
                    }),

                    uuidColumn({
                        entity: "offer",
                        filters: { status: { _in: ["open"] } },
                        filter_fields: [{ accessor: "commodity_variety" }],
                        typeahead_fields: `id name shape_id shape { name } size_id size { name } color_id color { name }  type: enum_offer_status{value comment}`,

                        // add: false,
                        // edit: false,
                    }),
                    uuidColumn({
                        Header: "Seller",
                        entity: "customer",
                        dbname: "supplier_id",
                        accessor: "supplier",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        add: false,
                        edit: false,
                        filters: { customer_type: { _in: ["farmer", "trader"] } },
                    }),
                    enumColumn({
                        accessor: "order_type",
                        options: enum_order_type,
                        type: "select",
                        column: true,
                        span: 4,
                    }),
                    enumColumn({
                        accessor: "grading_type",
                        options: enum_grading_type,
                        type: "select",
                        column: false,
                        span: 4,
                    }),

                    enumColumn({
                        accessor: "credit_days",
                        options: enum_credit_days_type,
                        dependsValue: ["order_type:on_order"],

                        type: "select",
                        column: false,
                        span: 4,
                        rules: null,
                    }),
                    uuidColumn({
                        Header: "Commission Days (%)",
                        labelKey: "days",
                        dependsValue: ["order_type:on_commission"],

                        entity: "catalogue_commission",
                        //    accessor:"catalogue_commission.days",
                        typeahead_fields: `id commission days type:days commission `,
                        hideAdd: true,
                        rules: null,
                    }),
                    //   uuidColumn({
                    // 	entity: "catalogue_commission",
                    // 	accessor: "catalogue_commission.days",
                    // 	labelKey:"days",
                    // 	rules: null,
                    // 	// filter_fields: [{ accessor: "catalogue_commission" }],
                    // 	// typeahead_fields: `id  type: catalogue_commission{commission days}`,
                    // }),

                    uuidColumn({
                        Header: "Buyer",
                        entity: "customer",
                        dbname: "customer_id",
                        accessor: "customer",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        filters: { customer_type: { _in: ["wholesaler"] } },
                        reset_fields: ["delivery_location"],
                    }),

                    numberColumn({
                        Header: "Buyer Price",
                        accessor: "buyer_price",
                        type: "currency",
                        min: 0,

                        add: false,
                        edit: false,
                    }), 
                     numberColumn({
                        Header: "Purchase Amount",
                        accessor: "purchase_amount",
                        type: "currency",
                        min: 0,

                       
                    }),

                    numberColumn({
                        Header: " Buyer Selling Rate Per KG",
                        accessor: "buyer_selling_rate_per_kg",
                        type: "currency",
                        min: 0,
                        rules: null,
                        dependsValue: ["order_type:on_commission"],
                    }),

                    numberColumn({
                        Header: "Buyer Selling Grn Weight",
                        accessor: "buyer_selling_grn_weight",
                        type: "currency",
                        min: 0,
                        rules: null,

                        dependsValue: ["order_type:on_commission"],
                    }),

                    numberColumn({
                        Header: "Buyer Selling Price",
                        accessor: "buyer_selling_price",
                        type: "currency",
                        add: false,
                        edit: false,
                        min: 0,
                        // dependsValue: ["order_type:on_order"],
                    }),

                    numberColumn({
                        Header: "Supplier Price",
                        accessor: "supplier_price",
                        type: "currency",
                        dependsValue: ["order_type:on_order"],
                        min: 0,
                    }),

                    uuidColumn({
                        Header: "Origin",
                        entity: "pickup_location",
                        filter_fields: [{ accessor: "supplier", dbname: "customer_id" }],
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                        edit: false,
                        add: false,
                        column: false,
                    }),
                    uuidColumn({
                        Header: "Destination",
                        entity: "delivery_location",
                        filter_fields: [{ accessor: "customer", dbname: "customer" }],
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                        column: false,
                    }),
                    numberColumn({
                        Header: "Quantity",
                        accessor: "number_of_units",
                        type: "weight",
                        column: false,
                    }),

                    numberColumn({
                        Header: "Vehicle weight",
                        accessor: "delivery_vehicle_weight",
                        type: "weight",
                        column: true,
                    }),

                    numberColumn({
                        Header: "Price Per Kg",
                        accessor: "price_per_unit",
                        type: "currency",
                        column: false,
                    }),
                    numberColumn({
                        accessor: "amount",
                        type: "currency",
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),

                    dateColumn({
                        accessor: "requested_date",
                        minDate: new Date(),
                        maxDate: new Date(moment().add(14, "days")),
                    }),
                    dateColumn({
                        accessor: "delivery_date",
                        minDate: new Date(),
                        dependsValue: ["status:scheduled"],
                    }),

                    // booleanColumn({ accessor: "grading", column: false }),
                    // numberColumn({
                    //     accessor: "grading_charges",
                    //     min: 0,
                    //     
                    // depends: ["grading"],
                    //     type: "currency",
                    //     column: false,
                    // }),

                    // booleanColumn({ accessor: "transportation", column: false }),
                    // numberColumn({
                    //     accessor: "transportation_charges",
                    //     min: 0,
                    //     
                    // depends: ["transportation"],
                    //     type: "currency",
                    //     column: false,
                    // }),

                    // booleanColumn({ accessor: "packaging", column: false }),

                    // defaultColumn({
                    //     accessor: "packaging_type",
                    //     
                  //  depends: ["packaging"],
                    //     type: "select",
                    //     options: enum_packaging_type,
                    //     column: false,
                    // }),

                    // numberColumn({
                    //     accessor: "packaging_charges",
                    //     
                 //   depends: ["packaging"],
                    //     type: "currency",
                    //     column: false,
                    //     min: 0,
                    // }),

                    numberColumn({
                        Header: "GST Charges",
                        accessor: "gst_charges",
                        type: "currency",
                        column: false,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "total_amount",
                        type: "currency",
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    numberColumn({
                        accessor: "requested_price",
                        column: false,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    numberColumn({
                        accessor: "negotiated_price",
                        column: false,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    imageColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    imageColumn({
                        Header:"Draft",
                        accessor: "items_draft",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    imageColumn({
                        accessor: "videos",
                        type: "video",
                        accept: /\.(mp4)$/i,
                        rules: null,
                        column: false,
                    }),
                    defaultColumn({
                        accessor: "documents",
                        type: "document",
                        rules: null,
                        column: false,
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        column: true,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;

       case entity === "export":
                options = {
                    entity: "export_order",
                    fields: `id
                    uid
                    commodity_id
                    commodity {
                        id
                        name
                      }
                    commodity_spec
                    quantity_in_mt
                    hs_code
                    status
                    no_of_box
                    location_id
                    location{ id name address }
                    customer_id
                    customer{ id name }
                    supplier_id
                    supplier { id name }
                    
                    active
                    type_of_packaging
                    wt_per_pack
                    price_per_pack
                    total_amount
                    supplier_inv_price supplier_inv_weight  supplier_inv_total_price 
                    inv_created_at
                    total_freight_amount
                    sailing_date
                    origin_port_id origin_port { address }
                    destination_port_id destination_port { address }
                    export_order_payments_aggregate {
                        aggregate {
                          sum {
                            amount
                          }
                        }
                      }
                    updated_at

                    created_at
                    
                            `,
                    columns: [
                        linkColumn({
                            Header: "Id",
                            displayOnly: true,
                            accessor: "uid",
                            link_id:"id",
                            field:"uid",
                            link: `/marketplace/export/detail/#id`,
                            add: false,
                            edit: false,
                        }),
                        uuidColumn({
                            Header: "Buyer",
                            entity: "customer",
                            dbname: "customer_id",
                            accessor: "customer",
                            typeahead_fields: `id name type: enum_customer_type{value comment}`,
                            filters: { customer_type: { _in: ["wholesaler"] } },
                            reset_fields: ["delivery_location"],
                       }),

                        uuidColumn({
                            Header: "Seller",
                            entity: "customer",
                            dbname: "supplier_id",
                            accessor: "supplier",
                            typeahead_fields: `id name type: enum_customer_type{value comment}`,
                            add: false,
                            edit: false,
                            filters: { customer_type: { _in: ["farmer", "trader"] } },
                            width:200,
                            minWidth:150
                        }),

                      
                        // defaultColumn({
                        //     Header: "Payment",
                        //     accessor: "export_order_payments_aggregate.aggregate.sum.amount",
                        //     width:200,
                        //     minWidth:150,
                        //     add:false,
                        //     edit:false
                        // }), 
                        defaultColumn({ accessor: "commodity_spec",width:100,minWidth:100 }),
                        defaultColumn({Header:"Commodity", accessor: "commodity.name",width:150,minWidth:100 }),
                        defaultColumn({ accessor: "quantity_in_mt",column:false,minWidth:200 }),
                        defaultColumn({ accessor: "no_of_box",column:false,minWidth:200 }),
                        defaultColumn({ accessor: "hs_code" ,column:false,width:150,minWidth:150}),
                        enumColumn({
                            accessor: "status",
                            options: enum_po_status,
                            type: "select",
                        }),
                        defaultColumn({
                          Header:"Sup-Inv. Price",
                          accessor: "supplier_inv_price", 
                          type: "text",
                          rules: null, 
                          add:false, 
                          edit:false,
                          column:false,
                          minWidth:200 
                        }),
                        defaultColumn({
                            Header:"Sup-Inv. Weight", 
                            accessor: "supplier_inv_weight", 
                            type: "text", 
                            rules: null, 
                            add:false, 
                            edit:false,
                            column:false,
                            minWidth:200
                         }),
                        defaultColumn({
                            Header:"Sup-Inv. Total Weight", 
                            accessor: "supplier_inv_total_price", 
                            type: "text", 
                            rules: null, 
                            add:false, 
                            edit:false,
                            column:false,
                            minWidth:200 
                        }),
 
                        uuidColumn({
                            entity: "location",
                            filters: { location_type: { _in: ["business"] } },
                            typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                            filter_fields: [{ accessor: "customer" }],
                        }),

                        uuidColumn({
                            Header:"Origin Port", 
                            entity: "location",
                            accessor:"origin_port",
                            dbname: "origin_port_id",
                            typeahead_fields: `id name type:address`, 
                            rules:null, 
                            column:false,
                            
                            filters: { location_type: { _in: [ "origin_port" ,"origin_port_icd"] } },
    
                            }),
    
                            uuidColumn({ 
                            Header:"Destination Port",
                            entity: "location",
                            accessor:"destination_port" ,
                            typeahead_fields: `id name type:address`,
                            rules:null,
                            column:false,                       
                             dependsValue: ["order_type:on_commission"],
    
                            filters: { location_type: { _in: ["destination_port"] } },
                            }),
    
                            datetimeColumn({ accessor: "sailing_date", type:"date", rules: null, edit:true, add:true }),
                            enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false }),
                            defaultColumn({  Header:"Freight Amount",accessor: "total_freight_amount", type: "text", rules: null , column:false,searchable: false,}),

                        datetimeColumn({
                            Header:"Sup-invoice created_at",
                            accessor: "inv_created_at",
                            rules: null,
                            displayOnly: true,
                            column: false,

                        }),
                       
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column: true,

                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],
                };
    
                break;

                case entity ==="export_shipping_line":
                    options = {
                     entity  : "export_shipping_line",
                     fields:` id uid images name created_at updated_at active`,
                     columns: [
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                            link: `/marketplace/shipping_line/detail/#id`,
                            link_id:"id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
    
                         }),
    
                       
                         defaultColumn({Header:"Shipping line Name", accessor: "name", type: "text", rules: null,  }),
     
    
                         imageColumn({
                          Header:"Logo",
                          type: "photo",
                          accessor: "images",
                          accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                          rules: null,
                          column:true,
                          minWidth:150
                      }),
    
    
               
    
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                            column:true
                        }),
                        activeColumn()
                    ],
                    };
    
                    break;
    
    
       case entity ==="freight_forwarder":
                    options = {
                      entity: "export_freight",
                      fields: ` 
                      id
                      uid
                      container_type enum_container_type { comment} 
                      origin_port_id origin_port{id name address}
                      destination_port_id destination_port{id name address}
                      customer_id customer{id name}
                      eta
                      etd
                      ocean_freight
                      cargo_category_type
                      bill_of_lading_charges
                      cmc_charges
                      seal_charges
                      temp_variation_charges
                      terminal_handling_charges
                      toll_charges
                      export_shipping_line_id
                      export_shipping_line{
                        id
                        name
                        images
                      }
                      do
                      bl
                      transportation_slip
                      vessel_risk
                      muf
                      active
                      total_amount
                      created_at
                      updated_at
                         `, 
                      columns: [
                        //   linkColumn({
                        //       Header: "Id",
                        //       // displayOnly: true,
                        //       accessor: "id",
                        //       // link: `/web/exporter/detail/#id`,
                        //       // link_id:"customer_id",
                        //       field:"id",
                        //       add: false,
                        //       edit: false,
                        //       column:true,
                        //       searchable: false,
      
                        //    }),


                         linkColumn({
                            Header: "Id",
                            displayOnly: true,
                            accessor: "uid",
                            link_id:"id",
                            field:"uid",
                            link: `/marketplace/freight_forwarder/detail/#id`,
                            add: false,
                            edit: false,
                        }),
                        //    linkColumn({
                        //     Header: "Id",
                        //     // displayOnly: true,
                        //     accessor: "uid",
                        //     // link: `/web/exporter/detail/#id`,
                        //     // link_id:"customer_id",
                        //     field:"uid",
                        //     add: false,
                        //     edit: false,
                        //     column:false,
                        //     searchable: false,
                        //     minWidth:80
    
                        //  }),
                        uuidColumn({
                            Header:"Freight Forwarder",
                            entity:"customer",
                           typeahead_fields: `id name`,
                           filters: { customer_type: { _in: ["freight_forwarder"] } },

                        //    dbname: "origin_port_id",
   
                       }),
      
                           uuidColumn({
                               Header:"Origin Port",
                               entity:"location",
                              accessor: "origin_port",
                              typeahead_fields: `id name`,
                              dbname: "origin_port_id",
                                     filters: { location_type: { _in: ["origin_port"] } },

      
                          }),
        
                          uuidColumn({
                              Header:"Destination Port",
                              entity: "location",
                              accessor:"destination_port",
                              typeahead_fields: `id name`,
                              dbname: "destination_port_id",
                              filters: { location_type: { _in: ["destination_port"] } },

      
                            }),
                            
                            uuidColumn({
                              entity: "export_shipping_line",
                              accessor:"export_shipping_line",
                              typeahead_fields: `id name`,
                              dbname: "export_shipping_line_id",
          
                            }),
      

      
                          enumColumn({
                              Header:"Cargo",
                              accessor: "cargo_category_type",
                              options: enum_cargo_category,
                              type: "select",
                              add: true,
                              edit: true,
                              column: true,
      
                          }),
                          enumColumn({
                            Header:"Container Size",
                            accessor: "container_type",
                            options: enum_container_type,
                            type: "select",
                            add: true,
                            edit: true,
                            column: true,
    
                        }),

                          dateColumn({Header:"ETD Origin", accessor: "etd", rules: null,   column: true, }),
                          dateColumn({Header:"ETA Destination", accessor: "eta", rules: null,   column: true, }),
                          numberColumn({
                            Header: "Ocean freight($)",
                            accessor: "ocean_freight",
                        }),
                          numberColumn({
                              Header: "Bill of Loading Charges",
                              accessor: "bill_of_lading_charges",
                          min:0

                          }),

                          numberColumn({
                            Header: "MUC Charges",
                            accessor: "muf",
                          min:0

                          }),
 

                          numberColumn({
                            Header: "vessel_risk Charges",
                            accessor: "vessel_risk",
                          min:0

                          }),

      
                           numberColumn({
                              Header: "CMC Charges",
                              accessor: "cmc_charges",
                          min:0

                          }),
      
                           numberColumn({
                              Header: "Seal Charges",
                              accessor: "seal_charges",
                          min:0,
                          notRequired:true,
                        dependsValue: ["cargo_category_type:reefer"],


                          }),
                          numberColumn({
                              Header: "Temp Variation Charges",
                              accessor: "temp_variation_charges",
                          min:0

                          }),
                
                          numberColumn({
                              Header: "Terminal Handling Charges",
                              accessor: "terminal_handling_charges",
                          min:0

                          }),
                           numberColumn({
                              Header: "Toll Charges",
                              accessor: "toll_charges",
                          min:0

                          }),
      
                          imageColumn({
                              type: "photo",
                              accessor: "do",
                              key:"do",
                              id:"do",
                              accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                              rules: null,
                          }),
      
                          imageColumn({
                              type: "photo",
                              accessor: "bl",
                              key:"bl",
                              id:"bl",
                              accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                              rules: null,
                          }),
      
                          imageColumn({
                              type: "photo",
                              accessor: "transportation_slip",
                              key:"transportation_slip",
                              id:"transportation_slip",
                              accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                              rules: null,
                          }),
                          datetimeColumn({
                              accessor: "created_at",
                              rules: null,
                              displayOnly: true,
                              column:true,
                          }),
                          datetimeColumn({
                              accessor: "updated_at",
                              rules: null,
                              isSorted: true,
                              isSortedDesc: true,
                              column:true
                          }),
                      ],
                    };
                    break;
                     
                    case entity === "other_freight_forwarder":
                        options = {
                          entity: "export_freight",
                          fields: ` 
                          id
                          uid
                          container_type enum_container_type { comment}
                          origin_port_id origin_port{id name address}
                          destination_port_id destination_port{id name address}
                          eta
                          etd
                          dollar_price 
                          ocean_freight
                          cargo_category_type enum_cargo_category{comment}
                          bill_of_lading_charges
                          cmc_charges
                          seal_charges
                          temp_variation_charges
                          terminal_handling_charges
                          toll_charges
                          listing_validity_date
                          export_shipping_line_id
                          export_shipping_line{
                            id
                            name
                            images
                          }
                          cut_off
                          transit_period
                          vessel
                          muf
                          vessel_risk
                          total_amount
                          validity 
                          active
                          customer{
                              id
                              name
                            }
                          
                          
                          freight_rank{
                          id
                          rank
                          }
          
                          created_at
                          updated_at
                             `, 
                          columns: [
                              linkColumn({
                                  Header: "Id",
                                  // displayOnly: true,
                                  accessor: "uid",
                                  // link: `/web/exporter/detail/#id`,
                                  // link_id:"customer_id",
                                  field:"uid",
                                  add: false,
                                  edit: false,
                                  column:false,
                                  searchable: false,
                                  minWidth:150,
                                  // miniColumnWidth:150
          
                               }),
          
                               uuidColumn({
                                  Header:"Origin Port",
                                  entity:"location",
                                  accessor: "origin_port",
                                  typeahead_fields: `id name`,
                                  dbname: "origin_port_id",
                                  labelKey:"name",
                                  filters: { location_type: { _in: [ "origin_port"] } },
                                  filterSpan:3,
                                  span:3,
                                  distinctOn:true,
                                  distinctOnLabel:"origin_port.name"
          
                              }),
            
                              uuidColumn({
                                  Header:"Destination Port",
                                  entity: "location",
                                  accessor:"destination_port",
                                  typeahead_fields: `id name`,
                                  dbname: "destination_port_id",
                              
                                  filters: { location_type: { _in: [ "destination_port"] } },
                                  filterSpan:3,
                                  span:3,
                                  distinctOn:true,
                                  distinctOnLabel:"destination_port.name"
                                }),
          
                              enumColumn({
                                  Header:"Container",
                                  accessor: "container_type",
                                  options: enum_container_type,
                                  type: "select",
                                  add: true,
                                  edit: true,
                                  column: true,
                                  // shareWith:enum_cargo_category,
                                  // shareValue:"cargo_category_type"
          
                              }),
          
                                                  
                              uuidColumn({
                                  Header:"Carrier",
                                  entity: "export_shipping_line",
                                  accessor:"export_shipping_line",
                                  typeahead_fields: `id name`,
                                  dbname: "export_shipping_line_id",
                                  searchable:false,
                                  filterable:false
                                  // hideAdd:true
                                }),
          
          
          
          
          
                              enumColumn({
                                  Header:"Cargo",
                                  accessor: "cargo_category_type",
                                  options: enum_cargo_category,
                                  type: "select",
                                  add: true,
                                  edit: true,
                                  column: true,
                                  filterable:false,
                                  // minWidth:80,
                                  // width:80,
                                  // filterable:true
                                  // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
          
                              }),
          
          
                  
                              
          
          
                              // dateColumn({ accessor: "cut_off",rules: null,   column: true, add:true, edit:true }),
                              dateColumn({ accessor: "etd", rules: null,   column: true, add:true, edit:true, }),
                              dateColumn({FilterFormHeader:"MAX ETA", Header:"ETA", accessor: "eta", rules: null,   column: true, add:true, edit:true,filterable:true  }),
                           
                              numberColumn({
                                  Header: "freight($)",
                                  accessor: "ocean_freight",
                                  // minWidth:80,
                                  // width:80,
                              }),
          
          
                            //   defaultColumn({Header:"Rank", accessor: "freight_rank.rank" }),
                                                  
                              // numberColumn({
                              //     Header: "Freight($)",
                              //     accessor: "total_amount",
                              //     displayOnly:true
                              // }),
          
          
          
                              //   uuidColumn({
                              //     Header:"Freight Forwarder",
                              //     entity:"customer",
                              //     typeahead_fields: `id name`,
                              //     searchable:false,
                              //     filterable:false
          
                              //   }),
          
                              numberColumn({
                                  Header: "MUC Charges",
                                  accessor: "muf",
                                  column:false,
          
                              }),
          
          
                              numberColumn({
                                  Header: "vessel_risk Charges",
                                  accessor: "vessel_risk",
                                  column:false,
          
                              }),
          
                               
                               numberColumn({
                                  Header: "Bill of Lading Charges",
                                  accessor: "bill_of_lading_charges",
                                  column:false,
          
                              }),
          
                              numberColumn({
                                  Header: "Exchange Rate",
                                  accessor: "dollar_price",
                                  column:false,
          
                              }),
                               numberColumn({
                                  Header: "CMC Charges",
                                  accessor: "cmc_charges",
                                  column:false,
          
                              }),
          
                               numberColumn({
                                  Header: "Seal Charges",
                                  accessor: "seal_charges",
                                  column:false,
                                  notRequired:true
          
          
                              }),
                              numberColumn({
                                  Header: "Temp Variation Charges",
                                  accessor: "temp_variation_charges",
                                  column:false,
                                  dependsValue: ["cargo_category_type:reefer"],
          
                              }),
                            
                              numberColumn({
                                  Header: "Terminal Handling Charges",
                                  accessor: "terminal_handling_charges",
                                  column:false,
          
                              }),
          
                        
          
                               numberColumn({
                                  Header: "Toll Charges",
                                  accessor: "toll_charges",
                                  column:false,
          
                              }),
          
                              // numberColumn({
                              //     Header: "Transit Days",
                              //     accessor: "transit_period",
                              // }),
          
                              // enumColumn({ accessor: "status", options: enum_freight_transit_status, type: "select",    searchable: false, }),
                              numberColumn({
                                  Header: "Transit Period",
                                  accessor: "transit_period",
                                  column:false,
          
                              }),
                              
                                  
                              defaultColumn({Header:"Vessel Name", accessor: "vessel", column:false,}),
                             
                              // dateColumn({ accessor: "validity", rules: null,   column: true, add:true, edit:true }),
                                 
                            //   booleanColumn({Header:"Active",accessor:"active",add:true, edit:true, column:false, searchable:false, filterable:false}),
                              
                              datetimeColumn({
                                  accessor: "listing_validity_date",
                                  rules: null,
                                  // displayOnly: true,
                                  add:true,
                                  edit:true,
                                  column:false,
                              }),
                              datetimeColumn({
                                  accessor: "created_at",
                                  rules: null,
                                  displayOnly: true,
                                  column:false,
                              }),
                              datetimeColumn({
                                  accessor: "updated_at",
                                  rules: null,
                                  isSorted: true,
                                  isSortedDesc: true,
                                  column:false
                              }),
          
          
                              
          
                          ],
                        };
                        break;
                    
           
                    case entity === "freight_order":
                        options = {
                          entity: "export_freight_order",
                          fields: ` 
                          id uid
                          exporter_id exporter{ id name phone }
                          export_freight_id export_freight{ id ocean_freight }
                          invoice
                          transportation_slip bl_slip do_slip status created_at updated_at active
                          shipping_instructions_cut_off
                          vgm_cut_off
                          gate_cut_off
                          booking_validity
                          status
                          payment_status
                           
                          invoice_no
                          invoice_amount
                          gate_opens
                          draft_bl_slip
                          booking_validity_status
                          shipping_ins_status
        
                          draft_bl_status
                          quantity
                          cargo_wt
                          humidity
                          temperature
                          ventilation
                          cost
                          vgm_status
                          vgm_cut_off_date
                          gate_in_cut_off_date
                             `, 
                          columns: [
                              linkColumn({
                                  Header: "Id",
                                  // displayOnly: true,
                                  accessor: "uid",
                                  link: `/marketplace/freight_order/detail/#id`,
                                  link_id:"id",
                                  field:"uid",
                                  add: false,
                                  edit: false,
                                  column:true,
                                  searchable: false,
                                  minWidth:50
          
                               }),
          
                               uuidColumn({
                                Header:"Exporter",
                                entity: "customer",
                                accessor:"exporter",
                                dbname: "exporter_id",
                                // solution: "marketplace",
                                filters: { customer_type: { _in: ["exporter"] } },
                                typeahead_fields: `id name type: enum_customer_type{value comment}`,
                            }),
                             
                            //    defaultColumn({Header:"Exporter Name", accessor: "exporter.name", type: "text", rules: null,  }),
                               defaultColumn({Header:"Exporter Ph.", accessor: "exporter.phone", type: "text", rules: null,  edit:false,minWidth:100  }),
                               defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", type: "text", rules: null,  edit:false   }),
                                  
                               enumColumn({
                                accessor: "status",
                                options: enum_freight_status,
                                type: "select",
                                add: true,
                                edit: true,
                                column: true,
                                rules:null
        
                            }),
                            enumColumn({ accessor: "booking_validity_status", options: enum_exp_fri_ord_booking_val_status, type: "select",    searchable: false, }),

                            enumColumn({ accessor: "shipping_ins_status", options: enum_exp_fri_ord_shipping_ins_status, type: "select",    searchable: false, }),
        
                            enumColumn({ accessor: "draft_bl_status", options: enum_exp_fri_ord_draft_bl_status , type: "select",    searchable: false, }),
                         
                            enumColumn({ accessor: "vgm_status", options: enum_exp_fri_ord_vgm_status , type: "select",    searchable: false, }),
        
                            defaultColumn({ accessor: "quantity", type: "text", rules: null,  edit:false   }),
                            defaultColumn({  accessor: "cargo_wt", type: "text", rules: null,  edit:false   }),
                            defaultColumn({  accessor: "humidity", type: "text", rules: null,  edit:false   }),
                            defaultColumn({ accessor: "temperature", type: "text", rules: null,  edit:false   }),
                            defaultColumn({ accessor: "ventilation", type: "text", rules: null,  edit:false   }),
                            defaultColumn({ accessor: "cost", type: "text", rules: null,  edit:false ,minWidth:100  }),
        
                            datetimeColumn({
                                accessor: "vgm_cut_off_date",
                                rules: null,
                                displayOnly: true,
                                column:true,
                            }),
        
                            datetimeColumn({
                                accessor: "gate_in_cut_off_date",
                                rules: null,
                                displayOnly: true,
                                column:true,
                            }),
        
                            enumColumn({
                                Header:"Payment",
                                accessor: "payment_status",
                                options: enum_export_freight_order_payment_status,
                                type: "select",
                                add: true,
                                edit: true,
                                column: true,
                                rules:null,
                                searchable:false
        

                            }),


                               
        
                               imageColumn({
                                Header:"Transportation Slip",
                                type: "photo",
                                key:"transportation_slip",
                                id:"transportation_slip",
                                accessor: "transportation_slip",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                            }),


                            imageColumn({
                                Header:"DO Slip",
                                type: "document",
                                accessor: "do_slip",
                                key:"do_slip",
                                id:"do_slip",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                privateImage:true,
                            }),

                            imageColumn({
                                Header:"Draft Bl Slip(PDF)",
                                type: "document",
                                accessor: "draft_bl_slip",
                                key:"draft_bl_slip",
                                id:"draft_bl_slip",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                column:true,
                                minWidth:125,
                                searchable:false,
                                // dependsValue: ["status:completed", ],         
                                privateImage:true
                            }),
        
                                    
                            imageColumn({
                                Header:"VGM cut off",
                                type: "document",
                                accessor: "vgm_cut_off",
                                id: "vgm_cut_off",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                add:true,
                                edit:true,
                                column:true,
                                minWidth:80,
                                width:100   ,               
                                privateImage: true,       
    
                            }),


                            imageColumn({
                                Header:"CLP/Form13(PDF)",
                                type: "document",
                                accessor: "gate_cut_off",
                                id:'gate_cut_of',
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                add:false,
                                edit:false,
                                column:true,
                                minWidth:80,
                                width:100        ,
                                privateImage:true               
               
        
                            }),
        

                            imageColumn({
                                Header:"OBL Slip",
                                type: "document",
                                accessor: "bl_slip",
                                key:"bl_slip",
                                id:"bl_slip",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                privateImage:true,

                            }),
        

                            imageColumn({
                                Header:"Invoice",
                                type: "document",
                                accessor: "invoice",
                                key:"invoice",
                                id:"invoice",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                privateImage:true,

                            }),
        

               
                            defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null  }),
                            numberColumn({ accessor: "invoice_amount" }),
        
                              datetimeColumn({
                                  accessor: "created_at",
                                  rules: null,
                                  displayOnly: true,
                                  column:true,
                              }),
                              datetimeColumn({
                                  accessor: "updated_at",
                                  rules: null,
                                  isSorted: true,
                                  isSortedDesc: true,
                                  column:true
                              }),
                              activeColumn()
                          ],
                        };
                        break;
        
       case entity === "shipping":
                    options = {
                        entity: "export_shipping_line",
                        fields: `id uid name images updated_at created_at
                                `,
                        columns: [
                            linkColumn({
                                Header: "Id",
                                displayOnly: true,
                                accessor: "uid",
                                link_id:"id",
                                field:"uid",
                                // link: `/marketplace/offer/detail/#id`,
                                add: false,
                                edit: false,
                                minWidth:150    
                            }),
                            defaultColumn({ accessor: "name",minWidth:200 }),

                            imageColumn({
                                Header:"images",
                                accessor: "images",
                                type: "image",
                                accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                                rules: null,
                                column: true,
                                
                            }),
                             
                            datetimeColumn({
                                accessor: "created_at",
                                rules: null,
                                displayOnly: true,
                                column: true,
    
                            }),
                            datetimeColumn({
                                accessor: "updated_at",
                                rules: null,
                                column: true,
                                isSorted: true,
                                isSortedDesc: true,
                            }),
                        ],
                    };
        
                    break;
        
        case entity === "offer":
            options = {
                entity: "offer",
                fields: `id uid name active created_at updated_at   customer_id customer{id name}
                        shape_id shape{id name} size_id size{id name} color_id color{id name}
                        location_id location{id name address location_type enum_location_type {comment} }
						commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
                        number_of_units price_per_unit images videos documents status enum_offer_status{comment} amount
                        change_history(order_by:{created_at: desc_nulls_last}){id new_value old_value status created_at enum:enum_offer_status{comment}}
						available_date
                        `,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        link: `/marketplace/offer/detail/#id`,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_offer_status,
                        type: "select",
                        add: false,
                    }),
                    fillColumn(),
                    uuidColumn({
                        entity: "customer",
                        solution: "marketplace",
                        filters: { customer_type: { _in: ["farmer", "trader"] } },
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                    }),
                    uuidColumn({
                        entity: "location",
                        filters: { location_type: { _in: ["farm", "mandi"] } },
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    dateColumn({
                        Header: "Available Date",
                        accessor: "available_date",
                    }),
                    // dateColumn({ accessor: "start_date", valueAsNumber: true }),

                    uuidColumn({
                        entity: "commodity_variety",
                        reset_fields: ["shape", "size", "color"],
                        typeahead_fields: `id name type: commodity{id name}`,
                    }),
                    uuidColumn({
                        Header: "Color",
                        entity: "view_catalogue_commodity_variety",
                        accessor: "color",
                        dbname: "color_id",
                        filter_fields: [{ accessor: "commodity_variety" }],
                        activeFilter: false,
                        filters: { catalogue: { catalogue_type: { _eq: "color" } } },
                        typeahead_fields: `id:catalogue_id name`,
                        column: false,
                        filterable: false,
                    }),

                    uuidColumn({
                        Header: "Shape",
                        entity: "view_catalogue_commodity_variety",
                        accessor: "shape",
                        dbname: "shape_id",
                        filter_fields: [{ accessor: "commodity_variety" }],
                        activeFilter: false,
                        filters: { catalogue: { catalogue_type: { _eq: "shape" } } },
                        typeahead_fields: `id:catalogue_id name`,
                        column: false,
                        filterable: false,
                    }),

                    uuidColumn({
                        Header: "Size",
                        entity: "view_catalogue_commodity_variety",
                        accessor: "size",
                        dbname: "size_id",
                        filter_fields: [{ accessor: "commodity_variety" }],
                        activeFilter: false,
                        filters: { catalogue: { catalogue_type: { _eq: "size" } } },
                        typeahead_fields: `id:catalogue_id name`,
                        column: false,
                        filterable: false,
                    }),

                    numberColumn({ accessor: "number_of_units", type: "weight" }),
                    numberColumn({ accessor: "price_per_unit", type: "currency" }),
                 


                    defaultColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                        // filterable: false,
                    }),
                    defaultColumn({
                        accessor: "videos",
                        type: "video",
                        accept: /\.(mp4)$/i,
                        rules: null,
                        column: false,
                        // filterable: false,
                    }),
                    defaultColumn({
                        accessor: "documents",
                        type: "document",
                        rules: null,
                        column: false,
                        filterable: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        column: true,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };

            break;

      
        case entity === "order":
            options = {
                entity: "order",
                fields: `id uid name active po created_at updated_at status  items_draft enum_order_status{comment}  purchase_amount 
					grading_type enum_grading_type{comment value}  enum_credit_days_type {comment value}
                 credit_days shape_id shape{id name} size_id size{id name} color_id color{id name}
                offer_id offer{id name uid}  images videos documents order_type enum_order_type{comment value}
                grading grading_charges transportation transportation_charges
                packaging packaging_type enum_packaging_type{comment}
                packaging_charges gst_charges requested_price negotiated_price offered_price delivery_date total_amount
				supplier_id supplier{id name} pickup_location_id pickup_location{id name address location_type enum_location_type {comment} }
				commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
				customer_id customer{id name} delivery_location_id delivery_location{id name address location_type enum_location_type {comment} }
				number_of_units price_per_unit available_date amount requested_date delivery_vehicle_weight
            	change_history(order_by:{created_at: desc_nulls_last}){id new_value old_value status created_at enum:enum_order_status{comment}}
				commission_amount is_tag_to_offer
               
				catalogue_commission {
					active
					commission
					created_at
					days
					uid
					updated_at
					id
				  }	
				  loading_charges
				  unloading_charges
				  buyer_commission
				  supplier_commission

				  buyer_amount
				  supplier_amount

                  supplier_price
                  buyer_price

				  grv_commission
				  grv_profit

                  buyer_selling_rate_per_kg
                  buyer_selling_grn_weight
                  buyer_selling_price

                  jobcards{
                    transportation_cost
                  }
				 
                `,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        link: `/marketplace/order/detail/#id`,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_order_status,
                        type: "select",
                        add: false,
                    }),
                    booleanColumn({ accessor: "is_tag_to_offer" }),

                    defaultColumn({
                        accessor: "po",
                        type: "document",
                        rules: null,
                        column: false,
                        edit:false,
                        add:false,
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,
                    }),

                    fillColumn(),
                    uuidColumn({
                        entity: "commodity_variety",
                        reset_fields: ["offer"],
                        typeahead_fields: `id name type: commodity{id name}`,
                        // edit: false,
                        // add: false,
                    }),

                    uuidColumn({
                        entity: "offer",
                        filters: { status: { _in: ["open"] } },
                        filter_fields: [{ accessor: "commodity_variety" }],
                        typeahead_fields: `id name shape_id shape { name } size_id size { name } color_id color { name }  type: enum_offer_status{value comment}`,

                        // add: false,
                        // edit: false,
                    }),
                    uuidColumn({
                        Header: "Seller",
                        entity: "customer",
                        dbname: "supplier_id",
                        accessor: "supplier",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        add: false,
                        edit: false,
                        filters: { customer_type: { _in: ["farmer", "trader"] } },
                    }),
                    enumColumn({
                        accessor: "order_type",
                        options: enum_order_type,
                        type: "select",
                        column: true,
                        span: 4,
                    }),
                    imageColumn({
                        Header:"Draft",
                        accessor: "items_draft",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: true,
                        add:false,
                        edit:false
                    }),
                    enumColumn({
                        accessor: "grading_type",
                        options: enum_grading_type,
                        type: "select",
                        column: false,
                        span: 4,
                    }),

                    enumColumn({
                        accessor: "credit_days",
                        options: enum_credit_days_type,
                        dependsValue: ["order_type:on_order"],

                        type: "select",
                        column: false,
                        span: 4,
                        rules: null,
                    }),
                    uuidColumn({
                        Header: "Commission Days (%)",
                        labelKey: "days",
                        dependsValue: ["order_type:on_commission"],

                        entity: "catalogue_commission",
                        //    accessor:"catalogue_commission.days",
                        typeahead_fields: `id commission days type:days commission `,
                        hideAdd: true,
                        rules: null,
                    }),
                    //   uuidColumn({
                    // 	entity: "catalogue_commission",
                    // 	accessor: "catalogue_commission.days",
                    // 	labelKey:"days",
                    // 	rules: null,
                    // 	// filter_fields: [{ accessor: "catalogue_commission" }],
                    // 	// typeahead_fields: `id  type: catalogue_commission{commission days}`,
                    // }),

                    uuidColumn({
                        Header: "Buyer",
                        entity: "customer",
                        dbname: "customer_id",
                        accessor: "customer",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        filters: { customer_type: { _in: ["wholesaler"] } },
                        reset_fields: ["delivery_location"],
                    }),

                    numberColumn({
                        Header: "Buyer Price",
                        accessor: "buyer_price",
                        type: "currency",
                        min: 0,

                        add: false,
                        edit: false,
                    }), 
                     numberColumn({
                        Header: "Purchase Amount",
                        accessor: "purchase_amount",
                        type: "currency",
                        min: 0,

                       
                    }),

                    numberColumn({
                        Header: " Buyer Selling Rate Per KG",
                        accessor: "buyer_selling_rate_per_kg",
                        type: "currency",
                        min: 0,
                        rules: null,
                        dependsValue: ["order_type:on_commission"],
                    }),

                    numberColumn({
                        Header: "Buyer Selling Grn Weight",
                        accessor: "buyer_selling_grn_weight",
                        type: "currency",
                        min: 0,
                        rules: null,

                        dependsValue: ["order_type:on_commission"],
                    }),

                    numberColumn({
                        Header: "Buyer Selling Price",
                        accessor: "buyer_selling_price",
                        type: "currency",
                        add: false,
                        edit: false,
                        min: 0,
                        // dependsValue: ["order_type:on_order"],
                    }),

                    numberColumn({
                        Header: "Supplier Price",
                        accessor: "supplier_price",
                        type: "currency",
                        dependsValue: ["order_type:on_order"],
                        min: 0,
                    }),

                    uuidColumn({
                        Header: "Origin",
                        entity: "pickup_location",
                        filter_fields: [{ accessor: "supplier", dbname: "customer_id" }],
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                        edit: false,
                        add: false,
                        column: false,
                    }),
                    uuidColumn({
                        Header: "Destination",
                        entity: "delivery_location",
                        filter_fields: [{ accessor: "customer", dbname: "customer" }],
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                        column: false,
                    }),
                    numberColumn({
                        Header: "Quantity",
                        accessor: "number_of_units",
                        type: "weight",
                        column: false,
                    }),

                    numberColumn({
                        Header: "Vehicle weight",
                        accessor: "delivery_vehicle_weight",
                        type: "weight",
                        column: true,
                    }),

                    numberColumn({
                        Header: "Price Per Kg",
                        accessor: "price_per_unit",
                        type: "currency",
                        column: false,
                    }),
                    numberColumn({
                        accessor: "amount",
                        type: "currency",
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),

                    dateColumn({
                        accessor: "requested_date",
                        minDate: new Date(),
                        maxDate: new Date(moment().add(14, "days")),
                    }),
                    dateColumn({
                        accessor: "delivery_date",
                        minDate: new Date(),
                        dependsValue: ["status:scheduled"],
                    }),

                    // booleanColumn({ accessor: "grading", column: false }),
                    // numberColumn({
                    //     accessor: "grading_charges",
                    //     min: 0,
                    //     
                    // depends: ["grading"],
                    //     type: "currency",
                    //     column: false,
                    // }),

                    // booleanColumn({ accessor: "transportation", column: false }),
                    // numberColumn({
                    //     accessor: "transportation_charges",
                    //     min: 0,
                    //     
                    // depends: ["transportation"],
                    //     type: "currency",
                    //     column: false,
                    // }),

                    // booleanColumn({ accessor: "packaging", column: false }),

                    // defaultColumn({
                    //     accessor: "packaging_type",
                    //     
                  //  depends: ["packaging"],
                    //     type: "select",
                    //     options: enum_packaging_type,
                    //     column: false,
                    // }),

                    // numberColumn({
                    //     accessor: "packaging_charges",
                    //     
                 //   depends: ["packaging"],
                    //     type: "currency",
                    //     column: false,
                    //     min: 0,
                    // }),

                    numberColumn({
                        Header: "GST Charges",
                        accessor: "gst_charges",
                        type: "currency",
                        column: false,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "total_amount",
                        type: "currency",
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    numberColumn({
                        accessor: "requested_price",
                        column: false,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                    numberColumn({
                        accessor: "negotiated_price",
                        column: false,
                        add: false,
                        edit: false,
                        displayOnly: true,
                    }),
                 
                    imageColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    imageColumn({
                        accessor: "videos",
                        type: "video",
                        accept: /\.(mp4)$/i,
                        rules: null,
                        column: false,
                    }),
                    defaultColumn({
                        accessor: "documents",
                        type: "document",
                        rules: null,
                        column: false,
                        // eslint-disable-next-line no-useless-escape
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        column: true,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };

            break;

        case entity === "payment":
        case entity === "order_payment":
            options = {
                entity: "order_payment",
                fields: `id uid active created_at updated_at status enum_payment_status{value comment} payment_gateway
                order_id order{id name uid} amount name timestamp
                payment_gateway payment_type pg_order_id pg_payment_id
                pg_collection_fee pg_tax_amount refunded_amount refunded_at refund_attempts err_code err_desc
                change_history(order_by:{created_at: desc_nulls_last}){id new_value old_value status created_at enum:enum_order_status{comment}}
				images transaction_id
                `,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        link: `/marketplace/payment/detail/#id`,
                        add: false,
                        edit: false,
                    }),
                    numberColumn({ accessor: "amount", type: "currency" }),
                    defaultColumn({
                        accessor: "transaction_id",
                        type: "text",
                        rules: null,
                        // column: false,
                    }),
                    enumColumn({
                        accessor: "payment_gateway",
                        type: "select",
                        options: enum_payment_gateway,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_payment_status,
                        type: "select",
                        add: false,
                    }),
                    datetimeColumn({
                        Header: "payment received at",
                        accessor: "timestamp",
                        rules: null,
                        add: true,
                        edit: true,
                    }),
                    defaultColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    fillColumn(),
                    uuidColumn({
                        entity: "order",
                        typeahead_fields: `id name type: enum_order_status{value comment}`,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;

        case entity === "supplier":
        case entity === "customer":
        case entity === "farmer":
        case entity === "trader":
        case entity === "kissanmitra":
        case entity === "mandimitra":
        case entity === "agent":
        case entity === "moderator":
        case entity === "user":
        case entity === "coldstorager":
        case entity === "wholesaler":
        case entity === "transporter":
        case enum_customer_type?.includes(entity):
        case enum_user_role?.includes(entity):
            if (solution === "trade" || solution === "marketplace" || entity === "customer")
                options = {
                    entity: "customer",
                    fields: ` id uid  created_at po updated_at payment_terms_type  uid commodity_id order_id active customer_type enum_customer_type{comment} supplier_commission_percent  commission_percent payment_days
				name address postcode lat lng state district area city country phone whatsapp email year_of_establishment photo_url notes
				active_banks: customer_banks_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				active_locations: locations_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				active_documents: customer_documents_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				active_contacts: customer_contacts_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				total_banks: customer_banks_aggregate{aggregate{count}}
				total_locations: locations_aggregate{aggregate{count}}
				total_documents: customer_documents_aggregate{aggregate{count}}
				total_contacts: customer_contacts_aggregate{aggregate{count}}
                customer_documents{images}                      
                buyer_commission_percent
                supplier_order_percent  
                buyer_order_percent
                users{
                    id uid active created_at updated_at  name email phone photo_url is_phone_verified is_email_verified role 
			     	customer_id customer{ id name customer_documents{images} }
                }
				active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				total_offers: offers_aggregate{aggregate{count}}
				total_orders: orders_aggregate{aggregate{count}}
                total_active_exporter: exporter_commodities_aggregate(where:{active:{_eq:true}}){aggregate{count}}
                total_exporter: exporter_commodities_aggregate{aggregate{count}}
                total_active_exporter_comm:  exporter_commodities_aggregate(where:{active:{_eq:true}}){aggregate{count}}
                total_exporter_comm:  exporter_commodities_aggregate{aggregate{count}}
                active_nbfc: nbfcs_aggregate(where:{active:{_eq:true }}){aggregate{count}}
                total_nbfc: nbfcs_aggregate{aggregate{count}}


                `,
                    userColumns:{

                         skipColumn:{"payment_days":true,address:true}    , 
                         skipEnum  :{"customer_type":{"wholesaler":true, "photo_url":true,"trader":true,"farmer":true,"transporter":true,"exporter":true,vendor:"true","importer":true,"freight_forwarder":true,"coldstorager":true}},
                         exporterOverride:{"customer_type":"supplier"}
                        } ,

                    columns: [
                        
                        defaultColumn({ Header:"ID", accessor: "uid", displayOnly: true, add:false, edit:false, column:true }),

                        linkColumn({
                            accessor: "name",
                            link: `/trade/customer/detail/#id`,
 }),
                        enumColumn({
                            Header: "Type",
                            accessor: "customer_type",
                            options: enum_customer_type,
                            type: "select",
                        }),

                        enumColumn({
                            Header: "payment_terms_type",
                            accessor: "payment_terms_type",
                            options: enum_vendor_payment_terms,
                            type: "select",
                            simpleColumnDepends:{"vendor":true},
                            dependsValue: ["customer_type:vendor"]  ,   

                        }),

                        uuidColumn({
                            entity: "commodity",
                            typeahead_fields: `id name`,
                            dependsValue: ["customer_type:vendor"]        ,
                            simpleColumnDepends:{"vendor":true},                     

                        }),

                        defaultColumn({ accessor: "email", rules: null }),
                        defaultColumn({
                            accessor: "phone",
                            rules: { pattern: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/ },
                        }),
                        defaultColumn({
                            accessor: "whatsapp",
                            rules: { pattern: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/ },
                        }),
                        // numberColumn({
                        //     Header: "Supplier Commisison(%)",
                        //     accessor: "supplier_commission_percent",
                        //     type: "currency",
                        //     column: false,
                        //     min: 0,
                        //     rules: null,
                        // }),

                        // numberColumn({
                        //     Header: "Buyer  Commission(%)",
                        //     accessor: "buyer_commission_percent",
                        //     type: "currency",
                        //     column: false,
                        //     min: 0,
                        //     rules: null,
                        // }),

                        // numberColumn({
                        //     Header: "Supplier  Order(%)",
                        //     accessor: "supplier_order_percent",
                        //     type: "currency",
                        //     column: false,
                        //     min: 0,
                        //     rules: null,
                        // }),

                        // numberColumn({
                        //     Header: "Buyer  Order(%)",
                        //     accessor: "buyer_order_percent",
                        //     type: "currency",
                        //     column: false,
                        //     min: 0,
                        //     rules: null,
                        // }),

                        numberColumn({
                            Header: "Payment days",
                            accessor: "payment_days",
                            column: false,
                            min: 0,
                            rules: null,
                        }),
                        mapColumn(),
                        defaultColumn({
                            accessor: "area",
                            rules: null,
                            add: false,
                            edit: false,
                            column: false,
                        }),
                        defaultColumn({
                            accessor: "city",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        defaultColumn({
                            accessor: "district",
                            rules: null,
                            add: false,
                            edit: false,
                            column: false,
                        }),
                        defaultColumn({
                            accessor: "state",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        defaultColumn({
                            accessor: "country",
                            rules: null,
                            add: false,
                            edit: false,
                            column: false,
                        }),
                        defaultColumn({
                            accessor: "postcode",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        imageColumn({
                            accessor: "photo_url",
                            rules: null,
                            type: "photo",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                        }),
                        activeColumn(),
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],
                };
            else
                options = {
                    entity: "user",
                    fields: `
				id uid rating active created_at updated_at  name email phone photo_url is_phone_verified is_email_verified role 
                customer_id
                customer{
                  locations{
                    id
                    name
                    location_type
                  }
                }
                  user_organization_id
                  user_organization{
                    business_type
                    id
                    name
                  }

				active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
				total_offers: offers_aggregate{aggregate{count}}
				total_orders: orders_aggregate{aggregate{count}}
				`,
                    columns: [
                        linkColumn({
                            accessor: "name",
                            link: `/${solution}/user/detail/#id`,
                            check:entity
                        }),
                        defaultColumn({ accessor: "email" }),
                        defaultColumn({ accessor: "phone" }),
                        defaultColumn({ accessor: "rating", type:"rating" }),
                        // imageColumn({
                        //     type: "image",
                        //     accessor: "customer.customer_documents[0].images",
                        //     accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                        //     rules: null,
                        //     multiple: true,
                        //     private:true
                        // }),
                        imageColumn({
                            type: "photo",
                            accessor: "photo_url",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                            rules: null,
                        }),

                        booleanColumn({
                            accessor: "is_phone_verified",
                            add: false,
                            edit: false,
                        }),
                        booleanColumn({
                            accessor: "is_email_verified",
                            add: false,
                            edit: false,
                        }),
                        enumColumn({
                            accessor: "role",
                            options: enum_user_role,
                            type: "select",
                        }),
                      
                        // uuidColumn({
                        //     Header: "Customer",
                        //     accessor: "customer",
                        //     entity: "customer",
                        //     // dbname:"customer",
                        //     typeahead_fields: `id name type: name id `,
                        // }),

                        activeColumn(),

                        
                        uuidColumn({
                            Header: "Organization",
                            accessor: "user_organization",
                            entity: "user_organization",
                            // dbname:"customer",
                            typeahead_fields: `id name type: name id `,
                        }),


                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],
                };
            break;

        // case (entity = "vehicle_contact"):
        case entity === "vehicle_contact":
            options = {
                // entity: "vehicle_contact",
                fields: `   id   active created_at updated_at notes vehicle {id name    } location_id location{id name address location_type enum_location_type {comment} } contact_id contact{id name phone whatsapp} contact_type
                `,
                columns: [
                    enumColumn({
                        accessor: "contact_type",
                        options: enum_contact_type,
                        type: "select",
                    }),
                    uuidColumn({
                        entity: "vehicle",
                        typeahead_fields: `id name type: enum_vehicle_type{value comment}`,
                    }),
                    uuidColumn({
                        entity: "contact",
                        typeahead_fields: `id name phone whatsapp email`,
                    }),
                    defaultColumn({
                        Header: "Phone",
                        accessor: "contact.phone",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Whatsapp",
                        accessor: "contact.whatsapp",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Email",
                        accessor: "contact.email",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "location",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                        rules: null,
                    }),
                    defaultColumn({
                        accessor: "notes",
                        rules: null,
                        type: "textarea",
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;

        case entity === "contact":
        case entity === "customer_contact":
            if (solution === "trade")
                options = {
                    entity: "customer_contact",
                    fields: `id uid active created_at updated_at notes 
                    customer {id name customer_type commission_percent payment_days 
                    enum_customer_type{comment}} location_id 
                    location{id name address location_type enum_location_type {comment} } 
                    contact_id contact{id name phone whatsapp} contact_type`,
                    columns: [
                        enumColumn({
                            accessor: "contact_type",
                            options: enum_contact_type,
                            type: "select",
                        }),
                        uuidColumn({
                            entity: "customer",
                            typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        }),
                        uuidColumn({
                            entity: "contact",
                            typeahead_fields: `id name phone whatsapp email`,
                        }),
                        defaultColumn({
                            Header: "Phone",
                            accessor: "contact.phone",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        defaultColumn({
                            Header: "Whatsapp",
                            accessor: "contact.whatsapp",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        defaultColumn({
                            Header: "Email",
                            accessor: "contact.email",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        uuidColumn({
                            entity: "location",
                            typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                            rules: null,
                        }),
                        defaultColumn({
                            accessor: "notes",
                            rules: null,
                            type: "textarea",
                            column: false,
                        }),
                        activeColumn(),
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],  
                };
            else
               
                options = {
                    entity: "contact",
                    fields: `id uid active created_at updated_at name phone whatsapp email `,
                    remove_fields:[ "customer_id"],
                    columns: [
                        linkColumn({
                            accessor: "name",
                            link: `/${solution}/contact/detail/#id`,
                        }),
                        defaultColumn({
                            accessor: "phone",
                            rules: { pattern: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/ },
                        }),
                        defaultColumn({
                            accessor: "whatsapp",
                            rules: { pattern: /^(\+91[\s]?)?[0]?(91)?[6789]\d{9}$/ },
                        }),
                        defaultColumn({ accessor: "email", rules: null }),
                        defaultColumn({
                            accessor: "notes",
                            rules: null,
                            type: "textarea",
                            column: false,
                        }),
                        activeColumn(),
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],
                };
            break;
        case entity === "customer_document":
        case entity === "document":
            options = {
                entity: "customer_document",
                fields: `id uid active name  created_at updated_at id document_type  document images uid updated_at customer_id customer{id name}`,
                 userColumns:{

                    skipColumn:{"customer":true, }    , 
                    skipEnum  :{"document_type":{passport:true,voterid:true,drivinglicense:true,form:true,importer_license:true}}
                 } ,
                columns: [
                    linkColumn({Header:"Number", accessor: "name",column:false, type: "test", secured: true, document_type: ["passport"], idToken: id_token }),
                    uuidColumn({
                        entity: "customer",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                    }),
                    enumColumn({
                        accessor: "document_type",
                        options: enum_document_type,
                        type: "select",
                    }),
                    imageColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: true,
                        privateImage: true,
                        width: 250,
                        dependsValue: ["document_type:aadhaar","document_type:PAN","document_type:gst", ],                                
                        minWidth: 200,
                    }),
                      imageColumn({
                            accessor: "document",
                            rules: null,
                            type: "document",
                            privateImage: true,
                            // eslint-disable-next-line no-useless-escape
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            column:true,
                            dependsValue: ["document_type:apeda","document_type:ice", ],                             
                            width:150,
                            minWidth:100,
                            maxWidth:280,
                        }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "customer_bank":
        case entity === "bank":
            options = {
                entity: "customer_bank",
                fields: `id uid active created_at updated_at customer_id customer{id name}name account_no account_ifsc bank_name images`,
                userColumns:{

                    skipColumn:{"customer":true }    , 
                    skipEnum  :{}
                 } ,
                columns: [
                    uuidColumn({
                        entity: "customer",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                    }),
                    linkColumn({Header:"Account Holder Name", accessor: "name" }),
                    defaultColumn({ accessor: "account_no" }),
                    defaultColumn({ accessor: "account_ifsc" }),
                    defaultColumn({ accessor: "bank_name", rules: null }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    imageColumn({
                        Header:"Image Upload ( Pass Book / Cheque )",
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: true,
                        privateImage: true,
                        width: 250,
                        minWidth: 200,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "user_note":
            options = {
                entity: "user_note",
                fields: ` id active created_at updated_at user_id uid note`,
                columns: [
                    defaultColumn({ accessor: "note" }),
                    uuidColumn({
                        entity: "user",
                        typeahead_fields: `id name type: enum_user_role{value comment}`,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "jobcard":
            options = {
                entity: "jobcard",
                fields: `id active uid name created_at loading_po:jobcard_loadings{id po} unloading_po:jobcard_unloadings{id po}  updated_at transportation_cost start_date end_date  images videos documents 
				        invoice_no jobcard_purpose enum_jobcard_purpose{comment}  jobcard_type enum_jobcard_type{comment}
                        start_date status enum_jobcard_status{comment}
						order_id order {id name purchase_amount buyer_selling_price buyer_commission unloading_charges }  profit purchase  revenue
						transportation_paid_by enum_transportation_paid_by {comment}
						vehicle_id  vehicle { id active capacity created_at name  vehicle_type enum_vehicle_type {comment  value }   owner { name }  vehicle_contacts{ active contact_type contact{ email id name } }}
						origin_id origin { id name address location_type enum_location_type {comment} } destination_id destination { id name address location_type enum_location_type {comment} }`,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        link: `/trade/jobcard/detail/#id`,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "loading_po Invoice",
                        accessor: "loading_po.po",
                        rules: null,
                        column: false,
                    }),

                    defaultColumn({
                        Header: "unloading_po Invoice",
                        accessor: "unloading_po.po",
                        rules: null,
                        column: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_jobcard_status,
                        type: "select",
                        add: false,
                    }),
                    fillColumn(),
                    uuidColumn({
                        Header: "Vehicle Number",
                        entity: "vehicle",
                        typeahead_fields: `id name extra: enum_vehicle_type{comment} type: owner {name}`,
                    }),
                    enumColumn({
                        Header: "Transaction Type",
                        accessor: "jobcard_type",
                        options: enum_jobcard_type,
                        type: "select",
                        column: false,
                    }),
                    enumColumn({
                        accessor: "transportation_paid_by",
                        options: enum_transportation_paid_by,
                        type: "select",
                        column: false,
                    }),
                    enumColumn({
                        Header: "Order Type",
                        accessor: "jobcard_purpose",
                        options: enum_jobcard_purpose,
                        type: "select",
                        column: false,
                    }),
                    uuidColumn({
                        Header: "Order",
                        entity: "order",
                        accessor: "order",
                        dbname: "order_id",
                        link_id: "order_id",
                        link: `/marketplace/order/detail/#id`,
                        dependsValue: ["jobcard_purpose:on_order"],
                        typeahead_fields: `id name type: enum_order_status{comment}`,
                        rules: null,
                        filters: { status: { _in: ["inprogress"] } },
                    }),
                    uuidColumn({
                        Label: "Origin",
                        Header: "Origin (Loading Point)",
                        accessor: "origin",
                        dbname: "origin_id",
                        entity: "location",
                        link: `/trade/location/detail/#id`,
                        link_id: "origin_id",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    uuidColumn({
                        Label: "Destination",
                        Header: "Destination (Unloading Point)",
                        accessor: "destination",
                        dbname: "destination_id",
                        entity: "location",
                        link: `/trade/location/detail/#id`,
                        link_id: "destination_id",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    dateColumn({ accessor: "start_date", valueAsNumber: true }),
                    dateColumn({ accessor: "end_date", rules: null }),
                    defaultColumn({
                        Header: "Transportation Invoice",
                        accessor: "invoice_no",
                        rules: null,
                        column: false,
                    }),
                    numberColumn({
                        Header: "Transportation Charges",
                        accessor: "transportation_cost",
                        type: "currency",
                        column: false,
                        min: 0,
                    }),
                    numberColumn({
                        Header: "Profit",
                        accessor: "profit",
                        type: "currency",
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Transportation Invoice",
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;



            case entity === "location_contact":
            options = {
                entity: "location_contact",
                fields: `id active uid created_at updated_at notes  
                         contact_id contact{id name phone whatsapp email} contact_type enum_contact_type{comment}`,
                columns: [


                    enumColumn({
                        accessor: "contact_type",
                        options: enum_contact_type,
                        type: "select",
                    }),

                    uuidColumn({
                        entity: "contact",
                        typeahead_fields: `id name phone whatsapp email`,
                    }),

                    uuidColumn({
                        entity: "location",
                        typeahead_fields: `id name type:  enum_location_type{value comment} extra: address`,
                        
                    }),
                    defaultColumn({
                        Header: "Phone",
                        accessor: "contact.phone",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Whatsapp",
                        accessor: "contact.whatsapp",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Email",
                        accessor: "contact.email",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                   
                    defaultColumn({
                        accessor: "notes",
                        rules: null,
                        type: "textarea",
                        column: false,
                    }),
 
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;





        case entity === "jobcard_contact":
            options = {
                entity: "jobcard_contact",
                fields: `id active uid created_at updated_at notes jobcard jobcard{id name}
                    contact_id contact{id name phone whatsapp email} contact_type enum_contact_type{comment}`,
                columns: [
                    uuidColumn({
                        entity: "jobcard",
                        typeahead_fields: `id name type: enum_jobcard_status{value comment}`,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "jobcard",
                        accessor: "jobcard",
                        dbname: "jobcard_id",
                        link_id: "jobcard_id",
                        link: `/trade/jobcard/detail/#id`,
                        typeahead_fields: `id name type: enum_jobcard_status{comment}`,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "contact_type",
                        options: enum_contact_type,
                        type: "select",
                    }),
                    uuidColumn({
                        entity: "jobcard",
                        typeahead_fields: `id name type: enum_jobcard_status{value comment}`,
                    }),
                    uuidColumn({
                        entity: "contact",
                        typeahead_fields: `id name phone whatsapp email`,
                    }),
                    defaultColumn({
                        Header: "Phone",
                        accessor: "contact.phone",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Whatsapp",
                        accessor: "contact.whatsapp",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        Header: "Email",
                        accessor: "contact.email",
                        rules: null,
                        add: false,
                        edit: false,
                    }),
                    defaultColumn({
                        accessor: "notes",
                        rules: null,
                        type: "textarea",
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "jobcard_loading":
            options = {
                entity: "jobcard_loading",
                fields: `id active uid name po created_at updated_at name images videos documents
                status enum_loading_status{comment} loading_date invoice_no payment_due_date
				commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
				total_price kg_per_bag net_weight gross_weight
                jobcard_id jobcard{ id name } purchase_commission purchase_amount
				loading_charges grading_charges commission_charges extra_charges
				location_id location { id active area address location_type enum_location_type {comment} city district name }
				customer_id customer {id name customer_type commission_percent payment_days  enum_customer_type{comment}}
                number_of_bags    rate_per_kg weight_in_kgs   transportation_charges
				`,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        add: false,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "jobcard",
                        accessor: "jobcard",
                        dbname: "jobcard_id",
                        link_id: "jobcard_id",
                        link: `/trade/jobcard/detail/#id`,
                        typeahead_fields: `id name type: enum_jobcard_status{comment}`,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_loading_status,
                        type: "select",
                        add: false,
                    }),
                    fillColumn(),
                    uuidColumn({
                        Header: getLabel("supplier"),
                        accessor: "customer",
                        dbname: "customer_id",
                        entity: "customer",
                        solution: "trade",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        filters: { customer_type: { _in: ["farmer", "trader"] } },
                    }),
                    uuidColumn({
                        entity: "commodity_variety",
                        typeahead_fields: `id name type: commodity{id name}`,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "location",
                        link: `/trade/location/detail/#id`,
                        link_id: "location_id",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    dateColumn({ accessor: "loading_date" }),
                    numberColumn({ accessor: "number_of_bags", column: false }),
                    numberColumn({
                        accessor: "rate_per_kg",
                        type: "currency",
                        column: false,
                        edit: false,
                    }),

                    numberColumn({ accessor: "total_price", add: false, edit: false }),
                    numberColumn({
                        Header: "Gross Weight",
                        accessor: "gross_weight",
                        type: "weight",
                        column: false,
                    }),
                    numberColumn({
                        Header: "GRN Weight",
                        accessor: "net_weight",
                        type: "weight",
                        column: false,
                    }),
                    dateColumn({
                        accessor: "payment_due_date",
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    numberColumn({
                        accessor: "loading_charges",
                        column: false,
                        rules: null,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "grading_charges",
                        column: false,
                        rules: null,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "commission_charges",
                        column: false,
                        rules: null,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "extra_charges",
                        column: false,
                        rules: null,
                        min: 0,
                    }),
                    // numberColumn({
                    // 	accessor: "transportation_charges",
                    // 	type: "currency",
                    // 	column: false,
                    // 	rules: null,
                    // 	min: 0,
                    // }),
                    defaultColumn({
                        Header: "Invoice",
                        accessor: "invoice_no",
                        rules: null,
                        column: false,
                    }),
                    defaultColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    defaultColumn({
                        accessor: "videos",
                        type: "video",
                        accept: /\.(mp4)$/i,
                        rules: null,
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "jobcard_unloading":
            options = {
                entity: "jobcard_unloading",
                fields: `id active uid name created_at po updated_at name images videos documents
                status enum_loading_status{comment} unloading_date invoice_no payment_due_date
				commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
                jobcard_id jobcard{ id name }
				number_of_bags net_weight gross_weight
				location_id location { id active area address location_type enum_location_type {comment} city district name }
				customer_id customer {id name customer_type commission_percent payment_days  enum_customer_type{comment}}
                rate_per_kg weight_in_kgs sales_amount transportation_charges
				unloading_charges grading_charges commission_charges extra_charges sales_commission sales_revenue
						`,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        add: false,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "jobcard",
                        accessor: "jobcard",
                        dbname: "jobcard_id",
                        link_id: "jobcard_id",
                        link: `/trade/jobcard/detail/#id`,
                        typeahead_fields: `id name type: enum_jobcard_status{comment}`,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_loading_status,
                        type: "select",
                        add: false,
                    }),
                    fillColumn(),
                    uuidColumn({
                        Header: getLabel("customer"),
                        accessor: "customer",
                        dbname: "customer_id",
                        entity: "customer",
                        solution: "trade",
                        rules: null,
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        filters: { customer_type: { _in: ["wholesaler"] } },
                    }),
                    uuidColumn({
                        entity: "commodity_variety",
                        typeahead_fields: `id name type: commodity{id name}`,
                        filters: {
                            jobcard_loadings: { jobcard_id: { _eq: rest?.parent_id } },
                        },
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "location",
                        link: `/trade/location/detail/#id`,
                        link_id: "location_id",
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    dateColumn({ accessor: "unloading_date" }),
                    numberColumn({ accessor: "number_of_bags", column: false }),
                    numberColumn({ accessor: "rate_per_kg", type: "currency" }),
                    numberColumn({
                        Header: "Gross Weight",
                        accessor: "gross_weight",
                        type: "weight",
                        column: false,
                    }),
                    numberColumn({
                        Header: "GRN Weight",
                        accessor: "net_weight",
                        type: "weight",
                        column: false,
                    }),
                    dateColumn({
                        accessor: "payment_due_date",
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    numberColumn({
                        accessor: "sales_amount",
                        type: "currency",
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    numberColumn({
                        accessor: "unloading_charges",
                        type: "currency",
                        rules: null,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "grading_charges",
                        type: "currency",
                        rules: null,
                        min: 0,
                    }),
                    // numberColumn({
                    //     accessor: "commission_charges",
                    //     type: "currency",
                    //     rules: null,
                    //     min: 0,
                    //     add:false,
                    //     edit:false,
                    //     column:false
                    // }),
                    numberColumn({
                        accessor: "extra_charges",
                        type: "currency",
                        rules: null,
                        min: 0,
                    }),
                    numberColumn({
                        accessor: "sales_commission",
                        type: "currency",
                        rules: null,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    numberColumn({
                        accessor: "sales_revenue",
                        type: "currency",
                        rules: null,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    // numberColumn({
                    // 	accessor: "transportation_charges",
                    // 	type: "currency",
                    // 	rules: null,
                    // 	min: 0,
                    // }),
                    defaultColumn({
                        Header: "Invoice",
                        accessor: "invoice_no",
                        rules: null,
                    }),
                    defaultColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    defaultColumn({
                        accessor: "videos",
                        type: "video",
                        accept: /\.(mp4)$/i,
                        rules: null,
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "jobcard_payment":
            options = {
                entity: "jobcard_payment",
                fields: `id active uid name images created_at updated_at amount is_receipt name status  enum_payment_status{comment} timestamp
						customer_id customer {id name customer_type commission_percent payment_days  enum_customer_type{comment} active address city area country created_at email }
						jobcard_id jobcard { id name active } `,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        add: false,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "jobcard",
                        accessor: "jobcard",
                        dbname: "jobcard_id",
                        link_id: "jobcard_id",
                        link: `/trade/jobcard/detail/#id`,
                        typeahead_fields: `id name type: enum_jobcard_status{comment}`,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    enumColumn({
                        accessor: "status",
                        options: enum_payment_status,
                        type: "select",
                        add: false,
                    }),
                    fillColumn(),
                    uuidColumn({
                        accessor: "customer",
                        dbname: "customer_id",
                        entity: "customer",
                        solution: "trade",
                        typeahead_fields: `id name type: enum_customer_type{value comment}`,
                        filters: {
                            _or: [
                                { jobcard_loadings: { jobcard_id: { _eq: rest?.parent_id } } },
                                {
                                    jobcard_unloadings: { jobcard_id: { _eq: rest?.parent_id } },
                                },
                            ],
                        },
                    }),
                    booleanColumn({
                        Header: "Payment To Customer",
                        accessor: "is_receipt",
                        rules: null,
                    }),
                    numberColumn({ accessor: "amount", type: "currency" }),
                    datetimeColumn({
                        Header: "payment received at",
                        accessor: "timestamp",
                        rules: null,
                        add: true,
                        edit: true,
                    }),
                    defaultColumn({
                        accessor: "images",
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        rules: null,
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        case entity === "jobcard_weighing":
            options = {
                entity: "jobcard_weighing",
                accessor: "loaded_weight",
                // timestamp
                fields: `id active uid name created_at updated_at  videos images    empty_weight  loaded_weight  product_weight weighed_date
                  weighing_charges location_id location  { id active area address location_type enum_location_type {comment} city district name }`,
                columns: [
                    linkColumn({
                        Header: "Id",
                        displayOnly: true,
                        accessor: "name",
                        add: false,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "jobcard",
                        typeahead_fields: `id name type: enum_jobcard_status{value comment}`,
                        displayOnly: true,
                        add: false,
                        edit: false,
                    }),
                    uuidColumn({
                        entity: "location",
                        filters: { location_type: { _in: ["weighbridge"] } },
                        typeahead_fields: `id name type: enum_location_type{value comment} extra: address`,
                    }),
                    numberColumn({
                        Header: "Empty Weight",
                        accessor: "empty_weight",
                        type: "weight",
                    }),

                    numberColumn({
                        Header: "Loaded weight",
                        accessor: "loaded_weight",
                        type: "weight",
                        rules: null,
                    }),

                    dateColumn({
                        accessor: "weighed_date",
                        rules: null,
                    }),

                    numberColumn({ accessor: "weighing_charges", type: "currency" }),
                    // booleanColumn({ accessor: "is_loaded", rules: null }),

                    defaultColumn({
                        Header: "Upload Invoices",
                        accessor: "invoice_no",
                        rules: null,
                    }),
                    defaultColumn({
                        accessor: "images",
                        cameraField: true,
                        type: "image",
                        accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                        column: false,
                    }),
                    imageColumn({
                        accessor: "videos",
                        type: "video",
                        accept: /\.(mp4)$/i,
                        rules: null,
                        column: false,
                    }),
                    activeColumn(),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                    }),
                ],
            };
            break;
        
        case entity === "change_history":
            options = {
                entity: "change_history",
                fields: `id created_at rel_id status enum_order_status{comment} old_value new_value user_id user{id name}`,
                readOnly: true,
                columns: [
                    uuidColumn({ entity: "order" }),
                    uuidColumn({ entity: "user" }),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                        column: true,
                    }),
                ],
                sortBy: [{ id: "created_at", desc: true }],
                activateStatus: false,
            };
            break;
            case entity === "users":
                options = {
                    entity: "user",
                     // timestamp
                    fields: ` `,
                    columns: [
                        
                
                     
                        numberColumn({
                            Header: "Phone no",
                            accessor: "phone_no",
                         }),
    
             
    
                        defaultColumn({
                            Header: "Email",
                            accessor: "email",
                            rules: null,
                        }),
                  
                    ],
                };
                break;
          
             case entity === "user_organization":
                    options = {
                        entity: "user_organization",
                         // timestamp
                        fields: `  id name active business_type enum_user_org_business_type{ value comment }
                                    location_id location {id name location_type area city district state country postcode} 
                                    created_at updated_at`,
                        columns: [

 
                           

                            linkColumn({
                                Header: "Business Name",
                                accessor: "name",
                                link: `/management/user_organization/detail/#id`,
                            }),
                            uuidColumn({
                                entity: "location",
                                // filters: { location_type: { _in: ["business"] } },
                                typeahead_fields: `id name`,
                                // filter_fields: [{ accessor: "customer" }],
                            }),

                            enumColumn({
                                accessor: "business_type",
                                options: enum_user_org_business_type,
                                type: "select",
                            }),
                            // enumColumn({
                            //     accessor: "location.location_type",
                            //     type: "select",
                            //     options: enum_location_type,
                            //     add: true,
                            //     edit: true,
                            //     column: true,
                            // }),

                            // mapColumn({accessor:"location.data"}),
                            defaultColumn({
                                Header:"Area",
                                accessor: "location.area",
                                rules: null,
                                add: false,
                                edit: false,
                                column: false,
                            }),
                            defaultColumn({
                                Header:"City",
                                accessor: "location.city",
                                rules: null,
                                add: false,
                                edit: false,
                            }),
                            defaultColumn({
                                Header:"District",
                                accessor: "location.district",
                                rules: null,
                                add: false,
                                edit: false,
                                column: false,
                            }),
                            defaultColumn({
                                Header:"State",
                                accessor: "location.state",
                                rules: null,
                                add: false,
                                edit: false,
                            }),
                            defaultColumn({
                                Header:"Country",
                                accessor: "location.country",
                                rules: null,
                                add: false,
                                edit: false,
                                column: false,
                            }),
                            defaultColumn({
                                Header:"Postcode",
                                accessor: "location.postcode",
                                rules: null,
                                add: false,
                                edit: false,
                            }),

                        ],
                    };
                    break;
                    // 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
                        case entity === "inquiries":
                        options = {
                            entity: "inquiries",
                             // timestamp
                            fields: `  
                            id
                            uid
                            customer_id
                            customer{
                                id
                                name
                            }
                            ventilation
                            commodity 
                            commodity_id
                            origin_port_id
                            origin_port {
                              id
                              name
                            }
                            destination_port_id
                            destination_port {
                              id
                              name
                            }
                            incoterm_type
                            enum_incoterm {
                              comment
                              value
                            }
                            no_of_containers
                            container_type
                            cargo_type
                            temperature
                            humidity
                            enum_cargo_category {
                              comment
                              value
                            }
                            weight_in_mt
                            cargo_description
                            free_days_at_des  
                              enum_free_days_des_type {
                                comment
                                value
                              }
                            
                            stuffing_location_type
                            enum_stuffing_location_type {
                              comment
                              value
                            }
                            custom_clearance_required
                            cus_cl_at_origin
                            cus_cl_at_destination
                            transport_required
                            pickup_address
                            drop_off_address
                            cargo_readiness
                            inquiry_type
                            enum_inquery_type {
                              value
                              comment
                            }
                            freight_indication_in_usd
                            pickup_address
                            preferred_carrier
                            inquiry_expiry
                            notes
                            created_by_id
                            created_by{
                                id name customer_type
                            }
                            created_at
                            updated_at
                        
                                `,
                                   
                userColumns:{

                    skipColumn:{"customer_id":true,customer:true,  }    ,
                    skipEnum  :{ }
                 },
            
                
                             
                            columns: [
                                defaultColumn({
                                    Header: "UID",
                                    accessor: "uid",
                                    add:false,
                                    edit:false,

                                }),
    
                                uuidColumn({
                                    Header:"Customer",
                                    entity: "customer",
                                    typeahead_fields: `id name`,
                                }),

                                uuidColumn({
                                    Header:"Created By",
                                    entity: "customer",
                                    accessor:"created_by",
                                    typeahead_fields: `id name type:role`,
                                    add:false,
                                    edit:false
                                }),

                                defaultColumn({
                                    Header:"Created Role",
                                    accessor:"created_by.customer_type",
                                    add:false,
                                    edit:false                               
                                 }),
                                 defaultColumn({
                                    Header:"Commodity",
                                    accessor:"commodity",
                                                              
                                 }),
                                //  uuidColumn({
                                //     Header:"Commodity",
                                //     entity: "commodity",
                                //     dbname:"commodity_id",
                                //     //  filters: { location_type: { _in: ["origin_port"] } },
                                //     typeahead_fields: `id name`,
                                //     minWidth:80

                                //     // filter_fields: [{ accessor: "customer" }],
                                // }),
                                uuidColumn({
                                    Header:"Origin Port",
                                    entity: "location",
                                    accessor:"origin_port",
                                    dbname:"origin_port_id",
                                     filters: { location_type: { _in: ["origin_port"] } },
                                    typeahead_fields: `id name`,
                                    // filter_fields: [{ accessor: "customer" }],
                                }),

                                uuidColumn({
                                    Header:"Destination Port",
                                    entity: "location",
                                    accessor:"destination_port",
                                    dbname:"destination_port_id",
                                    filters: { location_type: { _in: ["destination_port"] } },
                                    typeahead_fields: `id name`,
                                    // filter_fields: [{ accessor: "customer" }],
                                }),
                                defaultColumn({
                                    Header:"Freight indication(USD)",
                                    accessor: "freight_indication_in_usd",
                                    rules:null,
                                    // dependsCollapse:"additional_preferences"
                                }),
                                // enumColumn({
                                //     Header:"IncoTerm",
                                //     accessor: "incoterm_type",
                                //     options: enum_incoterms_type,
                                //     type: "select",
                                // }),

                                defaultColumn({Header:"Cargo Details",onlyHeader:true, type:"collapse", column:false,  span:12}),

                                defaultColumn({
                                    Header:"No of Containers",
                                    accessor: "no_of_containers",
                                }),

                                enumColumn({
                                    Header:"Container Size",
                                    accessor: "container_type",
                                    options: enum_container_type,
                                    type: "select",
                                    add: true,
                                    edit: true,
                                    column: true,
            
                                }),



                                defaultColumn({
                                    Header:"Weight(MT)",
                                    accessor: "weight_in_mt",
                                }),




                                enumColumn({
                                    Header:"Cargo",
                                    accessor: "cargo_type",
                                    options: enum_cargo_category,
                                    type: "select",
                                }),

                                defaultColumn({
                                    Header:"Temperature",
                                    accessor: "temperature",
                                    dependsValue:["cargo_type:reefer"]
                                }),


                                defaultColumn({
                                    Header:"Humidity",
                                    accessor: "humidity",
                                    dependsValue:["cargo_type:reefer"]
                                }),

                             

                                // enumColumn({
                                //     Header:"Stuffing Location",
                                //     accessor: "stuffing_location_type",
                                //     options: enum_stuffing_location_type,
                                //     type: "select",
                                // }),

                                
                                defaultColumn({
                                    Header:"Cargo Descriptionss",
                                    accessor: "cargo_description",
                                    type:"textarea",
                                    rules:null
                                }),


                                defaultColumn({
                                    Header:"Ventilation",
                                    accessor: "ventilation",
                                    type:"textarea",
                                    rules:null,
                                    column:false,
                                    add:true,
                                    edit:true

                                }),

                                defaultColumn({Header:"Additional Services",  accessor:"", type:"collapse", id:"additional_services", column:false, span:12,}),
                            
                                booleanColumn({accessor:"custom_clearance_required", dependsCollapse:"additional_services",add:false, edit:false,}),
                               
                                booleanColumn({Header:"At Origin",accessor:"cus_cl_at_origin",  dependsValue:["custom_clearance_required:true"]}),

                                booleanColumn({Header:"At Destination",accessor:"cus_cl_at_destination", dependsValue:["custom_clearance_required:true"]}),


   
                                booleanColumn({accessor:"transport_required",dependsCollapse:"additional_services",add:false, edit:false}),
                                

                                 defaultColumn({
                                    Header:"Pickup",
                                    accessor: "pickup_address",
                                    rules:null,
                                    
                                    dependsValue:["transport_required:true"]
                                }),


                                defaultColumn({
                                    Header:"DropOff",
                                    accessor: "drop_off_address",
                                    rules:null,
                                    dependsValue:["transport_required:true"]
                                }),

                                // defaultColumn({Header:"Preferences", id:"additional_preferences",type:"collapse", column:false, span:12}),


                                
                                // defaultColumn({Header:"Additional Services",  accessor:"", type:"collapse", id:"additional_services", column:false, span:12,}),
                                // booleanColumn({accessor:"custom_clearance_required", dependsCollapse:"additional_services",add:false, edit:false,}),
                                // booleanColumn({accessor:"transport_required",dependsCollapse:"additional_services",add:false, edit:false}),
                                


                                

                                // enumColumn({
                                //     accessor: "inquiry_type",
                                //     options: enum_inquery_type,
                                //     type: "select",
                                //     rules:null,
                                //     add:false,
                                //       edit:false,
                                //     dependsCollapse:"additional_preferences"
                                // }),

                             


                                //    enumColumn({
                                //     accessor: "free_days_at_des",
                                //     options: enum_free_days_des_type,
                                //     type: "select",
                                //     rules:null,
                                //     add:false, edit:false,
                                //     dependsCollapse:"additional_preferences"
                                // }),

 
                                
                                // defaultColumn({
                                //     Header:"Preferred Carrier",
                                //     accessor: "preferred_carrier",
                                //     rules:null,
                                //     add:false, edit:false,
                                //     dependsCollapse:"additional_preferences"
                                // }),
        

                        dateColumn({ accessor: "inquiry_expiry",rules:null,add:false, edit:false,dependsCollapse:"additional_preferences" }),
                        dateColumn({ accessor: "cargo_readiness",rules:null ,add:false, edit:false,dependsCollapse:"additional_preferences"}),
                        
                        // defaultColumn({Header:"Additional Notes",id:"additional_notes",type:"collapse", column:false, span:12}),
                        
                        // defaultColumn({
                        //     Header:"Notes",
                        //     accessor: "notes",
                        //     type: "textarea",
                        //     rules:null,
                        //     add:false,
                        //     edit:false,
                        //     dependsCollapse:"additional_notes"

                        // }),

                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            add:false,
                            edit:false                       
                         }),
    
                            ],
                        };
                        break;
                        // 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

            case entity === "datacenter":
                // console.log("checking",entity === "dataCenter")
                options   = {
                    entity : "data_center",
                    fields : `
                    id
                    uid
                    ch
                    consignee
                    contact_no
                    contact_person
                    uqc
                    currency
                    cush_
                    destination_country
                    email_id
                    exporter
                    exporter_address
                    exporter_city
                    exporter_pin
                    exporter_state
                    fob
                    fob_in_fc
                    
                    iec
                    invoice_no
                    item
                    item_rate
                    item_rate_in_inr
                    month
                    port_pod
                    port_pol
                    quantity
                    real
                    ritc
                    sb_no
                    sbdt
                    type
                    active
                    created_at
                    updated_at
                    `,

                    columns:[
                        

                        defaultColumn({
                            Header: "UID",
                            accessor: "uid",
                            add:false,
                            edit:false,
                            
                        }),




                        defaultColumn({
                            Header: "CH",
                            accessor: "ch",
                            

                            
                        }),


                        defaultColumn({
                            Header: "Consignee",
                            accessor: "consignee",
                                

                        }),



                        defaultColumn({
                            Header: "Contact No",
                            accessor: "contact_no",
                            minWidth:125
                        }),

                        defaultColumn({
                            Header: "Contact Person",
                            accessor: "contact_person",
                            minWidth:155
                        }),


                            defaultColumn({
                            Header: "UQC",
                            accessor: "uqc",
                            
                        }),

                            defaultColumn({
                            Header: "Currency",
                            accessor: "currency",
                            
                        }),

                        defaultColumn({
                            Header: "CUSH",
                            accessor: "cush_",
                            
                        }),

                        defaultColumn({
                            Header: "Destination Country",
                            accessor: "destination_country",
                            filterable:true,
                            minWidth:125
                            
                        }),

                        defaultColumn({
                            Header: "EMAIL",
                            accessor: "email_id",
                            minWidth:150
                        }),

                        defaultColumn({
                            Header: "Exporter",
                            accessor: "exporter",

                            filterable:true,
                            minWidth:125
                        }),


                            defaultColumn({
                            Header: "Exporter Address",
                            accessor: "exporter_address",
                            minWidth:155
                        }),

                        defaultColumn({
                            Header: "Exporter City",
                            accessor: "exporter_city",
                            minWidth:125
                        }),

                        defaultColumn({
                            Header: "Exporter PIN",
                            accessor: "exporter_pin",
                            minWidth:125
                                
                        }),

                        defaultColumn({
                            Header: "State",
                            accessor: "exporter_state",
                            minWidth:125
                            
                        }),

                        numberColumn({
                            Header: "FOB",
                            accessor: "fob",
                                
                        }),


                        numberColumn({
                            Header: "FOB IN FC",
                            accessor: "fob_in_fc",
                            
                        }),

                            defaultColumn({
                            Header: "IEC",
                            accessor: "iec",
                            
                        }),


                        defaultColumn({
                            Header: "BL Invoiceno",
                            accessor: "invoice_no",
                        }),

                        defaultColumn({
                            Header: "ITEM",
                            accessor: "item",
                            minWidth:155
                        }),

                        numberColumn({
                            Header: "Item Rate In INR",
                            accessor: "item_rate_in_inr",
                        }),
                            defaultColumn({
                            Header: "Item Rate",
                            accessor: "item_rate",
                        }),

                        defaultColumn({
                            Header: "Month",
                            accessor: "month",
                            minWidth:125
                        }),

                        defaultColumn({
                            Header: "Port Pod",
                            accessor: "port_pod",
                            filterable:true,
                            minWidth:125
                        }),

                        defaultColumn({
                            Header: "Port Pol",
                            accessor: "port_pol",
                            filterable:true,
                            minWidth:125
                        }),



                        defaultColumn({
                            Header: "Quantity",
                            accessor: "quantity",
                        }),


                        defaultColumn({
                            Header: "Real",
                            accessor: "real",
                            minWidth:125
                        }),




                        defaultColumn({
                            Header: "RITC",
                            accessor: "ritc",
                            minWidth:125
                        }),


                        defaultColumn({
                        Header: "SB no",
                        accessor: "sb_no",
                        minWidth:125
                    }),

                    defaultColumn({
                        Header: "SBDT",
                        accessor: "sbdt",
                        minWidth:125
                    }),

                    defaultColumn({
                        Header: "Type",
                        accessor: "type",
                        minWidth:125
                    }),

                    


            datetimeColumn({
                accessor: "created_at",
                rules: null,
                add:false,
                edit:false,

            }),  

            
            datetimeColumn({
                accessor: "updated_at",
                rules: null,
                add:false,
                edit:false                       
                }),
                    
                            ]
                    }
                
                        break;



            case entity === "quotations":
                options = {
                    entity: "quotations",
                    
                    fields: `  

                    id
                    uid
                    b_l_charges
                    basic_freight_in_usd
                    cargo_weight_per_container_in_mt
                    container_category
                    enum_cargo_category {
                        comment
                        value
                        }

                    container_count
                    container_type
                    enum_container_type {
                        comment
                        value
                        }
                        
                    exchange_rate_in_inr
                    free_days
                    enum_free_days_des_type {
                        comment
                        value
                        }
                        
                    incoterm
                    enum_incoterms_type {
                        comment
                        value
                        }
                    mandatory_user_charges
                    origin_port_id
                    origin_port{
                            name
                    }
                    destination_port_id
                    destination_port{
                            name
                    }
                    
                    sailing_date
                    seal_charges
                    toll_charges
                    transit_days
                    enum_freight_transit_status {
                        comment
                        value
                        }

                        terminal_handling_charges
                        customer_id
                        customer{
                        id 
                        name
                        }

                        
                        commodity_id
                        commodity {
                        id name
                        
                        }
                    temperature_variation_charges
                    etd
                    eta
                    vessel_name
                    carrier
                    updated_at
                    created_at
                    
                
                        `,
                            
        userColumns:{

            skipColumn:{"customer_id":true,customer:true,  }    ,
            skipEnum  :{ }
            },

        
                        
            columns: [

            defaultColumn({
                Header: "UID",
                accessor: "uid",
                add:false,
                edit:false,
            }),
            
                
            uuidColumn({
                Header:"Origin Port",
                entity: "location",
                accessor:"origin_port",
                dbname:"origin_port_id",
                typeahead_fields: `id name`,
                filters: { location_type: { _in: ["origin_port"] } },


            }),


                
            uuidColumn({
                Header:"Destination Port",
                entity: "location",
                accessor:"destination_port",
                dbname:"destination_port_id",
                typeahead_fields: `id name`,
                filters: { location_type: { _in: ["destination_port"] } },


            }),
 
        uuidColumn({
            Header:"Shippling Line",
            entity:"export_shipping_line",
            typeahead_fields: `id name`, 
            accessor: "carrier",  
            dbname:"shipping_line_id",
            rules: null,  
            edit:false,
            }),

                enumColumn({
                    Header:"Container type",
                    accessor: "container_category",
                    options: enum_cargo_category,
                    type: "select",
                    rules:null,
                    
                }),
                    enumColumn({
                    Header:"Container Size",
                    accessor: "container_type",
                    options: enum_container_type,
                    type: "select",
                    rules:null,
                    
                }),
                

                    dateColumn({ accessor: "etd",rules:null }),
                    dateColumn({ accessor: "eta",rules:null }),

                    defaultColumn({
                        Header: "Vessel Name",
                        accessor: "vessel_name",
                        add:false,
                        edit:false,
                    }),



                    numberColumn({
                        Header: "Basic Freight(USD)",
                        accessor: "basic_freight_in_usd",
                    }),


                    numberColumn({
                        Header: "Exchange rate (INR)",
                        accessor: "exchange_rate_in_inr",
                        type:"currency"
                    }),


                    numberColumn({
                        Header: "Terminal Handling Charges (INR)",
                        accessor: "terminal_handling_charges",
                    }),


                    numberColumn({
                        Header: "Toll Charges",
                        accessor: "toll_charges",
                    }) ,

                    

                        numberColumn({
                            Header: "BL Charges",
                            accessor: "b_l_charges",
                        }),

                        numberColumn({
                            Header: "Mandatory User Charges",
                            accessor: "mandatory_user_charges",
                        }),


                        defaultColumn({
                            Header: "Temperature Variation Charges",
                            accessor: "temperature_variation_charges",
                            add:false,
                            edit:false,
                        }),

                        numberColumn({
                            Header: "Seal Charges",
                            accessor: "seal_charges",
                        }),

                        

                    //     numberColumn({
                    //         Header: "Cargo Weight/Container(MT)",
                    //         accessor: "cargo_weight_per_container_in_mt",
                    //     }),




                    //         numberColumn({
                    //         Header: "Container Count",
                    //         accessor: "container_count",
                    //     }),




                    
                        
                    // uuidColumn({
                    //     entity: "commodity",
                    //     accessor:"commodity",
                    //     typeahead_fields: `id name`,
                    //     filters: { origin_type: { _in: ["non_domestic"] } },

                    //     // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

                    // }),






            //         uuidColumn({
            //             entity: "customer",
            //             accessor:"customer",
            //             typeahead_fields: `id name`,
            //             filters: { customer_type: { _in: ["exporter"] } },
            //             add:false, 
            //             edit:false
                        
            //             // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

            //         }),


            //     enumColumn({
            //         accessor: "free_days",
            //         options: enum_free_days_des_type,
            //         type: "select",
            //         rules:null,
                        
            //     }),

            //     enumColumn({
            //         accessor: "transit_days",
            //         options: enum_freight_transit_status,
            //         type: "select",
            //         rules:null,
                    
            //     }),


                




            //     enumColumn({
            //         Header:"IncoTerm",
            //         accessor: "incoterm",
            //         options: enum_incoterms_type,
            //         type: "select",
            //     }),





            // // defaultColumn({
            // //     Header: "POD",
            // //     accessor: "pod",
            // // }),


            // // defaultColumn({
            // //     Header: "POL",
            // //     accessor: "pol",
            // // }),



            // dateColumn({ accessor: "sailing_date",rules:null }),




            datetimeColumn({
                accessor: "created_at",
                rules: null,
                add:false,
                edit:false,

            }),  

            
            datetimeColumn({
                accessor: "created_at",
                rules: null,
                add:false,
                edit:false                       
                }),
                    
                

                    ],
                };
                break;
                case entity === "source_finance":
                    // console.log("source_finance ",entity );
                    options = {
                        entity: "sourcing_finance",
                        fields: `
                        id
                        uid  
                        active
                        customer_id
                        customer {
                            id
                            name
                            }

                        aadhaar
                        bank_statement_1_year
                        gst
                        gst_3b
                        pan
                        created_at
                        updated_at
                            `,

            
                            
                            columns: [


                                
                                defaultColumn({
                                    Header: "UID",
                                    accessor: "uid",
                                    add:false,
                                    edit:false,
                                    minWidth:200
                                }),


                                uuidColumn({
                                    accessor: "customer",
                                    entity: "customer",
                                    // solution: "trade",
                                    typeahead_fields: `id name type: enum_customer_type{value comment}`,
                                    filters: { customer_type: { _in: ["exporter"] } },
                                }),

                                imageColumn({
                                    Header:"Aadhaar",
                                    // type: "photo",
                                    type: "document",

                                    accessor: "aadhaar",
                                    id:"aadhaar",
                                    // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                    accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    
                                    rules: null,
                                    column:true,
                                    minWidth:200
                                    
                                }),

                                imageColumn({
                                    Header:"Bank Statement (1 year)",
                                    // type: "photo",
                                    type: "document",

                                    accessor: "bank_statement_1_year",
                                    id:"bank_statement_1_year",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    
                                    // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                    rules: null,
                                    column:true,
                                    minWidth:200


                                }),

                                imageColumn({
                                    Header:"GST",
                                    // type: "photo",
                                    type: "document",

                                    accessor: "gst",
                                    id:"gst",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                    // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                    rules: null,
                                    column:true,
                                    minWidth:200


                                }),


                                imageColumn({
                                    Header:"Gst 3b",
                                    type: "document",
                                    // type: "photo",
                                    accessor: "gst_3b",
                                    id:"gst_3b",
                                    // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                    rules: null,
                                    column:true,
                                    minWidth:200


                                }),



                                imageColumn({
                                    Header:"Pan",
                                    type: "document",
                                    // type: "photo",
                                    accessor: "pan",
                                    id:"pan",
                                    // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                    accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                    rules: null,
                                    column:true,
                                    minWidth:200


                                }),




                                
                            ],
                    };
                    break;

                case entity == "sourcing_finance":
                    options = {
                        entity: "sourcing_finance",
                        fields: `
                        id
                        uid  
                        active
                        customer_id
                        customer {
                            id
                            name
                            }

                        aadhaar
                        bank_statement_1_year
                        gst
                        gst_3b
                        pan
                        created_at
                        updated_at
                            `,
                                

                        columns: [


                                
                            defaultColumn({
                                Header: "UID",
                                accessor: "uid",
                                add:false,
                                edit:false,
                                minWidth:200
                            }),

                            imageColumn({
                                Header:"Aadhaar",
                                type: "document",
                                // type: "photo",
                                accessor: "aadhaar",
                                privateImage:true,
                                id:"aadhaar",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                rules: null,
                                column:true,
                                minWidth:200
                                
                            }),

                            imageColumn({
                                Header:"Bank Statement (1 year)",
                                type: "photo",
                                // type: "document",

                                accessor: "bank_statement_1_year",
                                id:"bank_statement_1_year",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                rules: null,
                                column:true,
                                minWidth:200


                            }),

                            imageColumn({
                                Header:"GST",
                                // type: "photo",
                                                                            type: "document",

                                accessor: "gst",
                                id:"gst",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                column:true,
                                minWidth:200


                            }),


                            imageColumn({
                                Header:"Gst 3b",
                                // type: "photo",
                                type: "document",

                                accessor: "gst_3b",
                                id:"gst_3b",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                rules: null,
                                column:true,
                                minWidth:200


                            }),



                            imageColumn({
                                Header:"Pan",
                                type: "document",
                                // type: "photo",
                                accessor: "pan",
                                id:"pan",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                rules: null,
                                column:true,
                                minWidth:200


                            }),




                            
                        ],
                    };
                    break;


                    case entity == "factoring_finance":
                        options = {
                            entity: "factoring_finance",
                            fields: `
                            id
                            uid  
                            active
                            customer_id
                            customer {
                                id
                                name
                            }

                            bank_statement_1_year
                            gst
                            pan
                            iec_doc
                            iec
                            created_at
                            updated_at
                                `,
                                    
        
                            
                        columns: [


                                
                            defaultColumn({
                                Header: "UID",
                                accessor: "uid",
                                add:false,
                                edit:false,
                            }),

                            uuidColumn({
                                accessor: "customer",
                                entity: "customer",
                                // solution: "trade",
                                typeahead_fields: `id name type: enum_customer_type{value comment}`,
                                filters: { customer_type: { _in: ["exporter"] } },
                            }),


                            defaultColumn({
                                Header: "Iec",
                                accessor: "iec",
                
                            }),

                            imageColumn({
                                Header:"Bank Statement (1 year)",
                                // type: "photo",
                                type: "document",

                                accessor: "bank_statement_1_year",
                                id:"bank_statement_1_year",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            
                                rules: null,
                                column:true,
                                minWidth:200


                            }),

                            imageColumn({
                                Header:"GST",
                                // type: "photo",
                                type: "document",

                                accessor: "gst",
                                id:"gst",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                column:true,
                                minWidth:200


                            }),



                            imageColumn({
                                Header:"Pan",
                                // type: "photo",
                                    type: "document",

                                accessor: "pan",
                                id:"pan",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                
                                rules: null,
                                column:true,
                                minWidth:200


                            }),

                            imageColumn({
                                Header:"IEC",
                                // type: "photo",
                                type: "document",

                                accessor: "iec_doc",
                                id:"iec_doc",
                                // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                
                                rules: null,
                                column:true,
                                minWidth:200


                            }),


                        ],
                        };
                        break;

                        case entity === "order_note":
                            options = {
                                entity: "order_note",
                                fields: `  
                                id
                                uid
                                active
                                customer_id
                                customer{
                                    id
                                    name
                                }
                                order_id
                                note
                                
                                created_at
                                updated_at
                                `,
                                    columns: [
                                    
                                        
                                        defaultColumn({
                                            Header:"Id",
                                            accessor: "uid",
                                            type: "text",
                                            add:false,
                                            edit:false,
                                            column:true
                                
                                        }),

                                        uuidColumn({
                                            Header:"Customer",
                                            entity: "customer",
                                            typeahead_fields: `id name`,
                                            add:false,
                                            edit:false,
                                            column:true
                                        
                                        }),
            
            
                                        defaultColumn({
                                            Header:"Notes",
                                            accessor: "note",
                                            type: "textarea",
                                            add:true,
                                            edit:true
                                
                                        }),
        
                                
            
                                    ],
                            }
                            break;
                            case entity==="order_note":
                            options = {
                                entity: "order_note",
                                fields: `  
                                id
                                active
                                customer_id
                                order_id
                                note
                                created_at
                                updated_at
                                `,
                                    columns: [
                                        defaultColumn({
                                            Header: "UID",
                                            accessor: "uid",
                                            add:false,
                                            edit:false,
                                            column:false
        
                                        }),
            
                                        uuidColumn({
                                            Header:"Customer",
                                            entity: "customer",
                                            typeahead_fields: `id name`,
                                            add:false,
                                            edit:false,
                                            column:false
                                        
                                        }),
            
            
                                        defaultColumn({
                                            Header:"Notes",
                                            accessor: "note",
                                            type: "textarea",
                                            add:true,
                                            edit:true
                            
                                        }),
        
                                
            
                                    ],
                            }
                            break;
                
                

                    case entity==="shipping_instructions":
                    options = {
                        entity: "shipping_instructions",
                        fields: `
                        id
                        uid   
                        customer_id
                        customer {
                            id
                            name
                        }

                        order_id
                        numberoforiginals
                        freightcharges
                        shippernameandaddress
                        bookingno
                        telno
                        emailaddress
                        consigneenameandaddress
                        notifypartynameandaddress
                        shippingline
                        placeofreceipt
                        placeofdelivery
                        portofloading
                        portofdischarge
                        vessel
                        marksandnumbers
                        numberandkindofpackagesdescriptionofgoods
                        weightinkgormt
                        measurement
                        totalnumberofcontainer
                            
                            
                        remarks
                        created_at
                        updated_at
                            `,
                                
    
                        columns: [


                                
                            defaultColumn({
                                Header: "UID",
                                accessor: "uid",
                                add:false,
                                edit:false
                            }),


                            uuidColumn({
                                Header:"Customer",
                                entity: "customer", 
                                typeahead_fields: `id name`, 
                                hideAdd: true,
                                dbname:"customer_id",
                                add:false,
                                edit:false,
                                column:false,

                                // labelKey:"commodity: {name}",
                                // filters: {  origin_type : { _in: [ "non_domestic"] } },
                                // filter_fields: [{ accessor: "supplier", nestedAccessor:"customer_int_commodities.customer_id" }],
                                }),

                            
                                uuidColumn({
                                    Header:"Order",
                                    entity: "export_freight_order", 
                                    accessor:"order_id",
                                    typeahead_fields: `id name`, 
                                    hideAdd: true,
                                    dbname:"order_id",
                                    add:false,
                                    edit:false,
                                    column:false,
                                    
                    
                                    }),    

                            defaultColumn({
                                Header:"Number of Originals",
                                accessor: "numberoforiginals",
                                type: "textarea",
                                add:true,
                                edit:true,
                                span:4
                    
                            }),


                            defaultColumn({
                                Header:"Freight Charges",
                                accessor: "freightcharges",
                                type: "textarea",
                                add:true,
                                edit:true,
                                span:4

                    
                            }),

                            
                            defaultColumn({
                                Header:"Shipping Namd And Address",
                                accessor: "shippernameandaddress",
                                type: "textarea",
                                add:true,
                                edit:true,
                                span:4

                    
                            }),

                            
                    defaultColumn({
                        Header:"Booking No",
                        accessor: "bookingno",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Tel Phone No",
                        accessor: "telno",
                        type: "textarea",
                        add:true,
                        edit:true,
                                span:4

            
                    }),
                    defaultColumn({
                        Header:"Consignee Namd And Address",
                        accessor: "consigneenameandaddress",
                        type: "textarea",
                        add:true,
                        edit:true,
                                span:4

            
                    }),
                    defaultColumn({
                        Header:"Notify Party Namd And Address",
                        accessor: "notifypartynameandaddress",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Shipping Line",
                        accessor: "shippingline",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Place of Receipt",
                        accessor: "placeofreceipt",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Port Of Delivery",
                        accessor: "placeofdelivery",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Port Of Loading",
                        accessor: "portofloading",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),


                    defaultColumn({
                        Header:"Port of Discharge",
                        accessor: "portofdischarge",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Vessel",
                        accessor: "vessel",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),
                    defaultColumn({
                        Header:"Marks and Numbers",
                        accessor: "marksandnumbers",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),                               defaultColumn({
                        Header:"Package Description",
                        accessor: "numberandkindofpackagesdescriptionofgoods",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),    
                        defaultColumn({
                        Header:"Weight in KG or MT",
                        accessor: "weightinkgormt",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),  
                    defaultColumn({
                        Header:"Total No of Containers",
                        accessor: "totalnumberofcontainer",
                        type: "textarea",
                        add:true,
                        edit:true,
                        span:4

            
                    }),


                        defaultColumn({
                            Header:"Remarks",
                            accessor: "remarks",
                            type: "textarea",
                            add:true,
                            edit:true
                
                        }),

                        ],
                    };
                    break;

            
                    case entity=="importerDirectoryInExp":
                        options = {
                            entity: "export_imp_order_view",
                            fields: `
                            id
                            uid
                            name
                            phone
                            rating
                            country
                            commodity
                            state
                            
                            location{
                                id 
                                name
                                city
                                state
                                address
        
                            }
                            
                            `,
                            columns: [
        
                            defaultColumn({Header:"Id", accessor: "uid", type: "text", rules: null,  disabled:true }),
                            // defaultColumn({Header:"Location", accessor: "location.address", type: "text", rules: null,  disabled:true }),
                            // defaultColumn({Header:"State", accessor: "state", type: "text",filterType:"textFilter", rules: null,  disabled:true ,filterable:true}),
                            // uuidColumn({
                            //     Header:"State", 
                            //     entity:"export_imp_order_view", 
                            //     accessor: "state", 
                            //     dbname:"location_id",
                            //     dbfield:"location_id",
                            //     labelKey:"state",
                            //     activeFilter:false,
                            //     rules: null,  
                            //     filterable:true,
                            //     typeahead_fields: `id location_id  name:state  `,
                            //     span:3
                            // }),
        
                            // defaultColumn({Header:"City", accessor: "location.city", type: "text", rules: null,  disabled:true,  }),
                            // defaultColumn({Header:"Commodity", accessor: "commodity", type: "text", rules: null, filterType:"textFilter", disabled:true, filterable:true }),
                            uuidColumn({
                                Header:"Commodity", 
                                entity:"export_imp_order_view",
                                accessor: "commodity", 
                                // dbname:"commodity_id",
                                // dbfield:"commodity_id",
                                labelKey:"commodity",
                                activeFilter:false,
                                rules: null, 
                                filterable:true ,
                                typeahead_fields: `id commodity_id name:commodity  `,
                                span:3,
                                distinctOn:true,
                                distinctOnLabel:"commodity"
        
                            }),
                            uuidColumn({
                                Header:"Country", 
                                entity:"export_imp_order_view",
                                accessor: "country", 
                                // dbname:"location_id",
                                // dbfield:"location_id",
                                labelKey:"country",
                                activeFilter:false,
                                rules: null,  
                                filterable:true,
                                typeahead_fields: `id location_id  name:country`,
                                span:3,
                                distinctOn:true,
                                distinctOnLabel:"country"
        
                            }),
        
        
                                    uuidColumn({
                                Header:"State", 
                                entity:"export_imp_order_view",
                                accessor: "state", 
                                // dbname:"location_id",
                                // dbfield:"location_id",
                                labelKey:"state",
                                activeFilter:false,
                                rules: null,  
                                filterable:true,
                                typeahead_fields: `id location_id  name:state  type:country`,
                                span:3,
                                distinctOn:true,
                                distinctOnLabel:"state"
        
                            }),
        
                            // defaultColumn({ accessor: "name", type: "text", rules: null,  disabled:true }),
                            uuidColumn({
                                Header:"Customer",
                                entity: "export_imp_order_view",
                                accessor:"name",
                                dbname:"customer_id",
                                dbfield:"customer_id",
                                labelKey:"name",
                                activeFilter:false,
                                // filters: { customer_type: { _in: ["exporter"] } },
                                typeahead_fields: `id customer_id name  `,
                                span:3,
                                distinctOn:true
        
                            }),
                            defaultColumn({ accessor: "phone", type: "text", rules: null,  disabled:true }),
                            defaultColumn({ accessor: "rating",isSorted: true,
                            isSortedDesc: true, type: "rating", rules: null,  disabled:true,filterable:false,span:3 }),
                        
                            ],
                        };
                    break; 
                

        case entity==="shipping_containers":
            options = {
                entity: "shipping_containers",
                fields: `
                id
                uid                    
                customer_id
                customer {
                    id
                    name
                }

                order_id
                    
                container_no
                seal_no

                created_at
                updated_at
                export_freight_order { 
                    booking_validity_status
                    enum_exp_fri_ord_booking_val_status{comment value}
                    }
                    `,
                        

                columns: [


                        
                    defaultColumn({
                        Header: "UID",
                        accessor: "uid",
                        add:false,
                        edit:false
                    }),


                    uuidColumn({
                        Header:"Customer",
                        entity: "customer", 
                        typeahead_fields: `id name`, 
                        hideAdd: true,
                        dbname:"customer_id",
                        add:false,
                        edit:false,
                        column:false,

                        // labelKey:"commodity: {name}",
                        // filters: {  origin_type : { _in: [ "non_domestic"] } },
                        // filter_fields: [{ accessor: "supplier", nestedAccessor:"customer_int_commodities.customer_id" }],
                        }),

                    
                        uuidColumn({
                            Header:"Order",
                            entity: "export_freight_order", 
                            accessor:"order_id",
                            typeahead_fields: `id name`, 
                            hideAdd: true,
                            dbname:"order_id",
                            add:false,
                            edit:false,
                            column:false,
                            
            
                            }),    

                    defaultColumn({
                        Header:"Container No",
                        accessor: "container_no",
                        type: "text",
                        add:true,
                        edit:true,
                        span:4
            
                    }),


                    defaultColumn({
                        Header:"Seal No",
                        accessor: "seal_no",
                        type: "text",
                        add:true,
                        edit:true,
                        span:4

            
                    }),

                

                ],
            };
            break;
            case entity === "export_freight_order":
                options = {
                    entity: "export_freight_order",
                    fields: ` id uid
                    exporter_id exporter{ id name phone }
                    export_freight_id export_freight{ id ocean_freight  customer {id name}  
                    export_shipping_line {id name} }
                    
                    transportation_slip bl_slip do_slip status created_at updated_at active
                    shipping_instructions_cut_off
                    vgm_cut_off
                    gate_cut_off
                    payment_status enum_export_freight_order_payment_status {comment value}
                    booking_validity
                    gate_opens
                    
                    draft_bl_slip
                    bl_approved
                    
                    shipping_ins_status

                    draft_bl_status

                    vgm_status
                    vgm_cut_off_date
                    gate_in_cut_off_date
                    invoice
                    invoice_no
                    invoice_amount
                    booking_validity_status
                    shipping_containers {
                    uid
                        seal_no
                        container_no
                        created_at 
                        updated_at
                    }

                    shipping_ins_status
                    shipping_instructions {
                    bookingno
                    consigneenameandaddress
                    created_at
                    customer {
                        email
                        name
                    }
                    emailaddress
                    freightcharges
                    id
                    marksandnumbers
                    measurement
                    notifypartynameandaddress
                    numberandkindofpackagesdescriptionofgoods
                    numberoforiginals
                    order_id
                    placeofdelivery
                    placeofreceipt
                    portofdischarge
                    portofloading
                    remarks
                    shippernameandaddress
                    shippingline
                    telno
                    totalnumberofcontainer
                    uid
                    updated_at
                    vessel
                    weightinkgormt
                    }
                        `, 
                    columns: [
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                        //   link: `/web/exp_blr/detail/#id`,
                            link: `/web/user_manage_order/detail/#id`,
                            // link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
    
                        }),
    
                        
                        uuidColumn({
                            Header:"Freight Forwarder",
                            entity:"customer",
                            typeahead_fields: `id name`, 
                            accessor: "export_freight.customer.name",  
                            dbname:"exporter_id",
                            rules: null,  
                            edit:false,
                        }),
                        uuidColumn({
                            Header:"Shippling Line",
                            entity:"export_shipping_line",
                            typeahead_fields: `id name`, 
                            accessor: "export_freight.export_shipping_line.name",  
                            dbname:"export_shipping_line_id",
                            rules: null,  
                            edit:false,
                            }),
                            
                        defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", edit:false, type: "text", rules: null,  }),
                
                        enumColumn({
                        accessor: "status",
                        options: enum_freight_order_status?.filter(e =>  ["awaiting_confirmation","cancelled"]?.includes(e.value)),
                        type: "select",
                        add: false,
                        edit: false,
                        column: true,
                        rules:null

                    }),

                    enumColumn({
                        Header:"Payment",
                        accessor: "payment_status",
                        options: enum_export_freight_order_payment_status,
                        type: "select",
                        add: false,
                        edit: false,
                        column: true,
                        rules:null,
                        searchable:false

                    }),

                    
                    
    
                    imageColumn({
                        Header:"Transportation Slip(image)",
                        type: "photo",
                        accessor: "transportation_slip",
                        key:"transportation_slip",
                        id:"transportation_slip",
                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                        rules: null,
                        edit:false,
                        column:true,
                        minWidth:125
                    }),


                    datetimeColumn({
                        accessor: "shipping_instructions_cut_off",
                        rules: null,
                        // displayOnly: true,
                        column:true,
                        add: false,
                        edit: false,
                    }),  


                    
                    imageColumn({
                        Header:"DO Slip",
                        type: "photo",
                        accessor: "do_slip",
                        key:"do_slip",
                        id:"do_slip",
                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                        rules: null,
                        edit:false,
                        column:true,
                        minWidth:125


                    }),

                    imageColumn({
                        Header:"Draft Bl Slip",
                        type: "document",
                        accessor: "draft_bl_slip",
                        key:"draft_bl_slip",
                        id:"draft_bl_slip",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        add:false,
                        edit:false,
                        column:true,
                        minWidth:125,
                        searchable:false,
                        // dependsValue: ["status:completed", ],       
                        privateImage:true             
                    }),

                    


                    imageColumn({
                        Header:"VGM cut off(PDF)",
                        type: "document",
                        accessor: "vgm_cut_off",
                        id: "vgm_cut_off",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        add:true,
                        edit:true,
                        column:true,
                        minWidth:80,
                        width:100           ,
                        privateImage: true,       

                    }),
                    imageColumn({
                        Header:"CLP/Form13(PDF)",
                        type: "document",
                        accessor: "gate_cut_off",
                        id:'gate_cut_of',
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        add:true,
                        edit:true,
                        column:true,
                        minWidth:80,
                        width:100        ,
                        privateImage:true               
    

                    }),
                
                    // datetimeColumn({
                    //     accessor: "gate_opens",
                    //     rules: null,
                    //     column:true,
                    //     add:true,
                    //     edit:true
                    // }),   

        

                    // imageColumn({
                    //     Header:"OBL(PDF)",
                    //     type: "document",
                    //     accessor: "bl_generated",
                    //     id:"bl_generate",
                    //     accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                    //     rules: null,
                    //     add:false,
                    //     edit:false,
                    //     column:true,
                    //     minWidth:80,
                    //     width:100     ,             
                    //     privateImage:true               

                    // }),
                    imageColumn({
                        Header:"OBL Slip",
                        type: "document",
                        accessor: "bl_slip",
                        key:"bl_slip",
                        id:"bl_slip",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        add:false,
                        edit:false,
                        column:true,
                        minWidth:125,
                        privateImage:true             
                    }),






                    defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null, add:false, edit:false  }),
                    numberColumn({ accessor: "invoice_amount", add:false, edit:false }),
                        

                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                        }),
                    //   datetimeColumn({
                    //       accessor: "updated_at",
                    //       rules: null,
                    //       isSorted: true,
                    //       isSortedDesc: true,
                    //       column:true
                    //   }),
                        activeColumn()
                    ],
                };
                break;
               
                case entity === "exporter_offer_button":
                    options = {
                        entity: "export_freight_order",
                        fields: ` id uid
                        exporter_id exporter{ id name phone }
                        export_freight_id export_freight{ id ocean_freight  customer {id name}  
                        export_shipping_line {id name} }
                        
                        transportation_slip bl_slip do_slip status created_at updated_at active
                        shipping_instructions_cut_off
                        vgm_cut_off
                        gate_cut_off
                        payment_status enum_export_freight_order_payment_status {comment value}
                        booking_validity
                        gate_opens
                        
                        draft_bl_slip
                        bl_approved
                        
                        shipping_ins_status
    
                        draft_bl_status
    
                        vgm_status
                        vgm_cut_off_date
                        gate_in_cut_off_date
                        invoice
                        invoice_no
                        invoice_amount
                        booking_validity_status
                        shipping_containers {
                        uid
                            seal_no
                            container_no
                            created_at 
                            updated_at
                        }
    
                        shipping_ins_status
                        shipping_instructions {
                        bookingno
                        consigneenameandaddress
                        created_at
                        customer {
                            email
                            name
                        }
                        emailaddress
                        freightcharges
                        id
                        marksandnumbers
                        measurement
                        notifypartynameandaddress
                        numberandkindofpackagesdescriptionofgoods
                        numberoforiginals
                        order_id
                        placeofdelivery
                        placeofreceipt
                        portofdischarge
                        portofloading
                        remarks
                        shippernameandaddress
                        shippingline
                        telno
                        totalnumberofcontainer
                        uid
                        updated_at
                        vessel
                        weightinkgormt
                        }
                            `, 
                        columns: [
                            linkColumn({
                                Header: "Id",
                                // displayOnly: true,
                                accessor: "uid",
                            //   link: `/web/exp_blr/detail/#id`,
                                link: `/web/user_manage_order/detail/#id`,
                                // link_id:"customer_id",
                                field:"uid",
                                add: false,
                                edit: false,
                                column:true,
                                searchable: false,
        
                            }),
        
                            
                            uuidColumn({
                                Header:"Freight Forwarder",
                                entity:"customer",
                                typeahead_fields: `id name`, 
                                accessor: "export_freight.customer.name",  
                                dbname:"exporter_id",
                                rules: null,  
                                edit:false,
                            }),
                            uuidColumn({
                                Header:"Shippling Line",
                                entity:"export_shipping_line",
                                typeahead_fields: `id name`, 
                                accessor: "export_freight.export_shipping_line.name",  
                                dbname:"export_shipping_line_id",
                                rules: null,  
                                edit:false,
                                }),
                                
                            defaultColumn({Header:"Ocean Freight", accessor: "ocean_freight",disabled:true,  type: "text", rules: null,  }),
                    
                            numberColumn({
                                Header: "Temp Variation Charges",
                                accessor: "temp_variation_charges",
                                disabled:true,
                            min:0
                           
                            }),

                            
                            numberColumn({
                                Header: "Terminal Handling Charges",
                                accessor: "terminal_handling_charges",
                                disabled:true,
                            min:0
  
                            }),
                            numberColumn({
                                Header: "Bill of Loading Charges",
                                accessor: "bill_of_lading_charges",
                                disabled:true,
                            min:0
  
                            }),
                            numberColumn({
                                Header: "MUC Charges",
                                accessor: "muf",
                                disabled:true,
                              min:0
    
                              }),

                            numberColumn({
                                Header: "CMC Charges",
                                accessor: " ",
                                disabled:true,
                            min:0
  
                            }),
        
                             numberColumn({
                                Header: "Seal Charges",
                                accessor: "seal_charges",
                                disabled:true,
                            min:0,
                            required:null
  
                            }),
                  
                  
                             numberColumn({
                                Header: "Toll Charges",
                                accessor: "toll_charges",
                                disabled:true,
                            min:0
  
                            }),
                
 
                            uuidColumn({
                                entity: "commodity_variety",
                                typeahead_fields: `id name type: commodity{id name}`,
                                span:3,
                                hideAdd:true
                            }),
 
                            numberColumn({
                                Header: "Temperature",
                                accessor: "temperature",
                            min:0
  
                            }),          numberColumn({
                                Header: "Humidity",
                                accessor: "humidity",
                            min:0
  
                            }),          numberColumn({
                                Header: "ventilation",
                                accessor: "ventilation",
                            min:0
  
                            }),
                            numberColumn({
                                Header: "TotalCost",
                                accessor: "cost",
                                type:"testing",
                                calcDepends: `parseInt(watching?.["ocean_freight"])
                                              + parseInt(watching?.["temp_variation_charges"])
                                              + parseInt(watching?.["terminal_handling_charges"])
                                              + parseInt(watching?.["seal_charges"])
                                              + parseInt(watching?.["toll_charges"])`,
                                disabled:true
            //                     value* 
            //   ( (0.18*object?.ocean_freight)
            //   + (0.18*object?.temp_variation_charges)
            //   + (0.18*object?.muf)
            //   //  + (0.18*object?.rate)
            //   + (0.18*object?.terminal_handling_charges)
            //   //  + (0.18*object?.bl_fee)
            //   + (0.18*object?.seal_charges)
            //   + (0.18*object?.toll_charges))?.toFixed(2);

  
                          }),

                          
                            datetimeColumn({
                                accessor: "created_at",
                                rules: null,
                                displayOnly: true,
                                column:true,
                            }),
                        //   datetimeColumn({
                        //       accessor: "updated_at",
                        //       rules: null,
                        //       isSorted: true,
                        //       isSortedDesc: true,
                        //       column:true
                        //   }),
                            activeColumn()
                        ],
                    };
                    break;
                      
                case "factoringFinancea":
                    options = {
                        entity: "factoring_finance",
                        fields: `
                        id
                        uid  
                        customer_id
                        customer {
                            id
                            name
                        }

                        bank_statement_1_year
                        gst
                        pan
                        iec_doc
                        iec
                        created_at
                        updated_at
                            `,
                                
    
                        columns: [


                                
                            defaultColumn({
                                Header: "UID",
                                accessor: "uid",
                                add:false,
                                edit:false,
                            }),


                            defaultColumn({
                                Header: "Iec",
                                accessor: "iec",
                
                            }),

                            imageColumn({
                                Header:"Bank Statement (1 year)",
                                type: "photo",
                                accessor: "bank_statement_1_year",
                                id:"bank_statement_1_year",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                column:true,
                                minWidth:200


                            }),

                            imageColumn({
                                Header:"GST",
                                type: "photo",
                                accessor: "gst",
                                id:"gst",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                column:true,
                                minWidth:200


                            }),



                            imageColumn({
                                Header:"Pan",
                                type: "photo",
                                accessor: "pan",
                                id:"pan",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                column:true,
                                minWidth:200


                            }),

                            imageColumn({
                                Header:"IEC",
                                type: "photo",
                                accessor: "iec_doc",
                                id:"iec_doc",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                column:true,
                                minWidth:200


                            }),


                        ],
                    };
                    break;
                    case entity === "user_manage_orders":
                        options = {
                            entity: "export_freight_order_test",
                            fields: ` id uid
                                    booking_validity_status
                                    shipping_containers {
                                        seal_no
                                        container_no
                                        created_at
                                        updated_at
                                    }
                                    shipping_ins_status
                                    shipping_instructions {
                                        bookingno
                                        consigneenameandaddress
                                        created_at
                                        customer {
                                            email
                                            name
                                        }
                                        emailaddress
                                        freightcharges
                                        
                                        marksandnumbers
                                        measurement
                                        notifypartynameandaddress
                                        numberandkindofpackagesdescriptionofgoods
                                        numberoforiginals
                                        order_id
                                        placeofdelivery
                                        placeofreceipt
                                        portofdischarge
                                        portofloading
                                        remarks
                                        shippernameandaddress
                                        shippingline
                                        telno
                                        totalnumberofcontainer
                                        
                                        updated_at
                                        vessel
                                        weightinkgormt
                                    }
                                    vgm_cut_off
                                    vgm_cut_off_date
                                    vgm_status
                                    transportation_slip
                                    shipping_instructions_cut_off
                                
                                `, 
                            columns: [
                                linkColumn({
                                    Header: "Id",
                                    // displayOnly: true,
                                    accessor: "uid",
                                //   link: `/web/exp_blr/detail/#id`,
                                    link: `/web/user_manage_order/detail/#id`,
                                    // link_id:"customer_id",
                                    field:"uid",
                                    add: false,
                                    edit: false,
                                    column:true,
                                    searchable: false,
            
                                }),
            
                                
                                uuidColumn({
                                    Header:"Freight Forwarder",
                                    entity:"customer",
                                    typeahead_fields: `id name`, 
                                    accessor: "export_freight.customer.name",  
                                    dbname:"exporter_id",
                                    rules: null,  
                                    edit:false,
                                }),
                                uuidColumn({
                                    Header:"Shippling Line",
                                    entity:"export_shipping_line",
                                    typeahead_fields: `id name`, 
                                    accessor: "export_freight.export_shipping_line.name",  
                                    dbname:"export_shipping_line_id",
                                    rules: null,  
                                    edit:false,
                                    }),
                                    
                                defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", edit:false, type: "text", rules: null,  }),
                        
                                enumColumn({
                                accessor: "status",
                                options: enum_freight_order_status?.filter(e =>  ["awaiting_confirmation","cancelled"]?.includes(e.value)),
                                type: "select",
                                add: false,
                                edit: false,
                                column: true,
                                rules:null
        
                            }),
    
                            enumColumn({
                                Header:"Payment",
                                accessor: "payment_status",
                                options: enum_export_freight_order_payment_status,
                                type: "select",
                                add: false,
                                edit: false,
                                column: true,
                                rules:null,
                                searchable:false
        
                            }),
    
                            
                            
            
                            imageColumn({
                                Header:"Transportation Slip(image)",
                                type: "photo",
                                accessor: "transportation_slip",
                                key:"transportation_slip",
                                id:"transportation_slip",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                edit:false,
                                column:true,
                                minWidth:125
                            }),
        
    
                            datetimeColumn({
                                accessor: "shipping_instructions_cut_off",
                                rules: null,
                                // displayOnly: true,
                                column:true,
                                add:true,
                                edit:true
                            }),  
    
    
                            
                            imageColumn({
                                Header:"DO Slip",
                                type: "photo",
                                accessor: "do_slip",
                                key:"do_slip",
                                id:"do_slip",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                edit:false,
                                column:true,
                                minWidth:125
    
    
                            }),
    
                            imageColumn({
                                Header:"Draft Bl Slip",
                                type: "document",
                                accessor: "draft_bl_slip",
                                key:"draft_bl_slip",
                                id:"draft_bl_slip",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                add:false,
                                edit:false,
                                column:true,
                                minWidth:125,
                                searchable:false,
                                // dependsValue: ["status:completed", ],       
                                privateImage:true             
                            }),
    
                            
    
    
                            imageColumn({
                                Header:"VGM cut off(PDF)",
                                type: "document",
                                accessor: "vgm_cut_off",
                                id: "vgm_cut_off",
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                add:true,
                                edit:true,
                                column:true,
                                minWidth:80,
                                width:100           ,
                                privateImage: true,       
    
                            }),
                            imageColumn({
                                Header:"CLP/Form13(PDF)",
                                type: "document",
                                accessor: "gate_cut_off",
                                id:'gate_cut_of',
                                accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                rules: null,
                                add:true,
                                edit:true,
                                column:true,
                                minWidth:80,
                                width:100        ,
                                privateImage:true               
            
    
                            }),
                        
                            // datetimeColumn({
                            //     accessor: "gate_opens",
                            //     rules: null,
                            //     column:true,
                            //     add:true,
                            //     edit:true
                            // }),   
    
                
    
                            // imageColumn({
                            //     Header:"OBL(PDF)",
                            //     type: "document",
                            //     accessor: "bl_generated",
                            //     id:"bl_generate",
                            //     accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            //     rules: null,
                            //     add:false,
                            //     edit:false,
                            //     column:true,
                            //     minWidth:80,
                            //     width:100     ,             
                            //     privateImage:true               
    
                            // }),
                            imageColumn({
                                Header:"OBL Slip",
                                type: "document",
                                accessor: "bl_slip",
                                key:"bl_slip",
                                id:"bl_slip",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                add:false,
                                edit:false,
                                column:true,
                                minWidth:125,
                                privateImage:true             
                            }),
    
    
        
    
        
        
                            defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null, add:false, edit:false  }),
                            numberColumn({ accessor: "invoice_amount", add:false, edit:false }),
                                
        
                                datetimeColumn({
                                    accessor: "created_at",
                                    rules: null,
                                    displayOnly: true,
                                    column:true,
                                }),
                            //   datetimeColumn({
                            //       accessor: "updated_at",
                            //       rules: null,
                            //       isSorted: true,
                            //       isSortedDesc: true,
                            //       column:true
                            //   }),
                                activeColumn()
                            ],
                        };
                        break;
    

                        case "sourceFinance":
                            options = {
                                entity: "sourcing_finance",
                                fields: `
                                id
                                uid  
                                customer_id
                                customer {
                                    id
                                    name
                                    }

                                aadhaar
                                bank_statement_1_year
                                gst
                                gst_3b
                                pan
                                created_at
                                updated_at
                                    `,
                                        
            
                                columns: [


                                        
                                    defaultColumn({
                                        Header: "UID",
                                        accessor: "uid",
                                        add:false,
                                        edit:false,
                                        minWidth:200
                                    }),

                                    imageColumn({
                                        Header:"Aadhaar",
                                        type: "photo",
                                        accessor: "aadhaar",
                                        id:"aadhaar",
                                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                        column:true,
                                        minWidth:200
                                        
                                    }),

                                    imageColumn({
                                        Header:"Bank Statement (1 year)",
                                        type: "document",
                                        // type: "photo",
                                        accessor: "bank_statement_1_year",
                                        id:"bank_statement_1_year",
                                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                        // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                        column:true,
                                        minWidth:200


                                    }),

                                    imageColumn({
                                        Header:"GST",
                                        type: "photo",
                                        accessor: "gst",
                                        id:"gst",
                                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                        column:true,
                                        minWidth:200


                                    }),


                                    imageColumn({
                                        Header:"Gst 3b",
                                        type: "photo",
                                        accessor: "gst_3b",
                                        id:"gst_3b",
                                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                        rules: null,
                                        column:true,
                                        minWidth:200


                                    }),



                                    imageColumn({
                                        Header:"Pan",
                                        type: "photo",
                                        accessor: "pan",
                                        id:"pan",
                                        accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                        rules: null,
                                        column:true,
                                        minWidth:200


                                    }),




                                    
                                ],
                            };
                            break;
                case "testfields":
                    options = {
                        entity: "export_freight_order",
                        fields: ` id uid
                        exporter_id exporter{ id name phone }
                        export_freight_id export_freight{ id ocean_freight }
                        invoice
                        transportation_slip bl_slip do_slip   created_at updated_at active
                        
                        vgm_cut_off
                        gate_cut_off
                            
                        
                        status
                        payment_status
                        
                        invoice_no
                        invoice_amount
                        
                        draft_bl_slip
                        booking_validity_status
                        shipping_ins_status
    
                        draft_bl_status
                        vgm_status
    
                        booking_validity
                        shipping_instructions_cut_off
                        vgm_cut_off_date
                        gate_opens
                        bl_approved
                        gate_in_cut_off_date
                            `, 
                        columns: [
                            linkColumn({
                                Header: "Id",
                                // displayOnly: true,
                                accessor: "uid",
                                link: `/web/manage_order/detail/#id`,
                                // link_id:"customer_id",
                                field:"uid",
                                add: false,
                                edit: false,
                                column:true,
                                searchable: false,
        
                            }),
        
                            uuidColumn({
                                Header:"Exporter Name",
                                entity:"customer",
                                typeahead_fields: `id name`, 
                                accessor: "exporter",  
                                dbname:"exporter_id",
                                rules: null,  
                                edit:false,
                            }),
                
                            defaultColumn({Header:"Exporter Ph.", accessor: "exporter.phone", type: "text",minWidth:125 ,  rules: null, edit:false,minWidth:150   }),
                            defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", edit:false, type: "text", rules: null,   searchable:false  }),
    
                
    
                            enumColumn({
                                accessor: "payment_status",
                                options: enum_export_freight_order_payment_status,
                                type: "select",
                                add: false,
                                edit: false,
                                column: true,
                                rules:null,
                                searchable:false
        
                            }),
                            booleanColumn({accessor:"bl_approved", add:false, edit:false,}),
    
    
    
                            enumColumn({
                                accessor: "status",
                                options: enum_freight_order_status,
                                type: "select",
                                add: true,
                                edit: true,
                                column: true,
                                rules:null,
                                searchable:false
        
                            }),
                            imageColumn({
                                Header:"Transportation Slip",
                                type: "photo",
                                accessor: "transportation_slip",
                                key:"transportation_slip",
                                id:"transportation_slip",
                                accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                rules: null,
                                column:true,
                                minWidth:125,
                                searchable:false,
                                dependsValue: ["status:completed", ],                    
        
                            }),  
    
    
                            
                    
                            
        
                            
    
    
                        imageColumn({
                            Header:"DO Slip(PDF)",
                            type: "document",
                            accessor: "do_slip",
                            id: "do_slip",
                            key:"do_slip",
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            column:true,
                            add:true,
                            edit:true,
                            minWidth:125,
                            searchable:false,
                            // dependsValue: ["status:completed", ],        
                            privateImage:true            
    
                        }),
                        imageColumn({
                            Header:"Draft Bl Slip(PDF)",
                            type: "document",
                            accessor: "draft_bl_slip",
                            key:"draft_bl_slip",
                            id:"draft_bl_slip",
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            column:true,
                            minWidth:125,
                            searchable:false,
                            // dependsValue: ["status:completed", ],         
                            privateImage:true
                        }),
    
                        imageColumn({
                            Header:"VGM cut off",
                            type: "document",
                            accessor: "vgm_cut_off",
                            id: "vgm_cut_off",
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            add:false,
                            edit:false,
                            column:true,
                            minWidth:80,
                            width:100   ,
                            privateImage:true               
    
    
                        }),
    
    
    
                        
                        defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null, add:false, edit:false  }),
                        numberColumn({ accessor: "invoice_amount", add:false, edit:false }),
        
    
                        enumColumn({ accessor: "booking_validity_status", options: enum_exp_fri_ord_booking_val_status, type: "select",  
                        add: false,
                        edit: false,   searchable: false, }),
    
                        enumColumn({ accessor: "shipping_ins_status", options: enum_exp_fri_ord_shipping_ins_status, type: "select",  add: false,
                        edit: false,
                            searchable: false, }),
    
                        enumColumn({ accessor: "draft_bl_status", options: enum_exp_fri_ord_draft_bl_status , type: "select",  add: false,
                        edit: false,
                            searchable: false, }),
                        
                        enumColumn({ accessor: "vgm_status", options: enum_exp_fri_ord_vgm_status , type: "select",   add: false,
                        edit: false,
                            searchable: false, }),
    
                        datetimeColumn({
                            accessor: "booking_validity",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
    
                        datetimeColumn({
                            Header:"Shipping Ins ",
                            accessor: "shipping_instructions_cut_off",
                            rules: null,
                            // displayOnly: true,
                            column:true,
                            add:true,
                            edit:true
                        }),   
    
    
                        datetimeColumn({
                            accessor: "vgm_cut_off_date",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
                            datetimeColumn({
                            accessor: "gate_opens",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
    
                        datetimeColumn({
                            accessor: "gate_in_cut_off_date",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
    
                        imageColumn({
                            Header:"CLP/Form13(PDF)",
                            type: "document",
                            accessor: "gate_cut_off",
                            id:'gate_cut_of',
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            add:false,
                            edit:false,
                            column:true,
                            minWidth:80,
                            width:100        ,
                            privateImage:true               
            
    
                        }),
    
                        imageColumn({
                            Header:"OBL Slip(PDF)",
                            type: "document",
                            accessor: "bl_slip",
                            key:"bl_slip",
                            id:"bl_slip",
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            column:true,
                            minWidth:125,
                            searchable:false,
                            // dependsValue: ["status:completed", ],    
                            privateImage:true                
    
                        }),
                            datetimeColumn({
                                accessor: "created_at",
                                rules: null,
                                displayOnly: true,
                                column:true,
                            }),
                        //   datetimeColumn({
                        //       accessor: "updated_at",
                        //       rules: null,
                        //       isSorted: true,
                        //       isSortedDesc: true,
                        //       column:true
                        //   }),
                            activeColumn()
                        ],
                    };
                    break;
    
            case entity === "bidding":
                options = {
                    entity : "bidding",
                    fields:`
                        id 
                        uid
                        
                        commodity_id
                        commodity {
                            name
                            id
                        }


                                
                        exporter_id
                        exporter {
                            active
                            address
                            area
                            city
                            district
                            gst_no
                            id
                            name
                        }
                        
                        delivery_location_id
                        delivery_location {
                            country
                            area
                            address
                            district
                            id
                            name
                        }
                        
                    
                        
                        importer_id
                        importer {
                            active
                        address
                        area
                        city
                        district
                        gst_no
                        id
                        name
                        }
                        
                        pick_up_location_id
                        pick_up_location {
                            active
                            address
                            area
                            city
                            country
                            district
                            email
                            id
                            name
                            notes
                            state
                            uid
                        }
                        
                        quantity_in_mt
                        
                        rank
                        
                        delivery_location_address
                        size
                        status
                        color
                        others
                        expected_rate
                        bid_response
                        created_at
                        updated_at
                        `,
                        columns:[
                        
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                            link: `/web/manage_order/detail/#id`,
                            // link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
    
                            }),
    
                            uuidColumn({
                            Header:"Commodity",
                            entity:"commodity",
                            typeahead_fields: `id name`, 
                            accessor: "commodity",  
                            //  dbname:"exporter_id",
                            rules: null,  
                            //  edit:false,
                            }),
                
                            
                            // uuidColumn({
                            // Header:"Delivery Location",
                            // entity:"location",
                            // typeahead_fields: `id name`, 
                            // accessor: "delivery_location",  
                            // //  dbname:"exporter_id",
                            // rules: null,  
                            // //  edit:false,
                            // }),

                            defaultColumn({Header:"Location", accessor: "delivery_location_address",minWidth:125 ,  rules: null, edit:false,minWidth:150   }),
                            defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:125 ,  rules: null, edit:false,minWidth:150   }),
                            enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, searchable:false }),
                            defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null, }),
                            defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null, }),
                            defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null, }),
                            defaultColumn({accessor: "expected_rate", edit:false, type: "text", rules: null, }),
                            defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false  }),
            
    
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                            column:true
                        }),
                        activeColumn()
                    
                        ]
                }

            case entity === "bidding_importer_offer":
                options = {
                    entity : "bidding",
                    fields:`
                        id 
                        uid
                        bidding_responses
                        bidding_timeline
                        active released commodity_id commodity { name id }
                            
                        bidding_orders{
                            bidding_offer_id
                    
                            bidding_rank{
                            id
                            rank
                            }
                        }
                        exporter_id exporter {id name }
                        delivery_location_id delivery_location { country area address district id name }
                        importer_id importer { 
                            id 
                            name
                            active          
                            locations {
                            id
                            address
                            city
                            state
                          } }
                        pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                        quantity_in_mt rank size status color
                        others expected_rate bid_response ok_margin total_expected_price
                        bidding_orders_aggregate{
                            aggregate{
                              count
                            }
                          }
                        expected_delivery_date  
                        delivery_location_address
                        created_at
                        updated_at
                        `,
                        columns:[
                        
                        linkColumn({
                            Header: "Bid Id",
                            // displayOnly: true,
                            accessor: "uid",
                            link: `/management/bidding_offer_/detail/#id`,
                            // link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
                            minWidth:50
                            }),
                            defaultColumn({Header:"Country", accessor: "delivery_location.country",minWidth:50 ,   rules:{required:"This Field is required"},    }),
    
                     
                                
                            
                            // uuidColumn({
                            //     Header:"Location",
                            //     entity:"location",
                            //     typeahead_fields: `id name`, 
                            //     accessor: "delivery_location",  
                            //     dbname:"delivery_location_id",
                            //     rules: null,  
                            //     searchable:false
                            //     //  edit:false,
                            //     }),
                            defaultColumn({Header:"Location", accessor: "delivery_location_address",minWidth:100 ,   rules:{required:"This Field is required"},    }),

                                uuidColumn({
                                    Header:"importer",
                                    entity:"importer",
                                    typeahead_fields: `id name`, 
                                    accessor: "importer",  
                                    dbname:"importer_id",
                                    rules: null,  
                                    //  edit:false,
                                    }),

                                    
                            uuidColumn({
                            Header:"Commodity",
                            entity:"commodity",
                            typeahead_fields: `id name`, 
                            accessor: "commodity",  
                            //  dbname:"exporter_id",
                            rules: null,  
                            //  edit:false,
                            }),
                
                            // uuidColumn({
                            // Header:"Pickup Location",
                            // entity:"location",
                            // typeahead_fields: `id name`, 
                            // accessor: "pick_up_location",  
                            // dbname:"pick_up_location_id",
                            // rules: null,  
                            // //  edit:false,
                            // }),
                            defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",   rules: null, edit:false,minWidth:100   }),
                            defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null,minWidth:100 }),
                            defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null, minWidth:100}),
                            defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null, minWidth:100}),
                            defaultColumn({accessor: "expected_rate", edit:false, type: "text", rules: null, }),
                            numberColumn({Header:"Total Exp-Price", accessor: "total_expected_price",add:false, edit:false, type: "text", rules: null,   searchable:false,  width:60 }),
                            dateColumn({
                                accessor: "expected_delivery_date",
                                rules: null,
                                displayOnly: true,
                                column:true,
                            }),
                            defaultColumn({Header:"OK Margin %", accessor: "ok_margin", type: "text", rules: null,   searchable:false,  }),
                            // defaultColumn({ Header:"Bid Response", accessor: "bidding_orders_aggregate.aggregate.count",add:false, edit:false, type: "text", rules: null,   searchable:false,   }),
                            // datetimeColumn({
                            //     accessor: "bidding_timeline",
                            //     rules: null,
                            //     subFrom:true,
                            //     displayOnly: true,
                            //     column:true,
                            // }),   
                            enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, activeFilter:false  }),

                        linkColumn({
                            Header: "Bid Response ",
                            // displayOnly: true,
                            accessor: "bidding_responses",
                            link: `/management/bidding_offer_/detail/#id`,
                            link_id:"id",
                            field:"bidding_responses",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
                            minWidth:100
    
                            }),
                            
                            booleanColumn({accessor:"released",width:50}),
                            // datetimeColumn({
                            //     accessor: "created_at",
                            //     rules: null,
                            //     displayOnly: true,
                            //     column:true,
                            // }),
                            // datetimeColumn({
                            //     accessor: "updated_at",
                            //     rules: null,
                            //     isSorted: true,
                            //     isSortedDesc: true,
                            //     column:true
                            // }),
                            activeColumn()
                    
                        ]
                }

                break;

            case entity === "bidding_exporter":
                options = {
                    entity : "bidding",
                    fields:`
                        id 
                        uid
                        active
                        commodity_id
                        commodity { name id }
                        exporter_id customer { id name}
                        delivery_location_id delivery_location { country area address district id name }
                        importer_id importer { active address area city district gst_no id name }
                        pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                        quantity_in_mt
                        rank size status color others
                        ok_margin expected_rate bid_response
                        created_at
                        updated_at
                        `,
                        columns:[
                        
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                            link: `/web/manage_order/detail/#id`,
                            // link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
    
                            }),
    
                            uuidColumn({
                            Header:"Commodity",
                            entity:"commodity",
                            typeahead_fields: `id name`, 
                            accessor: "commodity",  
                            //  dbname:"exporter_id",
                            rules: null,  
                            //  edit:false,
                            add:false,
                            edit:false
                            }),
                
                            
                            uuidColumn({
                            Header:"Delivery Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "delivery_location",  
                            dbname:"delivery_location_id",
                            rules: null,  
                            add:false,
                            edit:false
                            //  edit:false,
                            }),
                            uuidColumn({
                            Header:"Pickup Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "pick_up_location",  
                            dbname:"pick_up_location_id",
                            rules: null,  
                            add:false,
                            edit:false
                            //  edit:false,
                            }),

                            defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:125 ,  rules: null,add:false,
                            edit:false,minWidth:150   }),
                            enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false,
                            edit:false,searchable:false,activeFilter:false  }),
                            defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null,add:false,
                            edit:false }),
                            defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null, add:false,
                            edit:false}),
                            defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null,add:false,
                            edit:false }),
                            defaultColumn({accessor: "expected_rate", edit:false, type: "text", rules: null,add:false,
                            edit:false }),
                            defaultColumn({ accessor: "bid_response", type: "text", rules: null,   searchable:false  }),
                            
                            datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                            }),
                            datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                            column:true
                            }),
                            activeColumn()
                    
                        ]
                }

                break;

        case entity === "bidding_exporter_order":
            options = {
                entity : "bidding_order",
                fields:`
                    id 
                    uid
                    active
                  
                    bidding{
                        importer{
                            locations{
                              id
                              name
                            }
                          }

                        expected_delivery_date
                        importer{
                            id
                            name
                        }
                        exporter {
                            id
                            uid
                            locations {
                              id
                              name
                            }
                            users {
                              rating
                            }
                            bidding_orders_aggregate(where: {exp_order_status:{_eq:won} }) {
                              aggregate {
                                count
                              }
                            }
                          }
                        total_expected_price
                    }
                        
                    bid_price_per_mt                                                                    
                    bid_response
                    bidding_offer_id
                    bidding_time_line
                    exporter_id customer{ id  name locations{id name}}
                    admin_order_action_status enum_bid_order_action_admin_status { comment value }
                    exp_order_action_status enum_bid_order_action_exp_status { comment value }
                    imp_offer_action_status  enum_bid_order_action_imp_status { comment value }
                    admin_order_status enum_bidding_admin_status { comment value }
                    exp_order_status enum_bidding_exp_status { comment value }
                    imp_offer_status enum_bidding_imp_status { comment value }
                    bid_price_per_mt
                    bidding_rank{
                        rank
                        }
                    created_at
                    updated_at
                    `,
                    columns:[
                        defaultColumn({Header:"Rank", accessor: "bidding_rank.rank",  type: "text",column:true, rules: null, add:false,
                        edit:false}),
                    linkColumn({
                        Header: "Exporter Id",
                        // displayOnly: true,
                        accessor: "uid",
                        // link: `/web/manage_order/detail/#id`,
                        // link_id:"customer_id",
                        field:"uid",
                        add: false,
                        edit: false,
                        column:true,
                        searchable: false,
                        minWidth:50
                        }),

            
                        
                        uuidColumn({
                        Header:"Exporter Name",
                        entity:"customer",
                        typeahead_fields: `id name`, 
                        accessor: "customer",  
                        dbname:"exporter_id",
                        rules: null,  
                        add:false,
                        edit:false,
                        minWidth:100
                        }),

                         defaultColumn({Header:"Location",accessor: "customer.locations[0].name", edit:false, type: "text", rules: null,add:false, edit:false,minWidth:50 }),


                        defaultColumn({Header:"Rating",accessor: "bidding.exporter.users[0].rating", edit:false, type: "rating", rules: null,add:false, edit:false,minWidth:100 }),
                        defaultColumn({Header:"No of trades",accessor: "bidding.bidding_orders_aggregate.aggregate.count", edit:false, type: "text", rules: null,add:false, edit:false }),

                        numberColumn({Header:"Bidding rate/MT", accessor: "bid_price_per_mt",  rules: null,add:false, width:80   }),

                        datetimeColumn({
                            Header:"Date & Time",
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                            }),


                        uuidColumn({
                            Header:"Importer",
                            entity:"customer",
                            typeahead_fields: `id name`, 
                            accessor: "bidding.importer.name",  
                            dbname:"exporter_id",
                            rules: null,  
                            add:false,
                            edit:false
                         }),

                        // uuidColumn({
                        //     Header:"Offer",
                        //     entity:"bidding",
                        //     typeahead_fields: `id name`, 
                        //     accessor: "bidding",  
                        //     dbname:"bidding_offer_id",
                        //     rules: null,  
                        //     add:false,
                        //     edit:false,
                        //     }),
                       
                        defaultColumn({Header:"Exp. Price",accessor: "bidding.total_expected_price", edit:false, type: "text", rules: null,add:false, edit:false }),
                      

                        enumColumn({Header:"Exp Order Action", accessor: "exp_order_action_status", options: enum_bid_order_action_exp_status, type: "select", rules:null, add:false,
                            searchable:false ,edit:false}),





                        // defaultColumn({Header:"Imp Location",accessor: "bidding.exporter.locations.[0].name", edit:false, type: "text", rules: null,add:false, edit:false }),
                      
                      
                      
                        enumColumn({Header:"Exp Order Status", accessor: "exp_order_status", options: enum_bidding_exp_status, type: "select", rules:null, add:false,
                            searchable:false }),



                      
                        uuidColumn({
                            Header:"Imp Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "bidding.importer.locations[0].name",  
                            dbname:"delivery_location_id",
                            rules: null,  
                            add:false,
                            edit:false
                            //  edit:false,
                            }),


                        defaultColumn({Header:"Customer ID",accessor: "bidding.exporter.uid", edit:false, type: "text", rules: null,add:false, edit:false }),

                    
                        enumColumn({Header:"Bid Action", accessor: "admin_order_action_status", options: enum_bid_order_action_admin_status, type: "select", rules:null, add:false,
                            searchable:false }),

                        

                        enumColumn({Header:"Offer Action", accessor: "imp_offer_action_status", options: enum_bid_order_action_imp_status, type: "select", rules:null, add:false,
                            searchable:false }),

                        enumColumn({Header:"Bid status", accessor: "admin_order_status", options: enum_bidding_admin_status, type: "select", rules:null, add:false,
                            searchable:false }),


                        enumColumn({Header:"Imp Order Status", accessor: "imp_offer_status", options: enum_bidding_imp_status, type: "select", rules:null, add:false,
                            searchable:false, edit:false }),

                       
                        // numberColumn({ accessor: "bid_response",minWidth:125 ,  rules: null,add:false,
                        // edit:false,minWidth:150   }),

                  
                        // defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null,add:false,
                        // edit:false }),
                         
                        dateColumn({
                            Header:"Expected Delivery date",
                            accessor: "bidding.expected_delivery_date",
                            rules: null,
                            displayOnly: true,
                            column:true,
                            edit:false
                            }),
              
                        datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                        column:true
                        }),
                        activeColumn()
                
                    ]
            }

            break;
        
        case  "bidding_exporter":
                options = {
                    entity : "bidding",
                    fields:`
                    id uid active
                    commodity_id commodity { name id }
                    exporter_id exporter { active address area city district gst_no id name }
                    f_location_id delivery_location { country area address district id name }
                    importer_id importer { active address area city district gst_no id name }
                    pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                    quantity_in_mt rank size status color others 
                    expected_rate bid_response
                    created_at
                    updated_at
                        `,
                        columns:[
                        
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                            link: `/web/manage_order/detail/#id`,
                            // link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
    
                            }),
    
                            uuidColumn({
                            Header:"Commodity",
                            entity:"commodity",
                            typeahead_fields: `id name`, 
                            accessor: "commodity",  
                            //  dbname:"exporter_id",
                            rules: null,  
                            //  edit:false,
                            add:false,
                            edit:false
                            }),
                
                            
                            uuidColumn({
                            Header:"Delivery Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "delivery_location",  
                            dbname:"delivery_location_id",
                            rules: null,  
                            add:false,
                            edit:false
                            //  edit:false,
                            }),
                            uuidColumn({
                            Header:"Pickup Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "pick_up_location",  
                            dbname:"pick_up_location_id",
                            rules: null,  
                            add:false,
                            edit:false
                            //  edit:false,
                            }),

                            defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:125 ,  rules: null,add:false, edit:false,minWidth:150   }),
                            enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, edit:false,searchable:false }),
                            // defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null,add:false, edit:false }),
                            // defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null, add:false, edit:false}),
                            // defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null,add:false, edit:false }),
                            defaultColumn({accessor: "expected_rate", edit:false, type: "text", rules: null,add:false, edit:false }),
                            defaultColumn({ accessor: "bid_response", type: "text", rules: null,   searchable:false  }),

                            datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                            }),
                            datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                            column:true
                            }),
                            activeColumn()
                    
                        ]
                }

            break    
            case  "exporters_data":
                options = {
                    entity : "bidding",
                    fields:`
                    id uid active
                    commodity_id commodity { name id }
                    exporter_id exporter { active address area city district gst_no id name }
                    delivery_location_id delivery_location { country area address district id name }
                    importer_id importer { active address area city district gst_no id name }
                    pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                    quantity_in_mt rank size status color others 
                    expected_rate bid_response
                    created_at
                    updated_at
                      `,
                      columns:[
                        
                        // linkColumn({
                        //     Header: "Id",
                        //     // displayOnly: true,
                        //     accessor: "uid",
                        //     link: `/web/manage_order/detail/#id`,
                        //     // link_id:"customer_id",
                        //     field:"uid",
                        //     add: false,
                        //     edit: false,
                        //     column:true,
                        //     searchable: false,
                        //     minWidth:50
    
                        //  }),
    
                         uuidColumn({
                            Header:"Commodity",
                            entity:"commodity",
                            typeahead_fields: `id name`, 
                            accessor: "commodity",  
                           //  dbname:"exporter_id",
                            rules: null,  
                           //  edit:false,
                           add:false,
                           edit:false,
                           
                         }),
             
                 
                         uuidColumn({
                            Header:"Pickup Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "pick_up_location",  
                            dbname:"pick_up_location_id",
                            rules: null,  
                            add:false,
                            edit:false
                           //  edit:false,
                         }),

                         defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,   }),
                         defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null, add:true, edit:true}),
                         defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null, }),
                         defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null, }),
                         enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, edit:false }),
                         defaultColumn({Hedader:"Expected rate per MT", accessor: "expected_rate", edit:false, type: "text", rules: null, rules:{required:"This Field is required"} }),
                         uuidColumn({
                            Header:"Delivery Location",
                            entity:"location",
                            typeahead_fields: `id name`, 
                            accessor: "delivery_location",  
                            dbname:"delivery_location_id",
                            rules:{required:"This Field is required"}
                             
                         }), 
                        //  defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false, add:false  }),
                                                                
                         linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "bid_response",
                            link: `/web/manage_order/detail/#id`,
                            link_id:"id",
                            field:"bid_response",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
    
                         }),
                         dateColumn({
                            accessor: "expected_delivery_date",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true,
                            show:true,
                            rules:{required:"This Field is required"}
                         }),
                         datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                            column:true,
                         }),
                         datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                            column:true
                         }),
                         activeColumn()
                   
                      ]
                }

            break    
                     
                    case entity === "other_test_ff_detais":
                        options = {
                          entity: "export_freight",
                          fields: ` 
                          id
                          uid
                          container_type enum_container_type { comment}
                          origin_port_id origin_port{id name address}
                          destination_port_id destination_port{id name address}
                          eta
                          etd
                          dollar_price 
                          ocean_freight
                          cargo_category_type enum_cargo_category{comment}
                          bill_of_lading_charges
                          cmc_charges
                          seal_charges
                          temp_variation_charges
                          terminal_handling_charges
                          toll_charges
                          listing_validity_date
                          export_shipping_line_id
                          export_shipping_line{
                            id
                            name
                            images
                          }
                          cut_off
                          transit_period
                          vessel
                          muf
                          vessel_risk
                          total_amount
                          validity 
                          active
                          customer{
                              id
                              name
                            }
                          
                          
                          freight_rank{
                          id
                          rank
                          }
          
                          created_at
                          updated_at
                             `, 
                          columns: [
                              linkColumn({
                                  Header: "Id",
                                  // displayOnly: true,
                                  accessor: "uid",
                                  // link: `/web/exporter/detail/#id`,
                                  // link_id:"customer_id",
                                  field:"uid",
                                  add: false,
                                  edit: false,
                                  column:false,
                                  searchable: false,
                                  minWidth:150,
                                  // miniColumnWidth:150
          
                               }),
          
                               uuidColumn({
                                  Header:"Origin Port",
                                  entity:"location",
                                  accessor: "origin_port",
                                  typeahead_fields: `id name`,
                                  dbname: "origin_port_id",
                                  labelKey:"name",
                                  filters: { location_type: { _in: [ "origin_port"] } },
                                  filterSpan:3,
                                  span:3,
                                  distinctOn:true,
                                  distinctOnLabel:"origin_port.name"
          
                              }),
            
                              uuidColumn({
                                  Header:"Destination Port",
                                  entity: "location",
                                  accessor:"destination_port",
                                  typeahead_fields: `id name`,
                                  dbname: "destination_port_id",
                              
                                  filters: { location_type: { _in: [ "destination_port"] } },
                                  filterSpan:3,
                                  span:3,
                                  distinctOn:true,
                                  distinctOnLabel:"destination_port.name"
                                }),
          
                              enumColumn({
                                  Header:"Container",
                                  accessor: "container_type",
                                  options: enum_container_type,
                                  type: "select",
                                  add: true,
                                  edit: true,
                                  column: true,
                                  // shareWith:enum_cargo_category,
                                  // shareValue:"cargo_category_type"
          
                              }),
          
                                                  
                              uuidColumn({
                                  Header:"Carrier",
                                  entity: "export_shipping_line",
                                  accessor:"export_shipping_line",
                                  typeahead_fields: `id name`,
                                  dbname: "export_shipping_line_id",
                                  searchable:false,
                                  filterable:false
                                  // hideAdd:true
                                }),
          
          
          
          
          
                              enumColumn({
                                  Header:"Cargo",
                                  accessor: "cargo_category_type",
                                  options: enum_cargo_category,
                                  type: "select",
                                  add: true,
                                  edit: true,
                                  column: true,
                                  filterable:false,
                                  // minWidth:80,
                                  // width:80,
                                  // filterable:true
                                  // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
          
                              }),
          
          
                  
                              
          
          
                              // dateColumn({ accessor: "cut_off",rules: null,   column: true, add:true, edit:true }),
                              dateColumn({ accessor: "etd", rules: null,   column: true, add:true, edit:true, }),
                              dateColumn({FilterFormHeader:"MAX ETA", Header:"ETA", accessor: "eta", rules: null,   column: true, add:true, edit:true,filterable:true  }),
                           
                              numberColumn({
                                  Header: "freight($)",
                                  accessor: "ocean_freight",
                                  // minWidth:80,
                                  // width:80,
                              }),
          
          
                            //   defaultColumn({Header:"Rank", accessor: "freight_rank.rank" }),
                                                  
                              // numberColumn({
                              //     Header: "Freight($)",
                              //     accessor: "total_amount",
                              //     displayOnly:true
                              // }),
          
          
          
                              //   uuidColumn({
                              //     Header:"Freight Forwarder",
                              //     entity:"customer",
                              //     typeahead_fields: `id name`,
                              //     searchable:false,
                              //     filterable:false
          
                              //   }),
          
                              numberColumn({
                                  Header: "MUC Charges",
                                  accessor: "muf",
                                  column:false,
          
                              }),
          
          
                              numberColumn({
                                  Header: "vessel_risk Charges",
                                  accessor: "vessel_risk",
                                  column:false,
          
                              }),
          
                               
                               numberColumn({
                                  Header: "Bill of Lading Charges",
                                  accessor: "bill_of_lading_charges",
                                  column:false,
          
                              }),
          
                              numberColumn({
                                  Header: "Exchange Rate",
                                  accessor: "dollar_price",
                                  column:false,
          
                              }),
                               numberColumn({
                                  Header: "CMC Charges",
                                  accessor: "cmc_charges",
                                  column:false,
          
                              }),
          
                               numberColumn({
                                  Header: "Seal Charges",
                                  accessor: "seal_charges",
                                  column:false,
                                  notRequired:true
          
          
                              }),
                              numberColumn({
                                  Header: "Temp Variation Charges",
                                  accessor: "temp_variation_charges",
                                  column:false,
                                  dependsValue: ["cargo_category_type:reefer"],
          
                              }),
                            
                              numberColumn({
                                  Header: "Terminal Handling Charges",
                                  accessor: "terminal_handling_charges",
                                  column:false,
          
                              }),
          
                        
          
                               numberColumn({
                                  Header: "Toll Charges",
                                  accessor: "toll_charges",
                                  column:false,
          
                              }),
          
                              // numberColumn({
                              //     Header: "Transit Days",
                              //     accessor: "transit_period",
                              // }),
          
                              // enumColumn({ accessor: "status", options: enum_freight_transit_status, type: "select",    searchable: false, }),
                              numberColumn({
                                  Header: "Transit Period",
                                  accessor: "transit_period",
                                  column:false,
          
                              }),
                              
                                  
                              defaultColumn({Header:"Vessel Name", accessor: "vessel", column:false,}),
                             
                              // dateColumn({ accessor: "validity", rules: null,   column: true, add:true, edit:true }),
                                 
                              booleanColumn({Header:"Active",accessor:"active",add:true, edit:true, column:false, searchable:false, filterable:false}),
                              
                              datetimeColumn({
                                  accessor: "listing_validity_date",
                                  rules: null,
                                  // displayOnly: true,
                                  add:true,
                                  edit:true,
                                  column:false,
                              }),
                              datetimeColumn({
                                  accessor: "created_at",
                                  rules: null,
                                  displayOnly: true,
                                  column:false,
                              }),
                              datetimeColumn({
                                  accessor: "updated_at",
                                  rules: null,
                                  isSorted: true,
                                  isSortedDesc: true,
                                  column:false
                              }),
          
          
                              
          
                          ],
                        };
                        break;
                    
           
             
    


        default:
            return {};
    }
    // console.log("cehcking at athe end is not enoght",entity, { solution, ...default_options, ...options, ...rest } )
    return { solution, ...default_options, ...options, ...rest };
};
