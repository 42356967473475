import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();


	let entity ="customer";
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity:"bidding_order" }),
	// 	fields: `
	// 	id active
	// 	active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
	// 	active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
	// 	total_offers: offers_aggregate{aggregate{count}}
	// 	total_orders: orders_aggregate{aggregate{count}}
	// `,
	});

	console.log("object",object)


	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			// component={DetailInfo}
			entity={"customer"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				// {
                //     label: getLabel("contact", true),
                //     value: object?.active_contacts?.aggregate?.count || 0,
                //     total: object?.total_contacts?.aggregate?.count || 0,
                // },
                // {
                //     label: getLabel("location", true),
                //     value: object?.active_locations?.aggregate?.count || 0,
                //     total: object?.total_locations?.aggregate?.count || 0,
                // },
			]}
			tab_options={[
				{
					...useEntityFields({ entity: "bidding_importer_offer", parent_id, solution }),
					label: getLabel("Order", true),
					value: "Order",
                    disableAdd:true,
					disableAction:true,
					component: DataTable,
					filters: { id: { _eq: parent_id } },
					overrideValues: { bidding_offer_id: parent_id },
					skipColumns: ["released","bid_response","bidding_timeline"],
					hideFilter:true,
					disableEdit:true
					
				
					
				},
				{
					...useEntityFields({ entity: "bidding_exporter_order", parent_id, solution }),
					label: getLabel("bidding_offers", true),
					value: "bidding_offer",
                    disableAdd:true,
					disableAction:true,
					component: DataTable,
					filters: { bidding_offer_id: { _eq: parent_id } },
					overrideValues: { bidding_offer_id: parent_id },
					skipColumns: ["bidding.exporter.uid","imp_offer_action_status",
				 "updated_at","bidding.expected_delivery_date","location","bidding.importer.name","bidding.exporter.locations.[0]"],
					hideFilter:true

				},
				// {
				// 	...useEntityFields({ entity: "location", parent_id, solution }),
				// 	label: getLabel("location", true),
				// 	value: "location",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },	
			 
				 
			]}
		/>
	);
};

export default Detail;
