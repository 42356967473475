// import { useContext } from 'react';
import axios from 'axios';
// import moment from 'moment';
import { toast } from 'react-toastify';
import { APP_CONFIG } from '../../../config';
// var FileSaver = require('file-saver');

export const SendWebRequest = function ({    method, subUrl, body = {},Smes, Emes}) {

  
 
 
  return axios({
        method,
        url: `${APP_CONFIG.REACT_APP_SERVICES_URL}${subUrl}`,
        data: body,
    })
    .then((response) => {

      console.log("response ",response)
        toast.success(Smes || response?.message);
        return response;
 
    })
    .catch((err) => {

         console.log("check is is",err,err?.response?.data?.error?.message);
        // console.log("check is", err)

      toast.error(Emes(err)|| 'Something went wrong, please contact Admin !', { autoClose: 2000 }, ); 
      // toast.error(Emes(err)|| 'Something went wrong, please contact Admin !', { autoClose: 2000 }, ); 
    });
};
