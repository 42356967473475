import React from "react";
import { Redirect } from "react-router-dom";
import AuthRedirect from "../pages/authentication/AuthRedirect";
import ForgetPwd from "../pages/authentication/ForgetPassword";
import Login from "../pages/authentication/Login";
import Logout from "../pages/authentication/Logout";
import PasswordReset from "../pages/authentication/PasswordReset";
import ManagementActivityList from "../pages/management/activities/list";
import ManagementNbfcList from "../pages/management/nbfc/list";
import ManagementNbfcDetail from "../pages/management/nbfc/detail";
import ManagementContactDetail from "../pages/management/contact/detail";
import ManagementContactList from "../pages/management/contact/list";
import ManagementDashboard from "../pages/management/dashboard";
import ManagementUserDetail from "../pages/management/user/detail";


import ManagementUserOrganizationList from "../pages/management/organization/list";
import ManagementUserOrganizationDetail from "../pages/management/organization/detail";



import ManagementInquiriesList from "../pages/management/inquiries/list";
import ManagementInquiriesDetail from "../pages/management/inquiries/detail";

import ManagementQuotationsList from "../pages/management/quotations/list";
import ManagementQuotationsDetail from "../pages/management/quotations/detail";



import ManagementUserList from "../pages/management/user/list";
import ManagementPaymentDetail from "../pages/management/payment/detail";
import ManagementPaymentList from "../pages/management/payment/list";
import MarketIntelligenceDashboard from "../pages/marketIntelligence/dashboard";
import MarketIntelligenceRateDetail from "../pages/marketIntelligence/rate/detail";
import MarketIntelligenceRateList from "../pages/marketIntelligence/rate/list";
import MarketIntelligenceStockDetail from "../pages/marketIntelligence/stock/detail";
import MarketIntelligenceStockList from "../pages/marketIntelligence/stock/list";

import MarketPlaceSourceFinance from "../pages/marketplace/sourceFinance/list";
import MarketPlaceFactoringFinance from "../pages/marketplace/factoringFinance/list";
 


import MarketIntelligenceDataCenterDetail from "../pages/marketIntelligence/dataCenter/detail";
import MarketIntelligenceDataCenterList from "../pages/marketIntelligence/dataCenter/list";

import MarketPlaceDashboard from "../pages/marketplace/dashboard";
import MarketPlaceOfferDetail from "../pages/marketplace/offer/detail";
import MarketPlaceOfferList from "../pages/marketplace/offer/list";
import MarketPlaceOrderDetail from "../pages/marketplace/order/detail";
import MarketPlaceOrderList from "../pages/marketplace/order/list";
import MarketPlaceExporterOrderDetail from "../pages/marketplace/exporter/detail";
import MarketPlaceExporterOrderList from "../pages/marketplace/exporter/list";
import MarketPlaceFreightDetail from "../pages/marketplace/import/detail";
import MarketPlaceFreightList from "../pages/marketplace/import/list";
import MarketPlaceFreighOrdertList from "../pages/marketplace/freight_order/list";
import MarketPlaceFreightOrderDetail from "../pages/marketplace/freight_order/detail";
import MarketPlaceShippingtList from "../pages/marketplace/shippingLine/list";
import MarketPlaceShippingDetail from "../pages/marketplace/shippingLine/detail";
import MarketPlaceBiddingOfferList from "../pages/marketplace/bidding_offer/list";
import MarketPlaceBiddingOfferDetail from "../pages/marketplace/bidding_offer/detail";
import MarketPlaceBiddingOrderList from "../pages/marketplace/bidding_order/list";
import OrderPaymentDetailsList from "../pages/marketplace/order_payments/list";
import MarketPlacePaymentDetail from "../pages/marketplace/payment/detail";
import MarketPlacePaymentList from "../pages/marketplace/payment/list";
import MasterDataCatalogueDetail from "../pages/masterdata/catalogue/detail";
import MasterDataCatalogueList from "../pages/masterdata/catalogue/list";
import MasterDataCommissionDetail from "../pages/masterdata/commission/detail";
import MasterDataCommissionList from "../pages/masterdata/commission/list";
import MasterDataCommodityDetail from "../pages/masterdata/commodity/detail";
import MasterDataCommodityList from "../pages/masterdata/commodity/list";
import MasterDataCommodityVarietyDetail from "../pages/masterdata/commodity_variety/detail";
import MasterDataCommodityVarietyList from "../pages/masterdata/commodity_variety/list";
import MasterDataDashboard from "../pages/masterdata/dashboard";
import MasterDataParameterDetail from "../pages/masterdata/parameter/detail";
import MasterDataParameterList from "../pages/masterdata/parameter/list";
import TradeCustomerDetail from "../pages/trade/customer/detail";
import TradeCustomerList from "../pages/trade/customer/list";
import TradeDashboard from "../pages/trade/dashboard";
import TradeJobCardDetail from "../pages/trade/jobcard/detail";
import TradeJobCardList from "../pages/trade/jobcard/list";
import TradeLocationDetail from "../pages/trade/location/detail";
import TradeLocationList from "../pages/trade/location/list";
import TradeVehicleDetail from "../pages/trade/vehicle/detail";
import TradeVehicleList from "../pages/trade/vehicle/list";

//Web based Routes
import UserLogin from "../pages/web/authentication/Login";
import AdminDashboard from '../pages/dashboard/index';
import OtpScreen from "../pages/web/authentication/OtpScreen";
import WebExporterList from "../pages/web/pages/exporter/pohistory/list";
import WebExporterDetail from "../pages/web/pages/exporter/pohistory/detail";

import ExporterDashboard from "../pages/web/pages/exporter/homepage/homepage";

import ComingSoon from "../pages/web/common/comingsoon";


import WebExporterPaymentList from "../pages/web/pages/exporter/payment/list";
import WebExporterPaymentDetail from "../pages/web/pages/exporter/payment/detail";


import WebExporterInquiryList from "../pages/web/pages/exporter/inquiries/list";
import WebExporterInquiryDetail from "../pages/web/pages/exporter/inquiries/detail";


import WebExporterSourceFinanceList from "../pages/web/pages/exporter/sourceFinance/list";
import WebExporterSourceFinanceDetail from "../pages/web/pages/exporter/sourceFinance/detail";

import WebExporterFactoringFinanceList from "../pages/web/pages/exporter/factoringFinance/list";
import WebExporterFactoringFinanceDetail from "../pages/web/pages/exporter/factoringFinance/detail";




import WebExporterQuotationsList from "../pages/web/pages/exporter/quotations/list";
import WebExporterQuotationsDetail from "../pages/web/pages/exporter/quotations/detail";


import WebSupplierPaymentList from "../pages/web/pages/supplier/payment/list";
import WebSupplierPaymentDetail from "../pages/web/pages/supplier/payment/detail";


import WebExporterInvoiceHistoryList from "../pages/web/pages/exporter/invoiceHistory/list";
import WebExporterInvoiceHistoryDetail from "../pages/web/pages/exporter/invoiceHistory/detail";
import UserFreightForwarderList from "../pages/web/pages/exporter/freightforwarder/list";
import UserFreightForwarderDetail from "../pages/web/pages/exporter/freightforwarder/detail";
import UserOtherFreightForwarderList from "../pages/web/pages/freightForwarder/otherFreightListing/list";
import UserOtherFreightForwarderDetail from "../pages/web/pages/freightForwarder/otherFreightListing/detail";

import UserInquiryFreightForwarderList from "../pages/web/pages/freightForwarder/inquiry/list";
import UserInquiryFreightForwarderDetail from "../pages/web/pages/freightForwarder/inquiry/detail";



import FreightForwarderList from "../pages/web/pages/freightForwarder/listing/list";
import FreightForwarderDetail from "../pages/web/pages/freightForwarder/listing/detail";
import FreightManageOrderList from "../pages/web/pages/freightForwarder/manageorder/list";
import FreightManageOrderDetail from "../pages/web/pages/freightForwarder/manageorder/detail";
import ExporterManageOrderList from "../pages/web/pages/exporter/manageorder/list";
import ExporterManageOrderDetail from "../pages/web/pages/exporter/manageorder/detail";
import WebExporterSupplierDirectoryList from "../pages/web/pages/exporter/supplierdiretory/list";
import WebExporterImporterDirectoryList from "../pages/web/pages/exporter/importerDiscovery/list";
import HtmlViewer from "../pages/web/common/htmlView";
import OtpVerificationScreen from "../pages/web/authentication/OtpVerifiy";
import UserProfle from "../pages/web/pages/exporter/user/profile";
import UserDetail from "../pages/web/pages/exporter/user/detail";
import HomePage from "../pages/web/pages/homepage/homepage";
import InvoiceHistory from "../pages/web/pages/supplier/invoicehistory/list";
import WebInvoiceHistoryDetail from "../pages/web/pages/supplier/invoicehistory/detail";
import PurchaseOrder from "../pages/web/pages/supplier/purchaseorder/list";

 
import WebImporterSupplierDirectoryList from "../pages/web/pages/importer/importetDirectory/list";
import WebImporterOceanFreightDirectoryList from "../pages/web/pages/importer/freightforwarder/list";
import WebImporterNewBiddingList from "../pages/web/pages/importer/newBid/list";
import WebImporterBiddingList from "../pages/web/pages/importer/impoterbidding/list";
import WebImporterBiddingOrdersList from "../pages/web/pages/importer/impoterbiddingorders/list";
import WebExporterBiddingDetail from "../pages/web/pages/exporter/exporterBiddingOrder/list";
import WebExporterBiddingList from "../pages/web/pages/exporter/exporterBidding/list";
import Blr from "../pages/web/pages/exporter/manageorder/shippingIns";

const adminAuthRoutes = [
    // MarketPlace Routes
    { path: "/marketplace/offer/list", component: MarketPlaceOfferList },
    { path: "/marketplace/offer/detail", component: MarketPlaceOfferDetail },
    { path: "/marketplace/order/list", component: MarketPlaceOrderList },
    { path: "/marketplace/order/detail", component: MarketPlaceOrderDetail },
    { path: "/marketplace/export/list", component: MarketPlaceExporterOrderList },
    { path: "/marketplace/export/detail", component: MarketPlaceExporterOrderDetail },
    { path: "/marketplace/freight_forwarder/list", component: MarketPlaceFreightList },
    { path: "/marketplace/freight_forwarder/detail", component: MarketPlaceFreightDetail },
    { path: "/marketplace/freight_order/list", component: MarketPlaceFreighOrdertList },
    { path: "/marketplace/freight_order/detail", component: MarketPlaceFreightOrderDetail },
    { path: "/marketplace/shipping_line/list", component: MarketPlaceShippingtList },
    { path: "/marketplace/shipping_line/detail", component: MarketPlaceShippingDetail },
    { path: "/marketplace/orderpayment/list", component: OrderPaymentDetailsList },
    { path: "/marketplace/payment/list", component: MarketPlacePaymentList },
    { path: "/marketplace/payment/detail", component: MarketPlacePaymentDetail },
    { path: "/marketplace/source_finance/list", component: MarketPlaceSourceFinance },
    { path: "/marketplace/factoring_finance/list", component: MarketPlaceFactoringFinance },
    { path: "/marketplace/dashboard", component: MarketPlaceDashboard },
    { path: "/marketplace", component: MarketPlaceDashboard },

    // Trade Routes
    { path: "/trade/customer/detail", component: TradeCustomerDetail },
    { path: "/trade/customer/list", component: TradeCustomerList },
    { path: "/trade/location/list", component: TradeLocationList },
    { path: "/trade/location/detail", component: TradeLocationDetail },
    { path: "/trade/vehicle/detail", component: TradeVehicleDetail },
    { path: "/trade/vehicle/list", component: TradeVehicleList },
    { path: "/trade/jobcard/list", component: TradeJobCardList },
    { path: "/trade/jobcard/detail", component: TradeJobCardDetail },
    { path: "/trade/dashboard", component: TradeDashboard },
    { path: "/trade", component: TradeDashboard },

    // Market Intelligence Routes
    { path: "/marketintelligence/stock/list", component: MarketIntelligenceStockList },
    { path: "/marketintelligence/stock/detail", component: MarketIntelligenceStockDetail },
    { path: "/marketintelligence/dataCenter/list", component: MarketIntelligenceDataCenterList },
    { path: "/marketintelligence/dataCenter/detail", component: MarketIntelligenceDataCenterDetail },
    { path: "/marketintelligence/rate/list", component: MarketIntelligenceDataCenterList },
    { path: "/marketintelligence/rate/detail", component: MarketIntelligenceRateDetail },

    
    { path: "/marketintelligence/dashboard", component: MarketIntelligenceDashboard },
    { path: "/marketintelligence", component: MarketIntelligenceDashboard },

    // Master Data Routes
    { path: "/masterdata/commodity/list", component: MasterDataCommodityList },
    { path: "/masterdata/commodity/detail", component: MasterDataCommodityDetail },
    { path: "/masterdata/commodity_variety/list", component: MasterDataCommodityVarietyList },
    { path: "/masterdata/commodity_variety/detail", component: MasterDataCommodityVarietyDetail },
    { path: "/masterdata/parameter/list", component: MasterDataParameterList },
    { path: "/masterdata/parameter/detail", component: MasterDataParameterDetail },
    { path: "/masterdata/catalogue/list", component: MasterDataCatalogueList },
    { path: "/masterdata/catalogue/detail", component: MasterDataCatalogueDetail },
    { path: "/masterdata/commission/list", component: MasterDataCommissionList },
    { path: "/masterdata/commission/detail", component: MasterDataCommissionDetail },
    { path: "/masterdata/dashboard", component: MasterDataDashboard },
    { path: "/masterdata", component: MasterDataDashboard },

    // Management Routes
    { path: "/management/nbfc/list", component: ManagementNbfcList },
    { path: "/management/nbfc_partners/detail", component: ManagementNbfcDetail },
    { path: "/management/activity/list", component: ManagementActivityList },
    { path: "/management/contact/list", component: ManagementContactList },
    { path: "/management/contact/detail", component: ManagementContactDetail },
    { path: "/management/user/list", component: ManagementUserList },
    { path: "/management/user/detail", component: ManagementUserDetail },

    { path: "/management/user_organization/list", component: ManagementUserOrganizationList },
    { path: "/management/user_organization/detail", component: ManagementUserOrganizationDetail },


    
    { path: "/management/inquiries/list", component: ManagementInquiriesList },
    { path: "/management/inquiries/detail", component: ManagementInquiriesDetail },

    { path: "/management/quotations/list", component: ManagementQuotationsList },
    { path: "/management/quotations/detail", component: ManagementQuotationsDetail },


    
    { path: "/management/exporter_payment/list", component: ManagementPaymentList },
    { path: "/management/exporter_payment/detail", component: ManagementPaymentDetail },
    { path: "/management/dashboard", component: ManagementDashboard },    
    { path: "/management/bidding_offer/list", component: MarketPlaceBiddingOfferList },
    { path: "/management/bidding_offer_/detail", component: MarketPlaceBiddingOfferDetail },

    
    { path: "/management/bidding_order/list", component: MarketPlaceBiddingOrderList },

    { path: "/management", component: ManagementDashboard },
    { path: "/adminDashboard", component: AdminDashboard },
    { path: "*", component: () => <Redirect to="/adminDashboard" /> },
];

const adminNonAuthRoutes = [
    { path: "/", exact: true, component: () => <Redirect to="/homePage" /> },
    { path: "/logout", exact: true, component: Logout },
    { path: "/auth/login", exact: true, component: Login },
    { path: "/auth/forgot-password", exact: true, component: ForgetPwd },
    { path: "/auth/redirect", exact: true, component: AuthRedirect },
    { path: "/auth/reset", exact: true, component: PasswordReset },
    { path: "/homePage", component: HomePage },
    { path: "/user/auth/signup", exact: true, component: UserLogin },
    { path: "/user/auth/login", exact: true, component: OtpScreen },
    { path: "/user/auth/otpVerification", exact: true, component: OtpVerificationScreen },
    // { path: "*", exact: true, component: () => <Redirect to="/homePage" /> },


];
 
const userNonAuthRoutes = [
  
    { path: "/web/supplierDirectory/list",   component: WebExporterSupplierDirectoryList },
    { path: "/web/exporterImporterDirectory/list",   component: WebExporterImporterDirectoryList },
    { path: "/web/InvoiceHistory/list",   component: InvoiceHistory },
    { path: "/web/supplier/list",   component: PurchaseOrder },
    { path: "/web/view",   component: HtmlViewer },
    { path: "/web/exporter/list",   component: WebExporterList },
    { path: "/web/exporter/detail",   component: WebExporterDetail },

    { path: "/web/source_finance/list",   component: WebExporterSourceFinanceList },
    { path: "/web/source_finance/detail",   component: WebExporterSourceFinanceDetail },

    { path: "/web/factoring_finance/list",   component: WebExporterFactoringFinanceList },
    { path: "/web/factoring_finance/detail",   component: WebExporterFactoringFinanceDetail },



    { path: "/web/exporter_payment/list",   component: WebExporterPaymentList },
    { path: "/web/exporter_payment/detail",   component: WebExporterPaymentDetail },
    { path: "/web/supplier_payment/list",   component: WebSupplierPaymentList },
    { path: "/web/supplier_payment/detail",   component: WebSupplierPaymentDetail },
   
    { path: "/web/inquiries/list",   component: WebExporterInquiryList },
    { path: "/web/inquiries/detail",   component: WebExporterInquiryDetail },

    { path: "/web/quotations/list",   component: WebExporterQuotationsList },
    { path: "/web/quotations/detail",   component: WebExporterQuotationsDetail },

    
    { path: "/web/exporterDashboard/list",   component: ExporterDashboard },


    { path: "/web/exporterInvoiceHistory/list",   component: WebExporterInvoiceHistoryList },
    { path: "/web/exporterInvoiceHistory/detail",   component: WebExporterInvoiceHistoryDetail },

    // { path: "/web/exp_blr/list",   component: WebExporterInvoiceHistoryList },
    { path: "/web/exp_blr/detail",   component: Blr },

    { path: "/web/user_freight_forwarder/list",   component: UserFreightForwarderList },
    { path: "/web/user_freight_forwarder/detail",   component: UserFreightForwarderDetail },

    { path: "/web/other_freight_listing/list",   component: UserOtherFreightForwarderList },
    { path: "/web/other_freight_listing/detail",   component: UserOtherFreightForwarderDetail },

    { path: "/web/inquiry/list",   component: UserInquiryFreightForwarderList },
    { path: "/web/inquiry/detail",   component: UserInquiryFreightForwarderDetail },


    { path: "/web/freight_forwarder/list",   component:  FreightForwarderList },
    { path: "/web/freight_forwarder/detail",   component:  FreightForwarderDetail },
    { path: "/web/manage_order/list",   component: FreightManageOrderList },
    { path: "/web/manage_order/detail",   component: FreightManageOrderDetail },
    { path: "/web/user_manage_order/list",   component: ExporterManageOrderList },
    { path: "/web/user_manage_order/detail",   component: ExporterManageOrderDetail }, 
    { path: "/web/invoicehistory/detail",   component: WebInvoiceHistoryDetail },
    { path: "/web/user/list",   component: UserProfle },
    { path: "/web/user/detail",   component: UserDetail },

    { path: "/web/importer/list",   component: WebImporterSupplierDirectoryList },
    { path: "/web/OceanFreightImp/list",   component: WebImporterOceanFreightDirectoryList },
    { path: "/web/imp_new_bidding/list",   component: WebImporterNewBiddingList },
    { path: "/web/imp_bidding/list",   component: WebImporterBiddingList },
    { path: "/web/imp_bidding_order/list",   component: WebImporterBiddingOrdersList },
    
    { path: "/web/exp_bidding/list",   component: WebExporterBiddingList },
    { path: "/web/exp_bidding_order/list",   component: WebExporterBiddingDetail },

    
    // { path: "/web/ocean_freight_imp/list",   component: WebImporterOceanFreightDirectoryList },


    { path: "/web/coming_soon1/list",   component: ComingSoon },
    { path: "/web/coming_soon2/list",   component: ComingSoon },
    { path: "/web/coming_soon3/list",   component: ComingSoon },
    { path: "/web/coming_soon4/list",   component: ComingSoon },
    { path: "/web/coming_soon5/list",   component: ComingSoon },




    
   
];

export { adminAuthRoutes, adminNonAuthRoutes , userNonAuthRoutes};
