import {React} from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';  


export const HtmlToExcel = ({ headers=[] ,Title="", key={}, FileName="Oc",Sheet="MandiRates"}) =>{
  return (
    // <div className="App">
      <>
        <table id={FileName} class="table" style={{ display:"none",  }}>  
              <thead style={{visibility:"hidden", height:"0px"}}>  
                      <tr> { headers?.map((e,i)=><td key={i}>{e}</td>) } </tr>  
              </thead>  
        </table>  

        <div >  
                <ReactHTMLTableToExcel className={"btn btn-sm btn-primary m-1"} table={FileName} filename={Title} sheet={Sheet} buttonText="TEMPLATE" />  
        </div>
    </>
  );
}

 
