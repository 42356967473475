import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {
    BOOLEAN_LIST,
    ENUM_LIST,
    ENUM_STATUS,
    getCalculatedRows,
    getJobCardFields,
    getLabel,
    getLoadingFields,
    getOfferFields,
    getOrderFields,
    getOrderPriceFields,
    getPaymentFields,
    getUnLoadingFields,
    getWeighingFields,
    InvoiceButton,
    UI_DATETIME_FORMAT,
    UI_DATE_FORMAT,
} from "../../helpers";
import { DataForm } from "./DataForm";
import { ImageModal } from "./ImageModal";

export const CardInfo = ({
    entity = "user",
    object = {},
    columns = [],
    title_field = "name",
    disableEdit = false,
    refetch = () => {},
    solution,
}) => {
    const new_value = object?.new_value ?? {};
      console.log("new values", object, "->",new_value)

      

    if (entity === "change_history")
        return (
            <div key={Math.random()} className="card mb-1">
                <div className="card-header p-2 pb-0">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <div className="small text-muted text-uppercase">{getLabel("history")}</div>
                            <div className="float-left h5">{moment(object?.created_at).format(UI_DATETIME_FORMAT)}</div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            {ENUM_STATUS.map((e, i) =>
                                new_value?.[e]?.comment ? (
                                    <div key={i} className="p-1">
                                        <div className="small text-muted text-uppercase">
                                            {getLabel(e.replace("enum_", "").replace(entity + "_", ""))}
                                        </div>
                                        <div className="float-left h5">{new_value?.[e]?.comment}</div>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                        </div>
                        <div className="col-md-4 col-sm-12"></div>
                    </div>
                </div>

                <div className="card-body p-2">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 ">
                            <div className=" p-2">
                                {ENUM_LIST.map((e, i) =>
                                    new_value?.[e]?.comment ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e.replace("enum_", "").replace(entity + "_", ""))}</span>
                                            <span className="float-end h6">{new_value?.[e]?.comment}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["email", "phone", "whatsapp", "year_of_establishment","bookingno"].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["commission_percent"].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]} %</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {/* {["catalogue_commission"].map((e, i) =>{
								  console.log("details are the",e,new_value?.[e]);
								return new_value?.[e] ? (
										<div key={i} className="p-1">
											<span className="text-muted">{getLabel(e)}</span>
											<span className="float-end h6">{new_value?.["catalogue_commission"]["commission"]} %</span>
										</div>
									) : (
										<span key={Math.random()} />
									)
								})} */}
                                {
                                    // console.log("details are ",new_value)
                                }
                                {["catalogue_commission"].map((e, i) => {
                                    // console.log("details are ", e);

                                    return new_value?.[e]?.commission ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]?.commission}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    );
                                })}

                                {["payment_days"].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]} Days</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}

                                {["shape", "size", "color"].map((e, i) =>
                                    new_value?.[e]?.name ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]?.name}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {[
                                    "delivery_date",
                                    "date",
                                    "loading_date",
                                    "unloading_date",
                                    "start_date",
                                    "end_date",
                                    "requested_date",
                                    "available_date",
                                    "payment_due_date",
                                ].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{moment(new_value?.[e]).format(UI_DATE_FORMAT)}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["timestamp"].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{moment(new_value?.[e]).format(UI_DATETIME_FORMAT)}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}

                                {["address"].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <div className="h6">{new_value?.[e]}</div>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["origin", "destination", "location", "pickup_location", "delivery_location"].map((e, i) =>
                                    new_value?.[e]?.address ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">
                                                {getLabel(e)} [ {object?.[e]?.enum_location_type?.comment} ]
                                            </span>
                                            <div className="float-left h6">
                                                {object?.[e]?.name}, {object?.[e]?.address}
                                            </div>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["invoice_no", "payment_gateway", "payment_type", "pg_order_id", "pg_payment_id"].map((e, i) =>
                                    new_value?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            {["vehicle"].map((e, i) =>
                                new_value?.[e] ? (
                                    <div key={i} className="p-2">
                                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e).toUppercase()}</div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{new_value?.[e]}</span>
                                        </div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel("name")}</span>
                                            <span className="float-end h6">{new_value?.[e]?.name}</span>
                                        </div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel("capacity")}</span>
                                            <span className="float-end h6">
                                                {new_value?.[e]?.capacity} {new_value?.[e]?.enum_weight_unit?.comment}
                                            </span>
                                        </div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel("owner")}</span>
                                            <span className="float-end h6">{new_value?.[e]?.owner?.name}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                            {["owner", "driver", "customer", "user", "supplier", "seller", "buyer", "contact"].map((e, i) =>
                                new_value?.[e] ? (
                                    <div key={i} className="p-2">
                                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">
                                            {new_value?.[e]?.enum_customer_type?.comment ?? getLabel(e)}
                                        </div>
                                        {["name", "email", "phone", "whatsapp"].map((x, index) =>
                                            new_value?.[e]?.[x] ? (
                                                <div key={index} className="p-1">
                                                    <span className="text-muted">{getLabel(x)}</span>
                                                    <span className="float-end h6">{new_value?.[e]?.[x]}</span>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        )}
                                        {["commission_percent"].map((x, index) =>
                                            new_value?.[e]?.[x] ? (
                                                <div key={index} className="p-1">
                                                    <span className="text-muted">{getLabel(x)}</span>
                                                    <span className="float-end h6">{new_value?.[e]?.[x]} %</span>
                                                </div>
                                            ) : (
                                                <span key={Math.random()} />
                                            )
                                        )}
                                        {["payment_days"].map((x, index) =>
                                            new_value?.[e]?.[x] ? (
                                                <div key={index} className="p-1">
                                                    <span className="text-muted">{getLabel(x)}</span>
                                                    <span className="float-end h6">{new_value?.[e]?.[x]} Days</span>
                                                </div>
                                            ) : (
                                                <span key={Math.random()} />
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}

                            {["url", "photo_url", "document_url", "website_url", "desc", "summary"].map((e, i) =>
                                new_value?.[e] ? (
                                    <div key={i} className="p-1">
                                        <span className="text-muted">{getLabel(e)}</span>
                                        <span className="float-end h6">{new_value?.[e]}</span>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}

                            {["images", "videos", "documents"].map((e, i) => {
                                // console.log("values are", new_value);
                                return !_.isEmpty(new_value?.[e]) && _.isArray(new_value?.[e]) ? (
                                    <div key={i} className="p-2">
                                        <div className="text-muted">{getLabel(e, true)}</div>
                                        <div className="h6">
                                            <ImageModal
                                                key={new_value?.id}
                                                imageHeight={80}
                                                url={new_value?.[e]}
                                                title={_.get(new_value, title_field)}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                );
                            })}
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="border">
                                {["offer"].includes(entity) ? getCalculatedRows(new_value, getOfferFields()) : ""}
                                {["order"].includes(entity) ? getCalculatedRows(new_value, getOrderPriceFields()) : ""}
                                {["order"].includes(entity) ? getCalculatedRows(new_value, getOrderFields()) : ""}
                                {["order_payment", "jobcard_payment"].includes(entity) ? getCalculatedRows(new_value, getPaymentFields()) : ""}
                                {["jobcard_loading", "jobcard_unloading"].includes(entity)
                                    ? getCalculatedRows(new_value, getLoadingFields())
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    else if (entity.includes("contact"))
        return (
            <div key={Math.random()} className="card">
                <div className="card-header p-2 pb-0">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 d-flex justify-content-between">
                            <div>
                                <div className="small text-muted text-uppercase">{getLabel("contact")}</div>
                                <div className="float-left h5">
                                    <Link to={`/management/contact/detail/${object?.contact?.id}`} className="text-info float-left">
                                        {object?.contact?.name}
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <div className="small text-muted text-uppercase">{getLabel("type")}</div>
                                <div className="float-left h5">{object?.enum_contact_type?.comment}</div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <span className="float-end">
                                {!disableEdit && object?.active && (
                                    <DataForm
                                        key={entity + "edit"}
                                        entity={entity}
                                        columns={columns}
                                        defaultValues={object || {}}
                                        refetch={refetch}
                                    />
                                )}
                                {/* {object?.status === "completed" && ["order", "jobcard"].includes(entity) && (
                                    <div className="col-auto">
                                        <Invoice parent_id={object?.id} />
                                    </div>
                                )} */}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="card-body p-2">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 ">
                            <div className="text-muted">{getLabel("email")}</div>
                            <div className="h6">{object?.contact?.email}</div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="text-muted">{getLabel("phone")}</div>
                            <div className="h6">{object?.contact?.phone}</div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="text-muted">{getLabel("whatsapp")}</div>
                            <div className="h6">{object?.contact?.whatsapp}</div>
                        </div>
                    </div>
                </div>

                {/* <div className="card-footer p-2">
                <div className="row">
                    {object?.created_at && (
                        <div className="col-md-4 col-sm-12">
                            <div className="small text-muted text-uppercase">{getLabel("created_at")}</div>
                            <div className="float-left h6">{moment(object?.created_at).format(UI_DATETIME_FORMAT)}</div>
                        </div>
                    )}
                    {object?.updated_at && (
                        <div className="col-md-4 col-sm-12">
                            <div className="small text-muted text-uppercase">{getLabel("updated_at")}</div>
                            <div className="float-left h6">{moment(object?.updated_at).format(UI_DATETIME_FORMAT)}</div>
                        </div>
                    )}
                    {object?.timestamp && (
                        <div className="col-md-4 col-sm-12">
                            <div className="small text-muted text-uppercase">{getLabel("timestamp")}</div>
                            <div className="float-left h6">{moment(object?.timestamp).format(UI_DATETIME_FORMAT)}</div>
                        </div>
                    )}
                </div>
            </div> */}
            </div>
        );
    else
        return (
            <div key={Math.random()} className="card">
                <div className="card-header p-2">
                    <div className="row ">
                        <div className="col-sm-12 col-md-6 d-flex justify-content-between">
                            <div className="">
                                <div className="small text-muted text-uppercase">{getLabel(entity)}</div>
                                <div className="float-left h5">
                                    <Link to={`/${solution}/${entity}/detail/${object?.id}`} className="text-info float-left">
                                        {_.get(object, title_field) || (object?.user && object?.user?.name)}
                                    </Link>
                                </div>
                            </div>
                            <div className="">
                                {ENUM_STATUS.map((e, i) =>
                                    object?.[e]?.comment ? (
                                        <div key={i} className="p-1">
                                            <div className="small text-muted text-uppercase">
                                                {getLabel(e.replace("enum_", "").replace(entity + "_", ""))}
                                            </div>
                                            <div className="float-left h5">{object?.[e]?.comment}</div>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 d-flex justify-content-between">
                            {BOOLEAN_LIST.map((e, i) =>
                                _.isBoolean(object?.[e]) ? (
                                    <span key={i} className="p-1 my-auto">
                                        <div className="text-muted text-uppercase"></div>
                                        <div className="float-left h5">
                                            <p className="">
                                                {getLabel(e.replace("enum_", "").replace(entity + "_", "")).toUpperCase()}
                                                {" : "}
                                                {object?.[e] ? (
                                                    <i className="fa fa-check text-success m-1" />
                                                ) : (
                                                    <i className="fa fa-times text-danger m-1" />
                                                )}
                                            </p>
                                        </div>
                                    </span>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                            {/* <span className="float-end align-self-center"> */}
                            <span className="d-flex">
                                {!disableEdit && object?.active && (
                                    <DataForm
                                        key={entity + "edit"}
                                        entity={entity}
                                        columns={columns}
                                        defaultValues={object || {}}
                                        refetch={refetch}
                                    />
                                    )}
                                {object?.status === "scheduled" && ["jobcard_loading"].includes(entity) && (
                                    <div className="col-auto m-1">
                                        {/* <LoadingInvoice parent_id={object?.id} /> */}
                                        <InvoiceButton id={object?.id} type="loading" />
                                    </div>
                                )}
                                {["scheduled", "completed"].includes(object?.status) && ["jobcard_unloading"].includes(entity) && (
                                    <div className="col-auto m-1">
                                        {/* <UnloadingInvoice parent_id={object?.id} /> */}
                                        <InvoiceButton id={object?.id} type="unloading" />
                                    </div>
                                )}
                                {["order"].includes(entity) && (
                                    <div className="col-auto">
                                        <InvoiceButton title="PO" type="po" object={object}></InvoiceButton>

                                        {/* <InvoiceButton object={object}></InvoiceButton> */}
                                    </div>
                                )}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="card-body p-2">
                    <div className="row">
                        <div className="col-md-4 col-sm-12 ">
                            <div className=" p-2">
                                {ENUM_LIST.map((e, i) =>
                                    object?.[e]?.comment ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e.replace("enum_", "").replace(entity + "_", ""))}</span>
                                            <span className="float-end h6">{object?.[e]?.comment}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["email", "phone", "whatsapp", "year_of_establishment","bookingno",

                                "uid","vessel","weightinkgormt","seal_no","container_no",
                                "telno","totalnumberofcontainer",
                                "consigneenameandaddress", "emailaddress","freightcharges","marksandnumbers",
                                "measurement", "notifypartynameandaddress","numberandkindofpackagesdescriptionofgoods" ,
                                "numberoforiginals","placeofdelivery","placeofreceipt","portofdischarge","portofloading"
                                 ].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{object?.[e]}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["commission_percent"].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{object?.[e]} %</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["catalogue_commission"].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">
                                                {object?.[e]["commission"]} % ({object?.[e]["days"]} days){" "}
                                            </span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}

                                {["payment_days"].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{object?.[e]} Days</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["commodity_variety"].map((e, i) =>
                                    object?.[e]?.name ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e.replace("enum_", "").replace(entity + "_", ""))}</span>
                                            <span className="float-end h6">
                                                {object?.[e]?.name} ({object?.[e]?.commodity?.name})
                                            </span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["shape", "size", "color"].map((e, i) =>
                                    object?.[e]?.name ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{object?.[e]?.name}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {[
                                    "delivery_date",
                                    "date",
                                    "loading_date",
                                    "unloading_date",
                                    "start_date",
                                    "end_date",
                                    "requested_date",
                                    "available_date",
                                    "payment_due_date",
                                    "weighed_date",
                                ].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{moment(object?.[e]).format(UI_DATE_FORMAT)}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}
                                {["timestamp"].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <span className="float-end h6">{moment(object?.[e]).format(UI_DATETIME_FORMAT)}</span>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}

                                {["address"].map((e, i) =>
                                    object?.[e] ? (
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel(e)}</span>
                                            <div className="h6">{object?.[e]}</div>
                                        </div>
                                    ) : (
                                        <span key={Math.random()} />
                                    )
                                )}

                                {["invoice_no", "payment_gateway", "transaction_id", "payment_type", "pg_order_id", "pg_payment_id",
                                "remarks"].map(
                                    (e, i) =>
                                        object?.[e] ? (
                                            <div key={i} className="p-1">
                                                <span className="text-muted">{getLabel(e)}</span>
                                                <span className="float-end h6">{object?.[e]}</span>
                                            </div>
                                        ) : (
                                            <span key={Math.random()} />
                                        )
                                )}
                            </div>
                            {["origin", "destination", "location", "pickup_location", "delivery_location"].map((e, i) =>
                                object?.[e]?.address ? (
                                    <div key={i} className="p-2">
                                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">
                                            {getLabel(e)} [ {object?.[e]?.enum_location_type?.comment} ]
                                        </div>
                                        <div className="float-left h6 p-1">
                                            {object?.[e]?.name}, {object?.[e]?.address}
                                        </div>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                        </div>
                        <div className="col-md-4 col-sm-12">
                            {["vehicle"].map((e, i) =>
                                object?.[e] ? (
                                    <div key={i} className="p-2">
                                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e)}</div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel("name")}</span>
                                            <span className="float-end h6">{object?.[e]?.name}</span>
                                        </div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel("capacity")}</span>
                                            <span className="float-end h6">
                                                {object?.[e]?.capacity} {object?.[e]?.enum_weight_unit?.comment}
                                            </span>
                                        </div>
                                        <div key={i} className="p-1">
                                            <span className="text-muted">{getLabel("owner")}</span>
                                            <span className="float-end h6">{object?.[e]?.owner?.name}</span>
                                        </div>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}

                            {["owner", "driver", "customer", "user", "supplier", "seller", "buyer", "contact"].map((e, i) =>
                                object?.[e] ? (
                                    <div key={i} className="p-2">
                                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">
                                            {object?.[e]?.enum_customer_type?.comment ?? getLabel(e)}
                                        </div>
                                        {["name", "email", "phone", "whatsapp"].map((x, index) =>
                                            object?.[e]?.[x] ? (
                                                <div key={index} className="p-1">
                                                    <span className="text-muted">{getLabel(x)}</span>
                                                    <span className="float-end h6">{object?.[e]?.[x]}</span>
                                                </div>
                                            ) : (
                                                ""
                                            )
                                        )}
                                        {["commission_percent"].map((x, index) =>
                                            object?.[e]?.[x] ? (
                                                <div key={index} className="p-1">
                                                    <span className="text-muted">{getLabel(x)}</span>
                                                    <span className="float-end h6">{object?.[e]?.[x]} %</span>
                                                </div>
                                            ) : (
                                                <span key={Math.random()} />
                                            )
                                        )}

                                        {["payment_days"].map((x, index) =>
                                            object?.[e]?.[x] ? (
                                                <div key={index} className="p-1">
                                                    <span className="text-muted">{getLabel(x)}</span>
                                                    <span className="float-end h6">{object?.[e]?.[x]} Days</span>
                                                </div>
                                            ) : (
                                                <span key={Math.random()} />
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                            {["url", "photo_url", "document_url", "website_url", "desc", "summary","consigneenameandaddress","shippernameandaddress"].map((e, i) =>
                                object?.[e] ? (
                                    <div key={i} className="p-1">
                                        <span className="text-muted">{getLabel(e)}</span>
                                        <span className="float-end h6">{object?.[e]}</span>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                            {["images", "videos", "documents"].map((e, i) =>
                                !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                                    <div key={i} className="p-2">
                                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                                        <div className="h6">
                                            <ImageModal
                                                key={object?.id}
                                                imageHeight={80}
                                                url={object?.[e]}
                                                title={_.get(object, title_field)}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                        </div>

                        
{["adhaar_card_img", "pan_card_photo"].map((e, i) =>
                    !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                            <div className="h6">
                                <ImageModal key={object?.id} privateImage= {true}  type={e?.substring("img") ? "image" : "photo"} imageHeight={80} url={object?.[e]} title={_.get(object, title_field)} />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}

                
 {[  "documents","rent_aggrement_if_premise_are_rented","draft_bl"].map((e, i) =>
                    !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                            <div className="h6">
                                <ImageModal key={object?.id} privateImage= {true} type="document" imageHeight={80} url={object?.[e]} title={_.get(object, title_field)} />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}



                        <div className="col-md-4 col-sm-12">
                            <div className="">
                                {["offer"].includes(entity) ? getCalculatedRows(object, getOfferFields()) : ""}
                                {["order"].includes(entity)&& object?.["order_type"] !== 'on_commission' ? getCalculatedRows(object, getOrderPriceFields(), "Negotitation") : ""}
                                {["order"].includes(entity) ? getCalculatedRows(object, getOrderFields()) : ""}
                                {["order_payment", "jobcard_payment"].includes(entity) ? getCalculatedRows(object, getPaymentFields()) : ""}
                                {["jobcard"].includes(entity) ? getCalculatedRows(object, getJobCardFields()) : ""}
                                {["jobcard_loading"].includes(entity) ? getCalculatedRows(object, getLoadingFields()) : ""}
                                {["jobcard_unloading"].includes(entity) ? getCalculatedRows(object, getUnLoadingFields()) : ""}
                                {["jobcard_weighing"].includes(entity) ? getCalculatedRows(object, getWeighingFields()) : ""}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card-footer p-2">
                    <div className="row">
                        {object?.created_at && (
                            <div className="col-md-4 col-sm-12">
                                <div className="small text-muted text-uppercase">{getLabel("created_at")}</div>
                                <div className="float-left h6">{moment(object?.created_at).format(UI_DATETIME_FORMAT)}</div>
                            </div>
                        )}
                        {object?.updated_at && (
                            <div className="col-md-4 col-sm-12">
                                <div className="small text-muted text-uppercase">{getLabel("updated_at")}</div>
                                <div className="float-left h6">{moment(object?.updated_at).format(UI_DATETIME_FORMAT)}</div>
                            </div>
                        )}
                        {/* {object?.timestamp && (
                            <div className="col-md-4 col-sm-12">
                                <div className="small text-muted text-uppercase">{getLabel("timestamp")}</div>
                                <div className="float-left h6">{moment(object?.timestamp).format(UI_DATETIME_FORMAT)}</div>
                            </div>
                        )} */}
                    </div>
                </div>
            </div>
        );
};
