import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SidebarContent from "./SidebarContent";

const Sidebar = (props) => {
    return (
        <React.Fragment>
            {/* <div key="sidebar" className="vertical-menu" style={{ height: "100vh" }}>
                <div key="sidebar" data-simplebar className="h-100">
                    <SidebarContent key="sidebar" {...props} />
                </div>
            </div> */}
            <div key="sidebar" className="">
                <SidebarContent key="sidebar" {...props} />
            </div>
        </React.Fragment>
    );
};

Sidebar.propTypes = {
    type: PropTypes.string,
};

const mapStatetoProps = (state) => {
    return {
        layout: state.Layout,
    };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
