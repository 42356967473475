import React from "react";
import { AuthContext, AuthProvider } from "./auth";
import { GeneralContext, GeneralProvider } from "./general";

const GlobalProvider = ({ children }) => {
    return (
        <AuthProvider>
            <GeneralProvider>{children}</GeneralProvider>
        </AuthProvider>
    );
};

export { AuthContext, GeneralContext };

export default GlobalProvider;
