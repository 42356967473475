import React from "react";
import Carousal from "./carousal";

const Dashboard = () =>  {
  return (
<div class="container-fluid page-content mt-4" >
  <div class="row justify-content-center">
    <div class="col-sm">

    <Carousal/>


    </div>
   
  </div>
  <div class="row justify-content-center ">
    <div class="col-sm-5 bg-info m-2 p-5 text-center">
    Invoice Outstanding
    </div>
    <div class="col-sm-5 bg-primary  m-2 p-5 text-center">
    Trending Rate
    </div>
    
  </div>

  <div class="row justify-content-center ">
    <div class="col-sm-5 bg-info m-2 p-5 text-center">
     Looking for capital, Click here to get instant loan approval
    </div>
    <div class="col-sm-5 bg-info m-2 p-5 text-center">
     Favourite Searches
    </div>
    
  </div>



  <div class="row justify-content-md-center m-2 ">
    <div class="col-sm center-block text-center p-5 bg-info">
    Trending rates
    </div>
 
    
  </div>
  <div class="row justify-content-md-center m-2 ">
 
    <div  class="center-block text-center p-5 bg-info">
    Quotations
    </div>
    
  </div>

</div>
   
  
  );
}

export default Dashboard;
