import PropTypes from "prop-types";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { TiArrowBack } from "react-icons/ti";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import logoDark from "../../assets/images/logo-dark.png";
import logoLightPng from "../../assets/images/logo-light.png";
// import logoLightSvg from "../../assets/images/logo-light.svg";
// import logo from "../../assets/images/logo-sm-light.png";
import { default as logo, default as logoLightSvg } from "../../assets/images/oclogo/name.png";

import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from "../../store/actions";
import ProfileMenu from "../commonForBoth/TopbarDropdown/ProfileMenu";

const Header = (props) => {
    const [search, setsearch] = useState(false);
    // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const dispatch = useDispatch();

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        // if (props.leftSideBarType === "default") {
        //     props.changeSidebarType("condensed", isMobile);
        // } else if (props.leftSideBarType === "condensed") {
        //     props.changeSidebarType("default", isMobile);
        // }
    }
    const handleBackButton = () => {
        window.history.back();
        window.navigator.vibrate(30);
    };
    return (
        <React.Fragment>
            <header id="page-topbar" style={{ overflow: "hidden", backgroundColor: "white" }}>
                <div className="navbar-header">
                    <div className="d-flex">
                        <button
                            type="button"
                            onClick={() => {
                                dispatch({ type: "CHANGE_LAYOUT", payload: "horizontal" });
                                //props.toggleMenuCallback();
                                tToggle();
                            }}
                            className="btn shadow font-size-16 d-lg-none"
                            id=""
                            // style={{position:"fixed",bottom:10,right:10, borderRadius:"100%", width:50, height:50}}
                        >
                            <i className="fa fa-fw fa-bars m-auto text-light" />
                        </button>

                        <div className="navbar-brand-box" style={{ background: "white" }}>
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="40" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="40" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoLightSvg} alt="" height="40" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLightPng} alt="" height="40" />
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={handleBackButton}
                            className="btn shadow btn-primary font-size-16 sidenav-toggle d-lg-none p-2"
                            id=""
                            style={{ position: "fixed", bottom: 10, right: 10, borderRadius: "100%", width: 50, height: 50 }}
                        >
                            <TiArrowBack size={30} className="m-auto" />
                        </button>
                    </div>
                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ms-2">
                            <button
                                onClick={() => {
                                    setsearch(!search);
                                }}
                                type="button"
                                className="btn header-item noti-icon waves-effect"
                                id="page-header-search-dropdown"
                            >
                                <i className="mdi mdi-magnify" />
                            </button>
                            <div
                                className={
                                    search
                                        ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                                        : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                                }
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit">
                                                    <i className="mdi mdi-magnify" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="dropdown d-none d-lg-inline-block ms-1">
                            <button
                                type="button"
                                style={{ color: "black" }}
                                onClick={() => {
                                    toggleFullscreen();
                                }}
                                className="btn header-item noti-icon waves-effect"
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen" />
                            </button>
                        </div>

                        {/* <NotificationDropdown /> */}
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})(withTranslation()(Header));
