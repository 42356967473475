import React from "react";

const Footer = () => {
    return (
        <React.Fragment>
            {/* <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={6}>{new Date().getFullYear()} © OriginKonnect.</Col>
                        <Col md={6}>
                            <div className="text-sm-right d-none d-sm-block"></div>
                        </Col>
                    </Row>
                </Container>
            </footer> */}
        </React.Fragment>
    );
};

export default Footer;
