import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "reactstrap";
import { getMenuItems } from "../../helpers";
import { toggleLeftmenu } from "../../store/actions";
import { AuthContext } from "../../context";

const Navbar = (props) => {
    const menu_items = getMenuItems();

    const { role } = useContext(AuthContext);

    // console.log("role is",role)

    // props.toggleLeftmenu()
    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                        <Collapse isOpen={false} className="navbar-collapse collapse" id="topnav-menu-content">
                            <ul
                                className="navbar-nav"
                                onClick={(e) => menu_items.includes(e?.target?.getAttribute("value")) && props.toggleLeftmenu()}
                            >
                                {menu_items?.map((m, i) => (
                                    <li key={i} className="nav-item dropdown">
                                        {m?.children?.length > 0 && m?.role===role ? (
                                            <>
                                                <div
                                                    key={i}
                                                    className={
                                                        "pl-3 nav-link dropdown-toggle" +
                                                        (m?.link
                                                            ? props?.location?.pathname?.startsWith(`/${m?.link}`)
                                                                ? " active "
                                                                : ""
                                                            : "")
                                                    }
                                                > 
                                                    <span style={{ fontSize: "1.2rem" }}> {m?.icon ?? ""} </span>
                                                    {m?.title ?? ""}
                                                </div>
                                                <div className="dropdown-menu" style={{ pointerEvents: "auto" }}>
                                                    {m?.children?.map((sm, si) => (
                                                        <Link
                                                            key={si}
                                                            value={sm?.title}
                                                            to={`/${m?.link}/${sm?.link}${sm?.detail ? "/detail" : "/list"}`}
                                                            className={
                                                                "nav-link dropdown-toggle arrow-none " +
                                                                (sm?.link
                                                                    ? props?.location?.pathname?.startsWith(
                                                                          `/${m?.link}/${sm?.link}${sm?.detail ? "/detail" : "/list"}`
                                                                      )
                                                                        ? " active "
                                                                        : ""
                                                                    : "")
                                                            }
                                                            onClick={() => props.toggleLeftmenu()}
                                                        >
                                                            <span>
                                                                {sm?.icon ?? ""} {sm?.title ?? ""}{" "}
                                                            </span>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </>
                                        ) : m?.link && m?.role===role ? (
                                            <Link
                                                key={i}
                                                className={
                                                    "pl-3 nav-link" +
                                                    (m?.link ? (props?.location?.pathname?.startsWith(`/${m?.link}`) ? " active " : "") : "")
                                                }
                                                to={`/${m?.link}`}
                                            >
                                                <span style={{ fontSize: "1.2rem" }}> {m?.icon ?? ""} </span>
                                                {m?.title ?? ""}
                                            </Link>
                                        ) : (
                                            ""
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { leftMenu } = state.Layout;
    return { leftMenu };
};

export default withRouter(connect(mapStatetoProps, { toggleLeftmenu })(withTranslation()(Navbar)));
