import React from "react";
import { useFieldArray } from "react-hook-form";

const FieldArray = ({control ,  field={}, register=()=>{},state}) =>{

    const { fields,remove, append } = useFieldArray({ control, name:   "containersDetails" })
 console.log(
    "fields are ",fields, state
 )
 

    return    <>
            {fields?.map((item, index) => {

              return [...Object.keys(item)?.map((ee,s)=>{

                 if(ee != "id") {
                        return <div class="row  ">
                                <label for="one" class="col-sm-2 col-form-label h6 font-weight-normal   small  text-primary">{`${ee}` }</label>
                                <div class="col-sm-10">
                                    <input
                                    className="m-1  p-1    form-control  "
                                    id="one"
                                    name={`${field.multiName}[${index}].${ee}`}
                                    defaultValue={null} // make sure to set up defaultValue
                                    ref={register()}
                                        />
                                </div>
                            
                        </div>
                }

            }),
        
            <button className="btn btn-sm btn-primary m-1" type="button" onClick={() => {
                console.log("inde value is", index)
                remove(index)}}>
                                                                                Delete
                                                                            </button>]
        
 
         
                })
             }
                  <button className="btn btn-sm btn-primary m-1"
                                type="button"
                                onClick={() => {
                                    append({"container":0,"seal":0} );
                                    // append({} );
                                }}
                            >
                            Add
                            </button>
        </>
      

};

export default FieldArray;