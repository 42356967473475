import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { PageContainer } from "../../../components/common/PageContainer";
import { defaultColumn, ENUM_LIST, getLabel, getParams } from "../../../helpers";

const List = () => {
    const { arg_1: entity, arg_2: page } = getParams();

    const enum_fields = {
        fields: ` value comment `,
        sortBy: [{ id: "comment", desc: false }],
        columns: [
            defaultColumn({
                accessor: "value",
                rules: {
                    required: "Required !",
                    pattern: {
                        value: /^[a-zA-Z][a-z0-9A-Z]*$/,
                        message: "UpperCase and Numbers Only; Should start with Alphabets",
                        // message: "Lowercase and Numbers Only; Should start with Alphabets",
                    },
                },
            }),
            defaultColumn({ accessor: "comment" }),
        ],
        disableExpansion: true,
        select_field: "value",
        enum_table: true,
        disableAdd: false,
        disableEdit: true,
        disableAction: false,
    };

    if (page !== "list") return <span key={Math.random()} />;
    return (
        <PageContainer
            detail={true}
            breadcrumbTitle={getLabel(entity, true)}    
            tab_arg={3}
            tab_options={ENUM_LIST.map((e) => ({
                label: getLabel(e),
                value: e,
                component: DataTable,
                ...enum_fields,
            }))}
        />
    );
};

export default List;
