import React  from "react"; 
 
import { SimpleTable } from "../../../../../components/common/SimpleTable";
import { ENUM_ACTIVE } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import ListRowChild from "./new_rowChild";


const List =   (props) => {

	console.log("PROPSa",props)

 
   let entity_fields = useWebEntityFields("freight_forwarder_row_child");

	 
	 

	return (
  
		 <ListRowChild obj={props}/>

		// <SimpleTable
	// 	// {...rest}
	// 	// setLimit={ }
	// 	// setOffset={ }
	// 	// limit={ }
	// 	// offset={ }
	// 	// pages={ }
	// 	// page={ }
	// 	// total={ }
	// 	// hideTableHeaders={queryObjects?.length === 0}
	// 	// key={entity + 'table'}
	// 	// entity={entity}
		// disableAction={true}
		// disableEdit={true}
		// columns={entity_fields?.columns}
		// data={[props?.object]}
	// 	// sortBy={sortBy}
	// 	// refetch={queryRefetch}
	//   />
//  <h1> Hello</h1>
	  

		// <PageContainer
		// 	detail={false}
		// 	// breadcrumbTitle="Orders"
		// 	// tab_arg={2}
		// 	tab_options={
		// 		[ {value: 'all', comment: 'All'}].map((eps) => ({
		// 		...entity_fields,
		// 		...override_fields,
		// 		label: eps?.comment,
		// 		value: eps?.value,
		// 		component: DataTable,
		// 		filters: { ...(entity_fields?.filters || {}), ...(override_fields?.filters || {}), order_status: { _eq: eps.value } },
		// 	})
		// 	)}


		// />
	);
};
export default List;
