import _ from "lodash";
import React, { useContext, useState } from "react";
import { ChartCards } from "../../../components/common/ChartsandGraphs";
import { FilterForm } from "../../../components/common/FilterForm";
import { MetricCards } from "../../../components/common/MetricCards";
import { GeneralContext } from "../../../context";
import { getDateWhere, getLabel, useCommonSubscription, useTotalSubscription } from "../../../helpers";

const Detail = () => {
    const { enum_user_role } = useContext(GeneralContext);
    const [days_to_show] = useState(30);
    const [inner_filters, set_inner_filters] = useState({});

    const metric_user_role = useCommonSubscription({ entity: "metric_user_role" });
    const metric_location_type = useCommonSubscription({ entity: "metric_location_type" });
    const metric_user_role_created = useCommonSubscription({
        entity: "metric_user_role_created",
        where: { ...getDateWhere(days_to_show), ...inner_filters },
    });
    const metric_user_created = useCommonSubscription({
        entity: "metric_user_created",
        where: { ...getDateWhere(days_to_show), ...inner_filters },
    });
    return (
        <>
            <FilterForm fields={[]} onFilter={set_inner_filters} />
            <MetricCards
                options={[
                    {
                        // icon: <FcLandscape />,
                        label: getLabel("farm", true),
                        link: "/management/location/list/farm",
                        value: useTotalSubscription({
                            entity: "location",
                            where: { location_type: { _eq: "farm" }, active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "location", where: { location_type: { _eq: "farm" } } }),
                    },
                    {
                        // icon: <FcLandscape />,
                        label: getLabel("mandi", true),
                        link: "/management/location/list/mandi",
                        value: useTotalSubscription({
                            entity: "location",
                            where: { location_type: { _eq: "mandi" }, active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "location", where: { location_type: { _eq: "mandi" } } }),
                    },
                    {
                        // icon: <FcLandscape />,
                        label: getLabel("godown", true),
                        link: "/management/location/list/godown",
                        value: useTotalSubscription({
                            entity: "location",
                            where: { location_type: { _eq: "godown" }, active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "location", where: { location_type: { _eq: "godown" } } }),
                    },
                    {
                        // icon: <FcLandscape />,
                        label: getLabel("warehouse", true),
                        link: "/management/location/list/warehouse",
                        value: useTotalSubscription({
                            entity: "location",
                            where: { location_type: { _eq: "warehouse" }, active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "location", where: { location_type: { _eq: "warehouse" } } }),
                    },
                    {
                        // icon: <FcLandscape />,
                        label: getLabel("coldstorage", true),
                        link: "/management/location/list/coldstorage",
                        value: useTotalSubscription({
                            entity: "location",
                            where: { location_type: { _eq: "coldstorage" }, active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "location", where: { location_type: { _eq: "coldstorage" } } }),
                    },
                    {
                        // icon: <FcLandscape />,
                        label: getLabel("store", true),
                        link: "/management/location/list/store",
                        value: useTotalSubscription({
                            entity: "location",
                            where: { location_type: { _eq: "store" }, active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "location", where: { location_type: { _eq: "store" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("farmer", true),
                        link: "/management/user/list/farmer",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "farmer" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "farmer" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("trader", true),
                        link: "/management/user/list/trader",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "trader" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "trader" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("customer", true),
                        link: "/management/user/list/customer",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "customer" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "customer" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("grader", true),
                        link: "/management/user/list/grader",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "grader" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "grader" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("transporter", true),
                        link: "/management/user/list/transporter",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "transporter" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "transporter" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("packager", true),
                        link: "/management/user/list/packager",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "packager" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "packager" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("kissanmitra", true),
                        link: "/management/user/list/kissanmitra",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "kissanmitra" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "kissanmitra" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("mandimitra", true),
                        link: "/management/user/list/mandimitra",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "mandimitra" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "mandimitra" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("agent", true),
                        link: "/management/user/list/agent",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "agent" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "agent" } } }),
                    },
                    {
                        // icon: <FcBusinessman />,
                        label: getLabel("moderator", true),
                        link: "/management/user/list/moderator",
                        value: useTotalSubscription({ entity: "user", where: { role: { _eq: "moderator" }, active: { _eq: true } } }),
                        total: useTotalSubscription({ entity: "user", where: { role: { _eq: "moderator" } } }),
                    },
                ]}
            />
            <ChartCards
                options={[
                    {
                        header: "Locations By Type",
                        header_link: "/management/location/list/active/all",
                        type: "donut",
                        series: metric_location_type?.map((e) => e?.count ?? 0) ?? [],
                        options: {
                            chart: {
                                type: "donut",
                            },

                            labels: metric_location_type?.map((e) => e?.type?.toUpperCase()) ?? [],
                        },
                    },
                    {
                        header: "Users By Role",
                        header_link: "/management/user/list/active/all",
                        type: "donut",
                        series: metric_user_role?.map((e) => e?.count ?? 0) ?? [],
                        options: {
                            chart: {
                                type: "donut",
                            },

                            labels: metric_user_role?.map((e) => e?.type?.toUpperCase()) ?? [],
                        },
                    },
                    {
                        header: "Role of Users by Date",
                        header_link: "/management/user/list/active/all",
                        type: "bar",
                        options: {
                            chart: {
                                type: "bar",
                                stacked: true,
                                // stackType: "100%",
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories: metric_user_created?.map((e) => e?.date) ?? [],
                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                            ...((!_.isArray(enum_user_role)
                                ? []
                                : enum_user_role?.map((e) => ({
                                      name: e.comment,
                                      data:
                                          metric_user_role_created.filter((x) => x.type === e.value || !x.type).map((x) => x?.count ?? 0) ?? [],
                                  }))) ?? []),
                        ],

                        colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                    },
                ]}
            />
        </>
    );
};

export default Detail;
