import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { AuthContext,  } from "../../../../../context";
import {   getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";

const List = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	const { role,  user_id} = useContext(AuthContext);
	const entity_fields = useWebEntityFields("user");
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
//    console.log("Check role",useEntityFields({ entity: "exporter_data", solution }),)

	const { object, error } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });

    const override_fields = {  filters:{customer_id :{_eq:object?.customer_id}}};


  
	let tab_options = [
		
		{
			label: "ALL",
			value: "all",
			...entity_fields,
			...override_fields,
			disableAdd: "true" ,
			overrideValues: {
				...(entity_fields?.overrideValues || {}),
				...(override_fields?.overrideValues || {}),
			},
			component: DataTable,
			filters: {
				...(entity_fields?.filters || {}),
				...(override_fields?.filters || {}),
			},
		},{

		...useEntityFields({ entity: "exporter_data", solution }),
		label: getLabel("Business Data", true),
		value: "exporter_data",
		showTitle : "Business Data",
		DisableAddAftRec:1,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id } },
		overrideValues: { customer_id: object?.customer_id },
		skipColumns: ["user"],
	},
	
	{
		...useEntityFields({ entity: "customer_document", solution }),
		label: "APEDA",
		value: "apeda",
		disabled:"true",
		disableAction:false,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id }, document_type:{_eq:"apeda"} },
		overrideValues: { customer_id: object?.customer_id, document_type:"apeda"  },
		skipColumns: ["user"],
	},	

	{
		...useEntityFields({ entity: "customer_document", solution }),
		label: "ICE",
		value: "ice",
		 
		disableAction:false,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id }, document_type:{_eq:"ice"} },
		overrideValues: { customer_id: object?.customer_id, document_type:"ice"  },
		skipColumns: ["user"],
	},	
	{
		...useEntityFields({ entity: "customer_document", solution }),
		label: "GST",
		value: "gst",
		 
		disableAction:false,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id }, document_type:{_eq:"gst"} },
		overrideValues: { customer_id: object?.customer_id, document_type:"gst"  },
		skipColumns: ["user"],
	},	

	{
		...useEntityFields({ entity: "customer_document", solution }),
		label: "PAN",
		value: "PAN",
		 
		disableAction:false,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id }, document_type:{_eq:"PAN"} },
		overrideValues: { customer_id: object?.customer_id, document_type:"PAN"  },
		skipColumns: ["user"],
	},	

	{
		...useEntityFields({ entity: "customer_document", solution }),
		label: "AADHAAR",
		value: "aadhaar",
		 
		disableAction:false,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id }, document_type:{_eq:"aadhaar"} },
		overrideValues: { customer_id: object?.customer_id, document_type:"aadhaar"  },
		skipColumns: ["user"],
	},	

 
	{
		...useEntityFields({ entity: "customer_bank", solution }),
		label: "CUSTOMER BANK",
		value: "customer_bank",
		 

		DisableAddAftRec:1,
		disableAction:true,
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id } },
		overrideValues: { customer_id: object?.customer_id },
		skipColumns: ["user"],
	},	
	{
		...useEntityFields({ entity: "exporter_commodity" }),
		label: "COMMODITY DETAILS",
		value: `${object?.["customer_type"]}commodity`,
		showTitle : "Commmodity Data",
		dependsValue:  object?.["customer_type"] ,
		dependsOn:{"supplier":true},
		 
		
	    component: DataTable,
	    filters: { customer_id: { _eq: object?.customer_id } ,},
	    overrideValues: { customer_id: object?.customer_id },
   },
	{
		...useEntityFields({ entity: "location", solution }),
		label: "LOCATION",
		value: "location",
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id } },
		overrideValues: { customer_id: object?.customer_id },
		skipColumns: ["user"],
	},
		{
		...useWebEntityFields(  "customer_contact" ),
		label: "CUSTOMER CONTACT",
		value: "customer_contact",
		 
	 
		component: DataTable,
		filters: { customer_id: { _eq: object?.customer_id } },
		overrideValues: { customer_id: object?.customer_id },
		deepOverrideValues: { customer_id: object?.customer_id },
		skipColumns: ["customer"],
	},	]

	return (
		<PageContainer
			breadcrumbTitle={getLabel(role || "", true)}
			// breadcrumbOptions={["type", "active"]}
			breadcrumbArg={3}
			tab_arg={4}
 			key={Math.random()}
			options={{ entity_fields}}
		    disableAction={"true"}
		    // disableAdd={"true"}
			// disableEdit={"false"}
			hideFilter={"false"}
			detail={true}
			// detail={page === "detail"}
			tab_options={(() => {
				// let tab_options = [];
				// switch (tab) {
				// 	case "type":
				// 		tab_options = !_.isArray(enum_po_status)
				// 			? []
				// 			: enum_po_status?.map((e) => ({
				// 					...entity_fields,
				// 					...override_fields,
				// 					overrideValues: {
				// 						...(entity_fields?.overrideValues || {}),
				// 						...(override_fields?.overrideValues || {}),
				// 					},
				// 					label: e?.comment,
				// 					value: e?.value,
				// 					component: DataTable,
				// 					filters: {
				// 						...(entity_fields?.filters || {}),
				// 						...(override_fields?.filters || {}),
				// 						status: { _eq: e.value },
				// 					},
				// 			  }));
				// 		break;
				// 	default:
				// 		tab_options = ENUM_ACTIVE.map((e) => ({
				// 			...entity_fields,
				// 			...override_fields,
				// 			overrideValues: {
				// 				...(entity_fields?.overrideValues || {}),
				// 				...(override_fields?.overrideValues || {}),
				// 			},
				// 			label: e?.comment,
				// 			value: e?.value,
				// 			component: DataTable,
				// 			filters: {
				// 				...(entity_fields?.filters || {}),
				// 				...(override_fields?.filters || {}),
				// 				active: { _eq: e.value === "active" ? true : false },
				// 			},
				// 		}));
				// 		break;
				// }
				tab_options = [
					// {
					// 	label: "ALL",
					// 	value: "all",
					// 	...entity_fields,
					// 	...override_fields,
					// 	overrideValues: {
					// 		...(entity_fields?.overrideValues || {}),
					// 		...(override_fields?.overrideValues || {}),
					// 	},
					// 	component: DataTable,
					// 	filters: {
					// 		...(entity_fields?.filters || {}),
					// 		...(override_fields?.filters || {}),
					// 	},
					// },
					
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
	);
};

export default List;
