import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({ id: parent_id, ...useEntityFields({ entity, parent_id, solution }) });

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            component={DetailInfo}
            entity={entity}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            metric_cards={[
                {
                    label: getLabel("jobcard", true),
                    value: object?.active_jobcards?.aggregate?.count || 0,
                    total: object?.total_jobcards?.aggregate?.count || 0,
                },
            ]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "jobcard", parent_id, solution }),
                    label: getLabel("jobcard", true),
                    value: "jobcard",

                    component: DataTable,
                    filters: { vehicle_id: { _eq: parent_id } },
                    overrideValues: { vehicle_id: parent_id },
                },
                {
                    ...useEntityFields({ entity: "vehicle_contact", parent_id, solution }),
                    label: getLabel("contact", true),
                    value: "contact",

                    component: DataTable,
                    filters: { vehicle_id: { _eq: parent_id } },
                    overrideValues: { vehicle_id: parent_id },
                    skipColumns: ["customer"],
                },
            ]}
        />
    );
};

export default Detail;
