import React from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { DetailInfo } from "../../../../../components/common/DetailInfo";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import { AuthContext } from "../../../../../context";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();

	// eslint-disable-next-line no-unused-vars
	const { role } = React.useContext(AuthContext);

	let entity ="customer";
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity:"customer" }),
	});

    //  console.log("pares" , object)

	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			entity={"customer"}
			disableAction={"false"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
		
			
				{
					label: getLabel("Bussiness Data", true),
					value: object?.active_companys?.aggregate?.count || 0,
					total: object?.total_companys?.aggregate?.count || 0,
				},

				{
					label: getLabel("document", true),
					value: object?.active_documents?.aggregate?.count || 0,
					total: object?.total_documents?.aggregate?.count || 0,
				},
				{
					label: getLabel("bank", true),
					value: object?.active_banks?.aggregate?.count || 0,
					total: object?.total_banks?.aggregate?.count || 0,
				},
				{
					label: getLabel("commodites", true),
					value: object?.total_exporter_comm?.aggregate?.count || 0,
					total: object?.total_exporter_comm?.aggregate?.count || 0,
				},

					{
					label: getLabel("contacts", true),
					value: object?.active_contacts?.aggregate?.count || 0,
					total: object?.total_contacts?.aggregate?.count || 0,
				},

			]}
			tab_options={[
				{
					...useEntityFields({ entity: "exporter_data", parent_id, solution }),
					label: getLabel("Business Data", true),
					value: "exporter_data",
					showTitle : "Business Data",
                  
					component: DataTable,
					disableAction:false,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["user"],
				},	{
					...useEntityFields({ entity: "customer_document", parent_id, solution }),
					label: getLabel("Verify Documents", true),
					value: "customer_document",

					disableAction:false,
					component: DataTable,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["user"],
				},	
				{
					...useEntityFields({ entity: "customer_bank", parent_id, solution }),
					label: getLabel("customer_bank", true),
					value: "customer_bank",

					DisableAddAftRec:1,
					disableAction:false,
					component: DataTable,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["user"],
				},	
				{
                    ...useEntityFields({ entity: "exporter_commodity" }),
					label: getLabel(`Commodity Details`, true),
                    value: `${object?.["customer_type"]}commodity`,
					showTitle : "Commmodity Data",
					dependsValue:  object?.["customer_type"] ,
					dependsOn:{"supplier":true},
                   component: DataTable,
                   filters: { customer_id: { _eq: parent_id } ,},
                   overrideValues: { customer_id: parent_id },
               },
					{
					...useWebEntityFields(  "customer_contact" ),
					label: getLabel("customer_contact", true),
					value: "customer_contact",

					component: DataTable,
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					deepOverrideValues: { customer_id: parent_id },
					skipColumns: ["customer"],
				},	

						// {customer_documentcustomer_bank
				// 	...useEntityFields({ entity: "offer", parent_id, solution }),
				// 	label: getLabel("offer", true),
				// 	value: "offer",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },
				// {
				// 	...useEntityFields({ entity: "location", parent_id, solution }),
				// 	label: getLabel("location", true),
				// 	value: "location",
				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },

				// {
				// 	...useEntityFields({ entity: "exporter_data ", parent_id, solution }),
				// 	label: getLabel("exporter", true),
				// 	value: "exporter_data",
				// 	component: DataTable,
				// 	filters: { user_id: { _eq: parent_id } },
				// 	overrideValues: { user_id: parent_id },
				// 	skipColumns: ["customer"],
				// },

			]}
		/>
	);
};

export default Detail;
