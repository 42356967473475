import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { GeneralContext,AuthContext } from "../../../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import { Freight } from "./freight";
import { Modals } from "./modal";
import ListRowChild from "./new_rowChild";
import moment from "moment";
 
const List = () => {

	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	const { enum_po_status } = useContext(GeneralContext);
    const {   user_id , id_token} = useContext(AuthContext);
	let entity_fields = useWebEntityFields("freight_forwarder");
    //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
 
	const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });

	console.log("customerid",object?.customer_id);
	
    const override_fields = { overrideValues:{customer_id : object?.customer_id} ,filters:{customer_id :{_eq:object?.customer_id}}}
  
 
     
	return (
		<PageContainer
			breadcrumbTitle={getLabel("My Listings", true)}
			// breadcrumbOptions={["type", "active"]}
			showTitle="Freight Listing"
			breadcrumbArg={3}
			tab_arg={4}
			key={Math.random()}
			options={{ entity_fields}}
			disableAction={true}
			// disableEdit={true}
			disableExcel= {true}
            excelSubUrl = "user/freight_listing" 
			excelResponseType = "blob" 
			excelDocType = "" 
			rowChild={ListRowChild}
			expanded={true}
			radioAction={true}
			searchableColumns={false}

			// enabledispatchColumn={
			// 	[
			// 	   { body:{col:"id",type:"Quotation",customer_id:object?.customer_id, sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/freight_list_quotation",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
           
			// 	]}

			enabledispatchColumn={ 
				[
					// { body:{col:"id",type:"Quotation",customer_id:object?.customer_id, sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/freight_list_quotation",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},


				//    {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				// {Header:"Order",entity:"exporter_offer_button",showTitle:true,minWidth:150,  DataForm:true, overrideValues:{customer_id:object?.customer_id }},				// {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				// {Header:"Order",entity:"exporter_offer_button",showTitle:true,minWidth:150,  DataForm:true, overrideValues:{customer_id:object?.customer_id }},				// {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				// {Header:"",DataForm:true, entity:"quotation", showtitle:true, minWidth:150,  
				// 	overrideValues:{"customer_id":object?.customer_id, "created_by_id":object?.customer_id, },
				// 	skipdata:[
				// 				"bill_of_lading_charges","toll_charges","temp_variation_charges","cmc_charges","seal_charges","etd",
				// 				"etd","id","created_at","updated_at","export_shipping_line_id","do","bl","transportation_slip","cut_off","transit_period",
				// 				"vessel","dollar_price","active","uid","vessel_risk","muf","validity","total_cost"
				// 				],
				// },
						

				]}	

			htmlToExcelShow={false}
            htmlToExcelShowTitle={"Freight Rates"}
            htmlToExcelShowHeaders={["Origin Port",
                                     "Destination Port",
                                     "Container Size",
                                     "Container Category Type",
                                     "Ocean Freight Charges",
                                     "Terminal Handling Charges",
                                     "Muf",
                                     "Toll Charges",
                                     "Bill of Lading Charges",
                                     "SeaWay BL charges",
                                     "Entity Name",
                                     "Vessel",
                                     "CuttOff",
                                     "Validity",
                                     "ETD",
                                     "ETA",
                                     "Vessel Risk",
                                     "Dollar Price"
        ]}
            
			Model={Modals}
 			detail={page === "detail"}
			tab_options={(() => {
				let tab_options = [];
				switch (tab) {
					case "type":
						tab_options = !_.isArray(enum_po_status)
							? []
							: enum_po_status?.map((e) => ({
									...entity_fields,
									...override_fields,
									overrideValues: {
										...(entity_fields?.overrideValues || {}),
										...(override_fields?.overrideValues || {}),
									},
									label: e?.comment,
									value: e?.value,
									component: DataTable,
									filters: {
										...(entity_fields?.filters || {}),
										...(override_fields?.filters || {}),
										status: { _eq: e.value },
									},
							  }));
						break;
					default:
						tab_options = ENUM_ACTIVE.map((e) => ({
							...entity_fields,
							...override_fields,
							overrideValues: {
								...(entity_fields?.overrideValues || {}),
								...(override_fields?.overrideValues || {}),
							},
							label: e?.comment,
							value: e?.value,
							component: DataTable,
							filters: {
								...(entity_fields?.filters || {}),
								...(override_fields?.filters || {}),
								active: { _eq: e.value === "active" ? true : false },
							},
						}));
						break;
				}
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						// TableName: Freight,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						
						},
					},

					...tab_options,
					{
						label: "Archive",
						value: "archive",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						// TableName: Freight,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
							listing_validity_date: { _lt: moment()},
							active: { _eq: false },
						},
					},
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
 
	);
};

export default List;
