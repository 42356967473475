import _ from "lodash";
import React from "react";
import validate from "uuid-validate";
import { getLabel, getParams } from "../../helpers";
import ButtonTabs from "./ButtonTabs";
import { DataForm } from "./DataForm";

function BreadCrumb({ title = "", button = "", disablePath, addButtons = [], options = [], overrideValues = {}, skipColumns = [], arg = 0 }) {
    const { args } = getParams();
    let header = "";
    args?.map((arg) => {
        if (_.isString(getLabel(arg, true)) && !validate(arg)) header = header ? header + "  /  " + getLabel(arg, true) : getLabel(arg, true);
        return null;
    });

    return (
        <div className="row page-title-box d-flex align-items-center justify-content-between">
            {!disablePath && <span className="breadcrumb-header page-title-right text-muted col-12">{header}</span>}

            <span className="h3 col-sm-12 col-md-3 mt-2 d-flex justify-content-between">
                {title} {button}
            </span>
            <span className="page-title-right col-sm-12 col-md-9 mx-auto">
                {options?.length > 0 && <ButtonTabs options={options} arg={arg} prefix="group by" />}
                {addButtons?.length > 0 &&
                    addButtons?.map((b, i) => (
                        <span key={i} className="">
                            <DataForm key={b} showTitle={b} entity={b} overrideValues={overrideValues} skipColumns={skipColumns} />
                        </span>
                    ))}
            </span>
        </div>
    );
}

export default BreadCrumb;
