import React from "react";
import ComingSoonSvg from '../../../assets/images/brands/coming-soon.svg';


  const ComingSoon = () =>{


    return<> <div className=" mb-0 page-content mt-3 row   justify-content-around   align-items-center" >

             <img src={ComingSoonSvg} className="img-fluid     "  style={{height:"70vh"}} alt="Responsive" />

          
           </div> 
  <h5 className="text-center  " style={{marginTop:"-10vh"}}>ComingSoon...</h5>
  </>
}


export default ComingSoon;