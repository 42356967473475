import React, { createContext, useReducer } from "react";
import storage from "store2";

const initialAuthState = {
    is_authenticated: false,
    id_token: storage.has("id_token") ? storage.get("id_token") : "",
    user: undefined,
    role: storage.has("role") ? storage.get("role") : "",
    user_id: storage.has("user_id") ? storage.get("user_id") : "",
};

const authReducer = (state, action) => {
    // console.log("Auth reducer", state, action)
    switch (action.type) {
        case "AUTHENTICATE_USER":
            return {
                ...state,
                is_authenticated: action.payload,
            };
        case "SET_DATA":
            const { user_id, role, id_token } = action.payload;
            if (id_token && state.id_token !== id_token) storage.set("id_token", id_token);
            if (role && state.role !== role) storage.set("role", role);
            if (user_id && state.user_id !== user_id) storage.set("user_id", user_id);
            return { ...state, ...action.payload };
        case "LOGOUT_USER":
            storage.clearAll();
            return {
                ...state,
                is_authenticated: false,
                id_token: "",
                user: undefined,
                role: undefined,
                user_id: "",
            };
        default:
            return state;
    }
};

const AuthContext = createContext(initialAuthState);
const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialAuthState);

    const dispatches = {
        authenticateUser(validAuthentication) {
            dispatch({
                type: "AUTHENTICATE_USER",
                payload: validAuthentication,
            });
        },
        setAuthContext(data) {
            dispatch({
                type: "SET_DATA",
                payload: data,
            });
        },
        logoutUser() {
            dispatch({
                type: "LOGOUT_USER",
            });
        },
    };

    return <AuthContext.Provider value={{ ...state, ...dispatches }}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
