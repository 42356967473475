import _ from "lodash";
import React, { useContext } from "react";
import { ChartCards } from "../../../components/common/ChartsandGraphs";
import { MetricCards } from "../../../components/common/MetricCards";
import { GeneralContext } from "../../../context";
import { getLabel, useAggQuery, useCommonSubscription, useTotalSubscription } from "../../../helpers";

const Detail = () => {
	const { enum_order_status, enum_offer_status } = useContext(GeneralContext);

	const metric_offer_status = useCommonSubscription({ entity: "metric_offer_status" });
	const metric_order_status = useCommonSubscription({ entity: "metric_order_status" });
	const metric_payment_status = useCommonSubscription({ entity: "metric_payment_status" });

	const metric_offer_created = useCommonSubscription({ entity: "metric_offer_created", limit: 30 });
	const metric_order_created = useCommonSubscription({ entity: "metric_order_created", limit: 30 });
	const metric_payment_created = useCommonSubscription({ entity: "metric_payment_created", limit: 30 });

	const metric_offer_status_created = useCommonSubscription({ entity: "metric_offer_status_created", limit: 30 });
	const metric_order_status_created = useCommonSubscription({ entity: "metric_order_status_created", limit: 30 });
	return (
		<>
			<MetricCards
				options={[
					{
						// icon: <FcPackage />,
						label: getLabel("offer", true),
						link: "/marketplace/offer/list/active/all",
						value: useTotalSubscription({
							entity: "offer",
							where: { active: { _eq: true } },
						}),
						total: useTotalSubscription({ entity: "offer", where: {} }),
					},
					{
						// icon: <FcBusiness />,
						label: getLabel("order", true),
						link: "/marketplace/order/list/active/all",
						value: useTotalSubscription({
							entity: "order",
							where: { active: { _eq: true } },
						}),
						total: useTotalSubscription({ entity: "order", where: {} }),
					},
					{
						// icon: <FcSalesPerformance />,
						label: getLabel("payment", true),
						link: "/marketplace/payment/list/active/all",
						value: useTotalSubscription({
							entity: "order_payment",
							where: { active: { _eq: true } },
						}),
						total: useTotalSubscription({ entity: "order_payment", where: {} }),
					},
					{
						// icon: <FcBusinessContact />,
						label: getLabel("user", true),
						link: "/marketplace/user/list/active/all",
						value: useTotalSubscription({
							entity: "user",
							where: { active: { _eq: true } },
						}),
						total: useTotalSubscription({ entity: "user", where: {} }),
					},
					{
						// icon: <FcSalesPerformance />,
						label: getLabel("order_amount", true),
						type: "currency",
						link: "/marketplace/order/list/active/all",
						value: useAggQuery({ entity: "order", where: { active: { _eq: true } } }),
						total: useAggQuery({ entity: "order", where: {} }),
					},
					{
						// icon: <FcSalesPerformance />,
						label: getLabel("payment_amount", true),
						type: "currency",
						link: "/marketplace/payment/list/active/all",
						value: useAggQuery({ entity: "order_payment", where: { active: { _eq: true } } }),
						total: useAggQuery({ entity: "order_payment", where: {} }),
					},
				]}
			/>
			<ChartCards
				options={[
					{
						header: getLabel("offer", true),
						header_link: "/marketplace/offer/list/active/all",
						type: "donut",
						series: metric_offer_status?.map((e) => e?.count ?? 0) ?? [],
						options: {
							chart: {
								type: "donut",
							},
							labels: metric_offer_status?.map((e) => e?.type?.toUpperCase()) ?? [],
						},

						colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
					},
					{
						header: getLabel("order", true),
						header_link: "/marketplace/order/list/active/all",
						type: "donut",
						series: metric_order_status?.map((e) => e?.count ?? 0) ?? [],
						options: {
							chart: {
								type: "donut",
							},
							labels: metric_order_status?.map((e) => e?.type?.toUpperCase()) ?? [],
						},

						colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
					},
					{
						header: getLabel("payment", true),
						header_link: "/marketplace/payment/list/active/all",
						type: "donut",
						series: metric_payment_status?.map((e) => e?.count ?? 0) ?? [],
						options: {
							chart: {
								type: "donut",
							},
							labels: metric_payment_status?.map((e) => e?.type?.toUpperCase()) ?? [],
						},

						colSpan: `col-xl-4 col-lg-6 col-md-6 col-sm-12`,
					},
					{
						header: "Metrics by Created Date",
						// header_link: "/marketplace/order/list/active/all",
						type: "bar",
						options: {
							chart: {
								type: "bar",
								stacked: true,
								// stackType: "100%",
							},
							responsive: [
								{
									breakpoint: 480,
									options: {
										legend: {
											position: "bottom",
											offsetX: -10,
											offsetY: 0,
										},
									},
								},
							],
							xaxis: {
								categories: metric_order_created?.map((e) => e?.date) ?? [],
							},
							fill: {
								opacity: 1,
							},
						},
						series: [
							{
								name: getLabel("offer", true),
								data: metric_offer_created?.map((e) => e?.count ?? 0) ?? [],
							},
							{
								name: getLabel("order", true),
								data: metric_order_created?.map((e) => e?.count ?? 0) ?? [],
							},
							{
								name: getLabel("payment", true),
								data: metric_payment_created?.map((e) => e?.count ?? 0) ?? [],
							},
						],

						colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
					},
					{
						header: "Status of Orders By Date",
						header_link: "/marketplace/order/list/active/all",
						type: "bar",
						options: {
							chart: {
								type: "bar",
								stacked: true,
								// stackType: "100%",
							},
							responsive: [
								{
									breakpoint: 480,
									options: {
										legend: {
											position: "bottom",
											offsetX: -10,
											offsetY: 0,
										},
									},
								},
							],
							xaxis: {
								categories: metric_order_created?.map((e) => e?.date) ?? [],
							},
							fill: {
								opacity: 1,
							},
						},
						series: [
							...((!_.isArray(enum_order_status)
								? []
								: enum_order_status?.map((e) => ({
										name: e.comment,
										data: metric_order_status_created.filter((x) => x.type === e.value).map((x) => x?.count ?? 0) ?? [],
								  }))) ?? []),
						],

						colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
					},
					{
						header: "Status of Offers By Date",
						header_link: "/marketplace/offer/list/active/all",
						type: "bar",
						options: {
							chart: {
								type: "bar",
								stacked: true,
								// stackType: "100%",
							},
							responsive: [
								{
									breakpoint: 480,
									options: {
										legend: {
											position: "bottom",
											offsetX: -10,
											offsetY: 0,
										},
									},
								},
							],
							xaxis: {
								categories: metric_offer_created?.map((e) => e?.date) ?? [],
							},
							fill: {
								opacity: 1,
							},
						},
						series: [
							...((!_.isArray(enum_offer_status)
								? []
								: enum_offer_status?.map((e) => ({
										name: e.comment,
										data:
											metric_offer_status_created
												.filter((x) => x.type === e.value || !x.type)
												.map((x) => x?.count ?? 0) ?? [],
								  }))) ?? []),
						],

						colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
					},
				]}
			/>
		</>
	);
};

export default Detail;
