import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({
        id: parent_id,
        ...useEntityFields({ entity, parent_id, solution }),
    });

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            component={DetailInfo}
            entity={entity}
            options={{
                ...useEntityFields({ entity, parent_id, solution }),
            }}
            tab_arg={4}
            metric_cards={[
                {
                    label: getLabel("customer", true),
                    value: object?.active_customers?.aggregate?.count || 0,
                    total: object?.total_customers?.aggregate?.count || 0,
                },
                {
                    label: getLabel("location", true),
                    value: object?.active_locations?.aggregate?.count || 0,
                    total: object?.total_locations?.aggregate?.count || 0,
                },
            ]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "customer_contact", parent_id, solution }),
                    label: getLabel("customer_contact", true),
                    value: "customer_contact",

                    component: DataTable,
                    filters: { contact_id: { _eq: parent_id } },
                    skipColumns: ["contact"],
                },
                {
                    ...useEntityFields({ entity: "location_contact", parent_id, solution }),
                    label: getLabel("location_contact", true),
                    value: "location_contact",

                    component: DataTable,
                    filters: { contact_id: { _eq: parent_id } },
                    skipColumns: ["contact"],
                },
            ]}
        />
    );
};

export default Detail;
