import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
// import logoLightSvg from "../../assets/images/logo-dark.svg";
import logoLightSvg from "../../assets/images/OriginKonnect_512_512.png";

import { APP_CONFIG, APP_PATHS } from "../../config";
import { useFormValidation } from "../../helpers/utils";

const PasswordReset = () => {
    const location = useLocation();
    const history = useHistory();
    const [verifying, set_verifying] = useState(false);

    const queryParams = queryString.parse(location.search);

    const [formFields, setFormFields] = useState({
        password: "",
        confirmPassword: "",
        customerId: "",
        isSubmitting: false,
        token: "",
    });

    const [formFieldErrors, onBlur] = useFormValidation(
        {
            password: "",
            confirmPassword: "",
        },
        formFields
    );

    const [passwordVisibility, setPasswordVisibility] = useState({
        password: false,
        confirmPassword: false,
    });

    const togglePasswordVisibility = (field) => {
        setPasswordVisibility({
            ...passwordVisibility,
            [field]: !passwordVisibility[field],
        });
    };

    // pending | failed | success | completed
    const [verificationStatus, setVerificationStatus] = useState("pending");

    useEffect(() => {
        (async function () {
            if (queryParams?.t && !verifying && verificationStatus === "pending") {
                set_verifying(true);
                try {
                    const response = await axios({
                        method: "POST",
                        url: `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/verifypasswordreset`,
                        data: { reset_token: queryParams?.t },
                        headers: { "x-client-app": "web-admin" },
                    });
                    toast.success(response.data.message, { position: "top-right" });
                    setFormFields({ ...formFields, customerId: response.data.user_id, token: response?.data?.token?.value });
                    setVerificationStatus("success");
                    set_verifying(false);
                } catch (err) {
                    toast.error(err.response.data.message, { position: "top-right" });
                    setVerificationStatus("failed");
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams?.t]);

    useEffect(() => {
        if (verificationStatus === "success") {
            const timer = setTimeout(() => {
                setVerificationStatus("completed");
            }, 1500);
            return () => clearTimeout(timer);
        }
    }, [verificationStatus]);

    const onChange = (e) => {
        setFormFields({ ...formFields, [e.target.name]: e.target.value });
        onBlur(e);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const { password, confirmPassword } = formFields;
        if (password && password === confirmPassword) {
            setFormFields({ ...formFields, isSubmitting: true });
            try {
                const response = await axios({
                    method: "POST",
                    url: `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/resetpassword`,
                    data: {
                        password: password,
                        key: queryParams.t,
                        // Authorization: `Bearer ${formFields.token}`,
                    },
                    headers: { Authorization: `Bearer ${formFields.token}` },
                });
                toast.success(response.data.message, { position: "top-right" });
                history.push(APP_PATHS.login);
            } catch (err) {
                if (err?.response?.data?.message) {
                    toast.error(err.response.data.message, { position: "top-right" });
                }
            }
            setFormFields({ ...formFields, isSubmitting: false });
        }
    };

    return (
        <div className="row justify-content-center">
            <main
                className="shadow p-4"
                style={{ borderRadius: 10, position: "absolute", top: "50%", transform: "translateY(-50%)", width: "30rem" }}
            >
                <div className="left-pane-white">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100">
                        <img
                            src={logoLightSvg}
                            alt="FlipServe logo"
                            className="left-pane-white-logo mb-5 img-fluid"
                            style={{ objectFit: "contain", height: 30 }}
                        />
                    </div>
                </div>
                <div className="right-pane">
                    {verificationStatus !== "completed" && (
                        <div
                            style={{ width: "100%", height: "100%" }}
                            className="d-flex flex-column align-items-center justify-content-center user-select-none"
                        >
                            <img src={logoLightSvg} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />
                            {verificationStatus !== "completed" && verificationStatus === "pending" && (
                                <>
                                    <Spinner animation="border" />
                                    <h3 className="mt-3 text-sm font-weight-bold text-uppercase">Verifying</h3>
                                </>
                            )}
                            {verificationStatus !== "completed" && verificationStatus === "failed" && (
                                <>
                                    <h3 className="mt-3 text-sm font-weight-bold text-uppercase text-danger">Verification Failed</h3>
                                    <div className="mt-5">
                                        <p className="text-center">
                                            <span>
                                                <Link to={APP_PATHS.forgotPassword} className="font-weight-bold text-primary">
                                                    Request for Password Reset
                                                </Link>
                                            </span>
                                        </p>
                                    </div>
                                </>
                            )}
                            {verificationStatus !== "completed" && verificationStatus === "success" && (
                                <>
                                    <h3 className="mt-3 text-sm font-weight-bold text-uppercase text-success">Verification Successful</h3>
                                </>
                            )}
                        </div>
                    )}

                    {verificationStatus === "completed" && (
                        <div className="d-flex flex-column justify-content-center h-100">
                            <img src={logoLightSvg} alt="FlipServe logo" className="d-none right-pane-logo align-self-center" />
                            <div>
                                <h3 className="text-dark font-weight-bold" style={{ fontSize: 20 }}>
                                    Reset Password
                                </h3>
                                <p className="mb-4 text-gray text-sm">
                                    <span>Enter a new password for your account</span>
                                </p>
                                <form onSubmit={onSubmit}>
                                    <div className={formFieldErrors.password ? "form-group has-error" : "form-group"}>
                                        <label htmlFor="password">Password</label>
                                        <div className="input-group">
                                            <input
                                                type={passwordVisibility.password ? "text" : "password"}
                                                name="password"
                                                id="password"
                                                className="form-control"
                                                placeholder=""
                                                value={formFields.password}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                            <div className="input-group-append" onClick={() => togglePasswordVisibility("password")}>
                                                <i
                                                    className={
                                                        passwordVisibility.password
                                                            ? "input-group-text fa fa-eye-slash"
                                                            : "input-group-text fa fa-eye"
                                                    }
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </div>
                                        {formFieldErrors.password && (
                                            <small className="form-validation-message text-danger">{formFieldErrors.password}</small>
                                        )}
                                    </div>
                                    <div
                                        className={formFieldErrors.confirmPassword ? "form-group mb-2 has-error mt-1" : "form-group mb-2 mt-1"}
                                    >
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <div className="input-group">
                                            <input
                                                type={passwordVisibility.confirmPassword ? "text" : "password"}
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                className="form-control"
                                                placeholder=""
                                                value={formFields.confirmPassword}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                            />
                                            <div className="input-group-append" onClick={() => togglePasswordVisibility("confirmPassword")}>
                                                <i
                                                    className={
                                                        passwordVisibility.confirmPassword
                                                            ? "input-group-text fa fa-eye-slash"
                                                            : "input-group-text fa fa-eye"
                                                    }
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </div>
                                        {formFieldErrors.confirmPassword && (
                                            <small className="form-validation-message text-danger">{formFieldErrors.confirmPassword}</small>
                                        )}
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-block btn-primary my-2"
                                        //style={{ width: "50%", margin: "50px auto 0 auto" }}
                                        onClick={onSubmit}
                                        disabled={formFields.isSubmitting}
                                    >
                                        {formFields.isSubmitting ? <Spinner size="sm" role="status" /> : <span>Change Password</span>}
                                    </button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default PasswordReset;
