import _ from "lodash";
import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import Geocode from "react-geocode";
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from "react-google-maps";
import {
    FcBarChart,
    FcBusiness,
    FcBusinessContact,
    FcBusinessman,
    FcConferenceCall,
    FcCurrencyExchange,
    FcDatabase,
    FcDataConfiguration,
    FcDepartment,
    FcFinePrint,
    FcGlobe,
    FcInTransit,
    FcMultipleInputs,
    FcNeutralTrading,
    FcParallelTasks,
    FcRules,
    FcSalesPerformance,
    FcTodoList,
    FcTreeStructure,
    FcDocument,
    FcViewDetails,
    FcWorkflow,
    FcNews,
    FcAutomatic
    ,
    FcMoneyTransfer
} from "react-icons/fc";
import NumberFormat from "react-number-format";
import { geocodeByAddress } from "react-places-autocomplete";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import storage from "store2";
import Swal from "sweetalert2";
import { ImageModal } from "../components/common/ImageModal";
import { SendRequest } from "../components/common/sampleRequest";
import { useTotalQuery } from "./dbhooks";
export const GOOGLE_MAPS_CONFIG = {
    // API_KEY: "AIzaSyCdMeloIo-O-h-oEeWwGt3xW4N6_LLYoEk",
    API_KEY: "AIzaSyB1bqmhPuzG8buyKXue1OdvlfJmdndMIUo",
};


Geocode.setApiKey(GOOGLE_MAPS_CONFIG.API_KEY);

export const ENUM_LIST = [
    "enum_jobcard_status",
    "enum_loading_status",
    "enum_offer_status",
    "enum_order_status",
    "enum_payment_status",
    "enum_payment_gateway",
    "enum_bag_type",
    "enum_catalogue_type",
    "enum_commodity_type",
    "enum_commodity_category",
    "enum_contact_type",
    "enum_country",
    "enum_credit_days_type",
    "enum_currency",
    "enum_customer_type",
    "enum_document_type",
    "enum_grading_type",
    "enum_jobcard_purpose",
    "enum_jobcard_type",
    "enum_language",
    "enum_location_type",
    "enum_order_type",
    "enum_packaging_type",
    "enum_price_type",
    "enum_transportation_paid_by",
    "enum_user_role",
    "enum_vehicle_type",
    "enum_vehicle_weight",
    "enum_weight_unit",
    "enum_business_type",
    "enum_annual_sales",
    "enum_firm_type",
    "enum_nbfc_credit_status",
    "enum_nbfc_status",
    "enum_vendor_payment_terms",
    "enum_commodity_origin_type", 
    "enum_int_packaging_type",
    "enum_po_status",
    "enum_container_type",
    "enum_cargo_category",
    "enum_freight_status",
    "enum_freight_order_status",
    "enum_freight_transit_status",
    "enum_export_payment_status",
    "enum_user_org_business_type",
    "enum_incoterms_type",
    "enum_stuffing_location_type",
    "enum_inquery_type",
    "enum_export_freight_order_payment_status",
    "enum_free_days_des_type",
    "enum_export_freight_order_payment_status",
    "enum_exp_fri_ord_shipping_ins_status",
    "enum_freight_order_status",
    "enum_exp_fri_ord_vgm_status",
    "enum_exp_fri_ord_booking_val_status",
    "enum_exp_fri_ord_draft_bl_status",
    "enum_bidding_imp_status",
    "enum_bid_order_action_admin_status",
    "enum_bid_order_action_exp_status",
    "enum_bid_order_action_imp_status",
    "enum_bidding_admin_status",
    "enum_bidding_exp_status",
    "enum_bidding_imp_status"


];

export const ENUM_STATUS = ["enum_jobcard_status", "enum_loading_status", "enum_offer_status", "enum_order_status", "enum_payment_status"];

export const BOOLEAN_LIST = ["is_loaded", "is_graded", "is_receipt", "is_phone_verified", "is_email_verified", "is_percentage"];

export const ENUM_ACTIVE = [
    { value: "active", comment: "Active" },
    { value: "inactive", comment: "Inactive" },
];

export const DB_DATE_FORMAT = "YYYY-MM-DD";
export const UI_DATE_FORMAT = "DD/MM/YY";
export const UI_DATETIME_FORMAT = "DD/MM/YY hh:mm A";
export const UI_TIME_FORMAT = "hh:mm A";

export const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const useLocalStorage = (key, initialValue) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = storage.get(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.error(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to storage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            storage.set(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };

    return [storedValue, setValue];
};

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

export const getLabel = (entity, plural) => {
    let label = "";
    switch (entity) {
        case "vehicle":
        case "vehicles":
            if (plural) label = "Vehicles";
            else label = "Vehicle";
            break;
        case "activity":
        case "activities":
            if (plural) label = "Activities";
            else label = "Activity";
            break;
        case "jobcard":
        case "jobcards":
            if (plural) label = "JobCards";
            else label = "JobCard";
            break;
        case "jobcard_contact":
        case "jobcard_contacts":
            if (plural) label = "JobCard Contacts";
            else label = "JobCard Contact";
            break;
        case "jobcard_payment":
        case "jobcard_payments":
            if (plural) label = "JobCard Payments";
            else label = "JobCard Payment";
            break;
        case "jobcard_loading":
        case "jobcard_loadings":
            if (plural) label = "JobCard Loadings";
            else label = "JobCard Loading";
            break;
        case "jobcard_unloading":
        case "jobcard_unloadings":
            if (plural) label = "JobCard Unloadings";
            else label = "JobCard Unloading";
            break;
        case "jobcard_weighing":
        case "jobcard_weighings":
            if (plural) label = "JobCard Weighings";
            else label = "JobCard Weighing";
            break;
        case "loading":
        case "loadings":
            if (plural) label = "Loadings";
            else label = "Loading";
            break;
        case "unloading":
        case "unloadings":
            if (plural) label = "Unloadings";
            else label = "Unloading";
            break;
        case "weighing":
        case "weighings":
            if (plural) label = "Weighings";
            else label = "Weighing";
            break;
        case "contact":
        case "customer_contact":
        case "contacts":
        case "customer_contacts":
            if (plural) label = "Contacts";
            else label = "Contact";
            break;
        case "coldstorager":
        case "coldstoragers":
            if (plural) label = "Cold Storagers";
            else label = "Cold Storager";
            break;
        case "wholesaler":
        case "wholesalers":
            if (plural) label = "Wholesalers";
            else label = "Wholesaler";
            break;
        case "farm":
        case "farms":
            if (plural) label = "Farms";
            else label = "Farm";
            break;
        case "rate":
        case "rates":
            if (plural) label = "Rates";
            else label = "Rate";
            break;
        case "mandi":
        case "mandis":
            if (plural) label = "Mandis";
            else label = "Mandi";
            break;
        case "godown":
        case "godowns":
            if (plural) label = "Godowns";
            else label = "Godown";
            break;
        case "coldstorage":
        case "coldstorages":
            if (plural) label = "Cold Storages";
            else label = "Cold Storage";
            break;
        case "warehouse":
        case "warehouses":
            if (plural) label = "Warehouses";
            else label = "Warehouse";
            break;
        case "store":
        case "stores":
            if (plural) label = "Stores";
            else label = "Store";
            break;
        case "offer":
        case "offers":
            if (plural) label = "Offers";
            else label = "Offer";
            break;
        case "order":
        case "orders":
            if (plural) label = "Orders";
            else label = "Order";
            break;
        case "order_payment":
        case "order_payments":
        case "payment":
        case "payments":
            if (plural) label = "Payments";
            else label = "Payment";
            break;
        case "user":
        case "users":
            if (plural) label = "Users";
            else label = "User";
            break;
        case "stock":
        case "stocks":
            if (plural) label = "Stocks";
            else label = "Stock";
            break;
        case "commodity":
        case "commodities":
            if (plural) label = "Commodities";
            else label = "Commodity";
            break;
        case "commodity_variety":
        case "commodity_varieties":
        case "commodities_varieties":
        case "variety":
        case "varieties":
            if (plural) label = "Commodity Varieties";
            else label = "Commodity Variety";
            break;
        case "catalogue":
        case "catalogues":
            if (plural) label = "Catalogues";
            else label = "Catalogue";
            break;
        case "bank":
        case "customer_bank":
        case "banks":
        case "customer_banks":
            if (plural) label = "Banks";
            else label = "Bank";
            break;
        case "document":
        case "customer_document":
        case "documents":
        case "customer_documents":
            if (plural) label = "Documents";
            else label = "Document";
            break;
        case "farmer":
        case "farmers":
            if (plural) label = "Farmers";
            else label = "Farmer";
            break;
        case "trader":
        case "traders":
            if (plural) label = "Traders";
            else label = "Trader";
            break;
        case "customer":
        case "customers":
            if (plural) label = "Customers";
            else label = "Customer";
            break;
        case "buyer":
        case "buyers":
            if (plural) label = "Buyers";
            else label = "Buyer";
            break;
        case "seller":
        case "sellers":
            if (plural) label = "Sellers";
            else label = "Seller";
            break;
        case "grader":
        case "graders":
            if (plural) label = "Graders";
            else label = "Grader";
            break;
        case "transporter":
        case "transporters":
            if (plural) label = "Transporters";
            else label = "Transporter";
            break;
        case "packager":
        case "packagers":
            if (plural) label = "Packagers";
            else label = "Packager";
            break;
        case "kissanmitra":
        case "kissanmitras":
            if (plural) label = "Kissan Mitras";
            else label = "Kissan Mitra";
            break;
        case "mandimitra":
        case "mandimitras":
            if (plural) label = "Mandi Mitras";
            else label = "Mandi Mitra";
            break;
        case "agent":
        case "agents":
            if (plural) label = "Agents";
            else label = "Agent";
            break;
        case "moderator":
        case "moderators":
            if (plural) label = "Moderators";
            else label = "Moderator";
            break;
        case "location":
        case "locations":
            if (plural) label = "Locations";
            else label = "Location";
            break;
        case "masterdata":
            label = "Master Data";
            break;
        case "marketintelligence":
            label = "Market Intelligence";
            break;
        case "security":
            label = "Security";
            break;
        case "parameter":
        case "parameters":
            if (plural) label = "Parameters";
            else label = "Parameter";
            break;
        // case "product":
        // case "products":
        // 	if (plural) label = "Products";
        // 	else label = "Product";
        // 	break;
        case "configuration":
        case "configurations":
            if (plural) label = "Configurations";
            else label = "Configuration";
            break;
        case "pricetype":
        case "pricetypes":
            if (plural) label = "Price Types";
            else label = "Price Type";
            break;
        default:
            if (_.isString(entity)) {
                if (entity.length === 1) label = toTitleCase(entity);
                else if (plural)
                    label = toTitleCase(
                        entity
                            .replace("enum_", "")
                            .replace(new RegExp("_", "g"), " ")
                            .replace(new RegExp("/", "g"), " By ")
                            .replace(new RegExp("&", "g"), " & ")
                    );
                else {
                    const new_entity = entity
                        .replace("enum_", "")
                        .replace(new RegExp("_", "g"), " ")
                        .replace(new RegExp("/", "g"), " By ")
                        .replace(new RegExp("&", "g"), " & ");
                    if (!_.isEqual(new_entity, entity)) {
                        label = new_entity
                            .split(" ")
                            .map((e) => getLabel(e))
                            .join(" ");
                    } else {
                        label = toTitleCase(entity.replace("enum_", ""));
                    }
                }
            }
            break;
    }
    return label;
};

export const getDBTableName = (value) => {
    let entity = "";
    switch (value) {
        case "state":
        case "enum_state":
            entity = "xref_country_state";
            break;
        case "district":
        case "enum_district":
            entity = "xref_state_district";
            break;
        case "city":
        case "enum_city":
            entity = "xref_district_city";
            break;
        default:
            entity = value;
            break;
    }
    return entity;
};
export const uuidColumn = (props = {}) => {
    return {
        Header: getLabel(props?.entity),
        accessor: props?.accessor || props?.entity,
        dbname:   props.entity + "_id",
        minWidth: props.width ?? 150,
        column: true,
        add: true,
        edit: true,
        view: true,
        searchable: props?.searchable ? props?.searchable : true,
        filterable: true,
        multiple: false,
        type: "uuid",
        span: props?.span || 6,

        rules: { required: "Required !" },
        Cell: (cell) =>    {
            
            return cell?.value && cell?.row?.original?.id ? (
                <>
                    {/* {console.log("props are", props?.entity, " =>",cell,props.field, cell?.value)}    */}
                    {props.link ? (
                        <Link
                            to={props.link.replace("#id", props.link_id ? cell?.row?.original?.[props.link_id] : cell?.row.original.id)}
                            className="text-info float-left"
                        >
                            {cell?.value ? (
                                props.field ? (
                                    cell?.row?.original?.[props.field]
                                ) : _.isString(cell?.value) ? (
                                    cell?.value
                                ) : (
                                    cell?.value?.name ?? cell?.value?.map((e) => e || "") ?? ""
                                )
                            ) : (
                                <span key={Math.random()} />
                            )}
                        </Link>
                    ) : (
                        <>
                            {/* {console.log("uuid values",cell?.row?.original,cell?.value,props?.entity, cell?.value?.[props.entity]?.name)} */}
                            {/* {props?.entity === "location" && cell?.value?.name ? `  ${cell?.value?.name} `: "" }  */}
                            {props?.entity === "catalogue_commission" && cell?.value ? ( ` ${cell?.value?.commission}% (${cell?.value?.days} days)` ) : "" } 
                            
                             {cell?.value ? (
                                props.field ? (
                                    cell?.row?.original?.[props.field]
                                ) :
                                 _.isString(cell?.value) ? (
                                    cell?.value
                                ) :
                                _.isObject(cell?.value) ?  (
                                    cell?.value?.name  
                                )
                                : _.isObject(cell?.value?.[props.entity]) ?

                                cell?.value?.[props.entity]?.name
                                :

                                (
                                    cell?.value?.name ?  cell?.value?.name : _.isArray(cell?.value) ?  cell?.value?.map((e) => props.enum_vehicle_weight_map[e]).join(", ") : ""
                                )
                            ) 
                            : (
                                <span key={Math.random()} />
                            )
                            }
                        </>
                    )}
                    {props?.entity === "export_shipping_line" && cell?.value?.export_shipping_line?.name ? ` (${cell?.value?.export_shipping_line?.name})` : ""}

                    {props?.entity === "exporter_data" && cell?.value?.commodity?.name ? ` (${cell?.value?.commodity?.name})` : ""}
                    {props?.entity === "export_order" && cell?.value?.location?.name ? ` (${cell?.value?.commodity?.name})` : ""}
                    {props?.entity === "export_freight" && cell?.value?.location?.name ? ` (${cell?.value?.commodity?.name})` : ""}
                    {/* {props?.entity === "commodity" && cell?.value?.commodity?.name ? ` (${cell?.value?.commodity?.name})` : ""} */}
                    {props?.entity === "commodity_variety" && cell?.value?.commodity?.name ? ` (${cell?.value?.commodity?.name})` : ""}
                    {/* {props?.entity === "customer" && cell?.value?.customer ? ` (${cell?.value?.customer?.name})` : ""} */}
                </>
            ) : (
                // <>                {console.log("props are 2", props?.entity, " =>", cell?.value, cell?.row)}   

                // </>

                cell?.value || ""
            )},
        ...props,
    };
};
export const linkColumn = (props = {}) => {
     return {
        Header: getLabel(props.accessor),
        minWidth: props.width ?? 150,
        column: true,
        add: true,
        edit: true,
        view: true,
        searchable: props?.searchable || true,
        type: "text",
        span: props.type === "uuid" ? 6 : props.span ?? 6,
        rules: { required: "Required !" },

        Cell: (cell) =>
            cell?.value && cell?.row?.original?.id ? (
                <>
                     {console.log("cell props are",props,props?.link,props?.link_id,cell?.row?.original?.[props.link_id], cell?.value , props.field ,
                     cell?.row?.original, cell?.row?.original?.[props.field])}    
                    {props.link ? (
                        <Link
                          to={props.link.replace("#id", props.link_id ? cell?.row?.original?.[props.link_id] : props?.check === "user" ? cell?.row?.original?.customer?.id :  cell?.row.original.id)}
                       // to={props.link.replace("#id", props.link_id ? cell?.row?.original?.[props.link_id]  :  cell?.row.original.id)}
                            className="text-info float-left"
                        >
                            {cell?.value ? (
                                props.field ? (
                                    cell?.row?.original?.[props.field]
                                ) : _.isString(cell?.value) ? (
                                    cell?.value
                                ) : (
                                    cell?.value?.name ?? ""
                                )
                            ) : (
                                <span key={Math.random()} />
                            )}
                            {cell?.value?.commodity ? ` (${cell?.value?.commodity?.name})` : ""}
                        </Link>
                    ) : (
                        <>
                            {cell?.value ? (
                                props.field ? (
                                    cell?.row?.original?.[props.field]
                                ) : _.isString(cell?.value) ? (
                                    cell?.value
                                ) : (
                                    cell?.value?.name ?? ""
                                )
                            ) : (
                                <span key={Math.random()} />
                            )}
                            {cell?.value?.commodity ? ` (${cell?.value?.commodity?.name})` : ""}
                        </>
                    )}
                    {/* {props?.entity === "commodity_variety" && cell?.value?.commodity?.name ? ` (${cell?.value?.commodity?.name})` : ""} */}
                    {/* {cell?.row?.original?.active ? <i className="fa fa-check text-success float-end m-1" /> : ""} */}
                    {cell?.row?.original?.photo_url ? (
                        <span className="float-end">
                            <ImageModal key="photo_url" url={cell?.row?.original?.photo_url} title={cell?.value} />
                        </span>
                    ) : (
                        ""
                    )}
                    {/* {cell?.row?.original?.document_url ? (
                        <span className="float-end">
                            <ImageModal key="document_url" url={cell?.row?.original?.document_url} title={cell?.value} />
                        </span>
                    ) : (
                        ""
                    )} */}
                    {/* {cell?.row?.original?.images ? (
                        <span className="float-end">
                        <ImageModal key="images" url={cell?.row?.original?.images} title={cell?.value} />
                        </span>
                    ) : (
                        ""
                    )} */}
                    {/* {cell?.row?.original?.videos ? (
                        <span className="float-end">
                            <ImageModal key="videos" url={cell?.row?.original?.videos} title={cell?.value} />
                        </span>
                    ) : (
                        ""
                    )}
                    {cell?.row?.original?.documents ? (
                        <span className="float-end">
                            <ImageModal key="documents" url={cell?.row?.original?.documents} title={cell?.value} />
                        </span>
                    ) : (
                        ""
                    )} */}
                </>
            ) : (
                cell?.value || ""
            ),
        ...props,
    };
};
export const numberColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        width: props.width ?? 120,
        minWidth: props.width ?? 50,
        maxWidth: props.width ?? 150,
        disableResizing: true,
        column: true,
        add: true,
        edit: true,
        view: true,
        filterable: false,
        min: props?.min || 0,
        max: 999999999,
        type: "number",
        alignment: " text-center ",
        rules: { required: props?.notRequired ? null : "Required !" },
        step: props?.type === "currency" ? 0.01 : 1,
        Cell: (cell) => (
            <div className={` float-start ${cell?.value ? "" : " text-warning "}`}>
                {props?.type === "currency" ? (
                    <NumberFormat
                        value={cell?.value?.toFixed(2)}
                        thousandsGroupStyle="lakh"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₹"}
                    />
                ) : props?.type === "date" ? (
                    moment(cell?.value).format(UI_DATETIME_FORMAT)
                ) : (
                    cell?.value || 0
                )}
                {props?.type === "weight" ? <span> Kg</span> : ""}
            </div>
        ),
        ...props,
    };
};
export const dateColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        width: props.width ?? 100,
        minWidth: props.width ?? 50,
        maxWidth: props.width ?? 150,
        disableResizing: true,
        alignment: " text-right ",
        column: true,
        add: true,
        edit: true,
        view: true,
        type: "date",
        fiterable: true,
        rules: { required: "Required !" },
        Cell: (cell) => (cell?.value ? moment(new Date(cell?.value)).format(UI_DATE_FORMAT) : ""),
        ...props,
    };
};
export const timeColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        width: props.width ?? 100,
        minWidth: props.width ?? 50,
        maxWidth: props.width ?? 150,
        disableResizing: true,
        alignment: " text-right ",
        column: true,
        add: true,
        edit: true,
        view: true,
        type: "time",
        fiterable: true,
        rules: { required: "Required !" },
        Cell: (cell) => (cell?.value ? moment(cell?.value).format(UI_TIME_FORMAT) : ""),
        ...props,
    };
};
export const datetimeColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        width: props.width ?? 150,
        minWidth: props.width ?? 50,
        maxWidth: props.width ?? 150,
        disableResizing: true,
        alignment: " text-right ",
        column: false,
        add: false,
        edit: false,
        view: false,
        type: "datetime",
        fiterable: true,
        rules: { required: "Required !" },
        Cell: (cell) => (cell?.value&&!props?.subFrom ? moment(cell?.value).format(UI_DATETIME_FORMAT) :  cell?.value&&props?.subFrom&&moment(cell?.value).diff(moment(new Date()), 'hours')>0 ?  moment(cell?.value).diff(moment(new Date()), 'hours')+" hrs" : "0"),
        ...props,
    };
};

// return( cell?.value&&!props?.subFrom ? moment(cell?.value).format(UI_DATETIME_FORMAT) 
// : 
// cell?.value&&props?.subFrom ? moment(new Date()).diff(moment(new Date()), 'days') : ""})),

export const confirmAction = (action, object, name = "Confirm") =>
    Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${name}!`,
    }).then((x) => x.value && action(object));

export const performAction = ({ action, object, success_msg = "Success", error_msg = "Failed", refetch = () => {}, onComplete = () => {} }) =>
    action(object)
        .then(({ data, error }) => {
            if (!error && !_.isEmpty(data)) onComplete(data);
            if (!error && !_.isEmpty(data) && success_msg)
                if (!error && !_.isEmpty(data))
                    // if (!error && !_.isEmpty(data) && success_msg) toast.success("success_msg", { autoClose: 2000 });
                    refetch();
            if (error && error_msg) toast.error(error_msg, { autoClose: 2000 });
        })
        .catch((e) => toast.error(e, { autoClose: 2000 }));

export const confirmPerformAction = ({
    action,
    object,
    name = "Confirm",
    success_msg = "Action Successful",
    title = null,
    error_msg = "Action Failed",
    refetch = () => {},
    onComplete = () => {},
}) =>
    Swal.fire({
        title: title ?  title :  "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, ${name}!`,
    }).then(
        (x) =>
            x.value &&
            action(object)
                .then(({ data, error }) => {
                    if (!error && !_.isEmpty(data)) onComplete(data);
                    if (!error && !_.isEmpty(data) && success_msg) toast.success(success_msg, { autoClose: 2000 });
                    if (!error && !_.isEmpty(data)) refetch();
                    if (error && error_msg) toast.error(error_msg, { autoClose: 2000 });
                })
                .catch((e) => toast.error(error_msg, { autoClose: 2000 }))
    );
export const booleanDisplay = (value) => (value ? <i className="fa fa-check text-success" /> : <i className="fa fa-times text-warning" />);
export const booleanColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        width: props.width ?? 150,
        minWidth: props.width ?? 50,
        maxWidth: props.width ?? 150,
        type: "boolean",
        disableResizing: true,
        column: true,
        add: true,
        edit: true,
        view: true,
        filterable: true,
        alignment: " text-center ",
        ...props,
    };
};
export const badgeColumn = (props = {}) => {
    return {
        Header: "Conditions",
        accessor: "@",
        type: "widget",
        column: true,
        add: false,
        edit: false,
        view: false,
        Cell: (cell) =>
            props?.booleans?.length > 0 ? (
                toTitleCase(props?.booleans?.filter((boolean) => _.get(cell?.row?.original || {}, boolean, false)).join(", "))
            ) : (
                <span key={Math.random()} />
            ),
        ...props,
    };
};
export const activeColumn = (props = {}) => {
    return {
        Header: "Status",
        accessor: "active",
        width: props.width ?? 100,
        minWidth: props.width ?? 50,
        maxWidth: props.width ?? 150,
        type: "active",
        disableResizing: true,
        column: false,
        add: false,
        edit: false,
        view: true,
        filterable: false,
        alignment: " text-center ",
        ...props,
    };
};
export const toTitleCase = (str) =>
    str
        ? str
              .replace(new RegExp("_", "g"), " ")
              .replace(new RegExp("/", "g"), "/")
              .replace(new RegExp("&", "g"), " & ")
              .toLowerCase()
              .split(" ")
              .map(function (word) {
                  return word.charAt(0).toUpperCase() + word?.slice(1);
              })
              .join(" ")
        : "";
export const defaultColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        type: "text",
        column: true,
        add: true,
        edit: true,
        view: true,
        searchable: true,

        minWidth: props.minWidth || 65,
        filterable:
            !props?.type ||
            ["text", "textarea", "map", "date", "datetime", "tag", "textarray", "email", "url", "image", "video", "document"].includes(
                props?.type
            )
                ? false
                : true,
        isSorted: false,
        isSortedDesc: false,
        rules: { required: "Required !" },
        ...props,
    };
};
export const fillColumn = (props = {}) => ({ add: true, edit: true, view: true, fillSpan: 9, ...props });

export const enumColumn = (props = {}) => {
    return {
        Header: getLabel(props.accessor),
        type: "select",
        column: true,
        add: true,
        edit: true,
        view: true,
        searchable: true,
        width:props?.width || 120,
        minWidth: 50,
        maxWidth: 200,
        disableResizing: true,
        filterable: true,
        isSorted: false,
        isSortedDesc: false,
        rules: { required: "Required !" },
        ...props,
    };
};
export const imageColumn = (props = {}) => {
    return {
        Header: props?.header ? props?.header : `${props?.type}  Upload`,
        accessor: "photo_url",
        type: props?.type || "image",
        column: false,
        add: true,
        edit: true,
        view: true,
        width: props?.width || 30,
        minWidth:props?.minWidth ||  30,
        maxWidth: props?.maxWidth || 30,
        skipSort: true,
        disableResizing: true,
        rules: { required: "Required !" },
        ...props,
    };
};

export const mapColumn = (props = {}) => {
    return {
        Header: "Address",
        accessor: props?.accessor ??  "address",
        searchable: false,
        column: false,
        add: true,
        edit: true,
        view: true,
        type: "map",
        rules: { required: "Required !" },
        ...props,
    };
};

export const formatOrderBy = (orderBy = [], columns) => {
    let order_by = {};
    let columnMap = {};
    columns?.map((e) => {
        columnMap[e.accessor] = e;
        return e;
    });

    if (orderBy.length > 0) {
        orderBy?.map((o) =>
            o?.id
                ? _.set(
                      order_by,
                      o.id.replace(".aggregate.", ".") + (columnMap && columnMap[o.id] && columnMap[o.id].type === "uuid" ? ".name" : ""),
                      o.desc ? "desc_nulls_last" : "asc_nulls_last"
                  )
                : false
        );
    }
    return order_by;
};

export const getParams = (url) => {
    // we'll store the parameters here
    let pathObj = {};

    const pathString = _.isString(url) ? url?.split("?")[0] : window?.location?.pathname?.slice(1);
    pathObj.pathString = pathString;
    // if path string exists
    if (pathString) {
        // split our query string into its component parts
        const args = pathString.split("/");
        pathObj.args = args;
        for (let i = 0; i < args.length; i++) {
            pathObj["arg_" + i] = args[i];
        }
    }

    // stuff after # is not part of query string, so get rid of it
    const queryString = (url ? url?.split("?")[1] : window?.location?.search?.slice(1))?.split("#")[0];
    if (queryString) {
        pathObj.queryString = queryString;
        pathObj.params = queryStringToJSON(queryString);
    }
    if (!_.isEmpty(pathObj.params)) pathObj = { ...pathObj, ...pathObj.params };
    if (!pathObj.filter_by) pathObj.filter_by = {};
    pathObj.filters = getFilterBy(pathObj.filter_by);

    return pathObj;
};

export const queryStringToJSON = (queryString) => {
    var pairs = queryString.split("&");
    var result = {};
    pairs.forEach(function (p) {
        var pair = p.split("=");
        var key = pair[0];
        var value = decodeURIComponent(pair[1] || "");
        if (key === "filter_by" && value) value = JSON.parse(value);

        if (result[key]) {
            if (Object.prototype.toString.call(result[key]) === "[object Array]") {
                result[key].push(value);
            } else {
                result[key] = [result[key], value];
            }
        } else {
            result[key] = value;
        }
    });

    return JSON.parse(JSON.stringify(result));
};

export const getFilterBy = (filters) => {
    let newObject = {};
    for (const [key, value] of Object.entries(filters)) {
          console.log("Key values are",key, value)
        if (_.isArray(value) && key.endsWith("_at") && value[0] && value[1]) {
            _.set(newObject, key.replace(".aggregate.", "."), { _gte: value[0], _lte: value[1] });
        } 
        else if (key=="eta") {
            // console.log("checking date",moment(value).add(1, "d")  >  moment(new Date()).add(1, "d") )
            // newObject[key] =  { _lte:  moment(value).add(1, "d") ,_gt:  "2022-04-05T08:35:46.241Z" };
            newObject[key] =  { _lte:  moment(value).add(1, "d") ,_gt:  moment().format() };
            // newObject[key] =  { _lte:  moment(value).add(1, "d"),_gte:  moment(value).subtract(2, "d")   };
            // newObject[key] =  { _gte:  moment(value).subtract(30, "d")  };
        }

        else if (value == '-N/A-') {
            delete newObject[key]
        }
        else if (value && value.id && value.name&&value.dbname&&value.dbfield) {
            console.log("checkasdfasdfasdf",value,value.dbname)
            newObject[  value.dbname  ] = { _eq: value[value.dbfield] };
        }
        else if (value && value.id && value.name&&value.distinctOn&&value?.distinctOnLabel) {
            console.log("checkasdfasdfasdf",value,value.name)
            // newObject.originport.name = { _eq: value.name };
            _.set(newObject,value?.distinctOnLabel,{ _eq: value.name } )
        }
        else if (value && value.id && value.name) {
                 
            newObject[key + "_id"] = { _eq: value.id };
        } else if (_.isArray(value) && !_.isEmpty(value) && value[0].id) {
            if (!newObject[key + "_id"]) newObject[key + "_id"] = { _in: _.uniq(value?.map((e) => e.id)) };
            else newObject[key + "_id"]._in.push(...value?.map((e) => e.id));
        } else if (_.isArray(value) && !_.isEmpty(value) && value[0].id) {
            if (!newObject[key + "_id"]) newObject[key + "_id"] = { _in: _.uniq(value?.map((e) => e.id)) };
            else newObject[key + "_id"]._in.push(...value?.map((e) => e.id));
        } else if (_.isArray(value) && !_.isEmpty(value) && value[0].value) {
            if (!newObject[key]) _.set(newObject, key.replace(".aggregate.", "."), { _in: _.uniq(value?.map((e) => e.value)) });
            else {
                newObject[key]._in.push(...value?.map((e) => e.value));
                _.set(newObject, key.replace(".aggregate.", "."), newObject[key]);
            }
        } else if (_.isArray(value) && !_.isEmpty(value)) {
            _.set(newObject, key.replace(".aggregate.", "."), { _in: value });
        } else if (_.isObjectLike(value)) {
            if (!_.isEmpty(value)) _.set(newObject, key.replace(".aggregate.", "."), value);
        } else if (_.isString(value) && (value.startsWith("%") || value.endsWith("%"))) {
            _.set(newObject, key.replace(".aggregate.", "."), { _ilike: value });
        } 
        else if (!_.isNull(value)) {
            _.set(newObject, key.replace(".aggregate.", "."), { _eq: value });
        }
    }
    // console.log("new object is", newObject);
    return newObject;
};

export const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input key={Math.random()} type="checkbox" ref={resolvedRef} {...rest} />;
});

export const SubmitButton = ({ processing, children, disabled }) => (
    <button className="btn btn-success btn-block " type="submit" disabled={processing || disabled}>
        {processing ? "Processing..." : children}
    </button>
);

export const getUrlPath = (key, value, url) => {
    let { args } = getParams(url);
    if (value) args[key] = value;
    else args = args?.slice(0, key);
    return "/" + args.join("/");
};

export const setUrlPath = (key, value, url) => {
    let { args } = getParams(url);
    args = args?.slice(0, key);
    if (value) args[key] = value;
    else args = args?.slice(0, key);
    return "/" + args.join("/");
};

export function useFormValidation(initialState, formState = undefined) {
    const [state, setState] = useState(initialState);

    return [
        state,
        (e) => {
            switch (e.target.name) {
                case "name":
                    if (!e.target.value) {
                        setState({ ...state, [e.target.name]: "Please fill in your name" });
                    } else {
                        setState({ ...state, [e.target.name]: "" });
                    }
                    break;
                case "email":
                    if (!e.target.value) {
                        setState({ ...state, [e.target.name]: "Please enter an email address" });
                    } else if (e.target.value && !validateEmail(e.target.value)) {
                        setState({ ...state, [e.target.name]: "Please enter a valid email address" });
                    } else {
                        setState({ ...state, [e.target.name]: "" });
                    }
                    break;
                case "password":
                    if (!e.target.value) {
                        setState({ ...state, [e.target.name]: "Kindly enter a password" });
                    } else if (e.target.value && e.target.value.length < 8) {
                        setState({ ...state, [e.target.name]: "Password should be at least 8 characters long" });
                    } else if (e.target.value && e.target.value.length >= 8 && !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(e.target.value)) {
                        setState({ ...state, [e.target.name]: "Password should contain an uppercase, lowercase and a number" });
                    } else {
                        setState({ ...state, [e.target.name]: "" });
                    }
                    break;
                case "loginPassword":
                    if (!e.target.value) {
                        setState({ ...state, [e.target.name]: "Kindly enter a password" });
                    } else {
                        setState({ ...state, [e.target.name]: "" });
                    }
                    break;
                case "confirmPassword":
                    if (!e.target.value) {
                        setState({ ...state, [e.target.name]: "Please re-enter your password" });
                    } else if (formState && formState.password && e.target.value !== formState.password) {
                        setState({ ...state, [e.target.name]: "Password does not match" });
                    } else {
                        setState({ ...state, [e.target.name]: "" });
                    }
                    break;
                default:
                    break;
            }
        },
    ];
}

export const FullMap = withGoogleMap(({ data = {}, onMarkerDragEnd = () => {}, defaultZoom = 15 }) => {
    if (!data["lat"]) data["lat"] = 8.165975;
    if (!data["lng"]) data["lng"] = 77.445312;
    if (!data["address"]) data["address"] = "";
    let location = { address: "", lat: 8.165975, lng: 77.445312, ...data };
    // console.log("locaitno is",location);
    return (
        <GoogleMap defaultZoom={defaultZoom} defaultCenter={{ lat: location?.lat, lng: location?.lng }}>
            <InfoWindow position={{ lat: (location?.lat || 8.165975) + 0.0018, lng: location?.lng || 77.445312 }}>
                <span>{location?.address || ""}</span>
            </InfoWindow>
            <Marker
                draggable={true}
                onDragEnd={onMarkerDragEnd}
                position={{ lat: location?.lat || 8.165975, lng: location?.lng || 77.445312 }}
            />
        </GoogleMap>
    );
});

export const getLocationDataFromGeoCode = async (data = {}) => {
    let new_location = {
        address: "",
        city: "",
        state: "",
        country: "",
        postcode: "",
        lat: 8.165975,
        lng: 77.445312,
        ...data,
    };
    try {
        const geocodeData = await Geocode.fromLatLng(new_location?.lat || 8.165975, new_location?.lng || 77.445312);
        const result = geocodeData.results[0] ?? {};
        new_location = {
            address: result?.formatted_address,
            lat: result?.geometry?.location?.lat ?? null,
            lng: result?.geometry?.location?.lng ?? null,
            ...parseLocationData(result?.address_components ?? []),
        };
        return new_location;
    } catch (error) {
        console.error(error);
        return new_location;
    }
};
export const getLocationDataFromGeoAddress = async (data = {}) => {
    let new_location = {
        address: data,
        city: "",
        state: "",
        country: "",
        postcode: "",
    };
    try {
        const geocodeData = await geocodeByAddress(new_location?.address);
        const result = geocodeData[0] ?? {};
        new_location = {
            address: result?.formatted_address,
            lat: result?.geometry?.location?.lat() ?? null,
            lng: result?.geometry?.location?.lng() ?? null,
            ...parseLocationData(result?.address_components ?? []),
        };
        return new_location;
    } catch (error) {
        console.error(error);
        return new_location;
    }
};

export const parseLocationData = (address_components = []) => {
    let new_location = {
        area: "",
        city: "",
        district: "",
        state: "",
        country: "",
        postcode: "",
    };
    try {
        for (let i = 0; i < address_components.length; i++) {
            const address_component = address_components[i];
            if (address_component?.types?.includes("postal_code")) new_location.postcode = address_component?.long_name;
            else if (address_component?.types?.includes("country")) new_location.country = address_component?.short_name;
            else if (address_component?.types?.includes("administrative_area_level_1")) new_location.state = address_component?.long_name;
            else if (address_component?.types?.includes("administrative_area_level_2")) new_location.district = address_component?.long_name;
            else if (address_component?.types?.includes("locality")) new_location.city = address_component?.long_name;
            else if (address_component?.types?.includes("sublocality")) {
                if (new_location.area) new_location.area = new_location.area + ", " + address_component?.long_name;
                else new_location.area = address_component?.long_name;
            }
        }
        return new_location;
    } catch (error) {
        console.error(error);
        return new_location;
    }
};

export const CardBox = ({ component = <span key={Math.random()} />, header = "", footer = "", ...rest }) => (
    <div className="card" {...rest}>
        {header && <div className="card-header p-2 h6 text-dark">{header}</div>}
        {component && <div className="card-body p-2">{component}</div>}
        {footer && <div className="card-footer p-1">{footer}</div>}
    </div>
);
export const RowBox = ({ component = <span key={Math.random()} />, header = "", footer = "", col = "col-12", row = "row", ...rest }) => (
    <div className={row} {...rest}>
        {header && <div className={col}>{header}</div>}
        {component && <div className={col}>{component}</div>}
        {footer && <div className={col}>{footer}</div>}
    </div>
);
export const DataCardBox = ({
    component: Component = <span key={Math.random()} />,
    data = [],
    metrics,
    header = "",
    footer = "",
    toggle = false,
    ...rest
}) => {
    const [showData, setShowData] = useState(false);

    return (
        <CardBox
            key={Math.random()}
            header={
                !toggle ? (
                    <div className="h6 p-1">
                        {header}
                        {(data?.length > 0 || metrics?.length > 0) && (
                            <span className="btn btn-sm btn-light pull-right m-1" onClick={() => setShowData(!showData)}>
                                {showData ? <i className="fa fa-pie" size={15} /> : <i className="fa fa-grid" size={15} />}
                            </span>
                        )}
                    </div>
                ) : (
                    ""
                )
            }
            component={
                toggle || showData ? (
                    <ListCard
                        metrics={
                            metrics ??
                            data.map((e) => ({
                                ...e,
                                key: _.isArray(e.name) ? e.name.join(" + ") : e.name,
                                name: _.isArray(e.name) ? e.name.join(" + ") : e.name,
                                data: e.value,
                            }))
                        }
                        item={(m) => (
                            <>
                                <span className="pull-left ">{getLabel(m.name)}</span>
                                <span className="pull-right text-center">
                                    {m.prefix || ""}
                                    {_.isInteger(m.value) ? m.value : m.value?.toFixed(1)}
                                    {m.suffix || ""}
                                </span>
                            </>
                        )}
                    />
                ) : (
                    <Component data={data.map((e) => ({ ...e, key: e.name, data: e.value }))} {...rest} />
                )
            }
            footer={footer}
        />
    );
};

export const TableCard = ({ metrics = [{ entity: "location", count: 0 }], header = "", footer = "" }) => {
    return (
        <div className="card">
            {header && <div className="card-header p-2 h6">{header}</div>}
            <div className="card-body p-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Metric</th>
                            <th scope="col">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {metrics?.map((m, i) => (
                            <tr key={i}>
                                <td>{getLabel(m.entity, true)}</td>
                                <td>{m.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {footer && <div className="card-footer p-1">{footer}</div>}
        </div>
    );
};
export const QueryCounter = ({ entity, where = {}, ...rest }) => <CountUp end={useTotalQuery({ entity, where }) || 0} {...rest} />;
export const ListCard = ({
    metrics = [],
    header = "",
    footer = "",
    item = (m) => (
        <>
            {m.title || m.entity ? <span className="pull-left ml-2">{m.title ?? getLabel(m.entity, true)}</span> : ""}
            <span className="pull-right text-center">
                <QueryCounter {...m} />
            </span>
        </>
    ),
    small = false,
}) => {
    if (metrics.length > 0)
        return (
            <div className="p-0 m-0 text-dark">
                {header && <div className="card-header p-2 h6">{header}</div>}
                <div className="card-body list-group p-0 h7">
                    {metrics?.map((m, i) =>
                        m.link ? (
                            <Link
                                key={i}
                                className={"text-info m-1 " + (small ? " p-0 pl-1 pr-1 " : " b-r-8  list-group-item list-group-item-action ")}
                                to={m.link || "#"}
                            >
                                {item(m)}
                            </Link>
                        ) : (
                            <div key={i} className={"m-1 " + (small ? " p-0 pl-1 pr-1 " : " b-r-8  list-group-item list-group-item-action ")}>
                                {item(m)}
                            </div>
                        )
                    )}
                </div>
                {footer && <div className="card-footer p-1">{footer}</div>}
            </div>
        );
    else return <span key={Math.random()} />;
};

export const getMenuItems = () => [
    {}, // This is to align the menu - dont delete
    {
        role:"moderator",
        title: getLabel("dashboard", true),
        link: "dashboard",
        icon: <FcBarChart />,
        children: [],
    },
    {
        role:"moderator",
        title: getLabel("trade", true),
        link: "trade",
        icon: <FcBusiness />,
        children: [
            { title: getLabel("dashboard", true), link: "dashboard", icon: <FcBarChart />, children: [] },
            { title: getLabel("customer", true), link: "customer", icon: <FcBusinessman />, children: [] },
            { title: getLabel("location", true), link: "location", icon: <FcDepartment />, children: [] },
            { title: getLabel("vehicle", true), link: "vehicle", icon: <FcNeutralTrading />, children: [] },
            { title: getLabel("jobcard", true), link: "jobcard", icon: <FcGlobe />, children: [] },
        ],
    },

    {
        role:"moderator",
        title: getLabel("marketintelligence", true),
        link: "marketintelligence",
        icon: <FcFinePrint />,
        children: [
            { title: getLabel("dashboard", true), link: "dashboard", icon: <FcBarChart />, children: [] },
            { title: getLabel("rate", true), link: "rate", icon: <FcCurrencyExchange />, children: [] },
            { title: getLabel("dataCenter", true), link: "dataCenter", icon: <FcDatabase />, children: [] },
            { title: getLabel("stock", true), link: "stock", icon: <FcDatabase />, children: [] },
        ],
    },
    {

        role:"moderator",
        title: getLabel("marketplace", true),
        link: "marketplace",
        icon: <FcSalesPerformance />,
        children: [
            { title: getLabel("dashboard", true), link: "dashboard", icon: <FcBarChart />, children: [] },
            { title: getLabel("offer", true), link: "offer", icon: <FcRules />, children: [] },
            { title: getLabel("order", true), link: "order", icon: <FcTodoList />, children: [] },
            { title: getLabel("export", true), link: "export", icon: <FcRules />, children: [] },
            { title: getLabel("Freight Listing", true), link: "freight_forwarder", icon: <FcTodoList />, children: [] },
            { title: getLabel("Freight Order", true), link: "freight_order", icon: <FcTodoList />, children: [] },
            { title: getLabel("shipping", true), link: "shipping_line", icon: <FcTodoList />, children: [] },
            { title: getLabel("Source Finance", true), link: "source_finance", icon: <FcTodoList />, children: [] },
            { title: getLabel("Factoring Finance", true), link: "factoring_finance", icon: <FcTodoList />, children: [] },
        ],
    },
    {   role:"moderator",
        title: getLabel("masterdata", true),
        link: "masterdata",
        icon: <FcDataConfiguration />,
        children: [
            { title: getLabel("dashboard", true), link: "dashboard", icon: <FcBarChart />, children: [] },
            { title: getLabel("parameter", true), link: "parameter", icon: <FcTreeStructure />, children: [] },
            { title: getLabel("commodity", true), link: "commodity", icon: <FcNeutralTrading />, children: [] },
            { title: getLabel("commodity_variety", true), link: "commodity_variety", icon: <FcParallelTasks />, children: [] },
            { title: getLabel("catalogue", true), link: "catalogue", icon: <FcMultipleInputs />, children: [] },
            { title: getLabel("commission", true), link: "commission", icon: <FcMultipleInputs />, children: [] },
        ],
    },
    {   role:"moderator",
        title: getLabel("management", true),
        link: "management",
        icon: <FcBusinessContact />,
        children: [
            { title: getLabel("dashboard", true), link: "dashboard", icon: <FcBarChart />, children: [] },
            { title: getLabel("contact", true), link: "contact", icon: <FcBusinessContact />, children: [] },
            { title: getLabel("user", true), link: "user", icon: <FcConferenceCall />, children: [] },
            { title: getLabel("Organization", true), link: "user_organization", icon: <FcConferenceCall />, children: [] },
            { title: getLabel("Inquiry", true), link: "inquiries", icon: <FcConferenceCall />, children: [] },
            { title: getLabel("quotations", true), link: "quotations", icon: <FcConferenceCall />, children: [] },
            { title: getLabel("activity", true), link: "activity", icon: <FcInTransit />, children: [] },
            { title: getLabel("nbfc", true), link: "nbfc", icon: <FcInTransit />, children: [] },
            { title: getLabel("payments", true), link: "exporter_payment", icon: <FcInTransit />, children: [] },
        ],
    },
    {   role:"moderator",
        title: getLabel("Commodity Bidding", true),
        link: "management",
        icon: <FcBusinessContact />,
        children: [
            { title: getLabel("Bid Offers", true), link: "bidding_offer", icon: <FcBarChart />, children: [] },
            { title: getLabel("Bid Orders", true), link: "bidding_order", icon: <FcBusinessContact />, children: [] },
        ],
    },
    // {
    //     role:"exporter",
    //     title: getLabel("Dashboard", true),
    //     link: "web/exporterDashboard/list",
    //     icon: <FcDocument />,
    //     children: [],
    // },

    {
        role:"exporter",
        title: getLabel("Importer Discovery", true),
        link: "web/exporterImporterDirectory/list",
        icon: <FcViewDetails />,
        children: [ ],
    },
    {
        role:"exporter",
        title: getLabel("Supplier Discovery", true),
        link: "web/supplierDirectory/list",
        icon: <FcViewDetails />,
        children: [ ],
    },
    // {
    //     role:"exporter",
    //     title: getLabel("Discovery", true),
    //     link: "web",
    //     icon: <FcMoneyTransfer />,
    //     children: [ 
    //         {  title: getLabel("supplier Discovery", true), link: "supplierDirectory", icon: <FcBarChart />, children: [] },
    //         {  title: getLabel("Importer Discovery", true), link: "exporterImporterDirectory", icon: <FcBarChart />, children: [] },
                   
    
    //               ],
    //    },

        {
        role:"exporter",
        title: getLabel("Sourcing Finance", true),
        link: "web",
        icon: <FcMoneyTransfer />,
        children: [ 
                    {  title: getLabel("Eligibility check", true), link: "source_finance", icon: <FcBarChart />, children: [] },
                    {  title: getLabel("Proposal", true), link: "coming_soon1", icon: <FcBarChart />, children: [] },
                    {  title: getLabel("Agreement ", true), link: "coming_soon2", icon: <FcBarChart />, children: [] },
                    { title: getLabel("Po", true), link: "exporter/list", icon: <FcBarChart />, children: [] },
    
                  ],
       },

       {
        role:"exporter",
        title: getLabel("Ocean Freight", true),
        link: "web", 
        // link: "web/user_freight_forwarder/list",
        icon: <FcWorkflow />,
        children: [
            {  title: getLabel("Freight Listing", true), link: "user_freight_forwarder", icon: <FcBarChart />, children: [] },
            {  title: getLabel("Inquiries", true), link: "inquiries", icon: <FcBarChart />, children: [] },
            // { title: getLabel("Quotations", true), link: "quotations", icon: <FcBarChart />, children: [] },
            { title: getLabel("Manage Order", true), link: "user_manage_order", icon: <FcAutomatic />, children: [] },

    // {
    //     role:"exporter",
    //     title: getLabel("Manage Order", true),
    //     link: "web/user_manage_order/list",
    //     icon: <FcAutomatic />,
    //     children: [],
    // },
        ],
    },
    {
        role:"exporter",
        title: getLabel("Factoring Finance", true),
        link: "web",
        icon: <FcMoneyTransfer />,
        children: [
            {  title: getLabel("Eligibility check", true), link: "factoring_finance", icon: <FcBarChart />, children: [] },
            {  title: getLabel("proposal", true), link: "coming_soon5", icon: <FcBarChart />, children: [] },
            {  title: getLabel("Agreement ", true), link: "coming_soon3", icon: <FcBarChart />, children: [] },
            // { title: getLabel("Bl ShippingLine Invoice", true), link: "coming_soon4", icon: <FcBarChart />, children: [] },

        ],
    },

    {
        role:"exporter",
        title: getLabel("Commodity Bidding", true),
        link: "web",
        icon: <FcMoneyTransfer />,
        children: [
            {  title: getLabel("Commodity Bidding", true), link: "exp_bidding", icon: <FcBarChart />, children: [] },
            {  title: getLabel("Bidding orders", true), link: "exp_bidding_order", icon: <FcBarChart />, children: [] },
            // {  title: getLabel("Agreement ", true), link: "coming_soon3", icon: <FcBarChart />, children: [] },
            // { title: getLabel("Bl ShippingLine Invoice", true), link: "coming_soon4", icon: <FcBarChart />, children: [] },

        ],
    },

    // {
    //     role:"exporter",
    //     title: getLabel("Bidding", true),
    //     link: "web/exp_bidding/list",
    //     icon: <FcBusinessman />,
    //     children: [],
    // },

    {
        role:"exporter",
        title: getLabel("My Account", true),
        link: "web/user/list",
        icon: <FcBusinessman />,
        children: [],
    },

        {
            role:"supplier",
            title: getLabel("purchase Order", true),
            link: "web/supplier/list",
            icon: <FcViewDetails />,
            children: [],
        },

       {
            role:"supplier",
            title: getLabel("invoice History", true),
            link: "web/InvoiceHistory/list",
            icon: <FcNews />,
            children: [],
        },

        {
            role:"supplier",
            title: getLabel("Payment", true),
            link: "web/supplier_payment/list",
            icon: <FcMoneyTransfer />,
            children: [],
        },

        {
            role:"supplier",
            title: getLabel("My Account", true),
            link: "web/user/list",
            icon: <FcBusinessman />,
            children: [],
        },

        {
            role:"freight_forwarder",
            title: getLabel("Manage Listing", true),
            link: "web/freight_forwarder/list",
            icon: <FcViewDetails />,
            children: [],
        },
        {
            role:"freight_forwarder",
            title: getLabel("Manage Order", true),
            link: "web/manage_order/list",
            icon: <FcFinePrint />,
            children: [],
        },
        {
            role:"freight_forwarder",
            title: getLabel("OCEAN FREIGHT", true),
            link: "web/other_freight_listing/list",
            icon: <FcFinePrint />,
            children: [],
        },
        {
            role:"freight_forwarder",
            title: getLabel("INQUIRY", true),
            link: "web/inquiry/list",
            icon: <FcFinePrint />,
            children: [],
        },
        {
            role:"freight_forwarder",
            title: getLabel("My Account", true),
            link: "web/user/list",
            icon: <FcBusinessman />,
            children: [],
        },
 


        {
            role:"importer",
            title: getLabel("Exporter Discovery", true),
            link: "web/importer/list",
            icon: <FcViewDetails />,
            children: [],
        },

        {
            role:"importer",
            title: getLabel("Freight Discovery", true),
            link: "web/OceanFreightImp/list",
            icon: <FcBusinessman />,
            children: [],
        },
        {
            role:"importer",
            title: getLabel("Commodity Bidding", true),
            link: "web",
            icon: <FcMoneyTransfer />,
            children: [
                {  title: getLabel("New Bid", true), link: "imp_new_bidding", icon: <FcBusinessman />, children: [] },
                {  title: getLabel("Bidding Result", true), link: "imp_bidding", icon: <FcBusinessman />, children: [] },
                // {  title: getLabel("Bidding Orders", true), link: "imp_bidding_order", icon: <FcBusinessman />, children: [] },
    
            ],
        },
        // {
        //     role:"importer",
        //     title: getLabel("New Bid", true),
        //     link: "web/imp_new_bidding/list",
        //     icon: <FcBusinessman />,
        //     children: [],
        // },
        // {
        //     role:"importer",
        //     title: getLabel("Bidding Status", true),
        //     link: "web/imp_bidding/list",
        //     icon: <FcBusinessman />,
        //     children: [],
        // },

        {
            role:"importer",
            title: getLabel("My Account", true),
            link: "web/user/list",
            icon: <FcBusinessman />,
            children: [],
        },


 
];

export const getOfferFields = () => [
    { value: "number_of_units", type: "weight" },
    // { value: "price_per_unit", type: "currency" },
    { value: "amount", type: "currency", label: getLabel("commodity_charges") },
];
export const getOrderPriceFields = () => [
    { value: "offered_price", type: "currency" },
    { value: "requested_price", type: "currency" },
    { value: "negotiated_price", type: "currency" },
];

export const getOrderFields = () => [
    // { value: "number_of_units", type: "weight" },
    // { value: "price_per_unit", type: "currency" },
    { value: "amount", type: "currency", label: getLabel("commodity_charges") },
    { value: "grading_charges", type: "currency" },
    { value: "packaging_charges", type: "currency" },
    { value: "gst_charges", type: "currency", label:"GST Charges" },
  
    
    { value: "supplier_price", type: "currency" },
    { value: "buyer_selling_rate_per_kg", type: "currency" },
    { value: "buyer_selling_grn_weight", type: "currency", label:"Buyer Selling GRN Weight" },
    { value: "buyer_selling_price", type: "currency" },
    { value: "purchase_amount", type: "currency", label:"Purchase Amount" },

    { value: "loading_charges", type: "currency" },
    { value: "unloading_charges", type: "currency" },
    { value: "jobcards", type: "nested_currency", label:"Transportation Cost" },

    { value: "supplier_commission", type: "currency" },
    { value: "buyer_commission", type: "currency" },
    { value: "commission_amount", type: "currency" },

    { value: "transportation_charges", type: "currency" },

    { value: "buyer_amount", type: "currency", label:"GRV Receivable" },
    { value: "grv_commission", type: "currency", label:"GRV Commission" },
    { value: "grv_profit", type: "currency", label: "GRV Margin" },
    { value: "supplier_amount", type: "currency", label:"GRV Payable" },




    // { value: "total_amount", type: "currency" },
];

export const getChangeHistoryFields = () => [
    { value: "number_of_units", type: "weight" },
    { value: "price_per_unit", type: "currency" },
    { value: "amount", type: "currency", label: getLabel("commodity_charges") },
    { value: "grading_charges", type: "currency" },
    { value: "transportation_charges", type: "currency" },
    { value: "packaging_charges", type: "currency" },
    { value: "gst_charges", type: "currency" },
    { value: "total_amount", type: "currency" },
];

export const getPaymentFields = () => [
    { value: "amount", type: "currency" },
    { value: "pg_collection_fee", type: "currency" },
    { value: "pg_tax_amount", type: "currency" },
    { value: "refunded_amount", type: "currency" },
];

export const getLoadingFields = () => [
    { value: "number_of_bags" },
    { value: "rate_per_kg", type: "currency" },
    { value: "net_weight", type: "weight" },
    { value: "purchase_amount", type: "currency" },

    { value: "loading_charges", type: "currency" },
    { value: "grading_charges", type: "currency" },
    { value: "commission_charges", type: "currency" },
    { value: "extra_charges", type: "currency" },
    { value: "transportation_charges", type: "currency" },
    { value: "purchase_commission", type: "currency" },
    { value: "total_price", type: "currency" },
];

export const getJobCardFields = () => [
    { value: "order",type:"nested_currency_obj", nested_value:"purchase_amount", label:"purchase Amount" },
    { value: "order",type:"nested_currency_obj", nested_value:"buyer_selling_price", label:"Buyer Selling Price" },
    { value: "order",type:"nested_currency_obj", nested_value:"buyer_commission", label:"Buyer Commission" },
    { value: "order",type:"nested_currency_obj", nested_value:"unloading_charges", label:"Unloading Charges" },

    { value: "transportation_cost", type: "currency" },
    // { value: "purchase", type: "currency" },
    { value: "revenue", type: "currency" },
    { value: "profit", type: "currency" },
  
   
];

export const getUnLoadingFields = () => [
    { value: "number_of_bags" },
    { value: "rate_per_kg", type: "currency" },
    { value: "net_weight", type: "weight" },
    { value: "sales_amount", type: "currency" },
    { value: "unloading_charges", type: "currency" },
    { value: "grading_charges", type: "currency" },
    { value: "commission_charges", type: "currency" },
    { value: "extra_charges", type: "currency" },
    { value: "transportation_charges", type: "currency" },
    { value: "sales_commission", type: "currency" },
    { value: "sales_revenue", type: "currency" },
];

export const getWeighingFields = () => [
    { value: "weighing_charges", type: "currency" },
    { value: "empty_weight", type: "weight" },
    { value: "loaded_weight", type: "weight" },
    { value: "product_weight", type: "weight" },
];

export const getCalculatedRow = (object, { value, label, type, nested_value = null }) => {
    //    console.log("object is", object, value, label, type);
    if (object["order_type"] === "on_commission" && value === "supplier_commission") return;
 
    return object?.[value] ? (
        <div key={Math.random()} className="p-1">
            <span className="text-muted">{label ?? getLabel(value)}</span>

            <span className="float-end h6">
                {type === "currency" ? (
                    <NumberFormat
                        value={object[value]?.toFixed(2)}
                        thousandsGroupStyle="lakh"
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₹"}
                    />
                ) : type === "date" ? (
                    moment(object[value]).format(UI_DATETIME_FORMAT)
                ) :
                type === "nested_currency" ?

                <NumberFormat
                value={object[value]?.length>0&&(object[value][0]["transportation_cost"]?.toFixed(2) || 0)}
                thousandsGroupStyle="lakh"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
            />

                :

                type === "nested_currency_obj" ?

                <NumberFormat
                value={object[value][nested_value]?.toFixed(2) || 0}
                thousandsGroupStyle="lakh"
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹"}
            />

            :
                // _.isArray(object[value]) ?
                //    object[value][0]["transportation_cost"]
                // :
                
                (
                    object[value] || 0
                )}
                {type === "weight" ? <span> Kg</span> : ""}
            </span>
        </div>
    ) : (
        <span key={Math.random()} />
    );
};

export const getCalculatedRows = (object, fields, label) =>{
    
    // console.log("object check ",object, fields,label)
     return fields?.length > 0 ? (
        <div className="card-body p-2">
            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{label ?? "Commercials"}</div>
            {fields.map((field) => getCalculatedRow(object ?? {}, field))}
        </div>
    ) : (
        <span key={Math.random()}></span>
    );}

export const getPastDays = function (days = 90) {
    for (var a = [], d = moment().subtract(days, "days"); d <= moment(); d.add(1, "day")) a.push(d.format(DB_DATE_FORMAT));
    return a;
};

export const getDateWhere = (days) => ({
    date: { _gte: moment().subtract(days, "days").format(DB_DATE_FORMAT) },
});

export const getDaysBetween = function (s, e) {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) a.push(new Date(d));
    return a;
};

export const getUniqValues = (array, field) =>
    _.uniq(array?.reduce((array, item) => [...array, field ? item[field] : item].filter((x) => x !== null), []));

export const 
InvoiceButton = ({ title = "INVOICE", object = {}, id_token, id = "", type = null }) => {

    return (
        <button
            className="btn btn-sm btn-primary float-end m-1"
            onClick={() =>
                object?.id ? SendRequest({showTitle:type, docType:"pdf", "method":"POST"  ,"subUrl":"/user/sendPdf"  , "body": { order_id: object?.id, type: type },"id_token":id_token, message:`Order PO Sucessfully Created!` }) 
                             :
                             SendRequest({showTitle:type, docType:"pdf", "method":"POST"  ,"subUrl":"/user/sendJobcardPdf"  , "body": { jobcard_id: id, jobcard_type: type },"id_token":id_token, message:"Jobcard PO Sucessfully Created!"}) 
                             .catch((error) => console.error("ERROR ", error))
            }
        >
            {title}
        </button>
    );
};

export const useDynamicHeightField = (element, value) => {
    useEffect(() => {
        if (!element) return;
        element.current.style.height = "auto";
        element.current.style.height = element.current.scrollHeight + "px";
    }, [element, value]);
};
