import React from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { DetailInfo } from "../../../../../components/common/DetailInfo";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();

	let entity ="export_order_payment";
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity:"export_order_payment", parent_id, solution }),
	});

	// console.log("OBJECT ", object,useEntityFields({ entity:"export_order_payment", parent_id, solution}))

	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			entity={"customer"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				{
					label: getLabel("order", true),
					value: object?.active_orders?.aggregate?.count || 0,
					total: object?.total_orders?.aggregate?.count || 0,
				},
			 
			]}
			tab_options={[
				{
					...useEntityFields({ entity: "export", parent_id, solution }),
					label: getLabel("export_order", true),
					value: "export_order",
                    disableAdd:true,
					component: DataTable,
					filters: { id: { _eq: object?.export_order?.id } },
					overrideValues: { id: object?.export_order?.id  },
					skipColumns: ["user"],
				},
				// {
				// 	...useEntityFields({ entity: "offer", parent_id, solution }),
				// 	label: getLabel("listing", true),
				// 	value: "listing",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },
				// {
				// 	...useEntityFields({ entity: "location", parent_id, solution }),
				// 	label: getLabel("location", true),
				// 	value: "location",
				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },

				// {
				// 	...useEntityFields({ entity: "exporter_data ", parent_id, solution }),
				// 	label: getLabel("exporter", true),
				// 	value: "exporter_data",
				// 	component: DataTable,
				// 	filters: { user_id: { _eq: parent_id } },
				// 	overrideValues: { user_id: parent_id },
				// 	skipColumns: ["customer"],
				// },

			]}
		/>
	);
};

export default Detail;
