import _ from "lodash";
import React from "react";

export const DisplayTags = (options = []) =>
    options.length > 0 ? (
        <div className="border">
            {options?.map((tag, index) => (
                <div key={index} className="badge" DisplayTags>
                    <span className="">{tag}</span>
                </div>
            ))}
        </div>
    ) : (
        <span key={Math.random()} />
    );

const Tags = React.forwardRef((props, ref) => {
    const { value = [], onChange = () => {} } = props;
    return (
        <>
            {value.length > 0 && (
                <div className="border">
                    {value?.map((tag, index) => (
                        <button key={index} className="btn btn-sm btn-light m-1" onClick={() => onChange(value.filter((_, i) => i !== index))}>
                            <span className="">{tag}</span>
                            <span className="m-0 ml-2 p-0 text-danger">
                                <i className="fa fa-times fa-sm p-0 m-0" />
                            </span>
                        </button>
                    ))}
                </div>
            )}
            <input
                ref={ref}
                type="text"
                className="form-control mb-2"
                placeholder="Type and Enter Key to Add Tags; Click Tag Button to Remove;"
                onKeyUp={(event) => {
                    event.preventDefault();
                    if (event.key === "Enter" && event.target.value !== "") {
                        onChange(_.uniq([...value, event.target.value]));
                        event.target.value = "";
                    }
                }}
            />
        </>
    );
});
export default Tags;
