import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({
        id: parent_id,
        ...useEntityFields({ entity, parent_id, solution }),
    });

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            breadcrumbAddButtons={["order"]}
            breadcrumbAddOverrides={{ offer_id: object?.id }}
            breadcrumbSkipColumns={["offer"]}
            component={DetailInfo}
            entity={entity}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            metric_cards={[]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "order", parent_id, solution }),
                    label: getLabel("order", true),
                    value: "order",
                    component: DataTable,
                    filters: { offer_id: { _eq: parent_id } },
                    overrideValues: { offer_id: parent_id },
                    skipColumns: ["offer"],
                    showList: true,
                },
            ]}
        />
    );
};

export default Detail;
