import _ from "lodash";
import moment from "moment";
import React, { useContext } from "react";
import { DataTable } from "../../../components/common/DataTable";
import { PageContainer } from "../../../components/common/PageContainer";
import { GeneralContext } from "../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields } from "../../../helpers";

const List = () => {
    const { arg_2: page, arg_3: tab } = getParams();

    const { enum_catalogue_type, enum_commodity_type } = useContext(GeneralContext);
    const commodity = useEntityFields({ entity: "commodity", solution: "masterdata" });
    const commodityVariety = useEntityFields({ entity: "commodity_variety", solution: "masterdata" });
    const catlogue = useEntityFields({ entity: "catalogue", solution: "masterdata" });

    let FieldsArray = [commodity, commodityVariety, catlogue];
    let enums = [enum_commodity_type, enum_commodity_type, enum_catalogue_type];

    const override_fields = {};
    if (page !== "list") return <span key={Math.random()} />;

    return FieldsArray.map((e, i) => (
        <PageContainer
            breadcrumbTitle={getLabel(e?.entity, true)}
            breadcrumbArg={3}
            tab_arg={4}
            tab_options={(() => {
                let tab_options = [];
                switch (tab) {
                    case "role":
                        tab_options = !_.isArray([enums[i]])
                            ? []
                            : enums[i]?.map((e) => ({
                                  ...e,
                                  ...override_fields,
                                  overrideValues: {
                                      ...(e?.overrideValues || {}),
                                      ...(override_fields?.overrideValues || {}),
                                  },
                                  label: e?.comment,
                                  value: e?.value,
                                  component: DataTable,
                                  filters: {
                                      ...(e?.filters || {}),
                                      ...(override_fields?.filters || {}),
                                      role: { _eq: e.value },
                                      created_at: { _gte: moment().subtract(1, "days").format() },
                                  },
                              }));
                        break;
                    default:
                        tab_options = ENUM_ACTIVE.map((e) => ({
                            ...e,
                            ...override_fields,
                            overrideValues: {
                                ...(e?.overrideValues || {}),
                                ...(override_fields?.overrideValues || {}),
                            },
                            label: e?.comment,
                            value: e?.value,
                            component: DataTable,
                            filters: {
                                ...(e?.filters || {}),
                                ...(override_fields?.filters || {}),
                                active: { _eq: e.value === "active" ? true : false },
                                created_at: { _gte: moment().subtract(1, "days").format() },
                            },
                        }));
                        break;
                }
                tab_options = [
                    {
                        label: "ALL",
                        value: "all",
                        ...e,
                        ...override_fields,
                        overrideValues: {
                            ...(e?.overrideValues || {}),
                            ...(override_fields?.overrideValues || {}),
                        },
                        component: DataTable,
                        filters: {
                            ...(e?.filters || {}),
                            ...(override_fields?.filters || {}),
                            created_at: { _gte: moment().subtract(1, "days").format() },
                        },
                    },
                    ...tab_options,
                ];
                return tab_options;
            }).call(this)}
        />
    ));
};

export default List;
