import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeLayout, changeLayoutWidth, changeSidebarTheme, changeSidebarType, changeTopbarTheme } from "../../store/actions";
import Rightbar from "../commonForBoth/Rightbar";
import Footer from "./Footer";
// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: props.collapsed,
            setCollapsed: props.setCollapsed,
            isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
        };
        this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    }

    capitalizeFirstLetter = (string) => {
        return string?.charAt(1).toUpperCase() + string?.slice(2);
    };

    componentDidMount() {
        if (this.props.isPreloader === true) {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";

            setTimeout(function () {
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
            }, 2500);
        } else {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
        }

        // Scroll Top to 0
        window.scrollTo(0, 0);
        // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

        // document.title =
        //   currentage + " | Skote - Responsive Bootstrap 5 Admin Dashboard"
        if (this.props.leftSideBarTheme) {
            this.props.changeSidebarTheme(this.props.leftSideBarTheme);
        }

        if (this.props.layoutWidth) {
            this.props.changeLayoutWidth(this.props.layoutWidth);
        }

        if (this.props.leftSideBarType) {
            this.props.changeSidebarType(this.props.leftSideBarType);
        }
        if (this.props.topbarTheme) {
            this.props.changeTopbarTheme(this.props.topbarTheme);
        }
    }

    toggleMenuCallback = () => {
        this.state.setCollapsed(!this.state.collapsed);
        if (this.props.leftSideBarType === "default") {
            this.props.changeSidebarType("condensed", this.state.isMobile);
        } else if (this.props.leftSideBarType === "condensed") {
            this.props.changeSidebarType("default", this.state.isMobile);
        }
    };

    componentDidUpdate() {
        if (this.props.collapsed !== this.state.collapsed) this.setState({ ...this.state, collapsed: this.props.collapsed });
    }

    render() {
        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                        </div>
                    </div>
                </div>

                <div id="layout-wrapper" style={{ position: "relative" }}>
                    <Header toggleMenuCallback={this.toggleMenuCallback} />
                    <Sidebar
                        style={{ position: "absolute", top: 0, zIndex: "2000" }}
                        theme={this.props.leftSideBarTheme}
                        type={this.props.leftSideBarType}
                        collapsed={this.state.collapsed}
                        isMobile={this.state.isMobile}
                    />
                    <div className="main-content">{this.props.children}</div>
                    <Footer />
                </div>
                {this.props.showRightSidebar ? <Rightbar /> : null}
            </React.Fragment>
        );
    }
}

Layout.propTypes = {
    changeLayoutWidth: PropTypes.func,
    changeSidebarTheme: PropTypes.func,
    changeSidebarType: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.object,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    leftSideBarTheme: PropTypes.any,
    leftSideBarType: PropTypes.any,
    location: PropTypes.object,
    showRightSidebar: PropTypes.any,
    topbarTheme: PropTypes.any,
};

const mapStatetoProps = (state) => {
    return {
        ...state.Layout,
    };
};
export default connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeTopbarTheme,
    changeLayoutWidth,
})(withRouter(Layout));
