import  { useContext } from "react";
import { GeneralContext,AuthContext } from "../../../context";
import { activeColumn, booleanColumn, dateColumn, datetimeColumn, defaultColumn, enumColumn, imageColumn, linkColumn, numberColumn, uuidColumn } from "../../../helpers";

 
export let useWebEntityFields = (entity) =>{

     console.log("entity is", entity)
    const { 
         enum_po_status, 
         enum_int_packaging_type, 
         enum_contact_type, 
         enum_container_type,
         enum_cargo_category,
         enum_freight_order_status,
         enum_export_payment_status,
         enum_free_days_des_type,
         enum_stuffing_location_type,
         enum_incoterms_type,
         enum_inquery_type,
         enum_freight_transit_status,
         enum_export_freight_order_payment_status,
         enum_exp_fri_ord_booking_val_status,
         enum_exp_fri_ord_shipping_ins_status,
         enum_exp_fri_ord_draft_bl_status,
         enum_exp_fri_ord_vgm_status,
         enum_bidding_imp_status,
         enum_bid_order_action_admin_status ,
         enum_bid_order_action_exp_status ,
         enum_bid_order_action_imp_status  ,
         enum_bidding_admin_status      ,
         enum_bidding_exp_status
        } = useContext(GeneralContext);
    const { id_token, role } = useContext(AuthContext);

    const default_options = {
        entity,
        fields: `id  active created_at updated_at `,
        columns: [
            activeColumn(),
            datetimeColumn({
                accessor: "created_at",
                rules: null,
                displayOnly: true,
            }),
            datetimeColumn({
                Header: "Updated",
                accessor: "updated_at",
                rules: null,
                column: true,
                isSorted: true,
                isSortedDesc: true,
            }),
        ],
        sortBy: [{ id: "created_at", desc: true }],
        activateStatus: true,
        showTitle: entity,
    };

   let options = {};
   

    switch(entity) {
        
            case "po":
                options = {
                    showPo:true,
                    entity: "export_order",
                    // disableAdd:true,
                    method:"post",
                    subUrl:"/user/grnPdfWithFields",                                          
                    docType:"pdf",
                    fields: `
                    id uid supplier_id supplier{id name} customer_id customer{ id name }  
                    location_id location{id name address}
                    active status total_amount type_of_packaging  rate_per_kg   commodity_id
                    commodity{ id name }
                    
                    price_per_pack commodity_spec wt_per_pack  no_of_box hs_code quantity_in_mt created_at updated_at`,
                    columns: [
                            linkColumn({
                                Header: "Id",
                                // displayOnly: true,
                                accessor: "uid",
                                link: `/web/exporter/detail/#id`,
                                link_id:"customer_id",
                                field:"uid",
                                add: false,
                                edit: false,
                                column:true,
                                searchable: false,

                             }),
                   
                        uuidColumn({
                            Header:"Supplier",
                            entity: "customer",                        
                            dbname: "supplier_id",
                            link: `/web/exporter/detail/#id`,
                            link_id:"supplier_id",
                            accessor:"supplier",
                        filters: {                    
                                   users: {customer_id: {_is_null: false} ,role:{_eq:"supplier"} },
                                                                locations:{id:{_is_null:false}},
                                                                exporter_commodities:{commodity_id:{_is_null:false}} 
                                                            
                                                            
                                                            },

                            typeahead_fields: `id name type:address`, 
                             }),

                        uuidColumn({
                        Header:"commodity",
                        entity: "commodity", 
                        typeahead_fields: `id name`, 
                        hideAdd: true,
                        dbname:"commodity_id",
                        // labelKey:"commodity: {name}",
                        filters: {  origin_type : { _in: [ "non_domestic"] } },
                        // filter_fields: [{ accessor: "supplier", nestedAccessor:"customer_int_commodities.customer_id" }],
                        }),

                        enumColumn({ accessor: "status", options: enum_po_status, type: "select",    searchable: false, }),
                        defaultColumn({ accessor: "commodity_spec", type: "text", column:false ,  rules:{required:"This Field is required"}}),
                        enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false }),
                        defaultColumn({ accessor: "hs_code", type: "text",  column:false , rules:{required:"This Field is required"}}),
                       
                        defaultColumn({Header:"Quanitiy in kgs", accessor: "quantity_in_mt", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"WT Per bag/box per Kgs",accessor: "wt_per_pack", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        defaultColumn({ 
                            Header:"No Of Bag/Box", 
                            disabled:true,
                            accessor: "no_of_box", 
                            type:"testing", 
                            calcDepends: `parseInt(watching?.["quantity_in_mt"])/ parseInt(watching?.["wt_per_pack"]) `,
                            column:false, 
                            rules:null
                        }),
                        defaultColumn({ Header:"Rate per kg",accessor: "rate_per_kg", type: "text"}),
                       


                            defaultColumn({ Header:"Price per Pack/ Boxes", disabled:true,accessor: "price_per_pack", type:"testing", 
                            calcDepends: `parseInt(watching?.["rate_per_kg"]) * parseInt(watching?.["wt_per_pack"]) `,
                                column:false, rules:null}),


                        //  defaultColumn({ Header:"Price per Pack/ Boxes",  disabled:true,accessor: "price_per_pack", type: "text",  column:false, rules:{required:"This Field is required"}}),
                      
                      
                          uuidColumn({ 
                            entity: "location", 
                            typeahead_fields: `id name  address type:address `, 
                            // filter_fields: [{ accessor: "supplier_id" }],
                            // reset_fields: ["customer"],
                            rules:{required:"This Field is required"}               ,
                        }),

                        defaultColumn({  accessor: "total_amount",disabled:true, type:"testing", 
                        calcDepends: `parseInt(watching?.["price_per_pack"]) * parseInt(watching?.["no_of_box"]) `,
                            column:false, rules:null}),


                       
                        // defaultColumn({ accessor: "total_amount", disabled:true, type: "text",    rules:{required:"This Field is required"}}),

                        datetimeColumn({ accessor: "created_at", rules: null, displayOnly: true, column: true, }),
                        datetimeColumn({ accessor: "updated_at", rules: null,   isSorted: true, isSortedDesc: true, }),
                        activeColumn(),
                        
                    ],
                };
            break;


            case "exporterGrn":
                options = {
                    showPo:true,
                    entity: "export_order",
                    // disableAdd:true,
                    method:"post",
                    subUrl:"/user/grnPdfWithFields",                                          
                    docType:"pdf",
                    fields: `
                    id uid supplier_id supplier{id name} customer_id customer{ id name }  
                    location_id location{id name address}
                    active status total_amount type_of_packaging     commodity_id
                    commodity{ id name }
                    
                    price_per_pack commodity_spec wt_per_pack  no_of_box hs_code quantity_in_mt created_at updated_at`,
                    columns: [
                            linkColumn({
                                Header: "Id",
                                // displayOnly: true,
                                accessor: "uid",
                                link: `/web/exporter/detail/#id`,
                                link_id:"customer_id",
                                field:"uid",
                                add: false,
                                edit: false,
                                column:true,
                                searchable: false,

                             }),
                        uuidColumn({ entity: "commodity", typeahead_fields: `id name `, 
                        hideAdd: true,
                        filters: {  origin_type : { _in: [ "non_domestic"] } },
                        }),
                        uuidColumn({
                            Header:"Supplier",
                            entity: "customer",                        
                            dbname: "supplier_id",
                            link: `/web/exporter/detail/#id`,
                            link_id:"supplier_id",
                            accessor:"supplier",
                            filters: { customer_type: { _in: [ "supplier"] } },
                            typeahead_fields: `id name type:address`, 
                             }),
                        enumColumn({ accessor: "status", options: enum_po_status, type: "select",    searchable: false, }),
                        defaultColumn({ accessor: "commodity_spec", type: "text", column:false ,  rules:{required:"This Field is required"}}),
                        defaultColumn({Header:"Quanitiy in kgs", accessor: "quantity_in_mt", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false }),
                        defaultColumn({ accessor: "hs_code", type: "text",  column:false , rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"WT Per PACK ( KGS )",accessor: "wt_per_pack", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"No Of Pack/Boxes",accessor: "no_of_box", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"Price per Pack/ Boxes",accessor: "price_per_pack", type: "text",  column:false, rules:{required:"This Field is required"}}),
                        uuidColumn({ 
                            entity: "location", 
                            typeahead_fields: `id name  address type:address `, 
                            rules:{required:"This Field is required"}               ,
                        }),

                       
                        defaultColumn({ accessor: "total_amount", type: "text",    rules:{required:"This Field is required"}}),

                        datetimeColumn({ accessor: "created_at", rules: null, displayOnly: true, column: true, }),
                        datetimeColumn({ accessor: "updated_at", rules: null,   isSorted: true, isSortedDesc: true, }),
                        activeColumn(),
                        
                    ],
                };
            break;

            case "purchase_order":
                options = {
                    showPo:true,
                    entity: "export_order",
                    method:"post",
                    subUrl:"/user/grnPdfWithFields",                                          
                    docType:"pdf",
                    fields: `
                    id uid commodity_id commodity{id name} 
                    commodity_spec active
                    quantity_in_mt hs_code  status    
                    location_id location{id name } 
                    supplier_id  supplier{id name}   
                    customer_id  customer{id name}   
                    type_of_packaging wt_per_pack 
                    price_per_pack total_amount updated_at created_at`,
                    userColumns:{

                        skipColumn:{}    , 
                        skipEnum  :{"enum_po_status":{completed:true}}
                    } ,
                    columns: [
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                            link: `/web/exporter/detail/#id`,
                            link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            disabled:true,
                            searchable: false,
                            width:50
                        }),
                    uuidColumn({ entity: "commodity", typeahead_fields: `id name`, rules:null,disabled:true }),
                    // uuidColumn({ Header:"Customer", entity: "customer", dbname: "customer_id", link: `/web/exporter/detail/#id`, link_id:"customer_id", accessor:"customer", typeahead_fields: `id name type:address`, rules:null, disabled:true }),
                    enumColumn({ accessor: "status", options: enum_po_status, type: "select", searchable: false,}),
                    defaultColumn({ accessor: "commodity_spec", type: "text", rules: null, column:false, disabled:true }),
                    defaultColumn({Header:"Quanitiy in kgs", accessor: "quantity_in_mt", type: "text",     disabled:true,   rules:{required:"This Field is required"},searchable: false,}),
                    defaultColumn({ accessor: "hs_code", type: "text", rules: null,disabled:true ,column:false, searchable: false, }),
                    defaultColumn({ Header:"WT/PACK(KGS)",accessor: "wt_per_pack", type: "text", rules: null ,disabled:true,searchable: false,  }),
                    defaultColumn({ Header:"Price per Pack/ Boxes",accessor: "price_per_pack", type: "text", rules: null , column:false,disabled:true,searchable: false,}),
                    defaultColumn({Header:"Amount", accessor: "total_amount", type: "text", rules: null,  disabled:true,searchable: false, }),
                    uuidColumn({ entity: "location", typeahead_fields: `id name type:address`,column:false,  rules:null, disabled:true}),
                    enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false,disabled:true, searchable: false, }),
                    datetimeColumn({ accessor: "created_at", rules: null, displayOnly: true, column: true, }),
                    activeColumn(),
                ],
                };
            break; 
 
             case "export_order_view":
                options = {
                    entity: "export_order_view",
                    fields: `
                    id
                    name
                    phone
                     export_order {
                      id
                      uid
                      customer{
                        name
                      }
                      freight_forwarder{
                        name
                      }

                      origin_port{
                        address
                        name
                      }
                      destination_port{
                        address
                        name
                      }
                      sailing_date
                      total_freight_amount
                    }`,
                    columns: [
                        // linkColumn({
                        //     Header: "Id",
                        //     // displayOnly: true,
                        //     accessor: "export_order.uid",
                        //     // link: `/web/exporter/detail/#id`,
                        //     // link_id:"customer_id",
                        //       field:"uid",
                        //     add: false,
                        //     edit: false,
                        //     column:true,
                        //     disabled:true,
                        //     searchable: false,
                        // }),
                    defaultColumn({Header:"Id", accessor: "export_order.uid", type: "text", rules: null,  disabled:true }),

                    defaultColumn({Header:"Name", accessor: "name", type: "text", rules: null,  disabled:true }),
                    defaultColumn({Header:"Phone", accessor: "phone", type: "text", rules: null,  disabled:true }),
                    uuidColumn({ Header:"Freighter",accessor:"export_order.freight_forwarder.name", entity: "customer", dbname: "customer_id",   link_id:"customer_id",  typeahead_fields: `id name type:address`, rules:null, disabled:true }),
                    defaultColumn({Header:"Origin", accessor: "export_order.origin_port.name", type: "text", rules: null,  disabled:true }),
                    defaultColumn({Header:"Destination", accessor: "export_order.destination_port.name", type: "text",     disabled:true,   rules:{required:"This Field is required"},searchable: false,}),
                    defaultColumn({Header:"Amount", accessor: "total_freight_amount", type: "text", column:false, rules: null,  disabled:true,searchable: false, }),
                    datetimeColumn({Header:"Sailing Date", accessor: "export_order.sailing_date", rules: null, displayOnly: true, column: true, }),

                ],
                };
            break; 


            case "invoice_history":
                options = {
                    showPo:true,
                    entity: "export_order",
                    method:"post", 
                    subUrl:"/user/grnPdfWithFields",                                          
                    docType:"pdf",
                    fields: `id uid commodity_id commodity{id name} commodity_spec     
                    quantity_in_mt hs_code  status    
                    location_id location{id name } 
                    supplier_id  supplier:customer{id name}   
                    supplier_inv_price supplier_inv_weight  supplier_inv_total_price  inv_created_at
                    supplier_inv_driver  supplier_inv_driver_ph supplier_inv_veh_name 
                    type_of_packaging wt_per_pack price_per_pack total_amount updated_at created_at`,
                    columns: [
                        linkColumn({
                            Header: "Id",
                            // displayOnly: true,
                            accessor: "uid",
                            // link: `/web/exporter/detail/#id`,
                            // link_id:"customer_id",
                            field:"uid",
                            add: false,
                            edit: false,
                            column:true,
                            searchable: false,
                            minWidth:50,
                            width:50
                        }),
                    uuidColumn({ entity: "commodity", typeahead_fields: `id name`, rules:null, }),
                    uuidColumn({ Header:"Supplier", entity: "customer", dbname: "supplier_id", link: `/web/exporter/detail/#id`, link_id:"supplier_id", accessor:"supplier", typeahead_fields: `id name type:address`, rules:null, }),
                    enumColumn({ accessor: "status", options: enum_po_status, type: "select",searchable: false, }),
                    defaultColumn({Header:"Inv-Price", accessor: "supplier_inv_price", type: "text", rules: null, add:false, edit:false,searchable: false, }),
                    defaultColumn({Header:"Inv-Weight", accessor: "supplier_inv_weight", type: "text", rules: null, add:false, edit:false ,searchable: false,}),
                    defaultColumn({Header:"Inv-Total Weight", accessor: "supplier_inv_total_price", type: "text", rules: null, add:false, edit:false,searchable: false, }),
                    defaultColumn({Header:"Driver", accessor: "supplier_inv_driver", type: "text", rules: null, add:false, edit:false }),
                    defaultColumn({Header:"Driver ph", accessor: "supplier_inv_driver_ph", type: "text", rules: null, add:false, edit:false,searchable: false, }),
                    defaultColumn({Header:"Veh Name", accessor: "supplier_inv_veh_name", type: "text", rules: null, add:false, edit:false }),
                    defaultColumn({ accessor: "commodity_spec", type: "text", rules: null, column:false }),
                    defaultColumn({Header:"Quanitiy in kgs", accessor: "quantity_in_mt", type: "text",   column:false, rules:{required:"This Field is required"},searchable: false,}),
                    defaultColumn({ accessor: "hs_code", type: "text", rules: null, column:false }),
                    defaultColumn({  Header:"WT Per PACK ( KGS )",accessor: "wt_per_pack", type: "text", rules: null , column:false,searchable: false,}),
                    defaultColumn({ Header:"Price per Pack/ Boxes",accessor: "price_per_pack", type: "text", rules: null , column:false,searchable: false,}),
                    defaultColumn({ accessor: "total_amount", type: "text", rules: null, column:false ,searchable: false,}),
                    uuidColumn({ entity: "location", typeahead_fields: `id name type:address`, rules:null, column:false}),
                    enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false ,searchable: false,}),
                    activeColumn(),
                    datetimeColumn({ accessor: "inv_created_at", rules: null, displayOnly: true, column: true, }),
                ],
                };
            break;
      
            case "user":
                options = {
                    entity: "user",
                    fields: `
				id uid active rating created_at updated_at  name email phone photo_url 
                is_phone_verified is_email_verified role 
				customer_id customer{ id name customer_documents{images} }
				 
				`,
                    columns: [
                        linkColumn({
                            accessor: "name",
                            link: `/web/user/detail/#id`,
                            check:entity
                        }),
                        defaultColumn({ accessor: "email" }),
                        defaultColumn({ accessor: "phone" }),
                        defaultColumn({ accessor: "rating", type:"rating", edit:false }),
                        imageColumn({
                            type: "photo",
                            accessor: "photo_url",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                            rules: null,
                        }),

                        uuidColumn({
                            Header: "Customer",
                            accessor: "customer",
                            entity: "customer",
                            typeahead_fields: `id name type:address type: name id `,
                        }),
                        activeColumn(),
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],
                };
            break;

            case "customer_contact":
                options = {
                    entity: "customer_contact",
                    fields: `
                     id uid active created_at updated_at notes 
                     customer {id name customer_type commission_percent
                     payment_days enum_customer_type{comment}}
                     location_id location{id name address 
                     location_type enum_location_type {comment} } 
                     contact_id contact{id name phone whatsapp} contact_type`,
                    columns: [
                        enumColumn({
                            accessor: "contact_type",
                            options: enum_contact_type,
                            type: "select",
                        }),
                        uuidColumn({
                            entity: "customer",
                            typeahead_fields: `id name type:address type: enum_customer_type{value comment}`,
                        }),
                        uuidColumn({
                            entity: "contact",
                            typeahead_fields: `id name   phone whatsapp email`,
                        }),
                        defaultColumn({
                            Header: "Phone",
                            accessor: "contact.phone",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        defaultColumn({
                            Header: "Whatsapp",
                            accessor: "contact.whatsapp",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        defaultColumn({
                            Header: "Email",
                            accessor: "contact.email",
                            rules: null,
                            add: false,
                            edit: false,
                        }),
                        uuidColumn({
                            entity: "location",
                            typeahead_fields: `id name   `,
                            rules: null,
                        }),
                        defaultColumn({
                            accessor: "notes",
                            rules: null,
                            type: "textarea",
                            column: false,
                        }),
                        activeColumn(),
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            displayOnly: true,
                        }),
                        datetimeColumn({
                            accessor: "updated_at",
                            rules: null,
                            isSorted: true,
                            isSortedDesc: true,
                        }),
                    ],
                };
              break;

            case "supplierDirectory":
                options = {
                    entity: "export_order_view",
                    fields: `
                    id
                    uid
                    name
                    phone
                    rating

                    commodity
                    state
                    
                    location{
                        id 
                        name
                        city
                        state
                        address

                    }
                  
                    `,
                    sortBy: [{ id: "rating",desc: true }],
                    columns: [

                    defaultColumn({Header:"Id", accessor: "uid", type: "text", rules: null,  disabled:true }),
                    // defaultColumn({Header:"Location", accessor: "location.address", type: "text", rules: null,  disabled:true }),
                    // defaultColumn({Header:"State", accessor: "state", type: "text",filterType:"textFilter", rules: null,  disabled:true ,filterable:true}),
                    uuidColumn({
                        Header:"Commodity", 
                        entity:"export_order_view",
                        accessor: "commodity", 
                        dbname:"commodity_id",
                        dbfield:"commodity_id",
                        labelKey:"commodity",
                        activeFilter:false,
                        rules: null, 
                        filterable:true ,
                        typeahead_fields: `id commodity_id name:commodity  `,
                        span:3,
                        distinctOn:true

                    }),

                    uuidColumn({
                        Header:"State", 
                        entity:"export_order_view",
                        accessor: "state", 
                        // dbname:"location_id",
                        // dbfield:"location_id",
                        labelKey:"state",
                        activeFilter:false,
                        rules: null,  
                        filterable:true,
                        typeahead_fields: `id location_id  name:state  `,
                        span:3,
                        distinctOn:true,
                        distinctOnLabel:"state"
                    }),
                    defaultColumn({Header:"City", accessor: "location.city", type: "text", rules: null,  disabled:true,  }),
                    // defaultColumn({Header:"Commodity", accessor: "commodity", type: "text", rules: null, filterType:"textFilter", disabled:true, filterable:true }),


                    // defaultColumn({ accessor: "name", type: "text", rules: null,  disabled:true }),
                    uuidColumn({
                        Header:"Supplier",
                        entity: "export_order_view",
                        accessor:"name",
                        dbname:"customer_id",
                        dbfield:"customer_id",
                        labelKey:"name",
                        activeFilter:false,
                        // filters: { customer_type: { _in: ["exporter"] } },
                        typeahead_fields: `id customer_id name  `,
                        span:3,
                        distinctOn:true

                    }),
                    defaultColumn({ accessor: "phone", type: "text", rules: null,  disabled:true }),
                  
                    defaultColumn({ accessor: "rating", type: "rating", rules: null,  disabled:true,  filterable:false,    span:3  }),
             
                ],
                };
            break; 
            case "importerDirectory":
                options = {
                    entity: "importer_order_view",
                    fields: `
                    id
                    uid
                    name
                    phone
                    email
                    rating

                    commodity
                    state
                    
           
                  
                    `,
                    columns: [
                        // location{
                        //     id 
                        //     name
                        //     city
                        //     state
                        //     address
    
                        // }
                    defaultColumn({Header:"Id", accessor: "uid", type: "text", rules: null,  disabled:true ,searchable:false}),
                    // defaultColumn({Header:"Location", accessor: "location.address", type: "text", rules: null,  disabled:true }),


                    // defaultColumn({Header:"Commodity", accessor: "commodity", type: "text", rules: null, filterType:"textFilter", disabled:true, filterable:true }),
                    uuidColumn({
                        Header:"Commodity", 
                        entity:"importer_order_view",
                        accessor: "commodity", 
                        dbname:"commodity_id",
                        dbfield:"commodity_id",
                        labelKey:"commodity",
                        activeFilter:false,
                        rules: null, 
                        filterable:true ,
                        typeahead_fields: `id commodity_id name:commodity  `,
                        span:3,
                        distinctOn:true

                    }),
                             // defaultColumn({Header:"State", accessor: "state", type: "text",filterType:"textFilter", rules: null,  disabled:true ,filterable:true}),
                             uuidColumn({
                                Header:"State", 
                                entity:"importer_order_view",
                                accessor: "state", 
                                dbname:"location_id",
                                dbfield:"location_id",
                                labelKey:"state",
                                activeFilter:false,
                                rules: null,  
                                filterable:true,
                                typeahead_fields: `id location_id  name:state  `,
                                span:3,
                        distinctOn:true

                            }),
                    uuidColumn({
                        Header:"Customer",
                        entity: "importer_order_view",
                        accessor:"name",
                        dbname:"customer_id",
                        dbfield:"customer_id",
                        labelKey:"name",
                        activeFilter:false,
                        // filters: { customer_type: { _in: ["exporter"] } },
                        typeahead_fields: `id customer_id name  `,
                        span:3,
                        distinctOn:true

                    }),

           
                    defaultColumn({Header:"City", accessor: "location.city", type: "text", rules: null,  disabled:true,  }),

                    defaultColumn({ accessor: "phone", type: "text", rules: null,  disabled:true,searchable:false }),
                    defaultColumn({ accessor: "email", type: "text", rules: null,  disabled:true }),
                    // uuidColumn({ accessor: "name",   rules: null,  disabled:true, filterable:true, tyle }),
             

                      defaultColumn({ accessor: "rating", type: "rating", rules: null,  disabled:true,filterable:false,searchable:false , span:3 }),
                    



                ],
                };
            break; 


            case "importerDirectoryInExp":
                options = {
                    entity: "export_imp_order_view",
                    fields: `
                    id
                    uid
                    name
                    phone
                    rating
                    country
                    commodity
                    state
                    
                    location{
                        id 
                        name
                        city
                        state
                        address

                    }
                  
                    `,
                    columns: [

                    defaultColumn({Header:"Id", accessor: "uid", type: "text", rules: null,  disabled:true }),
 
                    uuidColumn({
                        Header:"Commodity", 
                        entity:"export_imp_order_view",
                        accessor: "commodity", 
                        // dbname:"commodity_id",
                        // dbfield:"commodity_id",
                        labelKey:"commodity",
                        activeFilter:false,
                        rules: null, 
                        filterable:true ,
                        typeahead_fields: `id commodity_id name:commodity  `,
                        span:3,
                        distinctOn:true,
                        distinctOnLabel:"commodity"

                    }),
                    uuidColumn({
                        Header:"Country", 
                        entity:"export_imp_order_view",
                        accessor: "country", 
                        // dbname:"location_id",
                        // dbfield:"location_id",
                        labelKey:"country",
                        activeFilter:false,
                        rules: null,  
                        filterable:true,
                        typeahead_fields: `id location_id  name:country`,
                        span:3,
                        distinctOn:true,
                        distinctOnLabel:"country"

                    }),


                         uuidColumn({
                        Header:"State", 
                        entity:"export_imp_order_view",
                        accessor: "state", 
                        // dbname:"location_id",
                        // dbfield:"location_id",
                        labelKey:"state",
                        activeFilter:false,
                        rules: null,  
                        filterable:true,
                        typeahead_fields: `id location_id  name:state  type:country`,
                        span:3,
                        distinctOn:true,
                        distinctOnLabel:"state"

                    }),

                    // defaultColumn({ accessor: "name", type: "text", rules: null,  disabled:true }),
                    uuidColumn({
                        Header:"Customer",
                        entity: "export_imp_order_view",
                        accessor:"name",
                        dbname:"customer_id",
                        dbfield:"customer_id",
                        labelKey:"name",
                        activeFilter:false,
                        // filters: { customer_type: { _in: ["exporter"] } },
                        typeahead_fields: `id customer_id name  `,
                        span:3,
                        distinctOn:true

                    }),
                    defaultColumn({ accessor: "phone", type: "text", rules: null,  disabled:true }),
                    defaultColumn({ accessor: "rating",isSorted: true,
                    isSortedDesc: true, type: "rating", rules: null,  disabled:true,filterable:false,span:3 }),
             
                 ],
                };
            break; 
      
            
            case "supplier":
              options = {
            entity: "offer",
            fields: `id uid name active created_at updated_at hsn_no  package_type  net_weight  gross_weight  customer_id customer{id name}
                    shape_id shape{id name} size_id size{id name} color_id color{id name}
                    location_id location{id name address location_type enum_location_type {comment} }
                    commodity_variety_id commodity_variety { id name commodity { id name commodity_type enum_commodity_type { comment } } }
                    number_of_units price_per_unit images videos documents status enum_offer_status{comment} amount
                    change_history(order_by:{created_at: desc_nulls_last}){id new_value old_value status created_at enum:enum_offer_status{comment}}
                    available_date
                    `,
            columns: [
                linkColumn({
                    Header: "Id",
                    displayOnly: true,
                    accessor: "name",
                    link: `/marketplace/offer/detail/#id`,
                    add: false,
                    edit: false,
                }),
                
                uuidColumn({
                    entity: "customer",
                    solution: "marketplace",
                    filters: { customer_type: { _in: ["exporter"] } },
                    typeahead_fields: `id name type:address type: enum_customer_type{value comment}`,
                }),
                uuidColumn({
                    entity: "location",
                    filters: { location_type: { _in: ["business"] } },
                    typeahead_fields: `id name type:address type: enum_location_type{value comment} extra: address`,
                    filter_fields: [{ accessor: "customer" }],
                }),
 
                uuidColumn({
                    entity: "commodity_variety",
                    reset_fields: ["shape", "size", "color"],
                    filters: {commodity :{ origin_type: { _in: ["non_domestic"] } }},
                    typeahead_fields: `id name type:address type: commodity{id name}`,
                }),
                 
                enumColumn({
                    accessor: "package_type",
                    options: enum_int_packaging_type,
                    type: "select",
                }), 
                defaultColumn({ accessor: "hsn_no" }),

                defaultColumn({
                    accessor: "images",
                    type: "image",
                    accept: /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i,
                    rules: null,
                    column: false,
                    // filterable: false,
                }),
                defaultColumn({
                    accessor: "videos",
                    type: "video",
                    accept: /\.(mp4)$/i,
                    rules: null,
                    column: false,
                    // filterable: false,
                }),
                defaultColumn({
                    accessor: "documents",
                    type: "document",
                    rules: null,
                    column: false,
                    filterable: false,
                }),
                activeColumn(),
                datetimeColumn({
                    accessor: "created_at",
                    rules: null,
                    displayOnly: true,
                }),
                datetimeColumn({
                    accessor: "updated_at",
                    rules: null,
                    column: true,
                    isSorted: true,
                    isSortedDesc: true,
                }),
            ],
              };
              break;
              case "freight":
                options = {
                    showPo:true,
                    entity: "export_order",
                    // disableAdd:true,
                    method:"post",
                    subUrl:"/user/grnPdfWithFields",                                          
                    docType:"pdf",
                    fields: `
                    id uid supplier_id supplier{id name} customer_id customer{ id name }  
                    location_id location{id name address}
                    active status total_amount type_of_packaging     commodity_id
                    commodity{ id name }
                    freight_forwarder_id freight_forwarder {id name}
                     total_freight_amount
                    sailing_date
                    origin_port_id origin_port { id name address }
                    destination_port_id destination_port { id name address }
                    price_per_pack commodity_spec wt_per_pack  no_of_box hs_code quantity_in_mt created_at updated_at`,
                    columns: [
                            linkColumn({
                                Header: "Id",
                                // displayOnly: true,
                                accessor: "uid",
                                link: `/web/exporter/detail/#id`,
                                link_id:"customer_id",
                                field:"uid",
                                add: false,
                                edit: false,
                                column:true,
                                searchable: false,

                             }),

                             uuidColumn({
                                Header:"Freght Forwarder",
                                entity: "customer",                        
                                dbname: "freight_forwarder_id",
                                link: `/web/exporter/detail/#id`,
                                link_id:"freight_forwarder_id",
                                accessor:"freight_forwarder",
                                filters: { customer_type: { _in: [ "supplier"] } },
                                typeahead_fields: `id name type:address`, 
                                rules:null, }),

                                uuidColumn({
                                    Header:"Origin Port", 
                                    entity: "location",
                                    accessor:"origin_port",
                                    dbname:"origin_port_id",
                                    typeahead_fields: `id  name address type:address`, 
                                    rules:null, 
                                    filters: { location_type: { _in: [ "origin_port"] } },
            
                                    }),
            
                                    uuidColumn({ 
                                    Header:"Destin. Port",
                                    entity: "location",
                                    accessor:"destination_port" ,   
                                    dbname:"destination_port_id",
                                    typeahead_fields: `id name address type:address`,    
                                    rules:null,
                                    filters: { location_type: { _in: ["destination_port"] } },
                                    }),

                                    datetimeColumn({ accessor: "sailing_date", type:"date", column:true, rules: null, edit:true, add:true }),



                        // uuidColumn({ entity: "commodity", typeahead_fields: `id name `, 
                        // rules:null,
                        // hideAdd: true,
                        // filters: {  origin_type : { _in: [ "non_domestic"] } },
                        // }),
                        // uuidColumn({
                        //     Header:"Supplier",
                        //     entity: "customer",                        
                        //     dbname: "supplier_id",
                        //     link: `/web/exporter/detail/#id`,
                        //     link_id:"supplier_id",
                        //     accessor:"supplier",
                        //     filters: { customer_type: { _in: [ "supplier"] } },
                        //     typeahead_fields: `id name type:address`, 
                        //     rules:null, }),
                        // enumColumn({ accessor: "status", options: enum_po_status, type: "select",    searchable: false, }),
                       
                        // defaultColumn({ accessor: "commodity_spec", type: "text", column:false ,  rules:{required:"This Field is required"}}),
                        // defaultColumn({ accessor: "quantity_in_mt", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        // defaultColumn({ accessor: "hs_code", type: "text",  column:false , rules:{required:"This Field is required"}}),
                        // defaultColumn({ Header:"WT Per PACK ( KGS )",accessor: "wt_per_pack", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        // defaultColumn({ Header:"No Of Pack/Boxes",accessor: "no_of_box", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        // defaultColumn({ Header:"Price per Pack/ Boxes",accessor: "price_per_pack", type: "text",  column:false, rules:{required:"This Field is required"}}),
                        // defaultColumn({ accessor: "total_amount", type: "text",  column:false , rules:{required:"This Field is required"}}),
                        // uuidColumn({ 
                        //     entity: "location", 
                        //     typeahead_fields: `id name  address type:address `, 
                        //     rules:{required:"This Field is required"}               ,
                        // }),

                        // enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false }),
                        // datetimeColumn({ accessor: "sailing_date", type:"date", column:true, rules: null, edit:true, add:true }),
                        
                        // uuidColumn({
                        //     Header:"Freght Forwarder",
                        //     entity: "customer",                        
                        //     dbname: "freight_forwarder_id",
                        //     link: `/web/exporter/detail/#id`,
                        //     link_id:"freight_forwarder_id",
                        //     accessor:"freight_forwarder",
                        //     filters: { customer_type: { _in: [ "supplier"] } },
                        //     typeahead_fields: `id name type:address`, 
                        //     rules:null, }),
               

                        // defaultColumn({  Header:"Amount", width:"50px",accessor: "total_freight_amount", type: "text", rules: null , column:true,searchable: false,}),
                        
                        datetimeColumn({ accessor: "created_at", rules: null, displayOnly: true, column: true, }),
                        datetimeColumn({ accessor: "updated_at", rules: null,   isSorted: true, isSortedDesc: true, }),
                        activeColumn(),
                        
                    ],
                };
            break;

       
            case "freight_forwarder":
              options = {
                entity: "export_freight",
                fields: ` 
                id
                uid
                container_type enum_container_type { comment}
                origin_port_id origin_port{id name address}
                destination_port_id destination_port{id name address}
                eta
                etd
                dollar_price 
                ocean_freight
                cargo_category_type enum_cargo_category{comment}
                bill_of_lading_charges
                cmc_charges
                seal_charges
                temp_variation_charges
                terminal_handling_charges
                toll_charges
                listing_validity_date
                export_shipping_line_id
                export_shipping_line{
                  id
                  name
                  images
                }
                cut_off
                transit_period
                vessel
                muf
                vessel_risk
                total_amount
                validity 
                active
                customer{
                    id
                    name
                  }
                
     

                created_at
                updated_at
                   `, 
                columns: [
                    linkColumn({
                        Header: "Id",
                        // displayOnly: true,
                        accessor: "uid",
                        // link: `/web/exporter/detail/#id`,
                        // link_id:"customer_id",
                        field:"uid",
                        add: false,
                        edit: false,
                        column:false,
                        searchable: false,
                        minWidth:150,
                        // miniColumnWidth:150

                     }),

                     uuidColumn({
                        Header:"POL",
                        entity:"location",
                        accessor: "origin_port",
                        typeahead_fields: `id name`,
                        dbname: "origin_port_id",
                        labelKey:"name",
                        filters: { location_type: { _in: [ "origin_port"] } },
                        filterSpan:3,
                        span:3,
                        distinctOn:true,
                        distinctOnLabel:"origin_port.name",
                        rules:{required:"This Field is required"}
                        // width:"60px"

                    }),
  
                    uuidColumn({
                        Header:"POD",
                        entity: "location",
                        accessor:"destination_port",
                        typeahead_fields: `id name`,
                        dbname: "destination_port_id",
                    
                        filters: { location_type: { _in: [ "destination_port"] } },
                        filterSpan:3,
                        span:3,
                        distinctOn:true,
                        distinctOnLabel:"destination_port.name",
                        rules:{required:"This Field is required"}

                      }),

                    enumColumn({
                        Header:"Container",
                        accessor: "container_type",
                        options: enum_container_type,
                        type: "select",
                        add: true,
                        edit: true,
                        column: true,
                        shareWith:enum_cargo_category,
                        shareValue:"cargo_category_type",
                        width:90,


                    }),

                                        
                    uuidColumn({
                        Header:"Carrier",
                        entity: "export_shipping_line",
                        accessor:"export_shipping_line",
                        typeahead_fields: `id name`,
                        dbname: "export_shipping_line_id",
                        searchable:false,
                        filterable:false,
                        // hideAdd:true,
                        // minWidth:"60px"

                      }),





                    enumColumn({
                        Header:"Cargo",
                        accessor: "cargo_category_type",
                        options: enum_cargo_category,
                        type: "select",
                        add: true,
                        edit: true,
                        column: true,
                        filterable:false,
                        width:90,
                        // width:80,
                        // filterable:true
                        // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}

                    }),


        
                    


                    // dateColumn({ accessor: "cut_off",rules: null,   column: true, add:true, edit:true }),
                    dateColumn({ accessor: "etd", rules: null,   column: true, add:true, edit:true,  rules:{required:"This Field is required"}}),
                    dateColumn({FilterFormHeader:"MAX ETA", Header:"ETA", accessor: "eta", rules: null,   column: true, add:true, edit:true,filterable:true , rules:{required:"This Field is required"} }),
                 
                    numberColumn({
                        Header: "freight($)",
                        accessor: "ocean_freight",
                        width:100,
                        // width:80,
                        rules:{required:"This Field is required"}
                    }),


                    // defaultColumn({Header:"Rank", accessor: "freight_rank.rank" , displayOnly:true, edit:false, add:false}),
                                        
                    // numberColumn({
                    //     Header: "Freight($)",
                    //     accessor: "total_amount",
                    //     displayOnly:true
                    // }),



                    //   uuidColumn({
                    //     Header:"Freight Forwarder",
                    //     entity:"customer",
                    //     typeahead_fields: `id name`,
                    //     searchable:false,
                    //     filterable:false

                    //   }),

                    numberColumn({
                        Header: "MUC Charges",
                        accessor: "muf",
                        column:false,
                        rules:{required:"This Field is required"}
                    }),



                    numberColumn({
                        Header: "Temperature Variation Charges",
                        accessor: "temp_variation_charges",
                        column:false,
                        rules:null

                    }),
                    

                    // numberColumn({
                    //     Header: "vessel_risk Charges",
                    //     accessor: "vessel_risk",
                    //     column:false,

                    // }),

                     
                     numberColumn({
                        Header: "Bill of Lading Charges",
                        accessor: "bill_of_lading_charges",
                        column:false,
                        rules:{required:"This Field is required"}
                    }),

                    numberColumn({
                        Header: "Exchange Rate",
                        accessor: "dollar_price",
                        column:false,
                        rules:{required:"This Field is required"}
                    }),
                    //  numberColumn({
                    //     Header: "CMC Charges",
                    //     accessor: "cmc_charges",
                    //     column:false,

                    // }),

                     numberColumn({
                        Header: "Seal Charges",
                        accessor: "seal_charges",
                        column:false,
                        // notRequired:true
                        rules:{required:"This Field is required"}


                    }),
                    // numberColumn({
                    //     Header: "Temp Variation Charges",
                    //     accessor: "temp_variation_charges",
                    //     column:false,
                    //     dependsValue: ["cargo_category_type:reefer"],
                    //     // rules:

                    // }),
                  
                    numberColumn({
                        Header: "Terminal Handling Charges",
                        accessor: "terminal_handling_charges",
                        column:false,

                    }),

              

                     numberColumn({
                        Header: "Toll Charges",
                        accessor: "toll_charges",
                        column:false,

                    }),

                    // numberColumn({
                    //     Header: "Transit Days",
                    //     accessor: "transit_period",
                    // }),

                    // enumColumn({ accessor: "status", options: enum_freight_transit_status, type: "select",    searchable: false, }),
                    // numberColumn({
                    //     Header: "Transit Period",
                    //     accessor: "transit_period",
                    //     column:false,

                    // }),
                    
                        
                   
                    dateColumn({ accessor: "validity", rules: null,   column: true, add:true, edit:true, rules:{required:"This Field is required"} }),
                    defaultColumn({Header:"Vessel", accessor: "vessel", rules:{required:"This Field is required"}}),


                       
                    // booleanColumn({Header:"Active",accessor:"active",add:true, edit:true, column:false, searchable:false, filterable:false}),
                    
                    // datetimeColumn({
                    //     accessor: "listing_validity_date",
                    //     rules: null,
                    //     // displayOnly: true,
                    //     add:true,
                    //     edit:true,
                    //     column:false,
                    // }),
                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                        column:false,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                        column:false
                    }),


                    

                ],
              };
              break;
          
              case "freight_forwarder_row_child":
                options = {
                  entity: "export_freight",
                  fields: ` 
                  id
                  uid
                  container_type enum_container_type { comment}
                  origin_port_id origin_port{id name address}
                  destination_port_id destination_port{id name address}
                  eta
                  etd
                  dollar_price 
                  ocean_freight
                  cargo_category_type
                  bill_of_lading_charges
                  cmc_charges
                  seal_charges
                  temp_variation_charges
                  terminal_handling_charges
                  toll_charges
                  export_shipping_line_id
                  export_shipping_line{
                    id
                    name
                    images
                  }
                  cut_off
                  transit_period
                  vessel
                  active
                  muf
                  vessel_risk
                  created_at
                  updated_at
                     `, 
                  columns: [
                      linkColumn({
                          Header: "ID",
                          // displayOnly: true,
                          accessor: "uid",
                          // link: `/web/exporter/detail/#id`,
                          // link_id:"customer_id",
                          field:"uid",
                          add: false,
                          edit: false,
                          column:true,
                          searchable: false,
                          minWidth:80
  
                       }),
  
                       uuidColumn({
                           Header:"Origin Port",
                           entity:"location",
                          accessor: "origin_port",
                          typeahead_fields: `id name`,
                          dbname: "origin_port_id",
                          filters: { location_type: { _in: [ "origin_port"] } },
                          column:false
  
  
  
                      }),
    
                      uuidColumn({
                          Header:"Destination Port",
                          entity: "location",
                          accessor:"destination_port",
                          typeahead_fields: `id name`,
                          dbname: "destination_port_id",
                          filters: { location_type: { _in: [ "destination_port"] } },
  
                          column:false
  
      
                        }),
                        uuidColumn({
                          Header:"Shipping Line",
                          entity: "export_shipping_line",
                          accessor:"export_shipping_line",
                          typeahead_fields: `id name`,
                          dbname: "export_shipping_line_id",
                          column:false
                          // hideAdd:true
  
                        }),
  
                        enumColumn({
                          Header:"Cont-Type",
                          accessor: "container_type",
                          options: enum_container_type,
                          type: "select",
                          add: true,
                          edit: true,
                          column:false
  
                      }),
  
                      enumColumn({
                          Header:"Cargo Type",
                          accessor: "cargo_category_type",
                          options: enum_cargo_category,
                          type: "select",
                          add: true,
                          edit: true,
                          column:false
                          // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
  
                      }),
   
                      datetimeColumn({Header:"ETD Origin", accessor: "etd", rules: null,   column:false, add:true, edit:true}),


                      datetimeColumn({Header:"ETA Destination", accessor: "eta", rules: null,   column:false, add:true, edit:true }),
                       
                    
                      numberColumn({
                        Header: "Ocean freight($)",
                        accessor: "ocean_freight",
                        column:false

                    }),
                   
                       numberColumn({
                          Header: "CMC Charges",
                          accessor: "cmc_charges",
                          min:0

  
                      }),
  
                       numberColumn({
                          Header: "Seal Charges",
                          accessor: "seal_charges",
                          min:0  ,
                          required:null
                      }),

                    


                      
                    //   numberColumn({
                    //     Header: "MUF",
                    //     accessor: "muf",
                    //      }),



                    //  numberColumn({
                    //     Header: "Vessel Risk",
                    //     accessor: "vessel_risk",
                    //     min:0


                    // }),

                  
  
                
  
                       numberColumn({
                          Header: "Toll Charges",
                          accessor: "toll_charges",
                        
                          min:0
  
                      }),

                         
                      numberColumn({
                        Header: "Dollar Price",
                        accessor: "dollar_price",

                    }),
                    numberColumn({
                        Header: "Transit Period",
                        accessor: "transit_period",
                    }),
                    numberColumn({
                        Header: "Bill of Lading Charges",
                        accessor: "bill_of_lading_charges",
                          min:0


                       }),

                       numberColumn({
                        Header: "Terminal Handling Charges",
                        accessor: "terminal_handling_charges",
                        minWidth:150,
                          min:0


                    }),

                      numberColumn({
                          Header: "Temp Variation Charges",
                          accessor: "temp_variation_charges",
                          min:0
  
                      }),
                          
                      defaultColumn({ accessor: "vessel", column:false,}),
                   
                      dateColumn({ accessor: "cut_off", column:false,}),
  
                      booleanColumn({Header:"Active",accessor:"active",add:true, edit:true, column:false,}),
                      
                      dateColumn({
                          accessor: "created_at",
                          rules: null,
                          displayOnly: true,
                          column:true,
                      }),
                      dateColumn({
                          accessor: "updated_at",
                          rules: null,
                          isSorted: true,
                          isSortedDesc: true,
                          column:false
                      }),
                  ],
                };
                break;
                case "other_freight_forwarder":
                    options = {
                      entity: "export_freight",
                      fields: ` 
                      id
                      uid
                      container_type enum_container_type { comment}
                      origin_port_id origin_port{id name address}
                      destination_port_id destination_port{id name address}
                      eta
                      etd
                      dollar_price 
                      ocean_freight
                      cargo_category_type enum_cargo_category{comment}
                      bill_of_lading_charges
                      cmc_charges
                      seal_charges
                      temp_variation_charges
                      terminal_handling_charges
                      toll_charges
                      listing_validity_date
                      export_shipping_line_id
                      export_shipping_line{
                        id
                        name
                        images
                      }
                      cut_off
                      transit_period
                      vessel
                      muf
                      vessel_risk
                      total_amount
                      validity 
                      active
                      customer{
                          id
                          name
                        }
                      
                      
                      freight_rank{
                      id
                      rank
                      }
      
                      created_at
                      updated_at
                         `, 
                      columns: [
                          linkColumn({
                              Header: "Id",
                              // displayOnly: true,
                              accessor: "uid",
                              // link: `/web/exporter/detail/#id`,
                              // link_id:"customer_id",
                              field:"uid",
                              add: false,
                              edit: false,
                              column:false,
                              searchable: false,
                              minWidth:150,
                              // miniColumnWidth:150
      
                           }),
      
                           uuidColumn({
                              Header:"Origin Port",
                              entity:"location",
                              accessor: "origin_port",
                              typeahead_fields: `id name`,
                              dbname: "origin_port_id",
                              labelKey:"name",
                              filters: { location_type: { _in: [ "origin_port"] } },
                              filterSpan:3,
                              span:3,
                              distinctOn:true,
                              distinctOnLabel:"origin_port.name"
      
                          }),
        
                          uuidColumn({
                              Header:"Destination Port",
                              entity: "location",
                              accessor:"destination_port",
                              typeahead_fields: `id name`,
                              dbname: "destination_port_id",
                          
                              filters: { location_type: { _in: [ "destination_port"] } },
                              filterSpan:3,
                              span:3,
                              distinctOn:true,
                              distinctOnLabel:"destination_port.name"
                            }),
      
                          enumColumn({
                              Header:"Container",
                              accessor: "container_type",
                              options: enum_container_type,
                              type: "select",
                              add: true,
                              edit: true,
                              column: true,
                              shareWith:enum_cargo_category,
                              shareValue:"cargo_category_type",
                              width:90,
                          }),
      
                                              
                          uuidColumn({
                              Header:"Carrier",
                              entity: "export_shipping_line",
                              accessor:"export_shipping_line",
                              typeahead_fields: `id name`,
                              dbname: "export_shipping_line_id",
                              searchable:false,
                              filterable:false
                              // hideAdd:true
                            }),
      
      
      
      
      
                          enumColumn({
                              Header:"Cargo",
                              accessor: "cargo_category_type",
                              options: enum_cargo_category,
                              type: "select",
                              add: true,
                              edit: true,
                              column: true,
                              filterable:false,
                              // minWidth:80,
                              // width:80,
                              // filterable:true
                              // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
      
                          }),
      
      
              
                          
      
      
                          // dateColumn({ accessor: "cut_off",rules: null,   column: true, add:true, edit:true }),
                          dateColumn({ accessor: "etd", rules: null,   column: true, add:true, edit:true, }),
                          dateColumn({FilterFormHeader:"MAX ETA", Header:"ETA", accessor: "eta", rules: null,   column: true, add:true, edit:true,filterable:true  }),
                       
                          numberColumn({
                              Header: "freight($)",
                              accessor: "ocean_freight",
                              // minWidth:80,
                              // width:80,
                          }),
      
      
                        //   defaultColumn({Header:"Rank", accessor: "freight_rank.rank" }),
                                              
                          // numberColumn({
                          //     Header: "Freight($)",
                          //     accessor: "total_amount",
                          //     displayOnly:true
                          // }),
      
      
      
                          //   uuidColumn({
                          //     Header:"Freight Forwarder",
                          //     entity:"customer",
                          //     typeahead_fields: `id name`,
                          //     searchable:false,
                          //     filterable:false
      
                          //   }),
      
                          numberColumn({
                              Header: "MUC Charges",
                              accessor: "muf",
                              column:false,
      
                          }),
      
      
                          numberColumn({
                              Header: "vessel_risk Charges",
                              accessor: "vessel_risk",
                              column:false,
      
                          }),
      
                           
                           numberColumn({
                              Header: "Bill of Lading Charges",
                              accessor: "bill_of_lading_charges",
                              column:false,
      
                          }),
      
                          numberColumn({
                              Header: "Exchange Rate",
                              accessor: "dollar_price",
                              column:false,
      
                          }),
                           numberColumn({
                              Header: "CMC Charges",
                              accessor: "cmc_charges",
                              column:false,
      
                          }),
      
                           numberColumn({
                              Header: "Seal Charges",
                              accessor: "seal_charges",
                              column:false,
                              notRequired:true
      
      
                          }),
                          numberColumn({
                              Header: "Temp Variation Charges",
                              accessor: "temp_variation_charges",
                              column:false,
                              dependsValue: ["cargo_category_type:reefer"],
      
                          }),
                        
                          numberColumn({
                              Header: "Terminal Handling Charges",
                              accessor: "terminal_handling_charges",
                              column:false,
      
                          }),
      
                    
      
                           numberColumn({
                              Header: "Toll Charges",
                              accessor: "toll_charges",
                              column:false,
      
                          }),
      
                          // numberColumn({
                          //     Header: "Transit Days",
                          //     accessor: "transit_period",
                          // }),
      
                          // enumColumn({ accessor: "status", options: enum_freight_transit_status, type: "select",    searchable: false, }),
                          numberColumn({
                              Header: "Transit Period",
                              accessor: "transit_period",
                              column:false,
      
                          }),
                          
                              
                          defaultColumn({Header:"Vessel Name", accessor: "vessel", column:false,}),
                         
                          // dateColumn({ accessor: "validity", rules: null,   column: true, add:true, edit:true }),
                             
                          booleanColumn({Header:"Active",accessor:"active",add:true, edit:true, column:false, searchable:false, filterable:false}),
                          
                          datetimeColumn({
                              accessor: "listing_validity_date",
                              rules: null,
                              // displayOnly: true,
                              add:true,
                              edit:true,
                              column:false,
                          }),
                          datetimeColumn({
                              accessor: "created_at",
                              rules: null,
                              displayOnly: true,
                              column:false,
                          }),
                          datetimeColumn({
                              accessor: "updated_at",
                              rules: null,
                              isSorted: true,
                              isSortedDesc: true,
                              column:false
                          }),
      
      
                          
      
                      ],
                    };
                    break;
                
              case "export_shipping_line":
                options = {
                 entity  : "export_shipping_line",
                 fields:` uid images name created_at updated_at active`,
                 columns: [
                    linkColumn({
                        Header: "Id",
                        // displayOnly: true,
                        accessor: "uid",
                        // link: `/web/exporter/detail/#id`,
                        // link_id:"customer_id",
                        field:"uid",
                        add: false,
                        edit: false,
                        column:true,
                        searchable: false,

                     }),

                   
                     defaultColumn({Header:"Shipping line Name", accessor: "name", type: "text", rules: null,  }),

                     imageColumn({
                      Header:"Logo",
                      type: "photo",
                      accessor: "images",
                      accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                      rules: null,
                      hideAdd:true
                  }),


           

                    datetimeColumn({
                        accessor: "created_at",
                        rules: null,
                        displayOnly: true,
                        column:true,
                    }),
                    datetimeColumn({
                        accessor: "updated_at",
                        rules: null,
                        isSorted: true,
                        isSortedDesc: true,
                        column:true
                    }),
                    activeColumn()
                ],
                };

                break;


              case "manage_order":
                options = {
                  entity: "export_freight_order",
                  fields: ` id uid
                  exporter_id exporter{ id name phone }
                  export_freight_id export_freight{ id ocean_freight }
                  invoice
                  transportation_slip bl_slip do_slip   created_at updated_at active
                  
                  vgm_cut_off
                  gate_cut_off
                      
                  
                  status
                  payment_status
                   
                  invoice_no
                  invoice_amount
                   
                  draft_bl_slip
                  booking_validity_status
                  shipping_ins_status

                  draft_bl_status
                  vgm_status

                  booking_validity
                  shipping_instructions_cut_off
                  vgm_cut_off_date
                  gate_opens
                  bl_approved
                  gate_in_cut_off_date
                     `, 
                  columns: [
                      linkColumn({
                          Header: "Id",
                          // displayOnly: true,
                          accessor: "uid",
                          link: `/web/manage_order/detail/#id`,
                          // link_id:"customer_id",
                          field:"uid",
                          add: false,
                          edit: false,
                          column:true,
                          searchable: false,
  
                       }),
  
                       uuidColumn({
                           Header:"Exporter Name",
                           entity:"customer",
                           typeahead_fields: `id name`, 
                           accessor: "exporter",  
                           dbname:"exporter_id",
                           rules: null,  
                           edit:false,
                        }),
            
                       defaultColumn({Header:"Exporter Ph.", accessor: "exporter.phone", type: "text",minWidth:125 ,  rules: null, edit:false,minWidth:150   }),
                       defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", edit:false, type: "text", rules: null,   searchable:false  }),

           

                        enumColumn({
                            accessor: "payment_status",
                            options: enum_export_freight_order_payment_status,
                            type: "select",
                            add: false,
                            edit: false,
                            column: true,
                            rules:null,
                            searchable:false
    
                        }),
                        booleanColumn({accessor:"bl_approved", add:false, edit:false,}),



                        enumColumn({
                            accessor: "status",
                            options: enum_freight_order_status,
                            type: "select",
                            add: true,
                            edit: true,
                            column: true,
                            rules:null,
                            searchable:false
    
                        }),
                        imageColumn({
                            Header:"Transportation Slip",
                            type: "photo",
                            accessor: "transportation_slip",
                            key:"transportation_slip",
                            id:"transportation_slip",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                            rules: null,
                            column:true,
                            minWidth:125,
                            searchable:false,
                            dependsValue: ["status:completed", ],                    
    
                        }),  


                        
             
                     
 
                       


                    imageColumn({
                        Header:"DO Slip(PDF)",
                        type: "document",
                        accessor: "do_slip",
                        id: "do_slip",
                        key:"do_slip",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        column:true,
                        add:true,
                        edit:true,
                        minWidth:125,
                        searchable:false,
                        // dependsValue: ["status:completed", ],        
                        privateImage:true            

                    }),
                    imageColumn({
                        Header:"Draft Bl Slip(PDF)",
                        type: "document",
                        accessor: "draft_bl_slip",
                        key:"draft_bl_slip",
                        id:"draft_bl_slip",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        column:true,
                        minWidth:125,
                        searchable:false,
                        // dependsValue: ["status:completed", ],         
                        privateImage:true
                    }),

                    imageColumn({
                        Header:"VGM cut off",
                        type: "document",
                        accessor: "vgm_cut_off",
                        id: "vgm_cut_off",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        add:false,
                        edit:false,
                        column:true,
                        minWidth:80,
                        width:100   ,
                        privateImage:true               


                    }),



                    
                    defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null, add:false, edit:false  }),
                    numberColumn({ accessor: "invoice_amount", add:false, edit:false }),
 

                    enumColumn({ accessor: "booking_validity_status", options: enum_exp_fri_ord_booking_val_status, type: "select",  
                    add: false,
                    edit: false,   searchable: false, }),

                    enumColumn({ accessor: "shipping_ins_status", options: enum_exp_fri_ord_shipping_ins_status, type: "select",  add: false,
                    edit: false,
                       searchable: false, }),

                    enumColumn({ accessor: "draft_bl_status", options: enum_exp_fri_ord_draft_bl_status , type: "select",  add: false,
                    edit: false,
                       searchable: false, }),
                 
                    enumColumn({ accessor: "vgm_status", options: enum_exp_fri_ord_vgm_status , type: "select",   add: false,
                    edit: false,
                      searchable: false, }),

                    datetimeColumn({
                        accessor: "booking_validity",
                        rules: null,
                        column:true,
                        add:true,
                        edit:true
                    }),

                    datetimeColumn({
                        Header:"Shipping Ins ",
                        accessor: "shipping_instructions_cut_off",
                        rules: null,
                        // displayOnly: true,
                        column:true,
                        add:true,
                        edit:true
                    }),   


                    datetimeColumn({
                        accessor: "vgm_cut_off_date",
                        rules: null,
                        column:true,
                        add:true,
                        edit:true
                    }),
                     datetimeColumn({
                        accessor: "gate_opens",
                        rules: null,
                        column:true,
                        add:true,
                        edit:true
                    }),

                    datetimeColumn({
                        accessor: "gate_in_cut_off_date",
                        rules: null,
                        column:true,
                        add:true,
                        edit:true
                    }),

                    imageColumn({
                        Header:"CLP/Form13(PDF)",
                        type: "document",
                        accessor: "gate_cut_off",
                        id:'gate_cut_of',
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        add:false,
                        edit:false,
                        column:true,
                        minWidth:80,
                        width:100        ,
                        privateImage:true               
       

                    }),

                    imageColumn({
                        Header:"OBL Slip(PDF)",
                        type: "document",
                        accessor: "bl_slip",
                        key:"bl_slip",
                        id:"bl_slip",
                        accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                        rules: null,
                        column:true,
                        minWidth:125,
                        searchable:false,
                        // dependsValue: ["status:completed", ],    
                        privateImage:true                

                    }),
                      datetimeColumn({
                          accessor: "created_at",
                          rules: null,
                          displayOnly: true,
                          column:true,
                      }),
                    //   datetimeColumn({
                    //       accessor: "updated_at",
                    //       rules: null,
                    //       isSorted: true,
                    //       isSortedDesc: true,
                    //       column:true
                    //   }),
                      activeColumn()
                  ],
                };
                break;


                case "invoiceHistory":
                options = {
                    showPo:true,
                    entity: "export_order",
                    // disableAdd:true,
                    method:"post",
                    subUrl:"/user/grnPdfWithFields",                                          
                    docType:"pdf",
                    fields: `
                    id uid supplier_id supplier{id name} customer_id customer{ id name }  
                    location_id location{id name address}
                    active status total_amount type_of_packaging     commodity_id
                    commodity{ id name }
                    
                    price_per_pack commodity_spec wt_per_pack  no_of_box hs_code quantity_in_mt created_at updated_at`,
                    columns: [
                            linkColumn({
                                Header: "Id",
                                // displayOnly: true,
                                accessor: "uid",
                                link: `/web/exporter/detail/#id`,
                                link_id:"customer_id",
                                field:"uid",
                                add: false,
                                edit: false,
                                column:true,
                                searchable: false,

                             }),
                        uuidColumn({ entity: "commodity", typeahead_fields: `id name `, 
                        hideAdd: true,
                        filters: {  origin_type : { _in: [ "non_domestic"] } },
                        }),
                        uuidColumn({
                            Header:"Supplier",
                            entity: "customer",                        
                            dbname: "supplier_id",
                            link: `/web/exporter/detail/#id`,
                            link_id:"supplier_id",
                            accessor:"supplier",
                            filters: { customer_type: { _in: [ "supplier"] } },
                            typeahead_fields: `id name type:address`, 
                             }),
                        enumColumn({ accessor: "status", options: enum_po_status, type: "select",    searchable: false, }),
                        defaultColumn({ accessor: "commodity_spec", type: "text", column:false ,  rules:{required:"This Field is required"}}),
                        defaultColumn({Header:"Quanitiy in kgs", accessor: "quantity_in_mt", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        enumColumn({ accessor: "type_of_packaging", options: enum_int_packaging_type, type: "select", column:false }),
                        defaultColumn({ accessor: "hs_code", type: "text",  column:false , rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"WT Per PACK ( KGS )",accessor: "wt_per_pack", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"No Of Pack/Boxes",accessor: "no_of_box", type: "text",   column:false, rules:{required:"This Field is required"}}),
                        defaultColumn({ Header:"Price per Pack/ Boxes",accessor: "price_per_pack", type: "text",  column:false, rules:{required:"This Field is required"}}),
                        uuidColumn({ 
                            entity: "location", 
                            typeahead_fields: `id name  address type:address `, 
                            rules:{required:"This Field is required"}               ,
                        }),

                       
                        defaultColumn({ accessor: "total_amount", type: "text",    rules:{required:"This Field is required"}}),

                        datetimeColumn({ accessor: "created_at", rules: null, displayOnly: true, column: true, }),
                        datetimeColumn({ accessor: "updated_at", rules: null,   isSorted: true, isSortedDesc: true, }),
                        activeColumn(),
                        
                    ],
                };
            break;

  
                case "user_manage_order":
                    options = {
                      entity: "export_freight_order",
                      fields: ` id uid
                      exporter_id exporter{ id name phone }
                      export_freight_id export_freight{ id ocean_freight  customer {id name}  
                      export_shipping_line {id name} }
                       
                      transportation_slip bl_slip do_slip status created_at updated_at active
                       
                      vgm_cut_off
                      gate_cut_off
                      payment_status enum_export_freight_order_payment_status {comment value}
                       
                      gate_opens
                       
                      draft_bl_slip

                      bl_approved

                      shipping_ins_status

                      draft_bl_status

                      vgm_status
                       
                      bl_approved
                       
                      invoice
                      invoice_no
                      invoice_amount
                      booking_validity_status

                      booking_validity
                      shipping_instructions_cut_off
                      vgm_cut_off_date
                      gate_opens
                      gate_in_cut_off_date

                      shipping_containers {
                        uid
                          seal_no
                          container_no
                          created_at
                          updated_at
                      }

                      shipping_ins_status
                      shipping_instructions {
                        bookingno
                        consigneenameandaddress
                        created_at
                        customer {
                          email
                          name
                        }
                        emailaddress
                        freightcharges
                        id
                        marksandnumbers
                        measurement
                        notifypartynameandaddress
                        numberandkindofpackagesdescriptionofgoods
                        numberoforiginals
                        order_id
                        placeofdelivery
                        placeofreceipt
                        portofdischarge
                        portofloading
                        remarks
                        shippernameandaddress
                        shippingline
                        telno
                        totalnumberofcontainer
                        uid
                        updated_at
                        vessel
                        weightinkgormt
                      }
                         `, 
                      columns: [
                          linkColumn({
                              Header: "Id",
                              // displayOnly: true,
                              accessor: "uid",
                            //   link: `/web/exp_blr/detail/#id`,
                              link: `/web/user_manage_order/detail/#id`,
                              // link_id:"customer_id",
                              field:"uid",
                              add: false,
                              edit: false,
                              column:true,
                              searchable: false,
      
                           }),
      
                         
                           uuidColumn({
                               Header:"Freight Forwarder",
                               entity:"customer",
                               typeahead_fields: `id name`, 
                               accessor: "export_freight.customer.name",  
                               dbname:"exporter_id",
                               rules: null,  
                               edit:false,
                            }),
                            uuidColumn({
                                Header:"Shippling Line",
                                entity:"export_shipping_line",
                                typeahead_fields: `id name`, 
                                accessor: "export_freight.export_shipping_line.name",  
                                dbname:"export_shipping_line_id",
                                rules: null,  
                                edit:false,
                             }),
                             
                           defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", edit:false, type: "text", rules: null, minWidth:150 }),
                  
                           enumColumn({
                            accessor: "status",
                            options: enum_freight_order_status?.filter(e =>  ["awaiting_confirmation","cancelled"]?.includes(e.value)),
                            type: "select",
                            add: false,
                            edit: false,
                            column: true,
                            rules:null,
                            minWidth:150
    
                        }),

                        enumColumn({
                            Header:"Payment",
                            accessor: "payment_status",
                            options: enum_export_freight_order_payment_status,
                            type: "select",
                            add: false,
                            edit: false,
                            column: true,
                            rules:null,
                            searchable:false
    
                        }),

                        booleanColumn({accessor:"bl_approved", add:false, edit:false,}),


                        
                        
      
                        imageColumn({
                            Header:"Transportation Slip(image)",
                            type: "photo",
                            accessor: "transportation_slip",
                            key:"transportation_slip",
                            id:"transportation_slip",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                            rules: null,
                            edit:false,
                            column:true,
                            minWidth:125
                        }),
    
 

                        
                        imageColumn({
                            Header:"DO Slip",
                            type: "photo",
                            accessor: "do_slip",
                            key:"do_slip",
                            id:"do_slip",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                            rules: null,
                            edit:false,
                            column:true,
                            minWidth:125


                        }),

                        imageColumn({
                            Header:"Draft Bl Slip",
                            type: "document",
                            accessor: "draft_bl_slip",
                            key:"draft_bl_slip",
                            id:"draft_bl_slip",
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            add:false,
                            edit:false,
                            column:true,
                            minWidth:125,
                            searchable:false,
                            // dependsValue: ["status:completed", ],       
                            privateImage:true             
                        }),

                        


                        imageColumn({
                            Header:"VGM cut off(PDF)",
                            type: "document",
                            accessor: "vgm_cut_off",
                            id: "vgm_cut_off",
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            add:true,
                            edit:true,
                            column:true,
                            minWidth:80,
                            width:100           ,
                            privateImage: true,       

                        }),
                        imageColumn({
                            Header:"CLP/Form13(PDF)",
                            type: "document",
                            accessor: "gate_cut_off",
                            id:'gate_cut_of',
                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                            rules: null,
                            add:true,
                            edit:true,
                            column:true,
                            minWidth:80,
                            width:100        ,
                            privateImage:true               
        

                        }),
  
                        imageColumn({
                            Header:"OBL Slip",
                            type: "document",
                            accessor: "bl_slip",
                            key:"bl_slip",
                            id:"bl_slip",
                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                            rules: null,
                            add:false,
                            edit:false,
                            column:true,
                            minWidth:125,
                            privateImage:true             
                        }),


    

    
    
                        defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null, add:false, edit:false  }),
                        numberColumn({ accessor: "invoice_amount", add:false, edit:false }),
                           
                        enumColumn({ accessor: "booking_validity_status", options: enum_exp_fri_ord_booking_val_status, type: "select",    searchable: false, }),

                        enumColumn({ accessor: "shipping_ins_status", options: enum_exp_fri_ord_shipping_ins_status, type: "select",    searchable: false, }),
    
                        enumColumn({ accessor: "draft_bl_status", options: enum_exp_fri_ord_draft_bl_status , type: "select",    searchable: false, }),
                     
                        enumColumn({ accessor: "vgm_status", options: enum_exp_fri_ord_vgm_status , type: "select",    searchable: false, }),
    
                        datetimeColumn({
                            accessor: "booking_validity",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
    
                        datetimeColumn({
                            Header:"Shipping Ins ",
                            accessor: "shipping_instructions_cut_off",
                            rules: null,
                            // displayOnly: true,
                            column:true,
                            add:true,
                            edit:true
                        }),   
    
    
                        datetimeColumn({
                            accessor: "vgm_cut_off_date",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
                         datetimeColumn({
                            accessor: "gate_opens",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
    
                        datetimeColumn({
                            accessor: "gate_in_cut_off_date",
                            rules: null,
                            column:true,
                            add:true,
                            edit:true
                        }),
    
      
                          datetimeColumn({
                              accessor: "created_at",
                              rules: null,
                              displayOnly: true,
                              column:true,
                          }),
                        //   datetimeColumn({
                        //       accessor: "updated_at",
                        //       rules: null,
                        //       isSorted: true,
                        //       isSortedDesc: true,
                        //       column:true
                        //   }),
                          activeColumn()
                      ],
                    };
                    break;
                
                  case "order_note":
                    options = {
                        entity: "order_note",
                        fields: `  
                        id
                        active
                        customer_id
                        order_id
                        note
                        created_at
                        updated_at
                        `,
                            columns: [
                                defaultColumn({
                                    Header: "UID",
                                    accessor: "uid",
                                    add:false,
                                    edit:false,
                                    column:false

                                }),
    
                                uuidColumn({
                                    Header:"Customer",
                                    entity: "customer",
                                    typeahead_fields: `id name`,
                                    add:false,
                                    edit:false,
                                    column:false
                                
                                }),
 
 
                                defaultColumn({
                                    Header:"Notes",
                                    accessor: "note",
                                    type: "textarea",
                                    add:true,
                                    edit:true
                    
                                }),

                      
    
                            ],
                    }
                 break;
      

                  case  "inquiries":
                        options = {
                            entity: "inquiries",
                             // timestamp
                            fields: `  
                            id
                            uid
                            customer_id
                            customer{
                                id
                                name
                            }
                            quotations{
                                id
                                b_l_charges
                                basic_freight_in_usd
                                cargo_weight_per_container_in_mt
                                container_category
                                container_count
                                container_type
                                created_at
                                commodity_id
                                exchange_rate_in_inr
                                free_days
                                incoterm
                                inquiry_id
                                origin_port_id
                                sailing_date
                                seal_charges
                                terminal_handling_charges
                                toll_charges
                                transit_days
                            }
                            ventilation
                            commodity 
                             
                            origin_port_id
                            origin_port {
                              id
                              name
                            }
                            destination_port_id
                            destination_port {
                              id
                              name
                            }
                            incoterm_type
                            enum_incoterm {
                              comment
                              value
                            }
                            no_of_containers
                            container_type
                            cargo_type
                            temperature
                            humidity
                            enum_cargo_category {
                              comment
                              value
                            }
                            weight_in_mt
                            cargo_description
                            free_days_at_des  
                              enum_free_days_des_type {
                                comment
                                value
                              }
                            
                            stuffing_location_type
                            enum_stuffing_location_type {
                              comment
                              value
                            }
                            custom_clearance_required
                            cus_cl_at_origin
                            cus_cl_at_destination
                            transport_required
                            pickup_address
                            drop_off_address
                            cargo_readiness
                            inquiry_type
                            enum_inquery_type {
                              value
                              comment
                            }
                            freight_indication_in_usd
                            pickup_address
                            preferred_carrier
                            inquiry_expiry
                            notes
                            created_by_id
                            created_by{
                                id name customer_type
                            }
                            created_at
                            updated_at
                        
                                `,
                                columns: [
                                    defaultColumn({
                                        Header: "UID",
                                        accessor: "uid",
                                        add:false,
                                        edit:false,
                                        minWidth:30
                                    }),

                      
        
                                    uuidColumn({
                                        Header:"Customer",
                                        entity: "customer",
                                        typeahead_fields: `id name`,
                                        column:false
                                    }),
    
                                    uuidColumn({
                                        Header:"Created By",
                                        entity: "customer",
                                        accessor:"created_by",
                                        typeahead_fields: `id name type:role`,
                                        add:false,
                                        edit:false,
                                        column:false

                                    }),
    
                                    defaultColumn({
                                        Header:"Created Role",
                                        accessor:"created_by.customer_type",
                                        add:false,
                                        edit:false               ,
                                        column:false

                                     }),
                                     defaultColumn({
                                        Header:"Commodity",
                                        accessor:"commodity",
                                                                  
                                     }),
                                    uuidColumn({
                                        Header:"Origin Port",
                                        entity: "location",
                                        accessor:"origin_port",
                                        dbname:"origin_port_id",
                                         filters: { location_type: { _in: ["origin_port"] } },
                                        typeahead_fields: `id name`,
                                        minWidth:80

                                        // filter_fields: [{ accessor: "customer" }],
                                    }),
    
                                    uuidColumn({
                                        Header:"Destination Port",
                                        entity: "location",
                                        accessor:"destination_port",
                                        dbname:"destination_port_id",
                                        filters: { location_type: { _in: ["destination_port"] } },
                                        typeahead_fields: `id name`,
                                        minWidth:80

                                        // filter_fields: [{ accessor: "customer" }],
                                    }),
    
                                    // enumColumn({
                                    //     Header:"IncoTerm",
                                    //     accessor: "incoterm_type",
                                    //     options: enum_incoterms_type,
                                    //     type: "select",
                                    // }),
                                    defaultColumn({
                                        Header:"Freight indication(USD)",
                                        accessor: "freight_indication_in_usd",
                                        rules:null,
                                        // column:false 

                                        // dependsCollapse:"additional_preferences"
                                    }),
                                    defaultColumn({Header:"Cargo Details",onlyHeader:true, column:false,  span:12}),
    
                                    defaultColumn({
                                        Header:"No of Containers",
                                        accessor: "no_of_containers",
                                        type:"text",
                                        minWidth:30
                                    }),
    
                                    enumColumn({
                                        Header:"Container Type",
                                        accessor: "container_type",
                                        options: enum_container_type,
                                        type: "select",
                                        add: true,
                                        edit: true,
                                        column: true,
                
                                    }),
    
    
    
                                    defaultColumn({
                                        Header:"Weight(MT)",
                                        accessor: "weight_in_mt",
                                    }),
    
    
    
    
                                    enumColumn({
                                        Header:"Cargo",
                                        accessor: "cargo_type",
                                        options: enum_cargo_category,
                                        type: "select",
                                    }),
    
                                    defaultColumn({
                                        Header:"Temperature",
                                        accessor: "temperature",
                                        dependsValue:["cargo_type:reefer"],
                                        minWidth:30
                                    }),
    
    
                                    defaultColumn({
                                        Header:"Humidity",
                                        accessor: "humidity",
                                        dependsValue:["cargo_type:reefer"],
                                        minWidth:30

                                    }),
    
                                 
    
                                    // enumColumn({
                                    //     Header:"Stuffing Location",
                                    //     accessor: "stuffing_location_type",
                                    //     options: enum_stuffing_location_type,
                                    //     type: "select",
                                    // }),
    
                                    
                                    defaultColumn({
                                        Header:"Cargo Descriptiosn",
                                        accessor: "cargo_description",
                                        type:"textarea",
                                        rules:null,
                                        column:false

                                    }),
    
    
                                    defaultColumn({
                                        Header:"Ventilation",
                                        accessor: "ventilation",
                                        type:"textarea",
                                        rules:null,
                                        column:false,
                                        add:true,
                                        edit:true

                                    }),
    
                                    defaultColumn({Header:"Additional Services",  accessor:"", type:"collapse", id:"additional_services", column:false, span:12,}),
                                
                                    booleanColumn({accessor:"custom_clearance_required", dependsCollapse:"additional_services",add:false, edit:false, column:false}),
                                   
                                    booleanColumn({Header:"At Origin",accessor:"cus_cl_at_origin",  dependsValue:["custom_clearance_required:true"],  column:false}),
    
                                    booleanColumn({Header:"At Destination",accessor:"cus_cl_at_destination", dependsValue:["custom_clearance_required:true"], column:false}),
    
    
       
                                    booleanColumn({accessor:"transport_required",dependsCollapse:"additional_services",add:false, edit:false, column:false}),
                                    
    
                                     defaultColumn({
                                        Header:"Pickup",
                                        accessor: "pickup_address",
                                        rules:null,
                                        column:false,
                                        dependsValue:["transport_required:true"]
                                    }),
    
    
                                    defaultColumn({
                                        Header:"DropOff",
                                        accessor: "drop_off_address",
                                        rules:null,
                                        column:false,
                                        dependsValue:["transport_required:true"]
                                    }),
    
                                    // defaultColumn({Header:"Preferences", id:"additional_preferences",type:"collapse", column:false, span:12}),
    
    
                                    
                                    // defaultColumn({Header:"Additional Services",  accessor:"", type:"collapse", id:"additional_services", column:false, span:12,}),
                                    // booleanColumn({accessor:"custom_clearance_required", dependsCollapse:"additional_services",add:false, edit:false,}),
                                    // booleanColumn({accessor:"transport_required",dependsCollapse:"additional_services",add:false, edit:false}),
                                    
    
    
                                    
    
                                    // enumColumn({
                                    //     accessor: "inquiry_type",
                                    //     options: enum_inquery_type,
                                    //     type: "select",
                                    //     rules:null,
                                    //     add:false,
                                    //       edit:false,
                                    //     dependsCollapse:"additional_preferences"
                                    // }),
    

    
    
                                    //    enumColumn({
                                    //     accessor: "free_days_at_des",
                                    //     options: enum_free_days_des_type,
                                    //     type: "select",
                                    //     rules:null,
                                    //     add:false, edit:false,
                                    //     dependsCollapse:"additional_preferences"
                                    // }),
    
     
                                    
                                    // defaultColumn({
                                    //     Header:"Preferred Carrier",
                                    //     accessor: "preferred_carrier",
                                    //     rules:null,
                                    //     add:false, edit:false,
                                    //     dependsCollapse:"additional_preferences"
                                    // }),
            
    
                            dateColumn({ accessor: "inquiry_expiry",rules:null,add:false, edit:false,dependsCollapse:"additional_preferences" , column:false}),
                            dateColumn({ accessor: "cargo_readiness",rules:null ,add:false, edit:false,dependsCollapse:"additional_preferences", column:false}),
                            
                            // defaultColumn({Header:"Additional Notes",id:"additional_notes",type:"collapse", column:false, span:12}),
                            
                            // defaultColumn({
                            //     Header:"Notes",
                            //     accessor: "notes",
                            //     type: "textarea",
                            //     rules:null,
                            //     add:false,
                            //     edit:false,
                            //     dependsCollapse:"additional_notes"
    
                            // }),
    
                          
        
                                ],
                        }
                     break;

                    case "export_order_payment":
                        options={
                            entity : "export_order_payments",
                            fields: `   
                            id
                            active
                            uid
                             
                              
                            total_payed_amount
                            status enum_export_payment_status {comment}
                            export_order_id
                            export_order {
                                id
                                uid
                                name
                                total_amount
            
                                customer {
                                    id
                                    name
                                }
            
                                supplier{
                                    id
                                    name
                                }
            
                            }
                          
                            created_at
                            updated_at
                            
                           
                            `,
                            sortBy: [{ id: "status", desc: true }],
                            columns: [
                                    linkColumn({
                                        Header: "Id",
                                        displayOnly: true,
                                        accessor: "uid",
                                        link_id:"id",
                                        field:"uid",
                                        // link: `/management/exporter_payment/detail/#id`,
                                        add: false,
                                        edit: false,
                                        disabled:true
                                    }),
            
                                uuidColumn({
                                    Header:"Order ID",
                                    entity: "export_order",
                                    accessor:"export_order",
                                    dbname:"export_order_id",
                                    typeahead_fields: `id name type: customer{name} `,
                                    filters:{status:{_eq:"completed"}},
                                    add:false,
                                    edit:false
            
                                }),
                                uuidColumn({
                                    Header: "Customer",
                                    accessor: "export_order.customer.name",
                                    edit:false,
                                    add:false
                                }), 
            
                                uuidColumn({
                                    Header: "Supplier",
                                    accessor: "export_order.supplier.name",
                                    edit:false,
                                    add:false
                                }), 
                                
                        
                                
                            
            
            
                                defaultColumn({
                                    Header: "Total Payment",
                                    accessor: "total_payed_amount",
                                    displayOnly:true,
                                    add:false,
                                    edit:false
                                }), 
                                
                             
                                enumColumn({
                                    Header: "Status",
                                    accessor: "status",
                                    type: "select",
                                    options: enum_export_payment_status,
                                    add: true,
                                    edit: true,
                                    column: true,
                                }),
            
                                numberColumn({
                                Header:"Order Total Amount",
                                accessor: "export_order.total_amount",
                                add:false,
                                edit:false }),
            
                               
                                // dateColumn({ accessor: "payment_date" }),
                                
                                datetimeColumn({
                                    accessor: "created_at",
                                    rules: null,
                                    displayOnly: true,
                                }),
                                datetimeColumn({
                                    accessor: "updated_at",
                                    rules: null,
                                    isSorted: true,
                                    isSortedDesc: true,
                                }),
                             
            
                            ],
                        }   
                        break;
                        
                        case "quotations":
                            options = {
                                entity: "quotations",
                                
                                fields: `  

                                id
                                uid
                                b_l_charges
                                basic_freight_in_usd
                                cargo_weight_per_container_in_mt
                                container_category
                                enum_cargo_category {
                                    comment
                                    value
                                  }

                                container_count
                                container_type
                                enum_container_type {
                                    comment
                                    value
                                  }
                                 
                                exchange_rate_in_inr
                                free_days
                                enum_free_days_des_type {
                                    comment
                                    value
                                  }
                                 
                                incoterm
                                enum_incoterms_type {
                                    comment
                                    value
                                  }
                                mandatory_user_charges
                                origin_port_id
                                origin_port{
                                    id name
                                }
                                destination_port_id
                                destination_port{
                                    id name
                                }
                                
                                sailing_date
                                seal_charges
                                toll_charges
                                transit_days
                                enum_freight_transit_status {
                                    comment
                                    value
                                  }

                                  terminal_handling_charges
                                  commodity_id
                                  commodity {
                                    id name
                                    
                                  }

                                updated_at
                                created_at
                                
                            
                                    `,
                                       
                
                
                    
                                 
                                columns: [

                                    defaultColumn({
                                        Header: "UID",
                                        accessor: "uid",
                                        add:false,
                                        edit:false,
                                    }),

                                    numberColumn({
                                        Header: "BL Charges",
                                        accessor: "b_l_charges",
                                    }),


                                    numberColumn({
                                        Header: "Basic Freight(USD)",
                                        accessor: "basic_freight_in_usd",
                                    }),


                                    numberColumn({
                                        Header: "Cargo Weight/Container(MT)",
                                        accessor: "cargo_weight_per_container_in_mt",
                                    }),




                                     numberColumn({
                                        Header: "Container Count",
                                        accessor: "container_count",
                                    }),


                                numberColumn({
                                    Header: "Echange rate (INR)",
                                    accessor: "exchange_rate_in_inr",
                                }),

                                numberColumn({
                                    Header: "Terminal Handling Charges (INR)",
                                    accessor: "terminal_handling_charges",
                                }),

                                
                                 
                                uuidColumn({
                                    entity: "commodity",
                                    accessor:"commodity",
                                    typeahead_fields: `id name`,
                                    filters: { origin_type: { _in: ["non_domestic"] } },

                                    // simpleColumnDepends:{"partnership":true,"proprietorship":true, "pvt_limited_company":true}
        
                                }),

                                

                            enumColumn({
                                accessor: "free_days",
                                options: enum_free_days_des_type,
                                type: "select",
                                rules:null,
                                 
                            }),

                            enumColumn({
                                accessor: "transit_days",
                                options: enum_freight_transit_status,
                                type: "select",
                                rules:null,
                                
                            }),

                            enumColumn({
                                Header:"Container type",
                                accessor: "container_category",
                                options: enum_cargo_category,
                                type: "select",
                                rules:null,
                                
                            }),
                                   enumColumn({
                                    Header:"Container Size",
                                accessor: "container_type",
                                options: enum_container_type,
                                type: "select",
                                rules:null,
                                
                            }),
                         

                            




                            enumColumn({
                                Header:"IncoTerm",
                                accessor: "incoterm",
                                options: enum_incoterms_type,
                                type: "select",
                            }),



                            numberColumn({
                            Header: "Mandatory User Charges",
                            accessor: "mandatory_user_charges",
                        }),

                        // defaultColumn({
                        //     Header: "POD",
                        //     accessor: "pod",
                        // }),


                        // defaultColumn({
                        //     Header: "POL",
                        //     accessor: "pol",
                        // }),

                            
                        uuidColumn({
                            Header:"Origin Port",
                            entity: "location",
                            accessor:"origin_port",
                            dbname:"origin_port_id",
                            typeahead_fields: `id name`,
                            filters: { location_type: { _in: ["origin_port"] } },


                        }),


                            
                        uuidColumn({
                            Header:"Destination Port",
                            entity: "location",
                            accessor:"destination_port",
                            dbname:"destination_port_id",
                            typeahead_fields: `id name`,
                            filters: { location_type: { _in: ["destination_port"] } },


                        }),



                        dateColumn({ accessor: "sailing_date",rules:null }),

 
                        numberColumn({
                            Header: "Seal Charges",
                            accessor: "seal_charges",
                        }),


                          numberColumn({
                            Header: "Toll Charges",
                            accessor: "toll_charges",
                        }) ,

                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                           add:false,
                           edit:false,

                        }),  
        
                     
                        datetimeColumn({
                            accessor: "created_at",
                            rules: null,
                            add:false,
                            edit:false                       
                         }),
                                 
                          
        
                                ],
                            };
                            break;

                            case "sourceFinance":
                                options = {
                                    entity: "sourcing_finance",
                                    fields: `
                                    id
                                    uid  
                                    customer_id
                                    customer {
                                        id
                                        name
                                      }

                                    aadhaar
                                    bank_statement_1_year
                                    gst
                                    gst_3b
                                    pan
                                    created_at
                                    updated_at
                                        `,
                                           
                
                                    columns: [


                                            
                                        defaultColumn({
                                            Header: "UID",
                                            accessor: "uid",
                                            add:false,
                                            edit:false,
                                            minWidth:200
                                        }),

                                        imageColumn({
                                            Header:"Aadhaar",
                                            type: "photo",
                                            accessor: "aadhaar",
                                            id:"aadhaar",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            column:true,
                                            minWidth:200
                                            
                                        }),

                                        imageColumn({
                                            Header:"Bank Statement (1 year)",
                                            type: "document",
                                            // type: "photo",
                                            accessor: "bank_statement_1_year",
                                            id:"bank_statement_1_year",
                                            accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  

                                            // accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            column:true,
                                            minWidth:200


                                        }),

                                        imageColumn({
                                            Header:"GST",
                                            type: "photo",
                                            accessor: "gst",
                                            id:"gst",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            column:true,
                                            minWidth:200


                                        }),


                                        imageColumn({
                                            Header:"Gst 3b",
                                            type: "photo",
                                            accessor: "gst_3b",
                                            id:"gst_3b",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            rules: null,
                                            column:true,
                                            minWidth:200


                                        }),



                                        imageColumn({
                                            Header:"Pan",
                                            type: "photo",
                                            accessor: "pan",
                                            id:"pan",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            rules: null,
                                            column:true,
                                            minWidth:200


                                        }),




                                        
                                    ],
                                };
                                break;
    
            

                                
                        case "factoringFinance":
                                options = {
                                    entity: "factoring_finance",
                                    fields: `
                                    id
                                    uid  
                                    customer_id
                                    customer {
                                      id
                                      name
                                    }

                                    bank_statement_1_year
                                    gst
                                    pan
                                    iec_doc
                                    iec
                                    created_at
                                    updated_at
                                        `,
                                           
                
                                    columns: [


                                            
                                        defaultColumn({
                                            Header: "UID",
                                            accessor: "uid",
                                            add:false,
                                            edit:false,
                                        }),

         
                                        defaultColumn({
                                            Header: "Iec",
                                            accessor: "iec",
                          
                                        }),

                                        imageColumn({
                                            Header:"Bank Statement (1 year)",
                                            type: "photo",
                                            accessor: "bank_statement_1_year",
                                            id:"bank_statement_1_year",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            rules: null,
                                            column:true,
                                            minWidth:200


                                        }),

                                        imageColumn({
                                            Header:"GST",
                                            type: "photo",
                                            accessor: "gst",
                                            id:"gst",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            rules: null,
                                            column:true,
                                            minWidth:200


                                        }),
 


                                        imageColumn({
                                            Header:"Pan",
                                            type: "photo",
                                            accessor: "pan",
                                            id:"pan",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            rules: null,
                                            column:true,
                                            minWidth:200


                                        }),

                                        imageColumn({
                                            Header:"IEC",
                                            type: "photo",
                                            accessor: "iec_doc",
                                            id:"iec_doc",
                                            accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                            rules: null,
                                            column:true,
                                            minWidth:200


                                        }),

 
                                    ],
                                };
                                break;


                                case "shipping_instructions":
                                options = {
                                    entity: "shipping_instructions",
                                    fields: `
                                    id
                                    uid   
                                    customer_id
                                    customer {
                                      id
                                      name
                                    }

                                    order_id
                                    numberoforiginals
                                    freightcharges
                                    shippernameandaddress
                                    bookingno
                                    telno
                                    emailaddress
                                    consigneenameandaddress
                                    notifypartynameandaddress
                                    shippingline
                                    placeofreceipt
                                    placeofdelivery
                                    portofloading
                                    portofdischarge
                                    vessel
                                    marksandnumbers
                                    numberandkindofpackagesdescriptionofgoods
                                    weightinkgormt
                                    measurement
                                    totalnumberofcontainer
                                     
                                     
                                    remarks
                                    created_at
                                    updated_at

                                  
                                        `,
                                           
                
                                    columns: [


                                            
                                        defaultColumn({
                                            Header: "UID",
                                            accessor: "uid",
                                            add:false,
                                            edit:false
                                        }),


                                        uuidColumn({
                                            Header:"Customer",
                                            entity: "customer", 
                                            typeahead_fields: `id name`, 
                                            hideAdd: true,
                                            dbname:"customer_id",
                                            add:false,
                                            edit:false,
                                            column:false,

                                            // labelKey:"commodity: {name}",
                                            // filters: {  origin_type : { _in: [ "non_domestic"] } },
                                            // filter_fields: [{ accessor: "supplier", nestedAccessor:"customer_int_commodities.customer_id" }],
                                            }),

                                        
                                            uuidColumn({
                                                Header:"Order",
                                                entity: "export_freight_order", 
                                                accessor:"order_id",
                                                typeahead_fields: `id name`, 
                                                hideAdd: true,
                                                dbname:"order_id",
                                                add:false,
                                                edit:false,
                                                column:false,
                                                
                             
                                                }),    
         
                                        defaultColumn({
                                            Header:"Number of Originals",
                                            accessor: "numberoforiginals",
                                            type: "textarea",
                                            add:true,
                                            edit:true,
                                            span:4
                                
                                        }),
        

                                        defaultColumn({
                                            Header:"Freight Charges",
                                            accessor: "freightcharges",
                                            type: "textarea",
                                            add:true,
                                            edit:true,
                                            span:4

                                
                                        }),

                                        
                                        defaultColumn({
                                            Header:"Shipping Namd And Address",
                                            accessor: "shippernameandaddress",
                                            type: "textarea",
                                            add:true,
                                            edit:true,
                                            span:4

                                
                                        }),

                                        
                                defaultColumn({
                                    Header:"Booking No",
                                    accessor: "bookingno",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Tel Phone No",
                                    accessor: "telno",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                            span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Consignee Namd And Address",
                                    accessor: "consigneenameandaddress",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                            span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Notify Party Namd And Address",
                                    accessor: "notifypartynameandaddress",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Shipping Line",
                                    accessor: "shippingline",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Place of Receipt",
                                    accessor: "placeofreceipt",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Port Of Delivery",
                                    accessor: "placeofdelivery",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                               defaultColumn({
                                    Header:"Port Of Loading",
                                    accessor: "portofloading",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),


                               defaultColumn({
                                    Header:"Port of Discharge",
                                    accessor: "portofdischarge",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Vessel",
                                    accessor: "vessel",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
                                defaultColumn({
                                    Header:"Marks and Numbers",
                                    accessor: "marksandnumbers",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),                               defaultColumn({
                                    Header:"Package Description",
                                    accessor: "numberandkindofpackagesdescriptionofgoods",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),                               defaultColumn({
                                    Header:"Weight in KG or MT",
                                    accessor: "weightinkgormt",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),                               defaultColumn({
                                    Header:"Total No of Containers",
                                    accessor: "totalnumberofcontainer",
                                    type: "textarea",
                                    add:true,
                                    edit:true,
                                    span:4

                        
                                }),
 

                                    defaultColumn({
                                        Header:"Remarks",
                                        accessor: "remarks",
                                        type: "textarea",
                                        add:true,
                                        edit:true
                            
                                    }),
 
                                    ],
                                };
                                break;
        
            
                                case "shipping_containers":
                                    options = {
                                        entity: "shipping_containers",
                                        fields: `
                                        id
                                        uid                    
                                        customer_id
                                        customer {
                                          id
                                          name
                                        }
    
                                        order_id
                                         
                                        container_no
                                        seal_no

                                        created_at
                                        updated_at
                                        export_freight_order { 
                                            booking_validity_status
                                            enum_exp_fri_ord_booking_val_status{comment value}
                                          }
                                            `,
                                               
                    
                                        columns: [
    
    
                                                
                                            defaultColumn({
                                                Header: "UID",
                                                accessor: "uid",
                                                add:false,
                                                edit:false
                                            }),
    
    
                                            uuidColumn({
                                                Header:"Customer",
                                                entity: "customer", 
                                                typeahead_fields: `id name`, 
                                                hideAdd: true,
                                                dbname:"customer_id",
                                                add:false,
                                                edit:false,
                                                column:false,
    
                                                // labelKey:"commodity: {name}",
                                                // filters: {  origin_type : { _in: [ "non_domestic"] } },
                                                // filter_fields: [{ accessor: "supplier", nestedAccessor:"customer_int_commodities.customer_id" }],
                                                }),
    
                                            
                                                uuidColumn({
                                                    Header:"Order",
                                                    entity: "export_freight_order", 
                                                    accessor:"order_id",
                                                    typeahead_fields: `id name`, 
                                                    hideAdd: true,
                                                    dbname:"order_id",
                                                    add:false,
                                                    edit:false,
                                                    column:false,
                                                    
                                 
                                                    }),    
             
                                            defaultColumn({
                                                Header:"Container No",
                                                accessor: "container_no",
                                                type: "text",
                                                add:true,
                                                edit:true,
                                                span:4
                                    
                                            }),
            
    
                                            defaultColumn({
                                                Header:"Seal No",
                                                accessor: "seal_no",
                                                type: "text",
                                                add:true,
                                                edit:true,
                                                span:4
    
                                    
                                            }),
    
                                       
     
                                        ],
                                    };
                                    break;

                                    // case "financers":
                                    //     options = {
                                    //       entity: "export_freight_order",
                                    //       fields: ` id uid
                                    //       exporter_id exporter{ id name phone }
                                    //       export_freight_id export_freight{ id ocean_freight }
                                    //       invoice
                                    //       transportation_slip bl_slip do_slip   created_at updated_at active
                                          
                                    //       vgm_cut_off
                                    //       gate_cut_off
                                              
                                          
                                    //       status
                                    //       payment_status
                                           
                                    //       invoice_no
                                    //       invoice_amount
                                           
                                    //       draft_bl_slip
                                    //       booking_validity_status
                                    //       shipping_ins_status
                        
                                    //       draft_bl_status
                                    //       vgm_status
                        
                                    //       booking_validity
                                    //       shipping_instructions_cut_off
                                    //       vgm_cut_off_date
                                    //       gate_opens
                                    //       bl_approved
                                    //       gate_in_cut_off_date
                                    //          `, 
                                    //       columns: [
                                    //           linkColumn({
                                    //               Header: "Id",
                                    //               // displayOnly: true,
                                    //               accessor: "uid",
                                    //               link: `/web/manage_order/detail/#id`,
                                    //               // link_id:"customer_id",
                                    //               field:"uid",
                                    //               add: false,
                                    //               edit: false,
                                    //               column:true,
                                    //               searchable: false,
                          
                                    //            }),
                          
                                    //            uuidColumn({
                                    //                Header:"Exporter Name",
                                    //                entity:"customer",
                                    //                typeahead_fields: `id name`, 
                                    //                accessor: "exporter",  
                                    //                dbname:"exporter_id",
                                    //                rules: null,  
                                    //                edit:false,
                                    //             }),
                                    
                                    //            defaultColumn({Header:"Exporter Ph.", accessor: "exporter.phone", type: "text",minWidth:125 ,  rules: null, edit:false,minWidth:150   }),
                                    //            defaultColumn({Header:"Ocean Freight", accessor: "export_freight.ocean_freight", edit:false, type: "text", rules: null,   searchable:false  }),
                        
                                   
                        
                                    //             enumColumn({
                                    //                 accessor: "payment_status",
                                    //                 options: enum_export_freight_order_payment_status,
                                    //                 type: "select",
                                    //                 add: false,
                                    //                 edit: false,
                                    //                 column: true,
                                    //                 rules:null,
                                    //                 searchable:false
                            
                                    //             }),
                                    //             booleanColumn({accessor:"bl_approved", add:false, edit:false,}),
                        
                        
                        
                                    //             enumColumn({
                                    //                 accessor: "status",
                                    //                 options: enum_freight_order_status,
                                    //                 type: "select",
                                    //                 add: true,
                                    //                 edit: true,
                                    //                 column: true,
                                    //                 rules:null,
                                    //                 searchable:false
                            
                                    //             }),
                                    //             imageColumn({
                                    //                 Header:"Transportation Slip",
                                    //                 type: "photo",
                                    //                 accessor: "transportation_slip",
                                    //                 key:"transportation_slip",
                                    //                 id:"transportation_slip",
                                    //                 accept: /\.(gif|jpe?g|tiff?|png|bmp|tiff)$/i,
                                    //                 rules: null,
                                    //                 column:true,
                                    //                 minWidth:125,
                                    //                 searchable:false,
                                    //                 dependsValue: ["status:completed", ],                    
                            
                                    //             }),  
                        
                        
                                                
                                     
                                             
                         
                                               
                        
                        
                                    //         imageColumn({
                                    //             Header:"DO Slip(PDF)",
                                    //             type: "document",
                                    //             accessor: "do_slip",
                                    //             id: "do_slip",
                                    //             key:"do_slip",
                                    //             accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    //             rules: null,
                                    //             column:true,
                                    //             add:true,
                                    //             edit:true,
                                    //             minWidth:125,
                                    //             searchable:false,
                                    //             // dependsValue: ["status:completed", ],        
                                    //             privateImage:true            
                        
                                    //         }),
                                    //         imageColumn({
                                    //             Header:"Draft Bl Slip(PDF)",
                                    //             type: "document",
                                    //             accessor: "draft_bl_slip",
                                    //             key:"draft_bl_slip",
                                    //             id:"draft_bl_slip",
                                    //             accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    //             rules: null,
                                    //             column:true,
                                    //             minWidth:125,
                                    //             searchable:false,
                                    //             // dependsValue: ["status:completed", ],         
                                    //             privateImage:true
                                    //         }),
                        
                                    //         imageColumn({
                                    //             Header:"VGM cut off",
                                    //             type: "document",
                                    //             accessor: "vgm_cut_off",
                                    //             id: "vgm_cut_off",
                                    //             accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    //             rules: null,
                                    //             add:false,
                                    //             edit:false,
                                    //             column:true,
                                    //             minWidth:80,
                                    //             width:100   ,
                                    //             privateImage:true               
                        
                        
                                    //         }),
                        
                        
                        
                                            
                                    //         defaultColumn({Header:"Invoice No", accessor: "invoice_no", type: "text", rules: null, add:false, edit:false  }),
                                    //         numberColumn({ accessor: "invoice_amount", add:false, edit:false }),
                         
                        
                                    //         enumColumn({ accessor: "booking_validity_status", options: enum_exp_fri_ord_booking_val_status, type: "select",  
                                    //         add: false,
                                    //         edit: false,   searchable: false, }),
                        
                                    //         enumColumn({ accessor: "shipping_ins_status", options: enum_exp_fri_ord_shipping_ins_status, type: "select",  add: false,
                                    //         edit: false,
                                    //            searchable: false, }),
                        
                                    //         enumColumn({ accessor: "draft_bl_status", options: enum_exp_fri_ord_draft_bl_status , type: "select",  add: false,
                                    //         edit: false,
                                    //            searchable: false, }),
                                         
                                    //         enumColumn({ accessor: "vgm_status", options: enum_exp_fri_ord_vgm_status , type: "select",   add: false,
                                    //         edit: false,
                                    //           searchable: false, }),
                        
                                    //         datetimeColumn({
                                    //             accessor: "booking_validity",
                                    //             rules: null,
                                    //             column:true,
                                    //             add:true,
                                    //             edit:true
                                    //         }),
                        
                                    //         datetimeColumn({
                                    //             Header:"Shipping Ins ",
                                    //             accessor: "shipping_instructions_cut_off",
                                    //             rules: null,
                                    //             // displayOnly: true,
                                    //             column:true,
                                    //             add:true,
                                    //             edit:true
                                    //         }),   
                        
                        
                                    //         datetimeColumn({
                                    //             accessor: "vgm_cut_off_date",
                                    //             rules: null,
                                    //             column:true,
                                    //             add:true,
                                    //             edit:true
                                    //         }),
                                    //          datetimeColumn({
                                    //             accessor: "gate_opens",
                                    //             rules: null,
                                    //             column:true,
                                    //             add:true,
                                    //             edit:true
                                    //         }),
                        
                                    //         datetimeColumn({
                                    //             accessor: "gate_in_cut_off_date",
                                    //             rules: null,
                                    //             column:true,
                                    //             add:true,
                                    //             edit:true
                                    //         }),
                        
                                    //         imageColumn({
                                    //             Header:"CLP/Form13(PDF)",
                                    //             type: "document",
                                    //             accessor: "gate_cut_off",
                                    //             id:'gate_cut_of',
                                    //             accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    //             rules: null,
                                    //             add:false,
                                    //             edit:false,
                                    //             column:true,
                                    //             minWidth:80,
                                    //             width:100        ,
                                    //             privateImage:true               
                               
                        
                                    //         }),
                        
                                    //         imageColumn({
                                    //             Header:"OBL Slip(PDF)",
                                    //             type: "document",
                                    //             accessor: "bl_slip",
                                    //             key:"bl_slip",
                                    //             id:"bl_slip",
                                    //             accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,  
                                    //             rules: null,
                                    //             column:true,
                                    //             minWidth:125,
                                    //             searchable:false,
                                    //             // dependsValue: ["status:completed", ],    
                                    //             privateImage:true                
                        
                                    //         }),
                                    //           datetimeColumn({
                                    //               accessor: "created_at",
                                    //               rules: null,
                                    //               displayOnly: true,
                                    //               column:true,
                                    //           }),
                                    //         //   datetimeColumn({
                                    //         //       accessor: "updated_at",
                                    //         //       rules: null,
                                    //         //       isSorted: true,
                                    //         //       isSortedDesc: true,
                                    //         //       column:true
                                    //         //   }),
                                    //           activeColumn()
                                    //       ],
                                    //     };
                                    //     break;
                        
                            case  "new_bidding_importer":
                            options = {
                                entity : "bidding",
                                fields:`
                                    id uid active
                                    commodity_id commodity { name id }
                                    exporter_id exporter { active address area city district gst_no id name }
                                    delivery_location_id delivery_location { country area address district id name }
                                    importer_id importer { active address area city district gst_no id name }
                                    pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                                    quantity_in_mt rank size status color others 
                                    expected_rate bid_response
                                    expected_delivery_date
                                    delivery_location_address
                                    created_at
                                    updated_at
                                   `,
                                  columns:[
                                    
                                    // linkColumn({
                                    //     Header: "Id",
                                    //     // displayOnly: true,
                                    //     accessor: "uid",
                                    //     // link: `/web/manage_order/detail/#id`,
                                    //     // link_id:"customer_id",
                                    //     field:"uid",
                                    //     add: false,
                                    //     edit: false,
                                    //     column:true,
                                    //     searchable: false,
                                    //     minWidth:80
                
                                    //  }),
                                    defaultColumn({Header:"ID", accessor: "uid",minWidth:80 ,  displayOnly: true, add:false, edit:false,    minWidth:150,span:10   }),
                
                                     uuidColumn({
                                        Header:"Commodity",
                                        entity:"commodity",
                                        typeahead_fields: `id name`, 
                                        accessor: "commodity",  
                                        rules: null,  
                                        hideAdd:true,
                                        required:true,
                                        rules:{required:"This Field is required"},
                                        span:4
                                     }),
                         
                                     
                                   
                                     defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,span:4   }),
                                     defaultColumn({Header:"EXPECTED RATE (PER MT)", accessor: "expected_rate", edit:false, type: "text", rules: null, rules:{required:"This Field is required"}, span:4 }),

                                    //  defaultColumn({Header:"Specifications", id:"specifications",type:"collapse", column:false, span:12}),
                                    //  defaultColumn({Header:"",   onlyHeader:true,    column:false, span:12,}),
 
                                     defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null, add:true, edit:true, span:4,
                                    },),
                                     defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null,span:4,
                                     }),
                                     defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null,span:4,
                                     }),
                                    //  defaultColumn({Header:"",   onlyHeader:true,    column:false, span:12,}),

                                     
                                     enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, edit:false,span:3 }),
                                     defaultColumn({Header:"Delivery Address", accessor: "delivery_location_address",  type: "text",rules:{required:"This Field is required"},span:8, }),
                                     
                                     dateColumn({
                                        accessor: "expected_delivery_date",
                                        rules: null,
                                        column:true,
                                        add:true,
                                        edit:true,
                                        show:true,
                                        rules:{required:"This Field is required"},
                                        span:4
                                     }),
                                    //  uuidColumn({
                                    //     Header:"Delivery Location",
                                    //     entity:"location",
                                    //     typeahead_fields: `id name`, 
                                    //     accessor: "delivery_location",  
                                    //     dbname:"delivery_location_id",
                                    //     rules:{required:"This Field is required"},
                                    //     span:3,
                                        
                                    //     // disabled:true
                                         
                                    //  }), 

                                    
                                    defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false, add:false  }),
                                
                                     datetimeColumn({
                                        accessor: "created_at",
                                        rules: null,
                                        displayOnly: true,
                                        column:true,
                                     }),
                                     datetimeColumn({
                                        accessor: "updated_at",
                                        rules: null,
                                        isSorted: true,
                                        isSortedDesc: true,
                                        column:true
                                     }),
                                     activeColumn()
                               
                                  ]
                            }

                            break;
                             
                            case  "bidding_importer":
                                options = {
                                    entity : "bidding",
                                    fields:`
                                        id uid active
                                        commodity_id commodity { name id }
                                        exporter_id exporter { active address area city district gst_no id name }
                                        delivery_location_id delivery_location { country area address district id name }
                                        importer_id importer { active address area city district gst_no id name }
                                        pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                                        quantity_in_mt rank size status color others 
                                        expected_rate bid_response
                                        expected_delivery_date
                                        imp_offer_action_status enum_bid_order_action_imp_status {value comment}
                                        delivery_location_address
                                        created_at
                                        updated_at
                                       `,
                                      columns:[
                                        
                                        // linkColumn({
                                        //     Header: "Id",
                                        //     // displayOnly: true,
                                        //     accessor: "uid",
                                        //     link: `/web/manage_order/detail/#id`,
                                        //     // link_id:"customer_id",
                                        //     field:"uid",
                                        //     add: false,
                                        //     edit: false,
                                        //     column:true,
                                        //     searchable: false,
                    
                                        //  }),
                                         defaultColumn({Header:"ID", accessor: "uid",minWidth:50 ,  displayOnly: true, add:false, edit:false,     span:10   }),
                                         dateColumn({
                                            Header:"Bidding date",
                                            accessor: "created_at",
                                            rules: null,
                                            displayOnly: true,
                                            column:true,
                                            edit:false
                                         }),
                                         uuidColumn({
                                            Header:"Commodity",
                                            entity:"commodity",
                                            typeahead_fields: `id name`, 
                                            accessor: "commodity",  
                                            rules: null,  
                                            hideAdd:true,
                                            required:true,
                                            rules:{required:"This Field is required"},
                                            minWidth:100,
                                            edit:false

                                         }),
                             
                                         
                                       
                                         defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:80 ,   edit:false,  rules:{required:"This Field is required"}   }),
                                         defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null, add:true, edit:false,  }),
                                         defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null,  edit:false  }),
                                         defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null,  edit:false  }),
                                         defaultColumn({Hedader:"Expected rate", accessor: "expected_rate", edit:false, type: "text",   edit:false, rules: null, rules:{required:"This Field is required"}  }),
                                         dateColumn({
                                            Header: "Exp Delivery Date",
                                            accessor: "expected_delivery_date",
                                            rules: null,
                                            column:true,
                                            add:true,
                                            edit:true,
                                            show:true,
                                            rules:{required:"This Field is required"},
                                              edit:false
                                             
                                         }),
                                        //  uuidColumn({
                                        //     Header:"Delivery Location",
                                        //     entity:"location",
                                        //     typeahead_fields: `id name`, 
                                        //     accessor: "delivery_location",  
                                        //     dbname:"delivery_location_id",
                                        //     rules:{required:"This Field is required"},
                                        //     minWidth:100 ,
                                        //     edit:false
                                        //  }), 

                                         enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, edit:false,  }),
                                         enumColumn({Header:"Action", accessor: "imp_offer_action_status", options: enum_bid_order_action_imp_status , type: "select", rules:null, add:false,    }),

                                        //  defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false, add:false  }),
                                    
                                        //  datetimeColumn({
                                        //     accessor: "created_at",
                                        //     rules: null,
                                        //     displayOnly: true,
                                        //     column:true,
                                        //  }),
                                        //  datetimeColumn({
                                        //     accessor: "updated_at",
                                        //     rules: null,
                                        //     isSorted: true,
                                        //     isSortedDesc: true,
                                        //     column:true
                                        //  }),
                                         activeColumn()
                                   
                                      ]
                                }
    
                                break;
                                case "bidding_importer_order":
                                    options = {
                                        entity : "bidding_order",
                                        fields:`
                                            id 
                                            uid
                                            active
                                            bidding{
                                                id
                                                uid
                                                importer{
                                                    id
                                                    name
                                                }
                                                
                                                total_expected_price
                                            }
                                                
                                            bid_price_per_mt                                                                    
                                            bid_response
                                            bidding_offer_id
                                            bidding_time_line
                                            exporter_id customer{ id  name }
                                            admin_order_action_status enum_bid_order_action_admin_status { comment value }
                                            exp_order_action_status enum_bid_order_action_exp_status { comment value }
                                            imp_offer_action_status  enum_bid_order_action_imp_status { comment value }
                                            admin_order_status enum_bidding_admin_status { comment value }
                                            exp_order_status enum_bidding_exp_status { comment value }
                                            imp_offer_status enum_bidding_imp_status { comment value }
                                            bid_price_per_mt
                                            bidding_rank{
                                                rank
                                                }
                                            created_at
                                            updated_at
                                            `,
                                            columns:[
                                            
                               
                                                defaultColumn({Header:"ID", accessor: "uid",minWidth:50 ,  displayOnly: true, add:false, edit:false,     span:10   }),
                         
                                                numberColumn({Header:"Price/MT", accessor: "bid_price_per_mt",  rules: null,add:false, edit:false, minWidth:80  }),
                                                defaultColumn({Header:"Exp. Price",accessor: "bidding.total_expected_price", edit:false, type: "text", rules: null,add:false, edit:false }),
                                                // defaultColumn({Header:"Offer Id",accessor: "bidding.uid", edit:false, type: "text", rules: null,add:false, edit:false }),
                                                // defaultColumn({Header:"Rank", accessor: "bidding_rank.rank",  type: "text",column:true, rules: null, add:false,
                                                // edit:false}),
                                                enumColumn({Header:"Action", accessor: "admin_order_action_status", options: enum_bid_order_action_admin_status, type: "select", rules:null, add:false,
                                                    searchable:false,edit:false }),
                        
                                                enumColumn({Header:"Exp Order Action", accessor: "exp_order_action_status", options: enum_bid_order_action_exp_status, type: "select", rules:null, add:false,
                                                    searchable:false, edit:false }),
                        
                                                enumColumn({Header:"Offer Action", accessor: "imp_offer_action_status", options: enum_bid_order_action_imp_status, type: "select", rules:null, add:false,
                                                    searchable:false }),
                        
                                                // enumColumn({Header:"Order staus", accessor: "admin_order_status", options: enum_bidding_admin_status, type: "select", rules:null, add:false,
                                                //     searchable:false }),
                        
                                                // enumColumn({Header:"Status", accessor: "exp_order_status", options: enum_bidding_exp_status, type: "select", rules:null, add:false,
                                                //     searchable:false }),
     
                                                
                                                datetimeColumn({
                                                    accessor: "bidding_time_line",
                                                    rules: null,
                                                    displayOnly: true,
                                                    column:true,
                                                    }),
                                                datetimeColumn({
                                                accessor: "created_at",
                                                rules: null,
                                                displayOnly: true,
                                                column:true,
                                                }),
                                                datetimeColumn({
                                                accessor: "updated_at",
                                                rules: null,
                                                isSorted: true,
                                                isSortedDesc: true,
                                                column:true
                                                }),
                                                activeColumn()
                                        
                                            ]
                                    }
                        
                                    break;
                                
                            case  "bidding_exporter":
                                options = {
                                    entity : "bidding",
                                    fields:`
                                    id uid  
                                    commodity_id commodity { name id }
                                    bidding_timeline

                                    exporter_id exporter { active address area city district gst_no id name }
                                    delivery_location_id delivery_location { country  state area address district id name }
                                    delivery_location_address
                                    expected_delivery_date
                                    importer_id importer {
                                         active          
                                        locations {
                                        id
                                        address
                                        city
                                        state
                                      } }
                                    pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                                    quantity_in_mt rank size status color others 
                                    total_expected_price bid_response
                                    bidding_orders{
                                        bidding_rank{
                                            rank
                                        }
                                    }
                                    
                                    imp_ok_address

                                    created_at
                                    updated_at
                                      `,
                                      columns:[
                                        
                                        linkColumn({
                                            Header: "Bid Id",
                                            // displayOnly: true,
                                            accessor: "uid",
                                            // link: `/web/manage_order/detail/#id`,
                                            // link_id:"customer_id",
                                            field:"uid",
                                            add: false,
                                            edit: false,
                                            column:true,
                                            searchable: false,
                                            minWidth:50
                    
                                         }),

                                        //  defaultColumn({Header:"Country", accessor: "delivery_location.country",minWidth:50 ,   rules:{required:"This Field is required"},    }),
                                         uuidColumn({
                                            Header:"Country",
                                            entity:"location",
                                            typeahead_fields: `id name`, 
                                            accessor: "delivery_location.country",  
                                            dbname:"delivery_location_id",
                                            rules:{required:"This Field is required"},
                                            searchable:false,
                                            activeFilter:false,
                                            minWidth:80,
                                            filterable:false
                                             
                                         }), 
                                        //  uuidColumn({
                                        //     Header:"Location",
                                        //     entity:"location",
                                        //     typeahead_fields: `id name`, 
                                        //     accessor: "delivery_location.state",  
                                        //     dbname:"delivery_location_id",
                                        //     rules:{required:"This Field is required"},
                                        //     searchable:false,
                                        //     minWidth:100

                                             
                                        //  }),          
                                         defaultColumn({Header:"Location", accessor: "delivery_location_address",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,  searchable: false,  filterable:false }),
                                         defaultColumn({Header:"Importer", accessor: "imp_ok_address",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,  searchable: false,  filterable:false }),
                                         
                                         uuidColumn({
                                            Header:"Commodity",
                                            entity:"commodity",
                                            typeahead_fields: `id name`, 
                                            accessor: "commodity",  
                                           //  dbname:"exporter_id",
                                            rules: null,  
                                           //  edit:false,
                                           add:false,
                                           multiple:true,
                                           edit:false,
                                          
                                         }),
                             
                                 
                                        //  uuidColumn({
                                        //     Header:"Pickup Location",
                                        //     entity:"location",
                                        //     typeahead_fields: `id name`, 
                                        //     accessor: "pick_up_location",  
                                        //     dbname:"pick_up_location_id",
                                        //     rules: null,  
                                        //     add:false,
                                        //     edit:false
                                        //    //  edit:false,
                                        //  }),
                                   
                                        // defaultColumn({Header:"Location", accessor: "importer.locations[0].address",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,   }),
                                        // defaultColumn({Header:"Location", accessor: "exp_ok_address",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,   }),
                                         defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:100 , searchable: false,   rules:{required:"This Field is required"},    }),
                                         defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null, searchable: false, add:true, edit:true,minWidth:100}),
                                         defaultColumn({Header:"Color", accessor: "color",  type: "text", searchable: false, rules: null, minWidth:100}),
                                         defaultColumn({Header:"Others", accessor: "others",  type: "text",  searchable: false,rules: null,minWidth:100 }),
                                         enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, edit:false,searchable:false, filterable:false, activeFilter:false }),
                                         defaultColumn({Header:"Exp. rate per MT", accessor: "total_expected_price", edit:false, searchable: false, type: "text",minWidth:100, rules: null, rules:{required:"This Field is required"} }),
                                        //  defaultColumn({Header:"Rank", accessor: "bidding_orders.bidding_rank.rank", edit:false, type: "text", rules: null, rules:{required:"This Field is required"} }),
                                  
                                        //  defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false, add:false  }),
                                    
                                        

                                        datetimeColumn({
                                            accessor: "bidding_timeline",
                                            rules: null,
                                            column:true,
                                            add:true,
                                            edit:true,
                                            subFrom:true,
                                            show:true,
                                            column:true,
                                            searchable: false,
                                            // rules:{required:"This Field is required"}
                                         }),

                                            dateColumn({
                                            accessor: "expected_delivery_date",
                                            rules: null,
                                            column:true,
                                            add:true,
                                            edit:true,
                                            show:true,
                                            searchable: false,
                                            rules:{required:"This Field is required"}
                                         }),
                                        //  datetimeColumn({
                                        //     accessor: "created_at",
                                        //     rules: null,
                                        //     displayOnly: true,
                                        //     column:true,
                                        //  }),
                                        //  datetimeColumn({
                                        //     accessor: "updated_at",
                                        //     rules: null,
                                        //     isSorted: true,
                                        //     isSortedDesc: true,
                                        //     column:true
                                        //  }),
                                        //  activeColumn()
                                   
                                      ],
                                      sortBy: [{ id: "bidding_timeline", asc: true }],
                                }

                            break ;   
                            case "bidding_exporter_order":
                                options = {
                                    entity : "bidding_order",
                                    fields:`
                                        id 
                                        uid
                                        active
                                        bidding{
                                            id uid  
                                             
                                            commodity_id commodity { name id }
                                            exporter_id exporter { active address area city district gst_no id name }
                                            delivery_location_id delivery_location { country  state area address district id name }
                                            expected_delivery_date
                                            importer_id importer {
                                                 active          
                                                locations {
                                                id
                                                address
                                                city
                                                state
                                              } }
                                            pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                                            quantity_in_mt rank size status color others 
                                            total_expected_price bid_response
                                            bidding_orders{
                                                bidding_rank{
                                                    rank
                                                }
                                            }
                                            
                                            imp_ok_address
        
                                            created_at
                                            updated_at

                                            importer{
                                                id
                                                name
                                            }
                                            
                                             
                                        }
                                            
                                        bid_price_per_mt                                                                    
                                        bid_response
                                        bidding_offer_id
                                        bidding_time_line
                                        exporter_id customer{ id  name locations {id name} }
                                        admin_order_action_status enum_bid_order_action_admin_status { comment value }
                                        exp_order_action_status enum_bid_order_action_exp_status { comment value }
                                        imp_offer_action_status  enum_bid_order_action_imp_status { comment value }
                                        admin_order_status enum_bidding_admin_status { comment value }
                                        exp_order_status enum_bidding_exp_status { comment value }
                                        imp_offer_status enum_bidding_imp_status { comment value }
                                        bid_price_per_mt
                                        bidding_rank{
                                            rank
                                            }
                                        exp_ok_address
                                        created_at
                                        updated_at
                                        `,
                                        columns:[
                                        
                                        linkColumn({
                                            Header: "BId Id",
                                            // displayOnly: true,
                                            accessor: "uid",
                                            // link: `/web/manage_order/detail/#id`,
                                            // link_id:"customer_id",
                                            field:"uid",
                                            add: false,
                                            edit: false,
                                            column:true,
                                            searchable: false,
                                            minWidth:25
                    
                                            }),
                    
                                            uuidColumn({
                                                Header:"Country",
                                                entity:"location",
                                                typeahead_fields: `id name country`, 
                                                accessor: "bidding.delivery_location.country",  
                                                dbname:"delivery_location_id",
                                                rules:{required:"This Field is required"},
                                                edit:false,
                                                minWidth:50 
                                             }), 
     
                                 
                                      
                                             uuidColumn({
                                                Header:"Location",
                                                entity:"location",
                                                typeahead_fields: `id name`, 
                                                accessor: "bidding.delivery_location.state",  
                                                dbname:"delivery_location_id",
                                                rules:{required:"This Field is required"},
                                                edit:false ,
                                                minWidth:50 

                                                 
                                             }), 
                                             defaultColumn({Header:"Importer", accessor: "bidding.imp_ok_address",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,   edit:false  }),
                                             
                                             uuidColumn({
                                                Header:"Commodity",
                                                entity:"commodity",
                                                typeahead_fields: `id name`, 
                                                accessor: "bidding.commodity",  
                                               //  dbname:"exporter_id",
                                                rules: null,  
                                               //  edit:false,
                                               add:false,
                                               edit:false,
                                               
                                             }),

                                            //  defaultColumn({Header:"Country", accessor: "bidding.delivery_location.country",minWidth:50 ,   rules:{required:"This Field is required"},    }),
                                             defaultColumn({Header:"Quantity(MT).", accessor: "bidding.quantity_in_mt",minWidth:100 ,   rules:{required:"This Field is required"},   edit:false   }),
                                             defaultColumn({Header:"Size", accessor: "bidding.size",  type: "text", rules: null, add:true, edit:false}),
                                             defaultColumn({Header:"Color", accessor: "bidding.color",  type: "text", rules: null,  edit:false}),
                                             defaultColumn({Header:"Others", accessor: "bidding.others",  type: "text", rules: null, edit:false }),
                                             defaultColumn({Header:"Exp. rate per MT", accessor: "bidding.total_expected_price", edit:false, type: "text", rules: null, rules:{required:"This Field is required"} }),
                                             dateColumn({
                                                Header:"Exp-Delivery Date",
                                                accessor: "bidding.expected_delivery_date",
                                                rules: null,
                                                column:true,
                                                add:true,
                                                edit:true,
                                                show:true,
                                                rules:{required:"This Field is required"},
                                                edit:false
                                             }),
                                           datetimeColumn({
                                            accessor: "bidding_timeline",
                                            rules: null,
                                            isSorted: true,
                                            isSortedDesc: true,
                                            column:true,
                                            edit:false
                                            }),
                                            defaultColumn({Header:"Bid Price per MT", accessor: "bid_price_per_mt", edit:false, type: "text", rules: null, rules:{required:"This Field is required"} , edit:false}),
                                            enumColumn({Header:"Action", accessor: "exp_order_action_status", options: enum_bid_order_action_exp_status, type: "select", rules:null,edit:true, add:false, searchable:false }),
                                            defaultColumn({Header:"Rank", accessor: "bidding_rank.rank", edit:false, type: "text", rules: null, rules:{required:"This Field is required"} , edit:false}),
                                            enumColumn({Header:"Status", accessor: "exp_order_status", options: enum_bidding_exp_status, type: "select", rules:null, add:false, edit:false }),

                                            //  defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false, add:false  }),

                                            
                                            // uuidColumn({
                                            // Header:"Exporter",
                                            // entity:"customer",
                                            // typeahead_fields: `id name`, 
                                            // accessor: "customer",  
                                            // dbname:"exporter_id",
                                            // rules: null,  
                                            // add:false,
                                            // edit:false
                                            // }),
                    
                                            // uuidColumn({
                                            //     Header:"Importer",
                                            //     entity:"customer",
                                            //     typeahead_fields: `id name`, 
                                            //     accessor: "bidding.importer.name",  
                                            //     dbname:"exporter_id",
                                            //     rules: null,  
                                            //     add:false,
                                            //     edit:false
                                            //  }),
                    
                                            // uuidColumn({
                                            //     Header:"Offer",
                                            //     entity:"bidding",
                                            //     typeahead_fields: `id name`, 
                                            //     accessor: "bidding",  
                                            //     dbname:"bidding_offer_id",
                                            //     rules: null,  
                                            //     add:false,
                                            //     edit:false,
                                            //     }),
                                            // defaultColumn({Header:"Importer", accessor: "exp_ok_address",  rules: null,add:false, edit:false   }),
                                            // numberColumn({Header:"Price/MT", accessor: "bid_price_per_mt",  rules: null,add:false, edit:false   }),
                                            // defaultColumn({Header:"Exp. Price",accessor: "bidding.total_expected_price", edit:false, type: "text", rules: null,add:false, edit:false }),
                                            // defaultColumn({Header:"Rank", accessor: "bidding_rank.rank",  type: "text",column:true, rules: null, add:false,
                                            // edit:false}),
                                            // enumColumn({Header:"Action", accessor: "admin_order_action_status", options: enum_bid_order_action_admin_status, type: "select", rules:null, add:false,
                                            //     searchable:false, edit:false }),
                    

                    
                                            // enumColumn({Header:"Offer Action", accessor: "imp_offer_action_status", options: enum_bid_order_action_imp_status, type: "select", rules:null, add:false,
                                            //     searchable:false }),
                    
                                            // enumColumn({Header:"Order staus", accessor: "admin_order_status", options: enum_bidding_admin_status, type: "select", rules:null, add:false,
                                            //     searchable:false }),
                    
                                            // enumColumn({Header:"Status", accessor: "exp_order_status", options: enum_bidding_exp_status, type: "select", rules:null, add:false,
                                            //     searchable:false, edit:false }),
 
                                    
                                            // datetimeColumn({
                                            // accessor: "created_at",
                                            // rules: null,
                                            // displayOnly: true,
                                            // column:true,
                                            // }),
                                            // datetimeColumn({
                                            // accessor: "updated_at",
                                            // rules: null,
                                            // isSorted: true,
                                            // isSortedDesc: true,
                                            // column:true
                                            // }),
                                            activeColumn()
                                    
                                        ]
                                }
                    
                                break;
                            
                            case  "test_bidding_exporter":
                                options = {
                                    entity : "bidding",
                                    fields:`
                                    id uid active
                                    commodity_id commodity { name id }
                                    exporter_id exporter { active address area city district gst_no id name }
                                    delivery_location_id delivery_location { country area address district id name }
                                    importer_id importer { active address area city district gst_no id name }
                                    pick_up_location_id pick_up_location { active address area city country district email id name notes state uid }
                                    quantity_in_mt rank size status color others 
                                    expected_rate bid_response
                                    created_at
                                    updated_at
                                      `,
                                      columns:[
                                        
                                        // linkColumn({
                                        //     Header: "Id",
                                        //     // displayOnly: true,
                                        //     accessor: "uid",
                                        //     link: `/web/manage_order/detail/#id`,
                                        //     // link_id:"customer_id",
                                        //     field:"uid",
                                        //     add: false,
                                        //     edit: false,
                                        //     column:true,
                                        //     searchable: false,
                                        //     minWidth:50
                    
                                        //  }),
                    
                                         uuidColumn({
                                            Header:"Commodity",
                                            entity:"commodity",
                                            typeahead_fields: `id name`, 
                                            accessor: "commodity",  
                                           //  dbname:"exporter_id",
                                            rules: null,  
                                           //  edit:false,
                                           add:false,
                                           edit:false,
                                           
                                         }),
                             
                                 
                                         uuidColumn({
                                            Header:"Pickup Location",
                                            entity:"location",
                                            typeahead_fields: `id name`, 
                                            accessor: "pick_up_location",  
                                            dbname:"pick_up_location_id",
                                            rules: null,  
                                            add:false,
                                            edit:false
                                           //  edit:false,
                                         }),
      
                                         defaultColumn({Header:"Quantity(MT).", accessor: "quantity_in_mt",minWidth:125 ,   rules:{required:"This Field is required"},  minWidth:150,   }),
                                         defaultColumn({Header:"Size", accessor: "size",  type: "text", rules: null, add:true, edit:true}),
                                         defaultColumn({Header:"Color", accessor: "color",  type: "text", rules: null, }),
                                         defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null, }),
                                         enumColumn({ accessor: "status", options: enum_bidding_imp_status, type: "select", rules:null, add:false, edit:false }),
                                         defaultColumn({Hedader:"Expected rate per MT", accessor: "expected_rate", edit:false, type: "text", rules: null, rules:{required:"This Field is required"} }),
                                         uuidColumn({
                                            Header:"Delivery Location",
                                            entity:"location",
                                            typeahead_fields: `id name`, 
                                            accessor: "delivery_location",  
                                            dbname:"delivery_location_id",
                                            rules:{required:"This Field is required"}
                                             
                                         }), 
                                        //  defaultColumn({ accessor: "bid_response",add:false, edit:false, type: "text", rules: null,   searchable:false, add:false  }),
                                                                                
                                         linkColumn({
                                            Header: "Id",
                                            // displayOnly: true,
                                            accessor: "bid_response",
                                            link: `/web/manage_order/detail/#id`,
                                            link_id:"id",
                                            field:"bid_response",
                                            add: false,
                                            edit: false,
                                            column:true,
                                            searchable: false,
                    
                                         }),
                                         dateColumn({
                                            accessor: "expected_delivery_date",
                                            rules: null,
                                            column:true,
                                            add:true,
                                            edit:true,
                                            show:true,
                                            rules:{required:"This Field is required"}
                                         }),
                                         datetimeColumn({
                                            accessor: "created_at",
                                            rules: null,
                                            displayOnly: true,
                                            column:true,
                                         }),
                                         datetimeColumn({
                                            accessor: "updated_at",
                                            rules: null,
                                            isSorted: true,
                                            isSortedDesc: true,
                                            column:true
                                         }),
                                         activeColumn()
                                   
                                       ]
                                }

                            break    
                            case entity === "exp_data_te":
                                options = {
                                    entity : "bid_exp_data",
                                    fields:`
                                        id 
                                        uid
                                        active
                                      
                                        bidding{
                                            importer{
                                                locations{
                                                  id
                                                  name
                                                }
                                              }
                    
                                            expected_delivery_date
                                            importer{
                                                id
                                                name
                                            }
                                            exporter {
                                                id
                                                uid
                                                locations {
                                                  id
                                                  name
                                                }
                                                users {
                                                  rating
                                                }
                                                bidding_orders_aggregate(where: {exp_order_status:{_eq:won} }) {
                                                  aggregate {
                                                    count
                                                  }
                                                }
                                              }
                                            total_expected_price
                                        }
                                            
                                        bid_price_per_mt                                                                    
                                        bid_response
                                        bidding_offer_id
                                        bidding_time_line
                                        exporter_id customer{ id  name locations{id name}}
                                        admin_order_action_status enum_bid_order_action_admin_status { comment value }
                                        exp_order_action_status enum_bid_order_action_exp_status { comment value }
                                        imp_offer_action_status  enum_bid_order_action_imp_status { comment value }
                                        admin_order_status enum_bidding_admin_status { comment value }
                                        exp_order_status enum_bidding_exp_status { comment value }
                                        imp_offer_status enum_bidding_imp_status { comment value }
                                        bid_price_per_mt
                                        bidding_rank{
                                            rank
                                            }
                                        created_at
                                        updated_at
                                        `,
                                        columns:[
                                            defaultColumn({Header:"Rank", accessor: "bidding_rank.rank",  type: "text",column:true, rules: null, add:false,
                                            edit:false}),
                                        linkColumn({
                                            Header: "Exporter Id",
                                            // displayOnly: true,
                                            accessor: "uid",
                                            // link: `/web/manage_order/detail/#id`,
                                            // link_id:"customer_id",
                                            field:"uid",
                                            add: false,
                                            edit: false,
                                            column:true,
                                            searchable: false,
                                            minWidth:50
                                            }),
                    
                                
                                            
                                            uuidColumn({
                                            Header:"Exporter Name",
                                            entity:"customer",
                                            typeahead_fields: `id name`, 
                                            accessor: "customer",  
                                            dbname:"exporter_id",
                                            rules: null,  
                                            add:false,
                                            edit:false,
                                            minWidth:100
                                            }),
                    
                                             defaultColumn({Header:"Location",accessor: "customer.locations[0].name", edit:false, type: "text", rules: null,add:false, edit:false,minWidth:50 }),
                    
                    
                                            defaultColumn({Header:"Rating",accessor: "bidding.exporter.users[0].rating", edit:false, type: "rating", rules: null,add:false, edit:false,minWidth:100 }),
                                            defaultColumn({Header:"No of trades",accessor: "bidding.bidding_orders_aggregate.aggregate.count", edit:false, type: "text", rules: null,add:false, edit:false }),
                    
                                            numberColumn({Header:"Bidding rate/MT", accessor: "bid_price_per_mt",  rules: null,add:false, width:80   }),
                    
                                            datetimeColumn({
                                                Header:"Date & Time",
                                                accessor: "created_at",
                                                rules: null,
                                                displayOnly: true,
                                                column:true,
                                                }),
                    
                    
                                            uuidColumn({
                                                Header:"Importer",
                                                entity:"customer",
                                                typeahead_fields: `id name`, 
                                                accessor: "bidding.importer.name",  
                                                dbname:"exporter_id",
                                                rules: null,  
                                                add:false,
                                                edit:false
                                             }),
                    
                                            // uuidColumn({
                                            //     Header:"Offer",
                                            //     entity:"bidding",
                                            //     typeahead_fields: `id name`, 
                                            //     accessor: "bidding",  
                                            //     dbname:"bidding_offer_id",
                                            //     rules: null,  
                                            //     add:false,
                                            //     edit:false,
                                            //     }),
                                           
                                            defaultColumn({Header:"Exp. Price",accessor: "bidding.total_expected_price", edit:false, type: "text", rules: null,add:false, edit:false }),
                                          
                    
                                            enumColumn({Header:"Exp Order Action", accessor: "exp_order_action_status", options: enum_bid_order_action_exp_status, type: "select", rules:null, add:false,
                                                searchable:false ,edit:false}),
                    
                    
                    
                    
                    
                                            // defaultColumn({Header:"Imp Location",accessor: "bidding.exporter.locations.[0].name", edit:false, type: "text", rules: null,add:false, edit:false }),
                                          
                                          
                                          
                                            enumColumn({Header:"Exp Order Status", accessor: "exp_order_status", options: enum_bidding_exp_status, type: "select", rules:null, add:false,
                                                searchable:false }),
                    
                    
                    
                                          
                                            uuidColumn({
                                                Header:"Imp Location",
                                                entity:"location",
                                                typeahead_fields: `id name`, 
                                                accessor: "bidding.importer.locations[0].name",  
                                                dbname:"delivery_location_id",
                                                rules: null,  
                                                add:false,
                                                edit:false
                                                //  edit:false,
                                                }),
                    
                    
                                            defaultColumn({Header:"Customer ID",accessor: "bidding.exporter.uid", edit:false, type: "text", rules: null,add:false, edit:false }),
                    
                                        
                                            enumColumn({Header:"Bid Action", accessor: "admin_order_action_status", options: enum_bid_order_action_admin_status, type: "select", rules:null, add:false,
                                                searchable:false }),
                    
                                            
                    
                                            enumColumn({Header:"Offer Action", accessor: "imp_offer_action_status", options: enum_bid_order_action_imp_status, type: "select", rules:null, add:false,
                                                searchable:false }),
                    
                                            enumColumn({Header:"Bid status", accessor: "admin_order_status", options: enum_bidding_admin_status, type: "select", rules:null, add:false,
                                                searchable:false }),
                    
                    
                                            enumColumn({Header:"Imp Order Status", accessor: "imp_offer_status", options: enum_bidding_imp_status, type: "select", rules:null, add:false,
                                                searchable:false, edit:false }),
                    
                                           
                                            // numberColumn({ accessor: "bid_response",minWidth:125 ,  rules: null,add:false,
                                            // edit:false,minWidth:150   }),
                    
                                      
                                            // defaultColumn({Header:"Others", accessor: "others",  type: "text", rules: null,add:false,
                                            // edit:false }),
                                             
                                            dateColumn({
                                                Header:"Expected Delivery date",
                                                accessor: "bidding.expected_delivery_date",
                                                rules: null,
                                                displayOnly: true,
                                                column:true,
                                                edit:false
                                                }),
                                  
                                            datetimeColumn({
                                            accessor: "updated_at",
                                            rules: null,
                                            isSorted: true,
                                            isSortedDesc: true,
                                            column:true
                                            }),
                                            activeColumn()
                                    
                                        ]
                                }
                    
                                break;
            
                                case  "inquiries_freight":
                                    options = {
                                        entity: "inquiries_ff",
                                         // timestamp
                                        fields: `  
                                        id
                                        uid
                                        customer_id
                                        customer{
                                            id
                                            name
                                        }
                                        quotations{
                                            id
                                            b_l_charges
                                            basic_freight_in_usd
                                            cargo_weight_per_container_in_mt
                                            container_category
                                            container_count
                                            container_type
                                            created_at
                                            commodity_id
                                            exchange_rate_in_inr
                                            free_days
                                            incoterm
                                            inquiry_id
                                            origin_port_id
                                            sailing_date
                                            seal_charges
                                            terminal_handling_charges
                                            toll_charges
                                            transit_days
                                        }
                                        origin_port_id
                                        origin_port {
                                          id
                                          name
                                        }
                                        destination_port_id
                                        destination_port {
                                          id
                                          name
                                        }
                                        incoterm_type
                                        enum_incoterm {
                                          comment
                                          value
                                        }
                                        no_of_containers
                                        container_type
                                        cargo_type
                                        temperature
                                        humidity
                                        enum_cargo_category {
                                          comment
                                          value
                                        }
                                        weight_in_mt
                                        cargo_description
                                        free_days_at_des  
                                          enum_free_days_des_type {
                                            comment
                                            value
                                          }
                                        
                                        stuffing_location_type
                                        enum_stuffing_location_type {
                                          comment
                                          value
                                        }
                                        custom_clearance_required
                                        cus_cl_at_origin
                                        cus_cl_at_destination
                                        transport_required
                                        pickup_address
                                        drop_off_address
                                        cargo_readiness
                                        inquiry_type
                                        enum_inquery_type {
                                          value
                                          comment
                                        }
                                        freight_indication_in_usd
                                        pickup_address
                                        preferred_carrier
                                        inquiry_expiry
                                        notes
                                        created_by_id
                                        created_by{
                                            id name customer_type
                                        }
                                        created_at
                                        updated_at
                                    
                                            `,
                                            columns: [
                                                defaultColumn({
                                                    Header: "UID",
                                                    accessor: "uid",
                                                    add:false,
                                                    edit:false,
                                                    minWidth:30
                                                }),
            
                                  
                    
                                                uuidColumn({
                                                    Header:"Customer",
                                                    entity: "customer",
                                                    typeahead_fields: `id name`,
                                                    column:false
                                                }),
                
                                                uuidColumn({
                                                    Header:"Created By",
                                                    entity: "customer",
                                                    accessor:"created_by",
                                                    typeahead_fields: `id name type:role`,
                                                    add:false,
                                                    edit:false,
                                                    column:false
            
                                                }),
                
                                                defaultColumn({
                                                    Header:"Created Role",
                                                    accessor:"created_by.customer_type",
                                                    add:false,
                                                    edit:false               ,
                                                    column:false
            
                                                 }),
                                                uuidColumn({
                                                    Header:"Origin Port",
                                                    entity: "location",
                                                    accessor:"origin_port",
                                                    dbname:"origin_port_id",
                                                     filters: { location_type: { _in: ["origin_port"] } },
                                                    typeahead_fields: `id name`,
                                                    minWidth:80
            
                                                    // filter_fields: [{ accessor: "customer" }],
                                                }),
                
                                                uuidColumn({
                                                    Header:"Destination Port",
                                                    entity: "location",
                                                    accessor:"destination_port",
                                                    dbname:"destination_port_id",
                                                    filters: { location_type: { _in: ["destination_port"] } },
                                                    typeahead_fields: `id name`,
                                                    minWidth:80
            
                                                    // filter_fields: [{ accessor: "customer" }],
                                                }),
                
                                                // enumColumn({
                                                //     Header:"IncoTerm",
                                                //     accessor: "incoterm_type",
                                                //     options: enum_incoterms_type,
                                                //     type: "select",
                                                // }),
                                                defaultColumn({
                                                    Header:"Freight indication(USD)",
                                                    accessor: "freight_indication_in_usd",
                                                    rules:null,
                                                    // column:false 
            
                                                    // dependsCollapse:"additional_preferences"
                                                }),
                                                defaultColumn({Header:"Cargo Details",onlyHeader:true, column:false,  span:12}),
                
                                                defaultColumn({
                                                    Header:"No of Containers",
                                                    accessor: "no_of_containers",
                                                    type:"text",
                                                    minWidth:30
                                                }),
                
                                                enumColumn({
                                                    Header:"Container Type",
                                                    accessor: "container_type",
                                                    options: enum_container_type,
                                                    type: "select",
                                                    add: true,
                                                    edit: true,
                                                    column: true,
                            
                                                }),
                
                
                
                                                defaultColumn({
                                                    Header:"Weight(MT)",
                                                    accessor: "weight_in_mt",
                                                }),
                
                
                
                
                                                enumColumn({
                                                    Header:"Cargo",
                                                    accessor: "cargo_type",
                                                    options: enum_cargo_category,
                                                    type: "select",
                                                }),
                
                                                defaultColumn({
                                                    Header:"Temperature",
                                                    accessor: "temperature",
                                                    dependsValue:["cargo_type:reefer"],
                                                    minWidth:30
                                                }),
                
                
                                                defaultColumn({
                                                    Header:"Humidity",
                                                    accessor: "humidity",
                                                    dependsValue:["cargo_type:reefer"],
                                                    minWidth:30
            
                                                }),
                
                                             
                
                                                // enumColumn({
                                                //     Header:"Stuffing Location",
                                                //     accessor: "stuffing_location_type",
                                                //     options: enum_stuffing_location_type,
                                                //     type: "select",
                                                // }),
                
                                                
                                                defaultColumn({
                                                    Header:"Cargo Desscription",
                                                    accessor: "cargo_description",
                                                    type:"textarea",
                                                    rules:null,
                                                    column:false
            
                                                }),
                
                
                                                
                
                                                defaultColumn({Header:"Additional Services",  accessor:"", type:"collapse", id:"additional_services", column:false, span:12,}),
                                            
                                                booleanColumn({accessor:"custom_clearance_required", dependsCollapse:"additional_services",add:false, edit:false, column:false}),
                                               
                                                booleanColumn({Header:"At Origin",accessor:"cus_cl_at_origin",  dependsValue:["custom_clearance_required:true"],  column:false}),
                
                                                booleanColumn({Header:"At Destination",accessor:"cus_cl_at_destination", dependsValue:["custom_clearance_required:true"], column:false}),
                
                
                   
                                                booleanColumn({accessor:"transport_required",dependsCollapse:"additional_services",add:false, edit:false, column:false}),
                                                
                
                                                 defaultColumn({
                                                    Header:"Pickup",
                                                    accessor: "pickup_address",
                                                    rules:null,
                                                    column:false,
                                                    dependsValue:["transport_required:true"]
                                                }),
                
                
                                                defaultColumn({
                                                    Header:"DropOff",
                                                    accessor: "drop_off_address",
                                                    rules:null,
                                                    column:false,
                                                    dependsValue:["transport_required:true"]
                                                }),
                
                                                // defaultColumn({Header:"Preferences", id:"additional_preferences",type:"collapse", column:false, span:12}),
                
                
                                                
                                                // defaultColumn({Header:"Additional Services",  accessor:"", type:"collapse", id:"additional_services", column:false, span:12,}),
                                                // booleanColumn({accessor:"custom_clearance_required", dependsCollapse:"additional_services",add:false, edit:false,}),
                                                // booleanColumn({accessor:"transport_required",dependsCollapse:"additional_services",add:false, edit:false}),
                                                
                
                
                                                
                
                                                // enumColumn({
                                                //     accessor: "inquiry_type",
                                                //     options: enum_inquery_type,
                                                //     type: "select",
                                                //     rules:null,
                                                //     add:false,
                                                //       edit:false,
                                                //     dependsCollapse:"additional_preferences"
                                                // }),
                
            
                
                
                                                //    enumColumn({
                                                //     accessor: "free_days_at_des",
                                                //     options: enum_free_days_des_type,
                                                //     type: "select",
                                                //     rules:null,
                                                //     add:false, edit:false,
                                                //     dependsCollapse:"additional_preferences"
                                                // }),
                
                 
                                                
                                                // defaultColumn({
                                                //     Header:"Preferred Carrier",
                                                //     accessor: "preferred_carrier",
                                                //     rules:null,
                                                //     add:false, edit:false,
                                                //     dependsCollapse:"additional_preferences"
                                                // }),
                        
                
                                        dateColumn({ accessor: "inquiry_expiry",rules:null,add:false, edit:false,dependsCollapse:"additional_preferences" , column:false}),
                                        dateColumn({ accessor: "cargo_readiness",rules:null ,add:false, edit:false,dependsCollapse:"additional_preferences", column:false}),
                                        
                                        // defaultColumn({Header:"Additional Notes",id:"additional_notes",type:"collapse", column:false, span:12}),
                                        
                                        // defaultColumn({
                                        //     Header:"Notes",
                                        //     accessor: "notes",
                                        //     type: "textarea",
                                        //     rules:null,
                                        //     add:false,
                                        //     edit:false,
                                        //     dependsCollapse:"additional_notes"
                
                                        // }),
                
                                      
                    
                                            ],
                                    }
                                 break;
            
           default:
             return {...options};


    }
     return {  ...default_options, ...options,  };







}