import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { GeneralContext,AuthContext } from "../../../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";

const List = () => {
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	const { enum_po_status } = useContext(GeneralContext);
    const { id_token, user_id } = useContext(AuthContext);
	const entity_fields = useWebEntityFields("invoice_history");
	console.error(solution)
	// const override_fields = {};
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
	const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
    const override_fields = { filters:{supplier_id :{_eq:object?.customer_id}}};
    

	return (
		<PageContainer
			breadcrumbTitle={getLabel(entity, true)}
			// breadcrumbOptions={["type", "active"]}
			breadcrumbArg={3}
			tab_arg={4}
 			key={Math.random()}
			enabledispatchColumn={
				[
					{Header:"Sup-Invoice", width:80,minWidth:80, body:{col:"id",type:"Supplier Invoice", sendEmail:"false" ,internal:true },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob" ,message:"Sucessfully Created!"}},            
					// { body:{col:"id",type:"ExporterPO", sendEmail:"false"  },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob" ,message:"Sucessfully Created!"}}            
 				]}

		    disableAdd={"false"}
			disableAction={"false"}
			options={{ entity_fields}}
			detail={page === "detail"}
			tab_options={(() => {
				let tab_options = [];
				switch (tab) {
					case "type":
						tab_options = !_.isArray(enum_po_status)
							? []
							: enum_po_status?.map((e) => ({
									...entity_fields,
									...override_fields,
									overrideValues: {
										...(entity_fields?.overrideValues || {}),
										...(override_fields?.overrideValues || {}),
									},
									label: e?.comment,
									value: e?.value,
									component: DataTable,
									filters: {
										...(entity_fields?.filters || {}),
										...(override_fields?.filters || {}),
										status: { _eq: e.value },
									},
							  }));
						break;
					default:
						tab_options = ENUM_ACTIVE.map((e) => ({
							...entity_fields,
							...override_fields,
							overrideValues: {
								...(entity_fields?.overrideValues || {}),
								...(override_fields?.overrideValues || {}),
							},
							label: e?.comment,
							value: e?.value,
							component: DataTable,
							filters: {
								...(entity_fields?.filters || {}),
								...(override_fields?.filters || {}),
								active: { _eq: e.value === "active" ? true : false },
							},
						}));
						break;
				}
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
	// 	<PageContainer
	// 			// detail={page === "detail"}
	// 			// parent_id={parent_id}
	// 			key={Math.random()}
	// 			breadcrumbTitle={"Supplier"}
	// 			component={DataTable}
	// 			entity={entity}
	// 			options={{ ...useEntityFields( "customer" ) }}
	// 			tab_arg={4}
	// 			tab_options={[
		
	// 				{
	// 					...useEntityFields({ entity: "customer", }),
	// 					label: getLabel("contact", true),
	// 					value: "contact",

	// 					component: DataTable,
	// 					// filters: { customer_id: { _eq: parent_id } },
	// 					// overrideValues: { customer_id: parent_id },
	// 					skipColumns: ["customer"],
	// 				},

	// 			]}
	 
	 
	// />
	);
};

export default List;
