// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import _ from "lodash";
import React, { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import RatingValue from "../../pages/web/common/rating";
import TypeAhead from "./TypeAhead";
import DatePicker from "react-date-picker";

export const FilterForm = ({ defaultValues = {}, inner_filters= {},overrideValues = {},onExtraFilter, fields = [], formSpan, verticalLayout, onFilter, hide = true,
    setTextFilter,
    textFilter
}) => {
    const history = useHistory();
    let default_values = _.pickBy(defaultValues, _.identity);

    let modified_options = [];


    console.log("checking default values and others",fields );

    

    const [check, setCheck] = React.useState({});

    // let copyFields = Object.assign([], fields);
    let copyFields =  JSON.parse(JSON.stringify(fields));
    // let copyFields =  fields;
    // let copyFields = JSON.parse(JSON.stringify(fields));



        copyFields.forEach((field,is)=>{

            console.log("checking copyfieklds ka", field,fields)

            if(field.type === "select" && field?.shareWith &&  JSON.stringify(field.options) !== JSON.stringify(modified_options)) {
    
                  field.options?.forEach((e,i)=>{
    
                                   (field?.shareWith?.forEach((ee,ii)=>{
                                    // console.log("values",e)
                                    modified_options.push({
                                                        label:`${e?.comment}(${ee?.comment})`,
                                                        comment:`${e?.comment}`,
                                                        value:`${e?.value},${ee?.value}`,
                                                        extraValue:`${ee?.value} `,
                                                    
                                                    })
                                         }))
                                    })  
    
     
            }
    
            return field.options = [...modified_options];
    
        }) ;

  





 
    const { register, watch, control,setValue } = useForm({ defaultValues: {...inner_filters, ...check},mode: "onChange" });
    // const { register, watch, control } = useForm({ defaultValues: default_values || {}, mode: "onChange" });
    const local_state = watch();
    // console.log("🚀 ~ file: FilterForm.js ~ line 61 ~ local_state", local_state)
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let copyfilterDataValue = null;


    useEffect(() => {
        console.log("datas are as", local_state,inner_filters)
        let filterData = _.pickBy({ ...local_state }, _.identity); 
          filterData =_.omitBy(filterData, _.isNil)

 
          Object.keys(filterData)?.forEach(e=>{

            if(filterData?.[e] == "null" || filterData?.[e] == null || !filterData?.[e] || filterData?.[e]=='-N/A-' || (_.isArray(filterData?.[e])&&filterData?.[e]?.length==0) ) {

                delete filterData[e]
                
            }

            if(e==="container_type"&&default_values?.[e]?.includes(default_values?.["cargo_category_type"])){

            delete default_values["cargo_category_type"]

            }
            // console.log("checking filterData and defaultData as ===?>0000000"  ,e, filterData,filterData?.commodity, default_values,!_.isEqual(filterData, default_values));

            // if(e==="rating"&&filterData?.["rating"]&&default_values?.["rating"]){
            //     // console.log("checking filterData and defaultData as ===?>0000000" , filterData,filterData?.commodity, default_values,!_.isEqual(filterData, default_values));

            //       filterData["rating"] =  default_values["rating"];
            // }
        })

 
        // console.log("checking filterData and defaultData as ===?>0000000" , filterData,filterData?.commodity, default_values,!_.isEqual(filterData, default_values));

        if (!_.isEqual(filterData, default_values)) {
            // if (!_.isEqual(filterData, default_values)) {
            copyFields?.map((f, i) => {
                 console.log("fvalue is",f,filterData,default_values,_.isObject(filterData?.[f.accessor]))
                delete filterData?.[f.accessor]?.__typename;
                if (f.type === "uuid") {
                    if (_.isEmpty(filterData?.[f.accessor])) {
                        delete filterData?.[f.accessor];
                 console.log("fvalue is uuid",f,filterData,default_values,_.isObject(filterData?.[f.accessor]))

                    } else {
                        if(_.isObject(filterData?.[f.accessor])){
                 console.log("fvalue is inside asd",f,filterData,_.isObject(filterData?.[f.accessor]))
                           
                            if(f?.dbname&&f?.dbfield){

                                filterData[f.accessor] = {...filterData?.[f.accessor],  "dbname":  f.dbname , "dbfield": f.dbfield }
                                setValue(f.accessor,filterData[f.accessor])

                              }

                              if(f.distinctOn) {
                                filterData[f.accessor] = {...filterData?.[f.accessor],"distinctOnLabel":f?.distinctOnLabel,  "distinctOn":  f.distinctOn }
                                setValue(f.accessor,filterData[f.accessor])
                            }

                        }
                        if(_.isArray(filterData?.[f.accessor])){
                 console.log("fvalue is",f,filterData,_.isObject(filterData?.[f.accessor]))
                            
                            filterData[f.accessor] = filterData?.[f.accessor].map((x) => {
                                let newX = { ...x };
                                delete newX.__typename;
                                return newX;
                            });}

                   
                    }

               
                }



                let arr1 = f?.accessor?.split(".")?.[0];

                // console.log("checking filterData and defaultData as ===?>0000000",f?.accessor,arr1, filterData, filterData?.[f.accessor]);

                if (filterData?.[f.accessor] || filterData?.[arr1]) {


                    if(f?.type === "select" && f.shareWith&& (filterData?.[f.accessor] !=='-N/A-')){

                         let copyfilterData = {...filterData};
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                         copyfilterDataValue = copyfilterData?.[f.accessor];
                        // console.log("Copy filter",filterData?.[f.accessor] !=='-N/A-',f.accessor,copyfilterData?.[f.accessor])

                        const [one,two]= copyfilterData?.[f.accessor]?.split(",");
                        filterData[f.accessor] =  one?.trim();
                        filterData[f?.shareValue] =  two?.trim() ;
                        // console.log("filter data", filterData?.[f.accessor],local_state);

                    }


                  
                    console.log("RATING TYPE",f.type, f,f.type === "rating", f.accessor, filterData);

                    // if(f.type === "rating"){

                    //     console.log("RATING", f.accessor, filterData);

                    //     filterData[f.accessor] =  filterData?.[f.accessor];

                    // }
                   
                    if ((f.type === "select" &&  !f.shareWith  ) || f.type === "checkbox" || f.type === "boolean" || f.type === "nullcheck" ) {
                         console.log("checking filterData and defaultData as ===?>111111",f, filterData,f.accessor, default_values);
                    
                        filterData[f.accessor] = JSON?.parse(filterData?.[f.accessor]);
                       }

                    //    console.log("checking filterData and defaultData as ===?>",f, filterData, default_values);

                       if (f.type === "text"&&f?.filterType=="textFilter") {
                        // console.log("checking filterData and defaultData as ===?>",  filterData, default_values);

                        filterData[f?.accessor] =  filterData?.[f.accessor] ;

                        

                        setTextFilter({...textFilter,...{[f?.accessor]:{_ilike: `${ filterData?.[f.accessor] }%`}} })
                        // filterData[f?.accessor] = {_ilike: `${ filterData?.[f.accessor] }%`};
 
                      }
                    //   if (f.type === "rating") {

                    //     // let computedRating = (parseFloat(filterData?.[f.accessor])/5)  * 100
                    //     let computedRating = (parseFloat(filterData?.[f.accessor]) )  * 20

                    //     filterData[f?.accessor] =   `${computedRating}`;
                    //     // filterData[f?.accessor] = {_eq: `${computedRating}`};
 
                    //   }

                    if (f.prefix) {
                        filterData[f.prefix + f.accessor] = filterData?.[f.accessor];
                        filterData[f.accessor] = null;
                    }
             }

                // console.log("All Data",filterData)
                return f;
            });
            // console.log("filter data is as follows ",filterData);
            filterData = _.pickBy(filterData, _.identity);
            
             console.log("filter data is as follows ",filterData,defaultValues,local_state);
             console.log("fvalue is onfilter",filterData,defaultValues,local_state);

               if (onFilter){
                 onFilter({...filterData});
                //  setCheck({...filterData})
                }
              if (onFilter) onExtraFilter( copyfilterDataValue ? {container_type :copyfilterDataValue}: {container_type :null});
              else {
                if (!_.isEmpty(filterData))
                    history.push(window.location.pathname + `?filter_by=${encodeURIComponent(JSON.stringify(filterData))}`);
                else if (window.location.search) history.push(window.location.pathname);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [local_state]);

    if (copyFields?.length === 0 || hide) return <span key={Math.random()} />;
    return (
        <form className="form theme-form m-2 p-2 border" noValidate="">
            <div className="form-row row">
                {copyFields?.map((field, index) => {
                    field.name = field?.accessor;
                    console.log("fielddetails",field?.type, field?.options)
                    if (field.name)
                        return (
                            <div
                                className={
                                    verticalLayout
                                        ? "mb-3 col-sm-12 col-md-3"
                                        : (field.span || field.filterSpan)
                                        ? "mb-3 col-md-" + String((field.span || field.filterSpan) > 12 ? 12 : (field.span || field.filterSpan))
                                        : formSpan
                                        ? "mb-3 col-md-" + String(formSpan > 12 ? 12 : formSpan)
                                        : "mb-3 col-md-3"
                                }
                                key={index}
                            >
                                <div htmlFor={field.name} className="mb-1">
                                    {/* {console.log("field details are",field)} */}
                                    <span className="h6 small text-primary">{field.FilterFormHeader||field.Label || field.Header }</span>
                                </div>

                                {(() => {
                                    switch (field.type) {
                                        case "uuid":
                                            return (
                                                <Controller
                                                    as={
                                                        <TypeAhead
                                                            independent={true}
                                                            multiple={true}
                                                            {...field}
                                                            object={{ ...defaultValues, ...overrideValues, ...local_state }}
                                                        />
                                                    }
                                                    hideAdd="true"
                                                    name={field.name}
                                                    control={control}
                                                    onChange={([selected]) => selected}
                                                />
                                            );
                                        case "boolean":
                                        case "active":
                                        case "checkbox":
                                        case "select":
                                            console.log("fieldnam",field?.name,field?.shareWith, field?.options)
                                            return   field?.shareWith   ? 
                                                <select
                                                    className="form-control"
                                                    name={field.name}
                                                    type={field.type}
                                                    ref={register(field.rules)}
                                                >
                                                    {[
                                                        { label: "-N/A-", value: null },
                                                        ...(field.options || [

                                                        // ...(field.options?.map((e,i)=>{
                                                        //    return   (field?.shareWith?.map((ee,ii)=>{
                                                        //           console.log("checks",e?.value,ee?.value)
                                                        //         return {
                                                        //             label:`${e?.comment}(${ee?.comment})`,
                                                        //             value:`${e?.value},${e?.extraValue}`,
                                                        //             extraValue:`${ee?.value} `,
                                                                     
                                                            
                                                        //               }

                                                        //      }))
                                                        // })?.flat()
                                                        
                                                            { label: "True", value: { _eq: true } },
                                                            { label: "False", value: { _eq: false } },
                                                        ]),
                                                    ].map((o, i) => (
                                                        <option value={ o.value } key={i} className="option">
                                                            {o.label || o.comment}
                                                        </option>
                                                    ))}
                                                </select>
                                           :
                                                    <select
                                                    className="form-control"
                                                    name={field.name}
                                                    type={field.type}
                                                    ref={register(field.rules)}
                                                >
                                                    {[
                                                        { label: "-N/A-", value: null },
                                                        ...(field.options || [
                                                            { label: "True", value: { _eq: true } },
                                                            { label: "False", value: { _eq: false } },
                                                        ]),
                                                    ].map((o, i) => (
                                                        <option value={JSON.stringify(o.value)} key={i} className="option">
                                                        {/* <option value={JSON.stringify(o.value)} key={i} className="option"> */}
                                                            {o.label || o.comment}
                                                        </option>
                                                    ))}
                                                </select>
                                               
                                           
                                        case "date":
                                        // case "date":
                                                return (
                                                    <div>
                                                        <Controller
                                                            as={<DatePicker disabled={ field.disabled} {...field} />}
                                                            name={field.name}
                                                            control={control}
                                                            rules={field.rules}
                                                            onChange={([selected]) => new Date(selected)}
                                                        />
                                                    </div>
                                                );
                                        case "datetime":
                                            return (
                                                // <div>
                                                //     <Controller
                                                //         as={<DateRangePicker />}
                                                //         name={field.name}
                                                //         control={control}
                                                //         rules={field.rules}
                                                //         // onChange={([selected]) => selected}
                                                //     />
                                                // </div>

                                        <div>
                                        <Controller
                                            as={<DateTimePicker   {...field} />}
                                            name={field.name}
                                            control={control}
                                            rules={field.rules}
                                            onChange={([selected]) => selected}
                                        />
                                        </div>
                                            ); 
                                            case "rating":
                                                return (
                                                    <div>
                                                        <Controller
                                                            as={ <RatingValue key={index} {...field} readonly={false} />}
                                                            name={field.name}
                                                            showTooltip={true}
                                                            control={control}
                                                            rules={field.rules}
                                                            onChange={([selected]) => selected}
                                                        />
                                                    </div>
                                                );

                                        default:  
                                        
                                        return <input name={field.name}  id={field.name}   key={field.name} type={field.type} className="form-control"  placeholder={field.name} ref={register} />
                                      
                                            // return <INPUTS field={field} key={field.name} register={register}/>;
                                            // return <input name={field.name}  id={field.name}   key={field.name} type={field.type} className="form-control" 
                                            // ref={register} />;
                                    }
                                }).call(this)}
                            </div>
                        );
                    else return <span key={Math.random()} />;
                })}
            </div>
        </form>
    );
};


const INPUTS = React.memo ( ({field, register})=>{

    return <input name={field.name} key={field.name}  type={field.type} className="form-control" ref={register()} />;
})