import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { defaultColumn, getLabel, getParams, linkColumn, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = (props) => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({ id: parent_id, ...useEntityFields({ entity, parent_id, solution }) });


    // console.log("Krish is",object);

    const orderFieldsOverride = {

                "commodity_variety" : {add:true, edit:true},
                "offer_id" : {add:true, edit:true},
                "order":{filters: { status : { _in: ["completed"] } },},
                "customer":{add:false, edit:false}
        }

     const columnFields = useEntityFields({ entity: "order", parent_id, solution })["columns"]
     
     
     const mod =  columnFields.map((e,i) =>  {
        
            if(orderFieldsOverride[e["accessor"]]){
               
                return e["accessor"] = {...e, ...orderFieldsOverride[e["accessor"]] }
             
            }
    
            return e
                  
           } )

       const orderFields = {...useEntityFields({ entity: "order", parent_id, solution }), "columns":[...mod]}    

           

    //  console.log("order fields are", orderFields)
    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            // disableAdd={true}
         //  disableEdit={true}
            component={DetailInfo}
            entity={entity}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            metric_cards={[
                {
                    label: getLabel("jobcard_loading", true),
                    value: object?.active_jobcard_loadings?.aggregate?.count || 0,
                    total: object?.total_jobcard_loadings?.aggregate?.count || 0,
                },
                {
                    label: getLabel("jobcard_unloading", true),
                    value: object?.active_jobcard_unloadings?.aggregate?.count || 0,
                    total: object?.total_jobcard_unloadings?.aggregate?.count || 0,
                },
                {
                    label: getLabel("bank", true),
                    value: object?.active_banks?.aggregate?.count || 0,
                    total: object?.total_banks?.aggregate?.count || 0,
                },
                {
                    label: getLabel("contact", true),
                    value: object?.active_contacts?.aggregate?.count || 0,
                    total: object?.total_contacts?.aggregate?.count || 0,
                },
                {
                    label: getLabel("location", true),
                    value: object?.active_locations?.aggregate?.count || 0,
                    total: object?.total_locations?.aggregate?.count || 0,
                },
                {
                    label: getLabel("document", true),
                    value: object?.active_documents?.aggregate?.count || 0,
                    total: object?.total_documents?.aggregate?.count || 0,
                },

                {
                    label: getLabel("Exporter data", true),
                    value: object?.total_active_exporter?.aggregate?.count || 0,
                    total: object?.total_exporter?.aggregate?.count || 0,
                },

                {
                    label: getLabel("Exporter Commodities", true),
                    value: object?.total_active_exporter_comm?.aggregate?.count || 0,
                    total: object?.total_exporter_comm?.aggregate?.count || 0,
                },

                {
                    label: getLabel("NBFC", true),
                    value: object?.active_nbfc?.aggregate?.count || 0,
                    total: object?.total_nbfc?.aggregate?.count || 0,
                },

            ]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "jobcard_loading", parent_id, solution }),
                    label: getLabel("jobcard_loading", true),
                    value: "jobcard_loading",
                    component: DataTable,
                    dependsValue:  object?.["customer_type"] ,
                    notDependsOne:  "vendor" ,  
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                },
                {
                    ...useEntityFields({ entity: "jobcard_unloading", parent_id, solution }),
                    label: getLabel("jobcard_unloading", true),
                    value: "jobcard_unloading",
                    dependsValue:  object?.["customer_type"] ,
                    notDependsOne:  "vendor" ,  
                    component: DataTable,
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                },
                {
                    ...useEntityFields({ entity: "contact", parent_id, solution }),
                    label: getLabel("contact", true),
                    value: "contact",

                    component: DataTable,
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                    skipColumns: ["customer"],
                },
                {
                    ...useEntityFields({ entity: "document", parent_id, solution }),
                    label: getLabel("document", true),
                    value: "document",

                    component: DataTable,
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                    skipColumns: ["customer"],
                },
                {
                    ...useEntityFields({ entity: "bank", parent_id, solution }),
                    label: getLabel("bank", true),
                    value: "bank",

                    component: DataTable,
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                    skipColumns: ["customer"],
                },
                {
                    ...useEntityFields({ entity: "location", parent_id, solution }),
                    label: getLabel("location", true),
                    value: "location",
                    dependsValue:  object?.["customer_type"] ,
                    notDependsOne:  "vendor" ,  
                    component: DataTable,
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                    skipColumns: ["customer", "user"],
                },
                  {
                     ...useEntityFields({ entity: "exporter_data", parent_id, solution }),
                    dependsOn:{"exporter":true, "importer":true},
					label: getLabel("data", true),
					value: "exporter_data",
                    dependsValue:  object?.["customer_type"] ,

					component: DataTable,   
					filters: { customer_id: { _eq: parent_id } },
					overrideValues: { customer_id: parent_id },
					skipColumns: ["customer"],
				},
                {
                    // ...useEntityFields({ entity: "export_order", parent_id, solution }),
                   ...useEntityFields({ entity: "exporter_commodity", parent_id, solution }),
                   dependsOn:{"exporter":true, "importer":true},
                   label: getLabel(`commodity`, true),
                   value: `${object?.["customer_type"]}commodity`,
                   dependsValue:  object?.["customer_type"] ,
                   notDependsOne:  "vendor" ,   
                   		// deepOverrideValues: { customer_id: object?.customer_id },
                   component: DataTable,
                   filters: { customer_id: { _eq: parent_id } },
                   overrideValues: { customer_id: parent_id },
                  
               },


               {
                ...useEntityFields({ entity: "nbfc", parent_id, solution }),
               label: getLabel("nbfc", true),
               value: "nbfc",
               notDependsOne:  "vendor" ,   
               dependsValue:  object?.["customer_type"] ,              
               component: DataTable,
               filters: { customer_id: { _eq: parent_id } },
               overrideValues: { customer_id: parent_id },
           },

           {
             ...orderFields,
           dependsOn:{"vendor":true},
           label: getLabel("order", true),
    
           component: DataTable,
           filters: { customer_id: { _eq: parent_id } },
           overrideValues: { customer_id: parent_id },
        },

        {
            entity:"order",
            fields:"name id po",
            columns:[
                linkColumn({
                    Header: "Id",
                    displayOnly: true,
                    accessor: "name",
                    link: `/marketplace/order/detail/#id`,
                    add: false,
                    edit: false,
                }),
                defaultColumn({
                    accessor: "po",
                    type: "document",
                    privateImage:true,
                    rules: null,
                    // eslint-disable-next-line no-useless-escape
                    accept: /^[a-zA-Z0-9_()\s\-\[\]]+\.pdf$/i,
                }),
            ],

           dependsOn:{"vendor":true},
           showPo:true,
           poFields : [
                        {
                    fields : {
                        showTitle : "GRN PO",
                        entity:"customer",
                        disableAddExcel:true,
                        method:"post",
                        subUrl:"/user/grnPdfWithFields",                                          
                        docType:"pdf",
                        body:{accessor:"order_id"},
                        overrideValues:{ customer_id: parent_id },
                        columns: [ { Header: 'Received Quantity', type: 'text',  accessor: 'received_quantity', edit: true, add: true, column: true, }, 
                                    { Header: 'No of Bags', type: 'text',   accessor: 'no_of_bags', edit: true, add: true, column: true, }, 
                                    { Header: 'Kg', type: 'text',   accessor: 'kg', edit: true, add: true, column: true, }, 
                                    { Header: 'OrderID', type: 'uuid', entity: 'order', accessor: 'order_id', edit: true, add: true, column: true,                         
                                        activeFilter: false,
                                        filters: { "status": { _eq: 'completed' }, customer_id:{_eq:parent_id} },
                                    }
                                ]
                            ,
                                },},
                       { 
                        fields:{
                            showTitle:"GRN PO Fields",
                            entity:"customer",
                            disableAddExcel:true,
                            method:"post",
                            subUrl:"/user/grnPdfWithFields",
                            docType:"pdf",
                            body:{accessor:"order_id"},
                            overrideValues:{ customer_id: parent_id },
                            columns:[  { Header: 'Received Quantity', type: 'text',  accessor: 'received_quantity', edit: true, add: true, column: true, required:true }, 
                                        { Header: 'No of Bags', type: 'text',   accessor: 'no_of_bags', edit: true, add: true, column: true, required:true }, 
                                        { Header: 'Kg', type: 'text',   accessor: 'kg', edit: true, add: true, column: true, required:true }, 
                                        { Header: 'From Address',   type:"textarea", accessor: 'from', edit: true, add: true, column: true, required:true                         
                                        },
                                    ]
                        }  ,
            }
        ],
           disableAdd:true,
           label: getLabel("PO with order", true),
           value: "export_order",
           dependsValue:  object?.["customer_type"] ,
           disableExcel: false,
           excelSubUrl : "user/grnPdfWithTemplate",
           htmlToExcelShow: true ,
           htmlToExcelShowHeaders:["SI no","Order ID","Commodity","Variety","Quantity","Pack","Price", "Total Amount"],
           component: DataTable,
           filters: { customer_id: { _eq: parent_id } },
           overrideValues: { customer_id: parent_id },
        },

       ]}
        />
    );
};

export default Detail;
