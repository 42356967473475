import React from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import {   getLabel, getParams, } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";




const List = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
     //  const { id_token } = useContext(AuthContext);
	const entity_fields = useWebEntityFields("importerDirectory");
    console.log("checking entity ===1", entity_fields)
 
	// const override_fields = {};
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;

    // const override_fields = {  filters:{customer_type :{_eq:"supplier"}}}
    //   const override_fields = {}

    const override_fields = {overrideValues:{order_by: {rating: "desc"}}}

    console.log("enum_po_status",entity_fields,tab,solution)
	return (
		<PageContainer
			breadcrumbTitle={getLabel("Exporter Discovery", true)}
			// breadcrumbOptions={["type", "active"]}
			showBreadCrumb={false}
			breadcrumbArg={3}
			tab_arg={4}
			hideFilter={true}
			showFilterForm={true}
			searchableColumns={false}

			key={Math.random()}
			disableAdd={"false"}
			disableEdit={"false"}
			disableAction={"false"}
			options={{ entity_fields}}
			detail={page === "detail"}
			tab_options={(() => {
				let tab_options = [];
				// switch (tab) {
				// 	case "type":
				// 		tab_options = !_.isArray(enum_po_status)
				// 			? []
				// 			: enum_po_status?.map((e) => ({
				// 					...entity_fields,
				// 					...override_fields,
				// 					overrideValues: {
				// 						...(entity_fields?.overrideValues || {}),
				// 						...(override_fields?.overrideValues || {}),
				// 					},
				// 					label: e?.comment,
				// 					value: e?.value,
				// 					component: DataTable,
				// 					filters: {
				// 						...(entity_fields?.filters || {}),
				// 						...(override_fields?.filters || {}),
				// 						status: { _eq: e.value },
				// 					},
				// 			  }));
				// 		break;
				// 	default:
				// 		tab_options = ENUM_ACTIVE.map((e) => ({
				// 			...entity_fields,
				// 			...override_fields,
				// 			overrideValues: {
				// 				...(entity_fields?.overrideValues || {}),
				// 				...(override_fields?.overrideValues || {}),
				// 			},
				// 			label: e?.comment,
				// 			value: e?.value,
				// 			component: DataTable,
				// 			filters: {
				// 				...(entity_fields?.filters || {}),
				// 				...(override_fields?.filters || {}),
				// 				active: { _eq: e.value === "active" ? true : false },
				// 			},
				// 		}));
				// 		break;
				// }
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields,
						orderBy:{"rating":"desc"},
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
	// 	<PageContainer
	// 			// detail={page === "detail"}
	// 			// parent_id={parent_id}
	// 			key={Math.random()}
	// 			breadcrumbTitle={"Supplier"}
	// 			component={DataTable}
	// 			entity={entity}
	// 			options={{ ...useEntityFields( "customer" ) }}
	// 			tab_arg={4}
	// 			tab_options={[
		
	// 				{
	// 					...useEntityFields({ entity: "customer", }),
	// 					label: getLabel("contact", true),
	// 					value: "contact",

	// 					component: DataTable,
	// 					// filters: { customer_id: { _eq: parent_id } },
	// 					// overrideValues: { customer_id: parent_id },
	// 					skipColumns: ["customer"],
	// 				},

	// 			]}
	 
	 
	// />
	);
};

export default List;
