import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
// users
import storage from "store2";
import { AuthContext } from "../../../context";
import { useEntityFields, useObjectByPkQuery } from "../../../helpers";
import { useWebEntityFields } from "../../../pages/web/common/fields";

const ProfileMenu = (props) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [imgError, setImgError] = useState(false);

    const [username, setusername] = useState("Admin");

	const {   user_id ,role} = useContext(AuthContext);
	// eslint-disable-next-line no-unused-vars
	const entity_fields = useWebEntityFields("user");
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
	// if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
 	const { object:first } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
 	//  const { object } = useObjectsQuery({ id: first?.customer_id, ...useEntityFields({ entity:"customer" }) });
     const { object:dd }   =   useObjectByPkQuery({
         id:first?.customer_id,
        entity:   "customer",
        fields:   ` id name exporter_data{ id name type photo_url }`,
        where: { },
        limit:1
    });
                                                                            

    //  console.log("check menu", dd?.exporter_data[0]?.photo_url)


    function testImage(url) {
    var tester = new Image();
    tester.addEventListener('load', imageFound);
    tester.addEventListener('error', imageNotFound);
    tester.src = url;
}

function imageFound() {
    // alert('That image is found and loaded');
}

function imageNotFound() {
    setImgError(true)
}

testImage(dd?.exporter_data[0]?.photo_url[0]);
// testImage('https://i.postimg.cc/KYxTpwGQ/Origin-Konnectlogo.png');


    useEffect(() => {
        if (storage.get("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                const obj = JSON.parse(storage.get("authUser"));
                setusername(obj.displayName);
            } else if (process.env.REACT_APP_DEFAULTAUTH === "fake" || process.env.REACT_APP_DEFAULTAUTH === "jwt") {
                const obj = JSON.parse(storage.get("authUser"));
                setusername(obj.username);
            }
        }
    }, [props.success]);

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block d-none d-lg-block">
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    {/* <img className="rounded-circle header-profile-user"   src={imgError ? user1 : dd?.exporter_data[0]?.photo_url} alt="Img" /> */}
                    <img className="rounded-circle header-profile-user"   src={ dd?.exporter_data[0]?.photo_url || "https://oc-pub-dev.s3.ap-south-1.amazonaws.com/uploads/Copy%20of%20OriginKonnect%20%2813%29_1646186081452.png"} alt="Img" />
                    <span className="d-none d-xl-inline-block ml-2 mr-1" style={{color:"green"}}>{  dd?.exporter_data[0]?.name|| role ||  username}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu right style={{color:"green"}}>
                    <div className="dropdown-divider" />
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
                        <span >{props.t("Logout")}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any,
};

const mapStatetoProps = (state) => {
    const { error, success } = state.Profile ?? {};
    return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
