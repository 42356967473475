/* eslint-disable eqeqeq */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useHistory } from 'react-router-dom';
import moment from "moment";
import {
  formatOrderBy,
  getFilterBy,
  getParams,
  useObjectsQuery,
} from '../../helpers';
import { CardInfo } from './CardInfo';
import { DataForm } from './DataForm';
import { ExcelUpload } from './ExcelUpload';
import { FilterForm } from './FilterForm';
import { HtmlToExcel } from './HtmlToExcel';
import { SimpleTable } from './SimpleTable';
import { AuthContext } from '../../context';
import { SendWebRequest } from '../../pages/web/common/sendRequest';
import {SendRequest} from "../../pages/../components/common/sampleRequest";
export const DataTable = ({
  entity,
  solution,
  sortBy = [],
  filters = {},
  fields = ' id ',
  header,
  footer,
  addButton = () => {},
  htmlToExcelShow = false,
  htmlToExcelShowHeaders=[],
  htmlToExcelShowTitle="",
  excelJobcard = [],
  hideHeader = false,
  hideFilter = false,
  showFilterForm=false,
  searchableColumns=true,
  disableAdd = false,
  disableAddChild = true,
  disableAddChildName =null,
  defaultEnums = {},
  disableAddExcel = false,
  downloadExcelByfilters = false,
  disableExcel = true,
  excelSubUrl = "",
  modalValue=null,
  excelResponseType="",
  excelDocType="",
  skipColumns = [],
  buttons = [],
  onFilter = true,
  label,
  detail = false,
  component = '',
  parent_id = '',
  hideOnEmpty = false,
  showCard = false,
  showList = false,
  limit = 10,
  showPo=false,
  active_tab = '',
  simpleTableFilter = false,
  poFields = null,
  DisableAddAftRec=null,
   messageAfter=null,
  TableName = SimpleTable,
  ShowListCardInfo = CardInfo,
  showTitle=null,
  list=false,
  orderBy={},
  noRecordsFilterMessage="",
   ...rest
}) => {
  console.log("checking details",skipColumns)
  const history = useHistory();
  const { filter_by, filters: path_filters } = getParams();
  const [search_text, set_search_text] = useState('');
  const [hide_filter, set_hide_filter] = useState(
    
    (_.isEmpty(filter_by) && !showFilterForm) ? true : false,
  );
  const [search_on, set_search_on] = useState({});
  const [inner_filters, set_inner_filters] = useState({});
  const [extra_inner_filters, extra_set_inner_filters] = useState({});

  const [textFilter, setTextFilter] = useState([]);
  const { id_token ,role } = useContext(AuthContext);
  const [textFilters, setTextFilters] = useState([]);

      console.log("cheksareTableName ===>3",  TableName);

  // const TableName = SimpleTable;
   

  
  const columns =
    rest?.columns
      ?.map((c) => ({
        ...c,
        column: skipColumns.includes(c.accessor) ? false : c.column,
        searchable: skipColumns.includes(c.accessor) ? false : c.searchable,
        filterable: skipColumns.includes(c.accessor) ? false : c.filterable,
        add: skipColumns.includes(c.accessor) ? false : c.add,
        edit: skipColumns.includes(c.accessor) ? false : c.edit,
      }))
      ?.filter((e, i) => {
          //  console.log("arras ",   simpleTableFilter&&active_tab!=="all", e?.simpleColumnDepends&&e?.simpleColumnDepends[active_tab] , e)
        return simpleTableFilter &&
          e?.simpleColumnDepends &&
          active_tab !== 'all'
          ? e?.simpleColumnDepends && e?.simpleColumnDepends[active_tab]
          : e;
      }) || [];

    // console.log("disableAddExcel are", entity,fields);

  const {
    objects: queryObjects,
    refetch: queryRefetch,
    loading,
    setLimit: querySetLimit,
    setOffset: querySetOffset,
    limit: queryLimit,
    offset: queryOffset,
    pages: queryPages,
    page: queryPage,
    error:queryObjectsError,
    total: queryTotal,
  } = useObjectsQuery({
    entity,
    fields,
    limit: showList ? 25 : limit,
    where: {
      ...(filters ? _.pickBy(filters, _.identity) : {}),
      ...(path_filters ? _.pickBy(path_filters, _.identity) : {}),
      ...(inner_filters ? _.pickBy(getFilterBy(inner_filters), _.identity) : {}),
      ... textFilter
    }, 
    search_on,
    order_by: {...formatOrderBy(sortBy, columns),...orderBy},
  });

    console.log("query Objectss are a s", queryObjects,queryObjectsError,inner_filters,path_filters,sortBy,orderBy,formatOrderBy(sortBy, columns));
  // const { objects: subscriptionObjects } = useObjectsSubscription({
  //     entity,
  //     fields,
  //     limit: showList ? 25 : limit,
  //     where: {
  //         ...(filters ? _.pickBy(filters, _.identity) : {}),
  //         ...(path_filters ? _.pickBy(path_filters, _.identity) : {}),
  //         ...(inner_filters ? _.pickBy(getFilterBy(inner_filters), _.identity) : {}),
  //     },
  //     order_by: formatOrderBy(sortBy, columns),
  // });

  // if (subscriptionObjects.length > 0 && !_.isEqual(subscriptionObjects, queryObjects)) queryRefetch();

  useEffect(() => {
    const timeRef = setTimeout(() => {
      const search_columns = columns
        .filter((c) => c.column)
        .filter((c) => c.searchable);
      // console.log("search array is columns =>",search_columns)

      let search_array = [];

      if (
        search_text &&
        search_text?.length > 0 &&
        search_columns &&
        search_columns.length > 0
      ) {
        search_columns.map((e) => {
          let x = {};
          let y = {};
          let z = -_.isString(e.searchable) ? e.searchable : e.accessor;
          if (e.type === 'select' && z !== 'role')
            z = 'enum_' + (z === 'status' ? entity + '_status' : z);
          if (z === 'role') return x;
          _.set(
            x,
            z +
              (e.type === 'uuid'
                ? '.name'
                : e.type === 'select'
                ? '.comment'
                : ''),
            {
              _ilike: search_text + '%',
            },
          );
          _.set(
            y,
            z +
              (e.type === 'uuid'
                ? '.name'
                : e.type === 'select'
                ? '.comment'
                : ''),
            {
              _ilike: '% ' + search_text + '%',
            },
          );
          // console.log("search array  is X and Y is",x,y,z)
          search_array.push(x);
          search_array.push(y);
          return x;
        });
      }
      if (search_text?.length === 0) set_search_on({});
      //  console.log("search array is",search_array,   { _or: search_array }  )
      if (search_array.length > 0) set_search_on({ _or: search_array });
    }, 1000);

    return () => {
      clearTimeout(timeRef);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search_text]);

  // console.log("modalValue",modalValue);

  if (!entity) return <span key={Math.random()} />;
  if (hideOnEmpty && queryObjects?.length === 0)
    return <span key={Math.random()} />;

  return showList  ?  (
    <div key={entity} className="card card-header p-2">
      {!hideHeader ? (
        <div key={entity + 'header'}>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="p-2">
                <span className="h5">{label} </span>
                <span className="text-muted h5">( {queryTotal} )</span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="row justify-content-end">
                <div className="col-auto">
                  {!disableAdd  && (
                    <DataForm
                      {...rest}
                      key={ (label ||  entity) + 'add'}
                      entity={entity}
                      modalValue={modalValue}
                      columns={columns}
                      refetch={queryRefetch}
                    />
                  )}
               
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div key={entity} className="m-1">
        {queryObjects.map((e, i) => (
          <ShowListCardInfo
            key={i}
            {...rest}
            solution={solution}
            object={e}
            entity={entity}
            queryTotal={queryTotal}
            refetch={queryRefetch} 
            columns={columns}
            disableAdd={disableAdd}
             

          />
        ))}
      </div>
    </div>
  ) : (
    <div
      key={entity}
      className={detail || showCard ? 'card card-header p-2' : ''}
    >
      {!hideHeader ? (
        <div className="m-2" key={entity + 'header'}>
          <div className="row">
            <div className="col-12 col-md-4">
              {detail ? (
                <div>
                  <span className="h5">{label} </span>
                  <span className="text-muted h6">( {queryTotal} )</span>
                </div>
              ) : (
                <div className="text-muted pt-2 m-1 h6">
                  {queryTotal} {header ?? 'Records'}
                </div>
              )}
            </div>

            <div className="col-12 col-md-8">
              <div className="row justify-content-end">
                {/* {console.log("Checking Butss ", entity,!disableAdd, DisableAddAftRec, queryObjects?.length,!disableAdd &&   DisableAddAftRec >= queryObjects?.length )} */}
                {!disableAdd &&   ((DisableAddAftRec < queryObjects?.length)  || (queryObjects?.length == 0))  && (
                // {!disableAdd &&   ((DisableAddAftRec < queryObjects?.length)  || (queryObjects?.length ==0))  (
                  <div className="col-auto">
                    <DataForm
                      {...rest}
                      key={(label ||  entity) + 'add'}
                      defaultValues={defaultEnums || {}}
                      entity={entity}
                      columns={columns}
                      modalValue={modalValue}
                      refetch={queryRefetch}
                      showTitle={showTitle}
                    />
                  </div>
                )}
                   {!disableAddChild  && (
                    <DataForm
                      //  {...rest}
                      key={ ( disableAddChildName?.entity) + 'add'}
                      entity={disableAddChildName?.entity}
                      overrideValues={disableAddChildName?.overrideValues}
                      showTitle = {true}
                      // columns={columns}
                      // refetch={queryRefetch}
                    />
                  )}

                {disableAddExcel && (
                  <div className="col-auto">
                    <DataForm
                      {...rest}
                      key={(label ||  entity) + 'add'}
                      showTitle="Excel"
                     
                      entity={entity}
                      disableAddExcel={disableAddExcel}
                      columns={excelJobcard}
                      refetch={queryRefetch}
                    />
                  </div>
                )}

          {downloadExcelByfilters && (
            <div className="col-auto btn btn-sm btn-primary m-1" onClick={async()=>{

              const result = await SendRequest({

                showTitle:"Data", 
                docType:"excel", 
                "method":"post",
                "subUrl": "/user/GetDataCenterData", 
                "body": {
                    ...(inner_filters ? _.pickBy(getFilterBy(inner_filters), _.identity) : {}),
                    ... textFilter
                 },
                "id_token":id_token, 
                message:"Sucessfully Created!"
            
             }).then(res=> res)

            }}>DOWNLOAD</div>
                  // <div className="col-auto">
                  //   <DataForm
                  //     {...rest}
                  //     key={(label ||  entity) + 'add'}
                  //     showTitle="Excel"
                     
                  //     entity={entity}
                  //     disableAddExcel={disableAddExcel}
                  //     columns={excelJobcard}
                  //     refetch={queryRefetch}
                  //   />
                  // </div>
                )}




            {(showPo) && poFields && poFields?.map((e,i)  => ( <div className="col-auto">
                <DataForm key={Math.random()}  {...e["fields"] } refetch={queryRefetch} />
              </div>))
            }


            {!disableExcel && (
              <div className="col-auto">
                <ExcelUpload  docType={excelDocType}  responseType={excelResponseType} title="vendor" download ={false} suburl={excelSubUrl}></ExcelUpload> 
                {/* <ExcelUpload  docType="pdf"  responseType="blob" title="vendor" download ={false} suburl={excelSubUrl}></ExcelUpload>  */}
              </div>
            )}
    

            {htmlToExcelShow && (
                <div className="col-auto">
                  <HtmlToExcel key={Math.random()}Title={htmlToExcelShowTitle} headers={htmlToExcelShowHeaders} FileName = {`Vendor ${moment.now()}`} /> 
                </div>
              )}

                {(!hideFilter) &&
                ((queryObjects && queryObjects.length > 0) ||
                  (queryObjects.length === 0 &&
                    (!_.isEmpty(filter_by) || !_.isEmpty(inner_filters))))
                  ? columns.filter((c) => c.filterable).length > 0 && (
                      <div className="col-auto">
                        {hide_filter && _.isEmpty(filter_by) ? (
                          <div
                            key={Math.random()}
                            className="btn btn-sm btn-primary m-1 responsive-btn"
                            onClick={() => set_hide_filter(false)}
                          >
                            SHOW FILTERS
                          </div>
                        ) : (
                          <div
                            key={Math.random()}
                            className="btn btn-sm btn-warning m-1 responsive-btn"
                            onClick={() => {
                              set_hide_filter(true);
                              set_inner_filters([]);
                              if (!_.isEmpty(filter_by))
                                history.push(window.location.pathname);
                            }}
                          >
                            RESET FILTERS
                          </div>
                        )}
                      </div>
                    )
                  : ''}

                {searchableColumns &&columns.filter((c) => c.searchable).length > 0 && (
                  <div className="col-sm-12 col-md-auto">
                    <input
                      className="form-control form-control-sm m-1 w-100 shadow-sm"
                      type="text"
                      value={search_text}
                      placeholder="Search..."
                      onChange={(e) =>
                        set_search_text(e ? e.target.value : null)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {/* {console.log("datatable results are as ",inner_filters,extra_inner_filters)} */}
      {(!hideFilter || showFilterForm) &&
      ((queryObjects && queryObjects.length > 0) ||
        (queryObjects.length === 0 &&
          (!_.isEmpty(filter_by) || !_.isEmpty(inner_filters)))) ? (
        <FilterForm
          {...rest}
          // key="filters"
          key={entity}
          // defaultValues={{ ...(onFilter ? extra_inner_filters :  inner_filters ? inner_filters : filter_by) }}
          defaultValues={{ ...(onFilter ? { ...inner_filters,...extra_inner_filters}  : filter_by) }}
          fields={columns.filter((c) => c.filterable)}
          hide={hide_filter}
          onFilter={onFilter ? set_inner_filters : null}
          onExtraFilter={onFilter ? extra_set_inner_filters : null}
          inner_filters={inner_filters}
          // key={Math.random()}

          textFilter={textFilter}
          setTextFilter={setTextFilter}
          
        />
      ) : (
        ''
      )}

      {
      
       messageAfter&& (DisableAddAftRec <= queryObjects?.length)&&queryObjects?.length !=0 ?   <div className="p-2 text-danger "  style={{ background: "whitesmoke" }}>
          <p className="text-center m-auto">{messageAfter} </p>
       </div> : ""


      
      }
      {(!loading &&!list) ? (
        queryObjects?.length > 0 ? (
          <TableName
            {...rest}
            setLimit={querySetLimit}
            setOffset={querySetOffset}
            limit={queryLimit}
            offset={queryOffset}
            pages={queryPages}
            page={queryPage}
            total={queryTotal}
            hideTableHeaders={queryObjects?.length === 0}
            key={entity + 'table'}
            entity={entity}
            columns={columns}
            data={queryObjects}
            sortBy={sortBy}
            refetch={queryRefetch}
          />
        ) : (
          <div className="p-2 text-danger "  style={{ background: "whitesmoke" }}>
          <p className="text-center m-auto">{
             entity ==="export_freight"&&Object.keys(inner_filters)?.length>0 ? noRecordsFilterMessage : "No Records Found"} </p>
      </div>
        )
      ) : list ? <TableName
                {...rest}
                setLimit={querySetLimit}
                setOffset={querySetOffset}
                limit={queryLimit}
                offset={queryOffset}
                pages={queryPages}
                page={queryPage}
                total={queryTotal}
                hideTableHeaders={queryObjects?.length === 0}
                key={entity + 'table'}
                entity={entity}
                columns={columns}
                data={queryObjects}
                sortBy={sortBy}
                refetch={queryRefetch}
              />  : (
        <>
          <Skeleton className="mx-1 mb-2" width="100%" height={50} />
          <div className="d-flex" style={{ overflow: 'hidden' }}>
            <Skeleton className="m-1" width="40vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="40vw" height={30} />
          </div>
          <div className="d-flex" style={{ overflow: 'hidden' }}>
            <Skeleton className="m-1" width="40vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="40vw" height={30} />
          </div>
          <div className="d-flex" style={{ overflow: 'hidden' }}>
            <Skeleton className="m-1" width="40vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="40vw" height={30} />
          </div>
          <div className="d-flex" style={{ overflow: 'hidden' }}>
            <Skeleton className="m-1" width="40vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="40vw" height={30} />
          </div>
          <div className="d-flex" style={{ overflow: 'hidden' }}>
            <Skeleton className="m-1" width="40vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="20vw" height={30} />
            <Skeleton className="m-1" width="40vw" height={30} />
          </div>
        </>
      )}
      {footer ? <div className="p-2">{footer}</div> : ''}
    </div>
  );
};
