import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import storage from "store2";
// import logo from "../../assets/images/logo-dark.png";
import logo from "../../assets/images/OriginKonnectlogo.png";
import { APP_PATHS } from "../../config";
import { AuthContext } from "../../context";
import { useObjectByPkQuery } from "../../helpers";

const AuthRedirect = ({ location }) => {
    // console.log("location is",location);
    const { is_authenticated, setAuthContext } = useContext(AuthContext);
    const user_id = storage.has("user_id") ? storage.get("user_id") : "";
    const id_token = storage.has("id_token") ? storage.get("id_token") : "";

    const { object: user, loading, error } = useObjectByPkQuery({
        id: user_id,
        entity: "user",
        fields: ` id active name email phone photo_url created_at role`,
    });

    // console.log("user ", user,user_id, loading, error);

    useEffect(() => {
        if (!loading && !error && user_id && id_token && user && user?.active){
            setAuthContext({ user, role: user?.role, is_authenticated: true });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id_token, is_authenticated, user, user_id]);


      if (!loading && !error && is_authenticated && user && user?.active){

          console.log("checking details are",location?.state?.from?.pathname , location?.state?.from?.pathname , location?.state?.from?.search , APP_PATHS.dashboard)
   
   
   let oat = location?.state?.from?.pathname === "/web/exporter/list" ? "/web/user_freight_forwarder/list" :  location?.state?.from?.pathname;
              return (
            <Redirect to={oat ? oat + location.state.from.search : APP_PATHS.dashboard} />
        );}

    //  if error, redirect to login
    if (!user_id && !is_authenticated) return <Redirect to={APP_PATHS.login} />;


    return (
        <div
            style={{ width: "100vw", height: "100vh" }}
            className="d-flex flex-column align-items-center justify-content-center user-select-none"
        >
            <div className="loader mb-3" />
            <img src={logo} alt="logo" className="loader-image" style={{ width: 200 }} />
        </div>
    );
};
export default AuthRedirect;
