import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { APP_PATHS } from "../../config";
import { AuthContext } from "../../context";

const AuthMiddleware = ({
    component: Component = null,
    render: Render = null,
    layout: Layout,
    path,
    exact,
    collapsed,
    setCollapsed,
    ...rest
}) => {
    const { is_authenticated } = useContext(AuthContext);

    const routeComponent = (props) =>
        is_authenticated ? (
            Render ? (
                Render(props)
            ) : Component ? (
                Layout ? (
                    <Layout collapsed={collapsed} setCollapsed={setCollapsed}>
                        <Component {...props} />
                    </Layout>
                ) : (
                    <Component {...props} />
                )
            ) : null
        ) : (
            <Redirect to={{ pathname: APP_PATHS.authRedirect, state: { from: props.location } }} />
        );
    // return <Route path={path} exact={exact} {...rest} render={()=>routeComponent} />;
    return <Route path={path} exact={exact} {...rest} render={routeComponent} />;
};

export default AuthMiddleware;

export const NonAuthmiddleware = ({ component: Component, layout: Layout, collapsed, setCollapsed,exact, ...rest }) => (
    <Route exact={exact}
        render={(props) => (
            <Layout collapsed={collapsed} setCollapsed={setCollapsed}>
                <Component {...props} />
            </Layout>
        )}
        {...rest}
    />
);
