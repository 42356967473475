import { useApolloClient, useMutation } from "@apollo/client";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import TimePicker from "react-time-picker";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Field } from "redux-form";
import { INSERT_ENUM, INSERT_ONE, Q_UNIQUE, UPDATE_ENUM, UPDATE_ONE } from "../../gql";
import { getDBTableName, getLabel, useEntityFields } from "../../helpers";
import "./DataForm.css";
import { LocationWidget } from "./LocationWidget";
import {SendRequest} from "./sampleRequest";
import Tags from "./Tags";
import TypeAhead from "./TypeAhead";
import { UploadImage } from "./UploadImage";
import { AuthContext } from "../../context";
import RatingValue from "../../pages/web/common/rating";
import DynamicInput from "./DynamicInput";
import { Accordion, Button, Collapse } from "react-bootstrap";
import { Collapsable } from "./collapse";
import FieldArray from "./UseFieldArray";

export const DataForm = ({
    entity,
    showTitle,
    defaultValues = {},
    overrideValues = {},
    columns = [],
    formSpan,
    method=null,
    subUrl=null,
    body=null,
    verticalLayout,
    readOnly = false,
    showForm = false,
    disableAddExcel=null,
    onComplete = () => {},
    onClose = () => {},
    refetch = () => {},
    enum_table = false,
    spanButtons = false,
    docType="excel",
    modalValue=null,
    icon = false,
    buttonClassName = "",
    skipColumns = [],
    skipdata=[],
    solution,
    userColumns=[],
    remove_fields=null,
    deepOverrideValues={},
    deleteFields=null,
    classes = null,
    showClose=true,
    showClear=true,
    saveLabel="SAVE"
}) => {
       console.log("override values are ",skipdata,overrideValues,entity, columns,deleteFields,defaultValues)
    //   console.log("default values are", entity,columns, defaultValues);
    const [open, setOpen] = useState(false);



    const apolloClient = useApolloClient();
    const [location, set_location] = useState({});
    const [modal, set_modal] = useState(modalValue || false);
    const [submitting, set_submitting] = useState(false);
    const entity_fields = useEntityFields({ entity, solution });
    const { id_token ,role } = useContext(AuthContext);

     console.log("entity fields in data form",entity,entity_fields,useEntityFields({ entity, solution }),modal,modalValue);
    let modified_columns =
        (columns?.length > 0 ? columns : entity_fields?.columns)?.map((c) => ({
            ...c,
            column: skipColumns?.includes(c.accessor) ? false : c.column,
            searchable: skipColumns?.includes(c.accessor) ? false : c.searchable,
            filterable: skipColumns?.includes(c.accessor) ? false : c.filterable,
            add: skipColumns?.includes(c.accessor) ? false : c.add,
            edit: skipColumns?.includes(c.accessor) ? false : c.edit,
        })) || [];
        console.log("entity fields in data form",entity,entity_fields,useEntityFields({ entity, solution }),modified_columns,modal,modalValue);

 
        if(skipdata){
            skipdata?.forEach((e,i)=>{
             if(defaultValues[e]){
                  delete defaultValues[e]
             }
             if(overrideValues[e]==false || overrideValues[e]){
                  delete overrideValues[e]
             }
            }) 
         }

         
        let entity_columns =[...modified_columns];
         console.log("ENTITY columns",entity, skipColumns,entity_columns,modified_columns)

        if(role !== "moderator")  {
            entity_columns =(columns?.length > 0 ? columns : entity_fields?.columns)?.filter((e,i) =>    !entity_fields?.userColumns?.skipColumn[e?.accessor]);
        // console.log("ENTITY columns",entity_columns,modified_columns)


            entity_columns?.forEach((e,i) =>   {
            //  console.log("outside", e?.accessor, entity_fields,entity_fields?.userColumns?.skipEnum?.[e?.accessor]);
             if(entity_fields?.userColumns?.skipEnum?.[e?.accessor]){
            //   console.log("inside values",e?.accessor,  entity_fields?.userColumns?.skipEnum?.[e?.accessor],e?.options?.splice(i,1))
                    e?.options?.forEach((o,i)=>  entity_fields?.userColumns?.skipEnum?.[e?.accessor]?.[o?.value]&&e?.options?.splice(i,1))
             }
          }
           )
         } else{
            entity_columns=[...modified_columns]
         }
 
    //  console.log("ENTITY columns",entity_columns,modified_columns)
    const { register, handleSubmit, errors, reset, control, formState, getValues, setValue, watch } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            ...defaultValues,
            date: defaultValues?.date ? new Date(defaultValues.date) : null,
            start_date: defaultValues?.start_date ? new Date(defaultValues.start_date) : null,
            end_date: defaultValues?.end_date ? new Date(defaultValues.end_date) : null,
            delivery_date: defaultValues?.delivery_date ? new Date(defaultValues.delivery_date) : null,
            requested_date: defaultValues?.requested_date ? new Date(defaultValues.requested_date) : null,
            available_date: defaultValues?.available_date ? new Date(defaultValues.available_date) : null,
            timestamp: defaultValues?.timestamp ? new Date(defaultValues.timestamp) : null,
            // weighted_date: defaultValues?.weighted_date ? new Date(defaultValues.weighted_date) : null,
            weighed_date: defaultValues?.weighed_date ? new Date(defaultValues.weighed_date) : null,
            loading_date: defaultValues?.loading_date ? new Date(defaultValues.loading_date) : null,
            unloading_date: defaultValues?.unloading_date ? new Date(defaultValues.unloading_date) : null,
            sailing_date: defaultValues?.sailing_date ? new Date(defaultValues.sailing_date) : null,
            cut_off : defaultValues?.cut_off  ? new Date(defaultValues.cut_off ) : null,
            eta: defaultValues?.eta ? new Date(defaultValues.eta) : null,
            etd: defaultValues?.etd ? new Date(defaultValues.etd) : null,
            inquiry_expiry: defaultValues?.inquiry_expiry ? new Date(defaultValues.inquiry_expiry) : null,
            cargo_readiness: defaultValues?.cargo_readiness ? new Date(defaultValues.cargo_readiness) : null,
            booking_validity: defaultValues?.cargo_readiness ? new Date(defaultValues.booking_validity) : null,
            shipping_instructions_cut_off: defaultValues?.shipping_instructions_cut_off ? new Date(defaultValues.shipping_instructions_cut_off) : null,
            gate_in_cut_off_date: defaultValues?.gate_in_cut_off_date ? new Date(defaultValues.gate_in_cut_off_date) : null,
            vgm_cut_off_date: defaultValues?.vgm_cut_off_date ? new Date(defaultValues.vgm_cut_off_date) : null,
            gate_opens: defaultValues?.gate_opens ? new Date(defaultValues.gate_opens) : null,
            expected_delivery_date: defaultValues?.expected_delivery_date ? new Date(defaultValues.expected_delivery_date) : null,
            listing_validity_date: defaultValues?.listing_validity_date ? new Date(defaultValues.listing_validity_date) : null,
            ...overrideValues,
            
        },
    });

    const { isDirty, isValid } = formState;
    const local_state = getValues();


    // useEffect(()=>{
    //     console.log("Local state is check as",local_state);
    // })


    // const { fields,append,   remove } = useFieldArray(
    //     {
    //       control,
    //       name:"containersDetails"
    //     }
    //   );


    useEffect(() => {
        
        const start = new Date(getValues("start_date")).getTime();
        const end = new Date(getValues("end_date")).getTime();
        if (start && end && start > end) setValue("end_date", null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [local_state]);







    //Generate Query
    const [insertOne] = useMutation(enum_table ? INSERT_ENUM(getDBTableName(entity)) : INSERT_ONE(getDBTableName(entity)));
    const [updateOne] = useMutation(enum_table ? UPDATE_ENUM(getDBTableName(entity)) : UPDATE_ONE(getDBTableName(entity)));
    const [insertNestedOne] = useMutation((UPDATE_ONE(getDBTableName("nbfc_valid_doc"))));

    let watching = watch();

    // console.log("watching details", watching);

    const [ss, setss] = useState({});

    useEffect(() => {
        
    // console.log("watching details are",watching?.["quantity_in_mt"],watching?.["wt_per_pack"] )


        if(watching?.["quantity_in_mt"]&&watching?.["wt_per_pack"]){


            // console.log("checking rsfdgrgsfdg", watching?.["quantity_in_mt"],watching?.["wt_per_pack"] ,(parseInt(watching["quantity_in_mt"])/ parseInt(watching["wt_per_pack"])))

            let value =  parseInt(watching["quantity_in_mt"])/ parseInt(watching["wt_per_pack"])

            setValue("no_of_box", value || 0)  ;

            setss({...ss,"no_of_box":value,"wt_per_pack":watching?.["wt_per_pack"],"quantity_in_mt":watching?.["quantity_in_mt"]})
        }

        if(watching?.["price_per_pack"]&&watching?.["no_of_box"]){


            // console.log("checking rsfdgrgsfdg", watching?.["quantity_in_mt"],watching?.["wt_per_pack"] ,(parseInt(watching["quantity_in_mt"])/ parseInt(watching["wt_per_pack"])))

            let value =  parseInt(watching?.["price_per_pack"]) * parseInt(watching?.["no_of_box"])

            setValue("total_amount", value || 0)  

            setss({...ss,"total_amount":value,"price_per_pack":parseInt(watching?.["price_per_pack"]),"no_of_box":parseInt(watching?.["no_of_box"])})

        }

        setss({...ss,
            
            "price_per_pack":parseInt(watching?.["price_per_pack"]),
            "no_of_box":parseInt(watching?.["no_of_box"]),
            
            "total_amount":parseInt(watching?.["price_per_pack"]) * parseInt(watching?.["no_of_box"])})


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watching?.["quantity_in_mt"],watching?.["wt_per_pack"],watching?.["price_per_pack"],parseInt(watching?.["no_of_box"])]);

    // console.log("watching details are",watching )
    // parseInt(watching?.["price_per_pack"]) * parseInt(watching?.["no_of_box"]) 

    const onSubmit = async (newData) => {

 
          let check ={...newData}
          let copyBody   = {}
        //   console.log("new data is ",newData,ss )

  
        if(disableAddExcel)
        {
            
           if(check?.["order_id"]){
            copyBody["order_id"] = check[body["accessor"]]["id"]
            //    delete body["accessor"]
            } 
            copyBody = {...newData,...overrideValues }
          
 
            const result = SendRequest({showTitle, docType, "method":method ? method : "post","subUrl":subUrl ? subUrl :"/user/GetDataInExcel", "body": copyBody,"id_token":id_token, message:"Sucessfully Created!"}).then(res=> res)
           

            if(result) {return set_modal(!modal);}
             

        }   
        let newObject = { ...newData,  ...location };

        if(entity==="export_order"){

              newObject = { ...newObject,...ss };

        }
        // console.log("checking default values as skipdata",skipdata)

        // console.log("new object is as follows", newObject);
        // console.log("new data is ===1 ",newData )

        set_submitting(true);


        if (!_.isEmpty(newObject)) {
            try {

                let fieldMap = {};
                entity_columns?.map((e) => {
                    fieldMap[e.accessor] = { ...e, name: e.accessor };
                    return null;
                });
                // console.log("field map is", fieldMap);
                // remove_fields&&remove_fields?.forEach((e,ind) =>   entity ==="contact"&&overrideValues[e]  && delete overrideValues[e] )

                for (const [key, value] of Object.entries({
                    ...defaultValues,
                    ...newObject,
                })) {
                    const field = fieldMap[key];

                    const field_name = field?.modifyDbName ?? field?.dbname ?? field?.accessor;
                //    console.log("fields Map ===>","field =====>", field,"field_name ", field_name, "value", value, "key", key, "*************************************************");
                    if (field && field.displayOnly) {   
                        delete newObject[key];
                        // } else if (field && !field.edit && defaultValues?.id) {
                        //     delete newObject[key];
                    } else if (field && !defaultValues?.id && !field.add && !field.dependsCollapse) {
                        delete newObject[key];
                    } else if (field && field.type === "uuid" && field_name) {
                        if (field.multiple && value?.length > 0) {
                            // newObject[field_name] = value?.map((e) => e.id || e?.value?.replace("_", "") || e);
                            newObject[field_name] = value?.map((e) => e.id || e?.value || e);
                        } else if (value && value.id) {
                            //  console.log("fields else if ", field, field_name, "value", value, "key", key, fieldMap);

                            newObject[field_name] = value.id;
                        } else {
                            //   console.log("fields end ", field, field_name, "value", value, "key", key, fieldMap);

                            newObject[field_name] = null;
                        }

                        if (key !== field_name) delete newObject[key];
                    } else if (field && field.type === "select" && field_name) {
                        if (value && value.id) {
                            newObject[field_name] = value.id;
                        } else if (value && value.name) {
                            newObject[field_name] = value.name;
                        } else {
                            newObject[field_name] = value;
                        }
                        if (key !== field_name) delete newObject[key];
                    } else if (field && (field.type === "number" || field.type === "currency" || field.type === "weight")) {
                        newObject[field_name] = Number(value);
                    } else if (field && field.type === "text") {
                        // newObject[field_name] =     Number(_.trim(value)) || _.trim(valvc  ue);
                        newObject[field_name] = _.trim(value);
                    } else if (field && (field?.type === "image" || field?.type === "video" || field?.type === "documents" )) {
                        newObject[field_name] = _.isArray(value) ? value ?? [] : value ? [value] : [];
                    } else if (field && (field_name === "photo_url" || field?.type === "document")) {
                        newObject[field_name] = _.isArray(value) ? value[0] : value;
                    } else if (field && (field?.type === "date" || field?.typefield_name === "date")) {
                        // console.log("It is a date", field, field_name);
                        newObject[field_name] = moment(value).add(1, "d");
                    }
                }
    //    console.log("44444444444444444444")

       if(entity === "nbfc" )
            {
                newObject = {...newObject,"nbfc_valid_docs":{"data":{...newObject?.nbfc_valid_docs[0]}}}
               
 

                if (defaultValues?.id) {
                    let sepObj = newObject["nbfc_valid_docs"]["data"];
                    delete newObject["nbfc_valid_docs"];

                    insertNestedOne({
                    variables: {
                        id: defaultValues?.nbfc_valid_docs[0]?.id,
                        set: { ...sepObj, ...overrideValues },
                    },
                }).then(({ data, error }) => {
                    if (!error && !_.isEmpty(data)) {
                        // toast.success("Updated", { autoClose: 1500 });
                        // set_modal(!modal);
                        reset();
                        refetch();
                        onComplete({ id: defaultValues?.id, ...newObject });
                        onClose();
                    } else if (error) {
                        if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e) => e.message)));
                        console.error(error);
                    }
                })
                .catch((error) =>
                    JSON.stringify(
                        error?.graphQLErrors?.map((e) =>
                            e.message.search("unique constraint") > 0 && e.message.search("user_email_key") > 0
                                ? toast.error("Email Already Exists")
                                : e.message.search("unique constraint") > 0 && e.message.search("user_phone_key") > 0
                                ? toast.error("Phone Number Already Exists")
                                : ""
                        )
                    )
                );

            }
            }
 
                // console.log("new object is", defaultValues, newObject);
                remove_fields&&remove_fields?.forEach((e,ind) =>   entity ==="contact"&&overrideValues[e]  && delete overrideValues[e] )


                const userOverride =  entity_fields?.userColumns?.[`${role}Override`] ? entity_fields?.userColumns?.[`${role}Override`] : {};

                
                
                if(deleteFields){
                    deleteFields?.map((e)=> overrideValues[e] ? delete overrideValues[e] : null)
                };
                console.log("VOERIDAVAKYES", overrideValues);
              

                if (defaultValues?.id) {

                   
                    updateOne({
                        variables: {
                            id: defaultValues?.id,
                            set: { ...newObject, ...overrideValues ,...deepOverrideValues,...userOverride},
                        },
                    })
                        .then(({ data, error }) => {
                            if (!error && !_.isEmpty(data)) {
                                toast.success("Updated", { autoClose: 1500 });
                                set_modal(!modal);
                                reset();
                                refetch();
                                onComplete({ id: defaultValues?.id, ...newObject });
                                onClose();
                            } else if (error) {
                                if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e) => e.message)));
                                console.error(error);
                            }
                        })
                        .catch((error) =>
                            JSON.stringify(
                                error?.graphQLErrors?.map((e) =>
                                    e.message.search("unique constraint") > 0 && e.message.search("user_email_key") > 0
                                        ? toast.error("Email Already Exists")
                                        : e.message.search("unique constraint") > 0 && e.message.search("user_phone_key") > 0
                                        ? toast.error("Phone Number Already Exists")
                                        : ""
                                )
                            )
                        );
                } else {
                    console.log("checkingd efault values",skipdata)
                    insertOne({
                        variables: {
                            object: { ...location, ...newObject, ...overrideValues, ...deepOverrideValues,...userOverride },
                        },
                    })
                        .then(({ data, error }) => {
                            if (!error && !_.isEmpty(data)) {
                                if(entity === "bidding"){
                                    toast.success("Sucessfully placed ", { autoClose: 1500 });
                                }else{
                                    toast.success("Inserted", { autoClose: 1500 });
                                }
                                
                                set_modal(!modal);
                                reset();
                                refetch();
                                onComplete({ id: data?.object?.id, ...newObject });
                                onClose();
                            } else if (error) {
                                if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e) => e.message)));
                                console.error(error);
                            }
                        })
                        .catch((error) => {
                            //if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e, i) => e.message)));
                            JSON.stringify(
                                error?.graphQLErrors?.map((e) =>
                                    e.message.search("unique constraint") > 0 && e.message.search("user_email_key") > 0
                                        ? toast.error("Email Already Exists")
                                        : e.message.search("unique constraint") > 0 && e.message.search("user_phone_key") > 0
                                        ? toast.error("Phone Number Already Exists")
                                        : ""
                                )
                            );
                        });
                    refetch();
                }
            } catch (error) {
                if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e) => e.message)));
                console.error(error);
            }
        }
        set_submitting(false);
    };

    const form_body = (
        <form className="needs-validation form theme-form " noValidate="" onSubmit={handleSubmit(onSubmit)}>
            <div className={`row ${classes ? classes : ""}`}>
                {(defaultValues?.id ? _.filter(entity_columns, { edit: true }) : _.filter(entity_columns, { add: true })).map(
                    (field, index) => {
                        field.name = field?.accessor;
                        // console.log("fiel dis", field.name, field)
                        if (field?.dependsValue) {
                            let obj = {};
                            //   console.log("check  ====>1 ", field?.dependsValue)
                            Object.values(field.dependsValue).forEach((value) => {
                                value = value.split(":");
                                obj[value[0] + "," + value[1]] = value[1];
                                //  console.log("check =====>2",value, watching, obj);
                                //  console.log("check =====>1", "true"==true, typeof watching[value[0]],watching[value[0]] ,typeof obj[value[0] + "," + watching[value[0]]],obj[value[0] + "," + watching[value[0]]]
                                //    ,obj[value[0] + "," + watching[value[0]]] ,(watching[value[0]] == obj[value[0] + "," + watching[value[0]]])  );
                                if (!(String(watching[value[0]]) == String(obj[value[0] + "," + watching[value[0]]]))) {
                                    field.hidden = true;
                                    value = [];
                                } else {
                                    field.hidden = false;
                                    value = [];
                                }
                            });
                        }

                    

                        if (field?.depends)
                            Object.values(field.depends).forEach((value) => {
                                if (!watching[value]) field.hidden = true;
                                else field.hidden = false;
                            });

                            // if (field?.calcDepends){
                                
                            //     let value =  parseInt(watching?.["quantity_in_mt"])/ parseInt(watching?.["wt_per_pack"])
                               

                            //     if(value&& getValues("no_of_box")!==value){


                            //         console.log("checking rsfdgrgsfdg", watching?.["quantity_in_mt"],watching?.["wt_per_pack"] ,(parseInt(watching["quantity_in_mt"])/ parseInt(watching["wt_per_pack"])))
                        
                                    
                        
                            //         setValue("no_of_box", value || 0)  
                            //     }
                            // }

                        // if (field.hide || field.hidden) return <span key={index} />;
                        if (field.hide || field.hidden) return <></>;
                        if (field.fillSpan)
                            return (
                                <div key={index} className={"col-md-" + String(field.fillSpan > 12 ? 12 : field.fillSpan)}>
                                    {" "}
                                </div>
                            );
                            console.log("HEADER details", field, field?.onlyHeader)

                        return (
                            <div
                                className={
                                    verticalLayout ||                                   
                                    ["textarea", "url", "video",  "map", "tag", "textarray"].includes(field.type)
                                        ? "mb-3 col-"+(field?.span || 12)
                                       
                                        :
                                        
                                        field.span
                                        ? "mb-3 col-md-" + String(field.span > 12 ? 12 : field.span)
                                        : formSpan
                                        ? "mb-3 col-md-" + String(formSpan > 12 ? 12 : formSpan)
                                        : "mb-3 col-md-3"
                                }
                                key={index}
                            >
                               {field?.onlyHeader&&field?.type != "collapse"  ? <h6 className="align-self-center p-2 mbt-2 bg-light text-dark mb-1 col-md-12"  >{field.Header}</h6> : null }
                               {/* {field?.onlyHeader&& (()=>   {
                                
                                  console.log("HEADER details", field)
                                  return field?.type != "collapse" && <h6 className="p-2 mt-3 bg-light text-dark"  >{field.Header}</h6>
                                  })
                                } */}
                               {field?.name &&  <div htmlFor={field.name}  className="mb-1">
                                    <span
                                        className={`h6 small text-uppercase text-${field.rules && field.rules.required ? "primary" : "muted"}`}
                                    >
                                        {field.Label || field.Header}
                                        {field.type === "weight" ? (
                                            " ( Kg )"
                                        ) : field.type === "currency" ? (
                                            <span>
                                                {" "}
                                                ( <i className="fa fa-sm fa-rupee-sign" aria-hidden="true" /> )
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    {field.rules && field.rules.required && <span className="text-danger ml-2"> *</span>}
                                </div> }
                                {!field?.onlyHeader&&(() => {
                                    //  console.log("field is ",field)
                                    switch (field.type) {
                                        case "uuid":
                                            return (
                                                <Controller
                                                    as={
                                                        <TypeAhead
                                                            name={Field.type}
                                                            {...field}
                                                            disabled={readOnly || field.disabled}
                                                            key={index}
                                                            object={{
                                                                ...defaultValues,
                                                                ...overrideValues,
                                                                ...local_state,
                                                            }}
                                                            overrideValue ={overrideValues}
                                                            onReset={() => {
                                                                if (field?.reset_fields?.length > 0) {
                                                                    field.reset_fields?.map((f) => {
                                                                        setValue(f, null);
                                                                        setValue(`${f}_id`, null);
                                                                        return null;
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    name={field.name}
                                                    control={control}
                                                    rules={field.rules}
                                                    onChange={([selected]) => selected}
                                                />
                                            );

                                        case "map":
                                            return (
                                                <Controller
                                                    as={
                                                        <LocationWidget
                                                            {...field}
                                                            object={{
                                                                ...defaultValues,
                                                                ...overrideValues,
                                                                ...local_state,
                                                            }}
                                                              onUpdate={set_location}
                                                        />
                                                    }
                                                    name={field.name}
                                                    control={control}
                                                    rules={field.rules}
                                                    onChange={([selected]) => selected}
                                                    // onChange={(e)=>console.log("evalue is",e)}
                                                />
                                            );
                                        case "checkbox":
                                        case "boolean":
                                            return (
                                                // <div className="d-flex justify-content-end align-items-end">
                                                <div >
                                                    <input
                                                        name={field.name}
                                                        disabled={readOnly || field.disabled}
                                                        type="checkbox"
                                                        className= {  "float-left mt-2"}
                                                        ref={register(field.rules)}
                                                    />
                                                </div>
                                            );

                                            case "testing":
                                                // console.log("checking testing values",field?.calcDepends);
                                                let tsas = field?.calcDepends ;
                                                // console.log("checking testing values",typeof tsas , eval(tsas)    );
 
                                                return (
                                                    // <div className="d-flex justify-content-end align-items-end">
                                                    <div >
                                                        <input
                                                            class="   form-control  float-left mt-2 "
                                                            name={field.name}
                                                            disabled={readOnly || field.disabled}
                                                            type="text"
                                                            value={eval(field?.calcDepends) || 0}
                                                            ref={register(field.rules)}
                                                        />
                                                    </div>
                                                );


                                        case "date":
                                            return (
                                                <div>
                                                    <Controller
                                                        as={<DatePicker disabled={readOnly || field.disabled} {...field} />}
                                                        name={field.name}
                                                        control={control}
                                                        rules={field.rules}
                                                        onChange={([selected]) => new Date(selected)}
                                                    />
                                                </div>
                                            );
                                        case "time":
                                            return (
                                                <div>
                                                    <Controller
                                                        as={<TimePicker disabled={readOnly || field.disabled} {...field} />}
                                                        name={field.name}
                                                        control={control}
                                                        rules={field.rules}
                                                        onChange={([selected]) => selected}
                                                    />
                                                </div>
                                            );
                                        case "datetime":
                                            return (
                                                <div>
                                                    <Controller
                                                        as={<DateTimePicker disabled={readOnly || field.disabled} {...field} />}
                                                        name={field.name}
                                                        control={control}
                                                        rules={field.rules}
                                                        onChange={([selected]) => selected}
                                                    />
                                                </div>
                                            );



                                            case "rating":
                                                return (
                                                    <div>
                                                        <Controller
                                                            as={ <RatingValue key={index} {...field} readonly={false} />}
                                                            name={field.name}
                                                            showTooltip={true}
                                                            control={control}
                                                            rules={field.rules}
                                                            onChange={([selected]) => selected}
                                                        />
                                                    </div>
                                                );
                                            // case "multiInput":
                                                    // return (
                                                    //      <div>
                                                    //                     {fields.map((item, index) => {

                                                    //                     return (
                                                    //                         < >
                                                                          
                                                    //                           <div class="row  ">
                                                    //                             <label for="one" class="col-sm-2 col-form-label h6 font-weight-normal   small  text-primary">{field.multiInputFields[0] }</label>
                                                    //                             <div class="col-sm-10">
                                                    //                                 <input
                                                    //                                 className="m-1  p-1    form-control  "
                                                    //                                 id="one"
                                                    //                                 name={`${field.name}[${index}].${field.multiInputFields[0]}`}
                                                    //                                 defaultValue={`${item[field.multiInputFields[0]]}`} // make sure to set up defaultValue
                                                    //                                 ref={register()}
                                                    //                                     />
                                                    //                             </div>
                                                    //                         </div>


                                                    //                         <div class="row ">
                                                    //                             <label for="two" class="col-sm-2 col-form-label   small  text-primary">{ field.multiInputFields[1] }</label>
                                                    //                             <div class="col-sm-10">
                                                    //                                 <input
                                                    //                                 className="m-1 p-1  form-control"
                                                    //                                 id="two"
                                                    //                                 type="number"
                                                    //                                 name={`${field.name}[${index}].${field.multiInputFields[1]}`}
                                                    //                                 defaultValue={`${item[field.multiInputFields[1]]}`} // make sure to set up defaultValue
                                                    //                                 ref={register()}
                                                    //                             />
                                                    //                             </div>
                                                    //                         </div>

                                                    //                              <div class="row  ">
                                                    //                             <label for="three" class="col-sm-2 col-form-label h6 small  text-primary">{ field.multiInputFields[2]  }</label>
                                                    //                             <div class="col-sm-10">
                                                    //                                 <input
                                                    //                                     className=" m-1 p-1 form-control"
                                                    //                                     id="three"
                                                    //                                     type="date"
                                                    //                                     name={`${field.name}[${index}].${field.multiInputFields[2]}`}
                                                    //                                     defaultValue={`${item[field.multiInputFields[2]]}`} // make sure to set up defaultValue
                                                    //                                     ref={register()}
                                                    //                                 />  
                                                    //                             </div>
                                                    //                         </div>

     
                                                                             
                                                    //                          <button className="btn btn-sm btn-primary m-1" type="button" onClick={() => remove(index)}>
                                                    //                             Delete
                                                    //                         </button>
                                                    //                         </>
                                                    //                     );
                                                    //                                                                 })}
                                                    //                      <button className="btn btn-sm btn-primary m-1"
                                                    //                         type="button"
                                                    //                         onClick={() => {
                                                    //                             append({"utrno":0, "payed":0} );
                                                    //                             // append({} );
                                                    //                         }}
                                                    //                     >
                                                    //                     Add
                                                    //                     </button>

                                                          
                                                    //   </div>        );
                                                 case "multiInputs":
                                                    console.log("checksas",field,field.name)
                                                    return (
                                                         <div>

                                                                        <FieldArray field={field} state={local_state} control={control} register={register}/>
{/* 
                                                                        {fields?.map((item, index) => {

                                                                        return (
                                                                            < > */}
                                                                           
{/*                                                                           
                                                                              <div class="row  ">
                                                                                <label for="one" class="col-sm-2 col-form-label h6 font-weight-normal   small  text-primary">{field.multiInputFields[0] }</label>
                                                                                <div class="col-sm-10">
                                                                                    <input
                                                                                    className="m-1  p-1    form-control  "
                                                                                    id="one"
                                                                                    name={`${field.multiName}[${index}].one`}
                                                                                    defaultValue={`${item[field.multiInputFields[0]]}`} // make sure to set up defaultValue
                                                                                    ref={register()}
                                                                                        />
                                                                                </div>
                                                                            </div> */}

{/* 
                                                                            <div class="row ">
                                                                                <label for="two" class="col-sm-2 col-form-label   small  text-primary">{ field.multiInputFields[1] }</label>
                                                                                <div class="col-sm-10">
                                                                                    <input
                                                                                    className="m-1 p-1  form-control"
                                                                                    id="two"
                                                                                    type="number"
                                                                                    name={`${field.multiName}[${index}].two`}
                                                                                    defaultValue={`${item[field.multiInputFields[1]]}`} // make sure to set up defaultValue
                                                                                    ref={register()}
                                                                                />
                                                                                </div>
                                                                            </div> */}

                                                                           

     
                                                                             
                                                                             {/* <button className="btn btn-sm btn-primary m-1" type="button" onClick={() => remove(index)}>
                                                                                Delete
                                                                            </button>
                                                                            </> */}
                                                                        {/* ); */}
                                                                                                                    {/* })} */}
                                                                         {/* <button className="btn btn-sm btn-primary m-1"
                                                                            type="button"
                                                                            onClick={() => {
                                                                                append({"container":0} );
                                                                                // append({} );
                                                                            }}
                                                                        >
                                                                        Add
                                                                        </button> */}

                                                          
                                                      </div>        );
                                        case "select":
                                            if (field?.options?.length > 0){
                                            // console.log("select is", field)
                                                return (
                                                    <select
                                                        className="form-control "
                                                        name={field.name}
                                                        type={field.type}
                                                        disabled={readOnly || field.disabled || field.options?.length < 2}
                                                        multiple={field.multiple || false}
                                                        ref={register(field.rules)}
                                                        // defaultValue ={field.options[1].value}
                                                        
                                                    >
                                                        {field.options?.map((o, i) => (
                                                            <option value={o?.value ?? o?.id} key={i} className="option">
                                                                {o?.comment ?? o?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                );
                                                        }
                                            else return <span key={Math.random()} />;
                                            
                                        case "textarray":
                                            return (
                                                <Controller
                                                    as={<Tags {...field} disabled={readOnly || field.disabled} />}
                                                    name={field.name}
                                                    control={control}
                                                    rules={field.rules}
                                                    onChange={([selected]) => selected}
                                                />
                                            );
                                        case "tag":
                                            return (
                                                <Controller
                                                    as={<Tags {...field} disabled={readOnly || field.disabled} />}
                                                    name={field.name}
                                                    control={control}
                                                    rules={field.rules}
                                                    onChange={([selected]) => selected}
                                                />
                                            );

                                        case "collapse":

                                               
                                             return <Collapsable {...field} name="collapses"
                                                        control={control}
                                                        entity_columns={entity_columns}
                                                        field={field}
                                                        register = {register}
                                                        rules={field.rules}
                                                        onChange={([selected]) => selected} 
                                                        disabled={readOnly || field.disabled} 
                                                    /> 
                                                    
                                   
                                    
                                        //   let chekc =   _.filter(entity_columns, { dependsCollapse: field?.id })?.map((childField,i)=>{

                                        //             console.log("childField", childField, childField.type == "boolean");        
                           
                                        //             childField.name = childField.accessor;

                                        //           return  <>
                                                
                                        //               {
                                        //                     (() =>{
                                        //                     if(childField.type == "boolean"){
                                        //                         return ( 
                                        //                                 <div className="mb-3 col-3">
                                        //                                     <label for="exampleInputEmail1">{childField.name}</label>
                                        //                                     <input
                                        //                                         name={childField.name}
                                        //                                         disabled={readOnly || field.disabled}
                                        //                                         type="checkbox"
                                        //                                          className= {  "float-left mt-2"}
                                        //                                         ref={register(childField.rules)}
                                        //                                     /> 
                                        //                                 </div>
                                        //                         );
                                        //                         }

                                        //                     return  <div className="mb-3 col-3">
                                                            
                                        //                                 <label for="exampleInputEmail1">{childField.name}</label>
                                                                        
                                        //                                 <input
                                        //                                     key={i}
                                        //                                     className="form-control "
                                        //                                     name={childField.name}
                                        //                                     type="number"
                                        //                                     step={childField.step}
                                        //                                     min={childField.min}
                                        //                                     defaultValue={childField.min || 0}
                                        //                                     disabled={readOnly || childField.disabled}
                                        //                                     max={childField.max}
                                        //                                     ref={register({
                                        //                                         ...childField.rules,
                                        //                                         validate: { check: (val) => val.match() },
                                        //                                     })}
                                        //                                 />
                                        //                           </div>
                                        //                 } 
                                            
                
                                        //                 ).call(this)
                                                        
                                        //              } 
                                        //             </>
                                        //             })

                                        //     console.log("check details are",chekc)
                                            // return <>
                                            {/* <button  className="p-2 mt-3 bg-light text-dark" data-toggle="collapse" data-target={`#${field.id}`} aria-expanded="false" aria-controls="collapseExample">{field.Header}</button> */}
                                            {/* <button class="btn btn-primary" type="button" data-toggle="collapse" data-target={`#${field.id}`} aria-expanded="false" aria-controls="collapseExample">
                                            {field.Header}
                                            </button>
                                             
                                             <div class="collapse" id={field.id}>
                                             <div class="card card-body">
                                             {chekc}
                                             </div>
                                           </div> */}

{/*                                             
                                            <>
                                                <Button
                                                    onClick={() => setOpen(!open)}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={open}
                                                >
                                                    click
                                                </Button>
                                                <Collapse in={open}>
                                                    <div id="example-collapse-text">
                                                    {chekc}
                                                    </div>
                                                </Collapse>
                                                </>                 
                                                 </>  */}
                                                 
                                                 ;
                                         

                                        case "number":
                                        case "currency":
                                        case "weight":
                                            return (
                                                <input
                                                    className="form-control "
                                                    name={field.name}
                                                    type="number"
                                                    step={field.step}
                                                    min={field.min}
                                                    defaultValue={field.min || 0}
                                                    disabled={readOnly || field.disabled}
                                                    max={field.max}
                                                    ref={register({
                                                        ...field.rules,
                                                        validate: { check: (val) => val.match() },
                                                    })}
                                                />
                                            );
                                        case "textarea":
                                            return (
                                                <textarea
                                                    className="form-control "
                                                    name={field.name}
                                                    type={field.type}
                                                    step={field.step}
                                                    pattern=".{3,}"
                                                    title="3 characters minimum"
                                                    // min={field.min}
                                                    message={field?.message}
                                                    disabled={readOnly || field.disabled}
                                                    max={field.max}
                                                    ref={register(field.rules)}
                                                />
                                            );

                                            case "dependsText":
                                                return (
                                                    <div>
                                                    <input
                                                    className="form-control "
                                                    name={field.name}
                                                    // type={field.type}
                                                    readOnly={readOnly || field.disabled}
                                                        ref={register(field.rules)}
                                                    />
                                                    </div>
                                                );


                                        case "image":
                                        case "photo":
                                        case "video":
                                        case "document":
                                        case "documents":
                                            return (
                                                <div>
                                                    <Controller
                                                        as={
                                                            <UploadImage
                                                                {...field}
                                                                entity={entity}
                                                                multiple={field.multiple || false}
                                                                disabled={readOnly || field.disabled}
                                                            
                                                            />
                                                        }
                                                        setValue={setValue}
                                                        control={control}
                                                        rules={field.rules}
                                                        name={field.name}
                                                        className="form-control "
                                                        privateImage={field.privateImage}
                                                        // onChange={(e) => console.log(e)}
                                                    />
                                                </div>
                                            );
                                        default:
                                            return (
                                                <input
                                                    className="form-control "
                                                    name={field.name}
                                                    type={field.type}
                                                    readOnly={readOnly || field.disabled}
                                                    ref={register({
                                                        ...field.rules,
                                                        validate: {
                                                            unique: async (value) => {
                                                                if (
                                                                    field.unique &&
                                                                    value &&
                                                                    value !==
                                                                        _.get(
                                                                            {
                                                                                ...defaultValues,
                                                                                ...overrideValues,
                                                                                ...local_state,
                                                                            },
                                                                            field.name
                                                                        )
                                                                ) {
                                                                    const { data, error } = await apolloClient.query({
                                                                        query: Q_UNIQUE(getDBTableName(entity), field.name),
                                                                        variables: { text: _.trim(value) },
                                                                    });
                                                                    if (!error && !_.isEmpty(data) && data?.counts?.aggregate?.count)
                                                                        return `Already Exists !`;
                                                                    else return true;
                                                                } else return true;
                                                            },
                                                        },
                                                    })}
                                                />
                                            );
                                    }
                                }).call(this)}
                                {errors?.[field.name] && (
                                    <small className="text-danger">{errors?.[field.name]?.message || "Invalid Input"}</small>
                                )}
                            </div>
                        );
                    }
                )}
                {spanButtons && (
                    <div className="col">
                        {isDirty && isValid && (
                            <button className="btn btn-sm btn-success float-end m-1" type="submit">
                                {defaultValues?.id ? "UPDATE" : "SAVE"}
                            </button>
                        )}
                        {isDirty && (
                            <button className="btn btn-sm btn-warning float-end m-1" type="button" onClick={() => reset(defaultValues)}>
                                {defaultValues?.id ? "RESET" : "CLEAR"}
                            </button>
                        )}
                    </div>
                )}
            </div>
            {!spanButtons && (
                <>
                    <hr />
                    <div className="form-row ">
                        <div className="col pb-2">
                            {(!submitting&&showClose) && (
                                <button
                                    className="btn btn-sm btn-warning float-left m-1"
                                    type="button"
                                    onClick={() => {
                                        reset();
                                        set_modal(!modal);
                                        onClose();
                                        refetch();
                                    }}
                                >
                                    CLOSE
                                </button>
                            )}
                            {(isDirty&&showClear) && (
                                <button className="btn btn-sm btn-warning m-1" type="button" onClick={() => reset(defaultValues)}>
                                    {defaultValues?.id ? "RESET" : "CLEAR"}
                                </button>
                            )}
                            {isDirty && (
                                <button
                                    className="btn btn-sm btn-success float-end m-1"
                                    // style={isValid && submitting === true ? { pointerEvents: "none" } : { pointerEvents: "enabled" }}
                                    type="submit"
                                    onClick={(e) => {
                                        let X = e.clientX;
                                        let Y = e.clientY;
                                        if ((X === 0) & (Y === 0)) {
                                            e.preventDefault();
                                            return;
                                        }

                                        isValid && set_submitting(true);
                                    }}
                                >
                                    {defaultValues?.id ? "UPDATE" : saveLabel}
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </form>
    );

    return (
        <span className="m-0 p-0">
            {showForm ? (
                form_body
            ) : (
                <>
                    {icon ? (
                        <span className={"btn btn-sm btn-white p-0" + buttonClassName} onClick={() => set_modal(!modal)}>
                            {defaultValues?.id ? <i className={" fa fa-edit " + icon} /> : <i className={" fa fa-plus m-2 " + icon} />}
                        </span>
                    ) : (
                        <button className={"btn btn-sm btn-primary m-1" + buttonClassName} onClick={() => set_modal(!modal)}>
                            {defaultValues?.id  ? "EDIT" : showTitle ==="Excel" ? "DOWNLOAD" : "ADD"}{" "}
                            {showTitle ? getLabel(_.isString(showTitle) ? showTitle : entity)?.toUpperCase() : getLabel(entity)?.toUpperCase() }
                            {/* {showTitle ? getLabel(_.isString(showTitle) ? showTitle : entity).toUpperCase() : ""} */}
                            {console.log("modal body is title is",showTitle)}
                        </button>
                    )}
                    <Modal isOpen={modal} className="mx-auto responsive-modal">
                        <ModalHeader
                            className="bg-light p-2"
                            toggle={() => {
                                set_modal(!modal);
                                onClose();
                                refetch();
                                reset();
                            }}
                        >
                            {defaultValues?.id ? "EDIT" : "ADD"} {getLabel(_.isString(showTitle) ? showTitle : entity).toUpperCase()}
                        </ModalHeader>
                        <ModalBody>{form_body}</ModalBody>
                    </Modal>
                </>
            )}
        </span>
    );
};
