import { useMutation } from "@apollo/client";
import _ from "lodash";
import React from "react";
import { toast } from "react-toastify";
import { INSERT_ONE } from "../../../../../gql";
import { getDBTableName, getParams } from "../../../../../helpers";

const Blr = () => {

	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: id } = getParams();


  const [insertOne] = useMutation( INSERT_ONE(getDBTableName("shipping_instructions")));


    const handleSubmit=(e)=>{

         console.log("entity ", entity, solution,page, id)

         e.preventDefault();

         let arryOfObj = [...e.target.elements]?.map((e,i)=>{

          if(e?.value == "")  return {[e.name]:null}
          
          return {[e.name]:e.value}
          
          });

         let Obj  =   Object.assign({},...arryOfObj);

           insertOne({ variables: { object: {customer_id:id, ...Obj }, }, })
          .then(({ data, error }) => {
              if (!error && !_.isEmpty(data)) {
                  toast.success("Inserted", { autoClose: 1500 });
                  
              } else if (error) {
                  if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e) => e.message)));
                  console.error(error);
              }
          })
          .catch((error) => { toast.error("Phone Number Already Exists") });
     }


  return <form onSubmit={(e)=>handleSubmit(e)}>
  
             <div class="container page-content  ">
            <p className="text-center font-weight-bold"><strong>DRAFT BL INSTRUCTIONS</strong></p>
 
            <div class="row">
                <div class=" font-weight-bold col-sm font-weight-bold">
                SHIPPER Name & Address:   <textarea rows="2" name="ShipperNameAndAddress"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none",borderBottom:"1px solid c6c8c8"}}></textarea>
                </div>
                <div class="col-sm ">
                    <table>
                        <tr>
                            <td>BOOKING NO:</td><td><input name="BookingNo"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none",borderBottom:"1px solid c6c8c8"}}></input></td>
                        </tr>
                        <tr>
                            <td>TEL NO: </td><td><input name="TelNo" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none",borderBottom:"1px solid c6c8c8"}}></input></td>
                        </tr>
                        <tr>
                            <td>E-MAIL ADDRESS</td><td><input name="EmailAddress"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none",borderBottom:"1px solid c6c8c8"}}></input></td>
                        </tr>
                    </table>
                  

 
<br/>

                </div>
            
            </div>

            <div class="row ">
                <div class="col-sm ">
                CONSIGNEE  Name & Address:    <textarea rows="2" name="ConsigneeNameAndAddress"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none",}}></textarea>
                </div>
                <div class="col-sm ">
                {/* One of two columns */}
                </div>
            
            </div>

            <div class="row">
                <div class="col-sm ">
                 NOTIFY  PARTY  Name & Address:   <br/>
                <textarea rows="2" name="NotifyPartyNameAndAddress"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>
              
                </div>
                <div class="col-sm ">
               SHIPPING LINE          
              <br/>
              <textarea rows="2" name="ShippingLine"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>        </div>
            
            </div>

            <div class="row">
                <div class="col-sm ">
                 PLACE OF RECEIPT: 
                <br/>
                <textarea name="PlaceOfReceipt" rows="2"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>                </div>
                <div class="col-sm ">
                 PLACE OF DELIVERY:
               <br/>
               <textarea rows="2" name="PlaceOfDelivery" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>		                </div>
            
            </div>
            <div class="row">
                <div class="col-sm ">
                 PORT OF LOADING: 
                <br/>    <textarea rows="2" name="PortOfLoading" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>                </div>
                <div class="col-sm ">
                 PORT OF DISCHARGE : 
                
                <br/>
                <textarea rows="2" name="PortOfDischarge" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>	                </div>
            
            </div>
            <div class="row ">
                <div class="col-sm ">
                VESSEL:            
               <br/>
               <textarea rows="2" name="Vessel" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>             </div>
                
            
            </div>
          
            <br/>

            <div class="row  ">
            <table class="table">
  <thead>
    <tr>
      <td scope="col ">     MARKS & NUMBERS </td>
      <td scope="col ">    NUMBER & KIND OF PACKAGES
            DESCRIPTION OF GOODS
     </td>
      <td scope="col ">WEIGHT
         (KG/MT)
     </td>
      <td scope="col">MEASUREMENT</td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td scope="row"> 
      <textarea rows="2" name="MarksAndNumbers"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>
    </td>
      <td>   <textarea rows="2" name="NumberAndKindOfPackagesDescriptionOfGoods" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>
        </td>
      <td>   <textarea rows="2" name="WeightInKGorMT" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea></td>
      <td>   <textarea rows="2" name="Measurement"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea></td>
    </tr>
 
  </tbody>
</table>
            </div>
          

            <br/>


            <div class="row">
                <div class="col-sm ">
                TOTAL NUMBER OF CONTAINER’S/PACKAGES : 
                 <br/>
                 <textarea rows="2" name="TotalNumberOfContainer/Packages" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>              
                </div>
                <div class="col-sm ">
                MOVEMENT:
                  <br/>
                 <textarea rows="2" name="Movement"  style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>		                
               </div>
               <div class="col-sm ">
               FREIGHT CHARGES:
                  <br/>
                 <textarea rows="2" name="FreightCharges" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>		                
               </div>
            </div>


            <div class="row">
                <div class="col-sm ">
                NUMBER OF ORIGINALS  : 
                 <br/>
                 <textarea rows="2" name="NumberOfOriginals" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>              
                </div>
                <div class="col-sm ">
                REMARKS:
                  <br/>
                 <textarea rows="2" name="Remarks" style={{width:"100%", border:"1px solid   #c6c8c8",outline:"none"}}></textarea>		                
               </div>
                
            </div>

            <button type="submit">SUBMIT</button>
 


             </div>
         </form>
     


}

export default Blr ;