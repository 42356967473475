import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../components/common/DataTable";
import { PageContainer } from "../../../components/common/PageContainer";
import { GeneralContext } from "../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields } from "../../../helpers";

const List = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
    const { enum_price_type, enum_location_type } = useContext(GeneralContext);
    const entity_fields = useEntityFields({ "entity":"dataCenter" });
    // console.log("🚀 ~ file: list.js ~ line 12 ~ List ~ entity_fields",entity === "dataCenter", useEntityFields({ entity }))
    const override_fields = {};
    if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    return (
        <PageContainer
            breadcrumbTitle={getLabel(entity, true)}
            // breadcrumbOptions={["price", "location"]}
            breadcrumbArg={3}
            tab_arg={4}
            hideFilter={false}
            htmlToExcelShow={true}
            htmlToExcelShowHeaders={["type",	"month",	"port_pol",	"cush_",	"sb_no",	"sbdt",	"iec",	"exporter",	"exporter_address",	"exporter_city",	"exporter_pin",	"contact_no",	"email_id",	"contact_person",	"consignee",	"port_pod",	"destination_country",	"invoice_no",	"ritc",	"ch",	"item",	"quantity",	"uqc",	"item_rate",	"fob_in_fc",	"currency",	"item_rate_in_inr"	,"fob"
            ]}
            downloadExcelByfilters={true}
            disableExcel={false}
            excelSubUrl = "user/dataCenter"
            tab_options={(() => {
                let tab_options = [];
                // switch (tab) {
                    // case "price":
                    //     tab_options = !_.isArray(enum_price_type)
                    //         ? []
                    //         : enum_price_type?.map((e) => ({
                    //               ...entity_fields,
                    //               ...override_fields,
                    //               overrideValues: {
                    //                   ...(entity_fields?.overrideValues || {}),
                    //                   ...(override_fields?.overrideValues || {}),
                    //               },
                    //               label: e?.comment,
                    //               value: e?.value,
                    //               component: DataTable,
                    //               filters: {
                    //                   ...(entity_fields?.filters || {}),
                    //                   ...(override_fields?.filters || {}),
                    //                   price_type: { _eq: e.value },
                    //               },
                    //           }));
                    //     break;
                    // case "location":
                    //     tab_options = !_.isArray(enum_location_type)
                    //         ? []
                    //         : enum_location_type?.map((e) => ({
                    //               ...entity_fields,
                    //               ...override_fields,
                    //               overrideValues: {
                    //                   ...(entity_fields?.overrideValues || {}),
                    //                   ...(override_fields?.overrideValues || {}),
                    //               },
                    //               label: e?.comment,
                    //               value: e?.value,
                    //               component: DataTable,
                    //               filters: {
                    //                   ...(entity_fields?.filters || {}),
                    //                   ...(override_fields?.filters || {}),
                    //                   location: { location_type: { _eq: e.value } },
                    //               },
                    //           }));
                    //     break;
                    // default:
                //         tab_options = ENUM_ACTIVE.map((e) => ({
                //             ...entity_fields,
                //             ...override_fields,
                //             overrideValues: {
                //                 ...(entity_fields?.overrideValues || {}),
                //                 ...(override_fields?.overrideValues || {}),
                //             },
                //             label: e?.comment,
                //             value: e?.value,
                //             component: DataTable,
                //             filters: {
                //                 ...(entity_fields?.filters || {}),
                //                 ...(override_fields?.filters || {}),
                //                 active: { _eq: e.value === "active" ? true : false },
                //             },
                //         }));
                //         break;
                // }
                tab_options = [
                    {
                        label: "ALL",
                        value: "all",
                        ...entity_fields,
                        ...override_fields,
                        overrideValues: {
                            ...(entity_fields?.overrideValues || {}),
                            ...(override_fields?.overrideValues || {}),
                        },
                        component: DataTable,
                        filters: {
                            ...(entity_fields?.filters || {}),
                            ...(override_fields?.filters || {}),
                        },
                    },
                    ...tab_options,
                ];
                return tab_options;
            }).call(this)}
        />
    );
};

export default List;
