import React, { useContext, useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { GeneralContext } from "../../context";
import { FullMap, getLocationDataFromGeoAddress, getLocationDataFromGeoCode } from "../../helpers";

export const LocationWidget = React.forwardRef(({name, onUpdate = () => {}, onChange = () => {}, object = {} }, ref) => {
    const { current_location } = useContext(GeneralContext);
    const [search, setSearch] = useState("");
    const [location, setLocation] = useState({ ...current_location, ...object });

    // console.log("Location widget", location,name)

    useEffect(() => {
        if (location?.address !== object?.address) {

            // if(name=="location.data"){
            //     onUpdate({"data":location});
            //     onChange({"data":location?.address});
            // } else{
            //     onUpdate(location);
            //     onChange( location?.address);
            // }
                   onUpdate(location);
                onChange( location?.address);
            // onChange(location);
        } else if (!location?.address && location?.lat && location?.lng) {
            getLocationDataFromGeoCode(location).then(location);
            setSearch("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.address, object?.address]);

    return (
        <div ref={ref} className="row">
            <div className="col-sm-12 col-md-6">
                <PlacesAutocomplete
                    value={search}
                    onChange={setSearch}
                    onSelect={(e) => {
                        setSearch(e);
                        getLocationDataFromGeoAddress(e).then(setLocation);
                    }}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <input
                                {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input form-control",
                                })}
                            />
                            <div className="autocomplete-dropdown-container shadow">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                            className: suggestion.active ? "suggestion-item active" : "suggestion-item",
                                            style: suggestion.active
                                                ? { backgroundColor: "#f0f0f0", cursor: "pointer" }
                                                : { backgroundColor: "#ffffff", cursor: "pointer" },
                                        })}
                                    >
                                        <div className="p-2">{suggestion.description}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                <br />

                <FullMap
                    style={{ zIndex: 0 }}
                    data={location}
                    onMarkerDragEnd={(e) => getLocationDataFromGeoCode({ lat: e.latLng.lat() , lng: e.latLng.lng() }).then(setLocation)}
                    isMarkerShown
                    loadingElement={<div style={{ height: `20rem` }} />}
                    containerElement={<div style={{ height: `20rem` }} />}
                    mapElement={<div style={{ height: `20rem` }} />}
                />
            </div>
            <div className="col-sm-12 col-md-6 my-1">
                <div className="card card-body">
                    <div className="h6 small text-primary text-uppercase">Full Address</div>
                    <div className="h6">
                        <textarea
                            className="form-control "
                            pattern=".{3,}"
                            value={location?.address || ""}
                            onChange={(e) => setLocation({ ...location, address: e.target.value })}
                        />
                    </div>
                    <div className="h6 small text-primary text-uppercase">Area</div>
                    <div className="h6">{location?.area || ""}</div>
                    <div className="h6 small text-primary text-uppercase">City</div>
                    <div className="h6">{location?.city || ""}</div>
                    <div className="h6 small text-primary text-uppercase">District</div>
                    <div className="h6">{location?.district || ""}</div>
                    <div className="h6 small text-primary text-uppercase">State</div>
                    <div className="h6">{location?.state || ""}</div>
                    <div className="h6 small text-primary text-uppercase">Country</div>
                    <div className="h6">{location?.country || ""}</div>
                    <div className="h6 small text-primary text-uppercase">Post Code</div>
                    <div className="h6">{location?.postcode || ""}</div>
                </div>
            </div>
        </div>
    );
});
