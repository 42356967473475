import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { DetailInfo } from "../../../../../components/common/DetailInfo";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { AuthContext } from "../../../../../context";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import CardInfo from "./newDetail";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();

 
	let entity = "user_manage_order";
	const {   user_id } = useContext(AuthContext);
     //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    // console.log("updated_enum_freight_statsus",enum_freight_order_status)
	const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });

	const { objectS } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"shipping_containers" }) });

console.log("chsdeck  sd weasdasdb asdentadasdity",useWebEntityFields({ entity: "shipping_containers"}))
	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			// TableName={}
			entity={"customer"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				{
                    label: getLabel("shipping_instructions", true),
                    value: objectS?.active_contacts?.aggregate?.count || 0,
                    total: objectS?.total_contacts?.aggregate?.count || 0,
                },
                {
                    label: getLabel("order_note", true),
                    value: objectS?.active_locations?.aggregate?.count || 0,
                    total: objectS?.total_locations?.aggregate?.count || 0,
                },
			]}
			tab_options={[
				{
					...useWebEntityFields( "user_manage_order"),
					label: getLabel("user_manage_order", true),
					value: "user_manage_order",
					component: DataTable,
					// filters: {"order_id":{_eq:parent_id}},
					filters: {  "id":{_eq:parent_id} },
					overrideValues: { customer_id: object?.customer_id , "order_id":parent_id},
					skipColumns: ["user"],
                    showList: true,
                    ShowListCardInfo : CardInfo,
					hideHeader:true


				},	
				// {
				// 	...useWebEntityFields( "shipping_instructions"),
				// 	label: getLabel("shipping_instructions", true),
				// 	value: "shipping_instructions",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: object?.customer_id},  "order_id":{_eq:parent_id}  },
				// 	overrideValues: { customer_id: object?.customer_id , "order_id":parent_id},
				// 	skipColumns: ["user"],
                //     showList: true,

				// },	
				
				// {
				// 	...useEntityFields({ entity: "order_note"}),
				// 	label: getLabel("order_note", true),
				// 	value: "order_note",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: object?.customer_id,    }, "order_id":{_eq:parent_id}  },
				// 	overrideValues: { customer_id: object?.customer_id , "order_id":parent_id},
				// 	skipColumns: ["user"],
                //     showList: true,

				// },	


				 
				 
			]}
		/>
	);
};

export default Detail;
