// import React from "react";
import { Button , Modal, Row, Col,Container, Form} from "react-bootstrap";
import {SendRequest} from "../../../../../components/common/sampleRequest";
import React, { useContext  } from "react";
import { AuthContext } from "../../../../../context";
import { Input } from "reactstrap";

 
 export const Modals =   (props) => {


    const [modal, setModal] = React.useState(false);
    const { user_id,  id_token } = useContext(AuthContext);

    const order = async (e) =>{
        console.log("bid_price_per_mt",e.target?.bid_price_per_mt?.value);
        e?.preventDefault();
        if(e.target?.bid_price_per_mt?.value){

            await  SendRequest({
              body:{...props?.body?.original,user_id,id_token, bidValue:e.target?.bid_price_per_mt?.value},
              responseType:null,
              docType:null,
              "method":"POST",
              "subUrl":"/user/bidding_order",
              message:"Order Placed sucessfully"});
              setModal(false)

        }
      
 
    }
 
    return <> { <Modal  {...props} size="lg"   
                              backdrop="static" 
                              keyboard={false} 
                              show={modal}
                              aria-labelledby="contained-modal-title-vcenter" centered >

                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter"> Bid Details </Modal.Title>
                    </Modal.Header>
                    <Form  onSubmit={order}>
                        <Modal.Body>
                          <Container  className=" row justify-content-center" >
                            <Row className="pd-1"> 
                              <Col sm className="m-1 text-end small text-primary text-uppercase"> <b>Bid Price :</b> </Col> 
                              <Col sm className="text-start"><Input id="bid_price_per_mt" type="number"></Input> </Col>      
                                                                      <Col className="text-left">&nbsp;  {props?.body?.original?.total_amount} </Col>  
                              </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button type="submit" style={{float:"right"}} >Bid</Button>
                          <Button onClick={(e)=> setModal(false)}>Close</Button>
                          
                        </Modal.Footer>
                        </Form>
                      </Modal> } 
      
                     <button className="btn btn-sm btn-primary p-1 pl-2 pr-2 responsive-btn" onClick={(e)=>setModal(true)}>BID</button>
      
      </>
  }
  

  