import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1, arg_2: page, arg_3: parent_id } = getParams();

	let entity ="nbfc";
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity, parent_id, solution }),
		// fields: `
        //     id uid active created_at updated_at  name email phone photo_url is_phone_verified is_email_verified role
        //     active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
        //     active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
        //     total_offers: offers_aggregate{aggregate{count}}
        //     total_orders: orders_aggregate{aggregate{count}}
        // `,

	// 	fields: `
	// 	id active
	// 	active_offers: offers_aggregate(where:{active:{_eq:true}}){aggregate{count}}
	// 	active_orders: orders_aggregate(where:{active:{_eq:true}}){aggregate{count}}
	// 	total_offers: offers_aggregate{aggregate{count}}
	// 	total_orders: orders_aggregate{aggregate{count}}
	// `,

	fields:` 
	query MyQuery {
		nbfc {
		  id
		  status
		  company_name
		  aadhaar_card_of_director
		  pan_card_of_company
		  company_pan_no
		  customer_id
		  customer {
			id
			name
			active_documents: customer_documents_aggregate(where: {active: {_eq: true}}) {
			  aggregate {
				count
			  }
			}
			active_documents: customer_documents_aggregate {
			  aggregate {
				count
			  }
			}

			active_orders: customer_documents_aggregate(where: {active: {_eq: true}}) {
				aggregate {
				  count
				}
			  }
			  total_orders: customer_documents_aggregate {
				aggregate {
				  count
				}
			  }


			active_offers:offers_aggregate {
			   aggregate {
				count
			  }
			}
			
			total_offers:offers_aggregate {
			   aggregate {
				count
			  }
			} 
		  }
		  active
		  front_profile_photograph_of_director
		  adhaar_no
		  gst_no
		  pan_no
		  firm_type
		  _12_month_business_banking_currrent_or_cc_or_od
		  _12_months_gst_returns
		  _2_month_gst_return
		  _2_years_bs_and_pl
		  _2_years_bs_and_pnl
		  _2_years_business_banking_current_or_cc__od
		  _2_years_itr
		  _2_years_itr_pdf
		  _2_years_tax_audit_report
		  address_proof
		  adhaar_card_img
		  all_partners_kyc_pan_adhaar_address_proof
		  certificate_of_incorporation
		  company_pan
		  coi
		  front_profile_photo_of_properieter
		  director_residence_rpoor
		  front_profile_photograph_of_partners
		  gst_2a_june_2020_to_june_2021
		  gst_3_b_jan_2021_to_jun3_2021
		  gst_certificate_img
		  gst_certificate
		  id
		  moa_and_aoa
		  office_and_res_latest_ele_bill_gas_bill
		  office_and_residence_proor_partners_and_firm
		  office_residence_proof
		  pan_card_of_director
		  pan_card_photo
		  partnership_deed
		  rent_aggrement_if_rented
		  rent_aggrement_if_premise_are_rented
		}
	  }
	  
	`
	});

	// console.log("object is", object)

	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			entity={"nbfc"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				{
					label: getLabel("order", true),
					value: object?.customer.active_orders?.aggregate?.count || 0,
					total: object?.customer.total_orders?.aggregate?.count || 0,
				},
				{
					label: getLabel("offer", true),
					value: object?.customer.active_offers?.aggregate?.count || 0,
					total: object?.customer.total_offers?.aggregate?.count || 0,
				},
				// {
				// 	label: getLabel("bank", true),
				// 	value: object?.customer.active_banks?.aggregate?.count || 0,
				// 	total: object?.customer.total_banks?.aggregate?.count || 0,
				// },
				// {
				// 	label: getLabel("company", true),
				// 	value: object?.customer.active_companys?.aggregate?.count || 0,
				// 	total: object?.customer.total_companys?.aggregate?.count || 0,
				// },
				{
					label: getLabel("document", true),
					value: object?.customer.active_documents?.aggregate?.count || 0,
					total: object?.customer.total_documents?.aggregate?.count || 0,
				},
			]}
			tab_options={[

				{
					...useEntityFields({ entity: "nbfc_partners", parent_id, solution }),
					label: getLabel("nbfc_partners", true),
					value: "nbfc_partners",

					component: DataTable,
					filters: { nbfc_id: { _eq: parent_id } },
					overrideValues: { nbfc_id: parent_id },
					skipColumns: ["nbfc"],
				},

				{
					...useEntityFields({ entity: "nbfc_credit", parent_id, solution }),
					label: getLabel("nbfc_credit", true),
					value: "nbfc_credit",

					component: DataTable,
					filters: { nbfc_id: { _eq: parent_id } },
					overrideValues: { nbfc_id: parent_id },
					skipColumns: ["nbfc"],
				},



				// {
				// 	...useEntityFields({ entity: "order", parent_id, solution }),
				// 	label: getLabel("order", true),
				// 	value: "order",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },
				// {
				// 	...useEntityFields({ entity: "offer", parent_id, solution }),
				// 	label: getLabel("offer", true),
				// 	value: "offer",

				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },
				// {
				// 	...useEntityFields({ entity: "location", parent_id, solution }),
				// 	label: getLabel("location", true),
				// 	value: "location",
				// 	component: DataTable,
				// 	filters: { customer_id: { _eq: parent_id } },
				// 	overrideValues: { customer_id: parent_id },
				// 	skipColumns: ["user"],
				// },

				// {
				// 	...useEntityFields({ entity: "exporter_data ", parent_id, solution }),
				// 	label: getLabel("exporter", true),
				// 	value: "exporter_data",
				// 	component: DataTable,
				// 	filters: { user_id: { _eq: parent_id } },
				// 	overrideValues: { user_id: parent_id },
				// 	skipColumns: ["customer"],
				// },

			]}
		/>
	);
};

export default Detail;
