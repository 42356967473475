import _ from "lodash";
import React, { useContext, useState } from "react";
import { ChartCards } from "../../../components/common/ChartsandGraphs";
import { FilterForm } from "../../../components/common/FilterForm";
import { MetricCards } from "../../../components/common/MetricCards";
import { GeneralContext } from "../../../context";
import { getDateWhere, getLabel, useCommonSubscription, useTotalSubscription } from "../../../helpers";

const Detail = () => {
    const { enum_user_role } = useContext(GeneralContext);
    const [days_to_show] = useState(30);
    const [inner_filters, set_inner_filters] = useState({});

    const metric_commodity_type = useCommonSubscription({ entity: "metric_commodity_type" });
    const metric_commodity_variety_type = useCommonSubscription({ entity: "metric_commodity_variety_type" });
    const metric_commodity_variety = useCommonSubscription({ entity: "metric_commodity_variety" });
    const metric_user_role_created = useCommonSubscription({
        entity: "metric_user_role_created",
        where: { ...getDateWhere(days_to_show), ...inner_filters },
    });
    const metric_user_created = useCommonSubscription({
        entity: "metric_user_created",
        where: { ...getDateWhere(days_to_show), ...inner_filters },
    });
    return (
        <>
            <FilterForm fields={[]} onFilter={set_inner_filters} />
            <MetricCards
                options={[
                    {
                        // icon: <FcPackage />,
                        label: getLabel("commodity", true),
                        link: "/masterdata/commodity/list/active/all",
                        value: useTotalSubscription({
                            entity: "commodity",
                            where: { active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "commodity", where: {} }),
                    },
                    {
                        // icon: <FcBriefcase />,
                        label: getLabel("commodity_variety", true),
                        link: "/masterdata/commodity_variety/list/active/all",
                        value: useTotalSubscription({
                            entity: "commodity_variety",
                            where: { active: { _eq: true } },
                        }),
                        total: useTotalSubscription({ entity: "commodity_variety", where: {} }),
                    },
                ]}
            />
            <ChartCards
                options={[
                    {
                        header: "Commodities By Type",
                        header_link: "/masterdata/commodity/list/active/all",
                        type: "donut",
                        series: metric_commodity_type?.map((e) => e?.count ?? 0) ?? [],
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: metric_commodity_type?.map((e) => e?.type?.toUpperCase()) ?? [],
                        },
                    },
                    {
                        header: "Varieties By Type",
                        header_link: "/masterdata/commodity_variety/list/active/all",
                        type: "donut",
                        series: metric_commodity_variety_type?.map((e) => e?.count ?? 0) ?? [],
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: metric_commodity_variety_type?.map((e) => e?.type?.toUpperCase()) ?? [],
                        },
                    },
                    {
                        header: "Varieties By Commodity",
                        header_link: "/masterdata/commodity_variety/list",
                        type: "donut",
                        series: metric_commodity_variety.map((e) => e?.count ?? 0) ?? [],
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: metric_commodity_variety.map((e) => e?.type?.toUpperCase()) ?? [],
                        },
                    },
                    {
                        header: "Varieties By Catalogue",
                        header_link: "/masterdata/commodity_variety/list/active/all",
                        type: "donut",
                        series: metric_commodity_variety.map((e) => e?.count ?? 0) ?? [],
                        options: {
                            chart: {
                                type: "donut",
                            },
                            labels: metric_commodity_variety.map((e) => e?.type?.toUpperCase()) ?? [],
                        },
                    },
                    {
                        header: "Role of Users by Date",
                        header_link: "/management/user/list/active/all",
                        type: "bar",
                        options: {
                            chart: {
                                type: "bar",
                                stacked: true,
                                // stackType: "100%",
                            },
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        legend: {
                                            position: "bottom",
                                            offsetX: -10,
                                            offsetY: 0,
                                        },
                                    },
                                },
                            ],
                            xaxis: {
                                categories: metric_user_created?.map((e) => e?.date) ?? [],
                            },
                            fill: {
                                opacity: 1,
                            },
                        },
                        series: [
                            ...((!_.isArray(enum_user_role)
                                ? []
                                : enum_user_role?.map((e) => ({
                                      name: e.comment,
                                      data:
                                          metric_user_role_created.filter((x) => x.type === e.value || !x.type).map((x) => x?.count ?? 0) ?? [],
                                  }))) ?? []),
                        ],

                        colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
                    },
                ]}
            />
        </>
    );
};

export default Detail;
