// Queries can have multiple first-level fields to retrieve
import { gql } from "@apollo/client";
export const S_ENTITY_COUNT = (entity) =>
    gql`subscription S_ENTITY_COUNT_${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { count } } }`;
export const S_ENTITY_AGGREGATE = (entity, fields) =>
    gql`subscription S_ENTITY_AGGREGATE_${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { ${fields} } } }`;
export const S_ENTITY_DATA = (
    entity,
    fields
) => gql`subscription S_ENTITY_DATA_${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!], $distinct_on: [${entity}_select_column!]) {
		objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;
export const S_ENTITY_METRIC = (
    entity,
    fields
) => gql`subscription S_ENTITY_METRIC_${entity}($where: metric_bool_exp, $limit: Int, $offset: Int, $order_by: [metric_order_by!], $distinct_on: [metric_select_column!]) {
		objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;

export const S_ENTITY_METRIC_ARGS = (
    entity,
    fields
) => gql`subscription S_ENTITY_METRIC_${entity}($args: ${entity}_args!, $where: metric_bool_exp, $limit: Int, $offset: Int, $order_by: [metric_order_by!], $distinct_on: [metric_select_column!]) {
                objects: ${entity}(args: $args, where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;

export const S_ENTITY_BY_PK = (entity, fields) =>
    gql`subscription S_ENTITY_BY_PK_${entity}_by_pk($id: uuid!) {object: ${entity}_by_pk(id:$id) { id ${fields} } }`;

export const S_ENTITY_AGG = (entity, aggregate, field) =>
    gql`subscription ${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { ${aggregate} { ${field} } } } }`;

export const S_ID_NAMES = (
    entity,
    fields
) => gql`subscription S_ID_NAMES_${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!]) {
	objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by) { id name ${fields} } }`;

export const S_ENUMS = (entity) => gql`subscription S_ENUMS_${entity}{objects: ${entity}{id: value value comment}}`;
export const S_ID_NAME = (entity, fields) =>
    gql`subscription S_ID_NAME_${entity}_by_pk($id:uuid!) {object: ${entity}_by_pk(id: $id){ id name ${fields}}}`;

export const S_UNIQUE = (entity, field) =>
    gql`subscription S_UNIQUE_${entity}_aggregate($text:String!) {counts: ${entity}_aggregate(where:{${field}:{_ilike:$text}}) { aggregate {count} } }`;
export const S_UNIQUE_ID = (entity, field, dependency) =>
    gql`subscription S_UNIQUE_${entity}_aggregate($text:String!,$id:uuid!) {counts: ${entity}_aggregate(where:{${field}:{_ilike:$text},${dependency}:{_eq:$id}}) { aggregate {count} } }`;

export const S_TIME_SERIES = (entity = "timeseries", fields = " date count ") =>
    gql`subscription S_TIME_SERIES_${entity}($where: timeseries_bool_exp, $order_by: [timeseries_order_by!], ) {objects: ${entity}(where: $where, order_by: $order_by) { ${fields} } }`;
