import _ from "lodash";
import moment from "moment";
import React from "react";
import Skeleton from "react-loading-skeleton";
import NumberFormat from "react-number-format";
 
import {
    BOOLEAN_LIST,
    ENUM_LIST,
    getCalculatedRows,
    getJobCardFields,
    getLabel,
    getLoadingFields,
    getOfferFields,
    getOrderFields,
    getOrderPriceFields,
    getParams,
    getPaymentFields,
    getUnLoadingFields,
    getWeighingFields,
    InvoiceButton,
    UI_DATETIME_FORMAT,
    UI_DATE_FORMAT,
    useObjectByPkSubscription,
} from "../../helpers";
import { DataForm } from "./DataForm";
import { ImageModal } from "./ImageModal";
import { NoteBox } from "./NoteBox";

export const DetailInfo = ({ entity = "user", parent_id, columns = [], fields, title_field = "name", disableEdit = false }) => {
    const { arg_1 } = getParams();

    const { object, refetch, loading} = useObjectByPkSubscription({ id: parent_id, entity, fields: fields ?? ` id ` });

     console.log("detail info is",entity, fields, object);

    let price_change_map = {};
    let arr1Values = ["requested", "negotiated"];
    object?.change_history?.map((e) => {
        if (
            !price_change_map[e?.new_value?.price_change_counter + e?.new_value?.status] &&
            arr1Values?.toString()?.includes(e?.new_value?.status)
        )
            price_change_map[e?.new_value?.price_change_counter + e?.new_value?.status] = {
                status: e?.new_value?.status,
                price: e?.new_value?.[e?.new_value?.status + "_price"],
                created_at: e?.created_at,
            };
        return null;
    });

    if (loading)
        return (
            <>
                <Skeleton className="mx-1 mb-2" width="100%" height={50} />
                <div className="d-flex" style={{ overflow: "hidden" }}>
                    <Skeleton className="m-1" width="40vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="40vw" height={30} />
                </div>
                <div className="d-flex" style={{ overflow: "hidden" }}>
                    <Skeleton className="m-1" width="40vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="40vw" height={30} />
                </div>
                <div className="d-flex" style={{ overflow: "hidden" }}>
                    <Skeleton className="m-1" width="40vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="40vw" height={30} />
                </div>
                <div className="d-flex" style={{ overflow: "hidden" }}>
                    <Skeleton className="m-1" width="40vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="40vw" height={30} />
                </div>
                <div className="d-flex" style={{ overflow: "hidden" }}>
                    <Skeleton className="m-1" width="40vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="20vw" height={30} />
                    <Skeleton className="m-1" width="40vw" height={30} />
                </div>
            </>
        );
    return (
        <>
            <div key={Math.random()} className="card">
                <div className="card-header p-2">
                    <div className="row">
                        <div className="col">
                            <div className="small text-muted text-uppercase">{getLabel(arg_1)}</div>
                            <div className="float-left h5">{_.get(object, title_field) || (object?.user && object?.user?.name)}</div>
                        </div>
                        {!disableEdit && !loading && object?.active && (
                            <div className="col-auto">
                                <DataForm
                                    key={entity + "edit"}
                                    entity={entity}
                                    columns={columns}
                                    defaultValues={object || {}}
                                    refetch={refetch}
                                />
                            </div>
                        )}

                        {/* <InvoiceButton object={object}></InvoiceButton> */}

                        {object?.status === "confirmed" && ["order"].includes(entity) && (
                            <div className="col-auto">
                                <InvoiceButton title="PO" type="po" object={object}></InvoiceButton>

                                <InvoiceButton object={object}></InvoiceButton>
                            </div>
                        )}
                    </div>
                </div>

                <div className="card-body p-2 border">
                    {["commodity"].map((e, i) =>
                        object?.[e]?.name ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">
                                    {object?.[e]?.name} ({object?.[e]?.enum_commodity_type?.comment})
                                </span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                    {["commodity_variety"].map((e, i) =>
                        object?.[e]?.name ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">
                                    {object?.[e]?.name} ({object?.[e]?.commodity?.name})
                                </span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {["catalogue_commission"].map((e, i) =>
                        object?.[e]?.days ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">
                                    {object?.[e]?.days}days ({object?.[e]?.commission}%)
                                </span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    

                    {["email", "phone", "whatsapp", "year_of_establishment"].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {ENUM_LIST.map((e, i) =>
                        object?.[e]?.comment ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">
                                    {getLabel(
                                        e.replace("enum_", "")
                                        // ?.replace(entity + "_", "")
                                        // ?.replace("type", "")
                                    )}
                                </span>
                                <span className="float-end h6">{object?.[e]?.comment}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {BOOLEAN_LIST.map((e, i) =>
                        _.isBoolean(object?.[e]) ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e.replace("enum_", "").replace(entity + "_", ""))}</span>
                                <span className="h6">
                                    {object?.[e] ? (
                                        <i className="fa fa-check text-success m-1 float-end" />
                                    ) : (
                                        <i className="fa fa-times text-danger m-1 float-end" />
                                    )}
                                </span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {["shape", "size", "color", "grading_type", "credit_days",].map((e, i) =>
                        object?.[e]?.name ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]?.name}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                    {["email", "phone", "whatsapp", "year_of_establishment","firm_type","pan_no","gst_no","company_name","adhaar_no"].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {["commission_percent",].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]} %</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                     {["amount","utr_no", "commodity_spec","quantity_in_mt","price_per_pack","total_amount","total_freight_amount"
                     ,"uid","type_of_packaging","wt_per_pack","no_of_box","quantity_in_mt","status",
                    "supplier_inv_price","supplier_inv_weight","supplier_inv_total_price","hs_code"].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {["payment_days"].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]} Days</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {[
                        "delivery_date",
                        "date",
                        "loading_date",
                        "unloading_date",
                        "start_date",
                        "end_date",
                        "available_date",
                        "payment_due_date",
                        "etd",
                        "eta",
                        "payment_date",
                        "inv_created_at",
                        "sailing_date",
                        
                       
                    ].map((e) =>
                        object?.[e] ? (
                            <div key={Math.random()} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{moment(object?.[e]).format(UI_DATE_FORMAT)}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {[{ name: "Requested Delivery Date", value: "requested_date" }].map((e) =>
                        object?.[e["value"]] ? (
                            <div key={Math.random()} className="p-1">
                                <span className="text-muted">{e["name"]}</span>
                                <span className="float-end h6">{moment(object?.[e]).format(UI_DATE_FORMAT)}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {["invoice_no", "payment_gateway", "payment_type", "pg_order_id", "pg_payment_id","ocean_freight",
                    "terminal_handling_charges",
                    "bill_of_lading_charges",
                    "toll_charges",
                    "cmc_charges",
                    "seal_charges",
                    "temp_variation_charges",
                    "muf",
                    "vessel_risk",
                    "total_cost"
                ].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                    {["address"].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <div className="h6">{object?.[e]}</div>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                    {["website_url", "desc", "summary"].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e)}</span>
                                <span className="float-end h6">{object?.[e]}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}

                    {["url", "photo_url", "document_url","do_slip","bl_slip","invoice","transportation_slip","images"].map((e, i) =>
                        _.isString(object?.[e]) && object?.[e] ? (
                            <div key={i} className="p-1">
                                {/* {object?.[e]} */}
                                <div className="text-muted bg-light">{getLabel(e)}</div>
                                <span className=" h6">
                                    <ImageModal key={object?.id} imageHeight={80} url={object?.[e]} title={_.get(object, title_field)} />
                                </span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                </div>

                {["origin", "destination", "location", "pickup_location", "delivery_location"].map((e, i) =>
                    object?.[e]?.address ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">
                                {getLabel(e)} [ {object?.[e]?.enum_location_type?.comment} ]
                            </div>
                            <div className="float-left h6 p-1">
                                {object?.[e]?.name}, {object?.[e]?.address}
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}
                {["vehicle"].map((e, i) =>
                    object?.[e] ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e)}</div>
                            <div key={Math.random()} className="p-1">
                                <span className="text-muted">{getLabel("name")}</span>
                                <span className="float-end h6">{object?.[e]?.name}</span>
                            </div>
                            <div key={Math.random()} className="p-1">
                                <span className="text-muted">{getLabel("capacity")}</span>
                                <span className="float-end h6">
                                    {object?.[e]?.capacity} {object?.[e]?.enum_weight_unit?.comment}
                                </span>
                            </div>
                            <div key={Math.random()} className="p-1">
                                <span className="text-muted">{getLabel("owner")}</span>
                                <span className="float-end h6">{object?.[e]?.owner?.name}</span>
                            </div>

                            <div key={Math.random()} className="p-1">
                                <span className="text-muted">{getLabel("vehicle_type")}</span>
                                <span className="float-end h6">{object?.[e]?.enum_vehicle_type?.comment}</span>
                            </div>

                            {object?.[e]?.["vehicle_contacts"]?.length > 0
                                ? object?.[e]?.["vehicle_contacts"]?.map((e, i) => (
                                      <>
                                          <div key={Math.random()} className="p-1">
                                              <span className="text-muted">{getLabel(e["contact_type"])}</span>
                                              <span className="float-end h6">{e["contact"]["name"]}</span>
                                          </div>
                                      </>
                                  ))
                                : ""}
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}
                {["owner", "driver", "customer", "user", "supplier", "seller", "buyer", "contact","exporter","export_order","location","origin_port","destination_port"].map((e, i) =>
                    object?.[e] ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">
                                {object?.[e]?.enum_customer_type?.comment ?? getLabel(e)}
                            </div>
                            {["name", "email", "phone", "whatsapp",].map((x, index) =>
                                object?.[e]?.[x] ? (
                                    <div key={index} className="p-1">
                                        <span className="text-muted">{getLabel(x)}</span>
                                        <span className="float-end h6">{object?.[e]?.[x]}</span>
                                    </div>
                                ) : (
                                    ""
                                )
                            )}
                            {["commission_percent"].map((x, index) =>
                                object?.[e]?.[x] ? (
                                    <div key={index} className="p-1">
                                        <span className="text-muted">{getLabel(x)}</span>
                                        <span className="float-end h6">{object?.[e]?.[x]} %</span>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                            {["payment_days"].map((x, index) =>
                                object?.[e]?.[x] ? (
                                    <div key={index} className="p-1">
                                        <span className="text-muted">{getLabel(x)}</span>
                                        <span className="float-end h6">{object?.[e]?.[x]} Days</span>
                                    </div>
                                ) : (
                                    <span key={Math.random()} />
                                )
                            )}
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}

                <div className="border">
                    {["offer"].includes(entity) ? getCalculatedRows(object, getOfferFields()) : ""}
                    {["order"].includes(entity)&&  object?.["order_type"] !== 'on_commission' ? getCalculatedRows(object, getOrderPriceFields(), "Negotitation") : ""}
                    {["order"].includes(entity) ? getCalculatedRows(object, getOrderFields()) : ""}
                    {["order_payment", "jobcard_payment"].includes(entity) ? getCalculatedRows(object, getPaymentFields()) : ""}
                    {["jobcard"].includes(entity) ? getCalculatedRows(object, getJobCardFields()) : ""}
                    {["jobcard_loading"].includes(entity) ? getCalculatedRows(object, getLoadingFields()) : ""}
                    {["jobcard_unloading"].includes(entity) ? getCalculatedRows(object, getUnLoadingFields()) : ""}
                    {["jobcard_weighing"].includes(entity) ? getCalculatedRows(object, getWeighingFields()) : ""}
                </div>

                {["order"].includes(entity) && object?.["order_type"] !== "on_commission" ? (
                    <div key={Math.random()} className="card-body p-2 border">
                        <div className="font-weight-bold text-uppercase p-1 bg-light h6">Price Change History</div>
                        {Object?.values(price_change_map)?.map((e, i) => (
                            <div key={i} className="p-1">
                                <span className="text-muted">
                                    {moment(e?.created_at).format(UI_DATETIME_FORMAT)} - {getLabel(e.status)}
                                </span>
                                <span className="float-end h6">
                                    <NumberFormat
                                        value={e?.price?.toFixed(2)}
                                        thousandsGroupStyle="lakh"
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"₹"}
                                    />
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <span key={Math.random()} />
                )}
                {["images", "videos", "documents","items_draft"].map((e, i) =>
                    !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                            <div className="h6">
                                <ImageModal key={object?.id} imageHeight={80} url={object?.[e]} title={_.get(object, title_field)} />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}
                
               



              {["po", ].map((e, i) =>{
                   
                //    console.log("po loading unloading",object,object?.[e])
                   
                   return !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ?

                      
                       <div key={i} className="card-body p-2 ">
                            <div className="font-weight-bold  text-uppercase p-1 bg-light h6">{"PO"}</div>
                            <div className="h6">
                           { object?.[e]?.map(e =>   { 
                           
                           
                           return <ImageModal key={Math.random()} privateImage={true} type="document" imageHeight={80} url={e["key"]} docTitle={e["created_at"]} po_type={ `${ e["type"]}`} />}
                            
                            )}
                            </div>
                        </div>
                     : (
                        <span key={Math.random()} />
                    )}
                )}

{["loading_po","unloading_po"].map((e, i) =>{
                   
                //    console.log("po loading unloading",object,object?.[e])
                   
                   return !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ?

                      
                       <div key={i} className="card-body p-2 ">
                            <div className="font-weight-bold  text-uppercase p-1 bg-light h6">{"PO"}</div>
                            <div className="h6">
                           { object?.[e][0]["po"]?.map(e =>   { 
                           
                        //    console.log("e values are",e["po"], object)
                           
                           return <ImageModal key={Math.random()} privateImage={true} type="document" imageHeight={80} url={e["key"]} docTitle={e["created_at"]} po_type={ `${ e["type"]}`} />}
                            
                            )}
                            </div>
                        </div>
                     : (
                        <span key={Math.random()} />
                    )}
                )}



 
                
             

{["adhaar_card_img", "pan_card_photo", ].map((e, i) =>
                    !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                            <div className="h6">
                                <ImageModal key={object?.id} privateImage= {true}  type={e?.substring("img") ? "image" : "photo"} imageHeight={80} url={object?.[e]} title={_.get(object, title_field)} />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}
    
    
 
                
                {["documents","rent_aggrement_if_premise_are_rented"].map((e, i) =>
                    !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                            <div className="h6">
                                <ImageModal key={object?.id} privateImage= {true} type="document" imageHeight={80} url={object?.[e]} title={_.get(object, title_field)} />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}


                {["customer_documents" ].map((e, i) =>
                    !_.isEmpty(object?.[e]) && _.isArray(object?.[e]) ? (
                        <div key={i} className="card-body p-2 border">
                            <div className="font-weight-bold text-uppercase p-1 bg-light h6">{getLabel(e, true)}</div>
                            <div className="h6">
                                <ImageModal
                                    key={object?.id}
                                    imageHeight={80}
                                    url={object?.[e][0]["images"]}
                                    title={_.get(object, title_field)}
                                />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    )
                )}

                {/* {[{ keys: "customer", values: "customer_documents" }].map((e, i) => {
                    console.log("details are", object, e);

                    return !_.isNull(object) &&
                        // _.isArray(object[e?.keys]) &&
                        object[e?.keys] &&
                        _.isArray(object[e?.keys][e?.values]) &&
                        object[e?.keys][e?.values]?.length > 0 &&
                        !_.isEmpty(object[e?.keys]) ? (
                        <div key={i} className="card-body p-2 border">
                            {/* {console.log("details aree", object?.[e?.keys])} */}
                {/* <div className="font-weight-bold text-uppercase p-1 bg-light h6">{"Document images"}</div>
                            <div className="h6">
                                <ImageModal
                                    key={object?.id}
                                    imageHeight={80}
                                    url={object[e?.keys][e?.values][0]["images"]}
                                    title={_.get(object, title_field)}
                                />
                            </div>
                        </div>
                    ) : (
                        <span key={Math.random()} />
                    );
                })} */}

                <div className="card-footer p-2">
                    {["created_at", "updated_at", "timestamp", "refunded_at",].map((e, i) =>
                        object?.[e] ? (
                            <div key={i} className="p-1">
                                <span className="text-muted">{getLabel(e.replace("enum_", ""))}</span>
                                <span className="float-end h6">{moment(object?.[e]).format(UI_DATETIME_FORMAT)}</span>
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )
                    )}
                </div>
            </div>
            <NoteBox key="user_note" unique_id={parent_id} />
        </>
    );
};
