import { useMutation } from "@apollo/client";
import moment from "moment";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context";
import { INSERT_ONE } from "../../gql";
import { UI_DATETIME_FORMAT, useObjectsSubscription } from "../../helpers";

export const NoteBox = ({ unique_id }) => {
    const { user } = useContext(AuthContext);

    const [insertOne] = useMutation(INSERT_ONE("user_note"));
    const { register, handleSubmit, reset } = useForm();

    const { objects, loading } = useObjectsSubscription({
        entity: "user_note",
        fields: `id user_id user { id name photo_url } note`,
        where: { unique_id: { _eq: unique_id } },
        order_by: { created_at: "desc" },
    });

    if (loading) return <span />;

    return (
        <div className="card mb-2">
            <div className="card-header p-2">
                <span className="h5">Add a Note</span>
            </div>
            <div className="card-body p-2">
                <form
                    onSubmit={handleSubmit((data) =>
                        data.note
                            ? insertOne({
                                  variables: { object: { user_id: user?.id, note: data.note, unique_id: unique_id ?? user?.id } },
                              })
                                  .then(() => reset())
                                  .catch((err) => console.error(err))
                            : {}
                    )}
                >
                    <textarea className="form-control mb-2" name="note" ref={register({})} />
                    <button type="button" className={"btn btn-sm btn-secondary m-1"} onClick={() => reset()}>
                        CLEAR
                    </button>
                    <button className={"btn btn-sm btn-secondary m-1 float-end"} type="submit">
                        SUBMIT
                    </button>
                </form>
            </div>
            <div className="card-footer p-2">
                {objects?.map((o, i) => (
                    <div key={i} className="card mb-1">
                        <div className="card-body p-2">
                            <div className="user text-muted small">
                                <span>{o?.user?.name}</span>
                                <span className="float-end">{moment(o?.created_at).format(UI_DATETIME_FORMAT)}</span>
                            </div>
                            <span className="h6">{o?.note}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
