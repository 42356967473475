import { React, useContext } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../context";
import { SendRequest } from "./sampleRequest";
 

export const ExcelUpload = ({ title=" ", docType="excel", responseType =  "blob",suburl="", download= true}) => {
    const form_data = new FormData();
    const { id_token } = useContext(AuthContext);

    return (
        <>
            <label for="ecel" className={"btn btn-sm btn-primary m-1"}>
                EXCEL UPLOAD
            </label>

            <input
                id="ecel"
                type="file"
                accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                style={{ display: "none" }}
                onChange={(e) => {
                    // console.log("file is", e?.target?.files?.[0], /\.(xlsx|xls)$/i.test(e?.target?.files?.[0]?.name));
                    if (!/\.(xlsx|xls)$/i.test(e?.target?.files?.[0]?.name)) {
                        toast.error("Accepts only xlsx,xls file");
                        return;
                    }

                    form_data.append("file", e?.target?.files?.[0]);
                    // console.log("form data is ", form_data);
                    toast.success(`Please wait`);

                  
                    SendRequest({showTitle:title, responseType :  {responseType}  , docType:docType, "method":"POST","subUrl":"/"+suburl, "body":form_data,"id_token":id_token , message:"Excel Sucessfully uploaded!"  }) ;
  

                    // axios
                    //     .post(`${APP_CONFIG.REACT_APP_SERVICES_URL}/${suburl}`, form_data, {
                    //         headers: { Authorization: `Bearer ${id_token}`, "x-client-app": "web-admin" },
                    //         responseType:  docType==="pdf" ? "blob" : null
                    //     })
                    //     .then((result) => {
                    //         //  console.log("result is ",result,result.config, result?.status)

                    //          if(docType==="pdf" && result.status === 201 && result?.data && download){

                    //                 const link = document.createElement("a");
                    //                 link.href = window.URL.createObjectURL(new Blob([result?.data]));
                    //                 link.setAttribute("download", title + moment().toISOString() + ".pdf");
                    //                 document.body.appendChild(link);
                    //                 link.click();
                    //                 link.remove();

                    //          }
                    //         if (result.status === 201) {

                    //             // console.log("reuslt is", result.data.status, result.data.length);
                    //             toast.success(`${result.data.length || " "} Records uploaded sucessfully`, { position: "top-right" });
                    //             // toast.success(response.data.message, { position: "top-right" });
                    //         } else {
                    //             toast.error("Upload Failed.");
                    //         }
                    //     });
                    // .catch((error) => {
                    //     console.log("error is", error);
                    //     toast.error("Upload Failed.", error);
                    // });
                }}
            ></input>
        </>
    );
};
