import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { APP_CONFIG } from '../../config';

var FileSaver = require('file-saver'); 


export const SendRequest = function ({responseType="blob",history, showTitle, docType, method, subUrl, body = {}, id_token,message="Sucessfully Created!" }) {

  // console.log('Send request check',responseType, docType, method, subUrl, body, id_token);

  toast.success('Please Wait !', { autoClose: 1500 });

  return axios({
    method,
    url: `${APP_CONFIG.REACT_APP_SERVICES_URL}${subUrl}`,
    data: body,
    headers: { Authorization: `Bearer ${id_token}`, 'x-client-app': 'web-admin', },
    responseType: responseType,
  })
    .then((result) => {
         console.log('result is', docType, result, result?.status);

      if (result?.data) {

        if (docType === 'pdf') {
    
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([result?.data]));
          link.setAttribute( 'download', showTitle + moment().toISOString() + '.pdf', );
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success(message, { autoClose: 1500 });
          return true;
        }

        if (docType === 'excel') {
          var blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', });
          FileSaver.saveAs( blob, `${moment().format( 'MMMM Do YYYY, h:mm:ss a', )}${showTitle} job.xlsx`, );
          toast.success(message, { autoClose: 1500 });
          return true;
        }

        if (docType === 'html') {
          return  history.push({pathname:'/web/view', state:result?.data});
        }


        if (result.status === 201 || result.status === 200) {
           toast.success(`${result.data.length || " "}   success`, { position: "top-right" });
           return true;
          } 
      }

      toast.success('Failed! Please Try Again!', { autoClose: 1500 });
      return false;
    })
    .catch(async (e) => {
          // console.log("Errors is",e)
        let message = 'Something went wrong, please try again !';

        if(e?.response?.data?.message)   message =    e?.response?.data?.message;
        else  if(e?.response?.data?.text()){
         const data = await e?.response?.data?.text()  ;
         message = JSON.parse(data)?.message;
        } 
      //  const data = await e?.response?.data?.text() || e?.response?.data?.message;
      //  console.log("Errors is message",data,JSON.parse(data)?.message)

      toast.error( message , { autoClose: 2000 }, ); 
    
    });
};
