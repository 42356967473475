import React, { useContext, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"
import { getDBTableName, UI_DATETIME_FORMAT, useEntityFields, useObjectByPkQuery, useObjectQuery, useObjectsQuery, useObjectsSubscription, useTotalQuery } from "../../../helpers"
import { AuthContext } from "../../../context"
import { UPDATE_ONE } from "../../../gql"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import _ from "lodash"
import moment from "moment"

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

 const { user_id,  } = useContext(AuthContext);

 const [updateOne] = useMutation(UPDATE_ONE(getDBTableName("web_notification")));

 const { object:customerData } = useObjectByPkQuery({
  id: user_id,
  entity: "user",
  fields: `id active customer{id name}`,
});


const { objects,total, error,refetch, loading } = useObjectsSubscription({
entity: "web_notification" ,
fields: `id link customer_id _header active _message created_at`,
where: {customer_id:{_eq:customerData?.customer?.id }, active:{_eq:true} },

});

 const update_Notifications = (_id) =>{

   updateOne({
      variables: {
          id: _id,
          set: { active:false},
      },
  })
      .then(({ data, error }) => {
          if (!error && !_.isEmpty(data)) {
              refetch();
              toast.success("Updated", { autoClose: 1500 });
          } else if (error) {
              if (error?.graphQLErrors) toast.error(JSON.stringify(error?.graphQLErrors?.map((e) => e.message)));
              console.error(error);
          }
      })
      .catch((error) =>
          JSON.stringify(
              error?.graphQLErrors?.map((e) =>
                  e.message.search("unique constraint") > 0 && e.message.search("user_email_key") > 0
                      ? toast.error("Email Already Exists")
                      : e.message.search("unique constraint") > 0 && e.message.search("user_phone_key") > 0
                      ? toast.error("Phone Number Already Exists")
                      : ""
              )
          )
      );
 

 }



  
 



  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{objects?.length || 0}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-right">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {objects?.length>0  ? props.t("Notifications") : props.t("No Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <a href="#!" className="small">
                  {" "}
                  {/* View All */}
                </a>
              </div>
            </Row>
          </div>
          {/* https://oc-admin-dev.netlify.app */}
          {/* http://localhost:3000/ */}
          {objects?.length>0 ?<SimpleBar style={{ height: "230px" }}>
            {objects?.length>0 ? objects?.map((e,i)=>   <div classname="text-reset notification-item">    
              <div className="media ">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-cart" />
                    </span>
                  </div>
                  <Link onClick={()=> window.open(`https://oc-admin-dev.netlify.app${e?.link}`, "_blank")} className="text-reset notification-item">
                 <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t(e?._header)}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(e?._message)}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      { moment(e?.created_at).format(UI_DATETIME_FORMAT)}{" "}
                    </p>
                  </div>
                
                </div>
              </Link>
                <div>
                <button type="button" class="btn btn-success btn-sm" onClick={()=>update_Notifications(e?.id)}>Mark as read</button>

                  </div>

              </div>
            </div> ): <p className="h6 text-primary text-center">no Notifications</p>
}
            {/* <Link to="" className="text-reset notification-item">
              <div className="media"><Link onClick={()=> window.open(`http://localhost:3000/${e?.link}`, "_blank")} className="text-reset notification-item">
                <img
                  src={avatar3}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">James Lemire</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("It will seem like simplified English") + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="" className="text-reset notification-item">
              <div className="media">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="bx bx-badge-check" />
                  </span>
                </div>
                <div className="media-body">
                  <h6 className="mt-0 mb-1">
                    {props.t("Your item is shipped")}
                  </h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t("If several languages coalesce the grammar")}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />{" "}
                      {props.t("3 min ago")}
                    </p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="media">
                <img
                  src={avatar4}
                  className="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div className="media-body">
                  <h6 className="mt-0 mb-1">Salena Layfield</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      {props.t(
                        "As a skeptical Cambridge friend of mine occidental"
                      ) + "."}
                    </p>
                    <p className="mb-0">
                      <i className="mdi mdi-clock-outline" />
                      {props.t("1 hours ago")}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
          </SimpleBar> : null}
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="#"
            >
              {/* <i className="mdi mdi-arrow-right-circle me-1"></i> */}
              {" "}
              {/* {props.t("View all")}{" "} */}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}