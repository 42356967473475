import React from 'react';
import { useForm } from 'react-hook-form';
// import OkImage from '../../../assets/images/brands/OkLogo.jpeg';
import OkImage from '../../../assets/images/oclogo/cecs.png';
import ContainerSvg from '../../../assets/images/brands/container.svg';
import { SendWebRequest } from '../common/sendRequest';
import {useHistory} from 'react-router-dom';

const Signup = ({ verifyPage = false ,setphone=null}) => {

  const { register, handleSubmit, errors, formState } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange', });

  const { isDirty } = formState;


  const history = useHistory();

  const inputFields =  [
    { label: 'name', placeholder: 'Rajesh',type:"text" },
    { label: 'email', placeholder: 'test@gmail.com',type:"text"   },
    { label: 'phone', placeholder: '9700000000',  type:"text" },
   
  ];

  const radioButtons =  [
    { label: 'exporter', type:"radio",checked:true },
    { label: 'supplier', type:"radio", checked:false },
    { label: 'freight_forwarder', overrideLabel:"Freight Forwarder", type:"radio", checked:false },
    { label: 'importer', overrideLabel:"Importer", type:"radio", checked:false },
 ]

  const onSubmit = async (e) => {

    const phoneNumber = {"phone": `+91${e["phone"]}`};

    const respo = await SendWebRequest({ method: 'POST', subUrl: '/user/sendOtp', body: {...e,...phoneNumber}, Smes: 'Otp Sent to your Mobile number', Emes: (err) => `${err?.response?.data?.error?.message}` });

        if (respo) {
          // console.log("updating phone number", e)
          setphone(e["phone"]);
          verifyPage(false);
        }

  };

  const signupPage = (
    <div className="vh-100  vw-100   row align-items-center justify-content-even bg-white overflow-auto">
      <div className="col-12 col-sm-5  col-md-5 col-lg-6   ">
        <img src={ContainerSvg} className="img-fluid" alt="Responsive"   />
      </div>
{/* 
      <div className="text-center mt-3 p-2  ">
                                        <img src={ContainerSvg} alt="" height="110" />
                                    </div> */}

      <div className="col-12 col-sm-6  col-md-6 col-lg-6   ">
        <div class="container mt-2" style={{ margin: '0 auto' }}>
          <div className="  col-11 text-center"> <img src={OkImage} alt="Origin Konnect Logo" height="100px"></img> </div>
          <form class="needs-validation" className='justify-content-center vertical-align-items'  onSubmit={handleSubmit(onSubmit)} novalidate >
            <div className="row container-fluid align-items-center justify-content-center bg-white ">
              {
                inputFields?.map((e, i) => {
                return (
                  <div class="col-12 col-sm-12  col-md-6 col-lg-8    mb-3 " key={i}>
                    <h6 className="h6 small form-label text-success"> {e?.label?.charAt(0,1).toUpperCase()+ e?.label.slice(1)} <span className="text-danger ml-2"> *</span> </h6>
                    <input type={e?.type} className={`col-12 col-sm-6  col-md-6 col-lg-6  p-1  border-1  p-2 form-control ${ errors[e?.label]?.message ? 'border-danger' : '' }  `} name={e?.label} placeholder={e?.placeholder} ref={register({ required: `${e?.label}  is required`, ...e?.rules, })} />
                    <div className="text-danger">
                      {errors[e?.label]?.message ? errors[e?.label]?.message : ''} 
                    </div>
                  </div>
                );
              })
              }
            </div>

            <div className="row d-flex justify-content-center flex-wrap vertical-align-items p-3 mt-1" >
            <div className="text-center" >
            {
               radioButtons?.map((e, i) => {
                return (
                  <span className='m-1  col-12 col-sm-6 col-md-3' key={i}>
                  <input type="radio" id={e?.label}  value={e?.label}    name="role" className="m-1"   ref={register({ required: true })}/>
                  <label for={e?.label}>{!e?.overrideLabel  ? e?.label?.charAt(0,1).toUpperCase()+ e?.label.slice(1)?.replaceAll("_"," ") : e?.overrideLabel}</label> 
                  </span>
                );
              })
            }
             {/* <span className='m-1  col-12 col-sm-6 col-md-3' >
                  <input type="radio"  id="importer"   name="role" className="m-1"   />
                  <label for="importer">importer</label> 
                  </span> */}
            </div>
            </div>

            <div className="text-danger text-center">
                      {errors["role"]&& `please select ${radioButtons?.map((e,i)=> e?.label+" or ")?.join().replaceAll(",", " ").slice(0,-3)?.toLocaleLowerCase()}`}  
                    </div>
            <div className="text-center col-sm-11">
              <button type="submit" className={` cursor-pointer btn btn-sm btn-success text-justify m-4 ps-4 pe-4 pt-2 pb-2 m-2 ${ !isDirty ? 'disabled' : '' }`} >
                SignUp
              </button>
            </div>
          </form>
        </div>
        <h6 className="  text-center   text-success ">  Already have an account ? 
          <span className="text-danger cursor" role="button" onClick={() => history.push('/user/auth/login')} > Login </span>
        </h6>
      </div>
    </div>
  );

  return signupPage;
};

export default Signup;
