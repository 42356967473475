import _ from "lodash";
import React, { createContext, useReducer } from "react";

const initialGeneralState = {};

const generalReducer = (state, action) => {
    switch (action.type) {
        case "SET_ENUMS":
            return {
                ...state,
                [action.field]: action.payload,
                [action.field + "_map"]: action.payload_map,
            };
        case "SET_GROUP_BY":
            return {
                ...state,
                [action.field]: action.payload,
                [action.field + "_map"]: _.groupBy(action.payload, action.group_by),
            };
        case "SET_DATA":
            return {
                ...state,
                [action.field]: action.payload,
            };

        default:
            return state;
    }
};

const GeneralContext = createContext(initialGeneralState);
const GeneralProvider = ({ children }) => {
    const [state, dispatch] = useReducer(generalReducer, initialGeneralState);
    const dispatches = {
        setEnums(values, field) {
            let object_map = {};
            values?.map((e) => {
                if (e.value) object_map[e.value] = e.comment;
                else if (e.id) object_map[e.id] = e.name;
                return e;
            });
            dispatch({
                type: "SET_ENUMS",
                payload: values?.map((v) => ({ ...v, value: v.value ?? v.id, comment: v.comment ?? v.name })),
                field: field,
                payload_map: object_map,
            });
        },
        setGroupBy(data, field, group_by) {
            dispatch({
                type: "SET_GROUP_BY",
                payload: data.map((v) => ({
                    ...v,
                    name: v.commodity_type
                        ? `${v.comment} [ ${v.enum_commodity_type.comment} ]`
                        : v.commodity_id
                        ? `${v.comment} [ ${v.commodity.name} ]`
                        : v.comment,
                })),
                field: field,
                group_by: group_by,
            });
        },
        setData(data, field) {
            dispatch({
                type: "SET_DATA",
                payload: data,
                field: field,
            });
        },
    };
    return <GeneralContext.Provider value={{ ...state, ...dispatches }}>{children}</GeneralContext.Provider>;
};

export { GeneralContext, GeneralProvider };
