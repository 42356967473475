import React from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { DetailInfo } from "../../../../../components/common/DetailInfo";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";

const Detail = () => {
	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, _arg_1, arg_2: page, arg_3: parent_id } = getParams();

	// console.log("parent details are",parent_id,solution,page,"check")

	let entity ="customer";
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity:"customer" })

	});
	// const { object:y } = useObjectByPkQuery({
	// 	id: parent_id,
	// 	...useWebEntityFields({ entity: "customer_contact" }),

	// });


	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			entity={"customer"}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
			 
				{
					label: getLabel("bank", true),
					value: object?.active_banks?.aggregate?.count || 0,
					total: object?.total_banks?.aggregate?.count || 0,
				},
				{
					label: getLabel("company", true),
					value: object?.active_companys?.aggregate?.count || 0,
					total: object?.total_companys?.aggregate?.count || 0,
				},
				{
					label: getLabel("document", true),
					value: object?.active_documents?.aggregate?.count || 0,
					total: object?.total_documents?.aggregate?.count || 0,
				},
			]}
			tab_options={[
	 
				{
                    ...useWebEntityFields(  "customer_contact" ),
                    label: getLabel("customer_contact", true),
                    value: "customer_contact",

                    component: DataTable,
                    filters: { customer_id: { _eq: parent_id } },
                    overrideValues: { customer_id: parent_id },
                    skipColumns: ["customer"],
                },	
				 
					 

			]}
		/>
	);
};

export default Detail;
