import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Card, CardBody, Container, NavItem, NavLink } from "reactstrap";
import { getParams, getUrlPath } from "../../helpers";
import BreadCrumb from "./Breadcrumb";
import { MetricCards } from "./MetricCards";

export const PageContainer =React.memo (({
    breadcrumbTitle = false,
    breadcrumbOptions = [],
    breadcrumbAddButtons = [],
    breadcrumbAddOverrides = {},
    breadcrumbSkipColumns = [],
    breadcrumbArg = 3,
    tab_options = [], //[{ label: "Tab", value: "tab", component: null }],
    list_options = [], //[{ label: "Tab", value: "tab", component: null }],
    options = [],
    component: Component = null,
    internal = false,
    tab_arg,
    metric_cards = [],
    detail = false,
    showBreadCrumb=true,
			modalValue=null,
            messageAfter=null,
            showTitle=null,

    ...rest
}) => {
    console.log("cheksare  ======>", detail ,tab_options, modalValue);
    const active_tab = getParams()?.["arg_" + tab_arg] ?? "all";
    const open_tab = tab_options.find((t) => t.value === active_tab);
    const history = useHistory();
    if (!detail && !active_tab && tab_arg && tab_options?.length > 0 && tab_options[0]?.value)
        return <Redirect to={getUrlPath(tab_arg, tab_options[0]?.value)} />;
    // console.log("taboptionsare", tab_options,detail,open_tab,active_tab);
     return (
        <React.Fragment>
            <div className={internal ? "mt-4" : "page-content mt-4"}>
                <Container fluid>
                 {showBreadCrumb &&   <BreadCrumb
                        entity={rest?.entity}
                        title={breadcrumbTitle}
                        options={breadcrumbOptions}
                        arg={breadcrumbArg}
                        addButtons={breadcrumbAddButtons}
                        overrideValues={breadcrumbAddOverrides}
                        skipColumns={breadcrumbSkipColumns}
                    />}

                    <div className="row">
                        {Component || metric_cards.length > 0 ? (
                            <div className={detail ? "m-0 col-xl-3 col-md-12" : "m-0 col-12"}>
                                {Component && options?.length > 0
                                    ? options?.map((option, index) => <Component key={index} {...rest} {...option} />)
                                    : Component && <Component {...rest} {...options} />}
                                {metric_cards && metric_cards.length > 0 && <MetricCards options={metric_cards} expand={detail} />}
                                {list_options && list_options.length > 0
                                    ? list_options?.map((option, index) => (
                                          <option.component
                                              {...rest}
                                              {...option}
                                              active_tab={active_tab}
                                            //   TableName={option?.TableName}
                                              key={index}
                                              modalValue={modalValue}
                                              detail={detail}
                                              entity={option.entity || option.value}
                                          />
                                      ))
                                    : ""}
                            </div>
                        ) : (
                            <span key={Math.random()} />
                        )}

                        <div className={Component ? "m-0 col-xl-9 col-md-12" : "m-0 col-12"}>
                            {detail
                                ? tab_options &&
                                  tab_options.length > 0 &&
                                  tab_options?.map((option, index) => {

                                        //  console.log("checks ",option?.dependsOn , option?.dependsValue ,option) 
                                // eslint-disable-next-line no-mixed-operators
                                return   (option?.dependsOn&&option?.dependsValue&& !(option?.dependsOn[option?.dependsValue]) 
                                // eslint-disable-next-line no-mixed-operators
                                || (option?.notDependsOne && option?.dependsValue &&(option?.notDependsOne === option?.dependsValue))) 
                                              ? null : 
                                         <option.component
                                          {...rest}
                                          {...option}
                                          key={index}
                                          modalValue={modalValue}
                                          detail={detail}
                                          entity={option.entity || option.value}
                                      />
                                  })
                                : tab_options &&
                                  tab_options.length > 0 && (
                                      <>
                                      {/* {console.log("tab optionss", tab_options)} */}
                                          <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                              {tab_options?.map((option, index) => (
                                                  <NavItem key={index}>
                                                      <NavLink
                                                          className={`${active_tab === option.value ? "active" : ""}`}
                                                          onClick={() => history.push(getUrlPath(tab_arg, option?.value))}
                                                      >
                                                          {option.label}
                                                      </NavLink>
                                                  </NavItem>
                                              ))}
                                          </ul>
                                          <Card>
                                              <CardBody className="p-2" 
                                                 style={{ overflow : "scroll",  }}
                                               >
                                                  {/* {console.log("OPEN TAB", open_tab)} */}
                                                  {open_tab ? (
                                                      <open_tab.component
                                                          {...rest}
                                                          {...open_tab}
                                                          key={active_tab}
                                                          active_tab={active_tab}
                                                        //   TableName={open_tab?.TableName}
                                                          detail={detail}
                                                          modalValue={modalValue}
                                                          messageAfter={messageAfter}
                                                          showTitle={showTitle}
                                                          entity={open_tab.entity || open_tab.value}
                                                      />
                                                  ) : (
                                                      <span key={Math.random()} />
                                                  )}
                                              </CardBody>
                                          </Card>
                                      </>
                                  )}
                            {/* {timelineOptions?.length > 0 && (
                                <div className={detail ? "col-12" : "col-xl-2 col-lg-3 col-md-6 col-sm-12"}>
                                    <div className="card">
                                        <div className="card-header p-2 text-uppercase h6">TIMELINE</div>
                                        <div className="card-body p-2">
                                            <Timeline style={{ margin: "1.5rem 0" }}>
                                                {timelineOptions?.map((timeline, index) => (
                                                    <TimelineEvent
                                                        key={index}
                                                        titleStyle={{ fontWeight: "420", fontSize: "1rem" }}
                                                        subtitleStyle={{ fontWeight: "400", fontSize: "1.6rem" }}
                                                        bubbleStyle={{ background: "white", borderRadius: "50%" }}
                                                        createdAt={moment(timeline?.created_at).format(UI_DATETIME_FORMAT)}
                                                        icon={<FcCalendar style={{ fontSize: "1.5rem" }} />}
                                                        title={timeline?.enum?.comment ?? ""}
                                                        container="card"
                                                        cardHeaderStyle={{ backgroundColor: "light", color: "primary" }}
                                                        {...timeline}
                                                    >
                                                        {getOrderFields().map((field) => getCalculatedRow(timeline?.new_value ?? {}, field))}
                                                    </TimelineEvent>
                                                ))}
                                            </Timeline>
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
});
