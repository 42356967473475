import _ from "lodash";
import React, { useState } from "react";
import { ChartCards } from "../../../components/common/ChartsandGraphs";
import { FilterForm } from "../../../components/common/FilterForm";
import { getDateWhere, getPastDays, getUniqValues, useCommonQuery } from "../../../helpers";

const Detail = () => {
    const [inner_filters, set_inner_filters] = useState({});
    const [days_to_show] = useState(180);

    const mandi_metric_rate_pricetype_date_commodity_variety = useCommonQuery({
        entity: "metric_rate_pricetype_date_commodity_variety",
        args: { pricetype: "mandi" },
        where: { ...getDateWhere(days_to_show), ...inner_filters },
        order_by: { date: "asc" },
    });

    const wholesale_metric_rate_pricetype_date_commodity_variety = useCommonQuery({
        entity: "metric_rate_pricetype_date_commodity_variety",
        args: { pricetype: "wholesale" },
        where: { ...getDateWhere(days_to_show), ...inner_filters },
        order_by: { date: "asc" },
    });

    const retail_metric_rate_pricetype_date_commodity_variety = useCommonQuery({
        entity: "metric_rate_pricetype_date_commodity_variety",
        args: { pricetype: "retail" },
        where: { ...getDateWhere(days_to_show), ...inner_filters },
        order_by: { date: "asc" },
    });

    const mandi_varieties = getUniqValues(mandi_metric_rate_pricetype_date_commodity_variety, "type");
    const wholesale_varieties = getUniqValues(wholesale_metric_rate_pricetype_date_commodity_variety, "type");
    const retail_varieties = getUniqValues(retail_metric_rate_pricetype_date_commodity_variety, "type");

    const dateseries_chart = (items, series, title) =>
        items?.length > 0 && series?.length > 0
            ? {
                  header: `Rates By Commodity Variety - ${title} `,
                  // header_link: "/marketplace/order/list/active/all",
                  type: "area",
                  stroke: {
                    curve: "smooth",
                },
                  options: {
                      chart: {
                          id: title,
                          group: "dateseries",
                          type: "basic-bar",
                      },
                      stroke: {
                        curve: "smooth",
                    },
                    dataLabels: {
                        enabled:false
                    },
                      responsive: [
                          {
                              breakpoint: 480,
                              options: {
                                  legend: {
                                      position: "bottom",
                                      offsetX: -10,
                                      offsetY: 0,
                                  },
                              },
                          },
                      ],
                      xaxis: {
                          categories: getPastDays(days_to_show),
                          type: 'datetime'
                      },
                      yaxis: {
                          labels: {
                              minWidth: 50,
                          },
                      },
                  },
                  series: [
                      ...((!_.isArray(items) || _.isEmpty(items)
                          ? []
                          : items?.map((e) => ({
                                name: e,
                                data: series.filter((x) => x.type === e || !x.type).map((x) => x?.amount?.toFixed(0) ?? 0) ?? [],
                            }))) ?? []),
                  ],

                  colSpan: `col-xl-12 col-lg-12 col-md-12 col-sm-12`,
              }
            : null;

    return (
        <>
            <FilterForm fields={[]} onFilter={set_inner_filters} />
            <ChartCards
                options={[
                    dateseries_chart(mandi_varieties, mandi_metric_rate_pricetype_date_commodity_variety, "Mandi"),
                    dateseries_chart(wholesale_varieties, wholesale_metric_rate_pricetype_date_commodity_variety, "Wholesale"),
                    dateseries_chart(retail_varieties, retail_metric_rate_pricetype_date_commodity_variety, "Retail"),
                ].filter((x) => x !== null)}
            />
        </>
    );
};

export default Detail;
