import React, { useState }  from "react"; 
import NumberFormat from "react-number-format";
 
import { SimpleTable } from "../../../../../components/common/SimpleTable";
import { ENUM_ACTIVE } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";


const ListRowChild =   ({object}) => {
 

    // const [counter, setCounter] = useState(1);

 
    console.log("props ",object);
	 
    //  const total_amount =  
      
    //    (0.18*object?.ocean_freight)
    //  + (0.18*object?.temp_variation_charges)
    //  + (0.18*object?.muf)
    // //  + (0.18*object?.rate)
    //  + (0.18*object?.terminal_handling_charges)
    // //  + (0.18*object?.bl_fee)
    //  + (0.18*object?.seal_charges)
    //  + (0.18*object?.toll_charges);

 
	return (

<div class="container-fluid shadow  vw-80 py-2">
    
      <div class=" mb-1    " style={{color:"#34C38F"}}>Breakup (FCL Freight Local Charges)</div>
      
      <div className="row">
            
             <div className="col">

                  <div class="row">
                    <div class="col text-start font-weight-bold"><b>Ocean freight(USD)</b>:</div>
                    <div class="col text-start">
                                            
                    <NumberFormat
                                    value={ object?.ocean_freight?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                   </div>
                  </div>
                  <div class="row">
                   <div class="col  text-start font-weight-bold"><b>Ex rate(INR)</b>:</div>
                   <div class="col  text-start">
                   <NumberFormat
                                    value={ object?.dollar_price?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                   </div>
               </div>
                  

             </div>
             <div className="col">
             <div class="row">
                    <div class="col text-start font-weight-bold"><b>Temp variation Charges(INR)</b>:</div>
                    <div class="col text-start">
                      
                    <NumberFormat
                                    value={ object?.temp_variation_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    </div>


                   </div>

                   <div class="row">
                   <div class="col  text-start font-weight-bold"><b>THC (INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.terminal_handling_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                    
                                    </div>
               </div>  
               <div class="row">
                   <div class="col  text-start font-weight-bold"><b>BL Fee(INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.bill_of_lading_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                   </div>
               </div>

             </div>
             <div className="col">

             <div class="row">
                    <div class="col  text-start font-weight-bold"><b>MUF(INR)</b>:</div>
                    <div class="col text-start">
                    <NumberFormat
                                    value={ object?.muf?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                     </div>
                    
                   </div>

                   <div class="row">
                   <div class="col  text-start font-weight-bold"><b>Toll Charges(INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.toll_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                    </div>
               </div>

               <div class="row">
                   <div class="col  text-start font-weight-bold"><b>Seal Charges(INR)</b>:</div>
                   <div class="col text-start">
                   <NumberFormat
                                    value={ object?.seal_charges?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                
                                 </div>
               </div>



               <div class="row">
                   <div class="col text-start"><b>Total Amount(INR)</b> :</div>
                   <div class="col text-start"><strong>
                   <NumberFormat
                                    value={((object?.ocean_freight*object?.dollar_price) + 
                                      object?.temp_variation_charges + 
                                      object?.terminal_handling_charges + 
                                      object?.bill_of_lading_charges+
                                      object?.muf + 
                                      object?.toll_charges + 
                                      object?.seal_charges )?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />

</strong>
                        
                        </div>
               </div>
               

             </div>

      </div>
      
  
 
</div>
	);
};
export default ListRowChild;
