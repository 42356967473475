// import React from "react";
import { Button , Modal, Row, Col,Container, Form} from "react-bootstrap";
import {SendRequest} from "../../../../../components/common/sampleRequest";
import React, { useContext  } from "react";
import { AuthContext } from "../../../../../context";
import { Input } from "reactstrap";
import { useObjectByPkQuery } from "../../../../../helpers";

 
 export const Modals =   (props) => {


    const [modal, setModal] = React.useState(false);
    const { user_id,  id_token } = useContext(AuthContext);
    const [rank, setRank] = React.useState(null);
    const valRef = React.useRef(null);

    console.log("MODAL PROPS",props?.body?.original,props?.body?.original?.bidding?.bidding_orders);

    const order = async (e) =>{
        console.log("bid_price_per_mt",e.target?.bid_price_per_mt?.value);
        e?.preventDefault();
        if(e.target?.bid_price_per_mt?.value){

            await  SendRequest({
              body:{...props?.body?.original,user_id, id_token, bidValue:e.target?.bid_price_per_mt?.value, id:props?.body?.original?.bidding?.id },
              responseType:null,
              docType:null,
              method:"POST",
              subUrl:"/user/bidding_order",
              message:"Order Placed sucessfully"}
            );
              setModal(false)

        }
      
 
    }

    const { object:rankData,error:asd } = useObjectByPkQuery({
      id: props?.body?.original?.bidding?.id,
      entity: "bidding",
      fields: `id      
        bidding_orders(order_by: {bid_price_per_mt: asc}) {
        bid_price_per_mt
        bidding_rank{
          id
          rank
        }
      }
     `,
    });

    const datas = props?.body?.original?.bidding?.bidding_orders;


    console.log("rankDataasd",rankData, datas, )  

    
    const checkRank = (e)=>{
      e?.preventDefault();

      if(!valRef.current.value) return;

      const ranks = rankData?.bidding_orders
      console.log("checkas",valRef,datas,rankData,ranks, valRef.current, valRef.current.value);

      let checkstrue = false;

      for(let i=0;i<ranks.length;i++){
        console.log("loopinsides00",valRef.current.value,ranks[i]?.bid_price_per_mt,valRef.current.value < ranks[i]?.bid_price_per_mt );

        if(valRef.current.value < ranks[i]?.bid_price_per_mt){
        console.log("loopinsides11",valRef.current.value,ranks[i]?.bid_price_per_mt );

          checkstrue = true;
          setRank(i+1);
          break;
        }

      }
      console.log("loopinsides22",valRef.current.value, );

      if(!checkstrue){
        console.log("loopinsides22",checkstrue,valRef.current.value, );
        setRank(ranks.length+1)
      }

    }
 
    return <> { <Modal  {...props} size="lg" backdrop="static" keyboard={false} show={modal} aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter"> Bid Details </Modal.Title>
                    </Modal.Header>
                    <Form  onSubmit={order}>
                        <Modal.Body>
                          <Container  className=" row justify-content-around" >
                            <Row className="pd-1"> 
                              <Col sm className="m-1 text-end small text-primary text-uppercase"> <b>Override Bid Price :</b> </Col> 
                              <Col sm className="text-start">
                                <input id="bid_price_per_mt" ref={valRef} type="number"/>  
                              </Col>   
                              <Col sm className="text-start">
                                <div>Rank 1 Price: {rankData?.bidding_orders[0]?.bid_price_per_mt}</div>  
                              </Col>    
                               <Col className="text-left" style={{float:"right"}}>
                                 <span className="m-1" >{rank}</span>&nbsp;&nbsp;<Button     onClick={checkRank} >Check-Rank</Button>  
                                </Col>  
                              </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                          
                          <Button type="submit" style={{float:"right"}} >Bid</Button>
                          <Button onClick={(e)=> setModal(false)}>Close</Button>
                          
                        </Modal.Footer>
                        </Form>
                      </Modal> } 
      
                     <button className="btn btn-sm btn-primary p-1 pl-2 pr-2 responsive-btn" onClick={(e)=>setModal(true)}>BID</button>
      
      </>
  }
  

  