import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { getLabel, getParams, setUrlPath } from "../../helpers";

const ButtonTabs = ({ options = [], arg = 0, prefix }) => {
    const { args } = getParams();

    if (options?.length > 0)
        if (!args[arg] || (args[arg] && !options.includes(args[arg]))) return <Redirect to={setUrlPath(arg, options[0])} />;
    return (
        <Fragment>
            {options?.length > 1 ? (
                <div className="btn-group shadow mt-1" role="group">
                    {prefix && (
                        <button key="group" className={"btn btn-sm btn-light disabled text-muted"}>
                            {prefix.toUpperCase()}
                        </button>
                    )}
                    {options?.map((o, i) => (
                        <Link
                            key={i}
                            className={"btn btn-sm btn-outline-primary" + (o === args[arg] ? " active text-white" : "")}
                            to={setUrlPath(arg, o)}
                        >
                            {getLabel(o, true).toUpperCase()}
                        </Link>
                    ))}
                </div>
            ) : (
                <span key={Math.random()} />
            )}
        </Fragment>
    );
};

export default ButtonTabs;
