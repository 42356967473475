import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
	const { object } = useObjectByPkQuery({
		id: parent_id,
		...useEntityFields({ entity, parent_id, solution }),
	});

	return (
		<PageContainer
			detail={page === "detail"}
			parent_id={parent_id}
			breadcrumbTitle={object?.name}
			component={DetailInfo}
			entity={entity}
			options={{
				...useEntityFields({ entity, parent_id, solution }),
			}}
			tab_arg={4}
			metric_cards={[
				{
					label: getLabel("order", true),
					value: object?.active_orders?.aggregate?.count || 0,
					total: object?.total_orders?.aggregate?.count || 0,
				},
				{
					label: getLabel("offer", true),
					value: object?.active_offers?.aggregate?.count || 0,
					total: object?.total_offers?.aggregate?.count || 0,
				},
				{
					label: getLabel("bank", true),
					value: object?.active_banks?.aggregate?.count || 0,
					total: object?.total_banks?.aggregate?.count || 0,
				},
				{
					label: getLabel("company", true),
					value: object?.active_companys?.aggregate?.count || 0,
					total: object?.total_companys?.aggregate?.count || 0,
				},
				{
					label: getLabel("document", true),
					value: object?.active_documents?.aggregate?.count || 0,
					total: object?.total_documents?.aggregate?.count || 0,
				},
			]}
			tab_options={[
				{
					...useEntityFields({ entity: "jobcard" }),
					label: getLabel("jobcard", true),
					value: "jobcard",

					component: DataTable,
					filters: { user_id: { _eq: parent_id } },
				},
				{
					...useEntityFields({ entity: "order", parent_id, solution }),
					label: getLabel("order", true),
					value: "order",

					component: DataTable,
					filters: { user_id: { _eq: parent_id } },
					skipColumns: ["user"],
				},
				{
					...useEntityFields({ entity: "offer", parent_id, solution }),
					label: getLabel("offer", true),
					value: "offer",

					component: DataTable,
					filters: { user_id: { _eq: parent_id } },
					skipColumns: ["user"],
				},
			]}
		/>
	);
};

export default Detail;
