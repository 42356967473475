import _ from "lodash";
import React, { useContext } from "react";
import { DataTable } from "../../../../../components/common/DataTable";
import { PageContainer } from "../../../../../components/common/PageContainer";
import { AuthContext, GeneralContext } from "../../../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../../../helpers";
import { useWebEntityFields } from "../../../common/fields";
import RowChild from "./rowChild";
import { Modals } from "./modal";
import { Button } from "reactstrap";

const List = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
    const { enum_user_role } = useContext(GeneralContext);
    const entity_fields = useWebEntityFields(entity);
    // console.log("🚀 ~ file: list.js ~ line 13 ~ List ~ entity_fields", entity_fields)

    const {  user_id , id_token} = useContext(AuthContext);
   
 
    
	const { object, error } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
	
    const override_fields = {
                             filters:{customer_id :{_eq:object?.customer_id}},
                             overrideValues:{created_by_id:object?.customer_id,
                                             customer_id : object?.customer_id }
                            }
 

    
 

    // console.log("error for inquries",user_id,object,error)

    if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
    return (
        <PageContainer
            breadcrumbTitle={getLabel(entity, true)}
            // breadcrumbOptions={["role", "active"]}
            breadcrumbArg={3}
            tab_arg={4}
            disableAction={true}
            disableAdd={true}
            hideFilter={true}
            searchableColumns={false}
    
			enabledispatchColumn={ 
				[
				    {Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>ORDER</Button>, body:{col:"id",type:"ExporterPO", sendEmail:"false" },requestDetails:{showTitle:"po",subUrl:"/user/test",id_token, method:"post", docType:"pdf",responseType:"blob",message:"Sucessfully Created!"}},
				]}	
        	detail={page === "detail"}
			rowChild={RowChild}
			expanded={true}
			Model={Modals}
			// miniColumnWidth={120}
            expandedHeader="VIEW QUOTATION"
            tab_options={(() => {
                let tab_options = [];
                // switch (tab) {
                //     case "role":
                //         tab_options = !_.isArray(enum_user_role)
                //             ? []
                //             : enum_user_role?.map((e) => ({
                //                   ...entity_fields,
                //                   ...override_fields,
                //                   overrideValues: {
                //                       ...(entity_fields?.overrideValues || {}),
                //                       ...(override_fields?.overrideValues || {}),
                //                   },
                //                   label: e?.comment,
                //                   value: e?.value,
                //                   component: DataTable,
                //                   filters: {
                //                       ...(entity_fields?.filters || {}),
                //                       ...(override_fields?.filters || {}),
                //                       role: { _eq: e.value },
                //                   },
                //               }));
                //         break;
                //     default:
                //         tab_options = ENUM_ACTIVE.map((e) => ({
                //             ...entity_fields,
                //             ...override_fields,
                //             overrideValues: {
                //                 ...(entity_fields?.overrideValues || {}),
                //                 ...(override_fields?.overrideValues || {}),
                //             },
                //             label: e?.comment,
                //             value: e?.value,
                //             component: DataTable,
                //             filters: {
                //                 ...(entity_fields?.filters || {}),
                //                 ...(override_fields?.filters || {}),
                //                 active: { _eq: e.value === "active" ? true : false },
                //             },
                //         }));
                //         break;
                // }
                tab_options = [
                    {
                        label: "ALL",
                        value: "all",
                        ...entity_fields,
                        ...override_fields,
                        overrideValues: {
                            ...(entity_fields?.overrideValues || {}),
                            ...(override_fields?.overrideValues || {}),
                        },
                        component: DataTable,
                        filters: {
                            ...(entity_fields?.filters || {}),
                            ...(override_fields?.filters || {}),
                        },
                    },
                    ...tab_options,
                ];
                return tab_options;
            }).call(this)}
        />
    );
};

export default List;
