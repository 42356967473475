import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const Carousal = () =>{

   return (  <Carousel autoPlay infiniteLoop showThumbs={false}  showIndicators={false}>
    <div>
        <img  style={{backgroundSize:"cover", backgroundRepeat:"no-repeat",maxHeight:"70vh"}} class="img-fluid max-width: 100% height:auto" src="https://images.unsplash.com/photo-1634638022003-1e5928bb311f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80" />
     </div>
    <div>
        <img style={{backgroundSize:"cover", backgroundRepeat:"no-repeat",maxHeight:"70vh"}}  class="img-fluid max-width: 100%"  src="https://images.unsplash.com/photo-1518527989017-5baca7a58d3c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80" />
     </div>
    <div>
        <img style={{backgroundSize:"cover", backgroundRepeat:"no-repeat",maxHeight:"70vh"}} class="img-fluid max-width: 100%" src="https://images.unsplash.com/photo-1629267677471-45d3f36021f9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80" />
     </div>
</Carousel>
)
};


export default Carousal;