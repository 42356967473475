// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
// import logo from "../../assets/images/OriginKonnectlogo.png";
import logo from "../../assets/images/OriginKonnect_512_512.png";
import { APP_CONFIG, APP_PATHS } from "../../config";
import { AuthContext } from "../../context";

const ForgetPasswordPage = (props) => {
    const { user } = useContext(AuthContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [message, setMessage] = useState(false);

    useEffect(() => {
        setIsSubmitting(true);
        setMessage(false);
    }, []);

    const onSubmit = async (e, v) => {
        e.preventDefault();
        const { email } = v;
        if (email) {
            setIsSubmitting(true);
            try {
                const response = await Axios({
                    method: "POST",
                    url: `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/forgotpassword`,
                    data: { email },
                    headers: { "x-client-app": "web-admin" },
                });

                if (response.status === 201) setMessage(true);
                toast.info(response.data.message, { position: "top-right" });
            } catch (err) {
                toast.error(err.response.data.message, { position: "top-right" });
            }
            setIsSubmitting(false);
        }
    };

    if (user) return <Redirect to={APP_PATHS.dashboard} />;

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary">
                                    <Row>
                                        <Col className="col-12">
                                            <div className="text-white p-4">
                                                <h5 className="text-white">Password Reset</h5>
                                                <p>Enter your email to reset your password</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4 float-end">
                                                <span className="avatar-title rounded-circle bg-white shadow">
                                                    <img src={logo} alt="" className="" height="34" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        {props.forgetError && props.forgetError ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }}>
                                                {props.forgetError}
                                            </Alert>
                                        ) : null}
                                        {props.forgetSuccessMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }}>
                                                {props.forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm className="form-horizontal mt-4" onValidSubmit={onSubmit}>
                                            <div className="form-group">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <Row className="form-group">
                                                <Col className="text-right">
                                                    <button
                                                        className="btn btn-primary w-md waves-effect waves-light my-2"
                                                        type="submit"
                                                        disabled={!isSubmitting}
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-right" style={{ color: "orangeRed" }}>
                                                    {message === true ? "Please check your email to reset password" : null}
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link to="login" className="font-weight-medium text-primary">
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>© {new Date().getFullYear()} OriginKonnect .</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ForgetPasswordPage);
