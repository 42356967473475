import React from "react";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({ id: parent_id, ...useEntityFields({ entity, parent_id, solution }) });

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            component={DetailInfo}
            entity={entity}
            options={{
                ...useEntityFields({ entity, parent_id, solution }),
            }}
        />
    );
};

export default Detail;
