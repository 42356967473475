import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { APP_PATHS } from "../../config";
import { AuthContext } from "../../context";

const Logout = (props) => {
    const { logoutUser } = useContext(AuthContext);

    logoutUser();
    return <Redirect to={APP_PATHS.login} />;
};

export default Logout;
