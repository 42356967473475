import React from "react";
import _ from "lodash";
import { Accordion, Button, Collapse } from "react-bootstrap";
import { getLabel } from "../../helpers";
import { Controller } from "react-hook-form";
import DatePicker from "react-date-picker";



export const Collapsable = ({entity_columns, field, register, readOnly,control}) =>{

    const [open, setOpen] = React.useState(false);
                                  
    let chekc =   _.filter(entity_columns, { dependsCollapse: field?.id })?.map((childField,i)=>{

                  childField.name = childField.accessor;

           return  <div className="">
         
               {
                     (() =>{
                     if(childField.type == "boolean"){
                         return ( 
                                 <span className=" "  >
                                     <div for={childField.name} className={`h6 small text-uppercase text-muted `}>{childField?.Header || getLabel(childField.name) }</div>
                                     <input
                                         name={childField.name}
                                         disabled={readOnly || childField.disabled}
                                         type="checkbox"
                                         className= {  "float-left mt-2"}
                                         ref={register(childField.rules)}
                                     /> 
                                 </span>
                         );
                         }
                      
                    if(childField.type == "textarea"){
                        return(
                            <span   >
                            <div for={childField.name} className={`h6 small text-uppercase text-muted`}>{childField?.Header || getLabel(childField.name) }</div>
                            <textarea
                            className="form-control "
                            name={childField.name}
                            type={childField.type}
                            step={childField.step}
                            message={childField?.message}
                            disabled={readOnly || childField.disabled}
                            max={childField.max}
                            ref={register(childField.rules)}
                        />
                        </span>
                     )}
                     if(childField.type==="select"){
                       
                            if (childField?.options?.length > 0){
                            // console.log("select is", field)
                                return (
                                    <span   >
                                    <div for={childField.name} className={`h6 small text-uppercase text-muted`}>{childField?.Header || getLabel(childField.name) }</div>
                                    <select
                                        className="form-control "
                                        name={childField.name}
                                        type={childField.type}
                                        disabled={readOnly || childField.disabled || childField.options?.length < 2}
                                        multiple={childField.multiple || false}
                                        ref={register(childField.rules)}
                                        // defaultValue ={childField.options[1].value}
                                        
                                    >
                                        {childField.options?.map((o, i) => (
                                            <option value={o?.value ?? o?.id} key={i} className="option">
                                                {o?.comment ?? o?.name}
                                            </option>
                                        ))}
                                    </select>
                                    </span>
                                );
                             }
                     }

                     if(childField.type==="date"){
                        return(
                           <>
                            <div for={childField.name} className={`h6 small text-uppercase text-muted`}>{childField?.Header || getLabel(childField.name) }</div>
                         
                                 <Controller
                                        as={ <DatePicker control={control}  ref={register(childField.rules)} disabled={readOnly || childField.disabled} {...childField} />}
                                        name={childField.name}
                                        control={control}
                                        rules={childField.rules}
                                 ></Controller>
 
                            </>
                        );
                     }
                     return   <>
                     
                                 <span for={childField.name} className={`h6 small text-uppercase text-muted`}>{childField?.Header || getLabel(childField.name) }</span>
                                 
                                 <input
                                     key={i}
                                     className="form-control "
                                     name={childField.name}
                                     type={childField.type || "number"}
                                     step={childField.step}
                                     min={childField.min}
                                     defaultValue={childField.min || 0}
                                     disabled={readOnly || childField.disabled}
                                     max={childField.max}
                                     ref={register({
                                         ...childField.rules,
                                         validate: { check: (val) => val.match() },
                                     })}
                                 />
                          </>
                 } 
     

                 ).call(this)
                 
              } 
             </div>
             })

    //  console.log("check details are",chekc)
     return <>
 
     
     <>
 

         <div class="p-3 mb-2 bg-light text-dark" className="p-2 mt-3 bg-light text-dark mb-3 " 
             onClick={() => setOpen(!open)}
             aria-controls="example-collapse-text"
             aria-expanded={open}>    {getLabel(field.id)}<span>{">>"}</span></div>


         <Collapse in={true} className="container-fluid p-0">
             <div id="example-collapse-text" className="row "  >
             {chekc?.map((e,i)=>   <div className="col-sm-3 mb-3">
                                      {e}
                                    </div>

             )}
              </div>                      
  
         </Collapse>
         </>                 
          </> ;
}