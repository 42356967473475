// import React from "react";
import { Button , Modal, Row, Col,Container} from "react-bootstrap";
import {SendRequest} from "../../../../../components/common/sampleRequest";
import React, { useContext, useEffect  } from "react";
import { AuthContext } from "../../../../../context";
import NumberFormat from "react-number-format";

 
 export const Modals =   (props) => {

    console.log("Props", props, props?.body?.original);

    const object = props?.body?.original;
    const [modal, setModal] = React.useState(false);
    const { user_id,  id_token } = useContext(AuthContext);
    const [counter, setCounter] = React.useState(1);
    const [amount, setAmount] = React.useState(1);
    const [gst, setGst] = React.useState(1);

 

     React.useEffect(()=>{
      if(counter){
        const amounts = counter*((object?.dollar_price*object?.ocean_freight) + 
                                object?.temp_variation_charges + 
                                object?.terminal_handling_charges + 
                                object?.bill_of_lading_charges+
                                object?.muf + 
                                object?.toll_charges + 
                                object?.seal_charges); 

        const gsts = counter* 
                      ( (0.05*object?.dollar_price*object?.ocean_freight)
                      + (0.18*object?.temp_variation_charges)
                      + (0.18*object?.terminal_handling_charges)
                      + (0.18*object?.bill_of_lading_charges)
                      + (0.18*object?.muf)
                      + (0.18*object?.toll_charges)
                      + (0.18*object?.seal_charges)
                      )?.toFixed(2);
              console.log(amounts)
              setGst(gsts);
              setAmount(amounts);
       }
      
     },[counter])



    const inc = (e) => {
       e?.preventDefault();

       console.log("Counter", counter);

       

       if(e?.target?.dataset?.inctype=="increment"){
        setCounter(oldCounter => oldCounter+1)
       }

       if(e?.target?.dataset?.inctype=="decrement"){
        if((counter-1) <1) return;
        setCounter(oldCounter => oldCounter-1)
       }
    }

   
    const order = async (e) =>{
        e?.preventDefault();
     console.log("Props are",e,e?.target,
     e?.target?.humidity?.value,
     e?.target?.ventilation?.value,
     e?.target?.cargo_wt?.value,e?.target?.commodity?.value, e?.target?.temperature?.value ,  Object.fromEntries(new FormData(e.target)));
    //  console.log("Props are",e,e?.target,e?.target?.cargo_wt?.value, props?.body?.original,Object.fromEntries(new FormData(e.target)));
 
         await  SendRequest({ 
         body:{...props?.body?.original,user_id,
                  id_token,
                  cargo_wt:e?.target?.cargo_wt?.value ||0, 
                  quantity:counter,
                  humidity:e?.target?.humidity?.value,
                  ventilation: e?.target?.ventilation?.value?.trim() ||0,
                  cargo_wt: e?.target?.cargo_wt?.value?.trim() || 0,
                  commodity:e?.target?.commodity?.value?.trim() || 0, 
                  temperature:e?.target?.temperature?.value?.trim() || 0,
                  gst:gst,
                  cost:amount,
                  total_cost:gst+amount
          },
         responseType:null,
         docType:null,
         "method":"POST",
         "subUrl":"/user/placeFreightOrder",
         message:"Order Placed sucessfully"
        
        });

          setModal(false)
 
    }
 
    return <> {     <Modal  {...props} size="lg"   
                              backdrop="static" 
                              keyboard={false} 
                              show={modal}
                              aria-labelledby="contained-modal-title-vcenter" centered >
                        <form onSubmit={order}>
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter"> Order Summary (EX Rate :INR{props?.body?.original?.dollar_price}) </Modal.Title>
                       </Modal.Header>
                        <Modal.Body>
                          <Container >

                            <Row>
                              <Col>
                                          <Row className="mb-1">
                                            <Col sm className="m-1">
                                              <Row>
                                                 <b>Ocean Freight($)</b>  
                                                <Col><small>{`( ${counter}x${props?.body?.original?.ocean_freight})`}</small></Col>
                                              </Row>   </Col> 
                                             
                                            <Col sm className="text-start">: &nbsp;
                                            
                                            <NumberFormat
                                    value={ (props?.body?.original?.ocean_freight * counter)?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                      </Col>  
                                          </Row>

                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>Temp variation Charges</b>  
                                                <Col className="fs-6" ><small>{`( ${counter}x${props?.body?.original?.temp_variation_charges})`} </small></Col>
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"> <b>{`Temp variation Charges (${counter}x${props?.body?.original?.temp_variation_charges})`}</b> </Col>  */}
                                            <Col sm className="text-start">: &nbsp;
                                            <NumberFormat
                                    value={ (props?.body?.original?.temp_variation_charges || 0) * counter?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    
                                     </Col>  
                                          </Row>

                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>THC</b>  
                                                <Col><small>{`( ${counter}x${props?.body?.original?.terminal_handling_charges})`}</small></Col>
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"> <b>{`THC (${counter}x${props?.body?.original?.terminal_handling_charges})`}</b> </Col>  */}
                                            <Col sm className="text-start  d-flex">: &nbsp;     <NumberFormat
                                    value={ (props?.body?.original?.terminal_handling_charges * counter)?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    
  </Col>  
                                          </Row>
                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>BL Fee</b>  
                                                <Col><small>{`( ${counter}x${props?.body?.original?.bill_of_lading_charges})`}</small></Col>
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"> <b>{`BL Fee (${counter}x${props?.body?.original?.bill_of_lading_charges})`}</b> </Col>  */}
                                            <Col sm className="text-start  d-flex">: &nbsp;     <NumberFormat
                                    value={ (props?.body?.original?.bill_of_lading_charges *counter)?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    
                                      </Col>  
                                          </Row>

                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>MUC</b>  
                                                <Col><small>{`( ${counter}x${props?.body?.original?.muf})`}</small></Col>
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"> <b>{`MUC (${counter}x${props?.body?.original?.muf})`}</b> </Col>  */}
                                            <Col sm className="text-start  d-flex">: &nbsp;     <NumberFormat
                                    value={ (props?.body?.original?.muf * counter)?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    
                                    </Col>  
                                          </Row>


                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>Toll Charges </b>  
                                                <Col><small>{`( ${counter}x${props?.body?.original?.toll_charges})`}</small></Col>
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"> <b>{`Toll Charges (${counter}x${props?.body?.original?.toll_charges})`}:</b> </Col>  */}
                                            <Col sm className="text-start">: &nbsp;
                                            
                                            <NumberFormat
                                    value={(props?.body?.original?.toll_charges * counter)?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    
                                     </Col>  
                                          </Row>


                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>Seal Charges</b>  
                                                <Col><small>{`( ${counter}x${props?.body?.original?.seal_charges})`}</small></Col>
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"><b>{`Seal Charges (${counter}x${props?.body?.original?.seal_charges})`}:</b> </Col>  */}
                                            <Col sm className="text-start">: &nbsp;
                                            <NumberFormat
                                    value={(props?.body?.original?.seal_charges * counter)?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> 
                                    
                                    
                                     </Col>  
                                          </Row>


                                          <Row className="mb-1">

                                          <Col sm className="m-1">
                                              <Row>
                                                 <b>Cost</b>  
                                                {/* <Col>{`( ${counter}x${props?.body?.original?.ocean_freight})`}</Col> */}
                                              </Row>   
                                            </Col> 


                                            {/* <Col sm className="m-1"> <b>{`Cost (${counter}x${props?.body?.original?.ocean_freight})`} :</b> </Col>  */}
                                            <Col sm className="text-start">: &nbsp;
                                            
                                            <NumberFormat
                                    value={ (counter*amount).toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} />
                                    
                                    {/* {(counter*amount).toFixed(0)} */}
                                     </Col>  
                                          </Row>

                                          

                              </Col>
                              <Col>
                              <Row className="mb-1">
                                <>
                                 <Col sm className="m-1"> <b>{`Commodity`} </b> </Col>
                                 <Col className="text-left d-flex"> <input  required id="commodity" type="text"  ></input></Col>  
                                </> 
                                </Row>


                                <Row className="mb-1">
                                <>
                                  <Col sm className="m-1"> <b>{`Cargo wt`} </b> </Col>
                                  <Col className="text-left d-flex"> <input  required id="cargo_wt" type="text"  ></input></Col>  
                                </>  

                              </Row>

                              <Row className="mb-1">
                                        {props?.body?.original?.cargo_category_type === "reefer" ? <>
                                            <Col sm className="m-1"> <b>{`Temp`}</b> </Col>
                                            <Col className="text-left  d-flex"> <input    id="temperature" type="text"  ></input> </Col>  
                                            </> : null}
                                        </Row>

                                        <Row className="mb-1">
                                         {props?.body?.original?.cargo_category_type === "reefer" ? <>
                                            <Col sm className="m-1"> <b>{`Humidity`}</b> </Col>
                                            <Col className="text-left  d-flex"> <input    id="humidity" type="text"  ></input> </Col>  
                                            </> : null}
                                        </Row>

                                        <Row className="d-flex">
                                        {props?.body?.original?.cargo_category_type === "reefer" ? <>
                                            <Col sm className="m-1"  > <b>{`Ventilation `}</b>  </Col>
                                            <Col className="text-left  d-flex" >  <input       id="ventilation" type="text"  ></input> </Col>  
                                            </> : null}
                                        </Row>








                              </Col>
                            </Row>
                          
                                
                          </Container>
                        
                          <div class="p-1  bg-primary text-dark text-center">
                            
                            Approx INR            <NumberFormat
                                    value={
                                      counter* ( (0.05*object?.dollar_price*object?.ocean_freight)
                                      + (0.18*object?.temp_variation_charges)
                                      + (0.18*object?.terminal_handling_charges)
                                      +(0.18*object?.bill_of_lading_charges)
                                      + (0.18*object?.muf)
                                      + (0.18*object?.toll_charges)
                                      + (0.18*object?.seal_charges)
                                      )?.toFixed(0)}
                                    thousandsGroupStyle="lakh"
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={""} /> GST will be applicable at the time of billing
           

                                    
                                    </div> 
                        </Modal.Body>
                        <Modal.Footer>
                        <Row className="pd-1 d-flex"> 
                             <Col sm className="m-1 d-flex justify-content-between align-items-center"> 
                               Quantity : &nbsp;
                              <div className="d-flex justify-content-evenly">
                                <Button className="btn-primary p-2" data-incType="decrement" onClick={(e)=>inc(e)}>-</Button>
                                <input className="m-1"  value={counter} type="number" disabled style={{width:"50px"}} min={1}></input>
                                <Button className="btn-primary p-2" data-incType="increment" onClick={(e)=>inc(e)}>+</Button>
                                </div> 
                              </Col> 

                                    </Row>

                          <Button onClick={(e)=> setModal(false)}>Close</Button>
                          <Button type="submit">Place Order</Button>
                        </Modal.Footer>
                        </form>
                      </Modal> } 
      
                     <button className="btn btn-sm btn-primary  mt-1 p-1 responsive-btn" onClick={(e)=>setModal(true)}>BOOK NOW
</button>
      
      </>
  }
  

  