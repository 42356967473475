import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { INSERT_ONE } from "../gql";
import {
    Q_ENTITY_AGG,
    Q_ENTITY_AGGREGATE,
    Q_ENTITY_AGG_TEST,
    Q_ENTITY_BY_PK,
    Q_ENTITY_COUNT,
    Q_ENTITY_DATA,
    Q_ENTITY_METRIC,
    Q_ENTITY_METRIC_ARGS,
    Q_ENTITY_AGG_TEST_DISTINCT,
    Q_TIME_SERIES,
} from "../gql/query";
import {
    S_ENTITY_AGG,
    S_ENTITY_AGGREGATE,
    S_ENTITY_BY_PK,
    S_ENTITY_COUNT,
    S_ENTITY_DATA,
    S_ENTITY_METRIC,
    S_ENTITY_METRIC_ARGS,
    S_TIME_SERIES,
} from "../gql/subscription";
import { getDBTableName } from "./utils";
import { AuthContext, GeneralContext } from "../../src/context";
import { useEntityFields } from "./fields";

export const useTotalSubscription = ({ entity = "users", where = {}, query }) => {
    const [total, setTotal] = useState(0);
    const { data, loading, error } = useSubscription(query ? gql(query) : S_ENTITY_COUNT(getDBTableName(entity)), {
        skipCache: true,
        variables: { where: where },
    });
    if (!loading && !error && data?.counts?.aggregate && data?.counts?.aggregate?.count !== total)
        setTotal(data?.counts?.aggregate?.count ?? 0);
    return total;
};

export const useTotalQuery = ({ entity = "users", where = {}, query }) => {
    const [total, setTotal] = useState(0);
    const { data, loading, error } = useQuery(query ? gql(query) : Q_ENTITY_COUNT(getDBTableName(entity)), {
        skipCache: true,
        variables: { where: where },
    });
    if (!loading && !error && data?.counts?.aggregate && data?.counts?.aggregate?.count !== total)
        setTotal(data?.counts?.aggregate?.count ?? 0);
    return total;
};

export const useCommonQuery = ({
    entity = "user",
    fields = `id date type amount count`,
    where = {},
    search_on = {},
    order_by = {},
    args,
    distinct_on,
    skipCache,
    query,
    limit,
}) => {
    const [objects, setObjects] = useState([]);
    let query_variables = { skipCache: false, variables: { where: { ...where, ...search_on }, order_by } };
    if (skipCache) query_variables.skipCache = true;
    if (distinct_on) query_variables.variables.distinct_on = distinct_on;
    if (limit) query_variables.variables.limit = limit;
    if (args) query_variables.variables.args = args;
    const { data, error, loading } = useQuery(
        query ? gql(query) : args ? Q_ENTITY_METRIC_ARGS(getDBTableName(entity), fields) : Q_ENTITY_METRIC(getDBTableName(entity), fields),
        query_variables
    );

    useEffect(() => {
        if (!loading && !error && data?.objects && !_.isEqual(objects, data?.objects)) setObjects(data?.objects);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    return objects;
};

export const useCommonSubscription = ({
    entity = "users",
    fields = `id date type amount count`,
    where = {},
    search_on = {},
    order_by = {},
    distinct_on,
    args,
    skipCache,
    query,
    limit,
}) => {
    const [objects, setObjects] = useState([]);
    let query_variables = { skipCache: false, variables: { where: { ...where, ...search_on }, order_by } };
    if (skipCache) query_variables.skipCache = true;
    if (distinct_on) query_variables.variables.distinct_on = distinct_on;
    if (limit) query_variables.variables.limit = limit;
    if (args) query_variables.variables.args = args;
    const { data, error, loading } = useSubscription(
        query ? gql(query) : args ? S_ENTITY_METRIC_ARGS(getDBTableName(entity), fields) : S_ENTITY_METRIC(getDBTableName(entity), fields),
        query_variables
    );

    // console.log("HHHHHHHHH", fields, data, error, loading);

    useEffect(() => {
        if (!loading && !error && data?.objects && !_.isEqual(objects, data?.objects)) setObjects(data?.objects);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    return objects;
};


const useSaveHistory = ({entity,fields, data, error, loading,}) =>{

const { user_id, role} = useContext(AuthContext);

const { object } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });

// console.log("useSavehistory alling", entity, fields,fields?.origin_port_id?._eq,fields?.destination_port_id?._eq)

const [insertOne] = useMutation(INSERT_ONE(getDBTableName(entity)));

const [id, setId] =  useState(null);

// console.log("data us",id);


useEffect(()=>{


    if(entity==="export_freight_history"&&object?.customer_id&&fields?.origin_port_id?._eq&&fields?.destination_port_id?._eq&&role==="exporter"){
        
        // console.log("useSavehistory alling ===========>", entity, fields?.origin_port_id?._eq,fields?.destination_port_id?._eq,role)
    
        insertOne({
            
             variables:{
                object:{user_id:object?.customer_id,"origin_port_id":fields?.origin_port_id?._eq,"destination_port_id":fields?.destination_port_id?._eq}
            }
                
    }).then((data,error)=>{
        // console.log("data us",data, data?.object?.id,error);
    })
    
    }

    if(entity==="inquiries"&&object?.customer_id&&fields?.origin_port_id?._eq&&fields?.destination_port_id?._eq&&role==="exporter"){
        
        // console.log("useSavehistory alling ===========>", entity, fields?.origin_port_id?._eq,fields?.destination_port_id?._eq,role)
    
        insertOne({
            
             variables:{
                object:{customer_id:object?.customer_id,created_by_id:object?.customer_id,"origin_port_id":fields?.origin_port_id?._eq,"destination_port_id":fields?.destination_port_id?._eq}
            }
                
    }).then((data,error)=>{
        // console.log("data us",data, data?.object?.id,error);
    })
    
    }

},[fields?.origin_port_id?._eq,fields?.destination_port_id?._eq] )
}





  
export const  useObjectsQuery = ({
    entity = "users",
    fields,
    where = {},
    search_on = {},
    order_by = {},
    distinct_on,
    skipCache,
    query,
    ...rest
}) => {
    const [objects, setObjects] = useState([]);

    console.log("checking is asdf as follows", entity,distinct_on, where,rest)

    useSaveHistory({entity:"export_freight_history",fields:{...where}})

    const total = useTotalSubscription({
        entity: getDBTableName(entity),
        where: { ...where, ...search_on },
    });
    const [limit, setLimit] = useState(rest?.limit ?? 0);
    const [offset, setOffset] = useState(rest?.offset ?? 0);

    useEffect(() => {
        if (total < offset) setOffset(0);
    }, [offset, total]);

    const pages = Math.ceil(total / limit);

    const page = Math.floor(offset / limit) + 1;
    if (offset !== (page - 1) * limit) setOffset((page - 1) * limit);

    let query_variables = { skipCache: false, variables: { where: { ...where, ...search_on }, order_by } };
    if (skipCache) query_variables.skipCache = true;
    if (distinct_on) query_variables.variables.distinct_on = distinct_on;
    if (limit) query_variables.variables.limit = limit;
    if (offset) query_variables.variables.offset = offset;
    // console.log("check what is happening",query ? gql(query) : Q_ENTITY_DATA(getDBTableName(entity), fields), query_variables)

    const { data, error, loading, refetch } =  useQuery(query ? gql(query) : Q_ENTITY_DATA(getDBTableName(entity), fields), query_variables);
    console.log("🚀 ~ file: dbhooks.js ~ line 217 ~ data", data,error,loading)

    useSaveHistory({entity:"inquiries",fields:{...where}, data, error, loading })

    console.log("🚀 ~ file: dbhooks.js ~ line 217 ~ data", data,error,loading)

    useEffect(() => {
        // setObjects([]);

        // if (!loading && !error && data?.objects && !_.isEqual(objects, data?.objects)) {
            if (!loading && !error && data?.objects && !_.isEqual(objects, data?.objects)) {
            setObjects(data.objects);
        
        }


        



      console.log("entity_fieldss Ine",offset, limit, data?.objects)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading, data?.objects, objects?.length != data?.objects.length]);

    return {
        objects: objects?.map((o) => ({ ...o, refetch })),
        error,
        loading,
        refetch,
        data,
        setLimit,
        setOffset,
        limit,
        offset,
        pages,
        page,
        total,
    };
};

export const useObjectsSubscription = ({
    entity = "users",
    fields,
    where = {},
    search_on = {},
    order_by = {},
    distinct_on,
    skipCache,
    query,
    ...rest
}) => {
    const [objects, setObjects] = useState([]);

    const total = useTotalSubscription({
        entity: getDBTableName(entity),
        where: { ...where, ...search_on },
    });
    const [limit, setLimit] = useState(rest?.limit ?? 0);
    const [offset, setOffset] = useState(rest?.offset ?? 0);

    useEffect(() => {
        if (total < offset) setOffset(0);
    }, [offset, total]);

    const pages = Math.ceil(total / limit);
    const page = Math.floor(offset / limit) + 1;
    // if (offset !== (page - 1) * limit) setOffset((page - 1) * limit);

    let query_variables = { skipCache: false, variables: { where: { ...where, ...search_on }, order_by } };
    if (skipCache) query_variables.skipCache = true;
    if (distinct_on) query_variables.variables.distinct_on = distinct_on;
    if (limit) query_variables.variables.limit = limit;
    if (offset) query_variables.variables.offset = offset;

    const { data, error, loading, refetch } = useSubscription(
        query ? gql(query) : S_ENTITY_DATA(getDBTableName(entity), fields),
        query_variables
    );

    
    console.log("subscriptiasdfasdf",data, error, loading, refetch);

    useEffect(() => {
        if (!loading && !error && data?.objects && !_.isEqual(objects, data?.objects)) setObjects(data.objects);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    return {
        objects: objects?.map((o) => ({ ...o, refetch })),
        error,
        loading,
        refetch,
        setLimit,
        setOffset,
        limit,
        offset,
        pages,
        page,
        total,
    };
};
export const useObjectByPkQuery = ({ id, entity = "users", fields, query }) => {
    // console.log("Object quer details ", id, entity,fields,query)
    const [object, setObject] = useState(null);
    const { data, error, loading, refetch } = useQuery(query ? gql(query) : Q_ENTITY_BY_PK(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { id },
    });

    // console.log("check object query", data, error, loading)

    useEffect(() => {
        if (!loading && !error && data && data.object && !_.isEqual(data.object, object)) setObject(data.object);
    }, [data, error, loading, object]);

    return { object, error, loading, refetch };
};
export const useObjectByPkSubscription = ({ id, entity = "users", fields, query }) => {
    const [object, setObject] = useState(null);
    const { data, error, loading } = useSubscription(query ? gql(query) : S_ENTITY_BY_PK(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { id },
    });

    useEffect(() => {
        if (!loading && !error && data && data.object && !_.isEqual(data.object, object)) setObject(data.object);
    }, [data, error, loading, object]);

    return { object, error, loading };
};

export const useObjectQuery = ({ entity = "users", fields, query, where = {} }) => {
    const [object, setObject] = useState(null);
    const { data, error, loading } = useQuery(query ? gql(query) : Q_ENTITY_DATA(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { where: where },
    });

    useEffect(() => {
        if (!loading && !error && data.objects && data.objects[0]) setObject([data.objects[0]]);
    }, [data, error, loading]);

    return object;
};

export const useObjectSubscription = ({ entity = "users", fields, query, where = {} }) => {
    const [object, setObject] = useState(null);
    const { data, error, loading } = useSubscription(query ? gql(query) : S_ENTITY_DATA(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { where: where },
    });

    // console.log("Last details are", data, error, loading);
    useEffect(() => {
        if (!loading && !error && data.objects && data.objects[0]) setObject(data.objects);
    }, [data, error, loading]);

    return object;
};

export const useAggregateSubscription = ({ entity, where = {}, query, fields }) => {
    const [aggregate, setAggregate] = useState({ sum: {}, avg: {}, count: 0 });
    const { data, loading, error } = useSubscription(query ? gql(query) : S_ENTITY_AGGREGATE(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { where },
    });
    // console.log("data is",data)
    if (!loading && !error && data?.counts?.aggregate && !_.isEqual(data?.counts?.aggregate, aggregate)) setAggregate(data?.counts?.aggregate);
    return aggregate;
};

export const useAggregateQuery = ({ entity, where = {}, query, fields }) => {
    const [aggregate, setAggregate] = useState({ sum: {}, avg: {}, count: 0 });
    const { data, loading, error } = useQuery(query ? gql(query) : Q_ENTITY_AGGREGATE(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { where },
    });
    if (!loading && !error && data?.counts?.aggregate && !_.isEqual(data?.counts?.aggregate, aggregate)) setAggregate(data?.counts?.aggregate);
    return aggregate;
};

export const useAggQuery = ({ entity, where = {}, query, field = "amount", aggregate = "sum" }) => {
    const [value, setValue] = useState(0);
    const { data, loading, error } = useQuery(query ? gql(query) : Q_ENTITY_AGG(getDBTableName(entity), aggregate, field), {
        skipCache: true,
        variables: { where },
    });

    //  console.log("averages are ",aggregate ,data,loading, error , "--->",Q_ENTITY_AGG(getDBTableName(entity), aggregate, field))
    if (
        !loading &&
        !error &&
        data?.counts?.aggregate &&
        data?.counts?.aggregate[aggregate][field] &&
        !_.isEqual(data?.counts?.aggregate[aggregate][field], value)
    )
        setValue(data?.counts?.aggregate[aggregate][field]);

    return value;
};

export const useAggQuerytEST = ({ entity, where = {} ,query, field = "amount", aggregate = "sum" }) => {
    const [value, setValue] = useState(0);
    
    const { data, loading, error } = useQuery(query ? gql(query) : Q_ENTITY_AGG_TEST(getDBTableName(entity), aggregate, field), {
        skipCache: true,
        variables: { where },
    });

    if (
        !loading &&
        !error &&
        data?.counts?.aggregate &&
        data?.counts?.aggregate[aggregate][field] &&
        !_.isEqual(data?.counts?.aggregate[aggregate][field], value)
    )
        setValue(data?.counts?.aggregate[aggregate][field]);


    //  console.log("averages are test ",entity,  data?.counts?.aggregate[aggregate][field],  data, loading, error)


    return value;
};

export const useAggQuerytESTdistinct = ({ entity, where = {}, query, distinct_on = null, field = "count", aggregate = "count" }) => {
    const [value, setValue] = useState(0);
    const { data, loading, error } = useQuery(query ? gql(query) : Q_ENTITY_AGG_TEST_DISTINCT(getDBTableName(entity), aggregate, field), {
        skipCache: true,
        variables: { where },
    });

    // console.log("data is",data,  data?.counts["aggregate"][field] )
    // if (
    //     !loading &&
    //     !error &&
    //     data?.counts?.aggregate &&
    //     data?.counts?.aggregate[aggregate][field] &&
    //     !_.isEqual(data?.counts?.aggregate[aggregate][field], value)
    // )
    //     setValue(data?.counts?.aggregate[aggregate][field]);

    if (
        !loading &&
        !error &&
        data?.counts?.aggregate &&
        data?.counts["aggregate"][field] &&
        !_.isEqual(data?.counts["aggregate"][field], value)
    )
        setValue(data?.counts["aggregate"][field]);


    //  console.log("averages are test ",entity,  data?.counts?.aggregate[aggregate][field],  data, loading, error)


    return value;
};


export const useAggSubscription = ({ entity, where = {}, query, field = "amount", aggregate = "sum" }) => {
    const [value, setValue] = useState(0);
    const { data, loading, error } = useSubscription(query ? gql(query) : S_ENTITY_AGG(getDBTableName(entity), aggregate, field), {
        skipCache: true,
        variables: { where },
    });

    if (
        !loading &&
        !error &&
        data?.counts?.aggregate &&
        data?.counts?.aggregate[aggregate][field] &&
        !_.isEqual(data?.counts?.aggregate[aggregate][field], value)
    )
        setValue(data?.counts?.aggregate[aggregate][field]);

    return value;
};

export const usePaginatedQuery = ({ entity = "users", query, fields, where = {}, search_on = {}, order_by = {}, skipCache, ...rest }) => {
    const [objects, setObjects] = useState([]);
    const [limit, setLimit] = useState(rest.limit || 10);
    const [offset, setOffset] = useState(rest.offset || 0);

    const total = useTotalSubscription({ entity: getDBTableName(entity), where: { ...where } });
    const total_count = useTotalQuery({ entity: getDBTableName(entity), where: { ...where } });
    const count = useTotalQuery({ entity: getDBTableName(entity), where: { ...where, ...search_on } });
    let query_variables = { skipCache: true, variables: { where: { ...where, ...search_on }, limit, offset, order_by } };
    const { data, loading, refetch, error } = useQuery(
        query ? gql(query) : Q_ENTITY_DATA(getDBTableName(entity), String(fields)),
        query_variables
    );

    const pages = Math.ceil(count / limit);
    const page = Math.floor(offset / limit) + 1;
    // if (offset !== (page - 1) * limit) setOffset((page - 1) * limit);
    if (limit > total && total > 10) setLimit(total);

    useEffect(() => {
        if (!loading && !error && data?.objects) setObjects(data.objects?.map((o) => ({ ...o, refetch })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error]);

    useEffect(() => {
        if (count < offset) setOffset(0);
    }, [offset, count]);

    useEffect(() => {
        if (!loading && !error && total > 0 && total !== total_count) refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, total, total_count, error]);

    return {
        objects,
        loading,
        error,
        refetch,
        setLimit,
        setOffset,
        limit,
        offset,
        count,
        pages,
        page,
        total,
    };
};

export const useTimeSeriesQuery = ({ where = {}, order_by = {}, entity, fields = " date count ", query }) => {
    const objects = useRef([]);
    const { data, error, loading } = useQuery(query ? gql(query) : Q_TIME_SERIES(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { where, order_by },
    });
    useEffect(() => {
        if (!loading && !error && data && data.objects) objects.current = data.objects;
    }, [data, error, loading]);
    return { data: objects.current, loading };
};

export const useTimeSeriesSubscription = ({ where = {}, order_by = {}, entity, fields = " date count ", query }) => {
    const objects = useRef([]);
    const { data, error, loading } = useSubscription(query ? gql(query) : S_TIME_SERIES(getDBTableName(entity), fields), {
        skipCache: true,
        variables: { where, order_by },
    });
    useEffect(() => {
        if (!loading && !error && data && data.objects) objects.current = data.objects;
    }, [data, error, loading]);
    return { data: objects.current, loading };
};
