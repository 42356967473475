/* eslint-disable no-unused-vars */
import { useMutation } from "@apollo/client";
import _ from "lodash";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Pagination } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link, useHistory } from "react-router-dom";
import { useFlexLayout, usePagination, useResizeColumns, useRowSelect, useExpanded,useSortBy, useTable } from "react-table";
import { Button, PaginationItem, PaginationLink } from "reactstrap";
import { DELETE_ENUM, DELETE_ONE, UPDATE_ONE } from "../../gql";
import { confirmPerformAction, getDBTableName, IndeterminateCheckbox } from "../../helpers";
import { DataForm } from "./DataForm";
import { DropDownButton } from "./DropDownButton";
import { ImageModal } from "./ImageModal";
import { SendRequest } from "./sampleRequest";
import { FcDownload } from "react-icons/fc";

import "./SimpleTable.css";
import RatingValue from "../../pages/web/common/rating";
import { Modals } from "../../pages/web/common/screens/modal";

export const SimpleTable = ({
    setLimit = "",
    setOffset = "",
    offset = "",
    pages = "",
    page = "",
    total,
    data = [],
    columns = [{ accessor: "id" }],
    header,
    footer,
    entity,
    solution,
    limit = 10,
    smallTable = false,
    enableSelection = false,
    hideTableHeaders = false,
    disableAction = false,
    radioAction=false,
    enableDownloadCol=null,
    select_field = "id",
    disableExpansion = false,
    extraColumnData = null,
    disableEdit = false,
    disableView = false,
    activateStatus = false,
    rowChild,
    norecordsimg,
    norecordsmsg,
    enum_table = false,
    onSelect = () => {},
    onUpdate = () => {},
    refetch = () => {},
    enabledispatchColumn=null,
    miniColumnWidth = 30,
    Model = null,
    expandedHeader="VIEW DETAILS",
    ...rest
}) => {
    // const { id_token } = useContext(AuthContext);

    console.log("extraColumnData",expandedHeader );
    // console.log("disableEditChecking",disableEdit);
    const [rowId, setRowId] = useState();

    const [modal, setModal] = React.useState(false);

    const [updateOne] = useMutation(UPDATE_ONE(getDBTableName(entity)));
    const [deleteOne] = useMutation(enum_table ? DELETE_ENUM(getDBTableName(entity)) : DELETE_ONE(getDBTableName(entity)));
    const history = useHistory();

    const [ddata, setddata] = useState(data);
    useEffect(()=>{
        // eslint-disable-next-line eqeqeq
        if(data?.length != ddata?.length){
            setddata(data)
        }
  

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data, ddata])
 
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page: rows,
        selectedFlatRows,
        state: { expanded },
    } = useTable(
        {
            columns: useMemo(() => columns.filter((c) => c.column), [columns]),
            data: useMemo(() => (_.isArray(ddata) ? ddata : []), [ddata]),
            defaultColumn: useMemo(() => ({ width: 60, minWidth: 60, maxWidth: 600 }), []),
            defaultCanSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: limit ?? ddata?.length,
            },
        },
        useResizeColumns,
        useFlexLayout,
        useSortBy,
        useExpanded,

        usePagination,
        useRowSelect,

        // Here we will use a plugin to add our selection column, actions
        (hooks) =>
            hooks.visibleColumns.push((visible_columns) => {
                const idColumn = {
                    id: "id",
                    width: miniColumnWidth,
                    minWidth: miniColumnWidth,
                    maxWidth: miniColumnWidth,
                    skipSort: true,
                    disableResizing: true,
                    skipFilter: true,
                    Header: ({ getToggleAllRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,
                    Cell: ({ row }) => row.original[select_field] && <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
                };
                const expandColumn = {
                    id: "👉",
                    // width: miniColumnWidth,
                    // minWidth: miniColumnWidth,
                    // maxWidth: miniColumnWidth,

                    width: 120,
                    minWidth: 120,
                    maxWidth: 120,

                    skipSort: true,
                    disableResizing: true,
                    skipFilter: true,
                    Header: "",
                    // Cell: ({ row }) => (row.expanded ? '👇' : '👉'),
                    Cell: ({ row }) => (
                        <button className="btn btn-sm btn-primary  p-1 responsive-btn" onClick={(e)=>setModal(true)}>
                            {row.expanded ? "CLOSE" : expandedHeader}
                        </button>
                        ),
                };


                const extraColumn = {
                    id: "p",
                    width: miniColumnWidth,
                    minWidth: miniColumnWidth,
                    maxWidth: miniColumnWidth,
                    skipSort: true,
                    disableResizing: true,
                    skipFilter: true,
                    Header: "Extras",
                    ...extraColumnData
                 };
 
   
                const dispatchColumn = [];


                if( enabledispatchColumn?.length > 0 ) {
               
                    [...enabledispatchColumn]?.forEach((obj,i) =>{
                        // console.log("Object check is", obj)
                        dispatchColumn.push ({
                            id: i ,
                            key:{i},
                            width: obj?.minWidth ?? miniColumnWidth,
                            minWidth:obj?.minWidth ?? miniColumnWidth,
                            maxWidth: miniColumnWidth,
                            skipSort: true,
                            disableResizing: true,
                            skipFilter: true,
                            Header: obj?.Header || "",
                            Cell: ({ row }) => {

                                 console.log("Object is",entity, obj, row?.original,entity==="inquiries"&& obj?.entity !=="quotations",obj?.skipdata);

                                   return obj?.DataForm ?    

                                //    This is for the separates entities for the button, obj.entity represents the other table entitites
                                   <DataForm
                                        {...rest}
                                        key={row?.original?.id +i+ "edit"}
                                        entity={obj?.entity}
                                        showTitle={obj?.showTitle}
                                        overrideValues={ 
                                             entity==="inquiries"&& obj?.entity !=="quotations"  ? {"customer_id":row?.original?.["customer_id"]} : 
                                             obj?.entity=="inquiries"  ? {listing_id:row?.original?.["id"], ...obj?.overrideValues,} : 
                                             obj?.entity==="order_note"   ? {"order_id":row?.original?.["id"],...obj?.overrideValues,}:
                                             obj?.entity==="quotations"   ? {...obj?.overrideValues,inquiry_id:row?.original?.["id"]}:
                                            //  obj?.entity==="quotations"   ? {...row?.original,...obj?.overrideValues,}:
                                                                    {"customer_id":row?.original?.["customer_id"], ...obj?.overrideValues,}
                                             }
                                        skipdata={ entity==="inquiries"&& obj?.entity !=="quotations" ?   null : obj?.skipdata}
                                        defaultValues={   ((entity==="inquiries"&& obj?.entity !=="quotations" )|| obj?.entity==="order_note") ? {} : {...row?.original} }
                                   >  
                                 
                                   
                                   </DataForm>
                                   :

                                   obj?.link ?     
                                   
                                   <Link to={obj?.linkS.replace("#id",  row.original.id)} className="text-info float-left" >
                                        <button>Instructions</button>
                                   </Link> 

                                   :

                                  !obj?.colData ? 
                                        
                                        <i  style={{cursor:"pointer"}} className="text-center" onClick={(e)=>{

                                        const result = SendRequest({ ...obj?.requestDetails, history,  body:{...obj?.body,id:row?.original?.[obj?.body?.col],...row?.original}}).then(res=> res)
                    
                                        }}>{obj?.showHeader ? <button className="btn btn-sm btn-primary m-1">{ obj?.Header}</button>  :  <FcDownload /> } </i>  
                                    
                                    :
                                 
                                    Model ?  <Model refetch={refetch} modal={modal} body={row}/> :null 
                               } 
                        })
                    })

                }


                const editColumn = {
                    id: "~",
                    width: miniColumnWidth,
                    minWidth: miniColumnWidth,
                    maxWidth: miniColumnWidth,
                    skipSort: true,
                    disableResizing: true,
                    skipFilter: true,
                    Header: "",
                    Cell: ({ row }) =>{
                        return     row?.original?.active ? (
                            
                            <DataForm
                                {...rest}
                                key={row?.original?.id + "edit"}
                                entity={entity}
                                columns={columns}
                                defaultValues={{ ...(row?.original || {}) }}
                                refetch={refetch}
                                icon={true}
                            >
                                
                            </DataForm>
                        ) : (
                            ""
                        )
                    }
                };

                const radioActionColumn = {
                    id: "*",
                    width: miniColumnWidth,
                    minWidth: miniColumnWidth,
                    maxWidth: miniColumnWidth,
                    skipSort: true,
                    disableResizing: true,
                    skipFilter: true,
                    Header: "",
                    Cell: ({ row }) => {
                   
                        
                        return <label class="switch" >
                                        <input type="checkbox" onClick={() =>   confirmPerformAction({  title:`Are you sure, you want to make listing ${!row?.original?.active ? "active!":"inactive!"}`,
                                                                                                        action: updateOne,
                                                                                                        object: {
                                                                                                                        variables: {
                                                                                                                            [select_field]: row?.original?.[select_field],
                                                                                                                            set: { active: !row?.original?.active },
                                                                                                                        },
                                                                                                                },
                                            
                                                                                                        })
                                    } checked={row?.original?.active} data-toggle="toggle"  data-onstyle="success" data-offstyle="danger"/>
                                {/* } checked data-toggle="toggle" data-on="active" data-off="Inactive" data-onstyle="success" data-offstyle="danger"/> */}
                      <span class="slider round"></span>

                        </label> 

                    },
                
                }

    //             <Form>
    //             <Form.Check 
    //                 type="switch"
    //                 id="custom-switch"
    //                 label="lknlk"
    //             />
 
    // </Form>


                const actionColumn = {
                    id: "#",
                    width: miniColumnWidth,
                    minWidth: miniColumnWidth,
                    maxWidth: miniColumnWidth,
                    skipSort: true,
                    disableResizing: true,
                    skipFilter: true,
                    Header: "",
                    Cell: ({ row }) => {
                        let options = [];
                        if (!disableAction && activateStatus && select_field)
                            options.push({
                                title: row?.original?.active ? "Deactivate" : "Activate",
                                icon: row?.original?.active ? (
                                    <i className="fa fa-times h6 text-danger" />
                                ) : (
                                    <i className="fa fa-check text-success" />
                                ),
                                action: () =>
                                    confirmPerformAction({
                                        action: updateOne,
                                        object: {
                                            variables: {
                                                [select_field]: row?.original?.[select_field],
                                                set: { active: !row?.original?.active },
                                            },
                                        },
                                        refetch,
                                    }),
                            });
                        if (!disableAction && select_field && row?.original?.[select_field])
                            options.push({
                                title: "Delete",
                                icon: <i className="fa fa-trash text-danger" />,
                                action: () =>
                                    confirmPerformAction({
                                        action: deleteOne,
                                        object: { variables: { [select_field]: row?.original?.[select_field] } },
                                        refetch,
                                    }),
                            });
                        return <DropDownButton key={row?.original?.id || row?.original?.value} options={options} />;
                    },
                };
                let newColumns = [];
               
                if (enableSelection) newColumns.push(idColumn);
                newColumns.push(...visible_columns);
                // console.log("Dispat ",dispatchColumn)
                if (!disableExpansion && rowChild) newColumns.push(expandColumn);
                if (enabledispatchColumn) newColumns.push(...dispatchColumn);
                if (!disableEdit){
                    newColumns.push(editColumn);
                    // console.log("disableEdita",disableEdit,newColumns)
                
                }
                if(radioAction)newColumns.push(radioActionColumn);
                if (!disableAction) newColumns.push(actionColumn);
                if (extraColumnData) newColumns.push(extraColumn);
                //  console.log("asdfsdfads", newColumns)

                return newColumns;
            })
    );
    if (ddata && ddata.length > 0)
        return (
            <div key={entity + "table"} className="bg-white">
                {enableSelection && selectedFlatRows.length > 0 && <div className="m-2">{onSelect(selectedFlatRows)}</div>}

                {/**Added responsiveness to tables */}
                <div
                    className={`${headerGroups[0].headers.length >= 10 ? "table-responsive-xxl" : "table-responsive-xl"}`}
                    //   style={{ "overflow-x": "scroll !important" }}
                    style={{ overflowX: "scroll", ovferflowY: "visible" }}
                >
                    <table
                        className="table m-0 p-0 table-striped table-hover"
                        {...getTableProps()}
                        style={{ whiteSpace: "break-spaces", wordBreak: "normal" }}
                    >
                        {!hideTableHeaders && (
                            <thead>
                                {headerGroups?.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers?.map((column, index) => {
                                            return column.id !== "id" && column.id !== "👉" && column.id !== "#" && column.id !== "~" ? (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    key={index}
                                                    className={
                                                        (column.skipSort
                                                            ? " m-0 p-2 "
                                                            : column.isSorted
                                                            ? column.isSortedDesc
                                                                ? " cursor-pointer m-0 p-2"
                                                                : " cursor-pointer m-0 p-2"
                                                            : " cursor-pointer m-0 p-2 ") + " text-left "
                                                    }
                                                >
                                                    <span className="small text-primary text-uppercase">
                                                        {column.Label ? column.render("Label") : column.render("Header")}
                                                    </span>
                                                    {!column.skipSort && (
                                                        <span className=" float-end p-0 m-0 text-primary" {...column.getSortByToggleProps()}>
                                                            {column.isSorted ? (
                                                                <i
                                                                    className={
                                                                        column.isSortedDesc
                                                                            ? "fa fa-long-arrow-alt-down"
                                                                            : "fa fa-long-arrow-alt-up"
                                                                    }
                                                                />
                                                            ) : (
                                                                <i className="fa fa-arrows-alt-v text-light" />
                                                            )}
                                                        </span>
                                                    )}
                                                </th>
                                            ) : (
                                                <th {...column.getHeaderProps()} className=" m-0 p-2 text-center" key={index}>
                                                    {column.Label ? column.render("Label") : column.render("Header")}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                ))}
                            </thead>
                        )}
                        {data && data.length > 0 ? (
                            <tbody {...getTableBodyProps()}>
                                {rows?.map((row, index) => {
                                    prepareRow(row);
                                    if (rowId === row.original.id) row.expanded = true;
                                    else row.expanded = false;
                                    return (
                                        <Fragment key={index}>
                                            <tr {...row.getRowProps()} key={index}>
                                                {row.cells?.map((cell, index) => {
                                                    return cell?.column?.id === "id" ? (
                                                        <td {...cell?.getCellProps()} key={index} className="p-2 m-0 text-center">
                                                            <span
                                                                className="p-0 m-0"
                                                                onClick={() =>
                                                                    row.original.id === rowId ? setRowId(null) : setRowId(row.original.id)
                                                                }
                                                            >
                                                                {cell?.render("Cell")}
                                                            </span>
                                                        </td>
                                                    ) : cell?.column?.id === "👉" ? (
                                                        <td
                                                            {...cell?.getCellProps()}
                                                            key={index}
                                                            className="btn p-2 m-0"
                                                            minWidth="80px"
                                                            style={{minWidth:"80px"}}
                                                            onClick={() => {
                                                                if (row.original.id === rowId) setRowId(null);
                                                                else setRowId(row.original.id);
                                                            }}
                                                        >
                                                            {cell?.render("Cell")}
                                                        </td>
                                                    ) : cell?.column?.id === "#" || cell?.column?.id === "~" ? (
                                                        <td {...cell?.getCellProps()} key={index} className="p-1 m-0">
                                                            {cell?.render("Cell")}
                                                        </td>
                                                    ) : (
                                                        (() => {
                                                            switch (cell?.column?.type) {
                                                                case "widget":
                                                                    return (
                                                                        <td {...cell?.getCellProps()} key={index} className="m-0 p-0">
                                                                            {cell?.render("Cell")}
                                                                        </td>
                                                                    );
                                                                
                                                                case "checkbox":
                                                                    return (
                                                                        <td
                                                                            {...cell?.getCellProps()}
                                                                            key={index}
                                                                            className="m-0 p-2 pt-1 pb-1 text-center"
                                                                        >
                                                                            {cell?.column?.onChange ? (
                                                                                <IndeterminateCheckbox
                                                                                    key={index}
                                                                                    object={cell?.row.original}
                                                                                    field={cell?.column?.id}
                                                                                    defaultChecked={cell?.value}
                                                                                    onChange={(e) =>
                                                                                        cell?.column?.onChange(
                                                                                            e.target.checked,
                                                                                            cell?.row.original
                                                                                        )
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                  cell?.column?.expression
                                                                                      ? cell?.column?.expression(cell?.value)
                                                                                      : cell?.value
                                                                              ) ? (
                                                                                <i key={index} className="fa fa-check text-success" />
                                                                            ) : (
                                                                                <i key={index} className="fa fa-times text-warning" />
                                                                            )}
                                                                        </td>
                                                                    );
                                                                case "boolean":
                                                                case "nullcheck":
                                                                    // console.log("cell values are",cell,cell?.column?.expression,cell?.value)
                                                                    return (
                                                                        <td
                                                                            {...cell?.getCellProps()}
                                                                            key={index}                                                                                                         
                                                                            className="m-0 p-2 pt-1 pb-1 text-center"
                                                                        >
                                                                            {(
                                                                                cell?.column?.expression
                                                                                    ? cell?.column?.expression(cell?.value)
                                                                                    : cell?.value
                                                                            ) ? (
                                                                                <i key={index} className="fa fa-check text-success" />
                                                                            ) : (
                                                                                <i key={index} className="fa fa-times text-warning" />
                                                                            )}
                                                                        </td>
                                                                    );
                                                                case "active":
                                                                    return (
                                                                        <td
                                                                            {...cell?.getCellProps()}
                                                                            key={index}
                                                                            className="m-0 p-1  text-center"
                                                                        >
                                                                            {cell?.value ? (
                                                                                <button
                                                                                    key={index}
                                                                                    className="btn btn-sm btn-warning"
                                                                                    onClick={() =>
                                                                                        updateOne({
                                                                                            variables: {
                                                                                                id: cell?.row.original.id,
                                                                                                set: { active: false },
                                                                                            },
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    DEACTIVATE
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    key={index}
                                                                                    className="btn btn-sm btn-primary"
                                                                                    onClick={() =>
                                                                                        updateOne({
                                                                                            variables: {
                                                                                                id: cell?.row.original.id,
                                                                                                set: { active: true },
                                                                                            },
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    ACTIVATE
                                                                                </button>
                                                                            )}
                                                                        </td>
                                                                    );
                                                                case "select":
                                                                    return (
                                                                        <td {...cell?.getCellProps()} key={index} className="m-0 p-2 pt-1 pb-1">
                                                                            {_.isArray(cell?.value)
                                                                                ? (
                                                                                      _.filter(cell?.column?.options, (e) =>
                                                                                          cell?.value?.includes(e?.value)
                                                                                      )?.map((e, i) => e.comment) ||
                                                                                      cell?.value ||
                                                                                      []
                                                                                  ).join(", ")
                                                                                : cell?.column?.options?.length > 0 &&
                                                                                  (_.find(
                                                                                      cell?.column?.options,
                                                                                      (e) => e?.value === cell?.value
                                                                                  )?.comment ||
                                                                                      cell?.value)}
                                                                        </td>
                                                                    );
                                                                case "image":
                                                                case "video":
                                                                case "document":
                                                                case "documents":
                                                                case "photo":
                                                                    return (
                                                                        <td {...cell?.getCellProps()} key={index} className="m-0 p-2 pt-1 pb-1">
                                                                            <ImageModal
                                                                                key={index}
                                                                                url={cell?.value}
                                                                                title={_.get(cell?.row?.original, cell?.column?.image_title)}
                                                                                privateImage={false || cell?.column?.privateImage}
                                                                                type={cell?.column?.type}
                                                                            />
                                                                        </td>
                                                                    );
                                                            
                                                                    case "rating":
                                                                         return (
                                                                                <td {...cell?.getCellProps()} key={index} className="m-0 p-2 pt-1 pb-1">
                                                                                    
                                                                                    <RatingValue
                                                                                        key={index}
                                                                                        value={cell?.value}
                                                                                    />

                                                                                </td>
                                                                            );
                                                                 default:
                                                                    // console.log("props are", cell);

                                                                    // if (cell?.column?.secured) {
                                                                    //     // if (props.secured) {
                                                                    //     axios
                                                                    //         .post(
                                                                    //             `https://originconnect-server-dev.herokuapp.com/user/getTextFromEncrypt`,
                                                                    //             // { url: "sdf_1628736682837.PNG" },
                                                                    //             {
                                                                    //                 code: `24ce36f1eb60f1b60bc86702b3b22d42`,
                                                                    //                 type: "passport",
                                                                    //                 customer_id: "bb26420d-1275-4c31-b9a2-c791666b70cd",
                                                                    //             },
                                                                    //             {
                                                                    //                 headers: {
                                                                    //                     Authorization: `Bearer ${cell?.column.idToken}`,
                                                                    //                     "x-client-app": "web-admin",
                                                                    //                 },
                                                                    //                 // responseType: "blob",
                                                                    //             }
                                                                    //         )
                                                                    //         .then((res) => {
                                                                    //             // console.log("response is", res);
                                                                    //             <td
                                                                    //                 {...cell?.getCellProps()}
                                                                    //                 key={index}
                                                                    //                 className={
                                                                    //                     "m-0 p-2 pt-1 pb-1" + (cell?.column?.alignment || "")
                                                                    //                 }
                                                                    //                 value={res.text}
                                                                    //             >
                                                                    //                 {cell?.render("Cell")}
                                                                    //             </td>;
                                                                    //         });
                                                                    // }

                                                                    return (
                                                                        <td
                                                                            {...cell?.getCellProps()}
                                                                            key={index}
                                                                            className={"m-0 p-2 pt-1 pb-1 " + (cell?.column?.alignment || "")}
                                                                            value={cell?.value}
                                                                        >
                                                                            {cell?.render("Cell")}
                                                                        </td>
                                                                    );
                                                            }
                                                        }).call(this)
                                                    );
                                                })}
                                            </tr>
                                            {!disableExpansion &&
                                            rowId === row.original.id &&
                                            rowChild &&
                                            rowChild({
                                                ...rest,
                                                id: rowId,
                                                object: row.original,
                                                columns,
                                                onClose: () => setRowId(null),
                                                refetch,
                                            }) ? (
                                                <tr {...row.getRowProps()} key={index + "x"}>
                                                    <td style={{ width: "100%" }} className="bg-light">
                                                        <div key={index + "x"} className="border bg-white">
                                                            {rowChild({
                                                                ...rest,
                                                                id: rowId,
                                                                object: row.original,
                                                                columns,
                                                                onClose: () => setRowId(null),
                                                                refetch,
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        ) : (
                            <tbody {...getTableBodyProps()}>
                                <tr>
                                    <td className="text-center bg-white">
                                        <span className="text-muted h6">No Records Found !</span>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>

                {data && (pages > 1 || data.length > limit) && (
                    <div className="row m-1">
                        <div className="col p-2 text-muted ">
                            <span className="m-1">{`Records : ${offset + 1} to ${offset + limit > total ? total : offset + limit}`}</span>|
                            <span className="m-1">
                                Page :
                                <input
                                    style={{ width: "80px", border: "none", outline: "none" }}
                                    className="text-muted"
                                    type="number"
                                    // size="1"
                                    value={page}
                                    // minLength="1"
                                    // maxLength="3"
                                    min={1}
                                    max={pages}
                                    // style={{ padding: "3px", fontSize: "1rem" }}
                                    onChange={(e) => setOffset((e?.target?.value - 1) * limit + 1)}
                                ></input>
                                {
                                    `of ${pages}`

                                    // | Records: ${
                                    //     pageIndex * pageSize + 1
                                    //     // pageIndex * pageSize * limit + 1
                                    //     // } to ${(pageIndex + 1) * limit > total ? data.length : (pageIndex + 1) * pageSize}`}</span>
                                    //     // } to ${(pageIndex ) * limit > data.length ? data.length : pageIndex * pageSize}`}</span>
                                    // } to ${pageIndex * pageSize + pageSize}`}
                                }
                            </span>
                        </div>
                        <div className="col">
                            <Pagination size="sm" aria-label="Pagination" className="pagination-outline-primary float-end">
                                {!smallTable && limit * pages >= limit && (
                                    <>
                                        {[10, 25, 50, 100, 250, 500].map(
                                            (e) =>
                                                e < total && (
                                                    <PaginationItem key={e} className="m-1" active={limit === e}>
                                                        <PaginationLink onClick={() => setLimit(e)}>{e}</PaginationLink>
                                                    </PaginationItem>
                                                )
                                        )}
                                        {limit * pages < 1000 ? (
                                            <PaginationItem key="all" className="m-1" active={limit === total}>
                                                <PaginationLink onClick={() => setLimit(total)}>ALL</PaginationLink>
                                            </PaginationItem>
                                        ) : (
                                            <span key={Math.random()} />
                                        )}
                                    </>
                                )}
                            </Pagination>
                        </div>
                        <div className="col">
                            {pages > 1 && (
                                <Pagination size="sm" aria-label="Pagination" className="pagination-primary float-end">
                                    {pages > 0 ? (
                                        <PaginationItem disabled={page === 1} key="first" className="m-1">
                                            <PaginationLink first onClick={() => setOffset(0)}></PaginationLink>
                                        </PaginationItem>
                                    ) : (
                                        <span key={Math.random()} />
                                    )}
                                    {pages > 0 ? (
                                        <PaginationItem disabled={page === 1} key="previous" className="m-1">
                                            <PaginationLink previous onClick={() => setOffset(limit * (page - 1) - 1)}></PaginationLink>
                                        </PaginationItem>
                                    ) : (
                                        <span key={Math.random()} />
                                    )}
                                    {pages > 0 ? (
                                        <PaginationItem disabled={page === pages} key="next" className="m-1">
                                            <PaginationLink next onClick={() => setOffset(limit * page + 1)}></PaginationLink>
                                        </PaginationItem>
                                    ) : (
                                        <span key={Math.random()} />
                                    )}
                                    {pages > 0 ? (
                                        <PaginationItem disabled={page === pages} key="last" className="m-1">
                                            <PaginationLink last onClick={() => setOffset(limit * (pages - 1) + 1)}></PaginationLink>
                                        </PaginationItem>
                                    ) : (
                                        <span key={Math.random()} />
                                    )}
                                </Pagination>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    else
        return (
            <div className="p-2" style={{ background: "whitesmoke" }}>
                <p className="text-center m-auto"> No Records Found </p>
            </div>
        );
};
