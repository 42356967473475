import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({ id: parent_id, ...useEntityFields({ entity, parent_id, solution }) });

    // console.log("Object is", object);

    let ids = object?.order_id;

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            // breadcrumbAddButtons={["jobcard_contact", "jobcard_loading", "jobcard_unloading", "jobcard_weighing", "jobcard_payment"]}
            breadcrumbAddOverrides={{ jobcard_id: object?.id }}
            breadcrumbSkipColumns={["jobcard"]}
            component={DetailInfo}
            entity={entity}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            metric_cards={[]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "order", parent_id, solution: "marketplace" }),
                    label: getLabel("order", true),
                    value: "order",
                    disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { jobcards: { id: { _eq: parent_id } } },
                    showList: true,
                },
                {
                    ...useEntityFields({ entity: "jobcard_contact", parent_id, solution }),
                    label: getLabel("contact", true),
                    value: "contact",
                    component: DataTable,
                    filters: { jobcard_id: { _eq: parent_id } },
                    overrideValues: { jobcard_id: parent_id },
                    skipColumns: ["jobcard"],
                    // showList: true,
                },
                {
                    ...useEntityFields({ entity: "jobcard_loading", parent_id, solution }),
                    label: getLabel("loading", true),
                    value: "loading",
                    disableAdd: true,

                    component: DataTable,
                    filters: { jobcard_id: { _eq: parent_id } },
                    overrideValues: { jobcard_id: parent_id },
                    skipColumns: ["jobcard"],
                    showList: true,
                },

                {
                    ...useEntityFields({ entity: "jobcard_weighing", parent_id, solution }),
                    label: getLabel("weighing", true),
                    value: "weighing",
                    component: DataTable,
                    filters: { jobcard_id: { _eq: parent_id } },
                    overrideValues: { jobcard_id: parent_id },
                    skipColumns: ["jobcard"],
                    showList: true,
                },
                {
                    ...useEntityFields({ entity: "jobcard_unloading", parent_id, solution }),
                    label: getLabel("unloading", true),
                    disableAdd: true,

                    value: "unloading",
                    component: DataTable,
                    filters: { jobcard_id: { _eq: parent_id } },
                    overrideValues: { jobcard_id: parent_id },
                    skipColumns: ["jobcard"],
                    showList: true,
                },
                {
                    ...useEntityFields({ entity: "order_payment", ids, solution }),
                    label: getLabel("order_payment", true),
                    value: "order_payment",
                    disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { order_id: { _eq: object?.order_id } },
                    overrideValues: { order_id: object?.order_id },
                    skipColumns: ["order"],
                    showList: true,
                },
                // {
                //     ...useEntityFields({ entity: "jobcard_payment", parent_id, solution }),
                //     label: getLabel("payment", true),
                //     value: "payment",
                //     component: DataTable,
                //     filters: { jobcard_id: { _eq: parent_id } },
                //     overrideValues: { jobcard_id: parent_id },
                //     skipColumns: ["jobcard"],
                //     showList: true,
                // },
            ]}
        />
    );
};

export default Detail;
