import React, { Suspense } from "react";
import { useObjectByPkSubscription } from "../../helpers";
import Skeleton from "react-loading-skeleton";

const Metric = React.lazy(() => import("./Metrics"));

export const MetricDataCards = React.memo(({ entity = "user", parent_id, fields, data_options = () => [] }) => {
    const { object } = useObjectByPkSubscription({ id: parent_id, entity, fields: fields ?? ` id ` });
    const options = data_options(object);
    return (
        <div className="row">
            {options?.map((option, index) => (
                <MetricCard key={index} {...option} />
            ))}
        </div>
    );
});

export const MetricCard = React.memo(({ type, value, total, label, icon, link, colSpan }) => (
    <div className={colSpan ?? "col-xl-2 col-lg-3 col-md-6 col-sm-12"}>
        <Suspense fallback={<Skeleton className="card" height={100} duration={5000}/>}>
            <Metric type={type} value={value} total={total} label={label} icon={icon} link={link}/>
        </Suspense>
    </div>
));

export const MetricCards = React.memo(({ options = [], expand = false }) => (
    <div className="row">
        {options?.map((option, index) => (
            <MetricCard key={index} colSpan={expand ? "col-12" : null} {...option} />
        ))}
    </div>
));
