import React from "react";
import { useHistory } from "react-router-dom";
import { APP_CONFIG } from "../../../config";

const HtmlViewer = (props) =>
{

  const history =  useHistory();
  const inv_weight = React.useRef();
  const inv_price = React.useRef();
  const inv_total_price = React.useRef();
  const inv_vehicle_name = React.useRef();
  const inv_drive_name = React.useRef();
  const inv_drive_ph = React.useRef();
  const no_of_pack = React.useRef();
  const discount = React.useRef();
 
  const id = props?.location?.state?.id;

 
  const no_of_pack_calc =   props?.location?.state?.no_of_box;
  const pack_price_calc =  props?.location?.state?.price_per_pack;

  const total_price_calc = (Number.parseInt(no_of_pack_calc) * Number.parseInt(pack_price_calc)) - (0.01*(Number.parseInt(no_of_pack_calc)*Number.parseInt(pack_price_calc)));

  const [state, setState] = React.useState({
     no_of_pack:no_of_pack_calc, 
     inv_price:pack_price_calc,
     total_amount :total_price_calc
    })


  const checkOnChange = (e)=>{
 
    if(e?.target?.id === 'inv_price' || e?.target?.id === 'no_of_pack' || e?.target?.id === 'discount'){
      setState({...state, 
        inv_price:Number.parseInt(e?.target?.value),
        total_amount:(no_of_pack?.current?.value*inv_price?.current?.value)- ((discount.current.value/100)*(no_of_pack?.current?.value*inv_price?.current?.value))
      })
    }
  } 

  const funcs = () =>{

        //  fetch("http://127.0.0.1:5000/user/seller",{
      fetch(`${APP_CONFIG.REACT_APP_SERVICES_URL}/user/seller`,{

    body:JSON.stringify({
      inv_weight:inv_weight?.current?.value, 
      inv_price:inv_price?.current?.value,
      inv_total_price:inv_total_price?.current?.value, 
      inv_vehicle_name:inv_vehicle_name?.current?.value,
      inv_drive_name:inv_drive_name?.current?.value,
      inv_drive_ph:inv_drive_ph?.current?.value, 
      no_of_pack:no_of_pack?.current?.value,
      discount:discount?.current?.value,
      id, 
      type:"Supplier Invoice"
    }) ,
     headers:{ "Access-Control-Allow-Headers":"*","Content-type": "application/json; charset=UTF-8",
     responseType:"Blob"},
    method:"post"})
    .then(response => {
      return response.blob().then(blob => {

        history.push('/web/InvoiceHistory/list/type/all')
        return {
          contentType: response.headers.get("Content-Type"),
          raw: blob
        }
      })
    })
   
    .then(result => { 
       
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([result?.raw]));
      link.setAttribute( 'download', 'Supplier Invoice.pdf', );
      document.body.appendChild(link);
      link.click();
      link.remove();
  })
    .catch((error) => {
      //  console.log("asdfasdfas",erer?.message)
     })
    
   }
 

 
return  <>
  <body className="mainpage mt-5 p-5 bg=primary">
    <table style={{"width": "100%"}}>
       <tr>
         <th    style={{width: "50%", fontSize: "20px"}}> Invoice </th>
         <th style={{textAlign: "right", float:"right"}}>
              <table style={{width: "100%", fontSize: "12px", float: "right"}}>
                       <tr>   <th style={{textAlign: "left", fontSize: "10px", fontWeight: "bold"}}> Supplier Name:  </th> <td > {props?.location?.state?.supplier_name}</td></tr>
                       <tr>   <th style={{textAlign: "left", fontSize: "10px", fontWeight: "bold"}}>Address:  </th> <td > {props?.location?.state?.supplier_address}</td></tr>
                       <tr>   <th style={{textAlign: "left", fontSize: "10px", fontWeight: "bold"}}> GST No. :  </th> <td > {props?.location?.state?.gst_no}</td></tr>
                       <tr>   <th style={{textAlign: "left", fontSize: "10px", fontWeight: "bold"}}> Invoice ID:  </th> <td > {props?.location?.state?.uid} </td></tr>
               </table>
         </th>
       </tr>
    </table>
 
 
    <hr/>
 
    <table style={{width: "100%"}}>
 
     <tr style={{width:"80%"}} > 
 
       <td style={{width: "35%", textAlign:"center" }}>
         <table  style={{width: "100%",textAlign: "left", fontSize: "10px", fontWeight: "bold"}}>
           <tr> <th  >Order no</th><td  id="sendVaa">{props?.location?.state?.id}</td> </tr>
           <tr> <th > Invoice date :</th><td >  {props?.location?.state?.date}</td> </tr>
           <tr> <th >GST No.</th><td > {props?.location?.state?.gst_no}</td> </tr>
           <tr> <th >Vehicle No</th><td >     <input type="text" id="inv_vehicle_name"  ref={inv_vehicle_name} style={{backgroundColor:"white", border: "1px solid red"}} /> 
          </td> </tr>
                    <tr> <th >Driver name</th><td >     <input type="text" id="inv_drive_name"   ref={inv_drive_name} style={{backgroundColor:"white", border: "1px solid red"}} /> 
          </td> </tr>
                    <tr> <th >Driver Ph</th><td >     <input type="text" id="inv_drive_ph"  ref={inv_drive_ph} style={{backgroundColor:"white", border: "1px solid red"}} /> 
          </td> </tr>
         </table>
       </td>
  
       <td   style={{width: "50%",textAlign: "left", fontSize: "10px", fontWeight: "bold"}}>
         <table style={{width: "100%", marginLeft: "10px"}}>
           <tr> <th >Bill To/Ship to :</th><td > GRV Junction Agri-the pvt ltd 704/8, The Discovery, <br/> Dattapada Road Borivali East, Mumbai 400 066, India</td> </tr>
           {/* <tr> <th > Ship to :</th><td > GRV Junction Agri-the pvt ltd 704/8, The Discovery, <br/> Dattapada Road Borivali East, Mumbai 400 066, India</td> </tr> */}
             <tr> <th > Ship to  :</th><td >{props?.location?.state?.customer_address}</td> </tr>
          </table>
       </td> 
   
     </tr>
 
    </table>
 
    <br/>
 <br/>
 
 <table class="heading" style={{"width": "100%"}} cellpadding="10">
   <tbody class="heading">
  <tr class="heading" style={{textAlign: "left", fontSize: "10px", fontWeight: "bold"}}> 
    <th   > No.</th>
    <th  > HS-Code</th>
    <th  >Commodity spec </th>
    <th  > Quantity in (Kgs)</th>
    <th  >Type of packing </th>
    <th  >Weight per Unit</th>
    <th  >Discount(%) </th>
    <th  >No of units </th>
    <th  > Price per unit </th> 
    <th  >Total amount </th> 
 
   </tr>
  
 
  <tr class="heading" style={{textAlign: "left", fontSize: "10px", fontWeight: "bold"}} onChange={checkOnChange}>
   <td class="heading" >1</td>
   <td class="heading" >{props?.location?.state?.hs_code}</td>
   <td class="heading" >{props?.location?.state?.commodity_spec}</td>
   <td class="heading" > <input type="text" id="inv_weight" ref={inv_weight}   style={{backgroundColor:"white", border: "1px solid red"}}  defaultValue={props?.location?.state?.quantity_in_mt}/> </td>
   <td class="heading" >{props?.location?.state?.type_of_packaging === "box" ? "Box" : "Sack"}</td>
   <td class="heading" >{props?.location?.state?.wt_per_pack}</td>
   <td class="heading" > <input type="text" id="discount"  ref={discount} style={{backgroundColor:"white", border: "1px solid red"}} defaultValue={1} min="0" max="0" /> </td>   
   <td class="heading" > <input type="text" id="no_of_pack"  ref={no_of_pack} style={{backgroundColor:"white", border: "1px solid red"}} defaultValue={state?.no_of_pack  } /> </td>   
   <td class="heading" > <input type="text" id="inv_price" ref={inv_price}  style={{backgroundColor:"white", border: "1px solid red"}}   defaultValue={state?.inv_price  } /> </td>
   <td class="heading" > <input type="text" id="inv_total_price" ref={inv_total_price} style={{backgroundColor:"white", border: "1px solid red"}}  
   
   value={state?.total_amount}
   
   
    disabled/> </td>

  </tr>
  <br/>
  
 </tbody>
 
 </table>

 
 <table style={{"width": "100%"}}>
   <tbody>

     <tr>
     <th   style={{textAign: "left", padding:"10px", fontSize: "10px", fontWeight: "bold",    verticalAlign: "middle"}} >Authorized Signature:  
        </th>
    </tr>

   </tbody>

 </table>
     <button onClick={funcs} style={{float: "right", backgroundColor: "greenyellow", border:" 0px solid transparent", padding: "5px", }}>Generate Invoice</button>

 <br/>
 <div>
  <span style={{fontWeight: "bold", fontSize: "10px"}}>
 
   DECLARATION:
  </span> 
  
  <span style={{fontWeight: "normal", fontSize: "10px"}}>
   We declare that the invoice shows the actual price of the goods described and that the particulars are true and correct.												
   For any issues, please contact OriginKonnect at +91 8200693679	
 
  </span>											
 
 </div>
 
 <br/>
 <br/>
{/*  
 <div style={{"fontWeight": "bold", "fontSize": "10px", "marginBottom": "5px"}}>Terms & Conditions</div>
 <div style={{"fontSize": "10px", "fontWeight": "normal"}}>1. </div>
 <div style={{"fontSize": "10px", "fontWeight": "normal"}}>2. </div>
 <div style={{"fontSize": "10px", "fontWeight": "normal"}}>3. </div>
 <div style={{"fontSize": "10px", "fontWeight": "normal"}}>4. </div>
 
  */}
 </body>
  

 </>
}

export default HtmlViewer;