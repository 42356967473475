import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { APP_CONFIG } from "../../config";
import { Loader } from "./Loader";

class Camera extends React.Component {
    state = {
        imageDataURL: null,
        env: "environment",
        error: false,
        show: true,
        loader: false,
        id_token: this.props.token,
    };

    initializeMedia = () => {
        this.setState({ imageDataURL: null, show: false });

        if (!("mediaDevices" in navigator)) {
            navigator.mediaDevices = {};
        }

        if (!("getUserMedia" in navigator.mediaDevices)) {
            navigator.mediaDevices.getUserMedia = function (constraints) {
                var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                if (!getUserMedia) {
                    return Promise.reject(new Error("getUserMedia Not Implemented"));
                }

                return new Promise((resolve, reject) => {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            };
        }

        navigator.mediaDevices
            .getUserMedia({
                video: {
                    facingMode: { exact: this.state.env },
                    width: { min: 240, ideal: 270, max: 300 },
                    height: { min: 240, ideal: 270, max: 300 },
                },
            })
            .then((stream) => {
                this.player.srcObject = stream;
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: true });
            });
    };

    capturePicture = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ show: true, loader: true });

        var canvas = document.createElement("canvas");
        canvas.width = this.player.videoWidth;
        canvas.height = this.player.videoHeight;
        var contex = canvas.getContext("2d");
        contex.drawImage(this.player, 0, 0, canvas.width, canvas.height);
        this.player.srcObject.getVideoTracks().forEach((track) => {
            track.stop();
        });

        let blob = this.dataURItoBlob(canvas.toDataURL());
        const form_data = new FormData();
        form_data.append("uploads", blob, "hl.png");

        axios
            .post(`${APP_CONFIG.REACT_APP_SERVICES_URL}/user/userimage`, form_data, {
                headers: { Authorization: `Bearer ${this.state.id_token}`, "x-client-app": "web-admin" },
            })
            .then((result) => {
                if (result && result?.data?.status === 200 && result?.config && result?.config.url) {
                    this.setState({ imageDataURL: result?.data?.data?.file_url[0] });
                    this.props.onChange(result?.data?.data?.file_url[0]);
                } else toast.error("Upload Failed.");
            })
            .catch((error) => {
                this.setState({ error: true });
                toast.error(error);
            })
            .finally(() => this.setState({ loader: false }));
    };

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(",")[1]);
        var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob;
    };

    render() {
        const playerORImage = Boolean(this.state.imageDataURL) ? (
            <span />
        ) : (
            <video
                ref={(refrence) => {
                    this.player = refrence;
                }}
                autoPlay
            ></video>
        );

        return (
            <div onClick={(e) => e.stopPropagation()}>
                {this.state.show && this.state.error === false ? <span /> : playerORImage}
                {this.state.loader ? <Loader /> : <span />}
                {this.state.error && <h3>unable to find rear camera for this device</h3>}

                {this.state.show && this.state.loader === false ? (
                    <button type="button" className="btn btn-sm btn btn-outline-primary p-2 float-end" style={{zIndex: 0}} onClick={this.initializeMedia}>
                        Take Photo
                    </button>
                ) : (
                    <span />
                )}
                {!this.state.show && this.state.error === false ? (
                    <button className="btn btn-sm btn btn-outline-success float-end" style={{zIndex: 0}} onClick={this.capturePicture}>
                        Capture
                    </button>
                ) : (
                    <span />
                )}
            </div>
        );
    }
}

export default Camera;
