/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { APP_CONFIG } from "../../config";
import { AuthContext } from "../../context";
import moment from "moment";
import { getLabel } from "../../helpers";


export const ImageModal = ({ title = "Document / Image", url = "", po_type = null,docTitle = null,imageTitle = "", imageHeight = 20, privateImage = false , type=""}) => {
    const [modal, set_modal] = useState(false);
    const [index, set_index] = useState(0);
    const [ , settUrl] = useState(null);
    // const [uurl, singleUurl] = useState(null);
    // console.log("Loading and Unloading details are",url)

    const [img , setImg] = useState(null);

    const { id_token } = useContext(AuthContext);

    // let checkObj = {};
  console.log("props.true",title, url);

    // let ArrayPromise = [];
    useEffect(() => {
        settUrl(null);
    }, []);

    // eslint-disable-next-line no-unused-vars
    let [urlArray, setUrlArray] = useState(_.isArray(url) ? [...url] : [url]);
    if (_.isEmpty(url)) return <span key={Math.random()} />;
    if (_.isArray(urlArray[0])) urlArray = [...urlArray[0]];
//  console.log("url is", urlArray, type);


    // if (privateImage) {
    //     let Testurl = [];
    //     if (!_.isArray(url)) Testurl = [url];
    //     else Testurl = [...url];
    //     Testurl?.map((v) => {
    //         ArrayPromise.push(
    //             axios
    //                 .post(
    //                    `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/getImage`,

    //                     // `https://originconnect-server-dev.herokuapp.com/user/getImage`,
    //                     // { url: "sdf_1628736682837.PNG" },
    //                     { url: v?.substr(v?.indexOf("uploads/") + "uploads/".length) },
    //                     {
    //                         headers: { Authorization: `Bearer ${id_token}`, "x-client-app": "web-admin" },
    //                         responseType: "blob",
    //                     }
    //                 )
    //                 .then((res) => {
    //                     let windowUrl = window.URL.createObjectURL(new Blob([res?.data]));
    //                     // let url = v?.substr(v?.indexOf("uploads/") + "uploads/".length);
    //                     // console.log("window url", windowUrl);
    //                     // console.log("response is", res);
    //                     return windowUrl;
    //                 })
    //         );
    //     });

    //     let result = Promise.all([...ArrayPromise])
    //         .then((res) => {
    //             // console.log("dfghj", res);
    //             return res;
    //         })

    //         .catch((e) => console.log("error is", e));

    //     result.then((res, i) => {
    //         if (turl?.length == null && res != null) {
    //             // console.log("checknign and working", res, turl?.length);
    //             settUrl([res]);
    //             // urlArray.length = 0;
    //             // urlArray = [[...res]];
    //             // console.log("url array is", res);
    //             setUrlArray([...res]);
    //         }
    //     });

    //     // return turl !== null && turl?.length > 0 ? (
    //     //     turl[0]?.map((e, i) => (
    //     //         <img
    //     //             key={Math.random()}
    //     //             src={e}
    //     //             alt=""
    //     //             height={imageHeight}
    //     //             onClick={() => {
    //     //                 set_modal(true);
    //     //                 set_index(i);
    //     //                 //     singleUurl(true);
    //     //                 //   Ima(i, true);
    //     //                 console.log("index is", turl[0][i]);
    //     //             }}
    //     //         >
    //     //             {console.log("check1", turl, e)}
    //     //         </img>
    //     //     ))
    //     // ) : (
    //     //     <span>Hello</span>
    //     // );
    // }

    // console.log("url map", urlArray);

 

    // console.log("type is",type)
    const getDoc= (urlObject,privates,i) =>{
        // console.log("clicking ", urlObject,privates)
        axios
                          .post(
                              `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/getImage`,
                               {...privates, url:`${ typeof urlObject ==="string" ? urlObject : urlObject["key"]}`?.substr(`${typeof urlObject ==="string" ? urlObject : urlObject["key"]}`?.indexOf("uploads/") + "uploads/".length) },
                               { headers: { Authorization: `Bearer ${id_token}`, "x-client-app": "web-admin" }, responseType: "blob" }
                          )
                          .then((result) => {
                            //    console.log("clicking result is", result, type)
                             if(type === "photo" || type ==="image" || type==="video")
                             {
                                set_modal(true);
                               let windowUrl =  window.URL.createObjectURL(new Blob([result?.data],{type: 'image/png'}));
                                // console.log("clicking result one is",i, windowUrl)
                                return  setImg(windowUrl)
                                
                             }

                              if (result?.data) {
                                  const link = document.createElement("a");
                                  link.href = window.URL.createObjectURL(new Blob([result?.data], {type: 'application/pdf'}));
                                  link.setAttribute("download", `${moment().format('MMMM Do YYYY, h:mm:ss a')}(${typeof urlObject ==="string" ? urlObject : urlObject["type"]}).pdf`);
                                  document.body.appendChild(link);
                                  link.click();
                                  link.remove();
                              }
                          })
                          .catch((error) => console.log("clicking result Error ",error, type))
    }

    
    return urlArray?.map((urlObject, i) =>
        !_.isEmpty(urlObject) ? (
            <span key={i} id={url}>
                {imageTitle ? (
                    <div key={Math.random()} className="btn btn-sm btn-outline-primary m-1" onClick={() => set_modal(true)}>
                        {imageTitle}
                    </div>
                ) : (
                    <span key={Math.random()} className="btn btn-sm m-0 p-0 border m-1">
                            {  console.log("url is", urlObject, type,privateImage )}
                       
                        {
                                (privateImage===true && (type==="document" || type==="documents")) ? (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a key={Math.random()}  onClick={()=>getDoc(urlObject,{private:true},i)}>
                                    <i className="fa fa-file-download fa-lg float-start p-2" aria-hidden="true"></i>
                                    {docTitle ? <i className="float-end float-right p-2" aria-hidden="true">&nbsp;{ moment(docTitle || urlObject["created_at"]).format('MMMM Do YYYY, h:mm:ss a') }&nbsp;({getLabel(urlObject.type ? urlObject.type :po_type)})</i>:<span></span>}

                                </a>
                            ) : privateImage === true ?    
                            
                            <>
                             <a key={Math.random()} onClick={()=>getDoc(urlObject,{private:true})}>
                             <i className="fas fa-image fa-lg" aria-hidden="true" ></i>

                                </a>

                                </>
                            
                            
                            :  urlObject?.slice(-4) === ".mp4" ? (
                                <i
                                    className="fa fa-play fa-lg"
                                    onClick={() => {
                                        set_modal(true);
                                        set_index(i);
                                    }}
                                    aria-hidden="true"
                                />
                            ) :  
                            urlObject ? (
                                <img
                                    key={Math.random()}
                                    // src={urlObject?.replace(".pdf", ".jpg")}
                                    src={urlObject?.replace(".pdf", ".jpg")}
                                    alt=""
                                    height={imageHeight}
                                    onClick={() => {
                                        set_modal(true);
                                        set_index(i);
                                    }}
                                >
                                    {/* {console.log("url object is", urlObject)} */}
                                </img>
                            ) : (
                                <h1> _ </h1>
                            )
                            // )
                        }
                    </span>
                )}
                <Modal key={Math.random()} isOpen={modal} size="lg">
                    <ModalHeader toggle={() => set_modal(!modal)}>
                        <div className="m-0">{title}</div>
                    </ModalHeader>
                    <ModalBody style={{ maxHeight: "85vh", overflow: "auto", padding: 5 }}>
                        {typeof urlObject ==="string" &&urlObject?.slice(-4) === ".mp4" ? (
                            <video src={urlObject} width="100%" preload="metadata" controls />
                        ) : (
                            // eslint-disable-next-line no-whitespace-before-property
                            <img src={ privateImage ? img : typeof urlArray[index] ==="string" ? urlArray[index]?.replace(".pdf", ".jpg") : urlArray[index]?.["key"].replace(".pdf", ".jpg")} alt="" width="100%" />
                         
                        
                        )}
                    </ModalBody>
                </Modal>
            </span>
        ) : (
            <span key={i}></span>
        )
    );
};
