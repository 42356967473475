import _ from "lodash";
import React, { useContext } from "react";
import { Button } from "reactstrap";
import { DataTable } from "../../../components/common/DataTable";
import { PageContainer } from "../../../components/common/PageContainer";
import { AuthContext, GeneralContext } from "../../../context";
import { ENUM_ACTIVE, getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";
import { Modals } from "./modal";
 
const List = () => {

	// eslint-disable-next-line no-unused-vars
	const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: tab } = getParams();
	// const { enum_po_status } = useContext(GeneralContext);
    const {  user_id , id_token} = useContext(AuthContext);
	let entity_fields = useEntityFields({entity:"bidding_importer_offer"});
    console.log("checking entity ===2", entity_fields)
    //  if (page !== "list" || _.isEmpty(entity_fields)) return <span key={Math.random()} />;
  
 	const { object, error } = useObjectByPkQuery({ id: user_id, ...useEntityFields({ entity:"user" }) });
	
    const override_fields = { overrideValues:{exporter_id : object?.customer_id}  }
 
 

 
	return (
		<PageContainer
			// breadcrumbTitle={getLabel("Freight Options", true)}
			// breadcrumbOptions={["type", "active"]}
			// breadcrumbArg={3}
			tab_arg={4}
			key={Math.random()}
			showBreadCrumb={false}
			options={{ entity_fields}}
			disableAction={true}
			disableEdit={false}
			disableAdd={true}
			searchableColumns={false}
			hideFilter={true}
			// showFilterForm={true}
			// extraColumnData={extraColumnData}
			detail={page === "detail"}
			// expanded={true}
			Model={Modals}

			// enabledispatchColumn={
			// 	[
 			// 	{Header:"Release",minWidth:100, body:{ col:"id" },showHeader:true, requestDetails:{showTitle:"Email",subUrl:"/user/Biding_offer_release", method:"post",responseType:null,message:"Sucessfully Sent!"}}  ,          
			// 	]}	

				enabledispatchColumn={
					[
					{Header:"",minWidth:100, colData : <Button className="btn btn-sm btn-primary m-1 responsive-btn" onClick={""}>Bid</Button>, body:{col:"id",date: new Date()},requestDetails:{ date: new Date()}},
	
					]}	

			tab_options={(() => {
				let tab_options = [];
				// switch (tab) {
				// 	case "type":
				// 		tab_options = !_.isArray(enum_po_status)
				// 			? []
				// 			: enum_po_status?.map((e) => ({
				// 					...entity_fields,
				// 					...override_fields,
				// 					overrideValues: {
				// 						...(entity_fields?.overrideValues || {}),
				// 						...(override_fields?.overrideValues || {}),
				// 					},
				// 					label: e?.comment,
				// 					value: e?.value,
				// 					component: DataTable,
				// 					filters: {
				// 						...(entity_fields?.filters || {}),
				// 						...(override_fields?.filters || {}),
				// 						status: { _eq: e.value },
				// 					},
				// 			  }));
				// 		break;
				// 	default:
				// 		tab_options = ENUM_ACTIVE.map((e) => ({
				// 			...entity_fields,
				// 			...override_fields,
				// 			overrideValues: {
				// 				...(entity_fields?.overrideValues || {}),
				// 				...(override_fields?.overrideValues || {}),
				// 			},
				// 			label: e?.comment,
				// 			value: e?.value,
				// 			component: DataTable,
				// 			filters: {
				// 				...(entity_fields?.filters || {}),
				// 				...(override_fields?.filters || {}),
				// 				active: { _eq: e.value === "active" ? true : false },
				// 			},
				// 		}));
				// 		break;
				// }
				tab_options = [
					{
						label: "ALL",
						value: "all",
						...entity_fields,
						...override_fields,
						overrideValues: {
							...(entity_fields?.overrideValues || {}),
							...(override_fields?.overrideValues || {}),
						},
						component: DataTable,
						// TableName: Freight,
						filters: {
							...(entity_fields?.filters || {}),
							...(override_fields?.filters || {}),
						},
					},
					...tab_options,
				
					
				];
				return    tab_options
					 
			}).call(this)}
		/>
 
	);
};

export default List;
