import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'

export default function RatingValue({value=0 ,readonly=true,onChange=()=>{}, showTooltip=false}) {
  const [rating, setRating] = useState(value) // initial rating value
  console.log("rating values", value, readonly, onChange)
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    onChange(rate);
    // other logic
  }

  return (
    <div className='App'>
      <Rating onClick={handleRating} 
      ratingValue={value}  
      readonly={readonly} 
      showTooltip={showTooltip} 
      transition
      allowHalfIcon 
      tooltipArray={['Terrible','Terrible', 'Bad','Bad', 'Average','Average', 'Great','Great+', 'Prefect','Prefect+']}
      size="15" 
      
      />
      {/* (<span>{value}</span>) */}
    </div>
  )
}