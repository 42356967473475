// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import React, { useContext } from "react";
import MetaTags from "react-meta-tags";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
// import logoLightSvg from "../../assets/images/logo-dark.svg";
// import logoLightSvg from "../../assets/images/OriginKonnectlogo.png";
import logoLightSvg from "../../assets/images/oclogo/cecs.png";
import { APP_CONFIG, APP_PATHS } from "../../config";
import { AuthContext } from "../../context";

const Login = (props) => {
    const { setAuthContext, id_token, user_id } = useContext(AuthContext);

    const onSubmit = async (e, v) => {
        e.preventDefault();

        const { email, password } = v;

        if (email && password) {
            try {
                const response = await axios({
                    method: "POST",
                    url: `${APP_CONFIG.REACT_APP_SERVICES_URL}/user/login`,
                    data: { email, password },
                    headers: { "x-client-app": "web-admin" },
                });

                if (response?.data?.success)
                    setAuthContext({
                          customer_id: response?.data?.data?.user_info?.customer_id,
                          user_id: response?.data?.data?.user_info?.id,
                        id_token: response?.data?.data?.token,
                        role: response?.data?.data?.user_info?.role,
                    });
                else throw response?.data;
            } catch (error) {
                console.error("Login -> error", error);
                toast.error(error?.message ?? "Something went wrong. Please try again", { position: "top-right" });
            }
        } else {
        }
    };

    if (id_token && user_id) return <Redirect to={APP_PATHS.authRedirect} />;

    return (
        <React.Fragment>
            <MetaTags>
                <title>Login | OriginKonnect</title>
            </MetaTags>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link>
            </div>
            <div className="account-pages d-flex justify-content-center">
                <Container style={{ position: "absolute", top: "50%", transform: "translateY(-50%)" }}>
                    <Row className="justify-content-center" style={{ position: "relative" }}>
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="text-center mt-3 p-2  ">
                                        <img src={logoLightSvg} alt="" height="100" />
                                    </div>

                                    <div className="p-2">
                                        <AvForm className="form-horizontal" onValidSubmit={onSubmit}>
                                            {props.error && typeof props.error === "string" ? (
                                                <Alert color="danger">{props.error}</Alert>
                                            ) : null}
                                            <div className="mb-3">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    value={(e) => e.target.value}
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <AvField
                                                    name="password"
                                                    label="Password"
                                                    value={(e) => e.target.value}
                                                    type="password"
                                                    required
                                                    placeholder="Enter Password"
                                                />
                                            </div>
                                            {/* <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="customControlInline" />
                                                <label className="form-check-label" htmlFor="customControlInline">
                                                    {/* Remember me */}
                                            {/* </label>
                                            </div> */}{" "}
                                            <div className="mt-3 d-grid">
                                                <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                                                    LOG IN
                                                </button>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <Link to="/auth/forgot-password" className="text-muted">
                                                    <i className="mdi mdi-lock me-1" />
                                                    Forgot your password?
                                                </Link>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="text-center">
                        <p>© {new Date().getFullYear()} OriginKonnect.</p>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;
