import React from "react";
import { DataTable } from "../../../components/common/DataTable";
import { DetailInfo } from "../../../components/common/DetailInfo";
import { PageContainer } from "../../../components/common/PageContainer";
import { getLabel, getParams, useEntityFields, useObjectByPkQuery } from "../../../helpers";

const Detail = () => {
    const { arg_0: solution, arg_1: entity, arg_2: page, arg_3: parent_id } = getParams();
    const { object } = useObjectByPkQuery({
        id: parent_id,
        ...useEntityFields({ entity, parent_id, solution }),
    });

    return (
        <PageContainer
            detail={page === "detail"}
            parent_id={parent_id}
            breadcrumbTitle={object?.name}
            component={DetailInfo}
            entity={entity}
            list_options={[]}
            options={{ ...useEntityFields({ entity, parent_id, solution }) }}
            tab_arg={4}
            metric_cards={[]}
            tab_options={[
                {
                    ...useEntityFields({ entity: "offer", parent_id, solution }),
                    label: getLabel("offer", true),
                    value: "offer",
                    disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { id: { _eq: object?.offer_id } },
                    overrideValues: { offer_id: object?.offer_id },
                    skipColumns: ["offer"],
                    showList: true,
                },
                {
                    ...useEntityFields({ entity: "order_payment", parent_id, solution }),
                    label: getLabel("order_payment", true),
                    value: "order_payment",
                    // // disableAdd: true,
                    hideFilter: true,
                    component: DataTable,
                    filters: { order_id: { _eq: parent_id } },
                    overrideValues: { order_id: parent_id },
                    skipColumns: ["order"],
                    showList: true,
                },
                {
                    ...useEntityFields({ entity: "jobcard", parent_id, solution: "trade" }),
                    label: getLabel("jobcard", true),
                    value: "jobcard",
                    hideFilter: true,
                    component: DataTable,
                    filters: { order_id: { _eq: parent_id } },
                    overrideValues: { order_id: parent_id },
                    skipColumns: ["order"],
                    showList: true,
                },
            ]}
        />
    );
};

export default Detail;
