import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

export const DropDownButton = ({ title = "", options = [], direction = "left" }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    if (options.length === 0) return <span key={Math.random()} />;
    return (
        <Dropdown direction={direction} isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} className="btn btn-sm btn-white p-0">
                {title || <i className="fa fa-cog" />}
            </DropdownToggle>
            <DropdownMenu right>
                {options?.map((option, index) => (
                    <DropdownItem key={index} className="border text-uppercase" disabled={!option?.action} onClick={option?.action}>
                        {option?.title} {option?.icon ? <span className="float-end">{option?.icon}</span> : ""}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
