import React from "react";
import Signup from "./Signup";
import OtpVerification from "./OtpVerifiy";


const Login = (props) => {
  
     const [verificationPage, setVerificationPage] = React.useState(true);
     const [phone, setPhone] = React.useState();

     const updatePhone = (phoneNumber)=>{

        setPhone(phoneNumber)

     };

    return (

        <div className="vh-100  vw-100  row align-items-center justify-content-center bg-white">

             { verificationPage ?   <Signup verifyPage={setVerificationPage} setphone={updatePhone}/>   : <OtpVerification phoneNumber={phone}/>}

        </div>
       
    );
};

export default Login;
