// Queries can have multiple first-level fields to retrieve
import { gql } from "@apollo/client";
export const Q_ENTITY_COUNT = (entity) =>
    gql`query Q_ENTITY_COUNT_${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { count } } }`;
export const Q_ENTITY_AGGREGATE = (entity, fields) =>
    gql`query Q_ENTITY_AGGREGATE_${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { ${fields} } } }`;
export const Q_ENTITY_DATA = ( entity, fields ) => gql`query Q_ENTITY_DATA_${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!], $distinct_on: [${entity}_select_column!]) { objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;

export const Q_ENTITY_METRIC = (
    entity,
    fields
) => gql`query Q_ENTITY_METRIC_${entity}($where: metric_bool_exp, $limit: Int, $offset: Int, $order_by: [metric_order_by!], $distinct_on: [metric_select_column!]) {
                objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;

export const Q_ENTITY_METRIC_ARGS = (
    entity,
    fields
) => gql`query Q_ENTITY_METRIC_${entity}($args: ${entity}_args!, $where: metric_bool_exp, $limit: Int, $offset: Int, $order_by: [metric_order_by!], $distinct_on: [metric_select_column!]) {
                objects: ${entity}(args: $args, where: $where, limit: $limit, offset: $offset, order_by: $order_by, distinct_on: $distinct_on) { ${fields} } }`;

export const Q_ENTITY_BY_PK = (entity, fields) =>
    gql`query Q_ENTITY_BY_PK_${entity}_by_pk($id: uuid!) {object: ${entity}_by_pk(id:$id) { id ${fields} } }`;

export const Q_ENTITY_AGG = (entity, aggregate, field) =>
    gql`query Q_ENTITY_AGG_${entity}_aggregate($where: ${entity}_bool_exp) {counts: ${entity}_aggregate(where: $where) { aggregate { ${aggregate} { ${field} } } } }`;


    export const Q_ENTITY_AGG_TEST = (entity, aggregate, field) =>
    gql`query Q_ENTITY_AGG_${entity}_aggregate ($where: metric_bool_exp) {   counts: ${entity}_aggregate (where:$where){ aggregate { ${aggregate} { ${field} } } } }`;


    export const Q_ENTITY_AGG_TEST_DISTINCT = (entity, aggregate, field) =>
    gql`query Q_ENTITY_AGG_${entity}_aggregate  {counts: ${entity}_aggregate( distinct_on:id ) { aggregate { count(columns:id)  } } }`;
    

        
export const Q_ID_NAMES = (
    entity,
    fields
) => gql`query Q_ID_NAMES_${entity}($where: ${entity}_bool_exp, $limit: Int, $offset: Int, $order_by: [${entity}_order_by!]) {
	objects: ${entity}(where: $where, limit: $limit, offset: $offset, order_by: $order_by) { id name ${fields} } }`;

export const Q_ENUMS = (entity) => gql`query Q_ENUMS_${entity}{objects: ${entity}{id: value value comment}}`;
export const Q_ID_NAME = (entity, fields) =>
    gql`query Q_ID_NAME_${entity}_by_pk($id:uuid!) {object: ${entity}_by_pk(id: $id){ id name ${fields}}}`;

export const Q_UNIQUE = (entity, field) =>
    gql`query Q_UNIQUE_${entity}_aggregate($text:String!) {counts: ${entity}_aggregate(where:{${field}:{_ilike:$text}}) { aggregate {count} } }`;
export const Q_UNIQUE_ID = (entity, field, dependency) =>
    gql`query Q_UNIQUE_${entity}_aggregate($text:String!,$id:uuid!) {counts: ${entity}_aggregate(where:{${field}:{_ilike:$text},${dependency}:{_eq:$id}}) { aggregate {count} } }`;
export const Q_TIME_SERIES = (entity = "timeseries", fields = " date count ") =>
    gql`query Q_TIME_SERIES_${entity}($where: timeseries_bool_exp, $order_by: [timeseries_order_by!], ) {objects: ${entity}(where: $where, order_by: $order_by) { ${fields} } }`;
