import React, { Suspense } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
const Chart = React.lazy(() => import("react-apexcharts"));

export const ChartCard = ({
    header_link,
    footer_link,
    header = "Chart Header",
    footer,
    type = "donut",
    options = {},
    series = [],
    labels = [],
    ...rest
}) =>
    type ? (
        <div className="card">
            {header ? (
                <div className="card-header">
                    <div className="text-uppercase h6">
                        {header_link?.length > 0 ? (
                            <Link to={header_link}>
                                <div className="text-info">{header}</div>
                            </Link>
                        ) : (
                            <div className="text-muted">{header}</div>
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
            <div className="card-body p-2">
                <Suspense fallback={<Skeleton height={200} />}>
                    {series?.length > 0 ? (
                        <Chart type={type} options={options ?? {}} series={series ?? []} labels={labels ?? []} {...rest} />
                    ) : (
                        <span>No Data Found</span>
                    )}
                </Suspense>
            </div>
            {footer ? (
                <div className="card-footer">
                    <div className="text-uppercase h6">
                        {footer_link?.length > 0 ? (
                            <Link to={footer_link}>
                                <div className="text-info">{footer}</div>
                            </Link>
                        ) : (
                            <div className="text-muted">{footer}</div>
                        )}
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    ) : (
        <span key={Math.random()} />
    );

export const ChartCards = React.memo(({ options = [] }) => (
    <div className="row">
        {options?.map((option, index) => (
            <div key={index} className={option?.colSpan ?? `col-xl-4 col-lg-6 col-md-6 col-sm-12`}>
                <ChartCard key={index} height={["pie", "donut"].includes(option.type) ? 200 : 400} {...option} />
            </div>
        ))}
    </div>
));
