// MetisMenu
import React, { useContext } from "react";
import { FcCheckmark, FcExport } from "react-icons/fc";
import { Menu, MenuItem, ProSidebar, SidebarFooter, SidebarHeader, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { getMenuItems } from "../../helpers";
// import user from "../../assets/images/users/avatar-8.jpg"
import user from "../../assets/images/OriginKonnect_512_512.png";
import { useDispatch } from "react-redux";
import { AuthContext } from "../../context";

const SidebarContent = (props) => {
    const menu_items = getMenuItems();

    const { role } = useContext(AuthContext);

    // console.log("menu items", menu_items);
    const dispatch = useDispatch();
    return (
        <ProSidebar
            key="sidebar"
            {...props}
            className="shadow"
            style={{
                zIndex: 1000,
                position: "fixed",
                height: "100vh",
                borderRadius: "0px 8px 8px 0px",
                overflow: "hidden",
                transition: "all 0.05s",
            }}
        >
            <Menu iconShape="circle">
                <SidebarHeader className="text-left pt-5 p-4" style={{ height: 200 }}>
                    <img src={user} className="mt-4" alt="user" style={{ objectFit: "contain", width: 60, borderRadius: "100%" }} />
                    <p className="h4 mt-2 text-secondary">Admin</p>
                    {/* <p className="mb-1">admin@gmail.com</p> */}
                </SidebarHeader>
                {menu_items?.map((m, i) =>
                    m?.children?.length > 0 &&  m?.role===role ? (
                        <SubMenu
                            key={i}
                            title={m?.title ?? ""}
                            icon={m?.icon}
                            defaultOpen={m?.link ? props?.location?.pathname?.startsWith(`/${m?.link}`) : false}
                            className="mt-1"
                        >
                            {props.collapsed && <li className="menu-title">{m?.title ?? ""}</li>}
                            {m?.children?.map((sm, si) => (
                                <MenuItem
                                    key={si}
                                    icon={sm?.icon}
                                    onClick={() => {
                                        dispatch({ type: "CHANGE_LAYOUT", payload: "horizontal" });
                                    }}
                                >
                                    <Link key={si} to={`/${m?.link}/${sm?.link}${sm?.detail ? "/detail" : "/list"}`}>
                                        {sm?.title ?? ""}{" "}
                                        {sm?.link ? (
                                            props?.location?.pathname?.startsWith(
                                                `/${m?.link}/${sm?.link}${sm?.detail ? "/detail" : "/list"}`
                                            ) ? (
                                                <FcCheckmark className="float-end" />
                                            ) : (
                                                ""
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </Link>
                                </MenuItem>
                            ))}
                        </SubMenu>
                    ) : m?.role===role ? (
                        <MenuItem
                            key={i}
                            icon={m?.icon}
                            active={m?.link ? props?.location?.pathname?.startsWith(`/${m?.link}`) : false}
                            className="mt-1"
                            onClick={() => {
                                dispatch({ type: "CHANGE_LAYOUT", payload: "horizontal" });
                            }}
                        >
                            <Link key={i} to={`/${m?.link}`}>
                                {m?.title ?? ""}
                                {m?.link ? (
                                    props?.location?.pathname?.startsWith(`/${m?.link}`) ? (
                                        <FcCheckmark className="float-end" />
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    ""
                                )}
                            </Link>
                        </MenuItem> 
                    ): ""
                )}
                <SidebarFooter className="mt-4 w-100">
                    <MenuItem icon={<FcExport size={22} />}>
                        <Link to="/logout">Log Out</Link>
                    </MenuItem>
                </SidebarFooter>
            </Menu>
        </ProSidebar>
    );
};

export default SidebarContent;
